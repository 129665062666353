<form class="form-grid custom-form-form" action="" [formGroup]="service.form">
  <fieldset class="form-grid-item-full-width">
    <legend>Beschreibe dieses Formular</legend>

    <mat-form-field>
      <mat-label>Name des Formulars</mat-label>
      <input matInput formControlName="name" maxlength="120" />
      <mat-error>Bitte gib einen Namen an</mat-error>
      <mat-hint
        >{{ service.form.controls.name.value?.length ?? 0 }} / 120</mat-hint
      >
    </mat-form-field>
  </fieldset>

  <fieldset
    class="form-grid-item-full-width"
    *ngIf="!service.config?.hideInstitutionSettings"
  >
    <legend>
      Für welche Einrichtung(en) möchtest du das Formular erstellen?
    </legend>

    <mat-form-field appearance="outline">
      <mat-label>Einrichtung(en)</mat-label>
      <mat-select
        formControlName="institutions"
        multiple
        required
        [compareWith]="idCompareWith"
      >
        <mat-option
          *ngFor="let institution of service.availableInstitutions$ | async"
          [value]="institution"
        >
          {{ institution.name }}
        </mat-option>
      </mat-select>
      <mat-error
        >Bitte wähle die Einrichtung(en) aus, für die das Formular zur Verfügung
        stehen soll</mat-error
      >
    </mat-form-field>

    <tremaze-custom-form-feature-select
      appearance="outline"
      formControlName="feature"
    ></tremaze-custom-form-feature-select>
  </fieldset>

  <h5>Felder</h5>

  <mat-accordion
    class="form-grid-item-full-width"
    id="dragBoundary"
    formArrayName="fields"
    cdkDropList
    cdkDropListLockAxis="y"
    (cdkDropListDropped)="onDrop($event)"
  >
    <mat-expansion-panel
      cdkDrag
      [cdkDragDisabled]="
        expansionPanel.expanded || service.form.controls.fields.length <= 1
      "
      *ngFor="
        let field of service.form.controls.fields.controls;
        trackBy: trackField;
        let i = index
      "
      cdkDragBoundary="mat-accordion"
      @field
      #expansionPanel
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          style="
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
          "
          >{{ getFieldExpansionPanelHeaderTitle(field) }}
        </mat-panel-title>
        <mat-panel-description
          >{{ getFieldExpansionPanelHeaderDescription(field) }}
          <span class="spacer"></span>
          <mat-icon
            *ngIf="getShowErrorIconForExpansionPanel(field)"
            color="warn"
          >
            <span class="lnr lnr-notification"></span>
          </mat-icon>
          <mat-icon>
            <span [class]="getFieldExpansionPanelHeaderIcon(field)"></span>
          </mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <tremaze-custom-forms-field-edit
          [formGroup]="field"
          [index]="i"
        ></tremaze-custom-forms-field-edit>
      </ng-template>

      <mat-action-row>
        <button mat-button (click)="onClickRemoveFieldButton(i)" type="button">
          Entfernen
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="custom-form-form__Footer form-grid-item-full-width">
    <tremaze-custom-form-add-new-field-button></tremaze-custom-form-add-new-field-button>

    <mat-slide-toggle
      *tremazeHasPermission="publishPermissionRequest$ | async"
      formControlName="published"
      >Aktiv
    </mat-slide-toggle>
  </div>
</form>
