import {
  SortedFilteredTableDataManipulationServiceResponse,
  SortedFilteredTableDetailsResponse,
} from '@tremaze/shared/sorted-filtered-paginated-table/types';
import { Observable } from 'rxjs';

export abstract class SortedFilteredTableDataManipulationService<T> {
  abstract createItem(): Observable<
    null | undefined | SortedFilteredTableDataManipulationServiceResponse<T>
  >;

  abstract editItem(
    item: T,
  ): Observable<
    null | undefined | SortedFilteredTableDataManipulationServiceResponse<T>
  >;

  abstract deleteItem?(item: T): Observable<null | undefined | boolean>;
}

export abstract class SortedFilteredTableDetailsService<T> {
  abstract showDetails(
    item: T,
  ): Observable<null | undefined | SortedFilteredTableDetailsResponse>;
}
