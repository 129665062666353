import {
  TermsOfUse,
  TermsOfUseAcceptance,
} from '@tremaze/shared/feature/legal-settings/feature/terms-of-use/types';
import { map, Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BaseDataSource,
  DefaultDataSourceMethods,
} from '@tremaze/shared/util-http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';

@Injectable({
  providedIn: 'root',
})
export class RemoteTermsOfUseSettingsDataSourceDefaultImpl
  extends BaseDataSource<TermsOfUse>
  implements TermsOfUseSettingsDataSource
{
  protected controller = 'termsOfUse';
  protected deserializer = TermsOfUse.deserialize;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  getAcceptanceStateForUserById(
    userId: string,
  ): Observable<TermsOfUseAcceptance | null> {
    return this.http
      .get(`users/${userId}/termsOfUse/current`)
      .pipe(map(TermsOfUseAcceptance.deserialize));
  }

  getLatestTermsOfUseByUserTypeId(
    userTypeId: string,
  ): Observable<TermsOfUse | null> {
    return this.http
      .get(`${this.controller}/${userTypeId}/current`)
      .pipe(map(this.deserializer));
  }

  getTermsOfUseById(id: string): Observable<TermsOfUse> {
    return DefaultDataSourceMethods.getFreshById(
      this.http,
      this.controller,
      this.deserializer,
      id,
    );
  }

  getTermsOfUseVersionsByUserTypeId(
    _userTypeId: string,
  ): Observable<Pagination<TermsOfUse>> {
    return DefaultDataSourceMethods.getPaginated(
      this.http,
      this.controller,
      this.deserializer,
    );
  }

  setTermsOfUse(termsOfUse: TermsOfUse): Observable<TermsOfUse> {
    if (termsOfUse.isEmpty) {
      return this.http
        .put(`${this.controller}/current/unpublish`, null, {
          params: { userTypeId: termsOfUse.userType.id },
        })
        .pipe(map(() => termsOfUse));
    }
    const payload = {
      text: termsOfUse.text,
      checkboxes: termsOfUse.checkboxes.map((c) => ({ text: c })),
      userTypeId: termsOfUse.userType.id,
    };
    return DefaultDataSourceMethods.create(
      this.http,
      this.controller,
      this.deserializer,
      this.js,
      payload as any,
    );
  }
}

export abstract class TermsOfUseSettingsDataSource {
  abstract getLatestTermsOfUseByUserTypeId(
    userTypeId: string,
  ): Observable<TermsOfUse | null>;

  abstract setTermsOfUse(termsOfUse: TermsOfUse): Observable<TermsOfUse>;

  abstract getTermsOfUseById(id: string): Observable<TermsOfUse>;

  abstract getTermsOfUseVersionsByUserTypeId(
    userTypeId: string,
  ): Observable<Pagination<TermsOfUse>>;

  abstract getAcceptanceStateForUserById(
    userId: string,
  ): Observable<TermsOfUseAcceptance | null>;
}

export const provideTermsOfUseSettingsDataSource = () => ({
  provide: TermsOfUseSettingsDataSource,
  useClass: RemoteTermsOfUseSettingsDataSourceDefaultImpl,
});
