import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { PopupDialogComponent } from './popup-dialog/popup-dialog.component';
import { PopupBottomSheetComponent } from './popup-bottom-sheet/popup-bottom-sheet.component';

/**
 * This class is a wrapper around the cdk dialog ref class to expose a similar api like the material dialog ref class
 * https://github.com/angular/components/blob/main/src/material/dialog/dialog-ref.ts
 */
@Injectable()
export class PopupRef<C, R = any> {
  /** Subject for notifying the user that the dialog has finished opening. */
  private readonly _afterOpened = new Subject<void>();

  /**
   * disable close by clicking on the backdrop overlay
   */
  set disableClose(value: boolean) {
    this.ref.disableClose = value;
  }

  get disableClose(): boolean {
    return !!this.ref.disableClose;
  }

  get instance(): C | null {
    if (this.ref instanceof MatDialogRef) {
      return this.ref.componentInstance.componentRef.instance;
    }
    return this.ref.instance.componentRef.instance;
  }

  constructor(
    public ref:
      | MatDialogRef<PopupDialogComponent<C>, R>
      | MatBottomSheetRef<PopupBottomSheetComponent<C>, R>,
  ) {
    this._afterOpened.next();
    this._afterOpened.complete();
  }

  /**
   * Close the dialog.
   * @param dialogResult Optional result to return to the dialog opener.
   */
  close(result?: R | undefined) {
    if (this.ref instanceof MatBottomSheetRef) return this.ref.dismiss(result);
    return this.ref.close(result);
  }

  /**
   * Gets an observable that is notified when the dialog is finished opening.
   */
  afterOpened(): Observable<void> {
    return this._afterOpened.asObservable();
  }

  /**
   * Gets an observable that is notified when the dialog is finished closing.
   */
  afterClosed(): Observable<R | undefined> {
    if (this.ref instanceof MatBottomSheetRef) {
      return this.ref.afterDismissed();
    }
    return this.ref.afterClosed();
  }

  /**
   * Gets an observable that emits when the overlay's backdrop has been clicked.
   */
  backdropClick(): Observable<MouseEvent> {
    return this.ref.backdropClick();
  }

  /**
   * Gets an observable that emits when keydown events are targeted on the overlay.
   */
  keydownEvents(): Observable<KeyboardEvent> {
    return this.ref.keydownEvents();
  }

  /** Add a CSS class or an array of classes to the overlay pane. */
  addPanelClass(classes: string | string[]): this {
    if (this.ref instanceof MatBottomSheetRef) return this;
    this.ref.addPanelClass(classes);
    return this;
  }

  /** Remove a CSS class or an array of classes from the overlay pane. */
  removePanelClass(classes: string | string[]): this {
    if (this.ref instanceof MatBottomSheetRef) return this;
    this.ref.removePanelClass(classes);
    return this;
  }
}
