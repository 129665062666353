import { Component } from '@angular/core';
import { SharedFeatureEventTemplateFeatureDataTableModule } from './shared-feature-event-template-feature-data-table.module';

@Component({
  selector: 'tremaze-event-template-list-page',
  standalone: true,
  imports: [SharedFeatureEventTemplateFeatureDataTableModule],
  template: ` <tremaze-event-template-data-table></tremaze-event-template-data-table>`,
  styles: [],
})
export class EventTemplateDataTablePageComponent {}
