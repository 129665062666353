import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Institution } from '@tremaze/shared/feature/institution/types';

@staticImplements<Deserializable<Specialization>>()
export class Specialization {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly institution?: Institution,
    readonly description?: string,
  ) {}

  static deserialize(input: any): Specialization {
    return new Specialization(
      input.id,
      input.name,
      Institution.deserialize(input.institution),
      input.description,
    );
  }
}
