import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupDialogComponent } from './popup-dialog/popup-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PopupBottomSheetComponent } from './popup-bottom-sheet/popup-bottom-sheet.component';
import { PopupCloseDirective } from './popup-close.directive';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { PopupActionsComponent } from './popup-actions/popup-actions.component';
import { PopupService } from './popup.service';
import { PopupContentComponent } from './popup-content.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatBottomSheetModule],
  declarations: [
    PopupDialogComponent,
    PopupBottomSheetComponent,
    PopupCloseDirective,
    PopupActionsComponent,
    PopupContentComponent,
  ],
  exports: [PopupCloseDirective, PopupActionsComponent, PopupContentComponent],
  providers: [PopupService],
})
export class SharedUiPopupModule {}
