import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Meta } from '@tremaze/shared/models';
import { TremazeDate } from '@tremaze/shared/util-date';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { RssItem } from '@tremaze/shared/feature/rss/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { Department } from '@tremaze/shared/feature/department/types';
import { User, UserType } from '@tremaze/shared/feature/user/types';
import { Category } from '@tremaze/shared/feature/category/types';
import { Division } from '@tremaze/shared/feature/division/types';

@staticImplements<Deserializable<Information>>()
export class Information implements RssItem {
  constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public title?: string,
    public description?: string,
    public showFrom?: TremazeDate,
    public showUntil?: TremazeDate,
    public published?: boolean,
    public titleImage?: FileStorage,
    public tags: InformationTag[] = [],
    public informationFiles: FileStorage[] = [],
    public informationMedia: FileStorage[] = [],
    readonly rssGuid?: string,
    public institutions: Institution[] = [],
    public departments: Department[] = [],
    public users: User[] = [],
    public category?: Category,
    public subtitle?: string,
    public visibleForFamily?: boolean,
    public isPublic?: boolean,
    public userTypes: UserType[] = [],
    public secure = false,
    public contextInstitution?: Institution,
    public divisions: Division[] = [],
  ) {}

  get clients(): User[] {
    return this.users.filter((u) => u.isClient);
  }

  get employees(): User[] {
    return this.users.filter((u) => u.isEmployee);
  }

  get isRssItem(): boolean {
    return !!this.rssGuid;
  }

  get instIds(): string[] {
    return this.institutions?.map((i) => i.id) ?? [];
  }

  get departmentInstIds(): string[] {
    return (
      this.departments?.map((d) => d.institution?.id)?.filter((r) => !!r) ?? []
    );
  }

  private static _extractUniqueInstIds(users: User[]): string[] {
    const result = new Set<string>();
    for (const user of users) {
      if (user.userInstitutions?.length) {
        for (const { institution } of user.userInstitutions) {
          result.add(institution.id);
        }
      }
    }
    return [...result];
  }

  get userInstIds(): string[] {
    return Information._extractUniqueInstIds(this.users);
  }

  get clientsInstIds(): string[] {
    return Information._extractUniqueInstIds(this.clients);
  }

  get employeesInstIds(): string[] {
    return Information._extractUniqueInstIds(this.employees);
  }

  get userIds(): string[] {
    return this.users?.map((u) => u.id) ?? [];
  }

  get instIdsWithDepartmentsAndUsers(): string[] {
    const { instIds, departmentInstIds, userInstIds } = this;
    return [...new Set([...instIds, ...departmentInstIds, ...userInstIds])];
  }

  static deserialize(data: any): Information {
    return !data
      ? null
      : new Information(
          data.id,
          Meta.deserialize(data),
          data.title,
          data.description,
          TremazeDate.deserialize(data.showFrom),
          TremazeDate.deserialize(data.showUntil),
          data.published,
          FileStorage.deserialize(data.titleImage),
          data.tags?.map(InformationTag.deserialize) || [],
          data.informationFiles?.map(FileStorage.deserialize) || [],
          data.informationMedia?.map(FileStorage.deserialize) || [],
          data.rssGuid,
          data.institutions?.map(Institution.deserialize) ?? [],
          data.departments?.map(Department.deserialize),
          data.users?.map(User.deserialize) ?? [],
          Category.deserialize(data.category),
          data.subtitle,
          data.visibleForFamily,
          data.isPublic ?? data.public,
          data.userTypes ?? [],
          data.secure ?? false,
          Institution.deserialize(data.contextInstitution),
          data.divisions?.map(Division.deserialize) ?? [],
        );
  }
}

@staticImplements<Deserializable<InformationTag>>()
export class InformationTag {
  constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public name?: string,
    readonly standardTag?: boolean,
  ) {}

  static deserialize(data: any): InformationTag {
    return !data
      ? null
      : new InformationTag(
          data.id,
          Meta.deserialize(data.meta),
          data.name,
          data.standardTag,
        );
  }
}
