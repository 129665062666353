import { Meta } from '@tremaze/shared/models';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

@staticImplements<Deserializable<Gender>>()
export class Gender {
  constructor(
    readonly id: string,
    readonly meta: Meta,
    readonly name: string,
    readonly shortName: string,
    readonly sortNo: number,
  ) {}

  static deserialize(data: any): Gender {
    return !data
      ? null
      : new Gender(
          data.id,
          Meta.deserialize(data.meta),
          data.name,
          data.shortName,
          data.sortNo,
        );
  }
}
