import { CustomFormFieldValue } from './value';
import { Meta } from '@tremaze/shared/models';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

@staticImplements<Deserializable<CustomTextBoxFormFieldValue>>()
export class CustomTextBoxFormFieldValue
  implements CustomFormFieldValue<string>
{
  readonly fieldType = 'TEXTBOX';

  constructor(
    readonly id: string,
    readonly meta: Meta,
    readonly value: string,
  ) {}

  static deserialize(data: any): CustomTextBoxFormFieldValue {
    return new CustomTextBoxFormFieldValue(
      data.id,
      Meta.deserialize(data),
      data.value,
    );
  }

  toDisplayString(): string {
    return this.value;
  }
}
