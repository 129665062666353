import { inject, Injectable } from '@angular/core';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { firstValueFrom } from 'rxjs';
import { NotificationService } from '@tremaze/shared/notification';
import { FolderViewEventsService } from './folder-view-events.service';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';

@Injectable()
export class FolderViewDeletionService {
  private readonly _notificationService = inject(NotificationService);
  private readonly _fileStorageService = inject(FileStorageService);
  private readonly _eventsService = inject(FolderViewEventsService);

  async deleteFile(file: FileStorage) {
    await firstValueFrom(this._fileStorageService.deleteFiles([file]));

    this._notificationService.showNotification('Datei gelöscht');
    this._eventsService.refreshCurrentFiles$.next();
  }

  async deleteDirectory(dir: DirStorage) {
    await firstValueFrom(this._fileStorageService.deleteFolders([dir]));

    this._notificationService.showNotification('Verzeichnis gelöscht');
    this._eventsService.refreshCurrentSubDirectories$.next();
  }

  async deleteFilesAndDirectories(files: FileStorage[], dirs: DirStorage[]) {
    if (!files.length && !dirs.length) {
      return;
    }

    await firstValueFrom(
      this._fileStorageService.deleteFilesAndDirectories(files, dirs),
    );

    const deletedLabels: string[] = [];

    if (files.length) {
      deletedLabels.push('Dateien');
      this._eventsService.refreshCurrentFiles$.next();
    }

    if (dirs.length) {
      deletedLabels.push('Ordner');
      this._eventsService.refreshCurrentSubDirectories$.next();
    }

    this._notificationService.showNotification(
      `${deletedLabels.join(' und ')} gelöscht`,
    );
  }
}
