import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialsCircleComponent } from './initials-circle.component';

@NgModule({
  imports: [CommonModule],
  declarations: [InitialsCircleComponent],
  exports: [InitialsCircleComponent],
})
export class SharedUiInitialsCircleModule {}
