export type DurationUnit =
  | 'milliseconds'
  | 'seconds'
  | 'minutes'
  | 'hours'
  | 'days'
  | 'weeks';

export class Duration {
  constructor(
    /**
     * The value of the duration in milliseconds.
     */
    private _value: number,
  ) {}

  private static _conversionFactors: { [key in DurationUnit]: number } = {
    milliseconds: 1,
    seconds: 1000,
    minutes: 1000 * 60,
    hours: 1000 * 60 * 60,
    days: 1000 * 60 * 60 * 24,
    weeks: 1000 * 60 * 60 * 24 * 7,
  };

  static from(value: number, unit: DurationUnit) {
    return new Duration(value * this._conversionFactors[unit]);
  }

  static fromMilliseconds(value: number) {
    return Duration.from(value, 'milliseconds');
  }

  static fromSeconds(value: number) {
    return Duration.from(value, 'seconds');
  }

  static fromMinutes(value: number) {
    return Duration.from(value, 'minutes');
  }

  static fromHours(value: number) {
    return Duration.from(value, 'hours');
  }

  static fromDays(value: number) {
    return Duration.from(value, 'days');
  }

  static fromWeeks(value: number) {
    return Duration.from(value, 'weeks');
  }

  static fromHHMM(value: string) {
    const [hours, minutes] = value.split(':').map(Number);
    return Duration.fromHours(hours).add(Duration.fromMinutes(minutes));
  }

  static getUnitLabel(unit: DurationUnit, value: number) {
    const plural = value !== 1;
    switch (unit) {
      case 'milliseconds':
        return plural ? 'Millisekunden' : 'Millisekunde';
      case 'seconds':
        return plural ? 'Sekunden' : 'Sekunde';
      case 'minutes':
        return plural ? 'Minuten' : 'Minute';
      case 'hours':
        return plural ? 'Stunden' : 'Stunde';
      case 'days':
        return plural ? 'Tage' : 'Tag';
      case 'weeks':
        return plural ? 'Wochen' : 'Woche';
    }
  }

  get largestUnitWithoutRemainder(): DurationUnit {
    if (this._value % Duration._conversionFactors.weeks === 0) {
      return 'weeks';
    }
    if (this._value % Duration._conversionFactors.days === 0) {
      return 'days';
    }
    if (this._value % Duration._conversionFactors.hours === 0) {
      return 'hours';
    }
    if (this._value % Duration._conversionFactors.minutes === 0) {
      return 'minutes';
    }
    return 'seconds';
  }

  get largestUnitWithValueGreaterOrEqualOne(): DurationUnit {
    if (this.inWeeks >= 1) {
      return 'weeks';
    }
    if (this.inDays >= 1) {
      return 'days';
    }
    if (this.inHours >= 1) {
      return 'hours';
    }
    if (this.inMinutes >= 1) {
      return 'minutes';
    }
    if (this.inSeconds >= 1) {
      return 'seconds';
    }
    return 'milliseconds';
  }

  clone() {
    return new Duration(this._value);
  }

  in(unit: DurationUnit, round: 'round' | 'floor' | 'ceil' | false = 'floor') {
    const value = this._value / Duration._conversionFactors[unit];
    switch (round) {
      case 'round':
        return Math.round(value);
      case 'floor':
        return Math.floor(value);
      case 'ceil':
        return Math.ceil(value);
      default:
        return value;
    }
  }

  get inMilliseconds() {
    return this._value;
  }

  get inSeconds() {
    return this.in('seconds');
  }

  get inMinutes() {
    return this.in('minutes');
  }

  get inHours() {
    return this.in('hours');
  }

  get inDays() {
    return this.in('days');
  }

  get inWeeks() {
    return this.in('weeks');
  }

  get asHHMM() {
    const hours = this.inHours;
    const minutes = this.inMinutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  }

  get isZero() {
    return this._value === 0;
  }

  add(duration: Duration) {
    this._value += duration._value;
    return this;
  }

  subtract(duration: Duration) {
    this._value -= duration._value;
    return this;
  }

  ceil(unit: DurationUnit) {
    const factor = Duration._conversionFactors[unit];
    this._value = Math.ceil(this._value / factor) * factor;
    return this;
  }

  floor(unit: DurationUnit) {
    const factor = Duration._conversionFactors[unit];
    this._value = Math.floor(this._value / factor) * factor;
    return this;
  }

  round(unit: DurationUnit) {
    const factor = Duration._conversionFactors[unit];
    this._value = Math.round(this._value / factor) * factor;
    return this;
  }

  format(): string {
    if (this._value <= 0) {
      return '';
    }

    const unit = this.largestUnitWithValueGreaterOrEqualOne;

    const value = this.in(unit);
    const duration = Duration.from(value, unit);
    // recusively format the duration
    const n = this.subtract(duration).format();
    const r = [
      `${duration.in(unit, 'round')} ${Duration.getUnitLabel(unit, value)}`,
    ];

    if (n.length) {
      r.push(n);
    }
    return r.join(' ');
  }

  equals(duration: Duration) {
    return this._value === duration._value;
  }
}
