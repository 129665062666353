import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureAssistantDirective } from './feature-assistant.directive';
import { FeatureAssistantDialogComponent } from './feature-assistant-dialog/feature-assistant-dialog.component';
import { SharedUiPopupModule } from '@tremaze/shared/ui/popup';
import { MatButtonModule } from '@angular/material/button';
import { FeatureAssistantService } from './feature-assistant.service';

@NgModule({
  imports: [CommonModule, SharedUiPopupModule, MatButtonModule],
  declarations: [FeatureAssistantDirective, FeatureAssistantDialogComponent],
  exports: [FeatureAssistantDirective],
  providers: [FeatureAssistantService],
})
export class SharedUiFeatureAssistantModule {}
