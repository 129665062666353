import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  Observable,
  of,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { Department } from '@tremaze/shared/feature/department/types';
import { User, UserType } from '@tremaze/shared/feature/user/types';
import { mergeMap } from 'rxjs/operators';
import {
  mapAnyTrue,
  mapEveryTrue,
  negateBool,
} from '@tremaze/shared/util/rxjs';

interface InitParams {
  hasGlobalLevelPrivileges$: Observable<boolean>;
  hasInstitutionLevelPrivileges$: Observable<boolean>;
  hasDepartmentLevelPrivileges$: Observable<boolean>;
  hasUserLevelPrivileges$: Observable<boolean>;

  institutionValue$: Observable<Institution[]>;
  departmentValue$: Observable<Department[]>;
  userValue$: Observable<User[]>;
  userTypesValue$: Observable<UserType[]>;
}

/**
 * Helper service for components that edit objects which are "assignable". Meaning they can be assigned to institutions, departments, users, userTypes.
 */
@Injectable()
export class AssignableEditService {
  private _destroyed$ = new Subject<void>();

  private _initialized = false;

  get initialized(): boolean {
    return this._initialized;
  }

  private _disableInstitutionSelector$: BehaviorSubject<boolean> =
    new BehaviorSubject(true);

  get disableInstitutionSelector$(): Observable<boolean> {
    return this._disableInstitutionSelector$;
  }

  private _disableDepartmentSelector$: BehaviorSubject<boolean> =
    new BehaviorSubject(true);

  get disableDepartmentSelector$(): Observable<boolean> {
    return this._disableDepartmentSelector$;
  }

  private _disableUserSelector$: BehaviorSubject<boolean> = new BehaviorSubject(
    true,
  );

  get disableUserSelector$(): Observable<boolean> {
    return this._disableUserSelector$;
  }

  private _disableUserTypesSelector$: BehaviorSubject<boolean> =
    new BehaviorSubject(true);

  get disableUserTypesSelector$(): Observable<boolean> {
    return this._disableUserTypesSelector$;
  }

  private _showInstitutionSelector$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  get showInstitutionSelector$(): Observable<boolean> {
    return this._showInstitutionSelector$;
  }

  private _showDepartmentSelector$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  get showDepartmentSelector$(): Observable<boolean> {
    return this._showDepartmentSelector$;
  }

  private _showUserTypeSelector$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  get showUserTypeSelector$(): Observable<boolean> {
    return this._showUserTypeSelector$;
  }

  private _showUserSelector$: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  get showUserSelector$(): Observable<boolean> {
    return this._showUserSelector$;
  }

  /**
   * Initializes this service. Should only be called once per instance.
   * @param hasGlobalLevelPrivileges$
   * @param hasInstitutionLevelPrivileges$
   * @param hasDepartmentLevelPrivileges$
   * @param hasUserLevelPrivileges$
   * @param institutionValue$
   * @param departmentValue$
   * @param userValue$
   * @param userTypesValue$
   */
  init({
    hasGlobalLevelPrivileges$,
    hasInstitutionLevelPrivileges$,
    hasDepartmentLevelPrivileges$,
    hasUserLevelPrivileges$,
    institutionValue$,
    departmentValue$,
    userValue$,
    userTypesValue$,
  }: InitParams): void {
    this._initialized = true;
    institutionValue$
      .pipe(
        mergeMap((v) => (v.length ? of(true) : hasInstitutionLevelPrivileges$)),
        distinctUntilChanged(),
        tap((v) => this._showInstitutionSelector$.next(v)),
        takeUntil(this._destroyed$),
      )
      .subscribe();
    hasDepartmentLevelPrivileges$
      .pipe(
        distinctUntilChanged(),
        negateBool(),
        tap((v) => this._disableDepartmentSelector$.next(v)),
        takeUntil(this._destroyed$),
      )
      .subscribe();

    departmentValue$
      .pipe(
        mergeMap((v) => (v.length ? of(true) : hasDepartmentLevelPrivileges$)),
        distinctUntilChanged(),
        tap((v) => this._showDepartmentSelector$.next(v)),
        takeUntil(this._destroyed$),
      )
      .subscribe();
    hasUserLevelPrivileges$
      .pipe(
        distinctUntilChanged(),
        negateBool(),
        tap((v) => this._disableUserSelector$.next(v)),
        takeUntil(this._destroyed$),
      )
      .subscribe();
    userValue$
      .pipe(
        mergeMap((v) => (v.length ? of(true) : hasUserLevelPrivileges$)),
        distinctUntilChanged(),
        tap((v) => this._showUserSelector$.next(v)),
        takeUntil(this._destroyed$),
      )
      .subscribe();
    userTypesValue$
      .pipe(
        mergeMap((v) =>
          v.length
            ? of(true)
            : combineLatest([
                hasInstitutionLevelPrivileges$,
                hasDepartmentLevelPrivileges$,
                hasGlobalLevelPrivileges$,
              ]).pipe(mapAnyTrue()),
        ),
        distinctUntilChanged(),
        tap((v) => this._showUserTypeSelector$.next(v)),
        takeUntil(this._destroyed$),
      )
      .subscribe();

    hasInstitutionLevelPrivileges$
      .pipe(
        distinctUntilChanged(),
        negateBool(),
        tap((v) => this._disableInstitutionSelector$.next(v)),
        takeUntil(this._destroyed$),
      )
      .subscribe();
    combineLatest([
      this.disableInstitutionSelector$,
      this.disableDepartmentSelector$,
      hasGlobalLevelPrivileges$.pipe(negateBool()),
    ])
      .pipe(
        mapEveryTrue(),
        distinctUntilChanged(),
        tap((v) => this._disableUserTypesSelector$.next(v)),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  destroy(): void {
    this._initialized = false;
    this._disableUserSelector$.complete();
    this._disableUserTypesSelector$.complete();
    this._disableDepartmentSelector$.complete();
    this._disableInstitutionSelector$.complete();
    this._showInstitutionSelector$.complete();
    this._showDepartmentSelector$.complete();
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
