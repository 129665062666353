import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DragCounterReferenceService {
  private _rendererFactory = inject(RendererFactory2);
  private _renderer: Renderer2 = this._rendererFactory.createRenderer(
    'window',
    null,
  );

  _dragCounter = 0;

  get isDragging() {
    return this._dragCounter > 0;
  }

  private _onDragStart() {
    this._dragCounter++;
  }

  private _onDragEnd() {
    this._dragCounter--;
  }

  constructor() {
    this._renderer.listen('window', 'dragenter', this._onDragStart.bind(this));
    this._renderer.listen('window', 'dragleave', this._onDragEnd.bind(this));
    this._renderer.listen('window', 'dragover', (event: Event) =>
      event.preventDefault(),
    );

    this._renderer.listen('window', 'drop', () => {
      this._dragCounter = 0;
    });
  }
}
