import {
  CustomFormFieldType,
  MultiSelectType,
} from '@tremaze/shared/feature/custom-forms/types';

export const fieldTypeOptions: { [key in CustomFormFieldType]: string } = {
  FILE_UPLOAD: 'Datei',
  MULTISELECT: 'Auswahl',
  TEXTBOX: 'Textbox',
};

export const fieldTypeIcons: { [key in CustomFormFieldType]: string } = {
  FILE_UPLOAD: 'lnr lnr-file-empty',
  MULTISELECT: 'lnr lnr-list3',
  TEXTBOX: 'lnr lnr-text-align-left',
};

export const multiSelectFieldTypeOptions: { [key in MultiSelectType]: string } =
  {
    MULTISELECT: 'Checkboxes',
    RADIOBUTTON: 'Radiobutton',
    DROPDOWN: 'Dropdown',
  };

export const multiSelectFieldTypeIcons: { [key in MultiSelectType]: string } = {
  MULTISELECT: 'lnr lnr-list3',
  RADIOBUTTON: 'lnr lnr-radio-button',
  DROPDOWN: 'lnr lnr-menu3',
};
