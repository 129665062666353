import { Injectable } from '@angular/core';
import { RemoteCCReferencePersonDataSource } from '@tremaze/shared/feature/reference-person/data-access';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import {
  distinctUntilKeyChanged,
  interval,
  merge,
  Observable,
  ReplaySubject,
  skip,
  startWith,
  Subject,
  switchMap,
  throttleTime,
} from 'rxjs';
import { User } from '@tremaze/shared/feature/user/types';
import { map, take } from 'rxjs/operators';
import { bindTo } from '@tremaze/shared/util/rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReferencePersonService {
  private readonly _reloadEvent$ = new Subject<void>();
  private _ownReferenceClients$ = new ReplaySubject<User[]>(1);

  constructor(
    private dataSource: RemoteCCReferencePersonDataSource,
    private authService: AuthV2Service,
  ) {
    merge(
      this._reloadEvent$,
      this.authService.authenticatedUser$.pipe(
        distinctUntilKeyChanged('userId'),
      ),
      this.authService.activeTenant$.pipe(distinctUntilKeyChanged('id')),
      interval(10000),
    )
      .pipe(
        startWith(null),
        throttleTime(1000),
        switchMap(() =>
          this.dataSource.getAllReferenceClientsForAuthenticatedUser(),
        ),
        bindTo(this._ownReferenceClients$),
      )
      .subscribe();
  }

  readonly ownReferenceClients$: Observable<User[]> =
    this._ownReferenceClients$;

  reload(): Observable<User[]> {
    this._reloadEvent$.next();
    return this._ownReferenceClients$.pipe(skip(1), take(1));
  }

  getSeeded(seed: string[]): Observable<User[]> {
    return this.ownReferenceClients$.pipe(
      map((persons) => {
        return persons.filter(({ id }) => seed.includes(id));
      }),
    );
  }

  /**
   * Determines whether a user is my reference client for the specified institution
   * @param userId
   * @param instId
   */
  isUserOwnReferenceClientForInstitution$(
    userId: string,
    instId: string,
  ): Observable<boolean> {
    return this.ownReferenceClients$.pipe(
      take(1),
      map(
        (clients) =>
          clients?.some(
            (client) => userId === client.id && client.instIds.includes(instId),
          ) ?? false,
      ),
    );
  }

  /**
   * Returns a list of institution ids of current auth user's own reference clients
   * @param seed a list of user ids to which to limit the selection to
   */
  getUniqueInstitutionIdList(seed?: string[]): Observable<string[]> {
    return this._ownReferenceClients$.pipe(
      take(1),
      map((persons) => persons.filter(({ id }) => !seed || seed.includes(id))),
      map((persons) => {
        const personInstIds = ([] as string[]).concat(
          ...persons.map((p) => p.instIds),
        );
        const result = new Set<string>(personInstIds);
        return [...result];
      }),
    );
  }
}
