import { Component, Input } from '@angular/core';

@Component({
  selector: 'tremaze-table-add-new-item-button',
  template: ` <button
    mat-stroked-button
    type="button"
    color="accent"
    style="width: 100%; margin-top: 10px; display: flex; justify-content: center; align-items: center"
  >
    <span [class]="iconOverride || 'lnr lnr-plus-square'"></span>&nbsp;
    <span>
      {{ label || 'Neuer Eintrag' }}
    </span>
  </button>`,
})
export class TableAddNewItemButtonComponent {
  @Input() label: string;
  @Input() iconOverride;
}
