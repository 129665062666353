<ng-container [formGroup]="formGroup">
  <div class="form-grid">
    <div
      class="form-grid-item-full-width"
      style="display: flex; flex-direction: column; align-items: flex-start"
    >
      <mat-checkbox
        formControlName="registrationNecessary"
        *ngIf="showRegistrationNecessaryCheckbox"
        >Anmeldung ist notwendig
      </mat-checkbox>

      <mat-checkbox
        *ngIf="canSelectPublicCheckbox"
        formControlName="isPublic"
        matTooltip="Nicht öffentliche Veranstaltugen sind ausschließlich für eigeloggte Nutzer sichtbar"
      >
        Ist öffentlich
      </mat-checkbox>

      <mat-checkbox
        *ngIf="canSelectVisibleOnlyForEmployeesCheckbox"
        (change)="onChangeOnlyForEmployeesCheckbox($event.checked)"
        [disabled]="formGroup.controls.userTypes.disabled"
        [checked]="isChangeOnlyForEmployeesCheckboxChecked$ | async"
        >Nur für Mitarbeitende sichtbar
      </mat-checkbox>

      <mat-checkbox
        *ngIf="canSelectVisibleForFamilyCheckbox"
        formControlName="visibleForFamily"
        >Sichtbar für alle Familienmitglieder der zugewiesenen Teilnehmer*Innen
      </mat-checkbox>

      @if (canCreateVideoMeeting) {
        <mat-checkbox
          formControlName="hasVideoMeeting"
          *ifModuleEnabled="'VIDEO_MEETING'"
          matTooltip="Online-Termine wird ein Video-Meeting raum hinzugefügt"
          >Ist ein Online-Video-Termin
        </mat-checkbox>
      }

      <mat-checkbox
        formControlName="hideWhenFull"
        tremazeFeatureAssistant
        *ifModuleEnabled="'SPECIALIZATION'"
        featureName="Terminoptionen"
        featureAssistantMessage="Terminoptionen sind für Klient*innen so lange sichtbar, bis keine Plätze mehr vorhanden sind."
        featureAssistantAlignment="centerRight"
        featureAssistantXOffset="20px"
        featureAssistantYOffset="1px"
      >
        Ist Terminoption
      </mat-checkbox>
    </div>
  </div>
</ng-container>
