import { FilePreviewOverlayService } from '@tremaze/shared/feature/file-storage/ui/file-preview-overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FileStorage,
  FileStorageCompression,
} from '@tremaze/shared/feature/file-storage/types';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';

@UntilDestroy({ arrayName: 'subs' })
@Component({
  selector: 'tremaze-single-file-display',
  template: `
    <ng-container *ngIf="file?.id" [ngSwitch]="file.type">
      <ng-container *ngIf="getUrl() | async; let __url">
        <img
          class="img"
          (click)="onClick()"
          *ngSwitchCase="'IMAGE'"
          [alt]="alt || file.fileViewname"
          #imageElement
          [src]="__url"
          [style.cursor]="canShowPreviewOverlay ? 'pointer' : 'normal'"
          [style.objectFit]="fit"
          [draggable]="draggable"
        />
        <video *ngSwitchCase="'VIDEO'" [src]="__url" class="img"></video>
      </ng-container>
      <div
        class="img"
        style="position: absolute; display: flex; justify-content: center; align-items: center"
        *ngSwitchDefault
      >
        <strong>{{ extension.toUpperCase() }}</strong>
      </div>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: contents;
      }

      .img {
        height: 100%;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleFileDisplayComponent implements OnInit, AfterViewInit {
  @Input() file: FileStorage;
  @Input() canShowPreviewOverlay = true;
  @Input() fit: 'cover' | 'contain' = 'cover';
  @Input() url: string;
  @Input() alt: string;
  @Input() systemDirType: string;
  @Input() compression: FileStorageCompression = 'SMALL';
  @Output() aspectRatioUpdated = new EventEmitter<number>();
  @Input() targetWidth: number;
  @Input() draggable = true;
  private subs: Subscription[] = [];

  @ViewChild('imageElement') private imageElement: ElementRef<HTMLImageElement>;

  constructor(
    @Optional() private fileStorageService: FileStorageService,
    @Optional() private _filePreviewOverlayService: FilePreviewOverlayService,
  ) {}

  getUrl(fullWidth?: boolean): Observable<string> {
    if (this.url) {
      return of(this.url);
    }
    return this.fileStorageService
      .getFileDownloadURL(
        this.file,
        fullWidth ? null : (this.targetWidth ?? 1080),
      )
      .pipe(
        map((url) => {
          if (!this.targetWidth) {
            return url;
          }
          return url;
        }),
      );
  }

  get isImage() {
    return this.file?.type === 'IMAGE';
  }

  get extension() {
    return this.file?.filename?.split('.')?.[1] || '';
  }

  ngAfterViewInit() {
    if (this.imageElement) {
      this.subs.push(
        fromEvent(this.imageElement.nativeElement, 'load').subscribe((r) => {
          const h = this.imageElement.nativeElement.height;
          const w = this.imageElement.nativeElement.width;
          this.aspectRatioUpdated.emit(w / h);
        }),
      );
    }
  }

  ngOnInit() {
    if (!this.fileStorageService && !this.url) {
      console.warn(
        'SingleFileDisplayComponent needs either a file & SingleFileDisplayComponent or url',
      );
    }
  }

  async onClick() {
    if (this.canShowPreviewOverlay) {
      if (
        this._filePreviewOverlayService instanceof FilePreviewOverlayService
      ) {
        const url = await this.getUrl(true).toPromise();
        this._filePreviewOverlayService.open({
          data: { url, name: this.file.fileViewname, type: this.file.type },
        });
      } else {
        throw new Error('Missing provider of FilePreviewOverlayService');
      }
    }
  }
}
