import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthV2Service } from './auth-service';
import { AuthOidcService } from './auth-oidc.service';
import { AuthInterceptor } from './auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AuthV2DataSource,
  RemoteOIDCAuthV2DataSource,
} from '@tremaze/shared/core/auth-v2/data-access';

@NgModule({
  imports: [
    CommonModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          'http://localhost:4200/api',
          'https://api.dev.cloud.tagea.app',
          'https://api.qs.cloud.tagea.app',
          'https://api.staging.tagea.app',
          'https://api.tagea.app',
        ],
        sendAccessToken: true,
      },
    }),
  ],
})
export class SharedCoreAuthV2Module {
  static forRoot() {
    return {
      ngModule: SharedCoreAuthV2Module,
      providers: [
        {
          provide: AuthV2DataSource,
          useExisting: RemoteOIDCAuthV2DataSource,
        },
        {
          provide: AuthV2Service,
          useClass: AuthOidcService,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (ds: AuthV2Service) => () => ds.init(),
          deps: [AuthV2Service],
          multi: true,
        },
      ],
    };
  }
}
