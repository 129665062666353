import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  CustomFormFieldsAbstractControl,
  CustomFormsFileFieldType,
  CustomFormsMultiSelectFieldType,
  CustomFormsMultiSelectItemFieldType,
  CustomFormsTextBoxFieldType,
  CustomFromFieldBaseAbstractControl,
  FormGroupModel,
} from './form-models';
import {
  CustomFileFormField,
  CustomFormField,
  CustomFormFieldType,
  CustomMultiSelectFormField,
  CustomMultiSelectFormFieldItem,
  CustomTextBoxFormField,
  MultiSelectType,
} from '@tremaze/shared/feature/custom-forms/types';
import { TremazeValidators } from '@tremaze/shared/util/form';
import { CustomFormEditValueObserverService } from './custom-form-edit-value-observer.service';
import { uuid } from '@tremaze/shared/util-generators';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { isNotEmpty } from '@tremaze/shared/util-utilities';

const multiSelectItemValueRegex = /^[a-zA-Z0-9_-]+$/;

const multiSelectItemValueValidator: ValidatorFn = (control) => {
  if (!multiSelectItemValueRegex.test(control.value)) {
    return { pattern: true };
  }
  return null;
};

/**
 * Service to build the form group and form controls for the custom form edit form.
 */
@Injectable({
  providedIn: 'any',
})
export class CustomFormEditFormBuilderService {
  static readonly institutionSelectionValidator =
    TremazeValidators.minLengthArray(1);

  private readonly _fb = new FormBuilder();

  buildInstitutionsFormControl() {
    return this._fb.control(
      [],
      CustomFormEditFormBuilderService.institutionSelectionValidator,
    ) as FormControl<Institution[]>;
  }

  buildForm() {
    const group: FormGroupModel = {
      name: new FormControl('', Validators.required),
      fields: this._fb.array<FormGroup<CustomFormFieldsAbstractControl>>([]),
      published: this._fb.control(true),
      institutions: this.buildInstitutionsFormControl(),
      feature: this._fb.control(null, Validators.required),
    };
    return this._fb.group<FormGroupModel>(group);
  }

  constructor(
    private readonly _valueObserverService: CustomFormEditValueObserverService,
  ) {}

  /**
   * TODO: make this not need a switch (polymorphism?) and make it not need to be updated when new field types are added.
   * Creates a form group for the given field type. Sets up value observers via {@link CustomFormEditValueObserverService} where needed.
   * @param type The type of field to create a form group for.
   * @param seedValue The seed value to use to populate the form group.
   */
  public createFormFieldFormGroup(
    type: CustomFormFieldType,
    isInitialPopulate = false,
    seedValue?: CustomFormField<unknown>,
  ): FormGroup<CustomFormFieldsAbstractControl> {
    let newGroup: FormGroup<CustomFormFieldsAbstractControl>;
    switch (type) {
      case 'TEXTBOX':
        newGroup = this._createTextBoxFormGroup(
          seedValue as CustomTextBoxFormField,
        );
        break;
      case 'MULTISELECT':
        newGroup = this._createMultiSelectFormGroup(
          isInitialPopulate,
          seedValue as unknown as CustomMultiSelectFormField<unknown>,
        );
        break;
      case 'FILE_UPLOAD':
        newGroup = this._createFileFormGroup(seedValue as CustomFileFormField);
        break;
    }
    this._valueObserverService.setUpFormFieldValueObserver(newGroup);
    return newGroup;
  }

  /**
   * Creates the base form model for a custom form field. All fields must extend this model.
   * @param fieldType The type of field to create the base form model for.
   * @param seedValue The seed value to use to populate the form model.
   * @private
   */
  private _createBaseFormModel<T>(
    fieldType?: CustomFormFieldType,
    seedValue?: CustomFormField<unknown>,
  ): CustomFromFieldBaseAbstractControl<T> {
    return {
      name: new FormControl(seedValue?.name ?? '', Validators.required),
      fieldType: new FormControl<CustomFormFieldType | null>(
        fieldType ?? 'TEXTBOX',
      ),
      required: new FormControl<boolean | null>(seedValue?.required ?? false),
      label: new FormControl<string | null>(
        seedValue?.label ?? '',
        Validators.required,
      ),
      sort: new FormControl<number>(seedValue?.sort ?? 0, Validators.required),
      trackingId: new FormControl(seedValue?.json_id || uuid()),
    };
  }

  private _createTextBoxFormGroup(
    seedValue?: CustomTextBoxFormField,
  ): FormGroup<CustomFormsTextBoxFieldType> {
    const model: CustomFormsTextBoxFieldType = {
      ...this._createBaseFormModel('TEXTBOX', seedValue),
      minLength: new FormControl<number | null>(seedValue?.minLength ?? null),
      maxLength: new FormControl<number | null>(seedValue?.maxLength ?? null),
      enableLineBreaks: new FormControl<boolean | null>(
        seedValue?.enableLineBreaks ?? false,
      ),
    };
    return this._fb.group(model, {
      validators: [
        TremazeValidators.greaterThanOrEqualValidator<CustomTextBoxFormField>(
          'maxLength',
          'minLength',
          { toOptional: true, fromOptional: true },
        ),
      ],
    });
  }

  private _createFileFormGroup(
    seedValue?: CustomFileFormField,
  ): FormGroup<CustomFormsFileFieldType> {
    const model: CustomFormsFileFieldType = {
      ...this._createBaseFormModel('FILE_UPLOAD', seedValue),
      minFileCount: new FormControl<number | null>(
        seedValue?.minFileCount ?? null,
      ),
      maxFileCount: new FormControl<number | null>(
        seedValue?.maxFileCount ?? null,
      ),
      maxFileSize: new FormControl<number | null>(
        seedValue?.maxFileSize ?? null,
      ),
      allowedFileTypes: new FormControl<string[] | null>(
        seedValue?.allowedFileTypes ?? [],
      ) as any,
    };
    return this._fb.group(model);
  }

  public createMultiSelectItemFormGroup(
    seedValue?: CustomMultiSelectFormFieldItem<unknown>,
    isInitialPopulate = false,
  ): FormGroup<CustomFormsMultiSelectItemFieldType<unknown>> {
    const model: CustomFormsMultiSelectItemFieldType<unknown> = {
      label: new FormControl(seedValue?.label ?? '', Validators.required),
      value: new FormControl(seedValue?.value ?? '', [
        Validators.required,
        multiSelectItemValueValidator,
      ]),
      sort: new FormControl(seedValue?.sort ?? 0, Validators.required),
    };
    const group = this._fb.group(model);
    if (!isNotEmpty(seedValue?.value)) {
      this._valueObserverService.setUpValueChangesSubscriptionForMultiSelectOption(
        group,
        isInitialPopulate ? 1 : 0,
      );
    }
    return group;
  }

  private _createMultiSelectFormGroup(
    isInitialPopulate: boolean,
    seedValue?: CustomMultiSelectFormField<unknown>,
  ): FormGroup<CustomFormsMultiSelectFieldType<unknown>> {
    const model: CustomFormsMultiSelectFieldType<unknown> = {
      ...this._createBaseFormModel('MULTISELECT', seedValue),
      minSelectedItems: new FormControl<number | null>(
        seedValue?.minSelectedItems ?? null,
      ),
      maxSelectedItems: new FormControl<number | null>(
        seedValue?.maxSelectedItems ?? null,
      ),
      type: new FormControl<MultiSelectType | null>(
        seedValue?.type ?? 'MULTISELECT',
        Validators.required,
      ),
      items: this._fb.array(
        seedValue?.items.map((item) =>
          this.createMultiSelectItemFormGroup(item, isInitialPopulate),
        ) ?? [
          this.createMultiSelectItemFormGroup(undefined, isInitialPopulate),
        ],
        [
          Validators.required,
          Validators.minLength(1),
          TremazeValidators.uniqueArrayValidator<
            CustomMultiSelectFormFieldItem<unknown>
          >('value', 'label'),
        ],
      ),
    };
    return this._fb.group(model, {
      validators: [
        TremazeValidators.greaterThanOrEqualValidator<
          CustomMultiSelectFormField<unknown>
        >('maxSelectedItems', 'minSelectedItems'),
      ],
    });
  }
}
