import { Directive } from '@angular/core';

@Directive({
  selector: '[tremazeDynamicListItemSubtitle]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'dynamic-list__Item__Subtitle',
  },
})
export class DynamicListItemSubtitleDirective {}
