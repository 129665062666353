import { Address } from '@tremaze/shared/models';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Validators } from '@angular/forms';
import { TremazeValidators } from '@tremaze/shared/util/form';

export abstract class AddressFormUtil {
  static createFormGroup(
    address = new Address(),
    required = true,
  ): FormGroup<Address> {
    const val = required ? [Validators.required] : [];
    return new FormGroup<Address>(
      {
        street: new FormControl<string>(address?.street, val),
        zip: new FormControl<string>(address?.zip, [
          ...val,
          TremazeValidators.zip,
        ]),
        city: new FormControl<string>(address?.city, val),
        addition: new FormControl<string>(address?.addition),
        country: new FormControl(address?.country),
      } as never,
      { validators: val },
    );
  }
}
