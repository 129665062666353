import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconPickerButtonComponent } from './icon-picker-button.component';
import { IconPickerDialogComponent } from './icon-picker-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  declarations: [IconPickerButtonComponent, IconPickerDialogComponent],
  exports: [IconPickerButtonComponent],
})
export class SharedFeatureIconFeatureIconPickerModule {}
