<div class='file-selector-dialog-wrapper'>
  <tremaze-folder-view
    (confirmSelection)='onFileSelected($event)'
    [selectionRegex]='data?.fileTypeMatcher'
    [selectionType]='data.type'
    folderViewDisableRouting
  >
    <button [matDialogClose]='undefined' mat-raised-button slot='actions-start' color='primary' class='mat-elevation-z2'>
      Schließen
    </button>
  </tremaze-folder-view>
</div>

