import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class JsonSerializer {
  objectToJson(data: any) {
    return JSON.stringify(data, (key, value) => {
      if (['meta']?.includes(key)) {
        return undefined;
      } else {
        if (value instanceof Date) {
          return value.getTime();
        }
        return value;
      }
    });
  }
}
