import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipationCriteriaListComponent } from './participation-criteria-list.component';
import { SharedSortedFilteredPaginatedTableModule } from '@tremaze/shared/sorted-filtered-paginated-table';
import { MatIconModule } from '@angular/material/icon';
import { SharedFeatureParticipationCriteriaFeatureEditModule } from '@tremaze/shared/feature/participation-criteria/feature/edit';

@NgModule({
  imports: [
    CommonModule,
    SharedSortedFilteredPaginatedTableModule,
    MatIconModule,
    SharedFeatureParticipationCriteriaFeatureEditModule,
  ],
  declarations: [ParticipationCriteriaListComponent],
  exports: [ParticipationCriteriaListComponent],
})
export class SharedFeatureParticipationCriteriaFeatureParticipationCriteriaListModule {}
