import {
  Component,
  ContentChildren,
  HostBinding,
  QueryList,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FolderViewGridLayoutFileItemComponent } from '../file-item/folder-view-grid-layout-file-item.component';
import animations from '../../animations';

@Component({
  selector: 'tremaze-folder-view-grid-layout-files',
  standalone: true,
  imports: [CommonModule],
  template: ` <ng-content></ng-content> `,
  styles: `
    :host {
      list-style-type: none;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 10px;
    }
  `,
  animations: [animations.parentAnimation],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'tremaze-folder-view-grid-layout-files',
  },
})
export class FolderViewGridLayoutFilesComponent {
  @ContentChildren(FolderViewGridLayoutFileItemComponent)
  private readonly _files?: QueryList<FolderViewGridLayoutFileItemComponent>;

  @HostBinding('@parent')
  get itemCount(): number {
    return this._files?.length ?? 0;
  }
}
