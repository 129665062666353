import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Meta } from '@tremaze/shared/models';
import { PaymentMethod } from './event';
import { PriceLevel } from './price-level';

@staticImplements<Deserializable<EventSettings>>()
export class EventSettings {
  constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public customPrices: PriceLevel[] = [],
    public paymentMethod: PaymentMethod = 'ON_THE_CLAW',
    public sendEventTicket: boolean = false,
    public emergencyContactIsMandatory = false,
    public addressIsMandatory = false,
    public queueEnabled = false,
    public queueV1Enabled = false,
    public queueV2Enabled = false,
    public phoneMobileIsMandatory = false,
  ) {}

  static deserialize(data: EventSettings): EventSettings {
    return !data
      ? null
      : new EventSettings(
          data.id,
          Meta.deserialize(data.meta),
          data.customPrices?.map(PriceLevel.deserialize),
          data.paymentMethod,
          data.sendEventTicket,
          data.emergencyContactIsMandatory,
          data.addressIsMandatory,
          data.queueEnabled,
          data.queueV1Enabled,
          data.queueV2Enabled,
          data.phoneMobileIsMandatory,
        );
  }
}
