<div class="table-wrapper__container">
  <mat-card appearance="raised">
    <mat-progress-bar
      class="table-wrapper__progress-bar mat-progress-bar-invisible-background"
      mode="indeterminate"
      [@progressBar]
      style="z-index: 101"
      color="accent"
      *ngIf="showProgressBar"
    ></mat-progress-bar>
    <div class="table-wrapper__container__filter gridster-nodrag">
      <div style="padding: 5px 10px; flex: 1">
        <mat-expansion-panel
          class="mat-elevation-z0"
          style="border: 1px solid rgba(143, 143, 143, 0.5)"
          *ngIf="additionalFilters?.template"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon
                style="
                  height: 20px;
                  width: 20px;
                  margin-right: 10px;
                  font-size: 18px;
                "
              >
                <span class="lnr lnr-funnel"></span>
              </mat-icon>
              Weitere Filter
            </mat-panel-title>
            <mat-panel-description>
              <span [innerHTML]="additionalFilterDescriptionString"></span>
            </mat-panel-description>
            <span
              (click)="onClickResetFilterButton($event)"
              (keyup.enter)="onClickResetFilterButton($event)"
              tabindex="0"
              class="table-wrapper__container__filter__reset"
              >Zurücksetzen</span
            >
          </mat-expansion-panel-header>
          <ng-template
            [ngTemplateOutlet]="additionalFilters?.template"
          ></ng-template>
        </mat-expansion-panel>
      </div>
      <tremaze-expanding-icon-text-input
        label="Suche..."
        #filterInput
        [(ngModel)]="filterValue"
      ></tremaze-expanding-icon-text-input>
      <button
        class="table-wrapper__reload mat-elevation-z0"
        mat-mini-fab
        color="accent"
        (click)="onCLickReloadButton()"
      >
        <mat-icon>
          <span class="lnr lnr-sync"></span>
        </mat-icon>
      </button>
    </div>
    <div class="table-wrapper__container__table-wrapper">
      <table
        [dataSource]="data"
        mat-table
        matSort
        [matSortActive]="config?.initialActiveSortColumnName"
        [matSortDirection]="config?.initialSortDirection"
        class="gridster-nodrag"
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              *ngIf="!disableMultiSelection"
              [checked]="isAllSelected()"
              [indeterminate]="selectionModel.length > 0 && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? toggle(row) : null"
              [checked]="isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let col of customColumns; let colIndex = index"
          [matColumnDef]="col.name"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            [start]="col.sortStart"
            mat-sort-header
            [disableClear]="false"
            [disabled]="!col.sortable"
          >
            {{ col.displayName || '' }}
          </th>
          <td mat-cell *matCellDef="let row; let i = index">
            <ng-container
              [ngTemplateOutlet]="columnTemplates[col.name] || noTemplate"
              [ngTemplateOutletContext]="{ $implicit: row }"
            >
            </ng-container>
            <ng-template #noTemplate>
              {{ row[col.displayName] }}
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" align="right" mat-cell>
            <ng-template
              [ngTemplateOutlet]="preActions?.template"
              [ngTemplateOutletContext]="{ $implicit: row }"
            ></ng-template>
            <ng-container *ngIf="showDetailsButton">
              <button
                (click)="onClickDetailsButton(row)"
                *tremazeHasPermission="
                  config?.detailsPermissions
                    ? config.detailsPermissions(row)
                    : null
                "
                [matTooltip]="getNameOfItem(row) + ' Details'"
                color="primary"
                mat-icon-button
              >
                <span
                  class="{{
                    config?.icons?.detailsButton || 'lnr lnr-clipboard-text'
                  }}"
                ></span>
              </button>
            </ng-container>
            <ng-container *ngIf="showEditButton">
              <button
                (click)="onClickEditButton(row)"
                *tremazeHasPermission="
                  config?.editPermissions ? config.editPermissions(row) : null
                "
                [matTooltip]="getNameOfItem(row) + ' bearbeiten'"
                color="primary"
                mat-icon-button
              >
                <span
                  class="{{ config?.icons?.editButton || 'lnr lnr-pencil' }}"
                ></span>
              </button>
            </ng-container>
            <ng-container *ngIf="showDeleteButton">
              <button
                (click)="onClickDeleteButton(row)"
                *tremazeHasPermission="
                  config?.deletePermissions
                    ? config.deletePermissions(row)
                    : null
                "
                [matTooltip]="
                  getNameOfItem(row) +
                  (config?.deleteButtonTooltipOverride || ' löschen')
                "
                color="primary"
                mat-icon-button
              >
                <span
                  class="{{ config?.icons?.deleteButton || 'lnr lnr-trash2' }}"
                ></span>
              </button>
            </ng-container>
            <ng-template
              [ngTemplateOutlet]="postActions?.template"
              [ngTemplateOutletContext]="{ $implicit: row }"
            ></ng-template>
          </td>
        </ng-container>
        <tr
          *matHeaderRowDef="displayedColumnsNames; sticky: true"
          mat-header-row
          @rowAnimation
        ></tr>
        <tr
          *matRowDef="let row; columns: displayedColumnsNames"
          @rowAnimation
          mat-row
        ></tr>
      </table>
    </div>
    <div style="padding: 15px" *ngIf="showCreateButton">
      <tremaze-table-add-new-item-button
        (click)="onClickCreateButton()"
        [iconOverride]="config?.icons?.createButton"
        *tremazeHasPermission="config?.createPermissions"
        class="gridster-nodrag"
        [label]="config?.addButtonLabel || 'Neuen Eintrag anlegen'"
      ></tremaze-table-add-new-item-button>
    </div>
  </mat-card>
</div>

<mat-card appearance="raised" style="padding: 0">
  <div class="table-bottom">
    <mat-paginator
      [length]="totalItemCount"
      [pageSizeOptions]="
        paginationConfig?.pageSizeSteps || [1, 5, 10, 20, 50, 100]
      "
      [pageSize]="paginationConfig?.initialPageSize || 10"
      class="gridster-nodrag"
    ></mat-paginator>
    <div class="table-bottom__actions gridster-nodrag">
      <ng-container *ngIf="showExportButton">
        <button
          mat-mini-fab
          matTooltip="Liste exportieren"
          color="accent"
          (click)="onClickExportListButton()"
          *tremazeHasPermission="config?.exportPermissions"
        >
          <mat-icon>
            <span
              class="{{ config?.icons?.exportButton || 'lnr lnr-download2' }}"
            ></span>
          </mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="showCreateButton">
        <button
          mat-mini-fab
          [matTooltip]="config?.addButtonLabel || 'Neuen Eintrag anlegen'"
          color="accent"
          *tremazeHasPermission="config?.createPermissions"
          (click)="onClickCreateButton()"
        >
          <mat-icon>
            <span
              class="{{ config?.icons?.createFAB || 'lnr lnr-plus' }}"
            ></span>
          </mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="additionalTableActions?.template">
        <ng-template
          [ngTemplateOutlet]="additionalTableActions?.template"
        ></ng-template>
      </ng-container>
    </div>
  </div>
</mat-card>
