import { Injectable, OnDestroy } from '@angular/core';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import {
  combineLatest,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
} from 'rxjs';
import { SortDirection } from '@tremaze/shared/util-http';

type SortField = 'name' | 'date' | 'size';

@Injectable()
export class FolderViewListLayoutDataSource implements OnDestroy {
  readonly folders$ = new ReplaySubject<DirStorage[]>(1);
  readonly files$ = new ReplaySubject<FileStorage[]>(1);
  readonly sort$ = new ReplaySubject<{
    field: SortField;
    direction: SortDirection;
  }>(1);
  readonly mixFilesAndFolders$ = new ReplaySubject<boolean>(1);
  readonly totalBytes$ = this.files$.pipe(
    map((files) => files.reduce((acc, f) => acc + f.fileSize, 0)),
  );

  readonly data$ = combineLatest([
    this.folders$,
    this.files$,
    this.sort$,
    this.mixFilesAndFolders$,
  ]).pipe(
    map(([folders, files, sort, mix]) => {
      if (mix) {
        return this._sort([...folders, ...files], sort.field, sort.direction);
      }
      return [
        ...this._sort(folders, sort.field, sort.direction),
        ...this._sort(files, sort.field, sort.direction),
      ];
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  ngOnDestroy(): void {
    this.folders$.complete();
    this.files$.complete();
    this.sort$.complete();
    this.mixFilesAndFolders$.complete();
  }

  private _sort<T extends DirStorage | FileStorage>(
    items: T[],
    sort: SortField,
    order: SortDirection,
  ): T[] {
    return items.sort((a, b) => {
      if (sort === 'name') {
        const fieldA =
          'dirViewname' in a
            ? (a.dirViewname ?? a.dirname)
            : (a.fileViewname ?? a.filename);
        const fieldB =
          'dirViewname' in b
            ? (b.dirViewname ?? b.dirname)
            : (b.fileViewname ?? b.filename);
        return fieldA.localeCompare(fieldB) * (order === 'asc' ? 1 : -1);
      } else if (sort === 'date') {
        const d1 = a.meta?.editDate ?? a.meta?.insertDate;
        const d2 = b.meta?.editDate ?? b.meta?.insertDate;
        const comp = (d2 ? d1?.compare(d2) : null) ?? 0;
        return comp * (order === 'asc' ? 1 : -1);
      } else if (sort === 'size') {
        const sizeA = 'fileSize' in a ? a.fileSize : 0;
        const sizeB = 'fileSize' in b ? b.fileSize : 0;
        return (sizeA > sizeB ? 1 : -1) * (order === 'asc' ? 1 : -1);
      }
      return 0;
    });
  }

  deleteById(id: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
}
