import { Injectable, Optional } from '@angular/core';
import { EventTemplate } from '@tremaze/shared/feature/event/types';
import { SortedFilteredTableDataManipulationServiceResponse } from '@tremaze/shared/sorted-filtered-paginated-table/types';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { map, switchMap, tap } from 'rxjs/operators';
import { EventTemplateEditDialogComponent } from './edit-dialog.component';
import { DefaultRemoteEventTemplateDataSource } from '@tremaze/shared/feature/event/template/data-access';
import { NotificationService } from '@tremaze/shared/notification';
import { EventStateService } from '@tremaze/shared/feature/event';

@Injectable({ providedIn: 'any' })
export class EventTemplateEditService {
  constructor(
    private dialog: MatDialog,
    private templateDataSource: DefaultRemoteEventTemplateDataSource,
    private notificationService: NotificationService,
    @Optional() private eventStateService?: EventStateService,
  ) {}

  createItem(
    template?: EventTemplate,
  ): Observable<
    | SortedFilteredTableDataManipulationServiceResponse<EventTemplate>
    | null
    | undefined
  > {
    //TODO: Video-Meeting erstellen
    return this.dialog
      .open(EventTemplateEditDialogComponent, { data: { template } })
      .afterClosed()
      .pipe(
        switchMap((r) => {
          if (r instanceof EventTemplate) {
            return this.templateDataSource.create(r).pipe(
              tap((result) => {
                if (result instanceof EventTemplate) {
                  this.notificationService.showNotification(
                    'Die Vorlage wurde erstellt',
                  );
                  if (this.eventStateService) {
                    this.eventStateService.triggerTemplatesReload();
                  }
                } else {
                  this.notificationService.showDefaultErrorNotification();
                }
              }),
            );
          }
          return of(null);
        }),
        map((r) => (r ? { item: r, skipHttpAction: true } : null)),
      );
  }

  editItem(
    item: EventTemplate,
  ): Observable<
    | SortedFilteredTableDataManipulationServiceResponse<EventTemplate>
    | null
    | undefined
  > {
    //TODO: Video-Meeting bei Bedarf erstellen
    return this.dialog
      .open(EventTemplateEditDialogComponent, { data: { template: item } })
      .afterClosed()
      .pipe(
        switchMap((r) => {
          if (r instanceof EventTemplate) {
            return this.templateDataSource.edit(r).pipe(
              tap((result) => {
                if (result instanceof EventTemplate) {
                  this.notificationService.showNotification(
                    'Die Vorlage wurde bearbeitet',
                  );
                  if (this.eventStateService) {
                    this.eventStateService.triggerTemplatesReload();
                  }
                } else {
                  this.notificationService.showDefaultErrorNotification();
                }
              }),
            );
          }
          return of(null);
        }),
        map((r) => (r ? { item: r, skipHttpAction: true } : null)),
      );
  }

  deleteItem(id: string): Observable<boolean | null> {
    //TODO: Video-Meeting löschen?
    return this.templateDataSource.deleteById(id).pipe(
      tap((res) => {
        if (res) {
          this.notificationService.showNotification(
            'Die Vorlage wurde gelöscht',
          );
          if (this.eventStateService) {
            this.eventStateService.triggerTemplatesReload();
          }
        } else {
          this.notificationService.showDefaultErrorNotification();
        }
      }),
    );
  }
}
