import {
  GLOBAL_PAGINATION_CONFIG,
  GlobalPaginationConfig,
  SortedFilteredTableColumnDef,
  SortedFilteredTableConfig,
} from '@tremaze/shared/sorted-filtered-paginated-table/types';
import { NotificationService } from '@tremaze/shared/notification';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  catchError,
  debounceTime,
  finalize,
  startWith,
  tap,
} from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import {
  AdditionalFiltersDirective,
  ColumnDirective,
  PostActionsDirective,
  PreActionsDirective,
} from '@tremaze/shared/sorted-filtered-paginated-table/ui/directives';
import { PermissionCheckService } from '@tremaze/shared/permission/services';
import { ExportDataTypeSelectorService } from '@tremaze/shared/ui/export-data-type-selector';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import {
  EmailAlreadyInUseFailure,
  Failure,
  UsernameAlreadyInUseFailure,
  UsernameOrEmailAlreadyInUseFailure,
} from '@tremaze/shared/util-error';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import {
  SortedFilteredTableDataManipulationService,
  SortedFilteredTableDetailsService,
} from '@tremaze/shared/sorted-filtered-paginated-table/services';
import { animate, style, transition, trigger } from '@angular/animations';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DefaultCRUDDataSourceImpl } from '@tremaze/shared/util-http';
import { isFunction } from '@tremaze/shared/util-utilities';
import { IdObject } from '@tremaze/shared/util/id-object';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { TableActionsDirective } from '../../../directives/src/lib/table-actions.directive';
import { ExpandingIconTextInputComponent } from '@tremaze/shared/ui/inputs/expanding-icon-text-input';

export const rowAnimation = trigger('rowAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), // initial
    animate('.3s ease', style({ opacity: 1 })), // final
  ]),
  transition(':leave', [style({ opacity: 0, height: '0' })]),
  transition('* => void', [animate('0ms', style({ display: 'none' }))]),
]);

@Component({
  selector: 'tremaze-sorted-filtered-paginated-table',
  animations: [
    rowAnimation,
    trigger('progressBar', [
      transition(':enter', [
        style({ opacity: 0 }), // initial
        animate('.3s ease', style({ opacity: 1 })), // final
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(
          '.3s .3s ease',
          style({
            opacity: 0,
          }),
        ),
      ]),
    ]),
  ],
  templateUrl: './sorted-filtered-paginated-table.component.html',
  styleUrls: ['./sorted-filtered-paginated-table.component.scss'],
})
export class SortedFilteredPaginatedTableComponent<T extends IdObject>
  implements OnInit, AfterViewInit, OnDestroy
{
  data: T[] = [];
  totalItemCount: number;
  @Input() config: SortedFilteredTableConfig<T>;
  @Input() uniqueListId: string | Observable<string>;
  @Output() loadedData = new EventEmitter();
  @Output() filterReset = new EventEmitter();
  @Output() clickAdd = new EventEmitter();
  @Output() clickEdit = new EventEmitter<T>();
  @Output() clickDelete = new EventEmitter<T>();
  @Output() clickDetails = new EventEmitter<T>();
  @Output() elementCreated = new EventEmitter<T>();
  @Output() elementEdited = new EventEmitter<T>();
  @Output() elementDeleted = new EventEmitter<T>();
  @Output() elementChanged = new EventEmitter<T>();
  @ViewChild('colTemplateRef', { read: ViewContainerRef })
  colTemplateRef: ViewContainerRef;
  @ContentChild(PreActionsDirective, { read: PreActionsDirective })
  preActions: PreActionsDirective;
  @ContentChild(PostActionsDirective, { read: PostActionsDirective })
  postActions: PostActionsDirective;
  @ContentChild(AdditionalFiltersDirective, {
    read: AdditionalFiltersDirective,
  })
  additionalFilters: AdditionalFiltersDirective;
  @ContentChild(TableActionsDirective, {
    read: TableActionsDirective,
  })
  additionalTableActions: AdditionalFiltersDirective;
  @ContentChildren(ColumnDirective)
  columnDefinitions: QueryList<ColumnDirective>;
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  filterValue: string = '';
  _subscriptionsToKill = {};
  _reloadButtonPressed = new Subject();
  additionalFilterChange$ = new Subject();
  additionalFilter = {};
  additionalFilterDisplayKeys = {};
  additionalFilterDisplayValues = {};
  @Output() selectionChange = new EventEmitter<T[]>();
  @ViewChild('filterInput', { read: ExpandingIconTextInputComponent })
  private readonly _filterInput: ExpandingIconTextInputComponent;
  private _reload = new Subject();

  constructor(
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    public viewRef: ViewContainerRef,
    @Optional() private notificationService: NotificationService,
    @Optional() private confirmationService: ConfirmationService,
    @Optional() private permissionCheckService: PermissionCheckService,
    @Optional()
    private exportDataTypeSelectorService: ExportDataTypeSelectorService,
    @Optional()
    @Inject(GLOBAL_PAGINATION_CONFIG)
    public paginationConfig: GlobalPaginationConfig,
    @Optional() public detailsService: SortedFilteredTableDetailsService<T>,
    @Optional()
    public manipulationService: SortedFilteredTableDataManipulationService<T>,
    @Optional() private appConfigService: AppConfigService,
  ) {}

  private _disableMultiSelection = false;

  get disableMultiSelection(): boolean {
    return this._disableMultiSelection;
  }

  @Input('disable-multi-selection')
  set disableMultiSelection(value: boolean) {
    this._disableMultiSelection = coerceBooleanProperty(value);
  }

  private _enableSelection = false;

  @Input('enable-selection')
  get enableSelection(): boolean {
    return this._enableSelection;
  }

  set enableSelection(value: boolean) {
    this._enableSelection = coerceBooleanProperty(value);
  }

  private _initialSelection: T[] = [];

  get initialSelection(): T[] {
    return this._initialSelection;
  }

  @Input()
  set initialSelection(value: T[]) {
    this._initialSelection = value;
  }

  private _selectionModel: T[];

  get selectionModel(): T[] {
    return this._selectionModel;
  }

  set selectionModel(value: T[]) {
    this._selectionModel = value;
  }

  get additionalFilterDescriptionString(): string {
    const filters = Object.keys(this.additionalFilter);
    if (filters?.length) {
      const keys = this.additionalFilterDisplayKeys;
      const vals = this.additionalFilterDisplayValues;
      let res = '';
      filters.forEach((k, i) => {
        if (keys[k] && vals[k]) {
          res += `<strong>${keys[k]}</strong>: ${vals[k]}`;
        }
        if (i < filters.length - 1) {
          res += '; ';
        }
      });
      return res;
    }
    return 'Keine Filter eingestellt';
  }

  public get columnTemplates(): { [key: string]: TemplateRef<any> } {
    if (this.columnDefinitions != null) {
      const columnTemplates: { [key: string]: TemplateRef<any> } = {};
      for (const columnDefinition of this.columnDefinitions.toArray()) {
        columnTemplates[columnDefinition.columnName] =
          columnDefinition.columnTemplate;
      }
      return columnTemplates;
    } else {
      return {};
    }
  }

  get displayedColumns(): SortedFilteredTableColumnDef[] {
    return [
      ...(this.config.displayedColumns || []),
      ...(this.config?.hideDefaultActions
        ? []
        : [{ sortable: false, displayName: '', name: 'actions' }]),
    ];
  }

  get displayedColumnsNames(): string[] {
    return [
      ...(this.enableSelection ? ['select'] : []),
      ...this.displayedColumns.filter((d) => !d.hidden).map((d) => d.name),
    ];
  }

  get customColumns(): SortedFilteredTableColumnDef[] {
    return this.config?.displayedColumns || [];
  }

  get showCreateButton(): boolean {
    return (
      this.config?.hideDefaultCreateActionButton === false ||
      (this.manipulationService &&
        this.config?.dataSource instanceof DefaultCRUDDataSourceImpl &&
        this.config?.dataSource?.create &&
        this.config?.hideDefaultCreateActionButton !== true)
    );
  }

  get showEditButton(): boolean {
    return (
      this.config?.hideDefaultEditActionButton === false ||
      (this.manipulationService &&
        this.config?.dataSource?.getFreshById &&
        this.config?.dataSource instanceof DefaultCRUDDataSourceImpl &&
        this.config?.dataSource?.edit &&
        this.config?.hideDefaultEditActionButton !== true)
    );
  }

  get showDetailsButton(): boolean {
    return (
      this.config?.hideDefaultDetailsButton === false ||
      (this.config?.hideDefaultDetailsButton !== true &&
        this.detailsService &&
        !!this.config?.dataSource?.getFreshById)
    );
  }

  get showDeleteButton(): boolean {
    return (
      this.config?.hideDefaultDeleteActionButton === false ||
      (this.config?.dataSource instanceof DefaultCRUDDataSourceImpl &&
        this.config?.dataSource?.deleteById &&
        this.config?.hideDefaultDeleteActionButton !== true)
    );
  }

  get showExportButton(): boolean {
    return (
      this.config?.hideDefaultExportActionButton === false ||
      (this.config?.getSupportedExportDataTypes &&
        this.config?.exportData &&
        this.config?.hideDefaultExportActionButton !== true)
    );
  }

  private _showProgressBar = true;

  get showProgressBar(): boolean {
    return this._showProgressBar;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const { length: numSelected } = this._selectionModel,
      { length: numRows } = this.data;
    return numSelected > 0 && numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? (this._selectionModel = [])
      : this.data.forEach((row) => {
          if (!this.isSelected(row)) {
            this._selectionModel.push(row);
          }
        });
    this.selectionChange.emit(this.selectionModel);
  }

  isSelected(row: T): boolean {
    return this.selectionModel.some((t) => t['id'] === row['id']);
  }

  toggle(row: T) {
    if (this.isSelected(row)) {
      this.selectionModel = this.selectionModel.filter(
        (t) => t['id'] !== row['id'],
      );
    } else {
      if (this.disableMultiSelection) {
        this.selectionModel = [row];
      } else {
        this.selectionModel.push(row);
      }
    }
    this.selectionChange.emit(this.selectionModel);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this._subscriptionsToKill['reloads'] = merge(
      merge(
        this._filterInput.valueChange,
        this.sort.sortChange,
        this._reload,
        this.additionalFilterChange$,
        this._reloadButtonPressed,
      ).pipe(
        tap(() => {
          this._showProgressBar = true;
          this.cdRef.detectChanges();
        }),
        debounceTime(500),
      ),
      this.paginator.page,
    )
      .pipe(
        startWith({}),
        tap(() => {
          this._showProgressBar = true;
          this.cdRef.detectChanges();
        }),
      )
      .subscribe(() => this._onFilterChange());
  }

  ngOnInit() {
    if (!this.config) {
      console.warn(
        'SortedFilteredPaginatedTableComponent needs to be provided with a config!',
      );
    }
    this.config?.displayedColumns?.forEach((d) => {
      if (d.permissions) {
        this.permissionCheckService
          .checkPermission$(d.permissions)
          .then((r) => {
            d.hidden = !r;
          });
      }
    });

    if (this.enableSelection) {
      this.selectionModel = [...this.initialSelection];
    }
  }

  ngOnDestroy() {
    Object.values(this._subscriptionsToKill).forEach((s: Subscription) =>
      s.unsubscribe ? s.unsubscribe() : null,
    );
  }

  public additionalFilterChange(filter: {
    key: string;
    val: string;
    displayKey?: string;
    displayValue?: string;
  }) {
    if (filter?.key) {
      if (!filter.val) {
        delete this.additionalFilter[filter.key];
        if (filter.displayKey) {
          delete this.additionalFilterDisplayKeys[filter.key];
          delete this.additionalFilterDisplayValues[filter.key];
        }
      } else {
        this.additionalFilter[filter?.key] = filter?.val;
        if (filter.displayKey && filter.displayValue) {
          this.additionalFilterDisplayKeys[filter.key] = filter?.displayKey;
          this.additionalFilterDisplayValues[filter.key] = filter?.displayValue;
        } else {
          delete this.additionalFilterDisplayKeys[filter.key];
          delete this.additionalFilterDisplayValues[filter.key];
        }
      }
      this.additionalFilterChange$.next(this.additionalFilter);
      this.cdRef.detectChanges();
    }
  }

  getNameOfItem(item: T): string {
    if (this.config?.nameBuilder) {
      return this.config.nameBuilder(item);
    }
    return (item as any).name || 'Eintrag';
  }

  showErrorSnack = () =>
    this.notificationService.showDefaultErrorNotification();

  public reloadTable() {
    this._reload.next(null);
  }

  async onDelete(item: T) {
    if (
      this.config?.dataSource instanceof DefaultCRUDDataSourceImpl &&
      this.config?.dataSource?.deleteById &&
      (
        await this.confirmationService
          .askUserForConfirmation(
            this.config?.deleteConfirmationDialogConfigOverride || {
              warn: true,
            },
          )
          .toPromise()
      )?.confirmed
    ) {
      let hResult;
      if (this.manipulationService?.deleteItem) {
        hResult = await this.manipulationService
          ?.deleteItem?.(item)
          .toPromise();
        if ([null, undefined].includes(hResult)) {
          return;
        }
      } else {
        hResult = await this.config?.dataSource
          ?.deleteById(item?.id)
          .pipe(catchError(() => of(false)))
          .toPromise();
      }
      if (hResult === true) {
        this.elementDeleted.emit(item);
        this.elementChanged.emit(item);
        this.notificationService.showNotification(
          this.config?.deleteSuccessMessage || 'Der Eintrag wurde gelöscht',
        );
        this.reloadTable();
      } else {
        this.showErrorSnack();
      }
    }
  }

  onClickDeleteButton(item: T) {
    if (this.onDelete) {
      this.onDelete(item);
    }
    this.clickDelete.emit(item);
  }

  async onClickCreateButton() {
    try {
      if (
        this.manipulationService?.createItem &&
        this.config?.dataSource instanceof DefaultCRUDDataSourceImpl &&
        this.config?.dataSource?.create
      ) {
        const userCreatedItem = await this.manipulationService
          .createItem()
          .toPromise();
        if (userCreatedItem) {
          if (userCreatedItem.skipHttpAction) {
            if (!userCreatedItem.skipReload) {
              this.reloadTable();
            }
          } else if (userCreatedItem.item) {
            const hRes = await this.config?.dataSource
              ?.create(userCreatedItem.item)
              .toPromise();
            if (hRes instanceof Failure) {
              this.handleFailure(hRes);
            } else {
              this.notificationService.showNotification(
                this.config?.createSuccessMessage ||
                  'Der Eintrag wurde angelegt',
              );
              this.reloadTable();
            }
          }
        }
      }
      this.clickAdd.emit();
    } catch (e) {
      console.error(e);
      this.showErrorSnack();
    }
  }

  async onClickDetailsButton(item: T) {
    try {
      if (
        this.detailsService?.showDetails &&
        this.config?.dataSource?.getFreshById
      ) {
        const freshItem = await (
          this.config?.disableGetFreshItemBeforeEditDetails
            ? of(item)
            : this.config?.dataSource?.getFreshById(item?.id)
        ).toPromise();
        if (freshItem instanceof Failure) {
          this.showErrorSnack();
          return;
        }
        const r = await this.detailsService.showDetails(freshItem).toPromise();
        if (r) {
          switch (r.action) {
            case 'EDIT':
              this.onClickEditButton(item);
              break;
            case 'DELETE':
              this.onClickDeleteButton(item);
              break;
            case 'RELOAD':
              this.reloadTable();
              break;
          }
        }
      }
    } catch (e) {
      this.showErrorSnack();
    }
  }

  async onClickEditButton(item: T) {
    try {
      if (
        this.manipulationService?.editItem &&
        this.config?.dataSource instanceof DefaultCRUDDataSourceImpl &&
        this.config?.dataSource?.edit &&
        this.config?.dataSource?.getFreshById
      ) {
        const freshItem = await (
          this.config?.disableGetFreshItemBeforeEditDetails
            ? of(item)
            : this.config?.dataSource?.getFreshById(item?.id)
        ).toPromise();
        if (freshItem instanceof Failure) {
          this.showErrorSnack();
          return;
        }
        const userEditedItem = await this.manipulationService
          .editItem(freshItem)
          .toPromise();
        if (userEditedItem) {
          if (userEditedItem.skipHttpAction) {
            if (!userEditedItem.skipReload) {
              this.reloadTable();
            }
          } else if (userEditedItem.item) {
            const hRes = await this.config?.dataSource
              ?.edit(userEditedItem.item)
              .toPromise();
            if (hRes instanceof Failure) {
              this.handleFailure(hRes);
            } else {
              this.notificationService.showNotification(
                this.config?.editSuccessMessage ||
                  'Der Eintrag wurde bearbeitet',
              );
              this.reloadTable();
            }
          }
        }
      }
      this.clickEdit.emit(item);
    } catch (e) {
      console.error(e);
      this.showErrorSnack();
    }
  }

  onCLickReloadButton() {
    this._reloadButtonPressed.next(null);
  }

  onClickResetFilterButton(event: Event) {
    event.stopPropagation();
    this.filterValue = null;
    if (this._filterInput) {
      this._filterInput.value = null;
    }
    this.additionalFilter = {};
    this.additionalFilterDisplayKeys = {};
    this.additionalFilterChange$.next({});
    this.filterReset.emit();
  }

  async onClickExportListButton() {
    const type = await this.exportDataTypeSelectorService
      .selectDataType(
        await this.config?.getSupportedExportDataTypes().toPromise(),
      )
      .toPromise();
    if (type) {
      this.config?.exportData(type).subscribe((r) => {
        if (!r) {
          return;
        }
        if (r instanceof Failure) {
          this.notificationService.showDefaultErrorNotification();
          return;
        }
        const body = new Blob([new Uint8Array(r as any)]);
        const objectUrl: string = URL.createObjectURL(body);
        const a: HTMLAnchorElement = document.createElement(
          'a',
        ) as HTMLAnchorElement;
        a.href = objectUrl;
        let fileName;
        const f = this.config.exportFileName || 'export';
        if (typeof f === 'string') {
          fileName = f;
        } else if (isFunction(f)) {
          fileName = f();
        }
        a.download = `${fileName}.${type.fileExtension}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      });
    }
  }

  private handleFailure(failure: Failure) {
    if (this.appConfigService?.state === 'DEV') {
      console.error({ failure });
    }
    if (failure instanceof UsernameAlreadyInUseFailure) {
      this.notificationService.showNotification(
        'Der Benutzername wird bereits verwendet',
      );
    } else if (failure instanceof EmailAlreadyInUseFailure) {
      this.notificationService.showNotification(
        'Die E-Mail Adresse wird bereits verwendet',
      );
    } else if (failure instanceof UsernameOrEmailAlreadyInUseFailure) {
      this.notificationService.showNotification(
        'Der Benutzername und/oder die E-Mail Adresse werden bereits verwendet',
      );
    }
    this.showErrorSnack();
  }

  private _onFilterChange() {
    const req = this.config.dataSource.getPaginated({
      ...(this.config?.paginationRequestOptions || {}),
      filter: {
        sort: this.sort.active,
        sortDirection: this.sort.direction,
        pageSize: this.paginator.pageSize,
        page: this.paginator.pageIndex,
        filterValue: this.filterValue,
        filterFields: this.config?.filterFields,
        additionalParams: this.additionalFilter,
      },
    });
    req
      .pipe(
        finalize(() => {
          this.loadedData.emit();
          this._showProgressBar = false;
          this.cdRef.detectChanges();
        }),
      )
      .subscribe((r) => {
        if (r instanceof Failure) {
          if (this.appConfigService?.state === 'DEV') {
            console.error({ failure: r });
          }
          this.showErrorSnack();
        } else {
          const x = r as any;
          if (x.content) {
            this.data = x.content;
            this.totalItemCount = x.totalElements;
          } else if (x.items) {
            this.data = x.items;
            this.totalItemCount = x.count;
          } else {
            console.error({ error: 'EMPTY DATA', data: x });
          }
          this.cdRef.detectChanges();
          const currentPage =
            this.paginator.pageIndex * this.paginator.pageSize + 1;
          if (this.totalItemCount > 0 && currentPage > this.totalItemCount) {
            const newIndex =
              Math.ceil(this.totalItemCount / this.paginator.pageSize) - 1;
            this.paginator.pageIndex = newIndex;
            this.paginator.page.next({
              pageIndex: newIndex,
              length: this.paginator.length,
              pageSize: this.paginator.pageSize,
            });
          }
        }
      });
  }
}
