import { FolderViewNavigationService } from './services/folder-view-navigation.service';
import { FolderViewEventsService } from './services/folder-view-events.service';
import { FolderViewDeletionService } from './services/folder-view-deletion.service';
import { FolderViewFileOpenerService } from './services/folder-view-file-opener.service';
import { FolderViewSelectionService } from './services/folder-view-selection.service';
import { FolderViewUploadService } from './services/folder-view-upload.service';
import { FolderViewComponentService } from './folder-view-component.service';
import { FolderViewCopyService } from './services/folder-view-copy.service';

export default [
  FolderViewNavigationService,
  FolderViewEventsService,
  FolderViewDeletionService,
  FolderViewFileOpenerService,
  FolderViewSelectionService,
  FolderViewUploadService,
  FolderViewComponentService,
  FolderViewCopyService,
];
