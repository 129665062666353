import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tremaze-panel',
  template: `
    <ng-content select="tremaze-panel-header"></ng-content>
    <div class="tremaze-panel-content">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PanelComponent {
  @HostBinding('class') class = 'tremaze-panel';
}
