import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, MonoTypeOperatorFunction, Observable, switchMap } from 'rxjs';
import { ConfirmationComponent } from './confirmation.component';
import {
  ConfirmationPayload,
  ConfirmationServiceResponse,
} from '@tremaze/shared/feature/confirmation/types';

export abstract class ConfirmationService {
  public abstract askUserForConfirmation(
    data?: ConfirmationPayload,
  ): Observable<ConfirmationServiceResponse>;

  public abstract notifyUserAboutUnsavedChanges(): Observable<boolean>;

  public abstract filterConfirmed<T>(
    config?: ConfirmationPayload,
  ): MonoTypeOperatorFunction<T>;
}

@Injectable({ providedIn: 'root' })
export class ConfirmationServiceImpl implements ConfirmationService {
  constructor(protected d: MatDialog) {}

  public askUserForConfirmation(
    data?: ConfirmationPayload,
  ): Observable<ConfirmationServiceResponse> {
    return this.d
      .open(ConfirmationComponent, { data })
      .afterClosed()
      .pipe(
        tap((r) => {
          if (!r?.confirmed && data?.declineCallback) {
            data.declineCallback();
          }
        }),
        map((r) => {
          if (r === undefined) {
            return null;
          }
          if (r) {
            return { confirmed: true };
          }
          return { confirmed: false };
        }),
      );
  }

  public notifyUserAboutUnsavedChanges(): Observable<boolean> {
    return this.askUserForConfirmation({
      warn: true,
      title: 'Nicht gespeicherte Änderungen',
      text: 'Möchtest du diese Seite wirklich verlassen?<br> Du hast ungespeicherte Änderungen, die dann verloren gehen.',
    }).pipe(map((r) => r?.confirmed === true));
  }

  public filterConfirmed<T>(
    config?: ConfirmationPayload,
  ): MonoTypeOperatorFunction<T> {
    return (ob$: Observable<T>) =>
      ob$.pipe(
        switchMap((data) =>
          this.askUserForConfirmation(config).pipe(
            filter((confirmed) => confirmed?.confirmed),
            map(() => data),
          ),
        ),
      );
  }
}

export const provideConfirmationService = () => ({
  provide: ConfirmationService,
  useClass: ConfirmationServiceImpl,
});
