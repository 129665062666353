import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { CategorySelectorComponent } from './selector.component';
import { SharedFeatureCategoryFeatureEditModule } from '@tremaze/shared/feature/category/feature/edit';
import { SharedPermissionUiDirectivesModule } from '@tremaze/shared/permission/ui/directives';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    SharedFeatureCategoryFeatureEditModule,
    MatIconModule,
    SharedPermissionUiDirectivesModule,
  ],
  declarations: [CategorySelectorComponent],
  exports: [CategorySelectorComponent],
})
export class SharedFeatureCategoryFeatureSelectModule {}
