import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { FileSelectorConfig } from './config';
import { FolderViewSelectionType } from '@tremaze/shared/feature/file-storage/feature/folder-view';

@Component({
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
})
export class FileSelectorComponent {
  constructor(
    private ref: MatDialogRef<FileSelectorComponent>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: Pick<FileSelectorConfig, 'fileTypeMatcher'> & {
      type: FolderViewSelectionType;
    },
  ) {}

  onFileSelected(r: { files: FileStorage[]; dirs: DirStorage[] }) {
    this.ref.close(r);
  }

  onClose() {
    this.ref.close();
  }
}
