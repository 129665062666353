<mat-progress-bar [bufferValue]='service.loadedPercents$ | async' [value]='service.executedPercent$ | async'
                  mode='buffer'></mat-progress-bar>
<h2 mat-dialog-title>Mehrfachaktion: Löschen</h2>
<div mat-dialog-content>
  <p>Möchtest du alle {{ service.items.length }} Elemente löschen?</p>

  @if (service.notPermittedItems$ | async | count; as notPermittedItemsCount) {
    <tremaze-hint mode='WARN' colorPalette='accent'>
      Für {{ notPermittedItemsCount }}
      der ausgewählten Elemente verfügst du nicht über die benötigten
      Berechtigungen für diese Aktion.
    </tremaze-hint>
  }

  <table>
    <tbody>
    <tr>
      <td>Elemente geladen:</td>
      <td>{{ service.loadedItems$ | async | count }} / {{ service.items | count }}</td>
      <td style='min-width: 30px; box-sizing: border-box'>
        @if (service.isLoading$ | async) {
          <mat-spinner diameter='17'></mat-spinner>
        } @else {
          <tremaze-icon icon='lnr-check'></tremaze-icon>
        }
      </td>
    </tr>
    <tr>
      <td>Elemente gelöscht:</td>
      <td>{{ service.successfulItems$ | async | count }} / {{ service.permittedItems$ | async | count }}</td>
      <td style='min-width: 30px; box-sizing: border-box'>
        @if (service.isExecuting$ | async) {
          <mat-spinner diameter='17'></mat-spinner>
        } @else if (service.completed$ | async) {
          <tremaze-icon icon='lnr-check'></tremaze-icon>
        }
      </td>
    </tr>
    <tr>
      <td>Verbleibende Zeit:</td>
      <td>{{ service.remainingTimeEstimate$ | async | duration: 'seconds' }}</td>
    </tr>
      @if (service.failedItems$ | async | count; as errorCount) {
        <tr>
          <td>Fehler:</td>
          <td>{{ errorCount }}</td>
        </tr>
      }
    </tbody>
  </table>
  <small>
    <i>
      Du kannst den Vorgang auch starten, während die Elemente noch geladen werden
    </i>
  </small>

</div>
<div align='end' mat-dialog-actions>
  @if (service.completed$ | async) {
    <button mat-raised-button [mat-dialog-close]='true' color='accent'>Fertig</button>
  } @else {
    <button (click)='service.beginExecution()' [disabled]='disableExecuteButton$ | async' color='warn'
            mat-raised-button>
      Löschen
    </button>
    <button [disabled]='service.isExecuting$ | async' mat-button mat-dialog-close>Abbrechen</button>
  }
</div>
