<h2 mat-dialog-title>Berichtsvorlage bearbeiten</h2>
<div mat-dialog-content>
  <mat-form-field style="width: 100%" appearance="fill">
    <mat-label>HTML Vorlage</mat-label>
    <textarea
      [(ngModel)]="htmlText"
      matInput
      placeholder="Füge hier eine HTML-Vorlage ein."
      rows="9"
    ></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Schließen</button>
  <button mat-raised-button color="accent" (click)="save()">Speichern</button>
</div>
