import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { CostBearer } from '@tremaze/shared/feature/approval/types';

export abstract class CostBearerDataSource {
  abstract createCostBearer(
    costBearer: Omit<CostBearer, 'id'>,
    institutionId: string,
  ): Observable<CostBearer>;

  abstract updateCostBearer(costBearer: CostBearer): Observable<CostBearer>;

  abstract deleteCostBearer(id: string): Observable<boolean>;

  abstract getCostBearerById(id: string): Observable<CostBearer>;

  abstract getPaginatedCostBearers(
    instId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<CostBearer>>;

  abstract getCostBearerByName(
    instId: string,
    name: string,
  ): Observable<CostBearer>;
}
