import {
  Component,
  EventEmitter,
  forwardRef,
  InjectionToken,
  Input,
  NgModule,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ChipsFilterButtonComponent,
  ChipsFilterService,
  SharedUiChipsFilterPopupModule,
} from '@tremaze/shared/ui/chips-filter-popup';
import { RemoteUserDataSource } from '@tremaze/shared/feature/user/data-access';
import { PrivilegeName } from '@tremaze/shared/permission/types';
import { User, UserTypeName } from '@tremaze/shared/feature/user/types';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  combineLatest,
  isObservable,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { Department } from '@tremaze/shared/feature/department/types';
import {
  MatButtonToggle,
  MatButtonToggleGroup,
} from '@angular/material/button-toggle';
import { PersonalConfigUser } from '@tremaze/personal-config';
import { FullNamePipe } from '@tremaze/shared/ui/pipes/full-name-pipe';
import { Pagination } from '@tremaze/shared/models';

export const USER_FILTER_BUTTON_SERVICE = new InjectionToken<
  ChipsFilterService<Department>
>('USER_FILTER_BUTTON_SERVICE');

@Component({
  selector: 'tremaze-user-filter-button',
  template: `
    <tremaze-chips-filter-button
      (filterChange)="onFilterChange($event)"
      [displayWith]="displayWith"
      [loadItems]="loadItems"
      [getAvatar]="getAvatar"
      [getInitials]="getInitials"
      [initialValues]="initialUsers"
      [label]="label ?? 'Benutzer'"
      iconName="lnr lnr-users"
      [keepServiceAfterDestruction]="keepServiceAfterDestruction"
    >
      @if (showUserTypeFilter) {
        <ng-template tremazeChipsFilterButtonFilter>
          <mat-button-toggle-group
            [(ngModel)]="userTypeFilter"
            (ngModelChange)="onChangeUserTypeFilter($event)"
          >
            <mat-button-toggle value="ALL"> Alle</mat-button-toggle>
            <mat-button-toggle value="USER"> Klient*innen</mat-button-toggle>
            <mat-button-toggle value="EMPLOYEE">
              Mitarbeiter*innen
            </mat-button-toggle>
          </mat-button-toggle-group>
        </ng-template>
      }
    </tremaze-chips-filter-button>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserFilterButtonComponent),
      multi: true,
    },
    {
      provide: ChipsFilterService,
      useFactory: (service?: ChipsFilterService<PersonalConfigUser>) =>
        service ?? undefined,
      deps: [[new Optional(), USER_FILTER_BUTTON_SERVICE]],
    },
  ],
})
export class UserFilterButtonComponent implements ControlValueAccessor {
  @Input() userFilterPrivilege?: PrivilegeName[];
  @Input() userTypeIdentifiers?: UserTypeName[] | Observable<UserTypeName[]>;
  @Input() institutionIds?: string[] | Observable<string[]>;
  @Input() label?: string;
  @Output() filterChange = new EventEmitter<PersonalConfigUser[]>();
  @ViewChild(ChipsFilterButtonComponent, { static: true })
  private readonly _chipsFilterButtonComponent!: ChipsFilterButtonComponent<PersonalConfigUser>;

  @Input() keepServiceAfterDestruction = false;

  @Input() initialUsers: PersonalConfigUser[] = [];

  userTypeFilter?: UserTypeName | 'ALL' = 'ALL';

  onChangeUserTypeFilter(userTypeFilter: UserTypeName | 'ALL') {
    this._chipsFilterButtonComponent.reload();
  }

  get showUserTypeFilter(): boolean {
    return !this.userTypeIdentifiers;
  }

  constructor(private readonly _dataSource: RemoteUserDataSource) {}

  private get _userTypeIdentifiers$(): Observable<UserTypeName[]> {
    return isObservable(this.userTypeIdentifiers)
      ? this.userTypeIdentifiers
      : of(this.userTypeIdentifiers ?? []);
  }

  private get _institutionIds$(): Observable<string[]> {
    return isObservable(this.institutionIds)
      ? this.institutionIds
      : of(this.institutionIds ?? []);
  }

  resetFilter() {
    this._chipsFilterButtonComponent.resetFilter();
  }

  onFilterChange(users: PersonalConfigUser[]) {
    this.onChange?.(users);
    this.filterChange.emit(users);
  }

  readonly displayWith = (user: PersonalConfigUser): string =>
    new FullNamePipe().transform(user);

  readonly getAvatar = (user: PersonalConfigUser): FileStorage | undefined =>
    user.avatar;

  readonly getInitials = (user: PersonalConfigUser): string | undefined => {
    const l = [];
    if (user.firstName?.length) {
      l.push(user.firstName[0].toUpperCase());
    }
    if (user.lastName?.length) {
      l.push(user.lastName[0].toUpperCase());
    }
    if (!l.length) {
      return user.username?.[0]?.toUpperCase() ?? '??';
    }
    return l.join('');
  };

  readonly loadItems = (page: number, pageSize: number, filterValue?: string) =>
    combineLatest([this._userTypeIdentifiers$, this._institutionIds$]).pipe(
      switchMap(([userTypeIdentifier, institutionIds]) => {
        let userTypeIdentifierArray: UserTypeName[] | undefined;
        if (userTypeIdentifier?.length) {
          userTypeIdentifierArray = userTypeIdentifier;
        } else if (this.userTypeFilter !== 'ALL') {
          userTypeIdentifierArray = [this.userTypeFilter as UserTypeName];
        }
        return this._dataSource
          .getPaginated(
            {
              filter: {
                sort: 'lastName',
                sortDirection: 'asc',
                page,
                pageSize,
                filterValue,
                filterFields: ['FIRST_NAME', 'LAST_NAME'],
              },
              userTypeIdentifier: userTypeIdentifierArray,
              instIds: institutionIds,
            },
            this.userFilterPrivilege,
          )
          .pipe(
            map((r) => {
              return Pagination.deserialize<PersonalConfigUser>(
                r,
                (user: User) => ({
                  id: user.id,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  username: user.username,
                  avatar: user.profileImage,
                }),
              );
            }),
          );
      }),
    );

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._chipsFilterButtonComponent.setDisabledState(isDisabled);
  }

  writeValue(obj: any): void {
    this._chipsFilterButtonComponent.writeValue(obj);
  }

  private onChange = (_: any) => null;

  private onTouched = (_: any) => null;
}

@NgModule({
  declarations: [UserFilterButtonComponent],
  imports: [
    CommonModule,
    SharedUiChipsFilterPopupModule,
    MatButtonToggleGroup,
    MatButtonToggle,
    FormsModule,
  ],
  exports: [UserFilterButtonComponent],
})
export class SharedFeatureUserFeatureFilterButtonModule {}
