<tremaze-sorted-filtered-paginated-table
  [config]="config"
  [enable-selection]="enableSelection"
  [initialSelection]="initialSelectionValue"
  (selectionChange)="selectionChange.emit($event)"
>
  <ng-container tremazeColumn columnName="description">
    <ng-template let-row>{{ row.description }}</ng-template>
  </ng-container>

  <ng-container tremazeColumn columnName="criteria">
    <ng-template let-row>
      <mat-icon [color]="row.criteria ? 'accent' : 'warn'">
        <span class="lnr {{ row.criteria ? 'lnr-check' : 'lnr-cross' }}"></span>
      </mat-icon>
    </ng-template>
  </ng-container>

  <ng-container tremazeColumn columnName="criteriaType">
    <ng-template let-row>{{
      row.criteriaType === 'CHECKBOX' ? 'Auswahl' : 'Text-Eingabe'
    }}</ng-template>
  </ng-container>
</tremaze-sorted-filtered-paginated-table>
