import { Component } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { EventTemplate } from '@tremaze/shared/feature/event/types';
import { EventTemplateEditFormService } from '../../event-template-edit-form.service';

@Component({
  selector: 'tremaze-event-template-form-address-section',
  templateUrl: './address.component.html',
})
export class EventTemplateFormAddressSectionComponent<T extends EventTemplate> {
  constructor(
    public readonly eventTemplateForm: EventTemplateEditFormService,
  ) {}

  get formGroup(): FormGroup<T> {
    return this.eventTemplateForm?.formGroup as any;
  }
}
