import { Duration } from '@tremaze/duration';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

@staticImplements<Deserializable<TemporalRegistrationRestriction>>()
export class TemporalRegistrationRestriction {
  constructor(
    /**
     * The duration of the restriction in minutes.
     */
    readonly duration: Duration,
    /**
     * The relation of the restriction to the event.
     * BEFORE: The restriction is before the events start date.
     * AFTER: The restriction is after the events start date.
     */
    readonly relation: 'BEFORE' | 'AFTER',
  ) {}

  static deserialize(
    data: any,
    readDurationAsMillis = false,
  ): TemporalRegistrationRestriction {
    if (!data || data instanceof TemporalRegistrationRestriction) {
      return data;
    }

    if (data.duration instanceof Duration) {
      return new TemporalRegistrationRestriction(data.duration, data.relation);
    }

    return new TemporalRegistrationRestriction(
      Duration.fromMinutes(data.duration ?? 0),
      data.relation,
    );
  }
}

@staticImplements<Deserializable<TemporalRegistrationRestrictionGroup>>()
export class TemporalRegistrationRestrictionGroup {
  constructor(
    readonly register?: TemporalRegistrationRestriction,
    readonly cancel?: TemporalRegistrationRestriction,
  ) {}

  static deserialize(
    data: any,
    readDurationAsMillis = false,
  ): TemporalRegistrationRestrictionGroup {
    if (!data || data instanceof TemporalRegistrationRestrictionGroup) {
      return data;
    }
    return new TemporalRegistrationRestrictionGroup(
      TemporalRegistrationRestriction.deserialize(
        data.register,
        readDurationAsMillis,
      ),
      TemporalRegistrationRestriction.deserialize(
        data.cancel,
        readDurationAsMillis,
      ),
    );
  }
}
