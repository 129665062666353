<div class="table-bottom">
  <mat-paginator
    [length]="totalElementCount$ | async"
    [pageIndex]="pageIndex$ | async"
    [pageSizeOptions]="pageSizes"
    [pageSize]="initialPageSize"
    class="gridster-nodrag"
  ></mat-paginator>

  <div class="table-bottom__actions gridster-nodrag" *ngIf="showCreateButton">
    <ng-container *ngIf="!hideCreateButton">
      <!--suppress TypeScriptValidateTypes -->
      <button
        (click)="onClickCreateButton()"
        *tremazeHasPermission="permissionConfig?.createPermissions"
        [matTooltip]="'Neuen Eintrag anlegen'"
        tremazeUnfocus
        color="accent"
        mat-mini-fab
      >
        <mat-icon>
          <span class="{{ 'lnr lnr-plus' }}"></span>
        </mat-icon>
      </button>
    </ng-container>

    <ng-content select="[slot=actions]"></ng-content>
  </div>
</div>
