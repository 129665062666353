import { Component, Inject, Optional } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { EventTemplate } from '@tremaze/shared/feature/event/types';
import { EventTemplateEditFormService } from '../../event-template-edit-form.service';
import {
  EVENT_MODULE_CONFIG,
  EventModuleConfig,
} from '@tremaze/shared/feature/event/module-config';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';
import { RemoteUserDataSource } from '@tremaze/shared/feature/user/data-access';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'tremaze-event-template-form-settings-section',
  templateUrl: './settings.component.html',
})
export class EventTemplateFormSettingsSectionComponent {
  constructor(
    public readonly eventTemplateForm: EventTemplateEditFormService,
    private readonly _userDataSource: RemoteUserDataSource,
    @Optional()
    @Inject(EVENT_MODULE_CONFIG)
    private readonly moduleConfig?: EventModuleConfig,
  ) {}

  get formGroup(): FormGroup<EventTemplate> {
    return this.eventTemplateForm?.formGroup as any;
  }

  get showRegistrationNecessaryCheckbox(): boolean {
    return !this.moduleConfig?.registrationDisabled;
  }

  get canSelectPublicCheckbox(): boolean {
    return this.moduleConfig?.publicEvents !== 'DISABLED';
  }

  get canSelectVisibleForFamilyCheckbox(): boolean {
    return this.moduleConfig?.visibleForFamilySettingEnabled;
  }

  get canCreateVideoMeeting(): boolean {
    return !this.moduleConfig?.videoMeetingsDisabled;
  }

  /**
   *  ONLY EMPLOYEES CHECKBOX
   */
  get canSelectVisibleOnlyForEmployeesCheckbox(): boolean {
    return !this.moduleConfig?.visibleOnlyForEmployeesSettingDisabled;
  }

  readonly isChangeOnlyForEmployeesCheckboxChecked$ =
    this.formGroup.controls.userTypes.valueChanges.pipe(
      startWith(this.formGroup.controls.userTypes.value ?? []),
      filterNotNullOrUndefined(),
      map(
        (userTypes) => userTypes.length == 1 && userTypes[0].name == 'EMPLOYEE',
      ),
    );

  employeeUserTypes$ = this._userDataSource.getAllUserTypes().pipe(
    map((r) => [r.find((u) => u.name === 'EMPLOYEE')]),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  async onChangeOnlyForEmployeesCheckbox(checked: boolean) {
    const employeeUserTypes = await firstValueFrom(this.employeeUserTypes$);
    this.formGroup.controls.userTypes.patchValue(
      checked ? employeeUserTypes : [],
      {
        emitEvent: true,
      },
    );
    this.formGroup.updateValueAndValidity();
    this.formGroup.controls.userTypes.markAsDirty();
  }
}
