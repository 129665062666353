import { createAction, props } from '@ngrx/store';
import { TenantEntity } from './tenant.models';
import { TenantProject } from '@tremaze/shared/feature/tenant/types';

export const init = createAction('[Tenant Page] Init');

export const loadTenantSuccess = createAction(
  '[Tenant/API] Load Tenant Success',
  props<{ tenant: TenantEntity[] }>(),
);

export const loadTenantFailure = createAction(
  '[Tenant/API] Load Tenant Failure',
  props<{ error: any }>(),
);

export const loadTenantProjects = createAction(
  '[Tenant/API] Load Tenant Projects',
);

export const loadTenantProjectsSuccess = createAction(
  '[Tenant/API] Load Tenant Projects Success',
  props<{ projects: TenantProject[] }>(),
);
