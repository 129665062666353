import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { TremazeDate } from '@tremaze/shared/util-date';
import { Country, tryGetCountryByCode } from './country'; // META

// META
@staticImplements<Deserializable<Meta>>()
export class Meta {
  constructor(
    readonly insertDate?: TremazeDate | null,
    readonly editDate?: TremazeDate | null,
    readonly insertUser?: string,
    readonly editUser?: string,
  ) {}

  static deserialize(data: any): Meta {
    if (!data) {
      return null;
    }
    data = data.meta ?? data;
    return !data
      ? null
      : new Meta(
          TremazeDate.deserialize(data.insertDate),
          TremazeDate.deserialize(data.editDate),
          data.insertUser,
          data.editUser,
        );
  }

  equals(meta: Meta) {
    return (
      meta instanceof Meta && JSON.stringify(meta) === JSON.stringify(this)
    );
  }

  copyWith(data: Partial<Meta>): Meta {
    return new Meta(
      data.insertDate ?? this.insertDate,
      data.editDate ?? this.editDate,
      data.insertUser ?? this.insertUser,
      data.editUser ?? this.editUser,
    );
  }
}

// CONTACT INFO
@staticImplements<Deserializable<ContactInfo>>()
export class ContactInfo {
  id: string;

  constructor(
    public email: string = null,
    public mobile: string = null,
    public phone: string = null,
  ) {}

  static deserialize(data: any): ContactInfo {
    return !data ? null : new ContactInfo(data.email, data.mobile, data.phone);
  }
}

// ADDRESS
@staticImplements<Deserializable<Address>>()
export class Address {
  constructor(
    public country: Country | null = null,
    public city: string = '',
    public zip: string = '',
    public street: string = '',
    public addition: string = '',
  ) {}

  get isEmpty(): boolean {
    return !!(
      !this.country &&
      !this.city?.length &&
      !this.zip?.length &&
      !this.street?.length &&
      !this.addition?.length
    );
  }

  get isComplete(): boolean {
    return !!(this.city?.length && this.zip?.length && this.street?.length);
  }

  static deserialize(data: any): Address {
    if (
      data?.country &&
      typeof data.country === 'object' &&
      data.country.code
    ) {
      data.country = data.country.code;
    }
    return !data
      ? null
      : new Address(
          tryGetCountryByCode(data.country),
          data.city,
          data.zip,
          data.street,
          data.addition,
        );
  }

  equals(address: Address): boolean {
    if (!address) {
      return false;
    }
    return Object.keys(address).every((k) => this[k] === address[k]);
  }

  format(): string {
    const outputs: string[] = [];
    if (this.street?.length) {
      outputs.push(this.street);
    }
    if (this.zip?.length || this.city?.length) {
      outputs.push(
        `${this.zip?.length ? `${this.zip} ` : ''}${this.city ?? ''}`,
      );
    }
    if (this.country) {
      outputs.push(this.country.name);
    }
    if (this.addition?.length) {
      outputs.push(this.addition);
    }
    return outputs.join(', ');
  }
}

// PAGINATION
export class Pagination<T> {
  constructor(
    public content: T[],
    public empty?: boolean,
    public first?: boolean,
    public last?: boolean,
    public number?: number,
    public numberOfElements?: number,
    public size?: number,
    public pageable?: Pageable,
    public sort?: Sort,
    public totalElements?: number,
    public totalPages?: number,
  ) {}

  static fromArray<T>(arr: T[]): Pagination<T> {
    return new Pagination<T>(
      arr,
      !arr?.length,
      true,
      true,
      arr?.length,
      arr?.length,
      arr?.length,
      undefined,
    );
  }

  static deserialize<T>(
    data: any,
    itemDeserializer: (d: any) => T,
  ): Pagination<T> {
    if (data.count !== null && data.count !== undefined) {
      data.empty = !!data.count;
      data.content = data.items;
      data.totalElements = data.count;
    }
    return !data
      ? data
      : new Pagination<T>(
          data.content?.map((c) => itemDeserializer(c)),
          data.empty,
          data.first,
          data.last,
          data.number,
          data.numberOfElements,
          data.size,
          data.pageable,
          data.sort,
          data.totalElements,
          data.totalPages,
        );
  }

  copyWith(data: Partial<Pagination<T>>): Pagination<T> {
    return new Pagination(
      data.content ?? this.content,
      data.empty ?? this.empty,
      data.first ?? this.first,
      data.last ?? this.last,
      data.number ?? this.number,
      data.numberOfElements ?? this.numberOfElements,
      data.size ?? this.size,
      data.pageable ?? this.pageable,
      data.sort ?? this.sort,
      data.totalElements ?? this.totalElements,
      data.totalPages ?? this.totalPages,
    );
  }
}

interface Pageable {
  offset: number;
  pageNumber: number;
  paged: boolean;
  unpaged: boolean;
  sort: Sort;
}

interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface TableObject<T> {
  count: number;
  items: T[];
}

export abstract class IdItem {
  id?: string;
}

export interface UPDocker {
  id?: string;
  imageName?: string;
  description?: string;
  version?: string;
}

export interface TremazeTheme {
  accentColor: string;
  accentForegroundColor: string;
  primaryColor: string;
  primaryForegroundColor: string;
  tertiaryColor: string;
  tertiaryForegroundColor: string;
  warnColor: string;
  warnForegroundColor: string;
}

export interface Equatable {
  equals(other: Equatable): boolean;
}
