import { InjectionToken } from '@angular/core';

export interface UserModuleConfig {
  disableUserInstitutionController?: boolean;
  enableSetPasswordOnUserCreation?: boolean;
  enableUsernames?: boolean;
  enableReferencePersonSettings?: boolean;
  showReferencePersonSettingsInUserList?: boolean;
  disableManagedAccountsSettings?: boolean;
  showManagedAccountsSettingsInUserList?: boolean;
}

export const USER_MODULE_CONFIG = new InjectionToken<UserModuleConfig>(
  'USER_MODULE_CONFIG',
);
