import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { CustomFormField } from '../form-field';
import { Meta } from '@tremaze/shared/models';
import { CustomFileFormFieldValue } from '../values/file-value';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

@staticImplements<Deserializable<CustomFileFormField>>()
export class CustomFileFormField implements CustomFormField<FileStorage> {
  readonly fieldType = 'FILE_UPLOAD';

  constructor(
    readonly id: string,
    readonly meta: Meta,
    readonly label: string,
    readonly name: string,
    readonly required: boolean,
    readonly sort: number,
    readonly json_id: string,
    readonly minFileCount?: number,
    readonly maxFileCount?: number,
    readonly maxFileSize?: number,
    readonly allowedFileTypes?: string[],
    readonly filledOutValues?: CustomFileFormFieldValue[],
  ) {}

  static deserialize(data: any): CustomFileFormField {
    return new CustomFileFormField(
      data.id,
      Meta.deserialize(data),
      data.label?.trim(),
      data.name?.trim(),
      data.required ?? false,
      data.sort ?? 0,
      '' + data.json_id,
      data.minFileCount == 0 ? null : data.minFileCount,
      data.maxFileCount == 0 ? null : data.maxFileCount,
      data.maxFileSize == 0 ? null : data.maxFileSize,
      data.allowedFileTypes,
      data.filledOutValues?.map(CustomFileFormFieldValue.deserialize),
    );
  }
}
