<ng-container [formGroup]="formGroup">
  <div class="form-grid">
    <mat-form-field class="form-grid-item-full-width">
      <mat-label>Beschreibungstext</mat-label>
      <tremaze-rich-text-editor
        formControlName="description"
        style="display: block; height: 400px"
      ></tremaze-rich-text-editor>
    </mat-form-field>
  </div>
</ng-container>
