import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { ApprovalService } from '@tremaze/shared/feature/approval/types';
import { PartialBy } from '@tremaze/shared/util/types';

export abstract class ApprovalServiceDataSource {
  abstract getPaginatedApprovalServices(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<ApprovalService>>;

  abstract getPaginatedApprovalServicesForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<ApprovalService>>;

  abstract getApprovalServiceById(id: string): Observable<ApprovalService>;

  abstract deleteApprovalService(id: string): Observable<boolean>;

  abstract createApprovalService(
    approvalService: Omit<ApprovalService, 'id' | 'subApprovalServices'> & {
      subApprovalServices?: PartialBy<ApprovalService, 'id'>[];
    },
    institutionId: string,
  ): Observable<ApprovalService>;

  abstract updateApprovalService(
    approvalService: Omit<ApprovalService, 'subApprovalServices'> & {
      subApprovalServices?: PartialBy<ApprovalService, 'id'>[];
    },
  ): Observable<ApprovalService>;

  abstract getApprovalServicesForUser(
    userId: string,
  ): Observable<ApprovalService[]>;

  abstract getApprovalServiceByName(name: string): Observable<ApprovalService>;

  abstract getIsApprovalShortNameAvailable(
    institutionId: string,
    shortName: string,
  ): Observable<boolean>;
}
