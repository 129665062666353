import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ParticipationCriteria } from '@tremaze/shared/feature/participation-criteria/types';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
} from '@ngneat/reactive-forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'tremaze-participation-criteria-edit',
  templateUrl: './participation-criteria-edit.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ParticipationCriteriaEditComponent implements OnInit {
  isEdit = false;
  formGroup: FormGroup<ParticipationCriteria>;

  constructor(
    private fb: FormBuilder,
    private ref: MatDialogRef<ParticipationCriteriaEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data?: {
      criteria: ParticipationCriteria;
      hideMandatorySetting?: boolean;
    },
  ) {}

  get hideMandatoryCheckbox() {
    return this.data?.hideMandatorySetting;
  }

  get descriptionControl(): null | AbstractControl {
    return this.formGroup.get('description');
  }

  get criteriaTypeControl(): null | AbstractControl {
    return this.formGroup.get('criteriaType');
  }

  ngOnInit(): void {
    this.isEdit = !!this.data?.criteria;
    this.formGroup = this.buildFormGroup(
      this.data?.criteria || new ParticipationCriteria(),
    );
  }

  buildFormGroup(criteria: ParticipationCriteria): FormGroup {
    const editModel = {
      ...criteria,
      description: this.fb.control(criteria.description, Validators.required),
      type: this.fb.control(criteria.criteriaType, Validators.required),
    };
    return this.fb.group(editModel);
  }

  onClickSubmit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.ref.close(ParticipationCriteria.deserialize(this.formGroup.value));
    }
  }
}
