import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BulkActionComponentService } from './bulk-action-component.service';
import { bulkActionConfig } from './tokens';
import { CountPipe } from '@tremaze/count-pipe';
import { SharedUiHintModule } from '@tremaze/shared/ui/hint';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { DurationPipe } from '@tremaze/shared/ui/pipes/duration';
import { combineLatest, map } from 'rxjs';
import { mapAnyTrue } from '@tremaze/shared/util/rxjs';

@Component({
  selector: 'tremaze-bulk-action-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressBarModule,
    CountPipe,
    SharedUiHintModule,
    MatProgressSpinnerModule,
    IconComponent,
    DurationPipe,
  ],
  templateUrl: './bulk-action-dialog.component.html',
  styleUrl: './bulk-action-dialog.component.scss',
  providers: [
    {
      provide: bulkActionConfig,
      useExisting: MAT_DIALOG_DATA,
    },
    BulkActionComponentService,
  ],
})
export class BulkActionDialogComponent {
  readonly service = inject(BulkActionComponentService);

  readonly disableExecuteButton$ = combineLatest([
    this.service.isExecuting$,
    this.service.permittedItems$.pipe(map((r) => r.length === 0)),
  ]).pipe(mapAnyTrue());
}
