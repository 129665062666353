// TODO: Map values as well
import {
  CustomFileFormField,
  CustomForm,
  CustomFormField,
  CustomMultiSelectFormField,
  CustomTextBoxFormField,
} from '@tremaze/shared/feature/custom-forms/types';
import {
  BaseFieldDTO,
  CustomFormCreateDTO,
  CustomFormEditDTO,
  FieldsDTOCombined,
  FileFieldDTO,
  MultiSelectDTO,
  MultiSelectItemDTO,
  TextBoxFieldDTO,
} from './types';

function mapFieldToDTO<T extends CustomFormField<unknown>>(
  field: T,
): FieldsDTOCombined {
  const base: BaseFieldDTO = {
    label: field.label,
    required: field.required,
    fieldType: field.fieldType,
    name: field.name,
    sort: field.sort,
  };
  let f;
  switch (field.fieldType) {
    case 'TEXTBOX':
      f = field as CustomTextBoxFormField;
      return <TextBoxFieldDTO>{
        ...base,
        minLength: f.minLength,
        maxLength: f.maxLength,
        enableLineBreaks: f.enableLineBreaks ?? false,
      };
    case 'MULTISELECT':
      f = field as unknown as CustomMultiSelectFormField<unknown>;
      return <MultiSelectDTO>{
        ...base,
        type: f.type,
        items: f.items.map(
          ({ label, value, sort }) =>
            <MultiSelectItemDTO>{ label, value, sort },
        ),
        minSelectedItems: f.minSelectedItems,
        maxSelectedItems: f.maxSelectedItems,
      };
    case 'FILE_UPLOAD':
      f = field as CustomFileFormField;
      return <FileFieldDTO>{
        ...base,
        minFileCount: f.minFileCount,
        maxFileCount: f.maxFileCount,
        maxFileSize: f.maxFileSize,
      };
  }
}

export function toCreateDTO(form: CustomForm): CustomFormCreateDTO {
  return {
    name: form.name,
    fields: form.fields.map(mapFieldToDTO),
    feature: form.feature,
    instIds: form.institutions?.map((i) => i.id ?? ''),
  };
}

export function toEditDTO(form: CustomForm): CustomFormEditDTO {
  return {
    oldFormId: form.id,
    ...toCreateDTO(form),
  };
}
