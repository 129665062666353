import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  CustomFormFieldsAbstractControl,
  CustomFormsMultiSelectFieldType,
  CustomFormsMultiSelectItemFieldType,
} from './form-models';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  skip,
  startWith,
  Subject,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';
import { replaceUmlauts } from '@tremaze/shared/util-utilities';
import { CustomFormFieldType } from '@tremaze/shared/feature/custom-forms/types';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';

function convertDisplayStringToFieldValue(displayString: string): string {
  return replaceUmlauts(displayString)
    .trim()
    .replaceAll(/[^a-zA-Z0-9_-]/g, '_');
}

/**
 * Observes value changes within the form and makes adjustments to the form's values and/or behavior accordingly.
 */
@Injectable({
  providedIn: 'any',
})
export class CustomFormEditValueObserverService {
  private _fieldRemoved$ = new Subject<AbstractControl>();

  constructor(private readonly _confirmationService: ConfirmationService) {}

  /**
   * @description Completes all streams to prevent memory leaks. Should be called when the component is destroyed.
   */
  public destroy(): void {
    this._fieldRemoved$.complete();
  }

  /**
   * @description Cancels the value changes subscription for a multi select item form group. Should be called whenever a field is removed from the form.
   * @param control The form group to cancel the subscription for.
   */
  public removeObserverForControl(control: AbstractControl): void {
    this._fieldRemoved$.next(control);
  }

  /**
   * @description Sets up the value changes subscription for a form group based on the fields type.
   * @param fieldGroup The form group to set up the subscription for.
   */
  public setUpFormFieldValueObserver(
    fieldGroup: FormGroup<CustomFormFieldsAbstractControl>,
  ): void {
    const type: CustomFormFieldType | null =
      fieldGroup.controls.fieldType.value;
    if (type) {
      switch (type) {
        case 'TEXTBOX':
          break;
        case 'MULTISELECT':
          this._setUpMultiSelectFormGroupSubscription(fieldGroup);
          break;
        case 'FILE_UPLOAD':
          break;
      }
    }
  }

  /**
   * @description Sets up the value changes subscription for a multi select item form group. Updates the value of 'value' based on value changes of 'displayString'.
   * @param group The form group to set up the subscription for.
   */
  public setUpValueChangesSubscriptionForMultiSelectOption(
    group: FormGroup<CustomFormsMultiSelectItemFieldType<unknown>>,
    skipEmission = 0,
  ): void {
    group.controls.label.valueChanges
      .pipe(
        skip(skipEmission),
        filterNotNullOrUndefined(),
        filter(() => group.controls.value.pristine),
        map(convertDisplayStringToFieldValue),
        tap((v) => group.controls.value.patchValue(v, { emitEvent: false })),
        takeUntil(this._fieldRemoved$.pipe(filter((c) => c === group))),
      )
      .subscribe();
  }

  /**
   * @description Sets up the value changes subscription for a multi select form group. Updates the value of 'minSelectedItems' and 'maxSelectedItems' based on value changes of 'required', 'type' and 'items'.
   * @param formGroup The form group to set up the subscription for.
   */
  private _setUpMultiSelectFormGroupSubscription(
    formGroup: FormGroup<CustomFormsMultiSelectFieldType<unknown>>,
  ): void {
    const typeControl = formGroup.controls.type;
    const requiredControl = formGroup.controls.required;
    const itemsControl = formGroup.controls.items;
    const minSelectedItemsControl = formGroup.controls.minSelectedItems;
    const maxSelectedItemsControl = formGroup.controls.maxSelectedItems;
    const fieldRemoved$ = this._fieldRemoved$.pipe(
      filter((c) => c === formGroup),
    );
    if (
      typeControl &&
      itemsControl &&
      minSelectedItemsControl &&
      maxSelectedItemsControl
    ) {
      const typeValue$ = typeControl.valueChanges.pipe(
        startWith(typeControl.value),
        distinctUntilChanged(),
      );
      const requiredValue$ = requiredControl.valueChanges.pipe(
        startWith(requiredControl.value),
        distinctUntilChanged(),
      );
      const itemsLength$ = itemsControl.valueChanges.pipe(
        map((i) => i.length),
        startWith(itemsControl.value.length),
      );
      const minSelectedItemsValue$ = minSelectedItemsControl.valueChanges.pipe(
        startWith(minSelectedItemsControl.value),
        distinctUntilChanged(),
      );
      const maxSelectedItemsValue$ = maxSelectedItemsControl.valueChanges.pipe(
        startWith(maxSelectedItemsControl.value),
        distinctUntilChanged(),
      );

      combineLatest([
        typeValue$,
        minSelectedItemsValue$.pipe(filterNotNullOrUndefined()),
      ])
        .pipe(
          tap(([fieldType, minSelectedItems]) => {
            if (fieldType === 'MULTISELECT') {
              if (minSelectedItems === 1) {
                if (!requiredControl.value) {
                  requiredControl.patchValue(true, {});
                }
              } else if (minSelectedItems === 0) {
                if (requiredControl.value) {
                  requiredControl.patchValue(false, {});
                }
              }
              return;
            }
            const expectedRequiredValue = minSelectedItems > 0;
            if (requiredControl.value !== expectedRequiredValue) {
              requiredControl.patchValue(expectedRequiredValue, {});
            }
          }),
          takeUntil(fieldRemoved$),
        )
        .subscribe();

      combineLatest([typeValue$, requiredValue$])
        .pipe(
          tap(([fieldType, required]) => {
            if (fieldType === 'MULTISELECT') {
              if (required) {
                if ((minSelectedItemsControl.value ?? 0) < 1) {
                  minSelectedItemsControl.patchValue(1, {});
                }
              } else {
                if (minSelectedItemsControl.value === 1) {
                  minSelectedItemsControl.patchValue(0, {});
                }
              }
              return;
            }
            const expectedMinSelectedItemsValue = required ? 1 : 0;
            if (
              minSelectedItemsControl.value !== expectedMinSelectedItemsValue
            ) {
              minSelectedItemsControl.patchValue(
                expectedMinSelectedItemsValue,
                {},
              );
            }
          }),
          takeUntil(fieldRemoved$),
        )
        .subscribe();

      typeValue$
        .pipe(
          filter((fieldType) => fieldType !== 'MULTISELECT'),
          withLatestFrom(maxSelectedItemsValue$),
          tap(([_, value]) => {
            if (value !== 1) {
              maxSelectedItemsControl.patchValue(1, {});
            }
          }),
          takeUntil(fieldRemoved$),
        )
        .subscribe();

      combineLatest([itemsLength$])
        .pipe(
          tap(([itemsLength]) => {
            for (const control of [
              minSelectedItemsControl,
              maxSelectedItemsControl,
            ]) {
              if (control) {
                const { value } = control;
                if (typeof value === 'number') {
                  if (value > itemsLength) {
                    control.patchValue(itemsLength, {});
                  }
                }
              }
            }
          }),
          takeUntil(fieldRemoved$),
        )
        .subscribe();
    }
  }
}
