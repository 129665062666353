import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injectable,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Observable, zip } from 'rxjs';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { MatListModule } from '@angular/material/list';
import { FormControl } from '@ngneat/reactive-forms';
import { MatButtonModule } from '@angular/material/button';
import { mapNullOrUndefined } from '@tremaze/shared/util/rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { InstitutionREADDataSource } from '@tremaze/shared/feature/institution/data-access';

interface Settings {
  text?: string;
  customOptions?: {
    label: string;
    value: string;
  }[];
}

interface DialogData extends Settings {
  choices$: Observable<Institution[]>;
}

@Component({
  selector: 'tremaze-template-select-dialog',
  template: `
    <span mat-dialog-title>Einrichtung wählen</span>
    <div mat-dialog-content>
      <p>{{ text }}</p>
      <mat-selection-list [multiple]="false" [formControl]="formControl">
        <ng-container *ngIf="settings.customOptions?.length">
          <mat-list-option
            *ngFor="let customOption of settings.customOptions"
            [value]="customOption.value"
          >
            {{ customOption.label }}
          </mat-list-option>
        </ng-container>
        <mat-list-option
          *ngFor="let institution of institutions$ | async"
          [value]="institution.id"
          >{{ institution.name }}</mat-list-option
        >
      </mat-selection-list>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="null">Abbrechen</button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="disableSubmitButton$ | async"
        (click)="onClickSubmitButton()"
      >
        Bestätigen
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstitutionSelectionDialogComponent {
  readonly formControl = new FormControl<string[]>();

  constructor(
    private _ref: MatDialogRef<InstitutionSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly _data: DialogData,
  ) {}

  get text(): string {
    return this._data.text ?? 'Bitte wähle eine Einrichtung';
  }

  get settings(): Settings {
    return this._data;
  }

  get disableSubmitButton$(): Observable<boolean> {
    return this.formControl.value$.pipe(mapNullOrUndefined());
  }

  get institutions$(): Observable<Institution[]> {
    return this._data.choices$;
  }

  onClickSubmitButton(): void {
    this._ref.close(this.formControl.value[0]);
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatListModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  declarations: [InstitutionSelectionDialogComponent],
})
export class SharedFeatureInstitutionFeatureSelectionModule {}

@Injectable({ providedIn: SharedFeatureInstitutionFeatureSelectionModule })
export class InstitutionSelectionService {
  constructor(
    private readonly _dialog: MatDialog,
    private _dataSource: InstitutionREADDataSource,
  ) {}

  selectFromIdPool(
    pool: string[],
    settings?: Settings,
  ): Observable<null | string> {
    const data: DialogData = {
      choices$: this._getChoices$(pool),
      ...(settings ?? {}),
    };
    return this._dialog
      .open(InstitutionSelectionDialogComponent, {
        data,
      })
      .afterClosed();
  }

  private _getChoices$(pool: string[]): Observable<Institution[]> {
    return zip(...pool.map((instId) => this._dataSource.getFreshById(instId)));
  }
}
