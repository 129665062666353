import { CountryCode } from '@tremaze/shared/models';

/**
 * Converts a country code string to a flag emoji.
 * @param countryCode The country code string to convert.
 */
export function countryCodeToFlagEmoji(countryCode: CountryCode): string {
  const OFFSET = 127397; // Offset between uppercase letter "A" (65) and regional indicator symbol letter "A" (127462)

  // Create the flag emoji by adding the offset to each character's code point
  let flag = '';
  for (let i = 0; i < countryCode.length; i++) {
    flag += String.fromCodePoint(countryCode.charCodeAt(i) + OFFSET);
  }

  return flag;
}

export const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

export const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

export function normalizePath(
  path: string,
  cfg?: { withLeadingSlash?: boolean; withTrailingSlash?: boolean },
): string {
  const noDuplicates = path.replace(/\/+/g, '/');
  const noTrailingSlash =
    noDuplicates.endsWith('/') && !cfg?.withTrailingSlash
      ? noDuplicates.slice(0, -1)
      : noDuplicates;
  const leadingSlash =
    noTrailingSlash.startsWith('/') || cfg?.withLeadingSlash
      ? noTrailingSlash
      : `/${noTrailingSlash}`;
  return leadingSlash;
}
