import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UnapprovedUsersCountDataSource {
  constructor(protected http: HttpClient) {}

  getUnapprovedUsersCount(): Observable<number> {
    return this.http.get<number>('/users/unapproved/count');
  }
}
