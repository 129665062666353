import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable, of, switchMap } from 'rxjs';
import {
  CustomForm,
  CustomFormFeature,
} from '@tremaze/shared/feature/custom-forms/types';
import { CustomFormDataSource } from '@tremaze/shared/feature/custom-forms/data-access';
import { CustomFormEditDialogComponent } from './custom-form-edit-dialog/custom-form-edit-dialog.component';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';

@Injectable()
export class CustomFormEditService {
  constructor(
    private readonly _dialog: MatDialog,
    private readonly _dataSource: CustomFormDataSource,
    private readonly _confirmationService: ConfirmationService,
  ) {}

  create(config?: {
    feature?: CustomFormFeature;
    institutionId?: string;
  }): Observable<CustomForm | undefined> {
    return this._dialog
      .open(CustomFormEditDialogComponent, {
        panelClass: 'relative-dialog',
        disableClose: true,
        data: config,
      })
      .afterClosed();
  }

  edit(formId: string): Observable<CustomForm | undefined> {
    return this._dialog
      .open(CustomFormEditDialogComponent, {
        data: { formId },
        panelClass: 'relative-dialog',
        disableClose: true,
      })
      .afterClosed();
  }

  deleteById(formId: string): Observable<boolean> {
    return this._dataSource.deleteById(formId);
  }

  removeFormFromInstitution(
    formId: string,
    institutionId: string,
  ): Observable<boolean> {
    return this._confirmationService.askUserForConfirmation().pipe(
      map((c) => c?.confirmed),
      switchMap((confirmed) =>
        !confirmed
          ? of(false)
          : this._dataSource.removeFormFromInstitution(formId, institutionId),
      ),
    );
  }

  setPreviewHTMLonForm(formId: string, html: string): Observable<boolean> {
    return this._dataSource.setPreviewHTMLonForm(formId, html);
  }

  getPreviewHTMLofForm(formId: string): Observable<string> {
    return this._dataSource.getPreviewHTMLofForm(formId);
  }

  setCSVExportTemplate(
    formId: string,
    csvExportTemplate: string,
  ): Observable<boolean> {
    return this._dataSource.setCSVExportTemplate(formId, csvExportTemplate);
  }

  getCSVExportTemplate(formId: string): Observable<string> {
    return this._dataSource.getCSVExportTemplate(formId);
  }
}
