import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomFormsFileFieldType } from '../../form-models';

@Component({
  selector: 'tremaze-custom-form-fields-file-edit',
  template: `
    <fieldset [formGroup]="formGroup">
      <legend>Einstellungen Dateiupload</legend>

      <mat-form-field>
        <mat-label>Max. Dateien</mat-label>
        <input matInput formControlName="maxFileCount" type="number" />
      </mat-form-field>
    </fieldset>
  `,
  styles: [],
})
export class CustomFormFieldsFileEditComponent {
  @Input() formGroup!: FormGroup<CustomFormsFileFieldType>;
}
