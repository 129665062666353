import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import { AppComponent } from './app.component';
import { Router, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_CONFIG_STAGES,
  AppConfigService,
} from '@tremaze/shared/util-app-config';
import { environment } from '../environments/environment';
import { SharedCoreAuthV2Module } from '@tremaze/shared/core/auth-v2';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { SharedNotificationModule } from '@tremaze/shared/notification';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppStateService } from '@tremaze/shared/util-app-state';
import { PermissionCheckService } from '@tremaze/shared/permission/services';
import { LocalStorage } from '@tremaze/shared/core/storage/local-storage';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { TremazeDateAdapter } from '@tremaze/shared/util-date/angular/adapter';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { TremazeDate } from '@tremaze/shared/util-date';
import { BaseInterceptor } from '@tremaze/shared/core/base-http-interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import packageInfo from '../../../../package.json';
import { SharedCoreMsalModule } from '@tremaze/shared/core/msal';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSelectModule } from '@angular/material/select';
import {
  NGX_MAT_DATE_FORMATS,
  NGX_MAT_NATIVE_DATE_FORMATS,
  NgxMatDateAdapter,
} from '@angular-material-components/datetime-picker';
import {
  GenderDataSource,
  RemoteCCGenderDataSource,
} from '@tremaze/shared/feature/gender/data-access';
import { DATA_SOURCE_PROVIDERS } from './data-source-providers';
import { MODULE_CONFIG_PROVIDERS } from './module-configs';
import { provideInstitutionContextService } from '@tremaze/shared/feature/institution/shared/singletons';
import { provideTenantConfigService } from '@tremaze/shared/tenant-config';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { SharedFeatureEventFeatureEventScopeSelectorModule } from '@tremaze/shared/feature/event/feature/event-scope-selector';
import { SharedFeatureEventFeatureEventEditModule } from '@tremaze/event-shared';
import { QM_ADVISOR_ENABLED_TENANT_ID } from '@tremaze/qm-advisor';

@Injectable({ providedIn: 'root' })
class TageaControlConfigService implements AppConfigService {
  basePath = environment.basePath;
  clientId = environment.clientId;
  authIssuer = environment.authIssuer;
  enableAllFeatures = environment.enableAllFeatures;
  allowMaterialComponents = true;
  clientSecret: string;

  get logoPaths() {
    return {
      light: environment.lightThemeLogoPath,
      dark: environment.darkThemeLogoPath,
      icon: environment.iconPath,
    };
  }

  state: APP_CONFIG_STAGES = environment?.stage;
  disablePermissionChecks = false;
  readonly productName = environment.productName ?? 'Tagea';

  get version() {
    return packageInfo.version;
  }

  disableFreshChat = environment.disableFreshChat;

  get isProd(): boolean {
    return this.state === 'PROD';
  }
}

function _getSentryProviders() {
  if (environment.stage === 'PROD' || environment.stage === 'QS') {
    return [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    ];
  }
  return [];
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedNotificationModule,
    MatNativeDateModule,
    MatBottomSheetModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    SharedFeatureEventFeatureEventScopeSelectorModule,
    SharedCoreMsalModule.forRoot(),
    SharedFeatureEventFeatureEventEditModule,
    // Need to import this here to fix the stupid "scroll-strategy" error
    MatSelectModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () =>
            import('@tremaze/tagea-control-center/main').then(
              (m) => m.TageaControlCenterMainModule,
            ),
        },
      ],
      { initialNavigation: 'enabledBlocking' },
    ),
    SharedCoreAuthV2Module.forRoot(),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    !environment.production
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    StoreRouterConnectingModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
      registrationStrategy: 'registerWhenStable:10000',
    }),
    TourMatMenuModule,
  ],
  providers: [
    {
      provide: AppConfigService,
      useClass: TageaControlConfigService,
    },
    PermissionCheckService,
    AppStateService,
    JsonSerializer,
    LocalStorage,
    ...DATA_SOURCE_PROVIDERS,
    ...MODULE_CONFIG_PROVIDERS,
    { provide: GenderDataSource, useClass: RemoteCCGenderDataSource },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2500 },
    },
    { provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true },
    { provide: DateAdapter, useExisting: TremazeDateAdapter },
    { provide: NgxMatDateAdapter, useExisting: TremazeDateAdapter },
    provideTenantConfigService(),
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: {
        ...NGX_MAT_NATIVE_DATE_FORMATS,
        parse: {
          dateInput: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
          },
        },
        display: {
          ...NGX_MAT_NATIVE_DATE_FORMATS.display,
          dateInput: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
          },
        },
      },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: Storage, useClass: LocalStorage },
    {
      provide: QM_ADVISOR_ENABLED_TENANT_ID,
      useValue: environment.enableVitaAdvisorFor,
    },
    ..._getSentryProviders(),
    provideInstitutionContextService(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

registerLocaleData(localeDe);
TremazeDate.newJSONFormat = true;
