import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportDataType } from '../../types/src';

@Component({
  selector: 'tremaze-export-data-type-selector',
  template: `
    <span mat-dialog-tite>Dateityp für Export</span>
    <div mat-dialog-content style="flex-direction: column; overflow: hidden;">
      <div style="padding-top: 20px;">
        <p>Bitte wähle den Dateityp, den du exportieren möchtest:</p>
        <div>
          <mat-radio-group
            [(ngModel)]="selected"
            style="display: flex; flex-direction: column;"
          >
            <mat-radio-button
              *ngFor="let item of data"
              [value]="item"
              style="margin-top: 10px;"
            >
              {{ item?.viewName | uppercase }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-raised-button [mat-dialog-close]="selected" color="accent">
        Exportieren
      </button>
      <button mat-button mat-dialog-close>Abbrechen</button>
    </div>
  `,
  styles: [],
})
export class ExportDataTypeSelectorComponent {
  selected: ExportDataType = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ExportDataType[]) {
    this.selected = data?.[0];
  }
}
