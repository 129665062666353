import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirStorage } from '@tremaze/shared/feature/file-storage/types';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { Linearicon } from '@tremaze/shared/ui';
import { MatTooltip } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import animations from '../../animations';
import {
  MatMenu,
  MatMenuContent,
  MatMenuItem,
  MatMenuTrigger,
} from '@angular/material/menu';
import { MatIconButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { Observable } from 'rxjs';
import { FolderViewItemNameInputComponent } from '../../item-name-input/folder-view-item-name-input.component';
import { FolderViewItemIconComponent } from '../../item-icon/folder-view-item-icon.component';
import { FolderViewFolderContextmenuComponent } from '../../folder-contextmenu/folder-view-folder-contextmenu.component';

@Component({
  selector:
    'tremaze-folder-view-grid-layout-folder-item, a[tremaze-folder-view-grid-layout-folder-item], button[tremaze-folder-view-grid-layout-folder-item]',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    MatTooltip,
    ReactiveFormsModule,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
    MatIconButton,
    MatRipple,
    MatMenuContent,
    FolderViewItemNameInputComponent,
    FolderViewItemIconComponent,
    FolderViewFolderContextmenuComponent,
  ],
  templateUrl: './folder-view-grid-layout-folder-item.component.html',
  styleUrl: './folder-view-grid-layout-folder-item.component.scss',
  animations: [animations.itemAnimation],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-grid-layout-folder-item folder-view-grid-layout-item',
    '[@item]': '',
  },
})
export class FolderViewGridLayoutFolderItemComponent {
  @ViewChild('input') private _input?: ElementRef<HTMLInputElement>;

  @Input() folder?: DirStorage;

  @Output() readonly submitFolderName = new EventEmitter<string>();
  @Output() readonly cancelCreateFolder = new EventEmitter<void>();
  @Output() readonly deleteFolder = new EventEmitter<void>();
  @Output() readonly openFolder = new EventEmitter<void>();

  @HostBinding('class.folder-view-grid-layout-item--Selected')
  @Input()
  selected = false;

  @Input() canWrite$?: Observable<boolean>;

  private _isCreateFolderItem = false;
  private _isEditingFolderName = false;

  @Input({ transform: booleanAttribute })
  get isCreateFolderItem(): boolean {
    return this._isCreateFolderItem;
  }

  set isCreateFolderItem(value: boolean) {
    this._isCreateFolderItem = value;
  }

  get isEditingFolderName(): boolean {
    return this._isEditingFolderName;
  }

  get isSystemDir(): boolean {
    return this.folder?.isSystemDir ?? false;
  }

  get icon(): Linearicon {
    if (this.isSystemDir) {
      return 'lnr-folder-star';
    }

    return 'lnr-folder';
  }

  onSubmitText(value: string) {
    this.submitFolderName.emit(value);
    this._isEditingFolderName = false;
  }

  @HostListener('keyup.escape')
  onCancel() {
    if (this.isCreateFolderItem) {
      this.cancelCreateFolder.emit();
    } else if (this.isEditingFolderName) {
      this._isEditingFolderName = false;
    }
  }

  onClickRenameFolder() {
    this._isEditingFolderName = true;
  }
}
