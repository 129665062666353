import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParticipationCriteria } from '@tremaze/shared/feature/participation-criteria/types';

@Component({
  template: `
    <span mat-dialog-title>Kriterien wählen</span>
    <div mat-dialog-content>
      <tremaze-participation-criteria-list
        enable-selection
        [initialSelectionValue]="selection"
        (selectionChange)="result = $event"
        [mandatory]="mandatory"
      ></tremaze-participation-criteria-list>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Schließen</button>
      <button mat-raised-button [mat-dialog-close]="result" color="accent">
        Auswahl bestätigen
      </button>
    </div>
  `,
  encapsulation: ViewEncapsulation.Emulated,
})
export class ParticipationCriteriaSelectionComponent implements OnInit {
  selection: ParticipationCriteria[] = [];
  result: ParticipationCriteria[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data?: {
      preSelected: ParticipationCriteria[];
      mandatory?: boolean;
    },
  ) {}

  get mandatory() {
    return this.data?.mandatory;
  }

  ngOnInit(): void {
    this.selection = this.data?.preSelected || [];
    this.result = [...this.selection];
  }
}
