import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { map } from 'rxjs';
import { QmAdvisorComponent } from './qm-advisor.component';
import { Dialog } from '@angular/cdk/dialog';

export const QM_ADVISOR_ENABLED_TENANT_ID = new InjectionToken<
  string | undefined
>('QM_ADVISOR_ENABLED_TENANT_ID');

export const ADVISOR_CLASS = 'tremaze-qm-advisor';
export const ADVISOR_BACKDROP_CLASS = 'tremaze-qm-advisor-backdrop';

@Injectable({
  providedIn: 'root',
})
export class QmAdvisorService {
  constructor(
    @Inject(QM_ADVISOR_ENABLED_TENANT_ID)
    private enabledTenantId: string | undefined,
    private authService: AuthV2Service,
    private dialog: Dialog,
  ) {}

  showAdvisor$ = this.authService.activeTenant$.pipe(
    map(
      (tenant) =>
        this.enabledTenantId != undefined &&
        this.enabledTenantId != '' &&
        tenant?.id === this.enabledTenantId,
    ),
  );

  openAdvisor() {
    const panelElement = document.getElementsByClassName(ADVISOR_CLASS)[0];
    const backdropElement = document.getElementsByClassName(
      ADVISOR_BACKDROP_CLASS,
    )[0];

    // If the dialog is already open, just show it
    if (
      panelElement &&
      panelElement instanceof HTMLElement &&
      backdropElement &&
      backdropElement instanceof HTMLElement
    ) {
      panelElement.style.display = 'block';
      backdropElement.style.display = 'block';
      return;
    }

    // Else open the dialog
    this.dialog.open(QmAdvisorComponent, {
      backdropClass: ADVISOR_BACKDROP_CLASS,
      hasBackdrop: true,
      panelClass: ADVISOR_CLASS,
      disableClose: true,
    });
  }
}
