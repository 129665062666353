import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import the PdfViewer Module for the PDF Viewer component
import { MatDialogActions, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatButton, MatIconButton } from '@angular/material/button';
import { HtmlPreviewDialogComponent } from './application/html-preview/html-preview-dialog.component';
import { HtmlPreviewService } from './application/html-preview/html-preview.service';
import { SafePipe } from './application/html-preview/safe.pipe';
import { ReportBlockComponent } from './application/report-block/report-block.component';
import { MatIcon } from '@angular/material/icon';
import { NoReportSvgComponent } from './application/report-block/icons/no-report-svg';
import { ReportAvailableSvgComponent } from './application/report-block/icons/available-report-svg';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReportRepository } from './domain/report.repository';
import { RemoteReportRepository } from './infrastructure/remote-report.repository';
import { MatProgressBar } from '@angular/material/progress-bar';
import { PdfViewerModule } from '@tremaze/shared/feature/pdf-viewer';

/**
 *  When using this module following styles have to be included globally in the styles.scss file:
 *
 * @import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
 * @import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
 * @import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
 * @import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
 * @import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
 * @import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
 * @import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
 * @import '../../../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';
 * @import '../../../node_modules/@syncfusion/ej2-notifications/styles/material.css';
 *
 */

@NgModule({
  imports: [
    CommonModule,
    PdfViewerModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatIcon,
    MatIconButton,
    MatProgressSpinnerModule,
    MatProgressBar,
  ],
  providers: [
    HtmlPreviewService,
    {
      provide: ReportRepository,
      useClass: RemoteReportRepository,
    },
  ],
  declarations: [
    HtmlPreviewDialogComponent,
    SafePipe,
    ReportBlockComponent,
    NoReportSvgComponent,
    ReportAvailableSvgComponent,
  ],
  exports: [ReportBlockComponent],
})
export class ReportModule {}
