import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { DataTableDataSource } from '@tremaze/shared/ui/data-table';
import { FolderViewItemIconComponent } from '../../item-icon/folder-view-item-icon.component';
import { FolderViewListLayoutDataSource } from './data-table-data-source';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FolderViewFileContextmenuComponent } from '../../file-contextmenu/folder-view-file-contextmenu.component';
import { FolderViewFolderContextmenuComponent } from '../../folder-contextmenu/folder-view-folder-contextmenu.component';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  take,
  tap,
} from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { FolderViewItemNameInputComponent } from '../../item-name-input/folder-view-item-name-input.component';
import { FolderViewSelectionType } from '../../../types';
import { MatTableModule } from '@angular/material/table';
import { DragZoneItemDirective, DropZoneDirective } from '@tremaze/drag-zone';
import { FileDropInDropZoneDirective } from '@tremaze/file-drop-in';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import {
  MatSlideToggle,
  MatSlideToggleChange,
} from '@angular/material/slide-toggle';
import { BytesPipe } from '@tremaze/bytes-pipe';
import { FolderViewPersonalSettingsService } from '../../../services/folder-view-personal-settings.service';

@Component({
  selector: 'tremaze-folder-view-list-layout-list',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    FolderViewItemIconComponent,
    IconComponent,
    MatMenuModule,
    MatButtonModule,
    FolderViewFileContextmenuComponent,
    FolderViewFolderContextmenuComponent,
    FolderViewItemNameInputComponent,
    ReactiveFormsModule,
    DragZoneItemDirective,
    DropZoneDirective,
    FileDropInDropZoneDirective,
    MatCheckboxModule,
    MatSortHeader,
    MatSort,
    MatSlideToggle,
    BytesPipe,
  ],
  providers: [
    FolderViewListLayoutDataSource,
    {
      provide: DataTableDataSource,
      useExisting: FolderViewListLayoutDataSource,
    },
  ],
  templateUrl: './folder-view-list-layout-list.component.html',
  styleUrl: './folder-view-list-layout-list.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-list-layout-list',
  },
})
export class FolderViewListLayoutListComponent implements OnDestroy {
  private readonly _dataSource = inject(FolderViewListLayoutDataSource);
  private readonly _cdRef = inject(ChangeDetectorRef);
  private readonly _personalSettingsService = inject(
    FolderViewPersonalSettingsService,
  );

  readonly initialSort$ = this._personalSettingsService.settings$.pipe(
    take(1),
    map(
      (r) =>
        r.sort ?? {
          field: 'name',
          direction: 'asc',
        },
    ),
    tap((r) =>
      this._dataSource.sort$.next({
        field: r.field as any,
        direction: r.direction as any,
      }),
    ),
  );

  readonly initialMixFilesAndFolders$ =
    this._personalSettingsService.settings$.pipe(
      take(1),
      map((r) => r.mixFilesAndFolders ?? false),
      tap((r) => this._dataSource.mixFilesAndFolders$.next(r)),
    );

  private _folders: DirStorage[] = [];

  @Input() set folders(value: DirStorage[]) {
    this._folders = value;
    this.selectedFolders = [];
    this._dataSource.folders$.next(value);
  }

  private _files: FileStorage[] = [];

  @Input() set files(value: FileStorage[]) {
    this._files = value;
    this.selectedFiles = [];
    this._dataSource.files$.next(value);
  }

  @Input() selectedFiles: FileStorage[] = [];
  @Input() selectedFolders: DirStorage[] = [];

  get hasSelection() {
    return this.selectedFiles.length > 0 || this.selectedFolders.length > 0;
  }

  get isAllSelected() {
    return (
      this.hasSelection &&
      this.selectedFiles.length === this._files.length &&
      this.selectedFolders.length === this._folders.length
    );
  }

  @Input() getCanWriteStreamForFileOrFolder?: (
    file: FileStorage | DirStorage,
  ) => Observable<boolean>;
  @Input() getCanDeleteStreamForFileOrFolder?: (
    file: FileStorage | DirStorage,
  ) => Observable<boolean>;

  private _showCreateFolderRow$ = new BehaviorSubject(false);

  readonly tableData$ = combineLatest([
    this._dataSource.data$,
    this._showCreateFolderRow$.pipe(distinctUntilChanged()),
  ]).pipe(
    map(([data, showCreateFolderRow]) => {
      return showCreateFolderRow ? ['CREATE_DIRECTORY', ...data] : data;
    }),
  );
  readonly totalBytes$ = this._dataSource.totalBytes$;

  get displayedColumns() {
    return ['select', 'icon', 'name', 'date', 'size', 'actions'];
  }

  @Input({ transform: booleanAttribute }) set showCreateFolderRow(
    value: boolean,
  ) {
    this._showCreateFolderRow$.next(value);
    if (value) {
      this.resetSelection();
    }
  }

  @Input() selectionType: FolderViewSelectionType = 'mixed';

  get selectMultiple() {
    return (
      this.selectionType === 'mixed' ||
      this.selectionType === 'multipleFolders' ||
      this.selectionType === 'multipleFiles'
    );
  }

  get canSelectFolders() {
    return (
      this.selectionType === 'mixed' ||
      this.selectionType === 'multipleFolders' ||
      this.selectionType === 'singleFolder'
    );
  }

  get canSelectFiles() {
    return (
      this.selectionType === 'mixed' ||
      this.selectionType === 'multipleFiles' ||
      this.selectionType === 'singleFile'
    );
  }

  @Output() readonly selectionChange = new EventEmitter<{
    files: FileStorage[];
    folders: DirStorage[];
  }>();

  @Output() readonly openFolder = new EventEmitter<DirStorage>();
  @Output() readonly clickedFile = new EventEmitter<FileStorage>();
  @Output() readonly clickedFolder = new EventEmitter<DirStorage>();
  @Output() readonly doubleClickedFile = new EventEmitter<FileStorage>();
  @Output() readonly doubleClickedFolder = new EventEmitter<DirStorage>();
  @Output() readonly deleteFolder = new EventEmitter<DirStorage>();
  @Output() readonly deleteFile = new EventEmitter<FileStorage>();
  @Output() readonly downloadFile = new EventEmitter<FileStorage>();
  @Output() readonly copyFileLink = new EventEmitter<FileStorage>();
  @Output() readonly navigateToPath = new EventEmitter<string>();
  @Output() readonly showFilePreview = new EventEmitter<FileStorage>();
  @Output() readonly duplicateFile = new EventEmitter<FileStorage>();
  @Output() readonly cancelCreateFolder = new EventEmitter<void>();
  @Output() readonly submitFileName = new EventEmitter<{
    file: FileStorage;
    name: string;
  }>();
  @Output() readonly submitFolderName = new EventEmitter<{
    folder?: DirStorage;
    name: string;
  }>();
  @Output() readonly moveFilesAndFoldersToFolder = new EventEmitter<{
    target: DirStorage;
    data: (FileStorage | DirStorage)[];
  }>();
  @Output() readonly uploadFiles = new EventEmitter<{
    target: DirStorage;
    files: File[];
  }>();

  private _editingFile?: FileStorage;
  private _editingFolder?: DirStorage;

  ngOnDestroy() {
    this._showCreateFolderRow$.complete();
  }

  readonly canSelectRowFn = (
    row: FileStorage | DirStorage | 'CREATE_DIRECTORY',
  ) => {
    if (row instanceof DirStorage) {
      return this.selectionType === 'mixed'
        ? row.canBeTinkeredWith
        : this.canSelectFolders;
    } else if (row instanceof FileStorage) {
      return this.canSelectFiles;
    }
    return false;
  };

  isSelected(row: FileStorage | DirStorage) {
    return (
      (row instanceof FileStorage && this.selectedFiles.includes(row)) ||
      (row instanceof DirStorage && this.selectedFolders.includes(row))
    );
  }

  toggleSelection(row: FileStorage | DirStorage) {
    const isFile = row instanceof FileStorage;
    if (isFile && !this.canSelectFiles) {
      return;
    }
    const isFolder = row instanceof DirStorage;
    if (isFolder && !this.canSelectFolders) {
      return;
    }
    const isSelected = this.isSelected(row);
    const isMultipleSelection = this.selectMultiple;
    if (isSelected) {
      if (isFile) {
        this.selectedFiles = this.selectedFiles.filter((f) => f !== row);
      } else if (isFolder) {
        this.selectedFolders = this.selectedFolders.filter((f) => f !== row);
      }
    } else {
      if (isFile) {
        this.selectedFiles = isMultipleSelection
          ? [...this.selectedFiles, row]
          : [row];
      } else if (isFolder) {
        this.selectedFolders = isMultipleSelection
          ? [...this.selectedFolders, row]
          : [row];
      }
    }

    this.selectionChange.emit({
      files: this.selectedFiles,
      folders: this.selectedFolders,
    });
  }

  toggleAllRows() {
    if (this.isAllSelected) {
      this.selectedFiles = [];
      this.selectedFolders = [];
    } else {
      this.selectedFiles = this._files.slice();
      this.selectedFolders = this._folders.slice();
    }
    this.selectionChange.emit({
      files: this.selectedFiles,
      folders: this.selectedFolders,
    });
  }

  onDataDropped(
    target: FileStorage | DirStorage,
    event: (FileStorage | DirStorage)[],
  ) {
    if (target instanceof FileStorage) {
      return;
    }
    this.moveFilesAndFoldersToFolder.emit({ target, data: event });
  }

  onFilesDropped(target: DirStorage, event: File[]) {
    this.uploadFiles.emit({ target, files: event });
  }

  getRowName(row: FileStorage | DirStorage): string {
    if (row instanceof FileStorage) {
      return row.fileViewname;
    }
    return row.dirViewname;
  }

  isItemFile(item: FileStorage | DirStorage): item is FileStorage {
    return item instanceof FileStorage;
  }

  resetSelection() {
    this.selectedFiles = [];
    this.selectedFolders = [];
    this.selectionChange.emit({ files: [], folders: [] });
  }

  onChangeMixFilesAndFolders(event: MatSlideToggleChange) {
    this._dataSource.mixFilesAndFolders$.next(event.checked);
    this._personalSettingsService.updateMixFilesAndFolders(event.checked);
  }

  onSortChange(sort: Sort) {
    this._personalSettingsService.updateSort({
      field: sort.active as any,
      direction: sort.direction as any,
    });
    this._dataSource.sort$.next({
      field: sort.active as any,
      direction: sort.direction,
    });
  }

  startFileNameEdit(file: FileStorage) {
    this.resetSelection();
    this._editingFolder = undefined;
    this._editingFile = file;
  }

  startFolderNameEdit(folder: DirStorage) {
    this.resetSelection();
    this._editingFile = undefined;
    this._editingFolder = folder;
    this._cdRef.detectChanges();
  }

  stopEditing() {
    this._editingFile = undefined;
    this._editingFolder = undefined;
    if (this._showCreateFolderRow$.value) {
      this.cancelCreateFolder.emit();
    }
  }

  onSubmitNameEdit(value: string) {
    if (this._editingFile) {
      this.submitFileName.emit({
        file: this._editingFile,
        name: value,
      });
    } else if (this._editingFolder) {
      this.submitFolderName.emit({
        folder: this._editingFolder,
        name: value,
      });
    } else {
      this.submitFolderName.emit({ name: value });
    }
    this.stopEditing();
  }

  isRowBeingEdited(
    row: FileStorage | DirStorage | 'CREATE_DIRECTORY',
  ): boolean {
    if (row === 'CREATE_DIRECTORY') {
      return true;
    }
    if (this._editingFile && row instanceof FileStorage) {
      return this._editingFile.id === row.id;
    }
    return this._editingFolder?.id === row.id;
  }

  onRowClick(row: FileStorage | DirStorage) {
    if (row instanceof DirStorage) {
      this.clickedFolder.emit(row);
    } else if (row instanceof FileStorage) {
      this.clickedFile.emit(row);
    }
  }

  onRowDoubleClick(row: FileStorage | DirStorage) {
    if (row instanceof DirStorage) {
      this.doubleClickedFolder.emit(row);
    } else if (row instanceof FileStorage) {
      this.doubleClickedFile.emit(row);
    }
  }
}
