import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import animations from '../../animations';

@Component({
  selector:
    'tremaze-folder-view-grid-layout-folders, ul[tremaze-folder-view-grid-layout-folders]',
  standalone: true,
  imports: [CommonModule],
  template: ` <ng-content></ng-content> `,
  styles: `
    :host {
      list-style-type: none;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 10px;
    }
  `,
  animations: [animations.parentAnimation],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'tremaze-folder-view-grid-layout-folders',
    '[@parent]': '',
  },
})
export class FolderViewGridLayoutFoldersComponent {}
