import { InjectionToken } from '@angular/core';

export interface InformationModuleConfig {
  publicSetting?: 'DEFAULT_ON' | 'DEFAULT_OFF' | 'DISABLED';
  departmentAllocationDisabled?: boolean;
  userAllocationDisabled?: boolean;
  visibleOnlyForEmployeesDisabled?: boolean;
  automaticallyAssignAuthUserInstitutionOnCreation?: boolean;
  hasGoalsEnabled?: boolean;
  commentsDisabled?: boolean;
}

export const INFORMATION_MODULE_CONFIG =
  new InjectionToken<InformationModuleConfig>('INFORMATION_MODULE_CONFIG');
