<ng-container [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Straße und Hausnummer</mat-label>
    <input
      matInput
      formControlName="street"
      [required]="required"
      maxlength="60"
    />
    <mat-error *ngIf="formGroup.controls.street.invalid"
      >Bitte gib eine Straße an</mat-error
    >
    <mat-hint
      >{{ formGroup?.controls.street.value?.length ?? 0 }} / 60</mat-hint
    >
  </mat-form-field>
  <mat-form-field>
    <mat-label>Postleitzahl</mat-label>
    <input
      matInput
      formControlName="zip"
      [required]="required"
      #zipInput
      maxlength="5"
    />
    <mat-error *ngIf="formGroup.controls.zip.invalid"
      >Bitte gib eine Postleitzahl an</mat-error
    >
    <mat-hint>{{ zipInput.value.length || 0 }}/ 5</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Stadt</mat-label>
    <input
      matInput
      formControlName="city"
      [required]="required"
      maxlength="60"
    />
    <mat-error *ngIf="formGroup.controls.city.invalid"
      >Bitte gib eine Stadt an</mat-error
    >
    <mat-hint>{{ formGroup?.controls.city.value?.length ?? 0 }} / 60</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Addresszusatz</mat-label>
    <input matInput formControlName="addition" maxlength="60" />
    <mat-hint
      >{{ formGroup?.controls.addition.value?.length ?? 0 }} / 60</mat-hint
    >
  </mat-form-field>
</ng-container>
