import { TzPermissionRequest } from '@tremaze/shared/permission/types';
import {
  CustomForm,
  CustomFormSubmission,
} from '@tremaze/shared/feature/custom-forms/types';
import { ReferencePersonService } from '@tremaze/shared/feature/reference-person';
import { map, Observable, take } from 'rxjs';

export abstract class CustomFormPermissionUtils {
  static get createPermissions(): TzPermissionRequest {
    return {
      gPerms: 'FORM_WRITE',
      iPerms: {
        instIds: 'ANY',
      },
    };
  }

  static buildEditPermissionsForInstIds(
    instIds: string[],
  ): TzPermissionRequest {
    return {
      gPerms: 'FORM_WRITE',
      iPerms: {
        instIds,
      },
    };
  }

  static buildEditPermissions(customForm: CustomForm): TzPermissionRequest {
    return CustomFormPermissionUtils.buildEditPermissionsForInstIds(
      customForm.institutions?.map((i) => i.id!) ?? [],
    );
  }

  static buildDeletePermissions(customForm: CustomForm): TzPermissionRequest {
    return {
      gPerms: 'FORM_DELETE',
      iPerms: {
        instIds: customForm.institutions?.map((i) => i.id!) ?? [],
      },
    };
  }

  static buildReadPermissions(
    instIds: 'ANY' | string[] = 'ANY',
  ): TzPermissionRequest {
    return {
      gPerms: 'FORM_READ',
      iPerms: {
        instIds,
      },
    };
  }

  static buildPublishPermissions(customForm: CustomForm): TzPermissionRequest {
    return CustomFormPermissionUtils.buildPublishPermissionsForInstIds(
      customForm.institutions?.map((i) => i.id!) ?? [],
    );
  }

  static buildPublishPermissionsForInstIds(
    instIds: string[],
  ): TzPermissionRequest {
    return {
      gPerms: 'FORM_PUBLISH',
      iPerms: {
        instIds,
      },
    };
  }

  static get submissionReadPermissions(): TzPermissionRequest {
    return {
      gPerms: 'FORM_SUBMISSIONS_READ',
      iPerms: [
        {
          perms: 'FORM_SUBMISSIONS_READ',
          instIds: 'ANY',
        },
        {
          perms: 'FORM_SUBMISSIONS_READ_REFERENCE_CLIENT',
          instIds: 'ANY',
        },
      ],
    };
  }

  static buildSubmissionCompletePermissions$(
    submission: CustomFormSubmission,
    referencePersonService: ReferencePersonService,
  ): Observable<TzPermissionRequest> {
    const user = submission.user;
    return referencePersonService
      .getUniqueInstitutionIdList([user?.id ?? ''])
      .pipe(
        take(1),
        map((referencePersonsInstIds) => {
          return {
            gPerms: 'FORM_SUBMISSIONS_COMPLETE',
            iPerms: [
              {
                perms: 'FORM_SUBMISSIONS_COMPLETE',
                instIds:
                  submission.formVersion.form?.institutions?.map(
                    (i) => i.id!,
                  ) ?? [],
              },
              {
                perms: 'FORM_SUBMISSIONS_COMPLETE_REFERENCE_CLIENT',
                instIds: referencePersonsInstIds,
              },
            ],
          };
        }),
      );
  }
}
