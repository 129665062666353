<section [formGroup]="formGroup" class="form-grid">
  <div class="d-flex form-grid-item-full-width">
    <tremaze-hint mode="DEFAULT">
      Die Wiederholdungs-Einstellungen können nach dem Anlegen des Termines
      nicht mehr geändert werden.
    </tremaze-hint>
  </div>

  <mat-selection-list
    (selectionChange)="onChangeRepetitionType($event)"
    [multiple]="false"
    [disabled]="isSettingDisabled"
  >
    <mat-list-option
      [value]="null"
      [selected]="(repetitionTypeValue$ | async) === null"
      >Ohne</mat-list-option
    >
    <mat-list-option
      [value]="repeatEveryOptions.DAY"
      [selected]="(repetitionTypeValue$ | async) === repeatEveryOptions.DAY"
      >Täglich
    </mat-list-option>
    <mat-list-option
      [value]="repeatEveryOptions.WEEK"
      [selected]="(repetitionTypeValue$ | async) === repeatEveryOptions.WEEK"
      >Wöchentlich
    </mat-list-option>
    <mat-list-option
      [value]="repeatEveryOptions.MONTH"
      [selected]="(repetitionTypeValue$ | async) === repeatEveryOptions.MONTH"
      >Monatlich
    </mat-list-option>
    <mat-list-option
      [value]="repeatEveryOptions.YEAR"
      [selected]="(repetitionTypeValue$ | async) === repeatEveryOptions.YEAR"
      >Jährlich
    </mat-list-option>
  </mat-selection-list>

  <div
    *ngIf="repetitionTypeValue$ | async"
    formGroupName="schedule"
    class="form-grid"
    @formRow
  >
    <mat-form-field>
      <mat-label>Intervall</mat-label>
      <mat-select formControlName="repeatNumber">
        <mat-select-trigger>
          <span>{{ intervalLabel$ | async }}</span>
        </mat-select-trigger>
        <mat-option *ngFor="let option of intervalOptions" [value]="option"
          >{{ getIntervalOptionLabel(option) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="(repetitionTypeValue$ | async) === 'WEEK'" @formRow>
      <mat-label>Wochentag(e) wählen</mat-label>
      <mat-select formControlName="repeatDays" multiple>
        <mat-option value="MONDAY">Montags</mat-option>
        <mat-option value="TUESDAY">Dienstags</mat-option>
        <mat-option value="WEDNESDAY">Mittwochs</mat-option>
        <mat-option value="THURSDAY">Donnerstags</mat-option>
        <mat-option value="FRIDAY">Freitags</mat-option>
        <mat-option value="SATURDAY">Samstags</mat-option>
        <mat-option value="SUNDAY">Sonntags</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    class="form-grid"
    *ngIf="repetitionTypeValue$ | async"
    [formGroup]="formGroup.controls.schedule"
    @formRow
  >
    <mat-form-field
      style="width: 300px"
      tremazeFeatureAssistant
      featureName="Anmeldungsbeschränkung"
      featureAssistantMessage="{{
        allowedRegistrationScopeFeatureAssistantMessage
      }}"
    >
      <mat-label>Anmeldungen beschränken</mat-label>
      <mat-select formControlName="allowedRegistrationScope">
        <mat-option value="SINGLE"
          >Nur für einzelne Termine der Serie</mat-option
        >
        <mat-option value="ALL">Nur für die gesamte Serie</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</section>
