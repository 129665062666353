import { TremazeDate } from '@tremaze/shared/util-date';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  connectable,
  endWith,
  filter,
  map,
  Observable,
  Subscription,
  take,
} from 'rxjs';

export class BackgroundTask {
  private _subscription?: Subscription;

  constructor(
    readonly id: string,
    readonly created: TremazeDate,
    readonly type: BackgroundTaskType,
    readonly name: string,
    readonly progress$: Observable<number>,
  ) {
    this._subscription = this.isCompleted$.connect();
  }

  readonly isCompleted$ = connectable(
    this.progress$.pipe(
      catchError(() => {
        this.hasError$.next(true);
        return [];
      }),
      endWith('BRUH'),
      filter((v) => v === 'BRUH'),
      map(() => true),
      take(1),
    ),
    {
      connector: () => new BehaviorSubject(false),
    },
  );

  readonly is100PercentAndNotCompletedOrZeroPercent$ = combineLatest([
    this.isCompleted$,
    this.progress$.pipe(map((v) => v === 100)),
    this.progress$.pipe(map((v) => v === 0)),
  ]).pipe(
    map(
      ([isCompleted, is100Percent, isZeroPercent]) =>
        isZeroPercent || (!isCompleted && is100Percent),
    ),
  );

  readonly hasError$ = new BehaviorSubject(false);

  get progressBarMode(): 'determinate' | 'indeterminate' | 'buffer' | 'query' {
    switch (this.type) {
      case 'UPLOAD':
      case 'DOWNLOAD':
        return 'determinate';
      default:
        return 'indeterminate';
    }
  }

  get iconClass(): string {
    switch (this.type) {
      case 'UPLOAD':
        return 'lnr lnr-upload2';
      case 'MOVE':
        return 'lnr lnr-arrow-right';
      case 'DELETE':
        return 'lnr lnr-trash2';
      case 'CREATE':
        return 'lnr lnr-plus';
      case 'EDIT':
        return 'lnr lnr-pencil';
      default:
        return 'lnr lnr-download2';
    }
  }

  destroy() {
    this.hasError$.complete();
    this._subscription?.unsubscribe();
  }
}

export type BackgroundTaskType =
  | 'UPLOAD'
  | 'DOWNLOAD'
  | 'MOVE'
  | 'DELETE'
  | 'CREATE'
  | 'EDIT';
