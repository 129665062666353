import { Category } from '@tremaze/shared/feature/category/types';
import { Color } from '@angular-material-components/color-picker';

export abstract class CategoryFixtures {
  static readonly eventCategory1 = new Category(
    '1',
    undefined,
    'EVENT_CATEGORY',
    new Color(239, 133, 41),
    'user',
    'Event Category 1',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur ducimus nesciunt odio porro repellendus temporibus totam! A aperiam debitis dolore eum facilis fugit, ipsum itaque labore obcaecati quaerat voluptas voluptates?',
  );

  static readonly eventCategory2 = new Category(
    '2',
    undefined,
    'EVENT_CATEGORY',
    new Color(239, 133, 41),
    'rocket',
    'Event Category 2',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem, minus?',
  );

  static readonly informationCategory1 = new Category(
    '3',
    undefined,
    'INFORMATION_CATEGORY',
    new Color(239, 133, 41),
    'clipboard',
    'Information Category',
  );
}
