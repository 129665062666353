<tremaze-data-table
  caption="Alle Terminvorlagen"
  enableInstitutionFilter
  enableDepartmentFilter
  enableUserFilter
  sortStart="name"
  sortStartDirection="asc"
  [hideCreateButton]="enableSelection"
  [hideDeleteButton]="enableSelection"
  [filterFields]="['NAME']"
  [hideEditButton]="enableSelection"
  tremazeDataTablePersonalConfigSettingsKey="eventTemplateListSettings"
  [enableSelection]="enableSelection"
  (selectionChange)="onSelectionChange($event)"
  (filterReset)="filterService.updateSpecializationsFilter([])"
  (rowClick)="onRowClick($event)"
>
  <ng-template
    tremazeDataTableCustomFilter
    queryParamName="specializationIds"
    *ifModuleEnabled="'SPECIALIZATION'"
    [queryParamValue]="filterService.specializationsFilterIds$ | async"
  >
    <tremaze-specializations-select
      (selectionChange)="filterService.updateSpecializationsFilter($event)"
      [value]="filterService.specializationsFilter$ | async"
      appearance="outline"
      multiple
    ></tremaze-specializations-select>
  </ng-template>

  <!--  COLUMN DEFINITIONS-->
  <ng-container displayName="Name" sortable tremazeDataTableColumn="name">
    <ng-template let-row>{{ row.name }}</ng-template>
  </ng-container>

  <ng-container
    tremazeDataTableColumn="institutions"
    displayName="Einrichtungen"
  >
    <ng-template let-row>
      <tremaze-data-table-chips-column
        [data]="row.institutions"
        [displayWith]="displayWithNameFn"
        label="Einrichtungen"
      ></tremaze-data-table-chips-column>
    </ng-template>
  </ng-container>

  <ng-container tremazeDataTableColumn="departments" displayName="Abteilungen">
    <ng-template let-row>
      <tremaze-data-table-chips-column
        [data]="row.departments"
        [displayWith]="displayWithNameFn"
        label="Abteilungen"
      ></tremaze-data-table-chips-column>
    </ng-template>
  </ng-container>

  <ng-container
    tremazeDataTableColumn="specializations"
    displayName="Förderbereiche"
    *ifModuleEnabled="'SPECIALIZATION'"
  >
    <ng-template let-row>
      <tremaze-data-table-chips-column
        [data]="row.specializations"
        [displayWith]="displayWithNameFn"
        label="Förderbereiche"
      ></tremaze-data-table-chips-column>
    </ng-template>
  </ng-container>

  <ng-template let-row tremazeDataTableAction>
    @if (!hidePlanWithRowAction) {
      <button
        *tremazeHasPermission="planEventPermissionRequest"
        mat-icon-button
        color="primary"
        matTooltip="Mit &quot;{{ row.name }}&quot; planen"
        (click)="onClickPlanWithButton(row)"
      >
        <span class="lnr lnr-calendar-insert"></span>
      </button>
    }
  </ng-template>
</tremaze-data-table>
