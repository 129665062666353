import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Meta } from '@tremaze/shared/models';
import { Color } from '@angular-material-components/color-picker';
import { hexToRgb } from '@tremaze/shared/util-utilities';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { UserType } from '@tremaze/shared/feature/user/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { Department } from '@tremaze/shared/feature/department/types';
import { Linearicon } from '@tremaze/shared/ui';

export type CategoryType = 'EVENT_CATEGORY' | 'INFORMATION_CATEGORY';

@staticImplements<Deserializable<Category>>()
export class Category {
  constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public categoryType?: CategoryType,
    public color?: Color,
    public icon?: string,
    public name?: string,
    public titleImage?: FileStorage,
    public userTypes?: UserType[],
    public institutions: Institution[] = [],
    public departments: Department[] = [],
    readonly standardCategory?: boolean,
    readonly description?: string,
  ) {}

  get linearicon(): Linearicon | undefined {
    if (this.icon?.length) {
      return `lnr-${this.icon}` as Linearicon;
    }
    return undefined;
  }

  get instIds(): string[] {
    return this.institutions?.map((i) => i.id) ?? [];
  }

  get departmentInstIds(): string[] {
    const result = new Set<string>(
      this.departments.map((d) => d.institution?.id),
    );
    return [...result];
  }

  static deserialize(data: any): null | Category {
    if (!data) {
      return null;
    }
    const rgb =
      typeof data.color !== 'string' ? data.color : hexToRgb(data.color);
    return !data
      ? null
      : new Category(
          data.id,
          Meta.deserialize(data),
          data.categoryType,
          rgb instanceof Color ? rgb : new Color(rgb?.r, rgb?.g, rgb?.b, 1),
          data.icon,
          data.name,
          FileStorage.deserialize(data.titleImage),
          data.userTypes,
          data.institutions?.map(Institution.deserialize) ?? [],
          data.departments?.map(Department.deserialize) ?? [],
          data.standardCategory,
          data.description,
        );
  }
}
