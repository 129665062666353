import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'count',
})
export class CountPipe implements PipeTransform {
  transform(value: []): number {
    return value?.length ?? 0;
  }
}
