import { Injectable, Optional } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of, throwError, zip } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AuthV2Service } from './auth-service';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Optional() private authService?: AuthV2Service) {}

  static addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: { Authorization: 'Bearer ' + token },
    });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const isOpenIdRequest = request.url.includes('openid');
    if (isOpenIdRequest) {
      return next.handle(request);
    }
    return zip(
      this.authService.accessToken$.pipe(filterNotNullOrUndefined()),
      this.getTenantID$(request),
    ).pipe(
      switchMap(([accessToken, tenantId]) => {
        let headersObj = {};
        if (!request.url.includes('public/')) {
          headersObj = { Authorization: 'Bearer ' + accessToken };
        }
        if (tenantId) {
          headersObj = { ...headersObj, 'X-TenantID': tenantId };
        }
        request = request.clone({ setHeaders: headersObj });
        return next
          .handle(request)
          .pipe(catchError((e) => this.handleError(e, request, next)));
      }),
    );
  }

  handleError(
    err: HttpErrorResponse,
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<any> {
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 400:
          return this.handle400(err);
        case 401:
          return this.handle401Error(err, req, next);
        case 403:
          return this.handle403();
        case 502:
          break;
        default:
          return throwError(err);
      }
    } else {
      return throwError(err);
    }
  }

  handle400(err: HttpErrorResponse) {
    if (
      err &&
      err.status === 400 &&
      err.error &&
      err.error.error === 'invalid_grant'
    ) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logout(err);
    }
    return throwError(err);
  }

  handle401Error(
    err: HttpErrorResponse,
    req: HttpRequest<any>,
    next: HttpHandler,
  ) {
    if (typeof req.body === 'string' && req.body.includes('refresh_token')) {
      return this.logout(
        'Deine Sitzung ist abgelaufen. Bitte melde dich erneut an',
      );
    } else if (req.url.includes('logout')) {
      return of(true);
    } else if (req.url.includes('token')) {
      return throwError(err);
    }

    // Reset here so that the following requests wait until the token
    // comes back from the refreshToken call.
    return this.authService.getRefreshedToken().pipe(
      switchMap((newToken) => {
        if (newToken) {
          // Reload auth user since token got deleted somehow so it's worth checking if i.e. the permissions have changed
          // this._authService.reloadAuthenticatedUserData();
          return next.handle(AuthInterceptor.addToken(req, newToken));
        }

        // If we don't get a new token, we are in trouble so logout.
        return this.logout("Couldn't refresh token");
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if (error.status === 401) {
          this.logout("Couldn't refresh token");
        }
        return throwError(error);
      }),
    );
  }

  handle403() {
    return throwError('permission-denied');
  }

  logout(err: any) {
    this.authService.logout();
    return of(null);
  }

  private getTenantID$(request: HttpRequest<unknown>) {
    if (!request.params.has('skipTenantId') && this.authService) {
      return this.authService.activeTenant$.pipe(
        filterNotNullOrUndefined(),
        take(1),
        map(({ id }) => id),
      );
    }
    return of(null);
  }
}
