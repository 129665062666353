import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipationCriteriaSelectionComponent } from './participation-criteria-selection.component';
import { ParticipationCriteriaSelectionInputDirective } from './participation-criteria-selection-input.directive';
import { ParticipationCriteriaSelectionService } from './participation-criteria-selection.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SharedFeatureParticipationCriteriaFeatureParticipationCriteriaListModule } from '@tremaze/shared/feature/participation-criteria/feature/participation-criteria-list';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    SharedFeatureParticipationCriteriaFeatureParticipationCriteriaListModule,
  ],
  declarations: [
    ParticipationCriteriaSelectionComponent,
    ParticipationCriteriaSelectionInputDirective,
  ],
  exports: [ParticipationCriteriaSelectionInputDirective],
  providers: [ParticipationCriteriaSelectionService],
})
export class SharedFeatureParticipationCriteriaFeatureParticipationCriteriaSelectionModule {}
