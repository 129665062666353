import { Color } from '@angular-material-components/color-picker';
import { FactoryProvider, inject, Optional, SkipSelf } from '@angular/core';
import { NonUndefinedProperties } from '@tremaze/shared/util/types';

export function fileListToFileArray(fl: FileList): File[] {
  const res = [];
  for (let i = 0; i < fl.length; i++) {
    res.push(fl.item(i));
  }
  return res;
}

export function blobToFile(
  theBlob: Blob,
  fileName: string,
  fileType: string,
): File {
  return new File([theBlob], fileName, {
    type: fileType,
    lastModified: new Date().getTime(),
  });
}

export function isFunction(f) {
  return f && {}.toString.call(f) === '[object Function]';
}

export const isBrowser = (function () {
  return typeof window === 'undefined';
})();

export function isNotNullOrUndefined<T>(val: T | null | undefined): val is T {
  return val !== null && val !== undefined;
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function hexToColor(hex): Color {
  const rgb = hexToRgb(hex);
  if (rgb) {
    return new Color(rgb.r, rgb.g, rgb.b, 1);
  }
  return null;
}

export function isNotEmpty(value: any): boolean {
  if (!value) {
    return false;
  } else if (Array.isArray(value)) {
    return !!value.length;
  } else if (typeof value === 'object') {
    return Object.keys(value).length > 0;
  }
  return true;
}

export function isEmpty(value: any): boolean {
  return !isNotEmpty(value);
}

export function moveInArray(array: unknown[], from: number, to: number): void {
  const element = array[from];
  array.splice(from, 1);
  array.splice(to, 0, element);
}

export function replaceUmlauts(str: string): string {
  const umlautMap = {
    '\u00dc': 'UE',
    '\u00c4': 'AE',
    '\u00d6': 'OE',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u00df': 'ss',
  };
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(
      new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'),
      (a) => umlautMap[a],
    );
}

export function deepEqual(object1, object2) {
  const object1NullOrUndefined = object1 === null || object1 === undefined;
  const object2NullOrUndefined = object2 === null || object2 === undefined;
  if (object1NullOrUndefined || object2NullOrUndefined) {
    return object1NullOrUndefined === object2NullOrUndefined;
  }
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

export function isObject(object) {
  return object != null && typeof object === 'object';
}

/**
 * Returns the value if it is not null, otherwise returns undefined.
 * @param value
 */
export function ensureUndefinedOrValue<T>(
  value: T | null | undefined,
): T | undefined {
  return value === null ? undefined : value;
}

export function ensureArray<T>(value: T | T[] | null | undefined): T[] {
  if (value === null || value === undefined) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
}

export function ensureNotArray<T>(value: T | T[] | null | undefined): T {
  if (value === null || value === undefined) {
    return null;
  }
  return Array.isArray(value) ? value[0] : value;
}

export function overridableProviderFactory(t1: any, t2?: any): FactoryProvider {
  return {
    provide: t1,
    useFactory: (opt) => opt ?? inject(t2 ?? t1),
    deps: [[new Optional(), new SkipSelf(), t1]],
  };
}

export function getFirstValueFromSet<T>(set: Set<T>): T {
  return set.values().next().value;
}

/**
 * Compares two version strings.
 * @param v1
 * @param v2
 * @returns 1 if v1 > v2, 0 if v1 === v2, -1 if v1 < v2
 */
export function compareVersionStrings(v1: string, v2: string): number {
  const v1Parts = v1.split('.').map((part) => parseInt(part, 10));
  const v2Parts = v2.split('.').map((part) => parseInt(part, 10));
  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
    const v1Part = v1Parts[i] ?? 0;
    const v2Part = v2Parts[i] ?? 0;
    if (v1Part > v2Part) {
      return 1;
    }
    if (v1Part < v2Part) {
      return -1;
    }
  }
  return 0;
}

export function removeUndefined<T>(obj: T): NonUndefinedProperties<T> {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj as NonUndefinedProperties<T>;
}

function parseCsvLine(line: string): string[] {
  const result: string[] = [];
  let insideQuotes = false;
  let currentValue = '';

  for (let i = 0; i < line.length; i++) {
    const char = line[i];

    if (char === '"' && (i === 0 || line[i - 1] !== '\\')) {
      insideQuotes = !insideQuotes;
    } else if (char === ';' && !insideQuotes) {
      result.push(currentValue.trim());
      currentValue = '';
    } else {
      currentValue += char;
    }
  }

  result.push(currentValue.trim());
  return result;
}

export function parseCSV<R = Record<string, string>>(csv: string): R[] {
  const lines = csv.trim().split('\n');
  const headers = parseCsvLine(lines[0]);

  const records = lines.slice(1).map((line) => {
    const values = parseCsvLine(line);
    const record: Record<string, string> = {};

    headers.forEach((header, index) => {
      record[header] = values[index];
    });

    return record;
  });

  return records as R[];
}

export function getCSVHeaders(csv: string, cfg = {atIndex: 0}): string[] {
  return parseCsvLine(csv.trim().split('\n')[cfg.atIndex]);
}

export function getBestContrastColor(hexColor, opacity) {
  // Funktion zum Umwandeln von Hex in RGB
  function hexToRgb(hex) {
    if (hex.charAt(0) === '#') {
      hex = hex.substr(1);
    }
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map(function (hex) {
          return hex + hex;
        })
        .join('');
    }
    var num = parseInt(hex, 16);
    return {
      r: num >> 16,
      g: (num >> 8) & 0xff,
      b: num & 0xff,
    };
  }

  // Funktion zum Berechnen der relativen Luminanz
  function getLuminance(r, g, b) {
    var a = [r, g, b].map(function (v) {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  // Umwandlung der Hex-Farbe in RGB
  var rgb = hexToRgb(hexColor);
  // Anwendung der Opazität
  rgb.r = Math.round(rgb.r * opacity + 255 * (1 - opacity));
  rgb.g = Math.round(rgb.g * opacity + 255 * (1 - opacity));
  rgb.b = Math.round(rgb.b * opacity + 255 * (1 - opacity));
  // Berechnung der Luminanz der Farbe
  var luminance = getLuminance(rgb.r, rgb.g, rgb.b);
  // Bestimmung des besten Kontrasts (schwarz oder weiß)
  return luminance >= 0.175 ? 'black' : 'white';
}

export function findDuplicates<T>(a: T[], comp = (a: T, b: T) => a === b): T[] {
  const duplicates: T[] = [];
  for (let i = 0; i < a.length; i++) {
    if (!duplicates.some((d) => comp(d, a[i]))) {
      const count = a.filter((item) => comp(item, a[i])).length;
      if (count > 1) {
        duplicates.push(a[i]);
      }
    }
  }
  return duplicates;
}

export function clearAllTextSelection() {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  }
}

export function findFirstScrollableAncestor(node: HTMLElement): HTMLElement {
  let current = node;
  while (current) {
    if (current.scrollHeight > current.clientHeight) {
      return current;
    }
    current = current.parentElement;
  }
  return null;
}
