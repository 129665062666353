import { CustomFormFieldValue } from './value';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Meta } from '@tremaze/shared/models';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

@staticImplements<Deserializable<CustomFileFormFieldValue>>()
export class CustomFileFormFieldValue
  implements CustomFormFieldValue<FileStorage>
{
  readonly fieldType = 'FILE_UPLOAD';

  constructor(
    readonly id: string,
    readonly meta: Meta,
    readonly value: FileStorage,
  ) {}

  static deserialize(data: any): CustomFileFormFieldValue {
    return new CustomFileFormFieldValue(
      data.id,
      Meta.deserialize(data),
      FileStorage.deserialize(data.value),
    );
  }

  toDisplayString(): string {
    return this.value.fileViewname;
  }
}
