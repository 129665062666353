import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tenant } from '@tremaze/shared/feature/tenant/types';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';

@Injectable({ providedIn: 'root' })
export class ActiveTenantDefaultService implements ActiveTenantService {
  constructor(private authService: AuthV2Service) {}

  getTenant$(): Observable<Tenant> {
    return this.authService.activeTenant$;
  }
}

@Injectable({ useExisting: ActiveTenantDefaultService, providedIn: 'root' })
export abstract class ActiveTenantService {
  abstract getTenant$(): Observable<Tenant>;
}
