import { Component, Inject } from '@angular/core';
import { EventScope, TremazeEvent } from '@tremaze/shared/feature/event/types';
import { POPUP_DATA } from '@tremaze/shared/ui/popup';
import { TremazeDate } from '@tremaze/shared/util-date';

@Component({
  selector: 'tremaze-event-scope-selector-popup',
  templateUrl: './event-scope-selector-popup.component.html',
  styles: [],
})
export class EventScopeSelectorPopupComponent {
  public eventScope: EventScope[] = this._pickInitialScope();

  readonly event: Pick<TremazeEvent, 'startDate'> = this.data.event;

  readonly now = new TremazeDate();

  get isEventInPast() {
    return this.event.startDate?.isBefore(this.now) ?? false;
  }

  /**
   * Returns true if the given scope is disabled.
   * Scopes are disabled if they are not in the allowedScopes array.
   * Or if the event is in the past and the scope is not SINGLE.
   * @param scope
   */
  isScopeDisabled(scope: EventScope) {
    if (this.data.allowedScopes) {
      if (!this.data.allowedScopes.includes(scope)) {
        return true;
      }
    }
    if (this.isEventInPast && scope !== 'SINGLE') {
      return true;
    }
    return false;
  }

  private _pickInitialScope(): EventScope[] {
    if (this.data.allowedScopes?.includes('SINGLE') !== false) {
      return ['SINGLE'];
    }
    return [this.data.allowedScopes[0]];
  }

  get hasAllowedScopes() {
    return !!this.data.allowedScopes;
  }

  get showHint() {
    return this.isEventInPast || this.hasAllowedScopes;
  }

  get hintText(): string | null {
    if (this.isEventInPast) {
      return 'Da der gewählte Termin in der Vergangenheit liegt, <br>kann die Serie von diesem Termin aus nicht mehr verändert werden.';
    }
    if (this.hasAllowedScopes) {
      return 'Für diese Aktion sind nicht alle Optionen verfügbar.';
    }
    return null;
  }

  constructor(
    @Inject(POPUP_DATA)
    readonly data: {
      event: Pick<TremazeEvent, 'startDate'>;
      allowedScopes?: EventScope[];
    },
  ) {}
}
