import { EventPreset } from './types';
import { InjectionToken } from '@angular/core';

export interface EventPresetSelectionConfig {
  availablePresets?: EventPreset[];
}

export const EVENT_PRESET_SELECTION_CONFIG =
  new InjectionToken<EventPresetSelectionConfig>(
    'EVENT_PRESET_SELECTION_CONFIG',
  );
