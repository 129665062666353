import { Address, ContactInfo, Meta } from '@tremaze/shared/models';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

export interface IContactPointPerson {
  firstName?: string;
  lastName?: string;
}

export interface IContactPoint {
  id: string;
  name: string;
  meta?: Meta;
  address?: Address;
  contact?: ContactInfo;
  contactPerson?: IContactPointPerson;
  text?: string;
  logo?: FileStorage;
  faxNumber?: string;
  url?: string;
}

@staticImplements<Deserializable<ContactPoint>>()
export class ContactPoint implements IContactPoint {
  constructor(
    readonly id: string,
    public name: string,
    readonly meta?: Meta,
    public address?: Address,
    public contact?: ContactInfo,
    public contactPersons?: IContactPointPerson[],
    public text?: string,
    public logo?: FileStorage,
    public faxNumber?: string,
    public url?: string,
  ) {}

  static deserialize(data: Record<string, never>): ContactPoint | null {
    return !data
      ? null
      : new ContactPoint(
          data.id,
          data.name,
          Meta.deserialize(data),
          Address.deserialize(data.address) ?? new Address(),
          ContactInfo.deserialize(data.contact) ?? new ContactInfo(),
          data.contactPersons ?? [],
          data.text,
          FileStorage.deserialize(data.logo),
          data.faxNumber,
          data.url,
        );
  }
}
