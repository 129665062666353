import { inject, Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateFn,
  CanLoad,
  Router,
  UrlTree,
} from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { filterTrue, mapEveryTrue } from '@tremaze/shared/util/rxjs';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { TenantConfigService } from './tenant-config.service';

@Injectable({ providedIn: 'root' })
export class TenantSelectedGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private authService: AuthV2Service,
    private configService: TenantConfigService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([this.authService.hasActiveTenant$]).pipe(
      mapEveryTrue(),
      map((r) => {
        if (r) {
          return true;
        }
        return this.router.parseUrl('/select-tenant');
      }),
    );
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}

export const tenantConfigLoadedGuard: CanActivateFn = () => {
  return inject(TenantConfigService).hasConfig$.pipe(filterTrue());
};
