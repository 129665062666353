import { EventParticipationType } from '@tremaze/shared/feature/event/types';

export abstract class EventParticipationTypeFixtures {
  static readonly participationTypeOnline: EventParticipationType = {
    id: '1',
    name: 'Video-Termin',
    online: false,
  };

  static readonly participationTypePhone: EventParticipationType = {
    id: '2',
    name: 'Telefonat',
    online: true,
  };

  static readonly participationTypeInHouse: EventParticipationType = {
    id: '3',
    name: 'Vor Ort',
    online: false,
  };

  static readonly allParticipationTypes = [
    EventParticipationTypeFixtures.participationTypeOnline,
    EventParticipationTypeFixtures.participationTypePhone,
    EventParticipationTypeFixtures.participationTypeInHouse,
  ];
}
