import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DataSourceMethodsPaginatedOptions,
  DefaultDataSourceMethods,
  SortedFilteredPaginatedListParams,
} from '@tremaze/shared/util-http';
import {
  Approval,
  ApprovalStatus,
  PartialBudget,
} from '@tremaze/shared/feature/approval/types';
import { delay, map, Observable, switchMap } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { EventREADDataSource } from '@tremaze/shared/feature/event/data-access';
import { EventStatus, TremazeEvent } from '@tremaze/shared/feature/event/types';
import { NonFunctionProperties } from '@tremaze/shared/util/types';
import { removeUndefined } from '@tremaze/shared/util-utilities';
import { DateRange } from '@tremaze/shared/util-date';
import { ApprovalDataSource } from '../approval-data-source';

type PartialBudgetPayload = Omit<
  PartialBudget,
  | 'id'
  | 'approvalService'
  | 'approvedFrom'
  | 'approvedUntil'
  | 'copyWithNewId'
  | 'remainingPlannedBudget'
  | 'remainingActualBudget'
  | 'totalBudget'
  | 'netTotalBudget'
> & {
  approvalServiceId: string;
  approvedFrom: string;
  approvedUntil: string;
  totalBudget: number;
  netTotalBudget?: number;
};

type ApprovalCreatePayload = Omit<
  NonFunctionProperties<Approval>,
  | 'id'
  | 'client'
  | 'institution'
  | 'applicationFile'
  | 'approvalFile'
  | 'partialBudgets'
  | 'approvedAt'
  | 'approvedFrom'
  | 'approvedUntil'
  | 'requestedAt'
  | 'costBearer'
  | 'totalBudget'
  | 'remainingPlannedBudget'
  | 'remainingActualBudget'
  | 'hasApprovalPeriod'
  | 'approvalPeriod'
> & {
  userId: string;
  instId: string;
  applicationFileId?: string;
  approvalFileId?: string;
  partialBudgets: PartialBudgetPayload[];
  approvedAt?: string;
  approvedFrom?: string;
  approvedUntil?: string;
  requestedAt?: string;
  costBearerId: string;
};

function approvalToCreatePayload(approval: Approval): ApprovalCreatePayload {
  const partialBudgets: PartialBudgetPayload[] =
    approval.partialBudgets?.map((r) => {
      return removeUndefined({
        netTotalBudget: r.netTotalBudget,
        netWeeklyHours: r.netWeeklyHours,
        totalBudget: r.totalBudget,
        weeklyHours: r.weeklyHours,
        approvalServiceId: r.approvalService.id,
        approvedFrom: r.approvedFrom?.add(4, 'hour').toISOString(),
        approvedUntil: r.approvedUntil?.add(4, 'hour').toISOString(),
      });
    }) ?? [];

  return removeUndefined({
    userId: approval.client.id,
    instId: approval.institution.id,
    status: approval.status,
    fileNumber: approval.fileNumber,
    requestedAt: approval.requestedAt?.add(4, 'hour').toISOString(),
    approvedAt: approval.approvedAt?.add(4, 'hour').toISOString(),
    approvedFrom: approval.approvedFrom?.add(4, 'hour').toISOString(),
    approvedUntil: approval.approvedUntil?.add(4, 'hour').toISOString(),
    approvalFileId: approval.approvalFile?.id,
    applicationFileId: approval.applicationFile?.id,
    costBearerId: approval.costBearer!.id,
    partialBudgets,
    vivendiId: approval.vivendiId,
  });
}

@Injectable({
  providedIn: 'root',
})
export class RemoteApprovalDataSourceImpl implements ApprovalDataSource {
  constructor(
    private readonly _http: HttpClient,
    private readonly _js: JsonSerializer,
    private readonly _eventDataSource: EventREADDataSource,
  ) {}

  isVivendiIdUsed(vivendiId: string): Observable<boolean> {
    return this._http.get<boolean>('approvals/vivendiIdIsUsed', {
      params: { vivendiId },
    });
  }

  getPartialBudgetById(id: string): Observable<PartialBudget> {
    return DefaultDataSourceMethods.getFreshById(
      this._http,
      `partialBudgets`,
      PartialBudget.deserialize,
      id,
    );
  }

  getApprovalsForUserAndService(
    userId: string,
    serviceId: string,
    config?: { includeSubServices: boolean; statusFilter?: ApprovalStatus[] },
  ): Observable<Approval[]> {
    return this.getPaginatedApprovals({
      filter: {
        page: 0,
        pageSize: 50,
      },
      q: removeUndefined({
        userIds: [userId],
        approvalServiceId: serviceId,
        includeSubServices: config?.includeSubServices?.toString() ?? 'false',
        statuses: config?.statusFilter?.join(','),
      }),
    }).pipe(
      map((r) => r.content),
      delay(10),
    );
  }

  getPaginatedApprovals(
    options: DataSourceMethodsPaginatedOptions<any>,
  ): Observable<Pagination<Approval>> {
    // need to add fallback sort by id since some sort fields may be null
    // and then the sort order will be random
    let sort: string;
    if (options?.filter?.sort) {
      sort = options.filter.sort + ',id';
    } else {
      sort = 'id';
    }
    const sortDirection = options?.filter?.sortDirection ?? 'desc';
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      `approvals`,
      Approval.deserialize,
      {
        ...options,
        filter: {
          ...options?.filter,
          filterFields: ['FILE_NUMBER'],
          sort,
          sortDirection,
        },
      },
    );
  }

  getApprovalById(id: string): Observable<Approval> {
    return DefaultDataSourceMethods.getFreshById(
      this._http,
      `approvals`,
      Approval.deserialize,
      id,
    );
  }

  deleteApproval(id: string): Observable<boolean> {
    return DefaultDataSourceMethods.deleteById(this._http, `approvals`, id);
  }

  createApproval(approval: Approval): Observable<Approval> {
    return DefaultDataSourceMethods.create<string>(
      this._http,
      `approvals`,
      (d) => d['id'],
      this._js,
      approvalToCreatePayload(approval) as any,
    ).pipe(switchMap((id) => this.getApprovalById(id)));
  }

  updateApproval(approval: Approval): Observable<Approval> {
    return DefaultDataSourceMethods.edit(
      this._http,
      `approvals/${approval.id}`,
      Approval.deserialize,
      this._js,
      approvalToCreatePayload(approval) as any,
    );
  }

  getEventsForApproval(
    approvalId: string,
    filter?: {
      eventStatuses?: EventStatus[];
      filterValue?: string;
      dateRange?: DateRange;
    },
  ): Observable<TremazeEvent[]> {
    const additionalParams: Record<string, string> = {};

    if (filter?.dateRange?.length === 2) {
      additionalParams['startDate'] = filter.dateRange[0].toJSON(
        undefined,
        false,
      );
      additionalParams['endDate'] = filter.dateRange[1].toJSON(
        undefined,
        false,
      );
    }

    const params = {
      eventStatus: filter?.eventStatuses?.join(',') ?? '',
      ...SortedFilteredPaginatedListParams.toHttpParams({
        filterValue: filter?.filterValue,
        filterFields: ['NAME'],
        additionalParams,
      }),
    };
    return this._http
      .get<any[]>(`approvals/${approvalId}/events`, { params })
      .pipe(
        map((r) => r.map((d) => TremazeEvent.deserialize(d)!)),
        delay(10),
      );
  }

  getEventById(eventId: string): Observable<TremazeEvent> {
    return this._eventDataSource.getFreshById(eventId);
  }
}

export const provideRemoteApprovalDataSource = () => ({
  provide: ApprovalDataSource,
  useClass: RemoteApprovalDataSourceImpl,
});
