import { ReportRepository } from '../domain/report.repository';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { Injectable } from '@angular/core';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { finalize, map, Observable, Subject, switchMap, take, tap } from "rxjs";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { BackgroundTaskService } from '@tremaze/shared/ui/progress-display';
import { TremazeHttpResponse } from '@tremaze/shared/util-http/types';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { doOnError, filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';

@Injectable()
export class RemoteReportRepository extends ReportRepository {
  override overwriteReport(reportId: string, report: File): Observable<void> {
    const progress$ = new Subject<number>();

    this._backgroundTaskService.registerTask({
      name: 'Report überschreiben',
      type: 'UPLOAD',
      progress$,
    });

    const uploadData = new FormData();
    uploadData.append('file', report);
    const uploadRequest = this._http
      .request<TremazeHttpResponse<FileStorage>>(
        new HttpRequest('POST', `files/${reportId}/override`, uploadData, {
          reportProgress: true,
          params: new HttpParams({ fromObject: { 'ngsw-bypass': '' } }),
        }),
      )
      .pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            progress$.next(100);
          } else if (event.type === 1) {
            const total = event.total;
            if (total) {
              progress$.next(event.loaded / total);
            }
          }
        }),
        map((r) => {
          if (r instanceof HttpResponse) {
            return new HttpResponse({
              headers: r.headers,
              status: r.status,
              url: r.url!,
              statusText: r.statusText,
              body: void 0,
            });
          }
          return r;
        }),
        doOnError((e) => {
          progress$.error(e);
        }),
        finalize(() => {
          progress$.complete();
        }),
      );

    return uploadRequest as any as Observable<void>;
  }

  constructor(
    private appConfig: AppConfigService,
    private authService: AuthV2Service,
    private readonly _http: HttpClient,
    private readonly _backgroundTaskService: BackgroundTaskService,
  ) {
    super();
  }

  getHtmlReportUrl(reportId: string): string {
    return '';
  }

  // TODO: Refactor the refresh logic. This is utter trash, new devs will hate their life.
  getPDFReportUrl(reportId: string): Observable<string> {
    return this.authService.activeTenant$.pipe(
      filterNotNullOrUndefined(),
      take(1),
      map(
        (activeTenant) =>
          this.appConfig.basePath +
          `reports/${reportId}/download` +
          `?tenantId=${activeTenant.id}&type=pdf`,
      ),
    );
  }

  getCSVReportUrl(reportId: string): Observable<string> {
    return this.authService.activeTenant$.pipe(
      filterNotNullOrUndefined(),
      take(1),
      map(
        (activeTenant) =>
          this.appConfig.basePath +
          `reports/${reportId}/download` +
          `?tenantId=${activeTenant.id}&type=csv`,
      ),
      take(1),
    );
  }
}
