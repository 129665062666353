import { Component, inject } from '@angular/core';
import { EventTemplateEditFormService } from '../../event-template-edit-form.service';

@Component({
  selector: 'tremaze-event-template-form-approval-section',
  templateUrl: './event-template-form-approval-section.component.html',
})
export class EventTemplateFormApprovalSectionComponent {
  readonly eventTemplateFormService = inject(EventTemplateEditFormService);

  get formGroup() {
    return this.eventTemplateFormService?.formGroup;
  }
}
