import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Linearicon } from '@tremaze/shared/ui';

@Component({
  selector: 'tremaze-icon',
  standalone: true,
  imports: [CommonModule],
  template: ``,
  styles: `
    :host {
      display: inline-block;
      font-size: 1.25rem;

      &:before {
        margin-left: 1px;
      }
    }
  `,
})
export class IconComponent {
  @Input({ required: true }) icon!: Linearicon;

  @HostBinding('class')
  get className(): string {
    return `lnr ${this.icon}`;
  }
}
