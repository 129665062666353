import { User } from '@tremaze/shared/feature/user/types';
import { TremazeDate } from '@tremaze/shared/util-date';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import {
  EventParticipationType,
  PriceLevel,
  TremazeEvent,
} from '@tremaze/shared/feature/event/types';
import { Category } from '@tremaze/shared/feature/category/types';

export type EventRegistrationState = 'ACCEPTED' | 'DECLINED' | 'NOT_RESPONDED';

export type EventRegistrationScope = 'ALL' | 'SINGLE';

export interface EventRegistrationAmount {
  totalAmount: number;
  availableSlots: number;
}

@staticImplements<Deserializable<EventRegistration>>()
export class EventRegistration {
  constructor(
    readonly user: User,
    readonly event: TremazeEvent,
    readonly registrationState: EventRegistrationState,
    readonly registeredForWholeSeries: boolean,
    readonly id?: string,
    readonly registeredSince?: TremazeDate,
    readonly registeredBy?: string,
    readonly registrationText?: string,
    readonly participationType?: EventParticipationType,
    readonly category?: Category,
  ) {}

  static deserialize(data): null | EventRegistration {
    return !data
      ? null
      : new EventRegistration(
          User.deserialize(data.user),
          TremazeEvent.deserialize(data.event),
          data.registrationStatus,
          data.registeredForWholeSeries,
          data.id,
          TremazeDate.deserialize(data.registeredSince),
          data.registeredBy,
          data.registrationText,
          data.participationType,
          Category.deserialize(data.category),
        );
  }

  /**
   * Sorts the given registrations by their state and within the states alphabetically.
   * The order is: ACCEPTED, DECLINED, NOT_RESPONDED
   * @param regs
   */
  static sortRegistrationsByState(
    regs: EventRegistration[],
  ): EventRegistration[] {
    return regs.sort((a, b) => {
      if (a.registrationState === b.registrationState) {
        return (a.user.lastName ?? a.user.username ?? '').localeCompare(
          b.user.lastName ?? b.user.username ?? '',
        );
      }

      if (a.registrationState === 'ACCEPTED') {
        return -1;
      }

      if (b.registrationState === 'ACCEPTED') {
        return 1;
      }

      if (a.registrationState === 'DECLINED') {
        return -1;
      }

      if (b.registrationState === 'DECLINED') {
        return 1;
      }

      return 0;
    });
  }
}

@staticImplements<Deserializable<EventCapacity>>()
export class EventCapacity {
  constructor(
    readonly maxMember: number,
    readonly countRegistrations: number,
    readonly customPrices: PriceLevel[],
  ) {}

  get hasCapacity(): boolean {
    return this.maxMember > 0;
  }

  get freeDefaultCapacity(): number {
    const totalDefaultCapacity =
      this.maxMember -
      (this.customPrices ?? []).reduce((a, b) => a + b.attendeesSpace, 0);
    const totalDefaultRegistrations =
      this.countRegistrations -
      (this.customPrices ?? []).reduce((a, b) => a + b.countRegistrations, 0);
    return Math.max(0, totalDefaultCapacity - totalDefaultRegistrations);
  }

  get freeTotalCapacity(): number {
    return Math.max(0, this.maxMember - this.countRegistrations);
  }

  static deserialize(data): EventCapacity | null {
    return !data
      ? null
      : new EventCapacity(
          data.maxMember,
          data.countRegistrations,
          data.customPrices?.map(PriceLevel.deserialize) || [],
        );
  }
}
