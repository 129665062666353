<mat-form-field>
  <mat-label>Terminart{{ multiple ? 'en' : '' }}</mat-label>
  <mat-select
    [value]="value"
    [multiple]="multiple"
    (selectionChange)="onSelectionChange($event)"
    [compareWith]="compareWith"
  >
    @if (availableEventParticipationTypes$ | async; as participationTypes) {
      @for (
        participationType of participationTypes;
        track participationType.id
      ) {
        <mat-option [value]="participationType">
          <span>
            {{ participationType.name }}

            @if (participationType.online) {
              <tremaze-icon
                matSuffix
                icon="lnr-notification-circle"
                matTooltip="Wenn ein(e) Nutzer*in bei der Anmeldung diese Option wählt, wird der Termin automatisch zu einem Video-Termin umgewandelt."
              ></tremaze-icon>
            }
          </span>
        </mat-option>
      }
    }
  </mat-select>
</mat-form-field>
