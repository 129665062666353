import { Observable } from 'rxjs';
import { Tenant } from '@tremaze/shared/feature/tenant/types';
import { InjectionToken } from '@angular/core';
import {
  AuthenticatedUser,
  AuthenticatedUserTenant,
} from '@tremaze/shared/core/auth-v2/types';

export const WHITELISTED_USER_TYPES = new InjectionToken<string[]>(
  'WHITELISTED_USER_TYPES',
);

export abstract class AuthV2Service {
  abstract get authenticated$(): Observable<boolean>;

  abstract get authenticatedStateChanged$(): Observable<boolean>;

  abstract get authenticated(): boolean;

  abstract get notAuthenticated$(): Observable<boolean>;

  abstract get hasActiveTenant$(): Observable<boolean>;

  abstract get activeTenant$(): Observable<AuthenticatedUserTenant | null>;

  abstract get activeTenant(): AuthenticatedUserTenant | null;

  abstract get authenticatedUserTenants$(): Observable<
    AuthenticatedUserTenant[]
  >;

  abstract get hasAuthenticatedUser$(): Observable<boolean>;

  abstract get hasNoAuthenticatedUser$(): Observable<boolean>;

  abstract get authenticatedUser$(): Observable<AuthenticatedUser>;

  abstract get accessToken$(): Observable<string>;

  abstract get accessToken(): string;

  abstract login(): void;

  abstract init(): void;

  abstract logout(): void;

  abstract selectTenant(tenant: Tenant): void;

  abstract reloadAuthenticatedUser(): void;

  abstract reloadActiveTenant(): void;

  abstract refreshToken(): void;

  abstract getRefreshedToken(): Observable<string | null>;
}
