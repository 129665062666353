<mat-progress-bar
  mode="indeterminate"
  *ngIf="service.loading$ | async"
></mat-progress-bar>
<span mat-dialog-title
  >Formular {{ service.isEdit ? 'bearbeiten' : 'anlegen' }}</span
>
<div mat-dialog-content>
  <tremaze-custom-form-edit
    [customFormEditConfig]="config"
  ></tremaze-custom-form-edit>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="undefined" type="button">
    Abbrechen
  </button>
  <button
    mat-raised-button
    color="accent"
    (click)="onClickSubmitButton()"
    type="button"
  >
    Speichern
  </button>
</div>
