import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

@Component({
  selector: 'tremaze-folder-view-file-contextmenu',
  standalone: true,
  imports: [CommonModule, IconComponent, MatMenuModule],
  templateUrl: './folder-view-file-contextmenu.component.html',
  styleUrl: './folder-view-file-contextmenu.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-file-contextmenu',
  },
})
export class FolderViewFileContextmenuComponent {
  @Input({ required: true }) file?: FileStorage;
  @Input() canWrite?: boolean;
  @Input() canDelete?: boolean;

  @Output() readonly showFilePreview = new EventEmitter<void>();
  @Output() readonly duplicateFile = new EventEmitter<void>();
  @Output() readonly deleteFile = new EventEmitter<void>();
  @Output() readonly downloadFile = new EventEmitter<void>();
  @Output() readonly copyFileLink = new EventEmitter<void>();
  @Output() readonly renameFile = new EventEmitter<void>();
  @Output() readonly navigateToPath = new EventEmitter<void>();
}
