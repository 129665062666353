import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HintComponent } from './hint.component';
import { MatButton, MatIconButton } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatButton, MatIconButton],
  declarations: [HintComponent],
  exports: [HintComponent],
})
export class SharedUiHintModule {}
