import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, first, map, Observable } from 'rxjs';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { FolderViewDeletionService } from './folder-view-deletion.service';
import { FolderViewSelectionType } from '../types';

@Injectable()
export class FolderViewSelectionService {
  private readonly _deletionService = inject(FolderViewDeletionService);
  readonly selectedFiles$ = new BehaviorSubject<FileStorage[]>([]);
  readonly selectedDirectories$ = new BehaviorSubject<DirStorage[]>([]);
  readonly hasSelection$: Observable<boolean> = combineLatest([
    this.selectedFiles$,
    this.selectedDirectories$,
  ]).pipe(
    map(([files, directories]) => !!files.length || !!directories.length),
  );

  private _selectionType?: FolderViewSelectionType;

  get _isSingleSelection() {
    return (
      this._selectionType === 'singleFile' ||
      this._selectionType === 'singleFolder'
    );
  }

  private _selectionRegex$ = new BehaviorSubject<RegExp | undefined>(undefined);

  readonly selectionRegex$ = this._selectionRegex$.asObservable();

  clearSelection() {
    this.selectedFiles$.next([]);
    this.selectedDirectories$.next([]);
  }

  setSelectionType(type: FolderViewSelectionType | undefined) {
    this._selectionType = type;
  }

  setSelectionRegex(value?: RegExp) {
    this._selectionRegex$.next(value);
  }

  setSelectedFiles(files: FileStorage[]) {
    this.selectedFiles$.next(files);
  }

  setSelectedDirectories(directories: DirStorage[]) {
    this.selectedDirectories$.next(directories);
  }

  deleteSelectedFilesAndFolders() {
    const selectedFiles = this.selectedFiles$.value;
    const selectedFolders = this.selectedDirectories$.value;

    this._deletionService.deleteFilesAndDirectories(
      selectedFiles,
      selectedFolders,
    );
  }

  isDirectorySelected(dir: DirStorage) {
    return this.selectedDirectories$.value.some((d) => d.id === dir.id);
  }

  isFileSelected(file: FileStorage) {
    return this.selectedFiles$.value.some((f) => f.id === file.id);
  }

  switchDirectorySelection(dir: DirStorage) {
    if (this._isSingleSelection) {
      if (this.isDirectorySelected(dir)) {
        this.selectedDirectories$.next([]);
        return;
      }
      this.selectedDirectories$.next([dir]);
      this.selectedFiles$.next([]);
      return;
    }
    if (this.isDirectorySelected(dir)) {
      this.selectedDirectories$.next(
        this.selectedDirectories$.value.filter((d) => d.id !== dir.id),
      );
    } else {
      const canSelect = !dir.tenantDir && !dir.institution;

      if (!canSelect) {
        return;
      }

      this.selectedDirectories$.next([...this.selectedDirectories$.value, dir]);
    }
  }

  switchFileSelection(file: FileStorage) {
    if (this._selectionRegex$.value) {
      if (!this._selectionRegex$.value.test(file.fileType)) {
        return;
      }
    }

    if (this._isSingleSelection) {
      if (this.isFileSelected(file)) {
        this.selectedFiles$.next([]);
        return;
      }
      this.selectedFiles$.next([file]);
      this.selectedDirectories$.next([]);
      return;
    }
    if (this.isFileSelected(file)) {
      this.selectedFiles$.next(
        this.selectedFiles$.value.filter((f) => f.id !== file.id),
      );
    } else {
      this.selectedFiles$.next([...this.selectedFiles$.value, file]);
    }
  }

  setSelection(files: FileStorage[], directories: DirStorage[]) {
    this.selectedFiles$.next(files);
    this.selectedDirectories$.next(directories);
  }

  selectAll(
    files$: Observable<FileStorage[]>,
    directories$: Observable<DirStorage[]>,
  ) {
    combineLatest([files$, directories$])
      .pipe(first())
      .subscribe(([files, directories]) => {
        this.selectedFiles$.next(files);
        this.selectedDirectories$.next(
          directories.filter((d) => d.canBeTinkeredWith),
        );
      });
  }
}
