<button (click)='openFolder.emit()' mat-menu-item>
      <span class='folder-view-folder-contextmenu__Item__Content-Wrapper'>
        <tremaze-icon
          class='folder-view-folder-contextmenu__Item__Icon'
          icon='lnr-folder'
        ></tremaze-icon>
        <span>Ordner öffnen</span>
      </span>
</button>

@if (folder?.canBeTinkeredWith && canWrite) {
  <button mat-menu-item (click)='renameFolder.emit()'>
        <span class='folder-view-folder-contextmenu__Item__Content-Wrapper'>
          <tremaze-icon
            class='folder-view-folder-contextmenu__Item__Icon'
            icon='lnr-pencil2'
          ></tremaze-icon>
          <span>Ordner umbenennen</span>
        </span>
  </button>

  <button mat-menu-item (click)='deleteFolder.emit()' style='color: red'>
        <span class='folder-view-folder-contextmenu__Item__Content-Wrapper'>
          <tremaze-icon
            class='folder-view-folder-contextmenu__Item__Icon'
            icon='lnr-trash2'
          ></tremaze-icon>
          <span>Ordner löschen</span>
        </span>
  </button>
}
