import { Department } from '@tremaze/shared/feature/department/types';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { PageGenerator } from '../helpers';
import { InstitutionFixtures } from '../institution';

class DepartmentFixturePageGenerator extends PageGenerator<Department> {
  override generateByIndex(index: number): Department {
    return DepartmentFixtures.generateDepartment(index);
  }
}

export abstract class DepartmentFixtures {
  private static _departmentStubPageGenerator =
    new DepartmentFixturePageGenerator();

  static generateDepartmentPage = (
    options: DataSourceMethodsPaginatedOptions,
  ) => DepartmentFixtures._departmentStubPageGenerator.generatePage(options);

  static generateDepartment = (id: number): Department =>
    new Department(
      id.toString(),
      undefined,
      `Department ${id}`,
      InstitutionFixtures.generateInstitution(id),
    );

  static readonly department1: Department =
    DepartmentFixtures.generateDepartment(1);
  static readonly department2: Department =
    DepartmentFixtures.generateDepartment(2);
  static readonly department3: Department =
    DepartmentFixtures.generateDepartment(3);
  static readonly department4: Department =
    DepartmentFixtures.generateDepartment(4);
  static readonly department5: Department =
    DepartmentFixtures.generateDepartment(5);

  static readonly departments = [
    DepartmentFixtures.department1,
    DepartmentFixtures.department2,
    DepartmentFixtures.department3,
    DepartmentFixtures.department4,
    DepartmentFixtures.department5,
  ];
}
