import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceLevelSettingComponent } from './price-level-setting.component';
import { PriceLevelSettingButtonDirective } from './price-level-setting-button.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { SharedUiRichTextEditorModule } from '@tremaze/shared/ui/rich-text-editor';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    SharedUiRichTextEditorModule,
  ],
  declarations: [PriceLevelSettingComponent, PriceLevelSettingButtonDirective],
  exports: [PriceLevelSettingButtonDirective],
})
export class SharedFeatureEventFeaturePriceLevelSettingModule {}
