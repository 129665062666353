import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { User } from '@tremaze/shared/feature/user/types';

export class DivisionManager {
  constructor(
    readonly id: string,
    public user: User,
    public manager: boolean,
    public manageInformation: boolean,
  ) {}

  static deserialize(data: any): DivisionManager | null {
    return !data
      ? null
      : new DivisionManager(
          data.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          User.deserialize(data.user)!,
          data.manager,
          data.manageInformation,
        );
  }
}

@staticImplements<Deserializable<Division>>()
export class Division {
  constructor(
    readonly id?: string,
    public name?: string,
    public institutions: Institution[] = [],
    public isPublic: boolean = false,
    public managers: DivisionManager[] = [],
    public manager?: boolean,
    public manageInformation?: boolean,
  ) {}

  static deserialize(data: any): Division | null {
    return !data
      ? null
      : new Division(
          data.id,
          data.name,
          data.institutions?.map(Institution.deserialize),
          data.isPublic ?? data.public ?? false,
          data.managers?.map(DivisionManager.deserialize),
          data.manager,
          data.manageInformation,
        );
  }

  static empty(): Division {
    return new Division();
  }

  get initials(): string {
    const split = (this.name ?? '??')
      .split(' ')
      .map((s) => s.trim())
      .filter((s) => s.length);
    const r = [];
    for (let i = 0; i <= Math.min(split.length - 1, 1); i++) {
      r.push(split[i].charAt(0).toUpperCase());
    }
    return r.join('');
  }
}
