import { PageGenerator } from '../helpers';
import { CostBearer } from '@tremaze/shared/feature/approval/types';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Address } from '@tremaze/shared/models';

class CostBearerFixturePageGenerator extends PageGenerator<CostBearer> {
  override generateByIndex(index: number): CostBearer {
    return CostBearerFixtures.generateCostBearer(index);
  }
}

export abstract class CostBearerFixtures {
  static generateCostBearer = (id: number): CostBearer => ({
    shortName: `CB${id}`,
    name: `Cost Bearer ${id}`,
    address: new Address(
      { name: 'Germany', code: 'DE' },
      'Berlin',
      '12345',
      'Musterstraße ' + id,
      'Dachgeschoss',
    ),
    id: `${id}`,
  });

  private static _costBearerStubPageGenerator =
    new CostBearerFixturePageGenerator();

  static generateCostBearerPage = (
    options: DataSourceMethodsPaginatedOptions,
  ) => CostBearerFixtures._costBearerStubPageGenerator.generatePage(options);

  static readonly costBearer1: CostBearer =
    CostBearerFixtures.generateCostBearer(1);
  static readonly costBearer2: CostBearer =
    CostBearerFixtures.generateCostBearer(2);
  static readonly costBearer3: CostBearer =
    CostBearerFixtures.generateCostBearer(3);
  static readonly costBearer4: CostBearer =
    CostBearerFixtures.generateCostBearer(4);
  static readonly costBearer5: CostBearer =
    CostBearerFixtures.generateCostBearer(5);

  static readonly costBearers = [
    CostBearerFixtures.costBearer1,
    CostBearerFixtures.costBearer2,
    CostBearerFixtures.costBearer3,
    CostBearerFixtures.costBearer4,
    CostBearerFixtures.costBearer5,
  ];
}
