import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportDataTypeSelectorComponent } from './export-data-type-selector.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatRadioModule,
    MatButtonModule,
    FormsModule,
  ],
  declarations: [ExportDataTypeSelectorComponent],
  exports: [ExportDataTypeSelectorComponent],
})
export class SharedUiExportDataTypeSelectorModule {}
