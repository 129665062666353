import { Directive, HostListener, Input } from '@angular/core';
import { PopupRef } from './popup-ref';

@Directive({
  selector: '[tremazePopupClose]',
})
export class PopupCloseDirective {
  constructor(private ref: PopupRef<any>) {}

  private _data: any;

  @Input('tremazePopupClose')
  set data(data: any) {
    this._data = data;
  }

  @HostListener('click')
  close() {
    this.ref.close(this._data);
  }
}
