import { Address } from '@tremaze/shared/models';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

@staticImplements<Deserializable<TremazeLocation>>()
export class TremazeLocation {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly address: Address,
  ) {}

  static deserialize(input: any): TremazeLocation {
    return new TremazeLocation(
      input.id,
      input.name,
      Address.deserialize(input.address),
    );
  }
}
