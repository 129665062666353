import { Component, Input } from '@angular/core';
import {
  TremazeSchedule,
  TremazeScheduleRepetitionType,
} from '@tremaze/shared/scheduling/types';
import { MatSelectionListChange } from '@angular/material/list';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { EventTemplateEditFormService } from '../../event-template-edit-form.service';
import { FormGroup } from '@ngneat/reactive-forms';
import {
  animate,
  sequence,
  style,
  transition,
  trigger,
} from '@angular/animations';

const growDuration = 150;
const fadeDuration = 400;

const growTiming = `${growDuration}ms ease-out`;
const fadeTiming = `${fadeDuration}ms ease-in`;

@Component({
  selector: 'tremaze-event-template-schedule-settings',
  templateUrl: './schedule-settings.component.html',
  animations: [
    trigger('formRow', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        sequence([
          animate(growTiming, style({ height: '*' })),
          animate(fadeTiming, style({ opacity: 1 })),
        ]),
      ]),
      transition(':leave', [
        sequence([
          animate(fadeTiming, style({ opacity: 0 })),
          animate(growTiming, style({ height: 0 })),
        ]),
      ]),
    ]),
  ],
})
export class EventTemplateScheduleSettingsComponent {
  constructor(
    private readonly eventTemplateForm: EventTemplateEditFormService,
  ) {}

  @Input() isSettingDisabled = false;

  readonly now = new Date();

  get formGroup() {
    return this.eventTemplateForm.formGroup;
  }

  readonly repetitionTypeValue$ = this.formGroup.valueChanges.pipe(
    map(() => this.formGroup.getRawValue().schedule?.repeatEvery),
    startWith(this.formGroup.getRawValue().schedule?.repeatEvery),
    map((value) => value ?? null),
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly repeatEveryOptions: {
    [key in TremazeScheduleRepetitionType]?: TremazeScheduleRepetitionType;
  } = {
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    YEAR: 'YEAR',
  };

  readonly intervalOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  get intervalControl() {
    return (this.formGroup.controls.schedule as FormGroup<TremazeSchedule>)
      ?.controls.repeatNumber;
  }

  readonly intervalLabel$ = combineLatest([
    this.repetitionTypeValue$,
    this.formGroup.valueChanges.pipe(
      map((value) => value.schedule?.repeatNumber),
      startWith(this.formGroup.controls.schedule?.getRawValue()?.repeatNumber),
      map((value) => value ?? null),
      distinctUntilChanged(),
    ),
  ]).pipe(
    map(([repetitionType, interval]) => {
      if (repetitionType == null) {
        return '';
      }
      switch (repetitionType) {
        case 'DAY':
          return `${interval} Tag${interval > 1 ? 'e' : ''}`;
        case 'WEEK':
          return `${interval} Woche${interval > 1 ? 'n' : ''}`;
        case 'MONTH':
          return `${interval} Monat${interval > 1 ? 'e' : ''}`;
        case 'YEAR':
          return `${interval} Jahr${interval > 1 ? 'e' : ''}`;
      }
    }),
  );

  getIntervalOptionLabel(interval: number) {
    switch (this.formGroup.value.schedule?.repeatEvery) {
      case 'DAY':
        return `${interval} Tag${interval > 1 ? 'e' : ''}`;
      case 'WEEK':
        return `${interval} Woche${interval > 1 ? 'n' : ''}`;
      case 'MONTH':
        return `${interval} Monat${interval > 1 ? 'e' : ''}`;
      case 'YEAR':
        return `${interval} Jahr${interval > 1 ? 'e' : ''}`;
    }
  }

  onChangeRepetitionType(event: MatSelectionListChange) {
    const value: TremazeScheduleRepetitionType | null =
      event.options[0]?.value ?? null;
    if (value == null) {
      this.eventTemplateForm.removeSchedule();
    } else {
      this.eventTemplateForm.addSchedule(value);
    }
  }

  get allowedRegistrationScopeFeatureAssistantMessage(): string {
    return `
      <p>
        Hiermit kannst Du beschränken, wie sich Nutzer*innen zu dieser Serie
        anmelden können.
      </p>
      <ul>
        <li>
          <h4>Nur für die gesamte Serie</h4>
          <p>
            Nutzer*innen können sich nur für die gesamte Serie anmelden.<br>
            Sie können sich nicht für einzelne Termine anmelden.
          </p>
        </li>
        <li>
          <h4>Nur für einzelne Termine der Serie</h4>
          <p>
            Nutzer*innen können sich nur für einzelne Termine der Serie anmelden.<br>
            Sie können sich nicht für die gesamte Serie anmelden.
          </p>
        </li>
      </ul>
    `;
  }
}
