import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Report } from '../domain/report.type';

@Injectable()
export abstract class ReportGeneratorService {
  _isReportsActivated = true;
  set isReportsActivated(value: boolean) {
    this._isReportsActivated = value;
  }

  get isReportsActivated(): boolean {
    return this._isReportsActivated;
  }

  abstract generateReport(
    formSubmissionId: string,
    type: 'PDF' | 'CSV',
  ): Observable<Report>;

  abstract getPreviewUrl(formSubmissionId: string): Observable<string>;
}
