export class Report {
  constructor(
    public id: string,
    public type: 'CSV' | 'PDF',
    public file?: { id: string },
  ) {}

  static deserialize(data: any): Report {
    return new Report(
      data.id,
      data.type,
      data.fileId ? { id: data.fileId } : undefined,
    );
  }
}
