<tremaze-circle-avatar
  [file]="user.profileImage"
  [fallbackInitials]="user.initials"
>
  <ng-content
    select="[tremazeCircleAvatarBadge]"
    ngProjectAs="[tremazeCircleAvatarBadge]"
  ></ng-content>
</tremaze-circle-avatar>

<div style="min-width: 0">
  <h4 style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
    {{ user | fullName }}
  </h4>
  <h6 style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
    {{ userTypeString
    }}<strong
      *ngIf="institutionString"
      [matTooltip]="fullInstitutionString ?? ''"
      >{{ institutionString }}</strong
    >
  </h6>
</div>
