import { Component, inject } from '@angular/core';
import { EventTemplateEditFormService } from '../../event-template-edit-form.service';

@Component({
  selector: 'tremaze-event-template-edit-time-settings',
  template: `
    <mat-form-field>
      <mat-label>Standardtermindauer (hh:mm)</mat-label>
      <input
        [formControl]="formGroup.controls.defaultAppointmentDuration"
        matInput
        type="time"
      />
      <mat-error>Bitte gib eine Dauer an</mat-error>
    </mat-form-field>
  `,
})
export class EventTemplateEditTimeSettingsComponent {
  readonly formGroup = inject(EventTemplateEditFormService).formGroup;
}
