import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Pagination } from '@tremaze/shared/models';

export abstract class PageGenerator<T extends object> {
  abstract generateByIndex(index: number): T;

  generatePage(options: DataSourceMethodsPaginatedOptions): Pagination<T> {
    const page = options.filter?.page ?? 0;
    const pageSize = options.filter?.pageSize ?? 10;

    let items = Array.from({ length: pageSize }, (_, i) =>
      this.generateByIndex(page * pageSize + i),
    );

    if (options.filter?.filterValue && options.filter?.filterFields) {
      const { filterFields, filterValue } = options.filter;
      items = items.filter((item) => {
        return filterFields.some((field) => {
          if (field in item) {
            const value = (item as any)[field];
            return value?.toString().includes(filterValue);
          }
          return true;
        });
      });
    }

    const isEmpty = items.length === 0;
    const isFirstPage = page === 0;
    const isLastPage = false;
    const totalElements = 1000;
    const totalPages = Math.ceil(totalElements / pageSize);

    return new Pagination<T>(
      items,
      isEmpty,
      isFirstPage,
      isLastPage,
      page,
      items.length,
      pageSize,
      undefined,
      undefined,
      totalElements,
      totalPages,
    );
  }
}
