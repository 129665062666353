import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFormSelectionButtonComponent } from './custom-form-selection-button/custom-form-selection-button.component';
import { MatButtonModule } from '@angular/material/button';
import { DynamicListModule } from '@tremaze/dynamic-list';
import { SharedFeatureCustomFormsFeatureEditModule } from '@tremaze/shared/feature/custom-forms/feature/edit';
import { PickerPopupModule } from '@tremaze/picker-popup';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    DynamicListModule,
    PickerPopupModule,
    SharedFeatureCustomFormsFeatureEditModule,
  ],
  declarations: [CustomFormSelectionButtonComponent],
  exports: [CustomFormSelectionButtonComponent],
})
export class SharedFeatureCustomFormsFeatureSelectionModule {}
