import { Meta } from '@tremaze/shared/models';
import { TremazeDate, WEEKDAYS } from '@tremaze/shared/util-date';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValidatorFn } from '@angular/forms';

export type TremazeScheduleRepetitionType = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';

export type TremazeScheduleRegistrationScope = 'ALL' | 'SINGLE';

type FC<T> = FormControl<T | null>;

type EditModel<T> = {
  [key in keyof T]: FC<T[key]>;
};
export type ModifiableEventSchedule = Omit<
  Required<TremazeSchedule>,
  'id' | 'meta'
>;

export type EventScheduleEditModel = EditModel<ModifiableEventSchedule>;

export function conditionalRepeatDaysValidator(): ValidatorFn {
  return (
    formGroup: FormGroup<EventScheduleEditModel>,
  ): { [key: string]: any } | null => {
    const repeatEveryControl = formGroup.controls.repeatEvery;
    const repeatDaysControl = formGroup.controls.repeatDays;

    // If `repeatEvery` is not 'WEEK', validation will always pass
    if (repeatEveryControl && repeatEveryControl.value !== 'WEEK') {
      repeatDaysControl.setErrors(null);
    }

    // If `repeatEvery` is 'WEEK' but `repeatDays` is empty, attach the error to `repeatDays` control
    if (
      repeatEveryControl &&
      repeatDaysControl &&
      repeatEveryControl.value === 'WEEK' &&
      !repeatDaysControl.value
    ) {
      // Set the error on the repeatDays control
      repeatDaysControl.setErrors({ required: true });
    } else {
      // If repeatDays has a value or repeatEvery is not 'WEEK', clear the errors related to this validator
      if (repeatDaysControl && repeatDaysControl.hasError('required')) {
        repeatDaysControl.setErrors(null);
      }
    }

    return null; // Return null because the validity is set on the repeatDays control directly
  };
}

@staticImplements<Deserializable<TremazeSchedule>>()
export class TremazeSchedule {
  constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public startDate?: TremazeDate,
    public endDate?: TremazeDate,
    public repeatEvery: TremazeScheduleRepetitionType = 'DAY',
    public repeatDays: WEEKDAYS[] = [],
    public repeatNumber = 1,
    public allowedRegistrationScope?: TremazeScheduleRegistrationScope,
  ) {}

  static deserialize(data: any): null | TremazeSchedule {
    return !data
      ? null
      : new TremazeSchedule(
          data.id,
          Meta.deserialize(data.meta),
          TremazeDate.deserialize(data.startDate),
          TremazeDate.deserialize(data.endDate),
          data.repeatEvery,
          data.repeatDays?.map((d) => (typeof d === 'string' ? d : d?.day)),
          data.repeatNumber,
          data.allowedRegistrationScope,
        );
  }

  equals(other?: TremazeSchedule): boolean {
    return (
      other instanceof TremazeSchedule &&
      this.startDate?.isSame(other.startDate) &&
      this.endDate?.isSame(other.endDate) &&
      this.repeatEvery === other.repeatEvery &&
      this.repeatDays?.length === other.repeatDays?.length &&
      this.repeatDays?.every((d) => other.repeatDays?.includes(d)) &&
      this.repeatNumber === other.repeatNumber &&
      this.allowedRegistrationScope === other.allowedRegistrationScope
    );
  }
}
