import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalFiltersDirective } from './additional-filters.directive';
import { ColumnDirective } from './column.directive';
import { PostActionsDirective } from './post-actions.directive';
import { PreActionsDirective } from './pre-actions.directive';
import { TableActionsDirective } from './table-actions.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AdditionalFiltersDirective,
    ColumnDirective,
    PostActionsDirective,
    PreActionsDirective,
    TableActionsDirective,
  ],
  exports: [
    AdditionalFiltersDirective,
    ColumnDirective,
    PostActionsDirective,
    PreActionsDirective,
    TableActionsDirective,
  ],
})
export class SharedSortedFilteredPaginatedTableUiDirectivesModule {}
