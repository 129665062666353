import { inject, Injectable } from '@angular/core';
import { PersonalConfigService } from '@tremaze/personal-config';
import { map, shareReplay } from 'rxjs';
import { Specialization } from '@tremaze/shared/feature/specialization/types';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';

@Injectable()
export class EventTemplateDataTableFilterService {
  private readonly _personalConfigService = inject(PersonalConfigService);

  readonly specializationsFilter$ =
    this._personalConfigService.personalConfig$.pipe(
      filterNotNullOrUndefined(),
      map(
        (r) => r.filterSettings.eventTemplateListSettings.filterSpecializations,
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

  readonly specializationsFilterIds$ = this.specializationsFilter$.pipe(
    map((r) => r.map((s) => s.id)),
  );

  updateSpecializationsFilter(specs: Specialization[]) {
    this._personalConfigService.updateFilterSetting(
      'eventTemplateListSettings',
      {
        filterSpecializations: specs,
      },
    );
  }
}
