import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';
import { FeatureAssistantService } from './feature-assistant.service';

type FeatureAssistantAlignment =
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'center'
  | 'centerLeft'
  | 'centerRight';

@Directive({
  selector: '[tremazeFeatureAssistant]',
})
export class FeatureAssistantDirective implements OnChanges {
  @Input({ required: true }) featureAssistantMessage!: string;
  @Input({ required: true }) featureName!: string;
  @Input() featureAssistantXOffset = '0px';
  @Input() featureAssistantYOffset = '0px';
  @Input() featureAssistantAlignment: FeatureAssistantAlignment = 'topRight';
  @Input() maxDialogWidth?: string;

  private iconElement?: HTMLElement;

  constructor(
    private readonly _el: ElementRef,
    private readonly _renderer: Renderer2,
    private readonly _service: FeatureAssistantService,
  ) {
    this.createIconElement();
  }

  ngOnChanges(): void {
    this.repositionIcon();
  }

  private _negateOffset(offset: string): string {
    if (offset.startsWith('-')) {
      return offset.substring(1);
    } else {
      return `-${offset}`;
    }
  }

  private getIconPosition(): {
    top?: string;
    left?: string;
    bottom?: string;
    right?: string;
    transform?: string;
  } {
    switch (this.featureAssistantAlignment) {
      case 'topLeft':
        return {
          top: this.featureAssistantYOffset,
          left: this.featureAssistantXOffset,
        };
      case 'topRight':
        return {
          top: this.featureAssistantYOffset,
          right: this._negateOffset(this.featureAssistantXOffset),
        };
      case 'bottomLeft':
        return {
          bottom: this._negateOffset(this.featureAssistantYOffset),
          left: this.featureAssistantXOffset,
        };
      case 'bottomRight':
        return {
          bottom: this._negateOffset(this.featureAssistantYOffset),
          right: this._negateOffset(this.featureAssistantXOffset),
        };
      case 'center':
        return {
          top: `calc(50% + ${this.featureAssistantYOffset})`,
          left: `calc(50% + ${this.featureAssistantXOffset})`,
          transform: 'translate(-50%, -50%)',
        };
      case 'centerLeft':
        return {
          top: `calc(50% + ${this.featureAssistantYOffset})`,
          left: this.featureAssistantXOffset,
          transform: 'translateY(-50%)',
        };
      case 'centerRight':
        return {
          top: `calc(50% + ${this.featureAssistantYOffset})`,
          right: this._negateOffset(this.featureAssistantXOffset),
          transform: 'translateY(-50%)',
        };
    }
  }

  private repositionIcon() {
    const position = this.getIconPosition();
    this._renderer.setStyle(this.iconElement, 'top', position.top);
    this._renderer.setStyle(this.iconElement, 'left', position.left);
    this._renderer.setStyle(this.iconElement, 'bottom', position.bottom);
    this._renderer.setStyle(this.iconElement, 'right', position.right);
    this._renderer.setStyle(this.iconElement, 'transform', position.transform);
  }

  private createIconElement(): void {
    // Make sure the element is position relative
    this._renderer.setStyle(this._el.nativeElement, 'position', 'relative');
    this.iconElement = this._renderer.createElement('span');
    this._renderer.addClass(this.iconElement, 'lnr');
    this._renderer.addClass(this.iconElement, 'lnr-question-circle');
    // position the icon
    this._renderer.setStyle(this.iconElement, 'position', 'absolute');

    this._renderer.setStyle(this.iconElement, 'z-index', '100');
    this._renderer.setStyle(this.iconElement, 'cursor', 'pointer');

    // add click listener
    this._renderer.listen(this.iconElement, 'click', (event: Event) =>
      this.onClick(event),
    );

    this._renderer.appendChild(this._el.nativeElement, this.iconElement);
  }

  onClick(event: Event): void {
    event.stopPropagation();
    this._service.open({
      title: this.featureName,
      message: this.featureAssistantMessage,
      maxWidth: this.maxDialogWidth,
    });
  }
}
