import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes',
  standalone: true,
})
export class BytesPipe implements PipeTransform {
  transform(value: number): unknown {
    if (value <= 0) {
      return '0 B';
    }
    if (value < 1024) {
      return value + ' B';
    }
    const exp = Math.floor(Math.log(value) / Math.log(1024));
    const size = value / Math.pow(1024, exp);
    const shortSize = size.toFixed(2);
    return shortSize + ' ' + 'KMGTPEZY'[exp - 1] + 'B';
  }
}
