import { NgModule } from '@angular/core';
import { SharedFeatureEventFeatureEventScopeSelectorModule } from '@tremaze/shared/feature/event/feature/event-scope-selector';
import { EventPresetSelectionModule } from '@tremaze/shared/feature/event/feature/event-preset-selection';
import { provideEventEditService } from './event-edit.service';
import { SelectUserNotificationModule } from '@tremaze/select-user-notification';

@NgModule({
  imports: [
    SharedFeatureEventFeatureEventScopeSelectorModule,
    EventPresetSelectionModule,
    SelectUserNotificationModule,
  ],
  providers: [provideEventEditService()],
})
export class SharedFeatureEventFeatureEventEditModule {}
