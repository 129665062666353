/* eslint-disable @angular-eslint/no-input-rename */
import { FileSelectorService } from '@tremaze/shared/feature/file-storage/ui/file-selector';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl } from '@angular/forms';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';
import { PermissionCheckService } from '@tremaze/shared/permission/services';
import { NotificationService } from '@tremaze/shared/notification';
import { FilePreviewOverlayService } from '@tremaze/shared/feature/file-storage/ui/file-preview-overlay';
import { ImageCropperService } from '@tremaze/shared/feature/file-storage/feature/image-cropper';
import { InstitutionSelectionService } from '@tremaze/shared/feature/institution/feature/selection';
import { FileInputAcceptTypeOrArray, FileInputBase } from '../../../../file-input-base/file-input-base';
import { FileStorage, FileStorageEntityType } from '@tremaze/shared/feature/file-storage/types';
import { ObservableInput } from 'ngx-observable-input';
import { TzPermissionRequest } from '@tremaze/shared/permission/types';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { blobToFile } from '@tremaze/shared/util-utilities';

@Component({
  selector: 'tremaze-file-selector-input',
  templateUrl: './file-selector-input.component.html',
  styleUrls: ['./file-selector-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: FileSelectorInputComponent },
  ],
})
export class FileSelectorInputComponent
  extends FileInputBase
  implements OnInit, DoCheck, AfterViewInit, OnDestroy
{
  @Output()
  valueChanged;
  // tslint:disable-next-line:no-input-rename
  @ObservableInput() @Input('instId') instId$;
  @Input() entityName = 'OTHER' as FileStorageEntityType;
  @Input() systemDirType;
  @Input() permissionRequest: TzPermissionRequest;
  @HostBinding('id') id;
  @HostBinding('attr.aria-describedby') describedBy = '';
  protected _uploadEvent$ = new Subject<FileList | File>();
  protected _multiple = false;
  @ViewChild('fileUploadInput', { static: false })
  private _fileUploadInput: ElementRef<HTMLInputElement> = null;

  constructor(
    private imageCropperService: ImageCropperService,
    fileService: FileStorageService,
    permissionChecker: PermissionCheckService,
    fileSelectorService: FileSelectorService,
    institutionSelectionService: InstitutionSelectionService,
    _cdRef: ChangeDetectorRef,
    _authService: AuthV2Service,
    @Optional() notificationService: NotificationService,
    @Optional() filePreviewOverlayService: FilePreviewOverlayService,
    @Optional() @Self() ngControl: NgControl,
  ) {
    super(
      fileService,
      permissionChecker,
      fileSelectorService,
      institutionSelectionService,
      _cdRef,
      notificationService,
      filePreviewOverlayService,
      ngControl,
      _authService,
    );
  }

  get mode() {
    return super.mode;
  }

  @Input()
  set mode(value: FileInputAcceptTypeOrArray) {
    super.mode = value;
  }

  get shouldLabelFloat() {
    return !this.disabled && !this.empty;
  }

  get canShowPreview(): boolean {
    return super.canShowPreview;
  }

  get canUpload(): boolean {
    return super.canUpload;
  }

  @Input()
  @HostBinding('class.noActions')
  get disabled() {
    return super.disabled;
  }

  @Input()
  get required() {
    return super.required;
  }

  get aspectRatio() {
    return super.aspectRatio;
  }

  @Input()
  set aspectRatio(value: number) {
    super.aspectRatio = value;
  }

  get empty() {
    return super.empty;
  }

  get value(): FileStorage {
    if (super.value instanceof FileStorage) {
      return super.value;
    }
    return super.value?.[0];
  }

  @Input()
  set value(v: FileStorage[] | FileStorage | null) {
    if (this._multiple && v && !Array.isArray(v)) {
      v = [v];
    } else if (!this._multiple && v && Array.isArray(v)) {
      v = v[0];
    }
    super.value = v;
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  ngAfterViewInit(): void {
    fromEvent(this._fileUploadInput.nativeElement, 'change')
      .pipe(
        tap((ev) => {
          const fileList = (ev.target as HTMLInputElement).files;
          this._uploadEvent$.next(fileList);
          this._fileUploadInput.nativeElement.value = null;
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  ngDoCheck(): void {
    this.doCheck();
  }

  async onClickCrop() {
    if (this.value) {
      const blob = await this._fileService.downloadBlob(this.value).toPromise();
      if (blob) {
        const r = await this.imageCropperService
          .cropImage({ blob, aspectRatio: this.aspectRatio })
          .toPromise();
        if (r) {
          const file: File = blobToFile(
            r,
            this.value.filename,
            this.value.fileType,
          );
          this._uploadEvent$.next(file);
        }
      }
    }
  }
}
