import { Component, inject } from '@angular/core';
import { EventTemplateEditFormService } from '../../event-template-edit-form.service';

@Component({
  selector: 'tremaze-event-template-registration-settings',
  templateUrl: './event-template-registration-settings.component.html',
})
export class EventTemplateRegistrationSettingsComponent {
  readonly eventTemplateFormService = inject(EventTemplateEditFormService);

  get formGroup() {
    return this.eventTemplateFormService?.formGroup;
  }

  readonly featureAssistantMessage =
    'Wenn der Termin über mehr als eine Kategorie verfügt, können die Nutzer*innen bei der Anmeldung eine dieser Kategorien als "Termingrund" auswählen.';
}
