<mat-form-field [appearance]="appearance" subscriptSizing="dynamic">
  <mat-label>Förderbereiche</mat-label>
  <mat-select
    [value]="value"
    [multiple]="multiple"
    [compareWith]="idCompareFn"
    (selectionChange)="onSelectionChange($event)"
  >
    @for (spec of specializations$ | async; track spec.id) {
      <mat-option [value]="spec">{{ spec.name }}</mat-option>
    } @empty {
      <mat-option disabled>Keine Förderbereiche gefunden</mat-option>
    }
  </mat-select>
</mat-form-field>
