import { Injectable } from '@angular/core';
import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSourceImpl,
} from '@tremaze/shared/util-http';
import { Category, CategoryType } from '@tremaze/shared/feature/category/types';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { Pagination } from '@tremaze/shared/models';
import { Observable, of } from 'rxjs';
import { InstitutionContextService } from '@tremaze/shared/feature/institution/shared/singletons';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export abstract class RemoteCCCategoryDataSource extends DefaultCRUDDataSourceImpl<Category> {
  protected abstract _categoryType: CategoryType;
  protected controller = '/categories';
  protected deserializer = Category.deserialize;

  get categoryType(): CategoryType {
    return this._categoryType;
  }

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<Category>> {
    return super.getPaginated({
      ...(options ?? {}),
      q: { ...(options?.q ?? {}), categoryType: this._categoryType },
    });
  }

  create(
    {
      name,
      icon,
      color,
      titleImage,
      userTypes,
      institutions,
      departments,
      description,
    }: Category,
    options?: DataSourceMethodsCreateOptions<Category>,
  ): Observable<Category> {
    const payload: CreateDTO = {
      categoryType: this._categoryType,
      color: color?.toHexString(),
      instIds: institutions?.map((i) => i.id) ?? options?.instIds,
      departmentIds: departments?.map((d) => d.id),
      icon: icon,
      name: name,
      titleImageId: titleImage?.id,
      userTypeIds: userTypes?.map((i) => i.id),
      description,
    };
    return super.create(payload as any, options);
  }

  edit(
    {
      id,
      name,
      icon,
      color,
      titleImage,
      userTypes,
      institutions,
      departments,
      description,
    }: Category,
    options?: DataSourceMethodsEditOptions<Category>,
  ): Observable<Category> {
    const payload: EditDTO = {
      id,
      name,
      icon,
      instIds: institutions?.map((i) => i.id) ?? options?.instIds,
      departmentIds: departments?.map((d) => d.id),
      color: color.r === undefined ? null : color.toHexString(),
      userTypeIds: userTypes?.map((i) => i.id),
      titleImageId: titleImage?.id,
      description,
    };
    return super.edit(payload as any, options);
  }
}

@Injectable({ providedIn: 'root' })
export class RemoteCCInformationCategoryDataSource extends RemoteCCCategoryDataSource {
  protected _categoryType = 'INFORMATION_CATEGORY' as CategoryType;
  protected filterFields: ['NAME'];

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class RemoteCCEventCategoryDataSource extends RemoteCCCategoryDataSource {
  protected _categoryType = 'EVENT_CATEGORY' as CategoryType;
  protected filterFields: ['NAME'];

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class RemotePublicEventCategoryDataSource extends DefaultCRUDDataSourceImpl<Category> {
  protected controller = '/public/eventCategory';
  protected deserializer = Category.deserialize;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
    private contextService: InstitutionContextService,
  ) {
    super();
  }

  getAllFromInstitution(): Observable<Category[]> {
    return this.contextService.currentInstitutionId$.pipe(
      mergeMap((id) => {
        if (!id) return of(null);
        return this.http
          .get<Category[]>(this.controller + '/getAllFromInst/' + id)
          .pipe(map((data: any) => data.items));
      }),
    );
  }
}

interface BaseDTO {
  color: string;
  icon: string;
  name: string;
  titleImageId: string;
  userTypeIds: string[];
  departmentIds?: string[];
  instIds: string[];
  description: string;
}

interface CreateDTO extends BaseDTO {
  categoryType: CategoryType;
}

interface EditDTO extends BaseDTO {
  id: string;
}
