import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableAddNewItemButtonComponent } from './table-add-new-item-button.component';

@NgModule({
  imports: [CommonModule, MatButtonModule],
  declarations: [TableAddNewItemButtonComponent],
  exports: [TableAddNewItemButtonComponent],
})
export class SharedUiTableAddNewItemButtonModule {}
