import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RichTextEditorComponent } from './rich-text-editor.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';
import { SharedFeatureFileStorageUiFileSelectorModule } from '@tremaze/shared/feature/file-storage/ui/file-selector';

@NgModule({
  imports: [
    CommonModule,
    EditorModule,
    FormsModule,
    SharedFeatureFileStorageUiFileSelectorModule,
  ],
  declarations: [RichTextEditorComponent],
  exports: [RichTextEditorComponent],
})
export class SharedUiRichTextEditorModule {}
