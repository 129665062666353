<mat-card appearance="raised" class="image-preview-wrapper">
  <mat-toolbar color="primary">
    <span>{{ image.name }}</span>
    <button
      (click)="onClickDownload()"
      color="accent"
      mat-icon-button
      matTooltip="Herunterladen"
    >
      <span class="lnr lnr-download" style="font-weight: 700"></span>
    </button>
    <button
      (click)="dialogRef.close()"
      color="accent"
      mat-icon-button
      matTooltip="Schließen"
    >
      <span class="lnr lnr-cross" style="font-weight: 700"></span>
    </button>
  </mat-toolbar>
  <img
    (load)="loaded = true"
    *ngIf="image && image.type === 'IMAGE'"
    [class.loaded]="loaded"
    [src]="image.url"
    alt="File"
  />
  <video *ngIf="image.type === 'VIDEO'" autoplay controls>
    <source [src]="image.url" />
  </video>
  <object
    *ngIf="image && image.type === 'PDF'"
    [data]="safeResourceURL"
    type="application/pdf"
  ></object>
  <div
    *ngIf="!loaded && image.type === 'IMAGE'"
    class="image-preview-wrapper__spinner-wrapper"
  >
    <mat-spinner [diameter]="30" color="accent"></mat-spinner>
  </div>
</mat-card>
