import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressDisplayComponent } from './progress-display/progress-display.component';
import { ProgressDisplayItemComponent } from './progress-display-item/progress-display-item.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatProgressBarModule,
    DragDropModule,
  ],
  declarations: [ProgressDisplayComponent, ProgressDisplayItemComponent],
  exports: [ProgressDisplayComponent],
})
export class SharedUiProgressDisplayModule {}
