<fieldset [formGroup]="formGroup" class="form-grid-item-full-width">
  <legend>Einstellungen Auswahl</legend>

  <mat-form-field>
    <mat-label>Typ</mat-label>
    <mat-select formControlName="type">
      <mat-select-trigger>
        <span [class]="triggerIcon"></span>&nbsp;&nbsp;{{ triggerText }}
      </mat-select-trigger>
      <mat-option
        *ngFor="let type of multiSelectFieldTypeOptions | keyvalue"
        [value]="type.key"
      >
        <span [class]="multiSelectFieldTypeIcons[type.key]"></span>
        &nbsp;
        {{ type.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="form-grid-item-new-row">
    <mat-label>Min. ausgewählt</mat-label>
    <input
      matInput
      type="number"
      formControlName="minSelectedItems"
      [min]="minSelectedItemsMinValue$ | async"
      [max]="minSelectedItemsMaxValue$ | async"
    />
    <mat-error>{{ minSelectedItemsErrorMsg }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Max. ausgewählt</mat-label>
    <input
      matInput
      type="number"
      formControlName="maxSelectedItems"
      [min]="maxSelectedItemsMinValue$ | async"
      [max]="maxSelectedItemsMaxValue$ | async"
    />
    <mat-error>{{ maxSelectedItemsErrorMsg }}</mat-error>
  </mat-form-field>

  <h5>Auswahl</h5>

  <ol
    class="form-grid form-grid--dense form-grid-item-full-width form-grid--force-vertical"
    formArrayName="items"
    id="dragBoundary"
    cdkDropList
    cdkDropListLockAxis="y"
    (cdkDropListDropped)="onDrop($event)"
  >
    <li
      *ngFor="let item of options; let i = index"
      [formGroupName]="i"
      @field
      cdkDrag
      cdkDragBoundary="#dragBoundary"
    >
      <mat-icon cdkDragHandle>
        <span class="lnr lnr-menu"></span>
      </mat-icon>
      <mat-form-field style="margin-right: 10px">
        <mat-label>Anzeigewert</mat-label>
        <input
          matInput
          formControlName="label"
          (keyup.enter)="onPressedEnterInOption(i, $event)"
          #optionInput
          maxlength="120"
        />
        <mat-error>{{ getLabelErrorMsgByIndex(i) }}</mat-error>
        <mat-hint align="end"
          >{{ optionInput.value?.length ?? 0 }} / 120</mat-hint
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Technischer Wert</mat-label>
        <input
          matInput
          formControlName="value"
          (keyup.enter)="onPressedEnterInOption(i, $event)"
          #valueInput
          maxlength="120"
        />
        <mat-error>{{ getValueErrorMsgByIndex(i) }}</mat-error>
        <mat-hint align="end"
          >{{ valueInput.value?.length ?? 0 }} / 120</mat-hint
        >
      </mat-form-field>

      <button
        mat-icon-button
        color="warn"
        class="form-grid-item-full-width"
        type="button"
        [disabled]="disableRemoveOptionButton"
        (click)="onClickRemoveOption(i)"
      >
        <mat-icon>
          <span class="lnr lnr-trash2"></span>
        </mat-icon>
      </button>
    </li>
  </ol>

  <button mat-button (click)="onClickAddOptionButton()" type="button">
    Option hinzufügen
  </button>
</fieldset>
