import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { DragZoneItemDirective } from './drag-zone-item.directive';
import { DragZonePreviewComponent } from './drag-zone-preview/drag-zone-preview.component';
import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DropZoneDirective } from './drop-zone.directive';
import { clearAllTextSelection } from '@tremaze/shared/util-utilities';

@Directive({
  selector: '[tremazeDragZone]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'drag-zone',
  },
})
export class DragZoneDirective {
  private readonly _overlay = inject(Overlay);
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _element = inject(ElementRef);
  private overlayRef: OverlayRef | null = null;
  private _isDragging = false;
  private _dragStartCoords: { x: number; y: number } | null = null;

  private readonly _dragZoneItems: {
    [key: number]: DragZoneItemDirective<unknown>;
  } = {};

  private readonly _dropZoneItems: {
    [key: number]: DropZoneDirective;
  } = {};

  get dragData(): unknown[] {
    return Object.values(this._dragZoneItems)
      .filter((item) => !item.dragDisabled)
      .map((item) => item.dragData);
  }

  @HostBinding('class.drag-zone--Dragging')
  get isDragging(): boolean {
    return this._isDragging;
  }

  @Input() dragPreviewTemplate?: DragZonePreviewComponent;

  registerDragItem(id: number, item: DragZoneItemDirective<unknown>): void {
    this._dragZoneItems[id] = item;
  }

  unregisterDragItem(id: number): void {
    delete this._dragZoneItems[id];
  }

  registerDropZone(id: number, dropZone: DropZoneDirective): void {
    this._dropZoneItems[id] = dropZone;
  }

  unregisterDropZone(id: number): void {
    delete this._dropZoneItems[id];
  }

  startDrag(id: number, event: MouseEvent): void {
    if (!this.dragPreviewTemplate) {
      return;
    }
    this._dragStartCoords = { x: event.clientX, y: event.clientY };
  }

  private _createOverlay(): void {
    const templateRef = this.dragPreviewTemplate?.templateRef;

    if (!templateRef) {
      return;
    }

    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo({ x: 0, y: 0 })
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this._overlay.create({
      positionStrategy,
      hasBackdrop: false,
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
    });

    const portal = new TemplatePortal(templateRef, this._viewContainerRef);
    this.overlayRef.attach(portal);

    this.overlayRef.overlayElement.style.pointerEvents = 'none';
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (this._isDragging) {
      if (this.overlayRef) {
        const hostRect = this._element.nativeElement.getBoundingClientRect();

        // make sure the overlay does not leave the host element
        const x = Math.min(
          Math.max(event.clientX, hostRect.left),
          hostRect.right,
        );

        const y = Math.min(
          Math.max(event.clientY, hostRect.top),
          hostRect.bottom,
        );

        const positionStrategy = this.overlayRef.getConfig()
          .positionStrategy! as FlexibleConnectedPositionStrategy;
        positionStrategy.setOrigin({ x, y });
        this.overlayRef.updatePosition();
      }
    } else {
      // start dragging if the mouse moved more than 5px
      if (
        this._dragStartCoords &&
        Math.abs(event.clientX - this._dragStartCoords.x) > 5 &&
        Math.abs(event.clientY - this._dragStartCoords.y) > 5
      ) {
        clearAllTextSelection();
        this._isDragging = true;
        this._createOverlay();
      }
    }
  }

  private _findDropZone(event: MouseEvent): DropZoneDirective | null {
    return (
      Object.values(this._dropZoneItems).find((dropZone) => {
        const rect = dropZone.elementRef.nativeElement.getBoundingClientRect();
        return (
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom
        );
      }) ?? null
    );
  }

  @HostListener('click', ['$event'])
  private _onClick(event: Event): void {
    if (this._isDragging) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    setTimeout(() => {
      this._isDragging = false;
    });
    this._dragStartCoords = null;
    if (this.overlayRef) {
      event.stopPropagation();
      this.overlayRef.detach();
      this.overlayRef = null;
      const dropZone = this._findDropZone(event);
      if (dropZone) {
        dropZone.drop(this.dragData);
      }
    }
  }
}
