import { CustomFormField } from '../form-field';
import { Meta } from '@tremaze/shared/models';
import { CustomTextBoxFormFieldValue } from '../values/textbox-value';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

@staticImplements<Deserializable<CustomTextBoxFormField>>()
export class CustomTextBoxFormField implements CustomFormField<string> {
  readonly fieldType = 'TEXTBOX';

  constructor(
    readonly id: string,
    readonly meta: Meta,
    readonly name: string,
    readonly label: string,
    readonly required: boolean,
    readonly sort: number,
    readonly json_id: string,
    readonly enableLineBreaks?: boolean,
    readonly minLength?: number,
    readonly maxLength?: number,
    readonly filledOutValues?: CustomTextBoxFormFieldValue,
  ) {}

  static deserialize(data: any): CustomTextBoxFormField {
    return new CustomTextBoxFormField(
      data.id,
      Meta.deserialize(data),
      data.name?.trim(),
      data.label?.trim(),
      data.required ?? false,
      data.sort ?? 0,
      '' + data.json_id,
      data.enableLineBreaks ?? false,
      data.minLength == 0 ? null : data.minLength,
      data.maxLength == 0 ? null : data.maxLength,
      data.filledOutValues?.length
        ? CustomTextBoxFormFieldValue.deserialize(data.filledOutValues[0])
        : undefined,
    );
  }
}
