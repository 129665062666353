import {
  Component,
  EventEmitter,
  forwardRef,
  InjectionToken,
  Input,
  NgModule,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivilegeName } from '@tremaze/shared/permission/types';
import { RemoteCCDepartmentDataSource } from '@tremaze/shared/feature/department/data-access';
import {
  ChipsFilterButtonComponent,
  ChipsFilterService,
  SharedUiChipsFilterPopupModule,
} from '@tremaze/shared/ui/chips-filter-popup';
import { User } from '@tremaze/shared/feature/user/types';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PersonalConfigDepartment } from '@tremaze/personal-config';
import { Pagination } from '@tremaze/shared/models';
import { map } from 'rxjs';

export const DEPARTMENT_FILTER_BUTTON_SERVICE = new InjectionToken<
  ChipsFilterService<PersonalConfigDepartment>
>('DEPARTMENT_FILTER_BUTTON_SERVICE');

@Component({
  selector: 'tremaze-department-filter-button',
  template: `
    <tremaze-chips-filter-button
      (filterChange)="onFilterChange($event)"
      [displayWith]="displayWith"
      [loadItems]="loadItems"
      [initialValues]="initialDepartments"
      label="Abteilungen"
      iconName="lnr lnr-home"
      [keepServiceAfterDestruction]="keepServiceAfterDestruction"
    ></tremaze-chips-filter-button>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DepartmentFilterButtonComponent),
      multi: true,
    },
    {
      provide: ChipsFilterService,
      useFactory: (service?: ChipsFilterService<PersonalConfigDepartment>) =>
        service ?? undefined,
      deps: [[new Optional(), DEPARTMENT_FILTER_BUTTON_SERVICE]],
    },
  ],
})
export class DepartmentFilterButtonComponent implements ControlValueAccessor {
  @Input() departmentFilterPrivilege?: PrivilegeName[];
  @Output() filterChange = new EventEmitter<PersonalConfigDepartment[]>();
  @ViewChild(ChipsFilterButtonComponent, { static: true })
  private readonly _chipsFilterButtonComponent!: ChipsFilterButtonComponent<User>;

  @Input() keepServiceAfterDestruction = false;

  @Input() initialDepartments: PersonalConfigDepartment[] = [];

  constructor(private readonly _dataSource: RemoteCCDepartmentDataSource) {}

  resetFilter() {
    this._chipsFilterButtonComponent.resetFilter();
  }

  onFilterChange(departments: PersonalConfigDepartment[]) {
    this.filterChange.emit(departments);
    this.onChange?.(departments);
  }

  readonly displayWith = (institution: PersonalConfigDepartment): string =>
    institution.name ?? '';

  readonly loadItems = (page: number, pageSize: number, filterValue?: string) =>
    this._dataSource
      .getPaginated(
        {
          filter: {
            sort: 'name',
            sortDirection: 'asc',
            page,
            pageSize,
            filterValue,
            filterFields: ['NAME'],
          },
        },
        this.departmentFilterPrivilege,
      )
      .pipe(
        map((r) =>
          Pagination.deserialize(r, (d) => ({
            id: d.id,
            name: d.name,
          })),
        ),
      );

  private onChange = (_: any) => null;

  private onTouched = (_: any) => null;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._chipsFilterButtonComponent.setDisabledState(isDisabled);
  }

  writeValue(obj: any): void {
    this._chipsFilterButtonComponent.writeValue(obj);
  }
}

@NgModule({
  declarations: [DepartmentFilterButtonComponent],
  imports: [CommonModule, SharedUiChipsFilterPopupModule],
  exports: [DepartmentFilterButtonComponent],
})
export class SharedFeatureDepartmentFeatureFilterButtonModule {}
