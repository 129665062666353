import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  map,
  shareReplay,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DirStorage } from '@tremaze/shared/feature/file-storage/types';
import { FolderViewEventsService } from './folder-view-events.service';
import { FolderViewDisableRoutingDirective } from '../folder-view-disable-routing.directive';

type NavigationMode = 'path' | 'object';

@Injectable()
export class FolderViewNavigationService {
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);

  private readonly _eventsService = inject(FolderViewEventsService);

  private readonly _path$ = new Subject<string>();

  private readonly _initialNavigationMode: NavigationMode = inject(
    FolderViewDisableRoutingDirective,
    { optional: true },
  )
    ? 'object'
    : 'path';

  readonly navigationMode$ = new BehaviorSubject<NavigationMode>(
    this._initialNavigationMode,
  );

  readonly currentRelativePath$ = this.navigationMode$.pipe(
    switchMap((mode) => {
      if (mode === 'path') {
        return this._route.url.pipe(map((url) => url.join('/')));
      }
      return this._path$.pipe(startWith('/'));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  navigateToPath(path: string) {
    this._eventsService.searchValueChange$.next('');
    if (this._initialNavigationMode === 'object') {
      this._path$.next(path);
      return;
    }

    if (path === '/') {
      this._router.navigate(['.'], {
        relativeTo: this._route.parent,
      });
      return;
    }

    if (path.startsWith('/')) {
      path = path.slice(1);
    }
    this._router.navigate([path], {
      relativeTo: this._route.parent,
    });
  }

  navigateToDirectory(directory: DirStorage) {
    this.navigateToPath(directory.absolutePath);
  }
}
