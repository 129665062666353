import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimatedHeightComponent } from './animated-height.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AnimatedHeightComponent],
  exports: [AnimatedHeightComponent],
})
export class SharedUiAnimatedComponentsModule {}
