export function uuid(): string {
  let dt = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function generatePassword(
  length: number,
  characterPool = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz123456789',
): string {
  let result = '';
  const charactersLength = characterPool.length;
  for (let i = 0; i < length; i++) {
    result += characterPool.charAt(
      Math.floor(Math.random() * charactersLength),
    );
  }
  return result;
}
