import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[tremazeDataTableAction]',
})
export class DataTableActionDirective {
  constructor(public readonly template: TemplateRef<any>) {}

  @Input() public readonly label: string;
}
