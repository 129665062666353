import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { IconTextButtonTextDirective } from './icon-text-button-text.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[tremaze-icon-text-button]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon-text-button.component.html',
  styleUrl: './icon-text-button.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'icon-text-button',
  },
})
export class IconTextButtonComponent {}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[tremaze-icon-text-toggle-button]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon-text-button.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'icon-text-button',
    '[attr.aria-pressed]': 'active.toString()',
    '[class.icon-text-button--primary]': 'color === "primary"',
    '[class.icon-text-button--accent]': 'color === "accent"',
    '[class.icon-text-button--warn]': 'color === "warn"',
    role: 'button',
  },
})
export class IconTextToggleButtonComponent<T = unknown> {
  @ContentChild(IconTextButtonTextDirective)
  textDirective?: IconTextButtonTextDirective;

  @HostBinding('class.icon-text-button--has-text')
  get hasText() {
    return !!this.textDirective;
  }

  @Output() readonly toggled = new EventEmitter<void>();

  @Input() color: ThemePalette;

  private _active = false;

  @HostBinding('class.icon-text-button--active')
  @Input()
  get active() {
    return this._active;
  }

  set active(value: boolean) {
    this._active = coerceBooleanProperty(value);
  }

  @Input() value: T;

  private _disabled = false;

  @HostBinding('class.icon-text-button--disabled')
  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  @HostListener('click')
  onClick() {
    if (!this.disabled) {
      this.active = !this.active;
      this.toggled.emit();
    }
  }
}

@Component({
  selector: 'tremaze-icon-text-button-group',
  standalone: true,
  imports: [CommonModule],
  template: ` <ng-content></ng-content>`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'icon-text-button-group',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => IconTextButtonGroupComponent),
    },
  ],
})
export class IconTextButtonGroupComponent<T = unknown>
  implements ControlValueAccessor, AfterContentInit
{
  get value(): T[] {
    return this._value ?? [];
  }

  @Input()
  set value(value: T[]) {
    this._value = value;
  }

  private _value: T[];

  @Output() selectionChange = new EventEmitter<T[]>();

  @Input() multiple = false;

  @Input() canBeEmpty = true;

  @Input() color: ThemePalette;

  @Input() compareWith: (o1: T, o2: T) => boolean = (a, b) => a === b;

  @ContentChildren(IconTextToggleButtonComponent)
  buttons: QueryList<IconTextToggleButtonComponent<T>>;

  private _onChange: (value: unknown) => void;
  private _onTouched: () => void;

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  private _hasValue(value: T): boolean {
    return this.value.some((v) => this.compareWith(v, value));
  }

  writeValue(obj: T[]): void {
    this._value = obj;
    this.buttons?.forEach((button) => {
      button.active = this._hasValue(button.value);
    });
  }

  ngAfterContentInit(): void {
    this.buttons.forEach((button) => {
      button.active = this._hasValue(button.value);
      button.color = this.color;
      button.toggled.subscribe(() => {
        if (this.multiple) {
          if (button.active) {
            this._value = [...this._value, button.value];
          } else {
            this._value = this._value.filter((v) => v !== button.value);
          }
        } else {
          if (button.active) {
            this._value = button.active ? [button.value] : [];
          } else if (this.canBeEmpty) {
            this._value = [];
          } else {
            button.active = true;
          }
        }
        this.selectionChange.emit(this._value);
        this.writeValue(this._value);
        this._onChange(this._value);
        this._onTouched();
      });
    });
  }
}
