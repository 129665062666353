import { inject, Injectable } from '@angular/core';
import { BulkActionConfig } from './types';
import { MatDialog } from '@angular/material/dialog';
import { BulkActionDialogComponent } from './bulk-action-dialog.component';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BulkActionService {
  private readonly _dialog = inject(MatDialog);

  openDialog<T>(config: BulkActionConfig<T>): Observable<boolean> {
    return this._dialog
      .open(BulkActionDialogComponent, {
        data: config,
        disableClose: true,
        panelClass: 'relative-dialog',
      })
      .afterClosed()
      .pipe(map((r) => r === true));
  }
}
