import { Component, ViewEncapsulation } from '@angular/core';
import { EventTemplate } from '@tremaze/shared/feature/event/types';

@Component({
  selector: 'tremaze-event-template-select-dialog',
  template: `
    <span mat-dialog-title>Vorlage wählen</span>
    <div mat-dialog-content>
      <tremaze-event-template-data-table
        enableSelection
        tremazeDataTableDisableFilterPersistence
        hide-plan-with-row-action
        (selectionChange)="onSelectionChange($event)"
      ></tremaze-event-template-data-table>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Abbrechen</button>
      <button mat-raised-button [mat-dialog-close]="null" color="primary">
        Weiter ohne Vorlage
      </button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="!selectedTemplate"
        [mat-dialog-close]="selectedTemplate"
      >
        Weiter
      </button>
    </div>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.Emulated,
})
export class EventTemplateSelectDialogComponent {
  selectedTemplate: EventTemplate;

  onSelectionChange(ev: EventTemplate[]) {
    if (ev?.length) {
      this.selectedTemplate = ev[0];
    } else {
      this.selectedTemplate = null;
    }
  }
}
