import {
  booleanAttribute,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { map, Observable } from 'rxjs';
import { Specialization } from '@tremaze/shared/feature/specialization/types';
import { RemoteSpecializationCRUDDataSourceDefaultImpl } from '@tremaze/shared/feature/specialization/data-access';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ensureArray, ensureNotArray } from '@tremaze/shared/util-utilities';

@Component({
  selector: 'tremaze-specializations-select',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule],
  templateUrl: './specializations-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SpecializationsSelectComponent,
    },
  ],
  styles: `
    :host {
      display: block;
    }

    mat-form-field {
      width: 100%;
    }
  `,
})
export class SpecializationsSelectComponent extends DefaultValueAccessor {
  private _fetchSpecializationsFn: () => Observable<Specialization[]> = () =>
    inject(RemoteSpecializationCRUDDataSourceDefaultImpl)
      .getPaginated({
        filter: {
          page: 0,
          pageSize: 1000,
        },
      })
      .pipe(map((r) => r.content));

  @Input()
  set fetchSpecializationsFn(value: () => Observable<Specialization[]>) {
    this._fetchSpecializationsFn = value;
    this._specializations$ = this._fetchSpecializationsFn();
  }

  private _specializations$ = this._fetchSpecializationsFn();

  get specializations$(): Observable<Specialization[]> {
    return this._specializations$;
  }

  readonly idCompareFn = (a: { id?: string }, b: { id?: string }) =>
    a?.id === b?.id;

  private _value?: Specialization | Specialization[] | null;

  get value(): Specialization | Specialization[] | undefined | null {
    return this._value;
  }

  @Input()
  set value(value: Specialization | Specialization[] | undefined | null) {
    value = this._transformValue(value);
    this._value = value;
    this.onChange(value);
  }

  private _multiple = false;

  @Input({ transform: booleanAttribute })
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean | string | null | undefined | '') {
    this._multiple = coerceBooleanProperty(value);
  }

  @Input() appearance =
    inject(MAT_FORM_FIELD_DEFAULT_OPTIONS, { optional: true })?.appearance ??
    'outline';

  @Output() readonly selectionChange = new EventEmitter<
    Specialization | Specialization[]
  >();

  override writeValue(value: any) {
    value = this._transformValue(value);
    this._value = value;
    super.writeValue(value);
  }

  onSelectionChange(event: MatSelectChange) {
    this._value = this._transformValue(event.value);
    this.onChange(this._value);
    this.selectionChange.emit(this._value);
  }

  private _transformValue(
    value: Specialization | Specialization[] | undefined | null,
  ): Specialization | Specialization[] | undefined {
    if (this.multiple) {
      return ensureArray(value);
    }
    return ensureNotArray(value);
  }
}
