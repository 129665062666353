import { BaseDataSource } from '@tremaze/shared/util-http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { HttpClient } from '@angular/common/http';
import { User } from '@tremaze/shared/feature/user/types';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RemoteCCReferencePersonDataSource extends BaseDataSource<User> {
  protected controller = '/users';
  protected deserializer = User.deserialize;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  getAllForUser(userId: string): Observable<User[]> {
    return this.http
      .get<any[]>(`${this.controller}/${userId}/referencePersons`)
      .pipe(map((r) => r.map(this.deserializer).sort(User.sortByLastName)));
  }

  getAllReferenceClientsForUser(userId: string): Observable<User[]> {
    return this.http
      .get<any[]>(`${this.controller}/${userId}/referenceClients`)
      .pipe(map((r) => r.map(this.deserializer).sort(User.sortByLastName)));
  }

  getAllReferenceClientsForAuthenticatedUser(): Observable<User[]> {
    return this.http.get<any[]>(`${this.controller}/me/referenceClients`).pipe(
      map((r) => r.map(this.deserializer)),
      catchError(() => of([])),
    );
  }

  setAllForUser(
    userId: string,
    referencePersonIds: string[],
  ): Observable<boolean> {
    return this.http
      .post(`${this.controller}/${userId}/referencePersons`, null, {
        params: { referencePersonIds },
      })
      .pipe(mapTo(true));
  }

  addReferenceClientToUser(
    userId: string,
    referenceClientId: string,
  ): Observable<void> {
    return this.http
      .put(
        `${this.controller}/${userId}/addReferenceClient/${referenceClientId}`,
        null,
      )
      .pipe(
        map((r) => {
          if (!r) {
            throw new Error();
          }
        }),
      );
  }

  removeReferenceClientFromUser(
    userId: string,
    referenceClientId: string,
  ): Observable<void> {
    return this.http
      .delete(
        `${this.controller}/${userId}/deleteReferenceClient/${referenceClientId}`,
      )
      .pipe(map(() => undefined));
  }

  addReferenceEmployeeToUser(
    userId: string,
    referenceEmployeeId: string,
  ): Observable<void> {
    return this.addReferenceClientToUser(referenceEmployeeId, userId);
  }

  removeReferenceEmployeeFromUser(
    userId: string,
    referenceEmployeeId: string,
  ): Observable<void> {
    return this.removeReferenceClientFromUser(referenceEmployeeId, userId);
  }
}
