import {
  animate,
  animateChild,
  keyframes,
  query,
  sequence,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const animationTimings = '300ms ease-in-out';
export const staggerTiming = '100ms';

export const listItemEnterAnimation = [
  style({ opacity: 0, height: 0, transform: 'translateY(-10px)' }),
  animate(
    animationTimings,
    keyframes([
      style({
        offset: 0,
        height: 0,
        transform: 'translateY(-10px)',
        opacity: 0,
      }),
      style({
        offset: 0.1,
        height: '*',
        transform: 'none',
        opacity: 0,
      }),
      style({
        offset: 0.5,
        height: '*',
        transform: 'none',
        opacity: 0,
      }),
      style({
        offset: 1,
        height: '*',
        transform: 'none',
        opacity: 1,
      }),
    ]),
  ),
];

export const listItemLeaveAnimation = [
  style({ opacity: 1, height: '*', transform: 'none' }),
  animate(
    animationTimings,
    keyframes([
      style({
        offset: 0,
        height: '*',
        transform: 'none',
        opacity: 1,
      }),
      style({
        offset: 0.5,
        height: '*',
        transform: 'none',
        opacity: 0,
      }),
      style({
        offset: 0.9,
        height: '*',
        transform: 'none',
        opacity: 0,
      }),
      style({
        offset: 1,
        height: 0,
        transform: 'translateY(-10px)',
        opacity: 0,
      }),
    ]),
  ),
];

export const dynamicListAnimations = [
  trigger('tableAnimation', [
    transition('* => *', [
      query(
        ':enter',
        [
          stagger(staggerTiming, [
            sequence([
              query('@childAnimation', animateChild(), { optional: true }),
            ]),
          ]),
        ],
        { optional: true },
      ),
      query(
        ':leave',
        [
          stagger(staggerTiming, [
            sequence([
              query('@childAnimation', animateChild(), { optional: true }),
            ]),
          ]),
        ],
        { optional: true },
      ),
    ]),
  ]),
];

export const dynamicListItemAnimations = [
  trigger('childAnimation', [
    transition(':enter', listItemEnterAnimation),
    transition(':leave', listItemLeaveAnimation),
  ]),
];
