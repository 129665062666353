<span mat-dialog-title>Kriterium {{ isEdit ? 'bearbeiten' : 'anlegen' }}</span>
<div mat-dialog-content>
  <form class="form-grid" [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Beschreibung</mat-label>
      <input matInput formControlName="description" required maxlength="60" />
      <mat-hint>{{ descriptionControl.value?.length || 0 }} / 60</mat-hint>
      <mat-error *ngIf="descriptionControl.invalid"
        >Bitte gib eine Beschreibun an</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <mat-label>Typ</mat-label>
      <mat-select formControlName="criteriaType" required>
        <mat-option>Bitte wählen</mat-option>
        <mat-option value="CHECKBOX">Auswahl</mat-option>
        <mat-option value="TEXT_QUESTION">Text-Eingabe</mat-option>
      </mat-select>
      <mat-error *ngIf="criteriaTypeControl.invalid"
        >Bitte gib einen Typ an</mat-error
      >
    </mat-form-field>
    <div style="padding-bottom: 20px" *ngIf="!hideMandatoryCheckbox">
      <mat-checkbox formControlName="criteria">Ist Pflichtangabe</mat-checkbox>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Schließen</button>
  <button mat-raised-button color="accent" (click)="onClickSubmit()">
    {{ isEdit ? 'Speichern' : 'Anlegen' }}
  </button>
</div>
