import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectorComponent } from './selector.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, MatSelectModule, FormsModule],
  declarations: [SelectorComponent],
  exports: [SelectorComponent],
})
export class SharedUiSelectorModule {}
