import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperComponent } from './image-cropper.component';
import { ImageCropperService } from './image-cropper.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedUiHintModule } from '@tremaze/shared/ui/hint';

@NgModule({
  imports: [
    CommonModule,
    ImageCropperModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    SharedUiHintModule,
  ],
  declarations: [ImageCropperComponent],
  providers: [ImageCropperService],
})
export class SharedFeatureFileStorageFeatureImageCropperModule {}
