import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '@tremaze/shared/notification';

@Component({
  selector: 'tremaze-html-preview-dialog',
  templateUrl: './html-preview-dialog.component.html',
  styleUrl: './html-preview-dialog.component.scss',
})
export class HtmlPreviewDialogComponent implements OnInit {
  public previewUrl: string;
  @ViewChild('iframe') iframe?: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<HtmlPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private notificationService: NotificationService,
  ) {
    this.previewUrl = data.previewUrl;
    if (!this.previewUrl) {
      throw new Error('No previewUlr provided when opening the HTMLPreview.');
    }
  }

  loadingSubject$ = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject$.asObservable();

  ngOnInit() {
    this.loadHtmlContent();
  }

  loadHtmlContent() {
    this.loadingSubject$.next(true);
    this.http.get(this.previewUrl, { responseType: 'text' }).subscribe(
      (htmlContent) => {
        const iframeElement = this.iframe?.nativeElement;
        iframeElement.src = `data:text/html;charset=UTF-8,${encodeURIComponent(
          htmlContent.toString(),
        )}`;
        this.loadingSubject$.next(false);
      },
      (error) => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.notificationService.showNotification(
            'Fehler beim Laden des Berichts. Möglicherweise wurde kein HTML-Template hinterlegt.',
          );
        } else {
          this.notificationService.showNotification(
            'Fehler beim Laden des Berichts.',
          );
        }
        this.loadingSubject$.next(false);
      },
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
