import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { Observable } from 'rxjs';
import { FileSelectorConfig, FolderSelectorConfig } from './config';

export abstract class FileSelectorService {
  abstract selectFiles(
    config?: FileSelectorConfig,
  ): Observable<FileStorage[] | null>;

  abstract selectFolders(
    config?: FolderSelectorConfig,
  ): Observable<DirStorage[] | null>;
}
