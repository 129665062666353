import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { IconComponent } from '@tremaze/shared/ui/icon';

type DragItem = { type: 'folder' | 'file'; name: string; id: string };

@Component({
  selector: 'tremaze-folder-view-drag-preview',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './folder-view-drag-preview.component.html',
  styleUrl: './folder-view-drag-preview.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-drag-preview mat-elevation-z4',
  },
})
export class FolderViewDragPreviewComponent {
  @Input({ required: true }) folders?: DirStorage[];
  @Input({ required: true }) files?: FileStorage[];

  get dragItems(): DragItem[] {
    const x = [
      ...(this.folders ?? []).map(
        (folder) =>
          ({
            type: 'folder',
            name: folder.dirViewname,
            id: folder.id,
          }) as DragItem,
      ),
      ...(this.files ?? []).map(
        (file) =>
          ({
            type: 'file',
            name: file.fileViewname,
            id: file.id,
          }) as DragItem,
      ),
    ];

    // max return 10 items
    return x.slice(0, 10);
  }
}
