import {
  CustomFileFormField,
  CustomFormField,
  CustomFormFieldType,
  CustomMultiSelectFormField,
  CustomTextBoxFormField,
} from '../index';

export class CustomFormVersion {
  constructor(
    readonly fields: CustomFormField<unknown>[],
    readonly id: string,
    public form?: {
      id: string;
      name: string;
      institutions: { id: string; name: string }[];
      hasHtmlTemplate: boolean;
      hasCSVTemplate: boolean;
    },
  ) {}

  static deserialize(data: any): CustomFormVersion | undefined {
    if (!data) return undefined;
    const form = data.form ?? {};
    if (form) {
      form.name = form.name?.trim();
    }
    return new CustomFormVersion(
      data.fields.map(mapToFormField),
      data.id,
      data.form,
    );
  }
}

function mapToFormField(field: Record<string, any>): CustomFormField<unknown> {
  switch (field.fieldType as CustomFormFieldType) {
    case 'TEXTBOX':
      return CustomTextBoxFormField.deserialize(field);
      break;
    case 'MULTISELECT':
      return CustomMultiSelectFormField.deserialize(field);
      break;
    case 'FILE_UPLOAD':
      return CustomFileFormField.deserialize(field);
      break;
  }
}
