import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { DocumentEditorComponent } from '../document-editor.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { CustomToolbarItemModel } from '@syncfusion/ej2-angular-pdfviewer';
import { ToolbarItem } from '@syncfusion/ej2-angular-documenteditor';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FillableVariable } from '../fillable-variable';
import { MatCardModule } from '@angular/material/card';
import { AutosaveDisplayComponent } from './autosave-display.compontent';
import { TremazeDate } from '@tremaze/shared/util-date';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

@Component({
  selector: 'tremaze-document-editor-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DocumentEditorComponent,
    MatSidenavModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    AutosaveDisplayComponent,
  ],
  template: `
    <div style="display: flex; flex-direction: column; height: 100%">
      <div
        style="display: flex; flex-direction: row; height:calc(100% - 52px);"
      >
        <mat-drawer-container>
          <mat-drawer-content>
            <tremaze-document-editor
              #documentEditor
              (additionalToolbarItemClicked)="onToolbarClick($event)"
              (contentChange)="contentChange.emit()"
              [additionalToolbarItems]="toolbarItems"
              [canWrite]="canWrite"
              [class]="fillable ? 'fillable-open' : 'fillable-closed'"
              [documentName]="name"
              [documentUrl]="url"
              [fileId]="fileStorage?.id"
              (requestClose)="onRequestClose()"
              style=" width: 100%; display:block "
            >
            </tremaze-document-editor>
          </mat-drawer-content>
          @if (fillable) {
            <mat-drawer #sidenav position="end" mode="side" opened="false">
              <mat-card>
                <mat-card-header>
                  <h3 mat-card-title>Platzhalter</h3>
                  <mat-card-subtitle
                    >Füge die Platzhalter in das Dokument ein, um sie ersetzen
                    zu können
                  </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <table class="default-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Platzhalter</th>
                        <th>Wert</th>
                      </tr>
                    </thead>
                    <tbody>
                      @for (item of fillableVariables; track item.name) {
                        <tr>
                          <th>{{ item.displayName }}</th>
                          <td>{{ item.name }}</td>
                          <td>{{ item.value }}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </mat-card-content>
                <mat-card-actions align="end">
                  <button mat-button (click)="replaceVariables()">
                    Platzhalter ausfüllen
                  </button>
                </mat-card-actions>
              </mat-card>
            </mat-drawer>
          }
        </mat-drawer-container>
      </div>

      <div style="flex-grow: 1"></div>

      <div align="end" mat-dialog-actions style="height: 52px; flex-shrink: 0">
        @if (canWrite) {
          <tremaze-autosave-display
            [lastSave]="lastModified"
            [isSaving]="isSaving"
          ></tremaze-autosave-display>
        }
        <span class="spacer"></span>
        <button (click)="documentEditor.print()" mat-button>Drucken</button>
        @if (canWrite && documentEditor.editorEnabled) {
          <button (click)="onClickedSave()" mat-button>Speichern</button>
        }
        <button mat-button mat-dialog-close>Schließen</button>
      </div>
    </div>
  `,
  styleUrl: './document-editor-dialog.component.scss',
})
export class DocumentEditorDialogComponent implements OnInit {
  readonly url = inject(MAT_DIALOG_DATA).documentUrl;
  readonly name = inject(MAT_DIALOG_DATA).documentName;
  readonly fillable: boolean = inject(MAT_DIALOG_DATA).fillable;
  readonly fillableVariables: FillableVariable[] =
    inject(MAT_DIALOG_DATA).fillableVariables;
  readonly canWrite: boolean = inject(MAT_DIALOG_DATA).canWrite;
  lastModified?: TremazeDate = inject(MAT_DIALOG_DATA).lastModified;
  readonly fileStorage?: FileStorage = inject(MAT_DIALOG_DATA).fileStorage;
  isSaving = false;
  private readonly _cdRef = inject(ChangeDetectorRef);
  readonly ref = inject(MatDialogRef);

  datasource = new MatTableDataSource<FillableVariable>(this.fillableVariables);
  displayedColumns = ['displayName', 'name', 'value'];

  @ViewChild(DocumentEditorComponent, { static: true })
  documentEditor?: DocumentEditorComponent;

  @ViewChild('sidenav') sidenav: MatDrawer | undefined;

  openFillableItem = {
    prefixIcon: 'e-form-field',
    tooltipText: 'Platzhalter',
    text: 'Platzhalter',
    id: 'openFillableItem',
  };
  toolbarItems: (CustomToolbarItemModel | ToolbarItem)[] = [
    'New',
    'Open',
    'Separator',
    'Undo',
    'Redo',
    'Separator',
    'Image',
    'Table',
    'Hyperlink',
    'Bookmark',
    'TableOfContents',
    'Separator',
    'Header',
    'Footer',
    'PageSetup',
    'PageNumber',
    'Break',
    'Separator',
    'Find',
    'Separator',
    'Comments',
    'TrackChanges',
    'Separator',
    'LocalClipboard',
    'RestrictEditing',
    'Separator',
    'FormFields',
    'UpdateFields',
  ];

  onToolbarClick(id: string) {
    if (id === 'openFillableItem') {
      this.sidenav?.toggle();
    }
  }

  @Output() readonly save = new EventEmitter<Blob>();
  @Output() readonly contentChange = new EventEmitter<void>();

  getCurrentValue(): Promise<Blob | undefined> | undefined {
    return this.documentEditor?.save();
  }

  async onClickedSave() {
    const blob = await this.documentEditor?.save();
    if (blob) {
      this.save.emit(blob);
    }
  }

  async replaceVariables() {
    const _docEditor = this.documentEditor?.documentEditor?.documentEditor;
    for (const variable of this.fillableVariables) {
      _docEditor?.search.findAll(variable.name, 'CaseSensitive');
      _docEditor?.search.searchResults.replaceAll(
        !variable.value || variable.value.length == 0 ? '-' : variable.value,
      );
    }
  }

  setAutosave(isSaving: boolean) {
    this.isSaving = isSaving;
    if (!isSaving) {
      this.lastModified = TremazeDate.getNow();
    }
    this._cdRef.detectChanges();
  }

  ngOnInit() {
    if (this.fillable) {
      this.toolbarItems = [this.openFillableItem, ...this.toolbarItems];
    }
  }

  onRequestClose() {
    this.ref.close();
  }
}
