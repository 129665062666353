import { CustomFormFieldValue } from './value';
import { Meta } from '@tremaze/shared/models';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

export interface MultiSelectOptionValue<T> {
  readonly id: string;
  readonly value: T;
  readonly label: string;
  readonly sort: number;
}

@staticImplements<
  Deserializable<
    CustomMultiSelectFormFieldValue<MultiSelectOptionValue<unknown>>
  >
>()
export class CustomMultiSelectFormFieldValue<T>
  implements CustomFormFieldValue<MultiSelectOptionValue<T>>
{
  readonly fieldType = 'MULTISELECT';

  constructor(
    readonly id: string,
    readonly meta: Meta,
    readonly value: MultiSelectOptionValue<T>,
  ) {}

  static deserialize<T>(data: any): CustomMultiSelectFormFieldValue<T> {
    return new CustomMultiSelectFormFieldValue<T>(
      data.id,
      Meta.deserialize(data),
      data.value,
    );
  }

  toDisplayString(): string {
    return this.value.label;
  }
}
