import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Linearicon } from '@tremaze/shared/ui';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatIconButton } from '@angular/material/button';
import { SharedFileStorageUiSingleFileDisplayModule } from '@tremaze/shared/feature/file-storage/ui/single-file-display';
import { MatMenuModule } from '@angular/material/menu';
import animations from '../../animations';
import { Observable } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { FolderViewItemNameInputComponent } from '../../item-name-input/folder-view-item-name-input.component';
import { FolderViewFileContextmenuComponent } from '../../file-contextmenu/folder-view-file-contextmenu.component';

@Component({
  selector:
    'tremaze-folder-view-grid-layout-file-item, figure[tremaze-folder-view-grid-layout-file-item]',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    MatIconButton,
    SharedFileStorageUiSingleFileDisplayModule,
    MatMenuModule,
    ReactiveFormsModule,
    FolderViewItemNameInputComponent,
    FolderViewFileContextmenuComponent,
  ],
  templateUrl: './folder-view-grid-layout-file-item.component.html',
  styleUrl: './folder-view-grid-layout-file-item.component.scss',
  animations: [animations.itemAnimation],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-grid-layout-file-item folder-view-grid-layout-item',
    '[@item]': '',
  },
})
export class FolderViewGridLayoutFileItemComponent {
  @Input({ required: true }) file?: FileStorage;

  @HostBinding('class.folder-view-grid-layout-item--Selected')
  @Input()
  selected = false;

  @HostBinding('class.folder-view-grid-layout-item--Disabled')
  @HostBinding('class.folder-view-grid-layout-file-item--Disabled')
  @Input()
  disabled = false;

  @Input() canWrite$?: Observable<boolean>;
  @Input() canDelete$?: Observable<boolean>;

  @Output() readonly showFilePreview = new EventEmitter<void>();
  @Output() readonly duplicateFile = new EventEmitter<void>();
  @Output() readonly deleteFile = new EventEmitter<void>();
  @Output() readonly downloadFile = new EventEmitter<void>();
  @Output() readonly copyFileLink = new EventEmitter<void>();
  @Output() readonly submitFileName = new EventEmitter<string>();
  @Output() readonly navigateToPath = new EventEmitter<void>();

  private _isEditing = false;

  get isEditing(): boolean {
    return this._isEditing;
  }

  get icon(): Linearicon {
    switch (this.file?.type) {
      case 'IMAGE':
        return 'lnr-picture';
      case 'PDF':
        return 'lnr-document2';
      case 'DOCUMENT':
        return 'lnr-document';
      case 'VIDEO':
        return 'lnr-film-play';
      case 'OTHER':
      default:
        return 'lnr-file-empty';
    }
  }

  get name(): string {
    return this.file?.fileViewname ?? '';
  }

  @HostListener('keyup.escape')
  onCancel() {
    if (this.isEditing) {
      this._isEditing = false;
    }
  }

  onSubmitText(value: string) {
    this.submitFileName.emit(value);
    this._isEditing = false;
  }

  onClickEditName() {
    this._isEditing = true;
  }
}
