import { Inject, Injectable, Optional } from '@angular/core';
import { EventPresetSelectionModule } from './event-preset-selection.module';
import { Observable } from 'rxjs';
import { EventPreset } from './types';
import { EventPresetSelectionPopupComponent } from './event-preset-selection-popup.component';
import { MatDialog } from '@angular/material/dialog';
import {
  EVENT_PRESET_SELECTION_CONFIG,
  EventPresetSelectionConfig,
} from './config';

@Injectable({
  providedIn: EventPresetSelectionModule,
})
export class EventPresetSelectionService {
  constructor(
    private readonly _dialog: MatDialog,
    @Optional()
    @Inject(EVENT_PRESET_SELECTION_CONFIG)
    private readonly _config?: EventPresetSelectionConfig,
  ) {}

  selectPreset(): Observable<EventPreset | undefined> {
    return this._dialog
      .open(EventPresetSelectionPopupComponent, {
        maxWidth: '900px',
        data: this._config,
      })
      .afterClosed();
  }
}
