import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsFilterButtonComponent } from './chips-filter-button/chips-filter-button.component';
import { ChipsFilterDialogComponent } from './chips-filter-dialog/chips-filter-dialog.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedUiAnimatedComponentsModule } from '@tremaze/shared/ui/animated-components';
import { SharedFileStorageUiCircleAvatarModule } from '@tremaze/shared/feature/file-storage/ui/circle-avatar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ChipsFilterButtonFilterDirective } from './chips-filter-button-filter.directive';

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    SharedUiAnimatedComponentsModule,
    SharedFileStorageUiCircleAvatarModule,
    MatProgressBarModule,
    MatIconModule,
    MatDividerModule,
  ],
  declarations: [
    ChipsFilterButtonComponent,
    ChipsFilterDialogComponent,
    ChipsFilterButtonFilterDirective,
  ],
  exports: [ChipsFilterButtonComponent, ChipsFilterButtonFilterDirective],
})
export class SharedUiChipsFilterPopupModule {}
