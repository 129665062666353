import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Meta } from '@tremaze/shared/models';

export type FileStorageCompression = 'SMALL' | 'MEDIUM' | 'LARGE' | 'ORIGINAL';

export type FILE_UPLOAD_TYPE = 'AVATAR' | 'LOGO' | 'CHAT';

export interface DirFilePermission {
  readonly id?: string;
  // TODO remove instid
  readonly instId?: string;
  readonly perms?: number;
  readonly userId?: string;
}

export type FileType = 'IMAGE' | 'PDF' | 'DOCUMENT' | 'VIDEO' | 'OTHER';

@staticImplements<Deserializable<FileStorage>>()
export class FileStorage {
  constructor(
    readonly id: string,
    readonly meta: Meta,
    public fileViewname: string,
    public filename: string,
    public fileSize: number,
    public height: number,
    public width: number,
    public fileType: string,
    public fileUploadType?: FILE_UPLOAD_TYPE,
    public altText?: string,
    public absolutePath?: string,
  ) {}

  get type(): FileType {
    return this.fileType.includes('image')
      ? 'IMAGE'
      : this.fileType.includes('pdf')
        ? 'PDF'
        : this.fileType.includes('video')
          ? 'VIDEO'
          : this.fileType.includes('msword') ||
              this.fileType.includes(
                'vnd.openxmlformats-officedocument.wordprocessingml.document',
              )
            ? 'DOCUMENT'
            : 'OTHER';
  }

  static deserialize(data: any): FileStorage {
    return !data
      ? null
      : new FileStorage(
          data.id,
          Meta.deserialize(data),
          data.fileViewname ?? data.fileViewName,
          data.filename ?? data.fileName,
          data.fileSize,
          data.height,
          data.width,
          data.fileType ?? FileStorage.recognizeFileType(data.extension),
          data.fileUploadType,
          data.altText,
        );
  }

  /* Temporary Workaround if "fileType" in deserialize method is not defined.
   *  Uses extension property to define and return fileType.
   *  Should be solved in the backend probably.
   */
  static recognizeFileType(extension) {
    if (!extension) {
      return undefined;
    }
    if (extension.includes('image')) {
      return 'image';
    } else if (extension.includes('video')) {
      return 'video';
    } else if (extension.includes('pdf')) {
      return 'pdf';
    }
  }

  public static sortCallback(a: FileStorage, b: FileStorage): 1 | -1 | 0 {
    return a.fileViewname.toLowerCase() < b.fileViewname.toLowerCase()
      ? -1
      : a.fileViewname.toLowerCase() > b.fileViewname.toLowerCase()
        ? 1
        : 0;
  }
}

@staticImplements<Deserializable<DirStorage>>()
export class DirStorage {
  constructor(
    readonly id: string,
    readonly meta: Meta,
    public dirname: string,
    public dirViewname: string,
    public absolutePath: string,
    public absoluteViewPath: string,
    public subDirs: DirStorage[],
    public owner: { id: string },
    public files: FileStorage[],
    public institution?: { id: string; name: string },
    public tenantDir?: boolean,
  ) {}

  static deserialize(data: any): DirStorage {
    return !data
      ? null
      : new DirStorage(
          data.id,
          Meta.deserialize(data),
          data.dirname,
          data.dirViewname,
          data.absolutePath,
          data.absoluteViewPath,
          data.subDirs ? data.subDirs.map(DirStorage.deserialize) : [],
          data.owner,
          data.files?.map(FileStorage.deserialize),
          data.institution,
          data.tenantDir ?? false,
        );
  }

  static sortCallback(a: DirStorage, b: DirStorage): number {
    return a.dirViewname
      .toLowerCase()
      .localeCompare(b.dirViewname.toLowerCase());
  }

  get canBeTinkeredWith(): boolean {
    return !this.isSystemDir;
  }

  get isSystemDir(): boolean {
    return this.tenantDir || !!this.institution;
  }
}

export type FileStorageEntityType =
  | 'EVENT'
  | 'INFORMATION'
  | 'TENANT'
  | 'OTHER';

export interface FileStorageEntityMeta {
  dirName?: string;
  instId?: string;
  uploadInUserDir?: boolean;
}

export type FileStorageEntityFileTypes = 'IMAGE' | 'PDF';
