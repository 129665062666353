import {
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSourceImpl,
  DefaultREADDataSourceWithPaginationImpl,
  SortDirection,
} from '@tremaze/shared/util-http';
import { ExportDataType } from '@tremaze/shared/ui/export-data-type-selector/types';
import { Failure } from '@tremaze/shared/util-error';
import { ConfirmationPayload } from '@tremaze/shared/feature/confirmation/types';
import { TzPermissionRequest } from '@tremaze/shared/permission/types';
import { Observable } from 'rxjs';
import { IdObject } from '@tremaze/shared/util/id-object';

export interface SortedFilteredTableDetailsResponse {
  action: 'EDIT' | 'DELETE' | 'RELOAD';
}

export const GLOBAL_PAGINATION_CONFIG = 'GLOBAL_PAGINATION_CONFIG';

export interface GlobalPaginationConfig {
  pageSizeSteps?: number[];
  initialPageSize: number;
}

export interface SortedFilteredTableConfig<T extends IdObject> {
  displayedColumns: SortedFilteredTableColumnDef[];
  dataSource?:
    | DefaultREADDataSourceWithPaginationImpl<T>
    | DefaultCRUDDataSourceImpl<T>;
  paginationRequestOptions?: DataSourceMethodsPaginatedOptions;
  filterFields?: any[];
  getSupportedExportDataTypes?: () => Observable<ExportDataType[]>;
  exportData?: (
    exportDataType: ExportDataType,
  ) => Observable<Failure | ArrayBuffer>;
  hideDefaultActions?: boolean;
  hideDefaultDetailsButton?: boolean;
  hideDefaultEditActionButton?: boolean;
  hideDefaultDeleteActionButton?: boolean;
  hideDefaultCreateActionButton?: boolean;
  hideDefaultExportActionButton?: boolean;
  nameBuilder?: (v: T) => string;
  addButtonLabel?: string;
  filterInputLabel?: string;
  filterInputPlaceholder?: string;
  createSuccessMessage?: string;
  editSuccessMessage?: string;
  deleteSuccessMessage?: string;
  deleteConfirmationDialogConfigOverride?: ConfirmationPayload;
  deleteButtonTooltipOverride?: string;
  editPermissions?: (row?: T) => TzPermissionRequest;
  deletePermissions?: (row?: T) => TzPermissionRequest;
  detailsPermissions?: (row?: T) => TzPermissionRequest;
  createPermissions?: TzPermissionRequest;
  exportPermissions?: TzPermissionRequest;
  exportFileName?: ExportFileNameType;
  initialActiveSortColumnName?: string;
  initialSortDirection?: SortDirection;
  disableGetFreshItemBeforeEditDetails?: boolean;
  icons?: SortedFilteredTableConfigIcons;
}

interface SortedFilteredTableConfigIcons {
  deleteButton?: string;
  editButton?: string;
  createFAB?: string;
  createButton?: string;
  exportButton?: string;
  detailsButton?: string;
}

type ExportFileNameType = string | (() => string);

export interface SortedFilteredTableColumnDef {
  sortable?: boolean;
  displayName?: string;
  name: string;
  hidden?: boolean;
  permissions?: TzPermissionRequest;
  sortStart?: SortDirection;
}

export interface SortedFilteredTableDataManipulationServiceResponse<T> {
  item?: T;
  skipHttpAction?: boolean;
  skipReload?: boolean;
}
