import { Injectable } from '@angular/core';
import {
  DataSourceMethodsCreateOptions,
  DefaultCRUDDataSourceImpl,
  DefaultDataSourceMethods,
} from '@tremaze/shared/util-http';
import { Specialization } from '@tremaze/shared/feature/specialization/types';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { map, Observable } from 'rxjs';

interface CreateDTO {
  name: string;
  instId?: string;
  description?: string;
}

type EditDTO = CreateDTO & {
  id: string;
};

@Injectable({ providedIn: 'root' })
export class RemoteSpecializationCRUDDataSourceDefaultImpl extends DefaultCRUDDataSourceImpl<Specialization> {
  protected controller = 'specializations';

  protected deserializer = Specialization.deserialize;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  create(
    i: Specialization,
    options?: DataSourceMethodsCreateOptions<Specialization> & {
      institutionId?: string;
    },
  ): Observable<Specialization> {
    const dto: CreateDTO = {
      name: i.name,
      description: i.description,
    };
    if (options?.institutionId) {
      dto.instId = options.institutionId;
    }
    return super.create(dto as any, options);
  }

  edit(
    i: Specialization,
    options?: DataSourceMethodsCreateOptions<Specialization>,
  ): Observable<Specialization> {
    const dto: EditDTO = {
      id: i.id,
      name: i.name,
      instId: i.institution?.id,
      description: i.description,
    };
    return super.edit(dto as any, options);
  }

  addSpecializationToUser(
    userId: string,
    specializationId: string,
  ): Observable<void> {
    return this.http.post<void>(`users/${userId}/specializations`, null, {
      params: {
        specializationIds: [specializationId],
      },
    });
  }

  removeSpecializationFromUser(
    userId: string,
    specializationId: string,
  ): Observable<void> {
    return this.http.delete<void>(`users/${userId}/specializations`, {
      params: {
        specializationIds: [specializationId],
      },
    });
  }

  getSpecializationsForUserById(userId: string): Observable<Specialization[]> {
    return DefaultDataSourceMethods.getPaginated(
      this.http,
      this.controller,
      this.deserializer,
      {
        q: {
          userIds: [userId],
        },
      },
    ).pipe(map((r) => r.content));
  }
}
