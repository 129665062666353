import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomFormEditService } from '../edit.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { NotificationService } from '@tremaze/shared/notification';

@Component({
  selector: 'tremaze-report-template-dialog',
  templateUrl: './report-template-dialog.component.html',
  styleUrl: './report-template-dialog.component.scss',
})
export class ReportTemplateDialogComponent implements OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<ReportTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formEditService: CustomFormEditService,
    private notificationService: NotificationService,
  ) {
    this.subscription = formEditService
      .getPreviewHTMLofForm(this.data.id)
      .subscribe((html) => {
        this.htmlText = html;
      });
  }

  public htmlText = '';
  private subscription?: Subscription;

  async save() {
    console.log('Save', this.htmlText);
    try {
      await firstValueFrom(
        this.formEditService.setPreviewHTMLonForm(this.data.id, this.htmlText),
      );
      this.notificationService.showNotification(
        'Vorschau erfolgreich gespeichert',
      );
      this.closeDialog();
    } catch (e) {
      this.notificationService.showNotification(
        'Fehler beim Speichern der Vorschau',
      );
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
