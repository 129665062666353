import { TzPermissionRequest } from '@tremaze/shared/permission/types';
import { TremazeEvent } from '@tremaze/shared/feature/event/types';
import { ReferencePersonService } from '@tremaze/shared/feature/reference-person';
import { map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';

export abstract class TremazeEventPrivilegeChecks {
  static get createPrivilegeRequest(): TzPermissionRequest {
    return {
      gPerms: 'EVENT_WRITE',
      iPerms: {
        perms: [
          ['EVENT_WRITE'],
          ['EVENT_WRITE_DEPARTMENT'],
          ['EVENT_WRITE_REFERENCE_CLIENT'],
          ['EVENT_CLIENT_WRITE'],
          ['EVENT_EMPLOYEE_WRITE'],
        ],
        instIds: 'ANY',
      },
    };
  }

  static globalWritePrivilegeRequest(): TzPermissionRequest {
    return {
      gPerms: 'EVENT_WRITE',
    };
  }

  static createUserCreateRequest(): TzPermissionRequest {
    return {
      gPerms: 'EVENT_WRITE',
      iPerms: {
        perms: [
          ['EVENT_WRITE_REFERENCE_CLIENT'],
          ['EVENT_CLIENT_WRITE'],
          ['EVENT_EMPLOYEE_WRITE'],
        ],
        instIds: 'ANY',
      },
    };
  }

  static createDepartmentCreateRequest(): TzPermissionRequest {
    return {
      gPerms: 'EVENT_WRITE',
      iPerms: {
        perms: 'EVENT_WRITE_DEPARTMENT',
        instIds: 'ANY',
      },
    };
  }

  static createInstitutionCreateRequest(): TzPermissionRequest {
    return {
      gPerms: 'EVENT_WRITE',
      iPerms: { instIds: 'ANY' },
    };
  }

  static getEditPrivilegeRequest$(
    {
      userIds,
      instIds,
      meta,
      institutionIdsThatArePartOfDepartmentsAndContextInstitutions,
      institutionIdsThatArePartOfClientsAndContextInstitutions,
      institutionIdsThatArePartOfEmployeesAndContextInstitutions,
      contextInstitution,
    }: Pick<
      TremazeEvent,
      | 'userIds'
      | 'instIds'
      | 'meta'
      | 'institutionIdsThatArePartOfDepartmentsAndContextInstitutions'
      | 'institutionIdsThatArePartOfClientsAndContextInstitutions'
      | 'institutionIdsThatArePartOfEmployeesAndContextInstitutions'
      | 'contextInstitution'
    >,
    authService: AuthV2Service,
    referencePersonService: ReferencePersonService,
  ): Observable<TzPermissionRequest> {
    return authService.authenticatedUser$.pipe(
      take(1),
      switchMap((r) => {
        if (
          r &&
          (meta?.insertUser === r.userId ||
            (userIds.length === 1 && userIds[0] === r.userId))
        ) {
          return of(true);
        }
        return referencePersonService.getUniqueInstitutionIdList(userIds).pipe(
          map((referencePersonInstIds) => {
            return {
              gPerms: 'EVENT_WRITE',
              iPerms: [
                { perms: 'EVENT_WRITE', instIds: contextInstitution?.id },
                {
                  perms: 'EVENT_WRITE_DEPARTMENT',
                  instIds:
                    institutionIdsThatArePartOfDepartmentsAndContextInstitutions,
                },
                {
                  perms: 'EVENT_WRITE_REFERENCE_CLIENT',
                  instIds:
                    contextInstitution &&
                    referencePersonInstIds?.includes(contextInstitution?.id)
                      ? [contextInstitution?.id]
                      : [],
                },
                {
                  instIds:
                    institutionIdsThatArePartOfClientsAndContextInstitutions,
                  perms: 'EVENT_CLIENT_WRITE',
                },
                {
                  instIds:
                    institutionIdsThatArePartOfEmployeesAndContextInstitutions,
                  perms: 'EVENT_EMPLOYEE_WRITE',
                },
              ],
            } as TzPermissionRequest;
          }),
        );
      }),
    );
  }

  static getDeletePrivilegeRequest$(
    {
      userIds,
      institutionIdsThatArePartOfDepartmentsAndContextInstitutions,
      institutionIdsThatArePartOfClientsAndContextInstitutions,
      institutionIdsThatArePartOfEmployeesAndContextInstitutions,
      meta,
      contextInstitution,
    }: TremazeEvent,
    authService: AuthV2Service,
    referencePersonService: ReferencePersonService,
  ): Observable<TzPermissionRequest> {
    return authService.authenticatedUser$.pipe(
      take(1),
      switchMap((r) =>
        r &&
        (meta?.insertUser === r.userId ||
          (userIds.length === 1 && userIds[0] === r.userId))
          ? of(true)
          : referencePersonService.getUniqueInstitutionIdList(userIds).pipe(
              map((referencePersonInstIds) => {
                return {
                  gPerms: 'EVENT_DELETE',
                  iPerms: [
                    { perms: 'EVENT_DELETE', instIds: contextInstitution?.id },
                    {
                      perms: 'EVENT_DELETE_DEPARTMENT',
                      instIds: [
                        institutionIdsThatArePartOfDepartmentsAndContextInstitutions,
                      ],
                    },
                    {
                      perms: 'EVENT_DELETE_REFERENCE_CLIENT',
                      instIds:
                        contextInstitution &&
                        referencePersonInstIds?.includes(contextInstitution?.id)
                          ? [contextInstitution?.id]
                          : [],
                    },
                    {
                      instIds:
                        institutionIdsThatArePartOfClientsAndContextInstitutions,
                      perms: 'EVENT_CLIENT_WRITE',
                    },
                    {
                      instIds:
                        institutionIdsThatArePartOfEmployeesAndContextInstitutions,
                      perms: 'EVENT_EMPLOYEE_WRITE',
                    },
                  ],
                } as TzPermissionRequest;
              }),
            ),
      ),
    );
  }

  static getPublishPermissionRequest$(
    {
      userIds,
      meta,
      institutionIdsThatArePartOfDepartmentsAndContextInstitutions,
      contextInstitution,
    }: TremazeEvent,
    authService: AuthV2Service,
    referencePersonService: ReferencePersonService,
  ): Observable<TzPermissionRequest> {
    return authService.authenticatedUser$.pipe(
      take(1),
      switchMap((r) =>
        r &&
        (meta?.insertUser === r.userId ||
          (userIds.length === 1 && userIds[0] === r.userId))
          ? of(true)
          : referencePersonService.getUniqueInstitutionIdList(userIds).pipe(
              map((referencePersonInstIds) => {
                return {
                  gPerms: 'EVENT_PUBLISH',
                  iPerms: [
                    { perms: 'EVENT_PUBLISH', instIds: contextInstitution?.id },
                    {
                      perms: 'EVENT_PUBLISH_DEPARTMENT',
                      instIds:
                        institutionIdsThatArePartOfDepartmentsAndContextInstitutions,
                    },
                    {
                      perms: 'EVENT_PUBLISH_REFERENCE_CLIENT',
                      instIds:
                        contextInstitution &&
                        referencePersonInstIds?.includes(contextInstitution?.id)
                          ? [contextInstitution?.id]
                          : [],
                    },
                  ],
                } as TzPermissionRequest;
              }),
            ),
      ),
    );
  }
}
