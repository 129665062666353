import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'tremaze-hint',
  template: `
    <span class="lnr {{ iconClass }}" [style.color]="color"></span>
    <p [style.border-color]="color">
      <ng-content></ng-content>
    </p>
    <button
      (click)="onClickHideButton()"
      mat-icon-button
      color="primary"
      *ngIf="dismissable; else spacer"
    >
      <span class="lnr lnr-cross"></span>
    </button>
    <ng-template #spacer><span></span></ng-template>
    <div
      class="hint__bg"
      *ngIf="colorizeBackground"
      [style]="{ backgroundColor: color }"
    ></div>
  `,
  styles: [
    `
      :host {
        display: inline-flex;
        align-items: center;
        border: 2px solid;
        margin: 15px 0;
        border-radius: 25px;
        transition: all 0.5s ease;
        overflow: hidden;
        overflow-x: visible;
        box-sizing: border-box;
        justify-content: space-between;
        position: relative;

        > p {
          border-left: 1px solid;
          padding-left: 16px;
          margin: 0;
        }
      }

      button {
        margin-left: 5px;
      }

      :host > .lnr {
        font-size: 1.75rem;
        margin-right: 0.75rem;
      }

      :host > *:not(.hint__bg) {
        z-index: 1;
      }

      .hint__bg {
        opacity: 0.15;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class HintComponent {
  private _dense = false;

  @Input()
  get dense(): boolean {
    return this._dense;
  }

  set dense(value: boolean) {
    this._dense = coerceBooleanProperty(value);
  }

  hidden = false;
  @Input() foregroundColor: string;
  @Input() mode: MODES;

  @Input() icon: string;

  get iconClass() {
    if (this.hideIcon) {
      return '';
    }
    if (this.icon) {
      return this.icon;
    }
    switch (this.mode) {
      case 'WARN':
        return 'lnr-notification';
      case 'SUCCESS':
        return 'lnr-checkmark-circle';
      case 'ERROR':
        return 'lnr-cross-circle';
      default:
        return 'lnr-question-circle';
    }
  }

  private _colorPalette: ThemePalette = 'primary';

  get colorPalette(): ThemePalette {
    return this._colorPalette;
  }

  @Input()
  set colorPalette(value: ThemePalette) {
    this._colorPalette = value;
  }

  private _color: string;

  get color(): string {
    if (this._color) {
      return this._color;
    }
    switch (this.mode) {
      case 'DEFAULT':
        return '#000';
      case 'SUCCESS':
        return 'green';
      case 'ERROR':
        return 'red';
      case 'WARN':
        return 'orange';
    }
  }

  /**
   * @deprecated Use colorPalette instead
   * @param value
   */
  @Input()
  set color(value: string) {
    this._color = value;
  }

  private _colorizeBackground = false;

  @Input()
  get colorizeBackground(): boolean {
    return (
      this._colorizeBackground === true || COLORED_MODES.includes(this.mode)
    );
  }

  set colorizeBackground(value: boolean) {
    this._colorizeBackground = coerceBooleanProperty(value);
  }

  private _dismissable = false;

  @Input()
  get dismissable(): boolean {
    return this._dismissable;
  }

  set dismissable(value: boolean) {
    this._dismissable = coerceBooleanProperty(value);
  }

  @HostBinding('style')
  get parentStyle() {
    return {
      borderColor: this.color,
      color: this.foregroundColor,
      display: this.hidden ? 'none' : 'inline-flex',
      padding: this._dense ? '5px 15px' : '15px',
    };
  }

  @HostBinding('class')
  get parentClass() {
    return `hint hint--${this.colorPalette}`;
  }

  private _hideIcon = false;

  @Input()
  get hideIcon(): boolean {
    return this._hideIcon;
  }

  set hideIcon(value: boolean) {
    this._hideIcon = coerceBooleanProperty(value);
  }

  onClickHideButton() {
    this.hidden = true;
  }
}

const COLORED_MODES: MODES[] = ['SUCCESS', 'ERROR', 'WARN'];

type MODES = 'DEFAULT' | 'SUCCESS' | 'ERROR' | 'WARN' | null;
