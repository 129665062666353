import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventStateService {
  private _reloadEvents$ = new Subject<string | undefined>();

  get reloadEvents$(): Observable<string | undefined> {
    return this._reloadEvents$;
  }

  private _reloadTemplates$ = new Subject();

  get reloadTemplates$(): Observable<any> {
    return this._reloadTemplates$;
  }

  triggerEventsReload(id?: string) {
    this._reloadEvents$.next(id);
  }

  triggerTemplatesReload() {
    this._reloadTemplates$.next(null);
  }

  complete() {
    this._reloadTemplates$.complete();
    this._reloadEvents$.complete();
  }
}
