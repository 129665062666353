import {
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSourceImpl,
} from '@tremaze/shared/util-http';
import { ParticipationCriteria } from '@tremaze/shared/feature/participation-criteria/types';
import { Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { Injectable } from '@angular/core';

export interface ParticipationCriteriaDataSourceMethodsPaginatedOptions
  extends DataSourceMethodsPaginatedOptions {
  mandatory?: boolean;
}

@Injectable({ providedIn: 'root' })
export abstract class ParticipationCriteriaDataSource extends DefaultCRUDDataSourceImpl<ParticipationCriteria> {
  deserializer = ParticipationCriteria.deserialize;

  getPaginated(
    options?: ParticipationCriteriaDataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<ParticipationCriteria>> {
    return super.getPaginated(options);
  }
}
