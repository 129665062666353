import { RemoteUserDataSource } from '@tremaze/shared/feature/user/data-access';
import { Pagination } from '@tremaze/shared/models';
import { User } from '@tremaze/shared/feature/user/types';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { PrivilegeName } from '@tremaze/shared/permission/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RemoteUserDataSourceTageaCCImpl extends RemoteUserDataSource {
  getPaginated(
    options?: DataSourceMethodsPaginatedOptions<User>,
    forPrivilege?: PrivilegeName[],
  ): Observable<Pagination<User>> {
    options = {
      ...options,
    };
    if (forPrivilege?.length) {
      options.controller = '/v1/permissions/users';
      options.q = {
        ...(options?.q ?? {}),
        privileges: forPrivilege?.join(','),
      };
    }
    return super.getPaginated(options, forPrivilege);
  }
}
