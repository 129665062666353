<div style="display: flex; justify-content: end">
  <button mat-icon-button (click)="hideDialogWithoutClosing()">
    <mat-icon style="color: white">close</mat-icon>
  </button>
</div>
<iframe
  [style.height]="iframeHeight$ | async"
  src="https://www.openassistantgpt.io/embed/clwki3tq20001snir8nlyzvnp/window?chatbox=false"
  style="
    overflow: hidden;
    width: 480px;
    border: 2px solid #e2e8f0;
    border-radius: 0.375rem;
    box-shadow:
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  "
  allowfullscreen=""
  allow="clipboard-read; clipboard-write"
></iframe>
