import { inject, Injectable } from '@angular/core';
import { LocationDataSource } from '../data-source';
import { TremazeLocation } from '@tremaze/location-types';
import { Address, Pagination } from '@tremaze/shared/models';
import {
  DataSourceMethodsPaginatedOptions,
  DefaultDataSourceMethods,
} from '@tremaze/shared/util-http';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

type CreateDTO = Omit<TremazeLocation, 'id' | 'address'> & {
  address: Pick<Address, 'city' | 'zip' | 'street' | 'addition'>;
};

function _createPayload(d: CreateDTO | TremazeLocation) {
  return {
    name: d.name,
    address: {
      city: d.address.city,
      zip: d.address.zip,
      street: d.address.street,
      addition: d.address.addition,
    },
  };
}

@Injectable()
export class RemoteLocationDataSourceImpl implements LocationDataSource {
  private readonly _http = inject(HttpClient);

  getLocationsPageForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions<any>,
  ): Observable<Pagination<TremazeLocation>> {
    return DefaultDataSourceMethods.getPaginated<TremazeLocation>(
      this._http,
      `institutions/${instId}/eventLocations`,
      TremazeLocation.deserialize,
      options,
    );
  }

  deleteLocationById(id: string): Observable<void> {
    return DefaultDataSourceMethods.deleteById(
      this._http,
      `eventLocations`,
      id,
    ).pipe(map(() => void 0));
  }

  createLocation(
    instId: string,
    location: CreateDTO,
  ): Observable<TremazeLocation> {
    // re-map this to make sure nothing extra is sent
    const payload = _createPayload(location);

    return this._http
      .post<TremazeLocation>(`institutions/${instId}/eventLocations`, payload)
      .pipe(map(TremazeLocation.deserialize));
  }

  updateLocation(location: TremazeLocation): Observable<TremazeLocation> {
    const payload = _createPayload(location);

    return this._http
      .put<TremazeLocation>(`eventLocations/${location.id}`, payload)
      .pipe(map(TremazeLocation.deserialize));
  }

  getLocationById(id: string): Observable<TremazeLocation> {
    return this._http
      .get<TremazeLocation>(`eventLocations/${id}`)
      .pipe(map(TremazeLocation.deserialize));
  }
}

export const provideRemoteLocationDataSource = () => ({
  provide: LocationDataSource,
  useClass: RemoteLocationDataSourceImpl,
});
