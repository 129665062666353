import { PermissionCheckService } from '@tremaze/shared/permission/services';
import { TzPermissionRequest } from '@tremaze/shared/permission/types';
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { isNotNullOrUndefined } from '@tremaze/shared/util-utilities';

@Directive({
  selector: '[tremazeHasPermission]',
})
export class HasPermissionDirective implements OnChanges {
  private permissions: TzPermissionRequest;
  private isHidden = true;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private _permissionCheckService: PermissionCheckService,
    @Optional() private readonly configService: AppConfigService,
  ) {}

  @Input()
  get tremazeHasPermission(): TzPermissionRequest | undefined {
    return null;
  }

  set tremazeHasPermission(val: TzPermissionRequest | undefined) {
    this.permissions = val;
  }

  ngOnChanges(changes: SimpleChanges) {
    const current: TzPermissionRequest =
      changes['tremazeHasPermission'].currentValue;
    const prev: TzPermissionRequest =
      changes['tremazeHasPermission'].previousValue;
    if (
      !isNotNullOrUndefined(current) ||
      !isNotNullOrUndefined(prev) ||
      JSON.stringify(current) !== JSON.stringify(prev)
    ) {
      if (
        !isNotNullOrUndefined(this.permissions) ||
        !this._permissionCheckService ||
        this.configService?.disablePermissionChecks
      ) {
        this.createView();
        return;
      }
      this.updateView();
    }
  }

  private updateView() {
    this._permissionCheckService
      .checkPermission$(this.permissions)
      .then((r) => {
        if (r) {
          if (this.isHidden) {
            this.createView();
          }
        } else {
          this.isHidden = true;
          if (this.viewContainer) {
            this.viewContainer.clear();
          }
        }
      });
  }

  private createView() {
    if (this.viewContainer?.length === 0) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    this.isHidden = false;
  }
}
