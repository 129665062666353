import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandableFabDirection } from '../types';
import { ExpandableFabItemDirective } from '../expandable-fab-item/expandable-fab-item.directive';

@Component({
  selector: 'tremaze-expandable-fab-overlay',
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-template>
      <div>
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
  exportAs: 'overlay',
  styles: [
    `
      :host {
        display: none;
      }

      div {
        position: relative;
        display: block;
      }
    `,
  ],
})
export class ExpandableFabOverlayComponent
  implements AfterViewInit, AfterContentInit
{
  private readonly _renderer = inject(Renderer2);

  @ViewChild(TemplateRef, { static: true }) templateRef?: TemplateRef<unknown>;

  @ContentChildren(ExpandableFabItemDirective, { read: ElementRef })
  itemsRefs?: QueryList<ElementRef>;
  @ContentChildren(ExpandableFabItemDirective)
  items?: QueryList<ExpandableFabItemDirective>;

  @Input() direction: ExpandableFabDirection = 'topLeft';
  @Input() radius?: number;

  @Output() requestClose = new EventEmitter<void>();

  ngAfterContentInit() {
    this.items?.forEach((item) => {
      item.clicked.subscribe(() => {
        this.requestClose.emit();
      });
    });
  }

  ngAfterViewInit() {
    this._initializeItemPositions();
  }

  show() {
    setTimeout(() => {
      this._updateItemPositions();
    }, 0);
  }

  hide() {
    this._initializeItemPositions();
  }

  // first stack them all on top of each other before positioning them
  private _initializeItemPositions() {
    const items = this.itemsRefs;
    if (!items?.length) {
      return;
    }

    items.forEach((item, index) => {
      // stagger the transition of each item
      const timing = `0.2s ${(index + 1) * 50}ms`;
      const transformTransition = `transform ${timing} ease`;
      const opacityTransition = `opacity ${timing} ease`;
      const leftTransition = `left ${timing} ease`;
      const topTransition = `top ${timing} ease`;
      this._renderer.setStyle(
        item.nativeElement,
        'transition',
        `${transformTransition}, ${opacityTransition}, ${topTransition}, ${leftTransition}`,
      );
      this._renderer.setStyle(item.nativeElement, 'opacity', '0');
      this._renderer.setStyle(item.nativeElement, 'position', 'absolute');
      this._renderer.setStyle(item.nativeElement, 'left', '50%');
      this._renderer.setStyle(item.nativeElement, 'top', '50%');
      this._renderer.setStyle(
        item.nativeElement,
        'transform',
        'translate(-50%, -50%) scale(0) rotate(360deg)',
      );
    });
  }

  private _updateItemPositions() {
    const items = this.itemsRefs;
    if (!items?.length) {
      return;
    }
    const totalItems = items.length;
    const radius = this.radius ?? Math.max(30 * totalItems, 80);
    const angleStep = Math.PI / 2 / (totalItems - 1);

    items.forEach((item, index) => {
      const angle = angleStep * index;

      let x = radius * Math.cos(angle);
      let y = radius * Math.sin(angle);

      switch (this.direction) {
        case 'topLeft':
          x = -x;
          y = -y;
          break;
        case 'bottomRight':
          // x and y remain the same
          break;
        case 'bottomLeft':
          x = -x;
          break;
        case 'topRight':
          y = -y;
          break;
      }

      this._renderer.setStyle(item.nativeElement, 'opacity', '1');
      this._renderer.setStyle(item.nativeElement, 'position', 'absolute');
      this._renderer.setStyle(item.nativeElement, 'left', `calc(50% + ${x}px)`);
      this._renderer.setStyle(item.nativeElement, 'top', `calc(50% + ${y}px)`);
      this._renderer.setStyle(
        item.nativeElement,
        'transform',
        'translate(-50%, -50%) scale(1) rotate(0deg)',
      );
    });
  }
}
