import { SharedSortedFilteredPaginatedTableUiModule } from '@tremaze/shared/sorted-filtered-paginated-table/ui';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedSortedFilteredPaginatedTableUiDirectivesModule } from '@tremaze/shared/sorted-filtered-paginated-table/ui/directives';

@NgModule({
  imports: [
    CommonModule,
    SharedSortedFilteredPaginatedTableUiDirectivesModule,
    SharedSortedFilteredPaginatedTableUiModule,
  ],
  exports: [
    SharedSortedFilteredPaginatedTableUiDirectivesModule,
    SharedSortedFilteredPaginatedTableUiModule,
  ],
})
export class SharedSortedFilteredPaginatedTableModule {}
