import {
  EventBase,
  EventNotification,
  EventSettings,
  EventTemplate,
  TremazeEvent,
} from '@tremaze/shared/feature/event/types';
import { FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Address } from '@tremaze/shared/models';
import { Gender } from '@tremaze/shared/feature/gender/types';
import { TremazeSchedule } from '@tremaze/shared/scheduling/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { AbstractControl, Validators } from '@angular/forms';
import { TremazeValidators } from '@tremaze/shared/util/form';
import { TremazeDate } from '@tremaze/shared/util-date';
import { ControlsValue } from '@ngneat/reactive-forms/lib/types';
import { EventModuleConfig } from '@tremaze/shared/feature/event/module-config';
import { AddressFormUtil } from '@tremaze/shared/feature/address/util/form';
import { User } from '@tremaze/shared/feature/user/types';
import { Department } from '@tremaze/shared/feature/department/types';
import { filter, map, merge, startWith, tap } from 'rxjs';
import { Specialization } from '@tremaze/shared/feature/specialization/types';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';
import { CustomForm } from '@tremaze/shared/feature/custom-forms/types';

const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

interface TemplateEditModel {
  name: FormControl<string>;
  address: FormGroup<Address>;
  maxMember: FormControl<number>;
  institutions?: FormControl<Institution[]> | Institution[];
  institutionAssignments: FormControl<Institution[]>;
  description: string;
}

interface EventEditModel extends TemplateEditModel {
  gender: FormControl<Gender[]>;
  schedule?: FormGroup<TremazeSchedule> | TremazeSchedule;
  startTime: FormControl<string>;
  endTime: FormControl<string>;
  signOnTime: FormControl<string>;
  signOffTime: FormControl<string>;
  eventFiles: FormControl<FileStorage[]>;
  users: FormControl<User[]>;
  organizer: FormControl<User>;
  departments?: FormControl<Department[]> | Department[];
  hideWhenFull: FormControl<boolean>;
  specializations: FormControl<Specialization[]>;
}

export abstract class EventFormUtil {
  static getEventTemplateFormGroup<T extends EventTemplate = EventTemplate>(
    template: T,
    fb: FormBuilder,
    addressRequired?: boolean,
    instRequired?: boolean,
  ): FormGroup<T> {
    return fb.group<T>(
      EventFormUtil.getEventTemplateEditModel<T>(
        template,
        fb,
        addressRequired,
        instRequired,
      ) as any,
      { validator: EventFormUtil.groupValidator },
    );
  }

  static getEventSettingsFormGroup(
    settings: EventSettings,
    fb: FormBuilder,
  ): FormGroup<EventSettings> {
    const model = {
      ...settings,
      customPrices: fb.control(settings.customPrices || []),
    };
    return fb.group<EventSettings>(model as never);
  }

  static getEventNotificationsFormGroup(
    notification: EventNotification = new EventNotification(null, 1, 'DAY'),
  ): FormGroup {
    return new FormGroup({
      value: new FormControl(notification?.value, [
        Validators.required,
        Validators.min(1),
      ]),
      unit: new FormControl(notification?.unit, Validators.required),
    });
  }

  static getEventFormGroup<T extends TremazeEvent = TremazeEvent>(
    event: T,
    fb: FormBuilder,
    moduleConfig?: EventModuleConfig,
  ): FormGroup<T> {
    const eventTemplateEditModel: TemplateEditModel =
      EventFormUtil.getEventTemplateEditModel(
        { ...event, institutionAssignments: [] },
        fb,
        false,
        false,
      );
    const eventEditModel: EventEditModel = {
      ...event,
      ...eventTemplateEditModel,
      users: fb.control(event.users ?? []),
      userTypes: fb.control(event.userTypes ?? []),
      eventSettings: EventFormUtil.getEventSettingsFormGroup(
        event.eventSettings || new EventSettings(),
        fb,
      ),
      startTime: fb.control(
        event.startDate?.format('hh:mm') ||
          TremazeDate.getNow().format('hh:mm'),
        Validators.required,
      ),
      endTime: fb.control(event.endDate?.format('hh:mm')),
      signOnTime: fb.control(event.signOnDate?.format('hh:mm')),
      signOffTime: fb.control(event.signOffDate?.format('hh:mm')),
      eventFiles: fb.control<FileStorage[]>(event.eventFiles),
      notifications: fb.array(
        (event.notifications ?? []).map(
          EventFormUtil.getEventNotificationsFormGroup,
        ),
      ),
      gender: new FormControl(event.gender),
      organizer: fb.control<User>(event.organizer),
      hideWhenFull: fb.control(event.hideWhenFull),
      specializations: fb.control<Specialization[]>(event.specializations),
      contextInstitution: fb.control<Institution>(event.contextInstitution),
    };
    if (
      event.schedule instanceof TremazeSchedule &&
      !moduleConfig?.scheduleDisabled
    ) {
      eventEditModel.schedule = fb.group<TremazeSchedule>(event.schedule);
      const rDaysValue = eventEditModel.schedule.controls.repeatDays.value;
      if (typeof rDaysValue === 'string') {
        eventEditModel.schedule.controls.repeatDays.patchValue([rDaysValue], {
          emitEvent: false,
        });
      }
    } else {
      delete eventEditModel.schedule;
    }
    return fb.group<T>(eventEditModel as any, {
      validators: [
        EventFormUtil.groupValidator,
        TremazeValidators.greaterThanValidator<TremazeEvent>(
          'endDate',
          'startDate',
          { fromOptional: true },
        ),
      ],
    });
  }

  static extractTemplateFormGroupValue<
    T extends EventTemplate | EventBase = EventTemplate,
  >(formGroup: FormGroup<T>, validate = true): null | EventTemplate {
    if (validate) {
      formGroup.markAllAsTouched();
    }
    if (!validate || formGroup.valid) {
      const formValue: ControlsValue<EventTemplate | EventBase> =
        formGroup.getRawValue();
      return EventTemplate.deserialize(formValue);
    }
  }

  static extractTremazeEventFormGroupValue<
    T extends TremazeEvent = TremazeEvent,
  >(formGroup: FormGroup<T>, validate = true): null | TremazeEvent {
    const template: EventTemplate = this.extractTemplateFormGroupValue(
      formGroup,
      validate,
    );
    if (template instanceof EventTemplate) {
      const formValue = {
        ...formGroup.getRawValue(),
        ...template,
      };
      formValue.organizer = formGroup.controls['organizer']?.value;
      formValue.notifications = formGroup.controls['notifications']?.value;
      if (formValue.schedule && !formValue.schedule.startDate) {
        formValue.schedule.startDate = formValue.startDate;
      }
      return TremazeEvent.deserialize(formValue);
    }
  }

  // sync time of date input with time input
  static addDateTimeSync(group: FormGroup, dateKey: string, timeKey: string) {
    const dateControl = group.controls[dateKey] as AbstractControl;
    const timeControl = group.controls[timeKey] as AbstractControl;

    return merge(dateControl.valueChanges, timeControl.valueChanges).pipe(
      map(() => timeControl.value),
      startWith(timeControl.value),
      filterNotNullOrUndefined(),
      filter((v) => timeRegex.test(v)),
      tap((time) => {
        const date: TremazeDate = dateControl.value;
        if (date) {
          const split = time.split(':');
          const [hour, minute] = split.map((v) => parseInt(v, 10));
          const dateValue = date.clone().set({ hour, minute });
          dateControl.setValue(dateValue, { emitEvent: false });
        }
      }),
    );
  }

  private static groupValidator() {
    return (group: FormGroup<TremazeEvent>) => {
      function compMinMax(f1: FormControl<number>, f2: FormControl<number>) {
        if (f1.value > 0 && f2.value > 0 && f2.value < f1.value) {
          f1.setErrors({ aboveMax: true });
          f2.setErrors({ belowMin: true });
        } else {
          const f1Errs = f1.errors;
          delete f1Errs.aboveMax;
          f1.setErrors(f1Errs);
          const f2Errs = f2.errors;
          delete f2Errs.belowMin;
          f2.setErrors(f2Errs);
        }
      }

      compMinMax(group.controls.minAge, group.controls.maxAge);

      return null;
    };
  }

  private static getEventTemplateEditModel<
    T extends Omit<EventTemplate, 'assignedInstIds'>,
  >(
    template: T,
    fb: FormBuilder,
    addressRequired = false,
    instRequired = true,
  ): TemplateEditModel {
    return {
      ...template,
      name: new FormControl<string>(template.name, Validators.required),
      description: new FormControl(template.description),
      address: AddressFormUtil.createFormGroup(
        template.address,
        addressRequired === true,
      ),
      maxMember: new FormControl<number>(template.maxMember, Validators.min(0)),
      institutions: fb.control<Institution[]>(
        template?.institutions || [],
        instRequired ? TremazeValidators.minLengthArray(1) : null,
      ),
      institutionAssignments: fb.control<Institution[]>(
        template?.institutionAssignments ?? [],
      ),
      departments: fb.control<Department[]>(template?.departments ?? []),
      documentationForms: fb.control<CustomForm[]>(
        template?.documentationForms ?? [],
      ),
    };
  }
}
