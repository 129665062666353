<button
  class="form-grid-item-new-row"
  mat-stroked-button
  color="accent"
  [matMenuTriggerFor]="menu"
  type="button"
>
  Feld hinzufügen
</button>

<mat-menu #menu="matMenu">
  <!--suppress TypeScriptValidateTypes -->
  <button
    mat-menu-item
    *ngFor="let fieldType of fieldTypeOptions | keyvalue"
    (click)="onClickOption(fieldType.key)"
    type="button"
  >
    {{ fieldType.value }}
  </button>
</mat-menu>
