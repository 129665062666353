import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSelectorComponent } from './file-selector.component';
import { FileSelectorService } from './file-selector.service';
import {
  FolderViewComponent,
  FolderViewDisableRoutingDirective,
} from '@tremaze/shared/feature/file-storage/feature/folder-view';
import { FileSelectorServiceImpl } from './file-selector.service.impl';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FolderViewComponent,
    FolderViewDisableRoutingDirective,
  ],
  declarations: [FileSelectorComponent],
  providers: [
    {
      provide: FileSelectorService,
      useClass: FileSelectorServiceImpl,
    },
  ],
})
export class SharedFeatureFileStorageUiFileSelectorModule {}
