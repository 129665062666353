import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomFormsTextBoxFieldType } from '../../form-models';

@Component({
  selector: 'tremaze-custom-form-fields-textbox-field-edit',
  template: `
    <fieldset [formGroup]="formGroup">
      <legend>Textbox Einstellungen</legend>

      <mat-form-field>
        <mat-label>Mindestlänge</mat-label>
        <input matInput type="number" formControlName="minLength" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Maximallänge</mat-label>
        <input matInput type="number" formControlName="maxLength" />
        <mat-error>{{ maxLengthErrorMsg }}</mat-error>
      </mat-form-field>

      <mat-button-toggle-group formControlName="enableLineBreaks">
        <mat-button-toggle [value]="false">Einzeilig</mat-button-toggle>
        <mat-button-toggle [value]="true">Mehrzeilig</mat-button-toggle>
      </mat-button-toggle-group>
    </fieldset>
  `,
  styles: [
    `
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      mat-button-toggle-group {
        min-width: 0;
        display: flex;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      mat-button-toggle {
        flex: 1;
      }
    `,
  ],
})
export class CustomFormFieldsTextboxFieldEditComponent {
  @Input() formGroup!: FormGroup<CustomFormsTextBoxFieldType>;

  get maxLengthControl() {
    return this.formGroup.get('maxLength');
  }

  get maxLengthErrorMsg(): string | null {
    if (this.maxLengthControl?.hasError('greaterThanOrEqual')) {
      return 'Maximallänge muss größer oder gleich Mindestlänge sein.';
    }
    return null;
  }
}
