import { Injectable, OnDestroy } from '@angular/core';
import { Pagination } from '@tremaze/shared/models';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  merge,
  Observable,
  ReplaySubject,
  scan,
  skip,
  startWith,
  Subject,
  take,
  withLatestFrom,
} from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

const pageSize = 30;

interface PageRequest {
  page: number;
  pageSize: number;
  filterValue?: string | null;
}

@Injectable()
export class ChipsFilterService<T extends { id?: string }>
  implements OnDestroy
{
  private _isDestroyed = false;
  private _destroyed$ = new Subject<void>();
  private _loadNextPage$ = new Subject<void>();
  private _reload$ = new Subject<void>();

  private readonly _loader$ = new ReplaySubject<
    (
      page: number,
      pageSize: number,
      filterValue?: string | null,
    ) => Observable<Pagination<T>>
  >(1);

  readonly textFilter$ = new BehaviorSubject<string | null>(null);
  readonly textFilterChange$ = this.textFilter$.pipe(
    distinctUntilChanged(),
    skip(1),
    shareReplay(1),
    takeUntil(this._destroyed$),
  );
  private readonly _latestPageRequest$: Observable<PageRequest> = combineLatest(
    [
      this.textFilterChange$.pipe(startWith(null)),
      this._reload$.pipe(startWith(null)),
    ],
  ).pipe(
    debounceTime(300),
    switchMap(([filterValue]) =>
      this._loadNextPage$.pipe(
        startWith(null),
        scan((page) => page + 1, -1),
        map((page) => ({
          page,
          pageSize,
          filterValue,
        })),
      ),
    ),
  );

  private readonly _pages$: Observable<Pagination<T>[]> =
    this._latestPageRequest$.pipe(
      withLatestFrom(this._loader$),
      switchMap(([pageRequest, loader]) =>
        loader(pageRequest.page, pageRequest.pageSize, pageRequest.filterValue),
      ),
      scan((pages, page) => {
        if (page.number === 0) {
          return [page];
        } else {
          return [...pages, page];
        }
      }, [] as Pagination<T>[]),
      takeUntil(this._destroyed$),
      shareReplay(1),
    );

  readonly items$: Observable<T[]> = this._pages$.pipe(
    map((r) => r.reduce((acc, cur) => [...acc, ...cur.content], [] as T[])),
    takeUntil(this._destroyed$),
    shareReplay(1),
  );
  readonly isLoading$ = merge(
    this.textFilterChange$.pipe(map(() => true)),
    this._loadNextPage$.pipe(map(() => true)),
    this._reload$.pipe(map(() => true)),
    this.items$.pipe(map(() => false)),
  ).pipe(startWith(true));
  private readonly _selectedItems$ = new BehaviorSubject<T[]>([]);
  /**
   * These are elements that are not present in the returned list but are selected
   */
  readonly missingItems$: Observable<T[]> = combineLatest([
    this.items$.pipe(take(1)),
    this.selectedItems$.pipe(take(1)),
  ]).pipe(
    map(([items, selectedItems]) =>
      selectedItems.filter((i) => !items.some((ii) => ii.id === i.id)),
    ),
    takeUntil(this._destroyed$),
    shareReplay(1),
  );
  readonly totalElements$: Observable<number> = this._pages$.pipe(
    map((r) => r[0].totalElements ?? 0),
    withLatestFrom(this.missingItems$),
    map((r) => r[0] + r[1].length),
    takeUntil(this._destroyed$),
    shareReplay(1),
  );
  readonly hiddenElementsCount$: Observable<number> = combineLatest([
    this.items$,
    this._selectedItems$,
    this.missingItems$,
  ]).pipe(
    map(([items, selectedItems, missingItems]) => {
      return Math.max(
        0,
        selectedItems.filter((i) => !items.some((ii) => ii.id === i.id))
          .length - missingItems.length,
      );
    }),
    takeUntil(this._destroyed$),
    shareReplay(1),
  );
  readonly filterCount$: Observable<number> = this._selectedItems$.pipe(
    map((selectedItems) => selectedItems.length),
  );

  get selectedItems$(): Observable<T[]> {
    return this._selectedItems$;
  }

  setFilterValue(filterValue?: string): void {
    this.textFilter$.next(filterValue ?? null);
  }

  loadNextPage(): void {
    this._loadNextPage$.next();
  }

  isItemSelected$(item: T): Observable<boolean> {
    return this._selectedItems$.pipe(
      map((selectedItems) => selectedItems.some((i) => i.id === item.id)),
    );
  }

  init(
    loader: (page: number, pageSize: number) => Observable<Pagination<T>>,
  ): void {
    this._loader$.next(loader);
  }

  destroy(): void {
    if (!this._isDestroyed) {
      return;
    }
    this._isDestroyed = true;
    this._destroyed$.next();
    this._destroyed$.complete();
    this._selectedItems$.complete();
    this.textFilter$.complete();
    this._loadNextPage$.complete();
    this._loader$.complete();
    this._reload$.complete();
  }

  ngOnDestroy() {
    this.destroy();
  }

  toggleSelection(item: T): void {
    const selectedItems = [...this._selectedItems$.getValue()];
    const index = selectedItems.findIndex((i) => i.id === item.id);
    if (index === -1) {
      selectedItems.push(item);
    } else {
      selectedItems.splice(index, 1);
    }
    this._selectedItems$.next(selectedItems);
  }

  resetFilter(): void {
    this._selectedItems$.next([]);
  }

  reload(): void {
    this._reload$.next();
  }

  setValue(value: T[]): void {
    this._selectedItems$.next(value);
  }
}
