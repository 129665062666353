import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FolderViewDataSource } from '@tremaze/shared/feature/file-storage/data-access';
import { NotificationService } from '@tremaze/shared/notification';
import { doOnError } from '@tremaze/shared/util/rxjs';
import { finalize, tap } from 'rxjs';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
  selector: 'tremaze-folder-view-copy-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressBar,
  ],
  templateUrl: './folder-view-copy-dialog.component.html',
  styleUrl: './folder-view-copy-dialog.component.scss',
})
export class FolderViewCopyDialogComponent {
  private readonly _data: { file: FileStorage; folderId: string } =
    inject(MAT_DIALOG_DATA);
  private readonly _dataSource = inject(FolderViewDataSource);
  private readonly _notificationService = inject(NotificationService);
  private readonly _ref = inject(MatDialogRef);

  isLoading = false;

  readonly filenameFormControl = new FormControl(this.filenameNoExtension, {
    validators: [Validators.required],
  });

  get file() {
    return this._data.file;
  }

  get filenameNoExtension() {
    const extensionStartIndex = this.file.fileViewname.lastIndexOf('.');
    return extensionStartIndex > -1
      ? this.file.fileViewname.slice(0, extensionStartIndex)
      : this.file.fileViewname;
  }

  get fileExtension() {
    const extensionStartIndex = this.file.fileViewname.lastIndexOf('.');
    return extensionStartIndex > -1
      ? this.file.fileViewname.slice(extensionStartIndex)
      : '';
  }

  onClickCopy() {
    if (this.filenameFormControl.valid) {
      const fileName = this.filenameFormControl.value?.trim();
      if (!fileName?.length) {
        return;
      }
      this.isLoading = true;
      this._dataSource
        .copyFileToFolder({
          folderId: this._data.folderId,
          fileId: this.file.id,
          fileName: `${fileName}${this.fileExtension}`,
        })
        .pipe(
          doOnError(() =>
            this._notificationService.showDefaultErrorNotification(),
          ),
          tap((file) => {
            this._notificationService.showNotification(
              'Die Datei wurde kopiert',
            );
            this._ref.close(file);
          }),
          finalize(() => (this.isLoading = false)),
        )
        .subscribe();
    }
  }
}
