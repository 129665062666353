import { inject, Injectable } from '@angular/core';
import {
  AssignmentAction,
  AssignmentTarget,
  SuggestionConfig,
  SuggestionsDataSource,
} from '../data-source';
import { map, Observable } from 'rxjs';
import { Division } from '@tremaze/shared/feature/division/types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Pagination } from '@tremaze/shared/models';

@Injectable({
  providedIn: 'root',
})
export class RemoteDivisionsSuggestionsDataSource
  implements Pick<SuggestionsDataSource, 'getDivisions'>
{
  private readonly _http = inject(HttpClient);

  private _getDivisionsFromSuggestionsAPI(
    action: 'INST_WRITE' | 'INFORMATION_WRITE',
    config?: SuggestionConfig,
  ): Observable<Division[]> {
    return this._http
      .get<unknown[]>('/v2/suggestions/divisions', {
        params: {
          suggestionAction: action,
          filterValue: config?.filterValue ? `%${config.filterValue}%` : '',
        },
      })
      .pipe(
        map((response) =>
          response
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map((d) => Division.deserialize(d)!)
            .filter((d) => !!d)
            .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? '')),
        ),
      );
  }

  getDivisions(
    target: Omit<AssignmentTarget, 'division'>,
    action: AssignmentAction,
    config?: SuggestionConfig,
  ): Observable<Division[]> {
    if (action === 'write') {
      switch (target) {
        case 'institution':
          return this._getDivisionsFromSuggestionsAPI('INST_WRITE', config);
        case 'information':
          return this._getDivisionsFromSuggestionsAPI(
            'INFORMATION_WRITE',
            config,
          );
      }
    }

    const queryParams = {
      filterValue: config?.filterValue ?? '',
      filterFields: 'NAME',
    };
    const params = new HttpParams({ fromObject: queryParams });
    return this._http.get<Pagination<any>>(`/divisions`, { params }).pipe(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map((response) => response.content.map((d) => Division.deserialize(d)!)),
    );
  }
}
