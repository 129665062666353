import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModule as pdf } from '@syncfusion/ej2-angular-pdfviewer';
import { PdfViewerComponent } from './pdf-viewer.component';
import { PdfViewerDialogComponent } from './pdf-viewer-dialog.component';
import { MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
  imports: [
    CommonModule,
    pdf,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatSidenavModule,
    MatCardModule,
  ],

  declarations: [PdfViewerComponent, PdfViewerDialogComponent],
})
export class PdfViewerModule {}
