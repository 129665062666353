import { Institution } from '@tremaze/shared/feature/institution/types';
import { PageGenerator } from '../helpers';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';

class InstitutionFixturePageGenerator extends PageGenerator<Institution> {
  override generateByIndex(index: number): Institution {
    return InstitutionFixtures.generateInstitution(index);
  }
}

export abstract class InstitutionFixtures {
  private static _institutionStubPageGenerator =
    new InstitutionFixturePageGenerator();

  static generateInstitution = (id: number) =>
    new Institution(id.toString(), undefined, `Institution ${id}`);

  static generateInstitutionPage = (
    options: DataSourceMethodsPaginatedOptions,
  ) => InstitutionFixtures._institutionStubPageGenerator.generatePage(options);

  static readonly institution1 = InstitutionFixtures.generateInstitution(1);
  static readonly institution2 = InstitutionFixtures.generateInstitution(2);
  static readonly institution3 = InstitutionFixtures.generateInstitution(3);
  static readonly institution4 = InstitutionFixtures.generateInstitution(4);
  static readonly institution5 = InstitutionFixtures.generateInstitution(5);
}
