import {
  Component,
  ContentChild,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { DynamicListItemComponent } from '../dynamic-list-item/dynamic-list-item.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DynamicListAddButtonComponent } from '../tremaze-dynamic-list-add-button/dynamic-list-add-button.component';
import { dynamicListAnimations } from '../animations';

@Component({
  selector: 'tremaze-dynamic-list',
  template: `
    <div
      class="dynamic-list__Wrapper"
      [class.dynamic-list__Wrapper--With-Add-Button]="hasAddButton"
      [class.dynamic-list__Wrapper--Empty]="!listItems?.length"
    >
      <div
        class="dynamic-list__Table-Wrapper"
        *ngIf="listItems?.length; else empty"
      >
        <ul
          [@tableAnimation]="listItems?.length ?? 0"
          [style.padding-bottom]=""
        >
          <ng-content></ng-content>
        </ul>
      </div>
      <ng-template #empty>
        <div class="dynamic-list__Empty">
          <p class="dynamic-list__Empty__Text">{{ emptyText }}</p>
        </div>
      </ng-template>
      <ng-content select="tremaze-dynamic-list-add-button"></ng-content>
    </div>
  `,
  styleUrls: ['./dynamic-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: dynamicListAnimations,
})
export class DynamicListComponent {
  @ContentChildren(DynamicListItemComponent, { descendants: true })
  readonly listItems!: QueryList<DynamicListItemComponent>;

  @ContentChild(DynamicListAddButtonComponent)
  readonly addButton?: DynamicListAddButtonComponent;

  get hasAddButton(): boolean {
    return !!this.addButton;
  }

  @Input() readonly emptyText = 'Keine Einträge gefunden';

  @Input()
  readonly appearance: 'flat' | 'alternating' = 'flat';

  private _noBottomPadding = false;

  @Input()
  get noBottomPadding(): boolean {
    return this._noBottomPadding;
  }

  set noBottomPadding(value: boolean) {
    this._noBottomPadding = coerceBooleanProperty(value);
  }

  @HostBinding('class.dynamic-list--flat')
  get isFlat(): boolean {
    return this.appearance === 'flat';
  }

  @HostBinding('class.dynamic-list--alternating')
  get isAlternating(): boolean {
    return this.appearance === 'alternating';
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
}
