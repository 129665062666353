import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SuggestionConfig, SuggestionsDataSource } from '../data-source';
import { TremazeLocation } from '@tremaze/location-types';
import { map, Observable } from 'rxjs';
import { configToQueryParamObject } from './helpers';
import { Pagination } from '@tremaze/shared/models';

@Injectable({
  providedIn: 'root',
})
export class RemoteLocationsSuggestionsDataSource
  implements Pick<SuggestionsDataSource, 'getLocations'>
{
  private readonly _http = inject(HttpClient);

  getLocations(config?: SuggestionConfig): Observable<TremazeLocation[]> {
    return this._http
      .get<Pagination<any>>('/suggestions/eventLocations', {
        params: configToQueryParamObject(config),
      })
      .pipe(
        map((response) =>
          response.content.map((d) => TremazeLocation.deserialize(d)),
        ),
      );
  }
}
