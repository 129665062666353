import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[tremazeAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  private readonly _elRef = inject(ElementRef);

  ngAfterViewInit() {
    setTimeout(() => {
      this._elRef.nativeElement.focus();
    }, 0);
  }
}
