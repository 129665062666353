import { MatDialog } from '@angular/material/dialog';
import { SharedUiExportDataTypeSelectorModule } from './shared-ui-export-data-type-selector.module';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExportDataTypeSelectorComponent } from './export-data-type-selector.component';
import { ExportDataType } from '../../types/src';

@Injectable({
  providedIn: SharedUiExportDataTypeSelectorModule,
})
export class ExportDataTypeSelectorService {
  constructor(private dialog: MatDialog) {}

  selectDataType(options: ExportDataType[]): Observable<ExportDataType | null> {
    return this.dialog
      .open(ExportDataTypeSelectorComponent, { data: options })
      .afterClosed();
  }
}
