import {
  Component,
  EventEmitter,
  forwardRef,
  InjectionToken,
  Input,
  NgModule,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivilegeName } from '@tremaze/shared/permission/types';
import { InstitutionREADDataSource } from '@tremaze/shared/feature/institution/data-access';
import {
  ChipsFilterButtonComponent,
  ChipsFilterService,
  SharedUiChipsFilterPopupModule,
} from '@tremaze/shared/ui/chips-filter-popup';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PersonalConfigInstitution } from '@tremaze/personal-config';
import { map } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';

export const INSTITUTION_FILTER_BUTTON_SERVICE = new InjectionToken<
  ChipsFilterService<PersonalConfigInstitution>
>('INSTITUTION_FILTER_BUTTON_SERVICE');

@Component({
  selector: 'tremaze-institution-filter-button',
  template: `
    <tremaze-chips-filter-button
      (filterChange)="onFilterChange($event)"
      [displayWith]="displayWith"
      [loadItems]="loadItems"
      [getAvatar]="getAvatar"
      [getInitials]="getInitials"
      [initialValues]="initialInstitutions"
      label="Einrichtungen"
      iconName="lnr lnr-apartment"
      [keepServiceAfterDestruction]="keepServiceAfterDestruction"
    ></tremaze-chips-filter-button>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InstitutionFilterButtonComponent),
      multi: true,
    },
    {
      provide: ChipsFilterService,
      useFactory: (service?: ChipsFilterService<PersonalConfigInstitution>) =>
        service ?? undefined,
      deps: [[new Optional(), INSTITUTION_FILTER_BUTTON_SERVICE]],
    },
  ],
})
export class InstitutionFilterButtonComponent implements ControlValueAccessor {
  @Input() institutionFilterPrivilege?: PrivilegeName[];
  @Output() filterChange = new EventEmitter<PersonalConfigInstitution[]>();
  @ViewChild(ChipsFilterButtonComponent, { static: true })
  private readonly _chipsFilterButtonComponent!: ChipsFilterButtonComponent<PersonalConfigInstitution>;

  @Input() keepServiceAfterDestruction = false;
  @Input() initialInstitutions: PersonalConfigInstitution[] = [];

  constructor(private institutionDataSource: InstitutionREADDataSource) {}

  resetFilter() {
    this._chipsFilterButtonComponent.resetFilter();
  }

  onFilterChange(institutions: PersonalConfigInstitution[]) {
    this.onChange?.(institutions);
    this.filterChange.emit(institutions);
  }

  readonly displayWith = (institution: PersonalConfigInstitution): string =>
    institution.name ?? '';

  readonly getAvatar = (
    institution: PersonalConfigInstitution,
  ): FileStorage | undefined => institution.logo;

  readonly getInitials = (
    institution: PersonalConfigInstitution,
  ): string | undefined => {
    if (!institution.name) {
      return undefined;
    }

    const words = institution.name.split(' ');
    if (words.length === 1) {
      return words[0].charAt(0);
    }

    return words[0].charAt(0) + words[words.length - 1].charAt(0);
  };

  readonly loadItems = (page: number, pageSize: number, filterValue?: string) =>
    this.institutionDataSource
      .getPaginated(
        {
          filter: {
            sort: 'name',
            sortDirection: 'asc',
            page: page,
            pageSize: pageSize,
            filterValue: filterValue,
            filterFields: ['NAME'],
          },
        },
        this.institutionFilterPrivilege,
      )
      .pipe(
        map((r) =>
          Pagination.deserialize(r, (d) => ({
            id: d.id,
            name: d.name,
          })),
        ),
      );

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._chipsFilterButtonComponent.setDisabledState(isDisabled);
  }

  writeValue(obj: PersonalConfigInstitution[] | null): void {
    this._chipsFilterButtonComponent.writeValue(obj);
  }

  private onChange = (_: any) => null;

  private onTouched = (_: any) => null;
}

@NgModule({
  imports: [CommonModule, SharedUiChipsFilterPopupModule],
  declarations: [InstitutionFilterButtonComponent],
  exports: [InstitutionFilterButtonComponent],
})
export class SharedFeatureInstitutionFeatureFilterButtonModule {}
