import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class DocumentRef {
  constructor(@Inject(DOCUMENT) private _doc: any) {}

  get nativeDocument(): Document {
    return this._doc;
  }

  appendStyleToHead(css: string) {
    const headElement = this.nativeDocument.getElementsByTagName('head')[0];
    const styleTag = this.nativeDocument.createElement('style');
    styleTag.setAttribute('type', 'text/css');
    styleTag.appendChild(document.createTextNode(css));
    headElement.appendChild(styleTag);
  }
}
