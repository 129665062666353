import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ExpandableFabItemDirective,
  ExpandableFabOverlayComponent,
  ExpandableFabTriggerForDirective,
} from '@tremaze/expandable-fab';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatButton, MatFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';

@Component({
  selector: 'tremaze-folder-view-actions-row',
  standalone: true,
  imports: [
    CommonModule,
    ExpandableFabTriggerForDirective,
    IconComponent,
    MatFabButton,
    ExpandableFabItemDirective,
    MatTooltip,
    ExpandableFabOverlayComponent,
    MatButton,
  ],
  template: `
    <ng-content select="[slot=start]"></ng-content>

    <span class="spacer"></span>

    <ng-content></ng-content>

    <button [tremazeExpandableFabTriggerFor]="overlay" color="accent" mat-fab>
      <tremaze-icon icon="lnr-plus"></tremaze-icon>
    </button>

    <tremaze-expandable-fab-overlay #overlay="overlay">
      <button
        (click)="addFile.emit()"
        color="accent"
        mat-fab
        matTooltip="Dateien hochladen"
        tremazeExpandableFabItem
      >
        <tremaze-icon icon="lnr-folder-upload"></tremaze-icon>
      </button>

      @if (showSelectFromFileStorageButton) {
        <button
          mat-fab
          color="accent"
          tremazeExpandableFabItem
          matTooltip="Datei aus Dateiablage auswählen"
          (click)="selectFromFileStorage.emit()"
        >
          <tremaze-icon icon="lnr-cloud-upload"></tremaze-icon>
        </button>
      }

      <button
        (click)="addFolder.emit()"
        color="accent"
        mat-fab
        matTooltip="Ordner erstellen"
        tremazeExpandableFabItem
      >
        <tremaze-icon icon="lnr-folder-plus"></tremaze-icon>
      </button>
    </tremaze-expandable-fab-overlay>
  `,
  styles: `
    :host {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px;
      z-index: 1;
      overflow: hidden;
      position: relative;
    }
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-actions-row',
  },
})
export class FolderViewActionsRowComponent {
  @Input() selectedFiles: FileStorage[] = [];
  @Input() selectedFolders: DirStorage[] = [];
  @Input() hideSelectedFilesAndFoldersButton = false;
  @Input() showSelectFromFileStorageButton = false;

  @Output() readonly addFolder = new EventEmitter<void>();
  @Output() readonly addFile = new EventEmitter<void>();
  @Output() readonly selectFromFileStorage = new EventEmitter<void>();
}
