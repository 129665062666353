import { Meta } from '@tremaze/shared/models';
import { TremazeDate } from '@tremaze/shared/util-date';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { CustomFormVersion } from './form-version';

export type CustomFormFeature =
  | 'INST_REGISTRATION'
  | 'EVENT_DOCUMENTATION'
  | 'CLIENT_DOCUMENTATION'
  | 'USER_BASE_DATA';

export const customFormFeatureLabelMap: Record<CustomFormFeature, string> = {
  CLIENT_DOCUMENTATION: 'Klientendokumentation',
  EVENT_DOCUMENTATION: 'Veranstaltungsdokumentation',
  INST_REGISTRATION: 'Registrierungsformular',
  USER_BASE_DATA: 'Nutzer-Stammdaten',
};

@staticImplements<Deserializable<CustomForm>>()
export class CustomForm {
  constructor(
    readonly id: string,
    readonly meta: Meta,
    readonly name: string,
    readonly published: boolean,
    readonly currentFormVersion?: CustomFormVersion,
    readonly feature?: CustomFormFeature,
    readonly validUntil?: TremazeDate,
    readonly institutions?: Institution[],
    readonly hasHTMLTemplate?: boolean,
    readonly htmlTemplate?: string,
  ) {}

  get fields() {
    return this.currentFormVersion?.fields ?? [];
  }

  get institutionNamesString() {
    return this.institutions?.map((i) => i.name).join(', ') ?? '';
  }

  static deserialize(data: any): CustomForm {
    return new CustomForm(
      data.formId ?? data.id,
      Meta.deserialize(data),
      data.name?.trim(),
      data.published ?? false,
      CustomFormVersion.deserialize(
        data.currentFormVersion ?? data.formVersion,
      ),
      data.feature,
      data.validUntil ? TremazeDate.deserialize(data.validUntil) : undefined,
      data.institutions?.map((i: any) => Institution.deserialize(i)),
      data.hasHTMLTemplate ?? data.hasHtmlTemplate ?? false,
      data.htmlTemplate ?? '',
    );
  }
}
