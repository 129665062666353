import { Component, ViewEncapsulation } from '@angular/core';
import { CategoryEditComponentService } from './edit-component.service';
import { EditComponentService } from '@tremaze/shared/edit';
import { RemoteUserDataSource } from '@tremaze/shared/feature/user/data-access';
import { UserType } from '@tremaze/shared/feature/user/types';
import { RemoteCCDepartmentDataSource } from '@tremaze/shared/feature/department/data-access';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tremaze-category-edit',
  template: `
    <tremaze-edit-dialog title="Kategorie">
      <form class="form-grid" [formGroup]="formGroup" style="width: 600px;">
        <mat-form-field *ngIf="showTitleImageInput">
          <mat-label>Bild</mat-label>
          <tremaze-file-selector-input
            formControlName="titleImage"
          ></tremaze-file-selector-input>
        </mat-form-field>

        <mat-form-field class="form-grid-item-new-row">
          <mat-label>Name</mat-label>
          <input
            matInput
            formControlName="name"
            required
            maxlength="60"
            #nameInput
          />
          <mat-hint align="end">{{ nameInput.value.length ?? 0 }}/60</mat-hint>
          <mat-error>Name darf nicht leer sein</mat-error>
        </mat-form-field>

        <div class="form-grid-item-full-width">
          <tremaze-hint mode="ERROR" *ngIf="showAllocationNeededHint"
            >Du verfügst nicht über die notwendigen Berechtigungen, um globale
            Kategorien zu erstellen. Bitte nimm eine Zuweisung vor.
          </tremaze-hint>
        </div>

        <mat-form-field *ngIf="showColorInput">
          <mat-label>Farbe</mat-label>
          <input
            matInput
            [ngxMatColorPicker]="picker"
            formControlName="color"
            required
          />
          <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
          <ngx-mat-color-picker #picker></ngx-mat-color-picker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Einrichtungen</mat-label>
          <tremaze-institution-select
            formControlName="institutions"
            multiple
          ></tremaze-institution-select>
        </mat-form-field>

        <mat-form-field *ngIf="showDepartmentAllocationInput">
          <mat-label>Abteilungen</mat-label>
          <tremaze-selector
            formControlName="departments"
            multiple
            [dataSource]="departmentsDataSource"
          ></tremaze-selector>
        </mat-form-field>

        <mat-form-field *ngIf="showUserTypeAllocationInput">
          <mat-label>Benutzertypen</mat-label>
          <tremaze-selector
            formControlName="userTypes"
            multiple
            [dataSource]="userTypeDataSource"
            [getOptionLabel]="userTypeDisplayWith"
          ></tremaze-selector>
        </mat-form-field>

        <mat-form-field
          class="form-grid-item-full-width"
          *ngIf="showDescriptionInput"
        >
          <mat-label>Beschreibung</mat-label>
          <textarea
            matInput
            formControlName="description"
            maxlength="500"
            cdkTextareaAutosize
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="10"
          ></textarea>
          <mat-hint align="end"
            >{{ formGroup.get('description').value?.length || 0 }}/500</mat-hint
          >
        </mat-form-field>

        <strong *ngIf="showIconInput"
          >Icon:
          <tremaze-icon-picker-button
            formControlName="icon"
          ></tremaze-icon-picker-button>
        </strong>
      </form>
    </tremaze-edit-dialog>
  `,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    CategoryEditComponentService,
    {
      provide: EditComponentService,
      useExisting: CategoryEditComponentService,
    },
  ],
})
export class CategoryEditComponent {
  constructor(
    private service: CategoryEditComponentService,
    private userDataSource: RemoteUserDataSource,
    private departmentDataSource: RemoteCCDepartmentDataSource,
  ) {}

  get formGroup() {
    return this.service.formGroup;
  }

  get showTitleImageInput() {
    return this.service.titleImageEnabled;
  }

  get showIconInput() {
    return this.service.iconEnabled;
  }

  get showColorInput() {
    return this.service.colorEnabled;
  }

  get showAllocationNeededHint(): boolean {
    return this.formGroup.hasError('eitherRequired');
  }

  get showDescriptionInput() {
    return this.service.descriptionEnabled;
  }

  get showDepartmentAllocationInput() {
    return this.service.departmentAllocationEnabled;
  }

  get showUserTypeAllocationInput() {
    return this.service.userTypeAllocationEnabled;
  }

  userTypeDataSource = () => this.userDataSource.getAllUserTypes();

  userTypeDisplayWith = (u: UserType) => u.viewName;

  departmentsDataSource = () =>
    this.departmentDataSource.getPaginated().pipe(map((r) => r.content));
}
