import { CustomFormExportSchemaPlaceholders } from '@tremaze/shared/feature/custom-forms/types';
import { TremazeDate } from '@tremaze/shared/util-date';

export abstract class CustomFormSchemasFixtures {
  static readonly all: CustomFormExportSchemaPlaceholders = {
    formFieldPlaceholders: {
      latest: {
        formVersionId: '3',
        formVersionDate: new TremazeDate('2021-01-01'),
        placeholders: [
          {
            name: 'formField1',
            displayName: 'Form Feld 1',
          },
          {
            name: 'formField2',
            displayName: 'Form Feld 2',
          },
          {
            name: 'formField3',
            displayName: 'Form Feld 3',
          },
          {
            name: 'formField4',
            displayName: 'Form Feld 4',
          },
        ],
      },
      other: [
        {
          formVersionId: '2',
          formVersionDate: new TremazeDate('2020-01-01'),
          placeholders: [
            {
              name: 'formFieldAlt1',
              displayName: 'Form Feld Alt 1',
            },
            {
              name: 'formFieldAlt2',
              displayName: 'Form Feld Alt 2',
            },
            {
              name: 'formFieldAlt3',
              displayName: 'Form Feld Alt 3',
            },
            {
              name: 'formFieldAlt4',
              displayName: 'Form Feld Alt 4',
            },
          ],
        },
        {
          formVersionId: '1',
          formVersionDate: new TremazeDate('2019-01-01'),
          placeholders: [
            {
              name: 'formFieldOld1',
              displayName: 'Form Feld Alt 1',
            },
            {
              name: 'formFieldOld2',
              displayName: 'Form Feld Alt 2',
            },
            {
              name: 'formFieldOld3',
              displayName: 'Form Feld Alt 3',
            },
          ],
        },
      ],
    },
    otherPlaceholders: [
      {
        displayName: 'Name der Klient*in',
        name: 'clientName',
      },
      {
        displayName: 'Termindokumentation',
        name: 'appointmentDocumentation',
      },
      {
        displayName: 'Name des Mitarbeiters',
        name: 'employeeName',
      },
    ],
  };
}
