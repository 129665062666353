import { InjectionToken } from '@angular/core';

export interface InstitutionModuleConfig {
  enableWorkflow?: boolean;
  disableDashboard?: boolean;
  disableEventDashboard?: boolean;
  disableDepartments?: boolean;
  disableSubdomainSettings?: boolean;
  disablePermissionSettings?: boolean;
  disableSettings?: boolean;
}

export const INSTITUTION_MODULE_CONFIG =
  new InjectionToken<InstitutionModuleConfig>('INSTITUTION_MODULE_CONFIG');
