<div class='folder-view-grid-layout-file-item__Head'>
  <tremaze-icon [icon]='icon'></tremaze-icon>
  @if (isEditing && file) {
    <tremaze-folder-view-item-name-input
      class='folder-view-grid-layout-file-item__Name-Input'
      [item]='file'
      (cancel)='onCancel()'
      (submitText)='onSubmitText($event)'
    ></tremaze-folder-view-item-name-input>
  } @else {
    <figcaption class='folder-view-grid-layout-file-item__Head__Name'>
      {{ name }}
    </figcaption>
    <button
      mat-icon-button
      class='small-icon-button'
      [matMenuTriggerFor]='menu'
      (click)='$event.stopPropagation()'
      [disabled]='disabled'
    >
      <tremaze-icon
        icon='lnr-ellipsis'
        style='transform: rotate(90deg)'
      ></tremaze-icon>
    </button>
  }
</div>
@if (file) {
  <tremaze-single-file-display
    class='folder-view-grid-layout-file-item__File-Preview'
    [file]='file'
    [canShowPreviewOverlay]='false'
    [draggable]='false'
  ></tremaze-single-file-display>
}
<mat-menu #menu='matMenu'>
  <ng-template matMenuContent>
    <tremaze-folder-view-file-contextmenu
      (copyFileLink)='copyFileLink.emit()'
      (deleteFile)='deleteFile.emit()'
      (downloadFile)='downloadFile.emit()'
      (duplicateFile)='duplicateFile.emit()'
      (navigateToPath)='navigateToPath.emit()'
      (renameFile)='onClickEditName()'
      (showFilePreview)='showFilePreview.emit()'
      [canDelete]='!canDelete$ || ((canDelete$ | async) === true)'
      [canWrite]='!canWrite$ || ((canWrite$ | async) === true)'
      [file]='file'
    ></tremaze-folder-view-file-contextmenu>
  </ng-template>
</mat-menu>
