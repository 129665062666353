export type EventStatus =
  | 'PLANNED'
  | 'BILLED'
  | 'COMPLETED'
  | 'CANCELED_BY_EMPLOYEE'
  | 'DID_NOT_SHOW_UP_WITHOUT_CANCELLATION'
  | 'DID_NOT_SHOW_UP_WITH_SHORT_CANCELLATION'
  | 'DID_NOT_SHOW_UP_WITH_CANCELLATION';

export function isCanceledEventStatus(status: EventStatus): boolean {
  return [
    'CANCELED_BY_EMPLOYEE',
    'DID_NOT_SHOW_UP_WITHOUT_CANCELLATION',
    'DID_NOT_SHOW_UP_WITH_SHORT_CANCELLATION',
    'DID_NOT_SHOW_UP_WITH_CANCELLATION',
  ].includes(status);
}

export const EVENT_STATUSES: EventStatus[] = [
  'PLANNED',
  'BILLED',
  'COMPLETED',
  'CANCELED_BY_EMPLOYEE',
  'DID_NOT_SHOW_UP_WITHOUT_CANCELLATION',
  'DID_NOT_SHOW_UP_WITH_SHORT_CANCELLATION',
  'DID_NOT_SHOW_UP_WITH_CANCELLATION',
];

export function localizeEventEventStatus(status?: EventStatus): string {
  if (!status) {
    return 'Kein Status';
  }
  switch (status) {
    case 'PLANNED':
      return 'Geplant';
    case 'BILLED':
      return 'Abgerechnet';
    case 'COMPLETED':
      return 'Durchgeführt';
    case 'CANCELED_BY_EMPLOYEE':
      return 'Abgesagt durch Berater*in';
    case 'DID_NOT_SHOW_UP_WITHOUT_CANCELLATION':
      return 'Nicht erschienen ohne Absage';
    case 'DID_NOT_SHOW_UP_WITH_SHORT_CANCELLATION':
      return 'Nicht erschienen, kurzfr. Absage';
    case 'DID_NOT_SHOW_UP_WITH_CANCELLATION':
      return 'Nicht erschienen mit Absage';
    default:
      return 'Kein Status';
  }
}

export const eventStatusLocalizationMap: Record<EventStatus, string> =
  EVENT_STATUSES.reduce(
    (acc, status) => ({
      ...acc,
      [status]: localizeEventEventStatus(status),
    }),
    {},
  ) as Record<EventStatus, string>;
