<div>
  <table>
    @for (dragItem of dragItems; track dragItem.id) {
      <tr>
        <td>
          <tremaze-icon
            [icon]="dragItem.type === 'file' ? 'lnr-file-empty' : 'lnr-folder'"
          ></tremaze-icon>
        </td>
        <td>
          <span>
            {{ dragItem.name }}
          </span>
        </td>
      </tr>
    }
  </table>
</div>
