import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DataSourceMethodsPaginatedOptions,
  DefaultDataSourceMethods,
} from '@tremaze/shared/util-http';
import { ApprovalService } from '@tremaze/shared/feature/approval/types';
import { map, Observable, switchMap } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { ApprovalServiceDataSource } from '../approval-service-data-source';
import { Mutable, PartialBy } from '@tremaze/shared/util/types';
import { catchErrorMapTo } from '@tremaze/shared/util/rxjs';

function clearNullIds(subApprovalServices: PartialBy<ApprovalService, 'id'>[]) {
  return subApprovalServices.map((s) => {
    const ss = s as Mutable<PartialBy<ApprovalService, 'id'>>;
    if (ss.id === null) {
      delete ss.id;
    }
    return ss;
  });
}

@Injectable({
  providedIn: 'root',
})
export class RemoteApprovalServiceDataSource
  implements ApprovalServiceDataSource
{
  constructor(
    private readonly _http: HttpClient,
    private readonly _js: JsonSerializer,
  ) {}

  getIsApprovalShortNameAvailable(
    institutionId: string,
    shortName: string,
  ): Observable<boolean> {
    return this._http
      .get<boolean>(
        `institutions/${institutionId}/approvalServices/shortNameInUse`,
        {
          params: { shortName },
        },
      )
      .pipe(
        catchErrorMapTo(false),
        map((r) => !r),
      );
  }

  getApprovalServicesForUser(userId: string): Observable<ApprovalService[]> {
    return this._http
      .get<Pagination<ApprovalService>>(`users/${userId}/approvalServices`)
      .pipe(map((r) => r.content));
  }

  createApprovalService(
    approvalService: Omit<ApprovalService, 'id' | 'subApprovalServices'> & {
      subApprovalServices?: PartialBy<ApprovalService, 'id'>[];
    },
    institutionId: string,
  ): Observable<ApprovalService> {
    const payload = {
      ...approvalService,
      subApprovalServices: clearNullIds(
        approvalService.subApprovalServices ?? [],
      ),
    } as Mutable<PartialBy<ApprovalService, 'id'>>;
    delete payload.id;
    return DefaultDataSourceMethods.create(
      this._http,
      `institutions/${institutionId}/approvalServices`,
      (d) => d,
      this._js,
      payload,
    );
  }

  updateApprovalService(
    approvalService: Omit<ApprovalService, 'subApprovalServices'> & {
      subApprovalServices?: PartialBy<ApprovalService, 'id'>[];
    },
  ): Observable<ApprovalService> {
    const payload = {
      ...approvalService,
      subApprovalServices: clearNullIds(
        approvalService.subApprovalServices ?? [],
      ),
    } as Mutable<PartialBy<ApprovalService, 'id'>>;
    delete payload.id;
    return DefaultDataSourceMethods.edit(
      this._http,
      `approvalServices/${approvalService.id}`,
      (d) => d,
      this._js,
      payload,
    );
  }

  getPaginatedApprovalServices(
    options: DataSourceMethodsPaginatedOptions<any>,
  ): Observable<Pagination<ApprovalService>> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      `/approvalServices`,
      (d) => d,
      {
        ...options,
        filter: {
          ...options?.filter,
          filterFields: options.filter?.filterFields?.length
            ? options.filter.filterFields
            : ['SHORT_NAME', 'EXTERNAL_ID', 'DESCRIPTION'],
        },
      },
    );
  }

  getPaginatedApprovalServicesForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions<any>,
  ): Observable<Pagination<ApprovalService>> {
    return this.getPaginatedApprovalServices({
      ...options,
      instIds: [instId],
      filter: {
        ...options.filter,
        filterFields: options.filter?.filterFields?.length
          ? options.filter.filterFields
          : ['SHORT_NAME', 'EXTERNAL_ID', 'DESCRIPTION'],
      },
    });
  }

  getApprovalServiceById(id: string): Observable<ApprovalService> {
    return DefaultDataSourceMethods.getFreshById(
      this._http,
      `approvalServices`,
      (d) => d,
      id,
    );
  }

  deleteApprovalService(id: string): Observable<boolean> {
    return DefaultDataSourceMethods.deleteById(
      this._http,
      `approvalServices`,
      id,
    );
  }

  getApprovalServiceByName(name: string): Observable<ApprovalService> {
    return this.getPaginatedApprovalServices({
      filter: { filterValue: name, filterFields: ['DESCRIPTION'] },
    }).pipe(
      switchMap((r) =>
        this.getApprovalServiceById(
          r.content.find(
            (a) => a.description?.toLowerCase() === name.toLowerCase(),
          )!.id,
        ),
      ),
    );
  }
}

export const provideRemoteApprovalServiceDataSource = () => ({
  provide: ApprovalServiceDataSource,
  useClass: RemoteApprovalServiceDataSource,
});
