<mat-progress-bar
  mode="indeterminate"
  *ngIf="showLoader$ | async"
></mat-progress-bar>
<div mat-dialog-title style="display: flex; padding-top: 20px">
  <span>Filter: {{ label }} ({{ filterCount$ | async }})</span>

  <span class="spacer"></span>
  <button
    mat-button
    [disabled]="disableResetFilterButton$ | async"
    (click)="onClickResetFilterButton()"
  >
    Filter zurücksetzen
  </button>
</div>
<div mat-dialog-content>
  <div id="wrapper">
    <tremaze-animated-height [trigger]="items$ | async">
      <div
        style="display: flex; flex-wrap: wrap; justify-content: space-between"
      >
        <mat-form-field style="min-width: 200px">
          <mat-label>Suche</mat-label>
          <input
            matInput
            type="text"
            (input)="onChangeFilterText($event)"
            #filter
          />
        </mat-form-field>
        @if (projectedFilters) {
          <div style="margin-left: 20px">
            @for (filter of projectedFilters; track filter) {
              <div>
                <ng-container
                  *ngTemplateOutlet="filter.templateRef"
                ></ng-container>
              </div>
            }
          </div>
        }
      </div>
      <ng-container *ngIf="items$ | async as items">
        <mat-chip-listbox multiple selectable *ngIf="items.length; else empty">
          <mat-chip-option
            (click)="toggleSelection(item)"
            *ngFor="let item of items; let i = index"
            [selected]="isItemSelected$(item) | async"
          >
            <tremaze-circle-avatar
              *ngIf="_showAvatar"
              [file]="getAvatar(item)"
              [radius]="9"
              [fallbackInitials]="getInitials(item)"
              matChipAvatar
            ></tremaze-circle-avatar>
            {{ displayWith(item) }}
          </mat-chip-option>

          <button
            mat-stroked-button
            color="primary"
            *ngIf="showLoadMoreButton$ | async"
            (click)="onClickedLoadMoreButton()"
          >
            Mehr laden
          </button>
        </mat-chip-listbox>
      </ng-container>
      <ng-template #empty>
        <p>Keine Ergebnisse...</p>
      </ng-template>
      <mat-divider style="margin: 10px 0"></mat-divider>
      <div style="display: flex; align-items: center">
        <ng-container
          *ngIf="hiddenElementsCount$ | async as hiddenElementsCount"
        >
          <small>und {{ hiddenElementsCount }} ausgeblendete {{ label }}</small>
          <button
            mat-button
            (click)="onClickResetTextFilterButton()"
            [hidden]="textFilterEmpty$ | async"
          >
            Suche zurücksetzen
          </button>
        </ng-container>
        <span class="spacer"></span>
        <small style="color: grey" *ngIf="totalElementsCount$ | async"
          >{{ visibleElementsCount$ | async }} von
          {{ totalElementsCount$ | async }} Ergebnissen</small
        >
      </div>
    </tremaze-animated-height>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close="">Schließen</button>
</div>
