import {
  Component,
  Input,
  Optional,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { MatButton } from '@angular/material/button';
import { NgControl } from '@angular/forms';
import { IconPickerService } from './icon-picker.service';

@Component({
  selector: 'tremaze-icon-picker-button',
  template: `
    <button mat-icon-button color="accent" (click)="onClick()">
      <span class="lnr lnr-{{ value || 'question-circle' }}"></span>
    </button>
  `,
  encapsulation: ViewEncapsulation.Emulated,
})
export class IconPickerButtonComponent implements ControlValueAccessor<string> {
  @ViewChild(MatButton) private buttonElement: MatButton;

  constructor(
    private iconPickerService: IconPickerService,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _value: string | null;

  @Input()
  get value(): string | null {
    return this._value;
  }

  set value(value: string | null) {
    this._value = value;
  }

  writeValue(value: string): void {
    this.value = value;
    this.onChangeCallback(this.value);
  }

  onClick() {
    this.iconPickerService.pickIcon().subscribe((r) => {
      if (r) {
        this.writeValue(r);
      }
    });
  }

  registerOnChange(fn: never): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: never): void {
    this.onTouchedCallback = fn;
  }

  private onTouchedCallback = () => null;

  private onChangeCallback = (v: string) => null;
}
