<tremaze-folder-view-item-icon
  [item]='folder'
  [matTooltipDisabled]='!isSystemDir'
  class='folder-view-grid-layout-folder-item__Icon'
  matTooltip='System-Ordner'
></tremaze-folder-view-item-icon>
@if ((isCreateFolderItem || (isEditingFolderName && folder))) {
  <tremaze-folder-view-item-name-input
    class='folder-view-grid-layout-folder-item__Name-Input'
    [item]='isCreateFolderItem ? null : folder!'
    (submitText)='onSubmitText($event)'
    (cancel)='onCancel()'
  ></tremaze-folder-view-item-name-input>
} @else if (folder) {
  <div class='folder-view-grid-layout-folder-item__Name'>{{ folder.dirViewname }}</div>

  <button
    [matMenuTriggerFor]='menu'
    class='folder-view-grid-layout-folder-item__Menu-Button small-icon-button'
    (click)='$event.stopPropagation()'
    mat-icon-button
  >
    <tremaze-icon icon='lnr-ellipsis'></tremaze-icon>
  </button>
}

<mat-menu #menu='matMenu'>
  <ng-template matMenuContent>
    <tremaze-folder-view-folder-contextmenu
      (deleteFolder)='deleteFolder.emit()'
      (openFolder)='openFolder.emit()'
      (renameFolder)='onClickRenameFolder()'
      [canWrite]='!canWrite$ || ((canWrite$ | async) === true)'
      [folder]='folder'
    ></tremaze-folder-view-folder-contextmenu>
  </ng-template>
</mat-menu>
