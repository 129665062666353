import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Observable } from 'rxjs';
import { Address, Pagination } from '@tremaze/shared/models';
import { TremazeLocation } from '@tremaze/location-types';

export abstract class LocationDataSource {
  abstract getLocationsPageForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<TremazeLocation>>;

  abstract deleteLocationById(id: string): Observable<void>;

  abstract createLocation(
    instId: string,
    location: Omit<TremazeLocation, 'id' | 'address'> & {
      address: Pick<Address, 'city' | 'zip' | 'street' | 'addition'>;
    },
  ): Observable<TremazeLocation>;

  abstract updateLocation(
    location: TremazeLocation,
  ): Observable<TremazeLocation>;

  abstract getLocationById(id: string): Observable<TremazeLocation>;
}
