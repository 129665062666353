import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { TremazeModuleName } from './types';
import { TenantConfigService } from './tenant-config.service';
import {
  distinctUntilChanged,
  mergeMap,
  ReplaySubject,
  shareReplay,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';

@Directive({
  selector: '[ifModuleEnabled]',
  standalone: true,
})
export class IfModuleEnabledDirective implements OnDestroy, OnInit {
  private _moduleName$ = new ReplaySubject<TremazeModuleName>(1);
  private _destroyed$ = new Subject<void>();
  private _isEnabled$ = this._moduleName$.pipe(
    mergeMap((moduleName) => this._service.isModuleEnabled(moduleName)),
    distinctUntilChanged(),
    takeUntil(this._destroyed$),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
  private _ifModuleEnabled!: TremazeModuleName;

  get ifModuleEnabled(): TremazeModuleName {
    return this._ifModuleEnabled;
  }

  @Input()
  set ifModuleEnabled(value: TremazeModuleName) {
    this._ifModuleEnabled = value;
    this._moduleName$.next(value);
  }

  constructor(
    private readonly _service: TenantConfigService,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this._isEnabled$
      .pipe(
        tap((isEnabled) => {
          if (isEnabled) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._moduleName$.complete();
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
