import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Meta } from '@tremaze/shared/models';

type DefaultPriceLevelId = 'DEFAULT_PRICE_CLASS';

@staticImplements<Deserializable<PriceLevel>>()
export class PriceLevel {
  public static readonly DEFAULT_PRICE_CLASS_ID: DefaultPriceLevelId =
    'DEFAULT_PRICE_CLASS';

  constructor(
    readonly id?: string | DefaultPriceLevelId,
    readonly meta?: Meta,
    public name?: string,
    public description?: string,
    public price: number = 0.0,
    public attendeesSpace = 0,
    readonly countRegistrations = 0,
  ) {}

  public static GENERATE_DEFAULT_PRICE_CLASS(price: number) {
    return new PriceLevel(
      PriceLevel.DEFAULT_PRICE_CLASS_ID,
      null,
      'Standard',
      null,
      price,
    );
  }

  static deserialize(data: PriceLevel): PriceLevel {
    return !data
      ? null
      : new PriceLevel(
          data.id,
          Meta.deserialize(data.meta),
          data.name,
          data.description,
          data.price,
          data.attendeesSpace,
          data.countRegistrations,
        );
  }
}
