import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomFormEditComponentService } from '../custom-form-edit-component.service/custom-form-edit-component.service';
import { CustomFormEditConfig } from '@tremaze/shared/feature/custom-forms/feature/edit';

@Component({
  selector: 'tremaze-custom-form-edit-dialog',
  templateUrl: './custom-form-edit-dialog.component.html',
  styleUrls: ['./custom-form-edit-dialog.component.scss'],
  providers: [CustomFormEditComponentService],
})
export class CustomFormEditDialogComponent implements OnDestroy {
  constructor(
    private _ref: MatDialogRef<CustomFormEditDialogComponent>,
    readonly service: CustomFormEditComponentService,
    @Inject(MAT_DIALOG_DATA)
    readonly config?: CustomFormEditConfig,
  ) {}

  onClickSubmitButton() {
    this.service.submit((r) => this._ref.close(r));
  }

  ngOnDestroy() {
    this.service.destroy();
  }
}
