import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PrivilegeName } from '@tremaze/shared/permission/types';
import { User, UserTypeName } from '@tremaze/shared/feature/user/types';
import { map, Observable } from 'rxjs';
import { DefaultDataSourceMethods } from '@tremaze/shared/util-http';
import {
  AssignmentAction,
  AssignmentTarget,
  PaginatedSuggestionConfig,
  SuggestionConfig,
  SuggestionsDataSource,
} from '../data-source';
import { Pagination } from '@tremaze/shared/models';
import { configToQueryParamObject } from './helpers';
import { remoteSuggestionsApiConfig } from './config';

@Injectable({
  providedIn: 'root',
})
export class RemoteUsersSuggestionsDataSource
  implements Pick<SuggestionsDataSource, 'getUsers'>
{
  private readonly _http = inject(HttpClient);

  private _getUsersByPrivilege(
    privileges: PrivilegeName[],
    filterValue?: string,
    userTypes?: UserTypeName[],
  ): Observable<User[]> {
    const q = {
      perms: privileges,
    } as any;
    if (userTypes) {
      q['userTypeIdentifier'] = userTypes;
    }
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      'entityPermissions/users',
      User.deserialize,
      {
        q,
        filter: {
          pageSize: remoteSuggestionsApiConfig.pageSize,
          filterValue,
          filterFields: ['FIRST_NAME', 'LAST_NAME', 'USERNAME'],
          sort: 'lastName',
          sortDirection: 'asc',
        },
      },
    ).pipe(map((response) => response.content));
  }

  getUsersFromSuggestionsAPI(
    action: 'MANAGED_USERS' | 'DIVISION_USERS',
    config?: SuggestionConfig,
  ): Observable<User[]> {
    return this._http
      .get<unknown[]>('/v2/suggestions/users', {
        params: {
          suggestionAction: action,
          filterValue: config?.filterValue ?? '',
          filterFields: 'FIRST_NAME,LAST_NAME,USERNAME',
        },
      })
      .pipe(
        map((response) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          response.map((d) => User.deserialize(d)!).filter((d) => !!d),
        ),
      );
  }

  getPaginatedUsersFromSuggestionsAPI(
    action: 'RESOURCE_VIEW_USERS',
    config: PaginatedSuggestionConfig & {
      userTypeIdentifiers?: UserTypeName[];
    } = {},
  ): Observable<Pagination<User>> {
    const params = new HttpParams({
      fromObject: configToQueryParamObject(
        action,
        {
          ...config,
          sort: 'lastName,asc',
          userTypeIdentifiers: config?.userTypeIdentifiers,
        },
        ['FIRST_NAME', 'LAST_NAME', 'USERNAME'],
      ),
    });
    return this._http
      .get('/v3/suggestions/users', { params })
      .pipe(
        map(
          (response) =>
            Pagination.deserialize(response, User.deserialize) as any,
        ),
      );
  }

  getUsers(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
    userTypes?: UserTypeName[],
  ): Observable<User[]> {
    let privileges: PrivilegeName[] = [];
    switch (action) {
      case 'write':
        switch (target) {
          case 'event':
            privileges = [
              'EVENT_WRITE',
              'EVENT_WRITE_REFERENCE_CLIENT',
              'EVENT_CLIENT_WRITE',
              'EVENT_WRITE_DEPARTMENT',
              'EVENT_EMPLOYEE_WRITE',
            ];
            break;
          case 'information':
            privileges = [
              'INFORMATION_WRITE',
              'INFORMATION_WRITE_REFERENCE_CLIENT',
              'INFORMATION_WRITE_DEPARTMENT',
              'INFORMATION_CLIENT_WRITE',
              'INFORMATION_EMPLOYEE_WRITE',
            ];
            break;
          case 'family':
            privileges = ['FAMILY_MANAGE', 'FAMILY_MANAGE_REFERENCE_CLIENT'];
            break;
        }
        break;
      case 'delete':
        switch (target) {
          case 'event':
            privileges = ['EVENT_DELETE', 'EVENT_DELETE_REFERENCE_CLIENT'];
            break;
          case 'information':
            privileges = [
              'INFORMATION_DELETE',
              'INFORMATION_DELETE_REFERENCE_CLIENT',
            ];
            break;
        }
    }
    return this._getUsersByPrivilege(privileges, filter, userTypes);
  }
}
