import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ImageCropperComponent } from './image-cropper.component';
import { map } from 'rxjs/operators';
import { ImageCropperServicePayload } from '@tremaze/shared/feature/file-storage/feature/image-cropper/types';

@Injectable({ providedIn: 'root' })
export class ImageCropperService {
  constructor(private dialog: MatDialog) {}

  cropImage(data: ImageCropperServicePayload): Observable<null | Blob> {
    return this.dialog
      .open(ImageCropperComponent, { data })
      .afterClosed()
      .pipe(map((r) => r || null));
  }
}
