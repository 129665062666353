import { Deserializable, staticImplements } from '@tremaze/shared/util-decorators';
import { Meta } from '@tremaze/shared/models';

/**
 * @deprecated Use {ParticipationCriteria} instead
 */
@staticImplements<Deserializable<EventCriteria>>()
export class EventCriteria {
  constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public description?: string,
    public criteria?: boolean,
    public mandatory?: boolean,
  ) {}

  static deserialize(data: any) {
    return !data
      ? null
      : new EventCriteria(
          data.id,
          Meta.deserialize(data.meta),
          data.description,
          data.criteria,
          data.mandatory,
        );
  }
}

/**
 * @deprecated
 */
@staticImplements<Deserializable<EventRegistrationCriteria>>()
export class EventRegistrationCriteria {
  constructor(
    readonly id?: string,
    public checked = false,
    public eventCriteria?: EventCriteria,
  ) {}

  static deserialize(data: any) {
    return !data
      ? null
      : new EventRegistrationCriteria(
          data.id,
          data.checked,
          EventCriteria.deserialize(data.eventCriteria),
        );
  }
}
