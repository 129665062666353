import { map, switchMap } from 'rxjs/operators';
import { Privilege, Role } from '@tremaze/shared/permission/types';
import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsDeleteOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsGetFreshOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSourceImpl,
  DefaultREADDataSourceWithPaginationImpl,
} from '@tremaze/shared/util-http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { Observable, zip } from 'rxjs';
import { Injectable, Optional } from '@angular/core';
import { User } from '@tremaze/shared/feature/user/types';
import { Pagination } from '@tremaze/shared/models';
import { InstitutionContextService } from '@tremaze/shared/feature/institution/shared/singletons';
import { TremazeHttpResponse } from '@tremaze/shared/util-http/types';

@Injectable({ providedIn: 'root' })
export class RemotePrivilegeDataSource extends DefaultREADDataSourceWithPaginationImpl<Privilege> {
  deserializer = Privilege.deserialize;
  controller = '/privileges';

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class RemoteInstitutionPrivilegeDataSource extends RemotePrivilegeDataSource {
  controller = '/institutions/privileges';

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super(http, js);
  }
}

interface RoleCreateDTO {
  privilegeIds: string[];
  viewName: string;
}

interface RoleEditDTO extends RoleCreateDTO {
  roleId: string;
}

@Injectable({ providedIn: 'root' })
export class RemoteRoleDataSource extends DefaultCRUDDataSourceImpl<Role> {
  deserializer = Role.deserialize;
  controller = '/roles';

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  create(
    i: Role,
    options?: DataSourceMethodsCreateOptions<Role>,
  ): Observable<Role> {
    const payload: RoleCreateDTO = {
      viewName: i.viewName,
      privilegeIds: i.privileges.map((p) => p.id),
    };
    return super.create(payload as any, options);
  }

  edit(
    i: Role,
    options?: DataSourceMethodsEditOptions<Role>,
  ): Observable<Role> {
    const payload: RoleEditDTO = {
      roleId: i.id,
      privilegeIds: i.privileges.map((p) => p.id),
      viewName: i.viewName,
    };
    return super.edit(payload as any, options);
  }

  getRolesFromUser(user: User): Observable<Role[]> {
    return this.http
      .get<Role[]>(`/users/${user.id}/roles`)
      .pipe(map((r) => r?.map(Role.deserialize) || []));
  }

  setRolesToUser(user: User, roles: Role[]): Observable<boolean> {
    return this.getRolesFromUser(user).pipe(
      switchMap((currentRoles) => {
        const rolesToAdd = roles.filter(
          (r) => !currentRoles.some((rr) => rr.id === r.id),
        );
        const rolesToRemove = currentRoles.filter(
          (r) => !roles.some((rr) => rr.id === r.id),
        );
        const requests = [];
        if (rolesToAdd.length) {
          requests.push(
            this.http
              .post<TremazeHttpResponse<any>>(`/users/${user.id}/roles`, null, {
                params: new HttpParams({
                  fromObject: { roleIds: rolesToAdd.map((r) => r.id) },
                }),
              })
              .pipe(map((r) => r.status === 'SUCCESS')),
          );
        }
        if (rolesToRemove.length) {
          requests.push(
            this.http
              .delete<TremazeHttpResponse<any>>(`/users/${user.id}/roles`, {
                params: new HttpParams({
                  fromObject: { roleIds: rolesToRemove.map((r) => r.id) },
                }),
              })
              .pipe(map((r) => r.status === 'SUCCESS')),
          );
        }
        return zip(...requests).pipe(map((r) => r.every((x) => !!x)));
      }),
    );
  }
}

@Injectable({ providedIn: 'root' })
export class RemoteInstitutionRoleDataSource extends RemoteRoleDataSource {
  controller = '/institutions';

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
    @Optional()
    private readonly institutionContextService?: InstitutionContextService,
  ) {
    super(http, js);
  }

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions,
    instId?: string,
  ): Observable<Pagination<Role>> {
    if (instId) {
      return super.getPaginated({
        ...(options ?? {}),
        endpoint: `/${instId}/roles`,
      });
    }
    return this.institutionContextService.currentInstitutionId$.pipe(
      switchMap((iinstId) => {
        return this.getPaginated(options, iinstId);
      }),
    );
  }

  deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions,
  ): Observable<boolean> {
    return super.deleteById(id, {
      ...(options ?? {}),
      controller: `${this.controller}/roles`,
    });
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions,
  ): Observable<Role> {
    return super.getFreshById(id, {
      ...(options ?? {}),
      controller: `${this.controller}/roles`,
    });
  }

  create(
    i: Role,
    options?: DataSourceMethodsCreateOptions<Role>,
  ): Observable<Role> {
    return this.institutionContextService.currentInstitutionId$.pipe(
      switchMap((instId) => {
        return this.createRoleForInstitution(i, instId, options);
      }),
    );
  }

  createRoleForInstitution(
    role: Role,
    instId: string,
    options?: DataSourceMethodsCreateOptions<Role>,
  ): Observable<Role> {
    return super.create(role, {
      ...(options ?? {}),
      endpoint: `/${instId}/roles`,
    });
  }

  edit(
    i: Role,
    options?: DataSourceMethodsEditOptions<Role>,
  ): Observable<Role> {
    return this.institutionContextService.currentInstitutionId$.pipe(
      switchMap((instId) => {
        return super.edit(i, {
          ...(options ?? {}),
          endpoint: `/${instId}/roles`,
        });
      }),
    );
  }
}
