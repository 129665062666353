import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EventTemplate } from '@tremaze/shared/feature/event/types';
import { EventTemplateSelectDialogComponent } from './select-dialog.component';

export type EventTemplateResultType =
  | 'CANCEL'
  | 'CONTINUE_WITHOUT'
  | EventTemplate;

@Injectable({ providedIn: 'any' })
export class EventTemplateSelectService {
  constructor(private dialog: MatDialog) {}

  select(): Observable<EventTemplateResultType> {
    return this.dialog
      .open(EventTemplateSelectDialogComponent, {
        panelClass: 'relative-dialog',
      })
      .afterClosed()
      .pipe(
        map((r) => {
          if (r instanceof EventTemplate) {
            return r;
          } else if (r === null) {
            return 'CONTINUE_WITHOUT';
          } else {
            return 'CANCEL';
          }
        }),
      );
  }
}
