/**
 * Class decorator to force a class to statically implement a method
 */
export function staticImplements<T>() {
  return <U extends T>(constructor: U) => constructor;
}

export interface Deserializable<T> {
  deserialize(data: any): T | null;
}

export function debounceHostListener(delay: number = 300): MethodDecorator {
  return function (
    target: any,
    propertyKey: string | symbol,
    descriptor: PropertyDescriptor,
  ) {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}
