<p>Wie soll mit der Terminserie verfahren werden?</p>
<tremaze-hint
  *ngIf="showHint"
  colorPalette="accent"
  [mode]="'WARN'"
  [innerHTML]="hintText"
>
</tremaze-hint>
<mat-selection-list [(ngModel)]="eventScope" [multiple]="false">
  <mat-list-option [disabled]="isScopeDisabled('SINGLE')" value="SINGLE"
    >Nur diesen Termin</mat-list-option
  >
  <mat-list-option [disabled]="isScopeDisabled('FROM_DATE')" value="FROM_DATE"
    >Diesen Termin und alle Folgenden
  </mat-list-option>
  <mat-list-option [disabled]="isScopeDisabled('ALL')" value="ALL"
    >Alle Termine der Serie</mat-list-option
  >
</mat-selection-list>
<tremaze-popup-actions>
  <button mat-button [tremazePopupClose]="undefined">Abbrechen</button>
  <button mat-raised-button [tremazePopupClose]="eventScope[0]" color="accent">
    Bestätigen
  </button>
</tremaze-popup-actions>
