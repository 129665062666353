import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryEditComponent } from './edit.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { SharedFeatureIconFeatureIconPickerModule } from '@tremaze/shared/feature/icon/feature/icon-picker';
import { MatButtonModule } from '@angular/material/button';
import { SharedEditModule } from '@tremaze/shared/edit';
import { SharedFeatureFileStorageUiFileSelectorInputModule } from '@tremaze/shared/feature/file-storage/ui/file-selector/input';
import { SharedUiSelectorModule } from '@tremaze/shared/ui/selector';
import { SharedFeatureInstitutionFeatureSelectModule } from '@tremaze/shared/feature/institution/feature/select';
import { SharedUiHintModule } from '@tremaze/shared/ui/hint';
import { CategoryEditService } from './edit.service';
import { SharedUiRichTextEditorModule } from '@tremaze/shared/ui/rich-text-editor';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxMatColorPickerModule,
    SharedFeatureIconFeatureIconPickerModule,
    MatButtonModule,
    SharedEditModule,
    SharedFeatureFileStorageUiFileSelectorInputModule,
    SharedUiSelectorModule,
    SharedFeatureInstitutionFeatureSelectModule,
    SharedUiHintModule,
    SharedUiRichTextEditorModule,
  ],
  declarations: [CategoryEditComponent],
  providers: [CategoryEditService],
})
export class SharedFeatureCategoryFeatureEditModule {}
