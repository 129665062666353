import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventREADDataSource } from '@tremaze/shared/feature/event/data-access';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EventParticipationType } from '@tremaze/shared/feature/event/types';
import { ensureArray, ensureNotArray } from '@tremaze/shared/util-utilities';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { SharedUiHintModule } from '@tremaze/shared/ui/hint';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'tremaze-event-participation-type-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    SharedUiHintModule,
    IconComponent,
    MatTooltip,
  ],
  templateUrl: './event-participation-type-selector.component.html',
  styles: `
    :host {
      display: block;
    }

    mat-form-field {
      width: 100%;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EventParticipationTypeSelectorComponent,
    },
  ],
})
export class EventParticipationTypeSelectorComponent extends DefaultValueAccessor {
  private readonly _dataSource = inject(EventREADDataSource);

  readonly availableEventParticipationTypes$ =
    this._dataSource.getAllParticipationTypes();

  readonly compareWith = (
    a?: EventParticipationType,
    b?: EventParticipationType,
  ) => a?.id === b?.id;

  private _value?: EventParticipationType | EventParticipationType[];

  get value(): EventParticipationType | EventParticipationType[] | undefined {
    return this._value;
  }

  @Input()
  set value(
    value: EventParticipationType | EventParticipationType[] | undefined,
  ) {
    value = this._transformValue(value);
    this._value = value;
  }

  private _multiple = false;

  get multiple(): boolean {
    return this._multiple;
  }

  @Input({ transform: booleanAttribute })
  set multiple(value: boolean) {
    this._multiple = value;
  }

  onSelectionChange(event: MatSelectChange) {
    this.value = this._transformValue(event.value);
    this.onTouched();
    this.onChange(this.value);
  }

  override writeValue(value: any) {
    value = this._transformValue(value);
    this._value = value;
    super.writeValue(value);
  }

  private _transformValue(
    value: EventParticipationType | EventParticipationType[] | undefined,
  ): EventParticipationType | EventParticipationType[] | undefined {
    if (this.multiple) {
      return ensureArray(value);
    } else {
      return ensureNotArray(value);
    }
  }
}
