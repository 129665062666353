import { Injectable } from '@angular/core';
import { PopupService } from '@tremaze/shared/ui/popup';
import { FeatureAssistantDialogComponent } from './feature-assistant-dialog/feature-assistant-dialog.component';
import { Observable } from 'rxjs';

@Injectable()
export class FeatureAssistantService {
  constructor(private readonly _popup: PopupService) {}

  open(config: {
    title: string;
    message: string;
    maxWidth?: string;
  }): Observable<void> {
    const { title, message, maxWidth } = config;
    return this._popup
      .open(FeatureAssistantDialogComponent, {
        title,
        data: { message },
        maxWidth,
      })
      .afterClosed();
  }
}
