import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatRipple } from '@angular/material/core';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { AutofocusDirective } from '@tremaze/autofocus';

@Component({
  selector: 'tremaze-folder-view-item-name-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IconComponent,
    MatRipple,
    ReactiveFormsModule,
    AutofocusDirective,
  ],
  template: `
    @if (formControl) {
      <input
        #input
        (blur)="onBlur($event)"
        (focus)="onFocus()"
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        (keyup)="onKeyup($event)"
        (keyup.enter)="onClickSubmit($event)"
        (mousedown)="$event.stopPropagation()"
        [style.font-size]="fontSize"
        [formControl]="formControl"
        class="folder-view-item-name-input__Input"
        tremazeAutofocus
      />
    }
    <div class="folder-view-item-name-input__Suffixes">
      @if (extension) {
        <span>
          {{ extension }}
        </span>
      }

      <button (click)="onClickSubmit($event)" matRipple>
        <tremaze-icon icon="lnr-check"></tremaze-icon>
      </button>

      <button (blur)="onBlur($event)" (click)="onClickCancel($event)" matRipple>
        <tremaze-icon icon="lnr-cross"></tremaze-icon>
      </button>
    </div>
  `,
  styleUrls: ['./folder-view-item-name-input.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-item-name-input',
  },
})
export class FolderViewItemNameInputComponent {
  private readonly __elementRef = inject(ElementRef);

  @ViewChild('input') input?: ElementRef<HTMLInputElement>;

  private _focused = false;

  @HostBinding('class.focused')
  get focused() {
    return this._focused;
  }

  @Input() fontSize = '1rem';

  private _formControl?: FormControl<string | null>;

  get formControl() {
    return this._formControl;
  }

  private _extension?: string;

  get extension() {
    return this._extension;
  }

  @Input({ required: true }) set item(value: FileStorage | DirStorage | null) {
    let formControlValue: string;
    if (value instanceof FileStorage) {
      const extensionIndex = value.fileViewname?.indexOf('.');
      if (extensionIndex != -1) {
        formControlValue = value.fileViewname?.slice(0, extensionIndex);
        this._extension = value.fileViewname?.slice(extensionIndex);
      } else {
        formControlValue = value.fileViewname;
        this._extension = undefined;
      }
    } else {
      this._extension = undefined;
      if (value instanceof DirStorage) {
        formControlValue = value.dirViewname;
      } else {
        formControlValue = '';
      }
    }

    this._formControl = new FormControl(formControlValue);

    setTimeout(() => {
      return this.input?.nativeElement?.select();
    }, 10);
  }

  @Output() readonly submitText = new EventEmitter<string>();
  @Output() readonly cancel = new EventEmitter<void>();

  onFocus() {
    this._focused = true;
  }

  onClickCancel(event: Event) {
    event.stopPropagation();
    this.cancel.emit();
  }

  onClickSubmit(event: Event) {
    event.stopPropagation();
    if (this._formControl?.valid) {
      let value = this._formControl.value;
      if (value) {
        if (this._extension) {
          value += this._extension;
        }
        this.submitText.emit(value.trim());
        this.formControl?.markAsPristine();
      }
    }
  }

  onKeyup(event: KeyboardEvent) {
    event.stopPropagation();
  }

  onBlur(event: FocusEvent) {
    this._focused = false;
    const isFocusLostCompletely = event.relatedTarget == null;

    function isFocusOutsideComponent(elementRef: ElementRef) {
      return !elementRef.nativeElement.contains(event.relatedTarget);
    }

    const hasValue = !!this.formControl?.value;
    const isFocusLost =
      isFocusLostCompletely || isFocusOutsideComponent(this.__elementRef);
    if (isFocusLost && (!hasValue || this.formControl?.pristine)) {
      this.cancel.emit();
    }
  }
}
