import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Linearicon } from '@tremaze/shared/ui';

@Component({
  selector: 'tremaze-dynamic-list-item-action',
  template: `
    <button
      mat-icon-button
      (click)="onClick($event)"
      [color]="color"
      type="button"
      [disabled]="disabled"
    >
      <mat-icon>
        <span class="{{ icon }}"></span>
      </mat-icon>
    </button>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'dynamic-list__Item__Action',
  },
})
export class DynamicListItemActionComponent {
  @Input() icon!: `lnr ${Linearicon}`;

  @Input() color: ThemePalette = 'primary';

  @Output() clickedAction = new EventEmitter<void>();

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  onClick($event: Event): void {
    $event.stopPropagation();
    this.clickedAction.emit();
  }
}
