import { User, UserType } from '@tremaze/shared/feature/user/types';
import { ContactInfo, Meta } from '@tremaze/shared/models';
import { PageGenerator } from '../helpers';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';

class UserFixturePageGenerator extends PageGenerator<User> {
  override generateByIndex(index: number): User {
    return UserFixtures.generateUser(index);
  }
}

export abstract class UserFixtures {
  private static _userStubPageGenerator = new UserFixturePageGenerator();

  static generateUserPage = (options: DataSourceMethodsPaginatedOptions) =>
    UserFixtures._userStubPageGenerator.generatePage(options);

  static generateUser = (
    id: number,
    userType = UserFixtures.employeeUserType,
  ): User => {
    return new User(
      `user${id}`,
      new Meta(),
      `user${id}`,
      'User',
      `User ${id}`,
      undefined,
      undefined,
      new ContactInfo(`user${id}@mail.com`),
      undefined,
      true,
      true,
      true,
      true,
      undefined,
      undefined,
      [],
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      [userType],
    );
  };

  static readonly clientUserType: UserType = {
    name: 'USER',
    id: 'user',
    viewName: 'Client',
  };

  static readonly employeeUserType: UserType = {
    name: 'EMPLOYEE',
    id: 'employee',
    viewName: 'Employee',
  };

  static readonly client1 = UserFixtures.generateUser(
    1,
    UserFixtures.clientUserType,
  );
  static readonly client2 = UserFixtures.generateUser(
    2,
    UserFixtures.clientUserType,
  );
  static readonly client3 = UserFixtures.generateUser(
    3,
    UserFixtures.clientUserType,
  );
  static readonly client4 = UserFixtures.generateUser(
    4,
    UserFixtures.clientUserType,
  );
  static readonly client5 = UserFixtures.generateUser(
    5,
    UserFixtures.clientUserType,
  );

  static readonly employee1 = UserFixtures.generateUser(
    1,
    UserFixtures.employeeUserType,
  );
  static readonly employee2 = UserFixtures.generateUser(
    2,
    UserFixtures.employeeUserType,
  );
  static readonly employee3 = UserFixtures.generateUser(
    3,
    UserFixtures.employeeUserType,
  );
  static readonly employee4 = UserFixtures.generateUser(
    4,
    UserFixtures.employeeUserType,
  );
  static readonly employee5 = UserFixtures.generateUser(
    5,
    UserFixtures.employeeUserType,
  );

  static readonly allClients = [
    UserFixtures.client1,
    UserFixtures.client2,
    UserFixtures.client3,
    UserFixtures.client4,
    UserFixtures.client5,
  ];

  static readonly allEmployees = [
    UserFixtures.employee1,
    UserFixtures.employee2,
    UserFixtures.employee3,
    UserFixtures.employee4,
    UserFixtures.employee5,
  ];

  static readonly allUsers = [
    ...UserFixtures.allClients,
    ...UserFixtures.allEmployees,
  ];
}
