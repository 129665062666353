<h2 mat-dialog-title>Berichtsvorschau</h2>
<div mat-dialog-content style="overflow-y: hidden">
  <mat-progress-bar
    *ngIf="loading$ | async"
    mode="indeterminate"
  ></mat-progress-bar>
  <iframe style="width: 100%; height: 600px; border: none" #iframe></iframe>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Schließen</button>
</div>
