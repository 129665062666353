import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';
import {
  NotificationConfig,
  NotificationDismissedBy,
} from '@tremaze/shared/notification/types';
import { catchError, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  _p(s: MatSnackBarRef<SimpleSnackBar>): Observable<NotificationDismissedBy> {
    return s.afterDismissed().pipe(
      map((r) => {
        if (r.dismissedByAction) {
          return 'ACTION';
        }
        return 'TIMEOUT';
      }),
    );
  }

  showDefaultErrorNotification(): Observable<NotificationDismissedBy> {
    return this._p(this.snackBar.open('Es ist ein Fehler aufgetreten'));
  }

  showNotification(
    config: NotificationConfig | string,
  ): Observable<NotificationDismissedBy> {
    if (typeof config === 'string') {
      return this._p(this.snackBar.open(config));
    } else {
      return this._p(
        this.snackBar.open(config.message, config.actionName, {
          duration: config.duration || 3000,
        }),
      );
    }
  }

  public notifyOnError<T>(
    config?: (NotificationConfig & { filterError?: boolean }) | string,
  ): MonoTypeOperatorFunction<T> {
    return (ob$: Observable<T>) =>
      ob$.pipe(
        catchError((e) => {
          if (config) {
            this.showNotification(config);
          } else {
            this.showDefaultErrorNotification();
          }
          throw e;
        }),
      );
  }
}
