import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'tremaze-data-table-chips-column',
  template: `
    <mat-chip-set *ngIf="data?.length < 2; else countDisplay" dense>
      <mat-chip *ngFor="let item of data" disableRipple highlighted>{{
        displayWith?.(item) ?? item?.name
      }}</mat-chip>
    </mat-chip-set>
    <ng-template #countDisplay
      ><span
        [matTooltip]="labelsListTooltipStringFor"
        matTooltipClass="tooltip"
        style="text-decoration: underline"
        >{{ data.length }} {{ label }}</span
      ></ng-template
    >
  `,
})
export class TremazeDataTableChipsColumnComponent<T> {
  @Input() data: T[] = [];

  @Input() label?: string;

  @Input() displayWith?: (item: T) => string;

  get labelsListTooltipStringFor(): string {
    if (this.displayWith) {
      return this.data.map((t) => this.displayWith?.(t)).join(',\n');
    }
    return '';
  }
}

@NgModule({
  imports: [CommonModule, MatTooltipModule, MatChipsModule],
  declarations: [TremazeDataTableChipsColumnComponent],
  exports: [TremazeDataTableChipsColumnComponent],
})
export class SharedUiDataTableUiChipsColModule {}
