import { SharedUiExportDataTypeSelectorModule } from '@tremaze/shared/ui/export-data-type-selector';
import { SharedUiTableAddNewItemButtonModule } from '@tremaze/shared/ui/table-add-new-item-button';
import { SharedPermissionUiDirectivesModule } from '@tremaze/shared/permission/ui/directives';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { SortedFilteredPaginatedTableComponent } from './sorted-filtered-paginated-table/sorted-filtered-paginated-table.component';
import { SharedNotificationModule } from '@tremaze/shared/notification';
import { SharedSortedFilteredPaginatedTableUiDirectivesModule } from '@tremaze/shared/sorted-filtered-paginated-table/ui/directives';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { ExpandingIconTextInputComponent } from '@tremaze/shared/ui/inputs/expanding-icon-text-input';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatPaginatorModule,
    SharedUiTableAddNewItemButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatExpansionModule,
    SharedPermissionUiDirectivesModule,
    SharedUiExportDataTypeSelectorModule,
    SharedNotificationModule,
    SharedSortedFilteredPaginatedTableUiDirectivesModule,
    MatCheckboxModule,
    MatCardModule,
    ExpandingIconTextInputComponent,
  ],
  declarations: [SortedFilteredPaginatedTableComponent],
  exports: [
    SortedFilteredPaginatedTableComponent,
    SharedSortedFilteredPaginatedTableUiDirectivesModule,
  ],
})
export class SharedSortedFilteredPaginatedTableUiModule {}
