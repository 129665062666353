<button
  type="button"
  mat-button
  color="primary"
  (click)="onClickButton()"
  [style]="
    (hasSelectedItems$ | async)
      ? 'background-color: var(--mat-stepper-header-selected-state-icon-background-color); color: var(--mat-stepper-header-selected-state-icon-foreground-color)'
      : ''
  "
>
  {{ label }} ({{ filterCount$ | async }})
  <span style="margin-left: 8px" class="lnr lnr-chevron-down"></span>
</button>
