export class Price {
  constructor(
    public value: number,
    public currency: Currency,
  ) {}

  static createEuroPrice(value: number): Price {
    return new Price(value * 100, Currency.EURO);
  }

  static deserialize(data: any): null | Price {
    return !data ? null : new Price(data.value, data.currency);
  }

  static deserializeList(data: any[]): null | Price[] {
    return !data ? null : data.map(Price.deserialize);
  }

  static findPrice(prices: Price[], currency: Currency): Price {
    return prices.find((price) => price.currency === currency);
  }

  static findPriceRepresented(prices: Price[], currency: Currency): number {
    if (!prices?.length) {
      return null;
    }
    const price = this.findPrice(prices, currency);
    return price ? price.getRepresentation() : 0;
  }

  static findEuroPrice(prices: Price[]): Price {
    return this.findPrice(prices, Currency.EURO);
  }

  static findEuroPriceRepresented(prices?: Price[]): number | null {
    if (!prices?.length) {
      return null;
    }
    return this.findPriceRepresented(prices, Currency.EURO);
  }

  getRepresentation(): number {
    switch (this.currency) {
      case Currency.EURO:
        return this.value / 100;
      case Currency.CREDITS:
        return this.value;
    }
  }
}

export enum Currency {
  EURO = 'EURO',
  CREDITS = 'CREDITS',
}
