import { Observable, of, ReplaySubject } from 'rxjs';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { Injectable } from '@angular/core';
import { InstitutionREADDataSource } from '@tremaze/shared/feature/institution/data-access';
import { catchError, map, take, tap } from 'rxjs/operators';
import { DocumentRef } from '@tremaze/shared/util/dom/document';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { Title } from '@angular/platform-browser';

export abstract class InstitutionContextService {
  abstract hasInstitution: boolean;
  abstract currentInstitution$: Observable<Institution>;
  abstract currentInstitutionId$: Observable<string>;

  abstract setCurrentInstitution(inst: Institution): void;

  abstract loadInstitutionById(
    id: string,
    force?: boolean,
  ): Observable<Institution>;
}

@Injectable({ providedIn: 'root' })
export class RemoteInstitutionContextService
  implements InstitutionContextService
{
  private lastLoadedInstitutionId: string;

  get hasInstitution(): boolean {
    return typeof this.lastLoadedInstitutionId === 'string';
  }

  constructor(
    private dataSource: InstitutionREADDataSource,
    protected document: DocumentRef,
    private title: Title,
    protected config: AppConfigService,
  ) {}

  private _currentInstitution$ = new ReplaySubject<Institution | null>(1);

  get currentInstitution$(): Observable<Institution> {
    return this._currentInstitution$.pipe(take(1));
  }

  get currentInstitutionId$(): Observable<string> {
    return this.currentInstitution$.pipe(
      map((i) => {
        if (i instanceof Institution) {
          return i.id;
        }
        return null;
      }),
    );
  }

  setCurrentInstitution(inst: Institution): void {
    this._currentInstitution$.next(inst);
    this.lastLoadedInstitutionId = inst?.id;
    if (!inst) return;
    this.title.setTitle(inst.name);
  }

  loadInstitutionById(id: string, force?: boolean): Observable<Institution> {
    if (force || id !== this.lastLoadedInstitutionId) {
      return this.dataSource.getFreshById(id).pipe(
        tap((r) => {
          if (r instanceof Institution) {
            this.lastLoadedInstitutionId = id;
            this._currentInstitution$.next(r);
          }
        }),
        catchError(() => of(null)),
      );
    }
    return this._currentInstitution$.pipe(take(1));
  }
}

export const provideInstitutionContextService = () => {
  return {
    provide: InstitutionContextService,
    useClass: RemoteInstitutionContextService,
  };
};
