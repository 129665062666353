import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, of } from 'rxjs';

const _key = 'folder-view-personal-settings';

type FolderViewPersonalSettings = {
  sort?: {
    field: string;
    direction: 'asc' | 'desc';
  };
  mixFilesAndFolders?: boolean;
  viewMode?: 'grid' | 'list';
};

@Injectable({ providedIn: 'root' })
export class FolderViewPersonalSettingsService {
  private readonly _storage = inject(Storage);

  private readonly _storedSettings$: Observable<FolderViewPersonalSettings> =
    of(JSON.parse(this._storage.getItem(_key) ?? '{}'));

  private _updatedSettings$ =
    new BehaviorSubject<FolderViewPersonalSettings | null>(null);

  readonly settings$ = combineLatest([
    this._storedSettings$,
    this._updatedSettings$,
  ]).pipe(
    map(([storedSettings, updatedSettings]) => {
      return updatedSettings ?? storedSettings;
    }),
  );

  patchSettings(settings: Partial<FolderViewPersonalSettings>) {
    this._storedSettings$.subscribe((storedSettings) => {
      const newSettings = { ...storedSettings, ...settings };
      this._updatedSettings$.next(newSettings);
      this._storage.setItem(_key, JSON.stringify(newSettings));
    });
  }

  updateSort(sort: FolderViewPersonalSettings['sort']) {
    this.patchSettings({ sort });
  }

  updateMixFilesAndFolders(
    mixFilesAndFolders: FolderViewPersonalSettings['mixFilesAndFolders'],
  ) {
    this.patchSettings({ mixFilesAndFolders });
  }

  updateViewMode(viewMode: FolderViewPersonalSettings['viewMode']) {
    this.patchSettings({ viewMode });
  }
}
