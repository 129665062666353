import { inject, Injectable, OnDestroy } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import {
  ensureObservable,
  filterNotNullOrUndefined,
  filterTrue,
} from '@tremaze/shared/util/rxjs';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  race,
  ReplaySubject,
  shareReplay,
  Subject,
  tap,
  timer,
} from 'rxjs';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { uuid } from '@tremaze/shared/util-generators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocumentEditorDocumentIsBlockedDialogComponent } from './document-is-blocked-dialog/document-editor-document-is-blocked-dialog.component';
import { TenantConfigService } from '@tremaze/shared/tenant-config';

type OutgoingMessage = {
  sessionId: string;
  message: 'BLOCKING' | 'REQUEST_STATUS' | 'STOP_BLOCKING';
};

type IncomingMessage = OutgoingMessage & { userId: string };

@Injectable()
export class DocumentEditorWebsocketService implements OnDestroy {
  private readonly _appConfigService = inject(AppConfigService);
  private readonly _tenantConfigService = inject(TenantConfigService);

  private readonly _blockingFeatureEnabled$ =
    this._tenantConfigService.isModuleEnabled('SIMULTANEOUS_EDITING_OF_FILES');

  private readonly _dialog = inject(MatDialog);
  private readonly _stomp = new RxStomp();
  private readonly _fileId$ = new BehaviorSubject<string | undefined>(
    undefined,
  );
  private readonly _authService = inject(AuthV2Service);
  private readonly _remoteMessageSink$ = new Subject<IncomingMessage>();
  private readonly _remoteBlockingMessage$ = this._remoteMessageSink$.pipe(
    filter((r) => r.message === 'BLOCKING'),
  );
  private readonly _remoteRequestStatusMessage$ = this._remoteMessageSink$.pipe(
    filter((r) => r.message === 'REQUEST_STATUS'),
  );
  private readonly _remoteUnblockingMessage$ = this._remoteMessageSink$.pipe(
    filter((r) => r.message === 'STOP_BLOCKING'),
  );
  private readonly _sessionId = uuid();
  private readonly _isBlocked$ = new ReplaySubject<boolean>(1);

  readonly requestClose$ = new Subject<void>();

  readonly isBlocked$ = this._isBlocked$.asObservable();

  private _interval?: number;

  private readonly _accessToken$ = this._authService.accessToken$.pipe(
    filterNotNullOrUndefined(),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private readonly _tenantId$ = this._authService.activeTenant$.pipe(
    map((r) => r?.id),
    filterNotNullOrUndefined(),
    distinctUntilChanged(),
    shareReplay(1),
  );

  constructor() {
    combineLatest([
      ensureObservable(this._appConfigService.basePath),
      this._accessToken$,
      this._tenantId$,
      this._blockingFeatureEnabled$,
    ])
      .pipe(
        filter(
          ([basePath, token, tenantId, blockingFeatureEnabled]) =>
            blockingFeatureEnabled,
        ),
        tap(([basePath, token, tenantId]) => {
          this._stomp.configure({
            brokerURL:
              this._appConfigService.state === 'DEV'
                ? `https://api.dev.cloud.tagea.app/app/ws`
                : basePath + 'app/ws',
            connectHeaders: {
              'X-TenantId': tenantId,
              Authorization: `Bearer ${token}`,
            },
          });
          if (!this._stomp.active) {
            this._stomp.activate();
          }
        }),
      )
      .subscribe();

    this._blockingFeatureEnabled$
      .pipe(
        tap((enabled) => {
          if (!enabled) {
            this._isBlocked$.next(false);
          }
        }),
        filterTrue(),
        tap(() => {
          this._fileId$
            .pipe(
              filterNotNullOrUndefined(),
              distinctUntilChanged(),
              tap((fileId) => {
                this._stomp
                  .watch({
                    destination: `/topic/${fileId}`,
                  })
                  .subscribe((message) => {
                    try {
                      const parsed: IncomingMessage = JSON.parse(message.body);
                      if (parsed.sessionId !== this._sessionId) {
                        this._remoteMessageSink$.next(parsed);
                      }
                    } catch (e) {
                      console.error(e);
                    }
                  });
              }),
            )
            .subscribe();

          this._isBlocked$
            .pipe(
              distinctUntilChanged(),
              tap((blocked) => {
                if (blocked) {
                  if (this._interval) {
                    window.clearInterval(this._interval);
                  }
                  this._showBlockingDialog();
                } else {
                  this._hideBlockingDialog();
                  this._beginSendingBlockingMessages();
                }
              }),
            )
            .subscribe();

          this._remoteRequestStatusMessage$
            .pipe(
              tap(() => {
                this._sendBlockingMessage();
              }),
            )
            .subscribe();

          this._isInitiallyBlocked()
            .pipe(
              tap((isBlocked) => {
                this._isBlocked$.next(isBlocked);
              }),
            )
            .subscribe();

          this._remoteBlockingMessage$
            .pipe(
              tap(() => {
                this._isBlocked$.next(true);
              }),
            )
            .subscribe();

          merge(
            this._remoteBlockingMessage$.pipe(debounceTime(30000)),
            this._remoteUnblockingMessage$,
          )
            .pipe(
              tap(() => {
                this._isBlocked$.next(false);
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._sendMessage('STOP_BLOCKING');
    this._stomp.deactivate();
    this._isBlocked$.complete();
    this._fileId$.complete();
    this._remoteMessageSink$.complete();
    this.requestClose$.complete();

    if (this._interval) {
      window.clearInterval(this._interval);
    }
  }

  private _isInitiallyBlocked() {
    this._sendMessage('REQUEST_STATUS');
    return race([
      timer(1000).pipe(map(() => false)),
      this._remoteBlockingMessage$.pipe(map(() => true)),
    ]);
  }

  private _beginSendingBlockingMessages() {
    if (this._interval) {
      window.clearInterval(this._interval);
    }
    this._interval = window.setInterval(
      () => this._sendBlockingMessage(),
      1000,
    );
  }

  private _sendBlockingMessage() {
    this._sendMessage('BLOCKING');
  }

  private _sendMessage(message: OutgoingMessage['message']) {
    this._stomp.publish({
      destination: `/tremaze/topic/${this._fileId$.value}/isBlocked`,
      body: JSON.stringify({
        sessionId: this._sessionId,
        message,
      }),
    });
  }

  setFileId(value: string | undefined) {
    this._fileId$.next(value);
  }

  private _dialogRef?: MatDialogRef<DocumentEditorDocumentIsBlockedDialogComponent>;

  private _showBlockingDialog(): void {
    this._dialogRef = this._dialog.open(
      DocumentEditorDocumentIsBlockedDialogComponent,
      {
        maxWidth: '400px',
        disableClose: true,
      },
    );

    this._dialogRef
      .afterClosed()
      .pipe(
        tap((result) => {
          if (result === 'ABORT') {
            this.requestClose$.next();
          }
          this._dialogRef = undefined;
        }),
      )
      .subscribe();
  }

  private _hideBlockingDialog() {
    if (this._dialogRef) {
      this._dialogRef.close();
    }
  }
}
