import { Deserializable, staticImplements } from '@tremaze/shared/util-decorators';
import { Address } from '@tremaze/shared/models';

/**
 * CostBearer class
 * @description
 * Represents a cost bearer entity
 *
 */
@staticImplements<Deserializable<CostBearer>>()
export class CostBearer {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly shortName: string,
    readonly address?: Address,
  ) {}

  static deserialize(input: any): CostBearer {
    return new CostBearer(
      input.id,
      input.name,
      input.shortName,
      input.address ? Address.deserialize(input.address) : undefined,
    );
  }
}
