import { Country, getCountryByCode } from '@tremaze/shared/models';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

export type HousingSituation = 'RENTED' | 'OWNED' | 'OTHER';
export type EmploymentStatus =
  | 'SELF_EMPLOYED'
  | 'EMPLOYED'
  | 'GFB'
  | 'PENSION'
  | 'EARLY_RETIREMENT'
  | 'INCOME_SUPPORT'
  | 'ACCIDENT_PENSION'
  | 'SGB_II'
  | 'SGB_III'
  | 'SGB_XII'
  | 'ASYLUM_SEEKERS_BENEFITS_ACT'
  | 'OTHER';
export type MartialStatus = 'SINGLE' | 'MARRIED' | 'OTHER';
export type EducationalQualification =
  | 'NO_EDUCATION'
  | 'SECONDARY_SCHOOL'
  | 'MIDDLE_SCHOOL'
  | 'TECHNICAL_COLLEGE_ENTRANCE_QUALIFICATION'
  | 'HIGH_SCHOOL'
  | 'BACHELOR'
  | 'MASTER'
  | 'PHD'
  | 'OTHER';

@staticImplements<Deserializable<UserAdditionalInfo>>()
export class UserAdditionalInfo {
  constructor(
    readonly nationality?: Country,
    readonly housingSituation?: HousingSituation,
    readonly employmentStatus?: EmploymentStatus,
    readonly maritalStatus?: MartialStatus,
    readonly educationalQualification?: EducationalQualification,
    readonly amountHouseholdMembers: number = 1,
    readonly healthData: string = '',
  ) {}

  static deserialize(input: any): UserAdditionalInfo {
    return new UserAdditionalInfo(
      input?.nationality ? getCountryByCode(input.nationality) : undefined,
      input?.housingSituation,
      input?.employmentStatus,
      input?.maritalStatus,
      input?.educationalQualification,
      input?.amountHouseholdMembers,
      input?.healthData,
    );
  }
}
