import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { DropZoneDirective } from '@tremaze/drag-zone';
import { FileDropInDropZoneDirective } from '@tremaze/file-drop-in';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { normalizePath } from '@tremaze/shared/util/string';

type Breadcrumb = {
  label: string;
  path: string;
};

@Component({
  selector:
    'nav[tremaze-folder-view-breadcrumbs], tremaze-folder-view-breadcrumbs',
  standalone: true,
  imports: [
    CommonModule,
    MatButton,
    DropZoneDirective,
    FileDropInDropZoneDirective,
    IconComponent,
  ],
  template: `
    <ol class="folder-view-breadcrumbs__List">
      @for (crumb of breadcrumbs; track crumb.path; let last = $last) {
        <li class="folder-view-breadcrumbs__List-Item">
          <button
            class="folder-view-breadcrumbs__List-Item__Button"
            (click)="onCrumbClick(crumb.path)"
            tremazeDropZone
            tremazeFileDropInDropZone
            mat-button
            (filesDropped)="
              filesDropped.emit({ path: crumb.path, files: $event })
            "
            (dropped)="dataDropped.emit({ path: crumb.path, data: $event })"
          >
            {{ crumb.label }}
          </button>
          @if (!last) {
            <tremaze-icon icon="lnr-chevron-right"></tremaze-icon>
          }
        </li>
      }
    </ol>
  `,
  styleUrl: './folder-view-breadcrumbs.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-breadcrumbs',
    ariaLabel: 'Breadcrumb',
  },
})
export class FolderViewBreadcrumbsComponent {
  @Input({ required: true }) path?: string;
  @Input({ required: true }) viewPath?: string;

  @Output() navigate = new EventEmitter<string>();
  @Output() readonly dataDropped = new EventEmitter<{
    path: string;
    data: unknown[];
  }>();
  @Output() readonly filesDropped = new EventEmitter<{
    path: string;
    files: File[];
  }>();

  get breadcrumbs(): Breadcrumb[] {
    let path = this.path;
    if (!path || !this.viewPath) {
      return [];
    }

    if (path === '/') {
      return [{ label: 'Home', path }];
    }

    const normalizedPath = normalizePath(path, {
      withTrailingSlash: false,
      withLeadingSlash: true,
    });
    const normalizedViewPath = normalizePath(this.viewPath, {
      withTrailingSlash: false,
      withLeadingSlash: true,
    });

    const pathParts = normalizedPath.split('/');
    const viewPathParts = normalizedViewPath.split('/');

    if (pathParts.length < viewPathParts.length) {
      return [];
    }

    return path.split('/').map((part, index, parts) => {
      const path = parts.slice(0, index + 1).join('/');
      if (!part.length || index === 0) {
        return { label: 'Home', path: '/' };
      }
      return { label: viewPathParts[index].trim(), path };
    });
  }

  onCrumbClick(path: string) {
    this.navigate.emit(path);
  }
}
