import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { firstValueFrom, Subject, take, tap } from 'rxjs';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { simpleFadeInOutAnimation } from '../../../../animation/angular/simple/simple-animations';
import { AppStateService } from '@tremaze/shared/util-app-state';
import { FileSelectorService } from '@tremaze/shared/feature/file-storage/ui/file-selector';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';
import tinymce, { EditorOptions } from 'tinymce';
import { AppConfigService } from '@tremaze/shared/util-app-config';

export const DEFAULT_TINY_CONFIG: Partial<EditorOptions> = {
  base_url: '/tinymce',
  suffix: '.min',
  height: '100%',
  menubar: 'file edit view insert custom format tools table help',
  contextmenu: [],
  plugins: [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'preview',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'code',
    'help',
    'wordcount',
  ],
  entity_encoding: 'raw',
  promotion: false,
  toolbar:
    'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help',
  language: 'de',
  menu: {
    custom: { title: 'Einfügen aus...', items: 'filestorage' },
  },
};

@Component({
  selector: 'tremaze-rich-text-editor',
  template: `
    <editor
      apiKey="vt57c4mn9z6i9mhe953ef7m3x32wydty1vbbg30izv0rep82"
      [(ngModel)]="value"
      *ngIf="show"
      [@simpleFadeInOut]
      (ngModelChange)="onChange()"
      [disabled]="disabled"
      [init]="tinymceInit"
    ></editor>
  `,
  styles: [
    `
      :host {
        display: contents;
      }

      editor {
        min-height: 100% !important;
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    `,
  ],
  animations: [simpleFadeInOutAnimation],
  providers: [
    { provide: MatFormFieldControl, useExisting: RichTextEditorComponent },
  ],
})
export class RichTextEditorComponent
  implements
    OnInit,
    DoCheck,
    OnDestroy,
    AfterViewInit,
    MatFormFieldControl<string>,
    ControlValueAccessor
{
  static nextId = 0;
  tinymceInit: Partial<EditorOptions>;
  @HostBinding() id = `rich-text-editor-${RichTextEditorComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  errorState: boolean;
  focused: boolean;
  stateChanges = new Subject<void>();
  show = false;
  @ViewChild(EditorComponent, { static: true })
  private _input: EditorComponent;

  constructor(
    private el: ElementRef,
    private cdRef: ChangeDetectorRef,
    private appStateService: AppStateService,
    private readonly _fileSelectorService: FileSelectorService,
    private readonly _fileStorageService: FileStorageService,
    private readonly _appConfigService: AppConfigService,
    private ngZone: NgZone,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _disabled: boolean;

  @Input()
  get disabled(): boolean {
    return this.ngControl ? this.ngControl.disabled : this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _required: boolean;

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next(null);
  }

  private _placeholder: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next(null);
  }

  private _value: string | null;

  get value(): string | null {
    return this._value;
  }

  set value(value: string | null) {
    this._value = value;
    this.stateChanges.next(null);
    this.onChangeCallback(this.value);
  }

  get empty() {
    return !this.value;
  }

  get shouldLabelFloat() {
    return true;
  }

  private _selectAndInsertFileFromFileStorage(editor) {
    this.ngZone.run(() =>
      this._fileSelectorService
        .selectFiles({
          fileTypeMatcher: new RegExp('(image\\/.+)|(video\\/.+)'),
        })
        .pipe(
          tap(async (files) => {
            if (files?.length) {
              const file = files[0];
              const url = await firstValueFrom(
                this._fileStorageService.getFileDownloadURL(file, 1920),
              );
              if (file.type === 'IMAGE') {
                const html = `&nbsp;<img src="${url}">&nbsp;`;
                editor.insertContent(html);
              } else if (file.type === 'VIDEO') {
                const html = `&nbsp;<video controls="controls" width="300" height="150"><source src="${url}"></video>&nbsp;`;
                editor.insertContent(html);
              }
            }
          }),
        )
        .subscribe(),
    );
  }

  ngOnInit() {
    this.tinymceInit = {
      ...DEFAULT_TINY_CONFIG,
      cache_suffix: `?v=${this._appConfigService.version}`,
      setup: (editor) => {
        editor.ui.registry.addMenuItem('filestorage', {
          text: 'Dateiablage',
          onAction: () => this._selectAndInsertFileFromFileStorage(editor),
        });
        editor.on('ObjectResized', function (e) {
          const target: HTMLImageElement = e.target;
          if (target && target.src) {
            const url = new URL(target.src.replace('&amp;', '&'));
            if (url && url.searchParams?.has('width')) {
              url.searchParams.set('width', e.width.toString());
              tinymce.activeEditor.dom.setAttrib(target, 'src', url.toString());
            }
          }
        });
      },
    };
    this.appStateService.darkMode$
      .pipe(
        take(1),
        tap((darkMode) => {
          if (darkMode) {
            this.tinymceInit = {
              ...this.tinymceInit,
              skin: 'oxide-dark',
              content_css: ['dark'],
            };
          }
        }),
      )
      .subscribe();
  }

  onChange() {
    this.onChangeCallback(this.value);
    this.onTouchedCallback();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next(null);
    }
  }

  ngAfterViewInit() {
    this.show = true;
    this.cdRef.detectChanges();
    const nE = this.el?.nativeElement;
    if (nE instanceof HTMLElement) {
      const parent = nE.parentElement;
      if (parent?.classList.contains('mat-form-field-infix')) {
        parent.style.paddingBottom = '0';
      }
    }
  }

  onContainerClick(event: MouseEvent): void {}

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  writeValue(obj: any): void {
    if (this._value !== obj) {
      this._value = obj;
    }
  }

  private onTouchedCallback: () => void = () => {};

  private onChangeCallback: (_: any) => void = () => {};
}
