import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  transform(
    value?: FullNameObj,
    fallback?: string | false,
    lastNameFirst?: boolean,
  ): string {
    if (value?.fullName && !lastNameFirst) {
      return value.fullName;
    }
    const firstName = value?.firstName ?? value?.firstname ?? '';
    const lastName = value?.lastName ?? value?.lastname ?? '';
    if (!firstName?.length && !lastName?.length) {
      if (fallback && typeof fallback === 'string') {
        return fallback;
      }
      return value?.username ?? '';
    }
    return !lastNameFirst
      ? [firstName, lastName].join(' ')
      : [lastName, firstName].join(', ');
  }
}

interface FullNameObj {
  firstName?: string;
  firstname?: string;
  lastName?: string;
  lastname?: string;
  username?: string;
  fullName?: string;
}
