import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Output,
} from '@angular/core';

@Directive({
  selector: 'tremazeExpandableFabItem, [tremazeExpandableFabItem]',
  standalone: true,
})
export class ExpandableFabItemDirective {
  readonly elementRef = inject(ElementRef);

  @Output() clicked = new EventEmitter<void>();

  @HostListener('click')
  onClick() {
    this.clicked.emit();
  }
}
