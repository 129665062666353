import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DataSourceMethodsPaginatedOptions,
  DefaultDataSourceMethods,
} from '@tremaze/shared/util-http';
import { CostBearer } from '@tremaze/shared/feature/approval/types';
import { map, Observable, switchMap } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { CostBearerDataSource } from '../cost-bearer-data-source';

@Injectable({
  providedIn: 'root',
})
export class RemoteCostBearerDataSourceImpl implements CostBearerDataSource {
  constructor(
    private readonly _http: HttpClient,
    private readonly _js: JsonSerializer,
  ) {}

  getCostBearerByName(instId: string, name: string): Observable<CostBearer> {
    return this.getPaginatedCostBearers(instId, {
      filter: { filterValue: name, filterFields: ['NAME'] },
    }).pipe(
      map(
        (r) =>
          r.content.find((a) => a.name.toLowerCase() === name.toLowerCase())!,
      ),
      switchMap((r) => this.getCostBearerById(r.id)),
    );
  }

  createCostBearer(
    costBearer: CostBearer,
    institutionId: string,
  ): Observable<CostBearer> {
    const payload = { ...costBearer } as any;
    delete payload.id;
    return DefaultDataSourceMethods.create<CostBearer>(
      this._http,
      `institutions/${institutionId}/costBearers`,
      CostBearer.deserialize,
      this._js,
      payload,
    );
  }

  updateCostBearer(costBearer: CostBearer): Observable<CostBearer> {
    const payload = { ...costBearer } as any;
    delete payload.id;
    return DefaultDataSourceMethods.edit(
      this._http,
      `costBearers/${costBearer.id}`,
      CostBearer.deserialize,
      this._js,
      payload,
    );
  }

  deleteCostBearer(id: string): Observable<boolean> {
    return DefaultDataSourceMethods.deleteById(this._http, `costBearers`, id);
  }

  getCostBearerById(id: string): Observable<CostBearer> {
    return DefaultDataSourceMethods.getFreshById(
      this._http,
      `costBearers`,
      CostBearer.deserialize,
      id,
    );
  }

  getPaginatedCostBearers(
    instId: string,
    options: DataSourceMethodsPaginatedOptions<any>,
  ): Observable<Pagination<CostBearer>> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      `institutions/${instId}/costBearers`,
      CostBearer.deserialize,
      {
        ...options,
        filter: {
          ...options?.filter,
          filterFields: options?.filter?.filterFields ?? ['SHORT_NAME', 'NAME'],
        },
      },
    );
  }
}

export const provideRemoteCostBearerDataSource = () => ({
  provide: CostBearerDataSource,
  useClass: RemoteCostBearerDataSourceImpl,
});
