import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { User } from '@tremaze/shared/feature/user/types';
import { CostBearer } from './cost-bearer';
import { DateRange, TremazeDate } from '@tremaze/shared/util-date';
import { PartialBudget } from './partial-budget';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

export type ApprovalStatus = 'REQUESTED' | 'APPROVED' | 'REJECTED' | 'EXPIRED';

export const approvalStatuses: ApprovalStatus[] = [
  'REQUESTED',
  'APPROVED',
  'REJECTED',
  'EXPIRED',
];

export const approvalStatusLabels: Record<ApprovalStatus, string> = {
  REQUESTED: 'Angefragt',
  APPROVED: 'Bewilligt',
  REJECTED: 'Abgelehnt',
  EXPIRED: 'Abgelaufen',
};

@staticImplements<Deserializable<Approval>>()
export class Approval {
  constructor(
    readonly id: string,
    readonly institution: Institution,
    readonly client: User,
    readonly costBearer?: CostBearer,
    readonly fileNumber?: string,
    readonly approvedAt?: TremazeDate,
    readonly approvedFrom?: TremazeDate,
    readonly approvedUntil?: TremazeDate,
    readonly requestedAt?: TremazeDate,
    readonly partialBudgets?: PartialBudget[],
    readonly applicationFile?: FileStorage,
    readonly approvalFile?: FileStorage,
    readonly status: ApprovalStatus = 'REQUESTED',
    private readonly _totalBudget?: number,
    readonly remainingPlannedBudget = 0,
    readonly remainingActualBudget = 0,
    readonly vivendiId?: string,
  ) {}

  static deserialize(input: any): Approval {
    return new Approval(
      input.id,
      Institution.deserialize(input.institution),
      User.deserialize(input.client)!,
      input.costBearer ? CostBearer.deserialize(input.costBearer) : undefined,
      input.fileNumber,
      TremazeDate.deserialize(input.approvedAt) ?? undefined,
      TremazeDate.deserialize(input.approvedFrom) ?? undefined,
      TremazeDate.deserialize(input.approvedUntil) ?? undefined,
      TremazeDate.deserialize(input.requestedAt) ?? undefined,
      (input.partialBudgets as unknown[])
        ?.map((partialBudget: any) =>
          partialBudget instanceof PartialBudget
            ? partialBudget
            : PartialBudget.deserialize(partialBudget),
        )
        .sort((a, b) =>
          a.approvalService.shortName.localeCompare(
            b.approvalService.shortName,
          ),
        ),
      FileStorage.deserialize(input.applicationFile) ?? undefined,
      FileStorage.deserialize(input.approvalFile) ?? undefined,
      input.status,
      input.totalBudget ?? undefined,
      input.remainingPlannedBudget ?? undefined,
      input.remainingActualBudget ?? undefined,
      input.vivendiId,
    );
  }

  get totalBudget(): number {
    if (this._totalBudget) {
      return this._totalBudget;
    }
    let totalBudget = 0;
    for (const partialBudget of this.partialBudgets ?? []) {
      totalBudget += partialBudget.totalBudget;
    }
    return totalBudget;
  }

  get hasApprovalPeriod(): boolean {
    return !!this.approvedFrom && !!this.approvedUntil;
  }

  get approvalPeriod(): DateRange {
    return [this.approvedFrom!, this.approvedUntil!];
  }

  findPartialBudgetByApprovalServiceId(
    approvalServiceId: string,
  ): PartialBudget | undefined {
    return this.partialBudgets?.find(
      (partialBudget) =>
        partialBudget.approvalService.id === approvalServiceId ||
        partialBudget.approvalService.subApprovalServices?.some(
          (subApprovalService) => subApprovalService.id === approvalServiceId,
        ),
    );
  }

  isDateWithinApprovalPeriod(date: TremazeDate): boolean {
    return (
      (!this.approvedFrom ||
        date >= this.approvedFrom.clone().startOf('day')) &&
      (!this.approvedUntil || date <= this.approvedUntil.clone().endOf('day'))
    );
  }
}
