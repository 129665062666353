import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
} from '@angular/core';
import { SelectZoneItemDirective } from './select-zone-item.directive';
import { clearAllTextSelection } from '@tremaze/shared/util-utilities';

@Directive({
  selector: '[tremazeSelectZone]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'select-zone',
  },
})
export class SelectZoneDirective {
  private readonly _elementRef = inject(ElementRef);

  private _items: { [key: number]: SelectZoneItemDirective<unknown> } = {};

  private _dragStartCoords: { x: number; y: number } | null = null;
  private _isDragging = false;
  private _selectionBox: HTMLDivElement | null = null;
  private _userSelect: string | null = null;
  private _selectionData: unknown[] = [];

  @Input() disabled = false;

  @Output() readonly selectionStart = new EventEmitter<void>();
  @Output() readonly selectionEnd = new EventEmitter<void>();
  @Output() readonly selectionChange = new EventEmitter<unknown[]>();

  private _preventTextSelection() {
    this._userSelect = document.body.style.userSelect;
    document.body.style.userSelect = 'none';
  }

  private _allowTextSelection() {
    document.body.style.userSelect = this._userSelect ?? '';
  }

  registerItem(id: number, item: SelectZoneItemDirective<unknown>) {
    this._items[id] = item;
  }

  unregisterItem(id: number) {
    delete this._items[id];
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (this.disabled) {
      return;
    }
    this._dragStartCoords = { x: event.clientX, y: event.clientY };
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this._dragStartCoords = null;
    this._isDragging = false;
    if (this._selectionBox) {
      this.selectionEnd.emit();
      event.stopPropagation();
      document.body.removeChild(this._selectionBox);
      this._selectionBox = null;
      this._allowTextSelection();
    }
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.disabled) {
      return;
    }
    if (this._isDragging) {
      if (this._dragStartCoords) {
        const currentCoords = { x: event.clientX, y: event.clientY };
        this._drawSelectionBox(this._dragStartCoords, currentCoords);
        this._reportSelectionChanges();
      }
    } else {
      // start dragging if the mouse moved more than 5px
      if (
        this._dragStartCoords &&
        Math.abs(event.clientX - this._dragStartCoords.x) > 5 &&
        Math.abs(event.clientY - this._dragStartCoords.y) > 5
      ) {
        clearAllTextSelection();
        this._isDragging = true;
        this._preventTextSelection();
        this.selectionStart.emit();
      }
    }
  }

  private _drawSelectionBox(
    startCoords: { x: number; y: number },
    endCoords: { x: number; y: number },
  ) {
    // make sure the selection box never leaves the host element
    const hostElement = this._elementRef.nativeElement;
    const hostBox = hostElement.getBoundingClientRect();
    endCoords.x = Math.min(Math.max(endCoords.x, hostBox.left), hostBox.right);
    endCoords.y = Math.min(Math.max(endCoords.y, hostBox.top), hostBox.bottom);

    if (!this._selectionBox) {
      this._selectionBox = document.createElement('div');
      this._selectionBox.className = 'select-zone-selection-box';
      document.body.appendChild(this._selectionBox);
    }
    const selectionBox = this._selectionBox;
    selectionBox.style.position = 'absolute';
    selectionBox.style.left = `${Math.min(startCoords.x, endCoords.x)}px`;
    selectionBox.style.top = `${Math.min(startCoords.y, endCoords.y)}px`;
    selectionBox.style.width = `${Math.abs(endCoords.x - startCoords.x)}px`;
    selectionBox.style.height = `${Math.abs(endCoords.y - startCoords.y)}px`;
  }

  private _itemCollidesWithSelection(item: SelectZoneItemDirective<unknown>) {
    if (!this._selectionBox) {
      return false;
    }

    const selectionBox = this._selectionBox.getBoundingClientRect();
    const itemBox = item.elementRef.nativeElement.getBoundingClientRect();

    return (
      selectionBox.left < itemBox.right &&
      selectionBox.right > itemBox.left &&
      selectionBox.top < itemBox.bottom &&
      selectionBox.bottom > itemBox.top
    );
  }

  private _reportSelectionChanges() {
    const selected: unknown[] = [];
    let selectionChanged = false;
    Object.values(this._items).forEach((item, i) => {
      const isSelected =
        !item.selectionDisabled && this._itemCollidesWithSelection(item);
      if (isSelected) {
        selected.push(item.selectionData);
      }

      if (
        i >= this._selectionData.length ||
        this._selectionData[i] !== isSelected
      ) {
        selectionChanged = true;
      }

      item.selected = isSelected;
    });
    this._selectionData = selected;
    if (selectionChanged) {
      this.selectionChange.emit(selected);
    }
  }
}
