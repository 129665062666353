<div
  [class.disabled]="disabled"
  [tabindex]="disabled ? -1 : 0"
  class="file-selector"
>
  <div
    (click)="onClickSelectFromFileStorage()"
    *ngIf="empty; else notEmpty"
    class="file-selector__empty"
  >
    <div class="file-selector__overlay"></div>
  </div>
  <ng-template #notEmpty>
    <div (click)="onClickSelectFromFileStorage()" class="file-selector__value">
      <button
        (click)="onClickMagnifierButton($event)"
        *ngIf="canShowPreview"
        class="file-selector__value__magnifier"
        color="accent"
        mat-mini-fab
      >
        <mat-icon>
          <span class="lnr lnr-zoom-in"></span>
        </mat-icon>
      </button>
      <div class="file-selector__overlay"></div>
      <tremaze-single-file-display
        (aspectRatioUpdated)="onAspectRatioUpdated($event)"
        [file]="value"
        [systemDirType]="systemDirType"
        [targetWidth]="250"
      ></tremaze-single-file-display>
    </div>
  </ng-template>
  <div class="file-selector__actions">
    <ng-container *tremazeHasPermission="permissionRequest">
      <button
        (click)="onClickSelectFromFileStorage()"
        *ngIf="
          (hasViewFileStoragePermission$ | async) && canSelectFromFileStorage
        "
        [disabled]="disabled"
        color="primary"
        mat-icon-button
        matTooltip="Aus der Dateiablage wählen"
      >
        <span class="lnr lnr-cloud"></span>
      </button>
      <button
        (click)="fileUploadInput.click()"
        *ngIf="(hasUploadPermission$ | async) && canUpload"
        [disabled]="disabled"
        color="primary"
        mat-icon-button
        matTooltip="Hochladen"
      >
        <span class="lnr-upload lnr"></span>
      </button>
      <button
        (click)="onClickCrop()"
        *ngIf="(hasUploadPermission$ | async) && canUpload"
        [disabled]="empty || disabled"
        color="primary"
        mat-icon-button
        matTooltip="Zuschneiden"
      >
        <span class="lnr lnr-crop"></span>
      </button>
      <button
        (click)="onClickDeleteButton()"
        [disabled]="disabled || empty"
        color="primary"
        mat-icon-button
        matTooltip="Entfernen"
      >
        <span class="lnr lnr-trash2"></span>
      </button>
      <div class="spacer"></div>
      <button
        (click)="onClickCrop()"
        *ngIf="
          incorrectAspectRatio && (hasUploadPermission$ | async) && canUpload
        "
        [disabled]="empty || disabled"
        color="accent"
        mat-icon-button
        matTooltip="Inkorrektes Seitenverhältnis. Das Bild könnte abgeschnitten werden."
      >
        <span class="lnr lnr-warning"></span>
      </button>
    </ng-container>
  </div>
  <input
    #fileUploadInput
    [accept]="fileInputAccept"
    hidden
    multiple
    type="file"
  />
</div>
