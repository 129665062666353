import { inject, Injectable } from '@angular/core';
import { BackgroundTaskService } from '@tremaze/shared/ui/progress-display';
import { FolderViewDataSource } from '@tremaze/shared/feature/file-storage/data-access';
import {
  catchError,
  finalize,
  map,
  Observable,
  Subject,
  switchMap,
  take,
} from 'rxjs';
import { Directory } from '@microsoft/microsoft-graph-types';
import { NotificationService } from '@tremaze/shared/notification';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';
import { FolderViewEventsService } from './folder-view-events.service';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

@Injectable()
export class FolderViewUploadService {
  private readonly _backgroundTaskService = inject(BackgroundTaskService);
  private readonly _dataSource = inject(FolderViewDataSource);
  private readonly _fileStorageService = inject(FileStorageService);
  private readonly _notificationService = inject(NotificationService);
  private readonly _eventsService = inject(FolderViewEventsService);

  uploadFilesToPath(files: File[], absolutePath: string) {
    if (files.length === 0) {
      return;
    }

    const progress$ = new Subject<number>();
    const taskName =
      files.length > 1
        ? 'Mehrere Dateien hochladen'
        : `${files[0].name} hochladen`;

    this._backgroundTaskService.registerTask({
      name: taskName,
      type: 'UPLOAD',
      progress$: progress$,
    });

    this._dataSource
      .uploadFilesToPath({
        files,
        absolutePath,
        onProgress: (progress) => progress$.next(progress),
      })
      .pipe(
        catchError((e) => {
          this._notificationService.showNotification(
            'Fehler beim Hochladen der Dateien',
          );
          progress$.error(e);
          return [];
        }),
        finalize(() => {
          progress$.complete();
        }),
      )
      .subscribe((r) => {
        this._notificationService.showNotification('Dateien hochgeladen');
        this._eventsService.refreshCurrentFiles$.next();
      });
  }

  uploadFilesToCurrentDirectory(
    files: File[],
    currentDirectory$: Observable<Directory>,
  ) {
    if (files.length === 0) {
      return;
    }

    const progress$ = new Subject<number>();
    const taskName =
      files.length > 1
        ? 'Mehrere Dateien hochladen'
        : `${files[0].name} hochladen`;

    this._backgroundTaskService.registerTask({
      name: taskName,
      type: 'UPLOAD',
      progress$: progress$,
    });

    currentDirectory$
      .pipe(
        take(1),
        map((dir) => dir.id),
        filterNotNullOrUndefined(),
        switchMap((id) =>
          this._dataSource
            .uploadFilesToDirectory({
              files: Array.from(files),
              destinationDirId: id,
              onProgress: (progress) => progress$.next(progress),
            })
            .pipe(
              catchError((e) => {
                this._notificationService.showNotification(
                  'Fehler beim Hochladen der Dateien',
                );
                progress$.error(e);
                return [];
              }),
              finalize(() => {
                progress$.complete();
              }),
            ),
        ),
      )
      .subscribe((r) => {
        this._notificationService.showNotification('Dateien hochgeladen');
        this._eventsService.refreshCurrentFiles$.next();
      });
  }

  overwriteFile(
    fileId: string,
    file: File,
    cfg?: { silent: boolean },
  ): Observable<FileStorage> {
    const ob$ = this._fileStorageService.overwriteFile(fileId, file, cfg);
    ob$.subscribe(() => {
      if (!cfg?.silent) {
        this._notificationService.showNotification('Datei überschrieben');
        this._eventsService.refreshCurrentFiles$.next();
      }
    });
    return ob$;
  }
}
