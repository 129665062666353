import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickerPopupComponent } from './picker-popup/picker-popup.component';
import { PickerPopupDirective } from './picker-popup.directive';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SharedFeatureUserUiUserListItemComponent } from '@tremaze/shared/feature/user/ui/user-list-item';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedFileStorageUiCircleAvatarModule } from '@tremaze/shared/feature/file-storage/ui/circle-avatar';
import { MatListModule } from '@angular/material/list';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatInputModule,
    MatAutocompleteModule,
    SharedFeatureUserUiUserListItemComponent,
    MatProgressSpinnerModule,
    SharedFileStorageUiCircleAvatarModule,
    MatListModule,
  ],
  declarations: [PickerPopupComponent, PickerPopupDirective],
  exports: [PickerPopupDirective],
})
export class PickerPopupModule {}
