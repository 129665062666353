import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EventCancellationReasonSettingDialogComponent } from './event-cancellation-reason-setting-dialog/event-cancellation-reason-setting-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class EventCancellationSettingServiceService {
  private readonly _dialog = inject(MatDialog);

  open(eventId: string): Observable<{ changed: boolean }> {
    return this._dialog
      .open(EventCancellationReasonSettingDialogComponent, {
        data: { eventId },
        disableClose: true,
      })
      .afterClosed();
  }
}
