import {
  booleanAttribute,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { EventTemplate } from '@tremaze/shared/feature/event/types';
import { DefaultRemoteEventTemplateDataSource } from '@tremaze/shared/feature/event/template/data-access';
import { ActivatedRoute, Router } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { EventStateService } from '@tremaze/shared/feature/event';
import { of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { TzPermissionRequest } from '@tremaze/shared/permission/types';
import {
  DataTableActionsService,
  DataTableComponent,
  DataTableDataSource,
} from '@tremaze/shared/ui/data-table';
import { DataTablePermissionConfig } from '@tremaze/shared/ui/data-table/types';
import { SelectionChange } from '@angular/cdk/collections';
import { EventTemplateDataTableFilterService } from './event-template-data-table-filter.service';
import { EventTemplateDataTableActionsService } from './actions.service';
import { EventPresetSelectionService } from '@tremaze/shared/feature/event/feature/event-preset-selection';
import { TenantConfigService } from '@tremaze/shared/tenant-config';
import { TremazeEventPrivilegeChecks } from '@tremaze/shared/feature/event/util/privilege-checks';

@Component({
  selector: 'tremaze-event-template-data-table',
  templateUrl: './data-table.component.html',
  styles: [':host {display: contents}'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    EventTemplateDataTableFilterService,
    {
      provide: DataTableDataSource,
      useExisting: DefaultRemoteEventTemplateDataSource,
    },
    {
      provide: DataTableActionsService,
      useClass: EventTemplateDataTableActionsService,
    },
  ],
})
export class EventTemplateDataTableComponent implements OnInit, OnDestroy {
  private readonly _presetSelectionService = inject(
    EventPresetSelectionService,
  );
  private readonly _tenantConfigService = inject(TenantConfigService);

  readonly permissionConfig: DataTablePermissionConfig<EventTemplate> = {
    createPermissions: {
      gPerms: 'EVENT_WRITE',
      iPerms: {
        instIds: 'ANY',
        perms: [
          ['EVENT_WRITE'],
          ['EVENT_WRITE_DEPARTMENT'],
          ['EVENT_WRITE_REFERENCE_CLIENT'],
        ],
      },
    },
    getEditPermissionsForRow: ({ assignedInstIds }) => ({
      gPerms: 'EVENT_WRITE',
      iPerms: [{ instIds: assignedInstIds, perms: 'EVENT_WRITE' }],
    }),
    getDeletePermissionsForRow: ({ assignedInstIds }) => ({
      gPerms: 'EVENT_DELETE',
      iPerms: [{ instIds: assignedInstIds, perms: 'EVENT_DELETE' }],
    }),
  };

  readonly filterService = inject(EventTemplateDataTableFilterService);
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _eventStateService = inject(EventStateService, {
    optional: true,
  });

  readonly displayWithNameFn = (institution: { name: string }) =>
    institution.name;

  private readonly _destroyed$ = new Subject<void>();

  private _hidePlanWithRowAction = false;

  @Input('hide-plan-with-row-action')
  get hidePlanWithRowAction(): boolean {
    return this._hidePlanWithRowAction;
  }

  set hidePlanWithRowAction(value: boolean) {
    this._hidePlanWithRowAction = coerceBooleanProperty(value);
  }

  private _enableSelection = false;

  @Input({ transform: booleanAttribute })
  get enableSelection(): boolean {
    return this._enableSelection;
  }

  set enableSelection(value: boolean | '') {
    this._enableSelection = coerceBooleanProperty(value);
  }

  @Output() selectionChange = new EventEmitter<EventTemplate[]>();

  @ViewChild(DataTableComponent, { static: true })
  table?: DataTableComponent<EventTemplate>;

  get planEventPermissionRequest(): TzPermissionRequest {
    return TremazeEventPrivilegeChecks.createPrivilegeRequest;
  }

  ngOnInit() {
    if (this._eventStateService) {
      this._eventStateService.reloadTemplates$
        .pipe(takeUntil(this._destroyed$))
        .subscribe(() => this.table.reload());
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onSelectionChange(event: SelectionChange<EventTemplate>) {
    this.selectionChange.emit(event.added);
  }

  private _selectPreset() {
    return this._presetSelectionService.selectPreset();
  }

  onClickPlanWithButton(template: EventTemplate) {
    this._tenantConfigService.eventPresetsEnabled$
      .pipe(
        take(1),
        switchMap((enable) => (enable ? this._selectPreset() : of(undefined))),
        tap((preset) => {
          const queryParams = preset ? { preset } : {};
          this._router.navigate(['create'], {
            relativeTo: this._route.parent.parent,
            queryParams: { ...queryParams, templateId: template.id },
          });
        }),
      )
      .subscribe();
  }

  onRowClick(template: EventTemplate) {
    if (this.enableSelection) {
      this.table.selectRows(template);
    }
  }
}
