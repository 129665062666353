import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitutionSelectComponent } from './institution-select.component';
import { SharedUiSelectorModule } from '@tremaze/shared/ui/selector';

@NgModule({
  imports: [CommonModule, SharedUiSelectorModule],
  declarations: [InstitutionSelectComponent],
  exports: [InstitutionSelectComponent],
})
export class SharedFeatureInstitutionFeatureSelectModule {}
