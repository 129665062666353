import { Component, Inject, Optional } from '@angular/core';
import { EventTemplate } from '@tremaze/shared/feature/event/types';
import { EventTemplateEditFormService } from '../../event-template-edit-form.service';
import {
  EVENT_MODULE_CONFIG,
  EventModuleConfig,
} from '@tremaze/shared/feature/event/module-config';
import {
  map,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { RemoteCCDepartmentDataSource } from '@tremaze/shared/feature/department/data-access';
import { SuggestionsDataSource } from '@tremaze/suggestions-api';
import { User } from '@tremaze/shared/feature/user/types';
import {
  distinctUntilArrayChanged,
  mapEmpty,
  mapNotEmpty,
} from '@tremaze/shared/util/rxjs';
import { Observable, of } from 'rxjs';
import { RemoteSpecializationCRUDDataSourceDefaultImpl } from '@tremaze/shared/feature/specialization/data-access';
import { FormGroup } from '@ngneat/reactive-forms';

@Component({
  selector: 'tremaze-event-template-form-allocation-section',
  templateUrl: './allocation.component.html',
})
export class EventTemplateFormAllocationSectionComponent<
  T extends EventTemplate,
> {
  constructor(
    public readonly eventTemplateForm: EventTemplateEditFormService,
    private readonly departmentDataSource: RemoteCCDepartmentDataSource,
    private readonly assignmentEntityDataSource: SuggestionsDataSource,
    private readonly specializationDataSource: RemoteSpecializationCRUDDataSourceDefaultImpl,
    @Optional()
    @Inject(EVENT_MODULE_CONFIG)
    private readonly moduleConfig?: EventModuleConfig,
  ) {}

  get formGroup(): FormGroup<T> {
    return this.eventTemplateForm?.formGroup as any;
  }

  get showDepartmentSelector(): boolean {
    return !this.moduleConfig?.departmentAllocationDisabled;
  }

  get canSelectUsers(): boolean {
    return !this.moduleConfig?.usersDisabled;
  }

  userDisplayWith = (user: User) => (user ? user.fullName : '');

  userAvatarFn = (user: User) => user?.profileImage;

  userInitialsFn = (user: User) => user?.initials;

  userSubtitleFn = (user: User) =>
    user?.institutions.map((i) => i.name).join(', ');

  /**
   *  DATA SOURCES FOR AUTOCOMPLETE
   */

  get departmentAutocompleteDataSource() {
    return (filterValue: string) =>
      this.departmentDataSource
        .getPaginated(
          { filter: { filterValue, sort: 'name', sortDirection: 'asc' } },
          ['EVENT_WRITE', 'EVENT_WRITE_DEPARTMENT'],
        )
        .pipe(map((r) => r.content));
  }

  readonly clientsDataSource = (filterValue: string) =>
    this.assignmentEntityDataSource.getUsers('event', 'write', filterValue, [
      'USER',
    ]);

  readonly employeesDataSource = (filterValue: string) =>
    this.assignmentEntityDataSource.getUsers('event', 'write', filterValue, [
      'EMPLOYEE',
    ]);

  readonly instIds$: Observable<string[]> = this.formGroup.value$.pipe(
    map((v) => v.institutions.map((i) => i.id)),
    distinctUntilArrayChanged(),
    startWith([]),
  );

  readonly disableSpecializationSettings$ = this.instIds$.pipe(mapEmpty());

  readonly specializations$ = this.instIds$.pipe(
    distinctUntilArrayChanged(),
    skip(1),
    switchMap((instIds) => {
      if (instIds.length === 0) {
        return of({ content: [] });
      }
      return this.specializationDataSource.getPaginated({ instIds });
    }),
    map((r) => r.content),
    // remove specs from form group that are not available anymore
    tap((specs) => {
      this.formGroup.controls.specializations.setValue(
        this.formGroup.controls.specializations.value.filter((s) =>
          specs.find((spec) => spec.id === s.id),
        ),
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly hasSpecializations$ = this.specializations$.pipe(
    mapNotEmpty(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly idCompareFn = (a: { id: string }, b: { id: string }) =>
    a.id === b.id;
}
