import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UnapprovedUsersCountDataSource } from './unapproved-user-count-data-source';

@Injectable({
  providedIn: 'root',
})
export class UnapprovedUserCountService {
  constructor(private readonly _dataSource: UnapprovedUsersCountDataSource) {
    this.reloadUnapprovedUsers();
  }

  readonly unapprovedUsers$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);

  reloadUnapprovedUsers() {
    this._dataSource
      .getUnapprovedUsersCount()
      .subscribe((unapprovedUsersCount) =>
        this.unapprovedUsers$.next(unapprovedUsersCount),
      );
  }
}
