import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventTemplate } from '@tremaze/shared/feature/event/types';
import { FormGroup } from '@ngneat/reactive-forms';
import { InstitutionContextService } from '@tremaze/shared/feature/institution/shared/singletons';
import { AssignableEditService } from '@tremaze/shared-feature-assignment-assignable-edit-service';
import {
  EventTemplateEditFormService,
  EventTemplateFormModel,
} from './event-template-edit-form.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'tremaze-event-template-edit-dialog',
  template: `
    <span mat-dialog-title
      >Terminvorlage {{ isEdit ? 'bearbeiten' : 'anlegen' }}</span
    >
    <div mat-dialog-content>
      <div class="form-grid-wrapper">
        <tremaze-event-template-edit
          [eventTemplateForm]="eventTemplateForm"
          style="display: contents"
        ></tremaze-event-template-edit>
      </div>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Abbrechen</button>
      <button mat-raised-button (click)="onClickSubmit()" color="accent">
        {{ isEdit ? 'Speichern' : 'Anlegen' }}
      </button>
    </div>
  `,
  styles: [
    `
      div[mat-dialog-content] {
        width: 1000px;
        max-width: 100%;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [AssignableEditService, EventTemplateEditFormService],
})
export class EventTemplateEditDialogComponent implements OnInit {
  isEdit = false;
  get formGroup(): FormGroup<EventTemplateFormModel> {
    return this.eventTemplateForm.formGroup;
  }

  constructor(
    private readonly ref: MatDialogRef<EventTemplateEditDialogComponent>,
    private readonly el: ElementRef,
    public readonly eventTemplateForm: EventTemplateEditFormService,
    @Optional()
    private readonly institutionContextService: InstitutionContextService,
    @Inject(MAT_DIALOG_DATA)
    private readonly data?: { template: EventTemplate },
  ) {}

  ngOnInit(): void {
    this.isEdit = !!this.data?.template?.id;
    this._initForm(this.data?.template);
  }

  async _initForm(initialData?: EventTemplate) {
    if (this.institutionContextService?.hasInstitution) {
      const inst = await firstValueFrom(
        this.institutionContextService.currentInstitution$,
      );
      this.eventTemplateForm.init(initialData, inst);
    } else {
      this.eventTemplateForm.init(initialData);
    }
  }

  onClickSubmit() {
    const formResult = this.eventTemplateForm.submitForm();
    if (formResult && formResult instanceof EventTemplate) {
      this.ref.close(formResult);
    } else {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl() {
    scrollToFirstInvalidControl(this.el);
  }
}

function scrollToFirstInvalidControl(elementRef: ElementRef<any>) {
  const firstInvalidControl: HTMLElement =
    elementRef.nativeElement.querySelector(
      '.form-grid-wrapper mat-form-field.ng-invalid',
    );

  const page = elementRef.nativeElement.querySelector(
    '.mat-mdc-dialog-content.mdc-dialog__content',
  );
  const topOffset = firstInvalidControl.offsetTop - 200;
  page.scrollTo({
    top: topOffset,
    behavior: 'smooth',
  });
}
