import { tremazeDateFormat_DE } from './locales/de';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { isNotNullOrUndefined } from '@tremaze/shared/util-utilities';
import { Duration } from '@tremaze/duration';

export function parseTremazeDate(date: string): TremazeDate {
  return TremazeDate.deserialize(date);
}

export function tryParseTremazeDate(date: string): TremazeDate | null {
  try {
    // parse DD.MM.YY to DD.MM.YYYY
    if (typeof date === 'string' && date.includes('.')) {
      const split = date.split('.');
      if (split[2].length === 2) {
        split[2] = `20${split[2]}`;
        date = split.join('.');
      }
    }

    const d = TremazeDate.deserialize(date);
    // check if valid
    if (d.toString() === 'Invalid Date') {
      return null;
    }
    return d;
  } catch (e) {
    return null;
  }
}

@staticImplements<Deserializable<TremazeDate>>()
export class TremazeDate extends Date {
  static useUTC = false;
  static newJSONFormat = false;

  public overrideUTC = false; //Has to be set on true when handling birthdates

  get isMidnight(): boolean {
    return this.getHours() === 0 && this.getMinutes() === 0;
  }

  static getNow(): TremazeDate {
    return new TremazeDate();
  }

  static deserialize(data: any): TremazeDate {
    // check for DD.MM.YYYY
    const regex = /^\d{2}\.\d{2}\.\d{4}$/;
    if (typeof data === 'string' && regex.test(data)) {
      const split = data.split('.');
      return new TremazeDate(
        parseInt(split[2], 10),
        parseInt(split[1], 10) - 1,
        parseInt(split[0], 10),
      );
    }

    if (typeof data === 'string' && data.includes('.')) {
      data = data.split('.')[0];
    }
    if (TremazeDate.useUTC) {
      return !data ? null : TremazeDate.utc(data);
    }

    if (typeof data === 'string' && !data.includes('T')) {
      data += 'T00:00:00Z'; //Birthday-Format
    }
    if (typeof data === 'string' && !data.endsWith('Z')) {
      data += '.000Z';
    }
    return !data ? null : new TremazeDate(data);
  }

  static utc(d?: number | string | TremazeDate | Date): TremazeDate {
    if (!d) {
      return null;
    }
    if (d instanceof TremazeDate) {
      return d;
    }
    const date = new TremazeDate(d || Date.now());
    return new TremazeDate(date.toUTCString().substr(0, 25));
  }

  static intToWeekdayString(number: number): null | WEEKDAYS {
    if (number >= 0 && number < 7) {
      switch (number) {
        case 0:
          return 'MONDAY';
        case 1:
          return 'TUESDAY';
        case 2:
          return 'WEDNESDAY';
        case 3:
          return 'THURSDAY';
        case 4:
          return 'FRIDAY';
        case 5:
          return 'SATURDAY';
        case 6:
          return 'SUNDAY';
      }
    }
    return null;
  }

  static weekdayStringToInt(weekday: WEEKDAYS): number {
    switch (weekday) {
      case 'MONDAY':
        return 0;
      case 'TUESDAY':
        return 1;
      case 'WEDNESDAY':
        return 2;
      case 'THURSDAY':
        return 3;
      case 'FRIDAY':
        return 4;
      case 'SATURDAY':
        return 5;
      case 'SUNDAY':
        return 6;
    }
  }

  static timeStringToHourMinuteTuple(str: string): null | [string, string] {
    if (typeof str === 'string') {
      const split = str.split(':');
      if (split?.length >= 2) {
        return [split[0], split[1]];
      }
    }
    return null;
  }

  private static addLeadingZero(num: number) {
    let r = num.toString();
    if (r.length === 1) {
      r = `0${r}`;
    }
    return r;
  }

  setOverrideUTC(override: boolean): TremazeDate {
    this.overrideUTC = override;
    return this;
  }

  toUTC(): TremazeDate {
    return new TremazeDate(
      TremazeDate.UTC(
        this.getFullYear(),
        this.getMonth(),
        this.getDate(),
        this.getHours(),
        this.getMinutes(),
        this.getSeconds(),
        this.getMilliseconds(),
      ),
    );
  }

  compare(other: TremazeDate): 1 | 0 | -1 {
    if (this.isBefore(other)) {
      return -1;
    }
    if (this.isAfter(other)) {
      return 1;
    }
    return 0;
  }

  isSame(
    c?: TremazeDate,
    measurement: TREMAZE_DATE_MEASUREMENTS = 'millisecond',
  ): boolean {
    if (!c) {
      return false;
    }
    switch (measurement) {
      case 'year':
        return this.getFullYear() === c.getFullYear();
      case 'month':
        return this.isSame(c, 'year') && this.getMonth() === c.getMonth();
      case 'week':
        return this.isSame(c, 'year') && this.week() === c.week();
      case 'day':
        return this.isSame(c, 'month') && this.getDate() === c.getDate();
      case 'hour':
        return this.isSame(c, 'day') && this.getHours() === c.getHours();
      case 'minute':
        return this.isSame(c, 'hour') && this.getMinutes() === c.getMinutes();
      case 'second':
        return this.isSame(c, 'hour') && this.getSeconds() === c.getSeconds();
      case 'millisecond':
        return this.getTime() === c.getTime();
    }
  }

  isSameDay(c: TremazeDate): boolean {
    return (
      this.getDate() === c.getDate() &&
      this.getMonth() === c.getMonth() &&
      this.getFullYear() === c.getFullYear()
    );
  }

  isSameTimeOfDay(c?: TremazeDate, checkSeconds = false): boolean {
    return (
      this.getHours() === c?.getHours() &&
      this.getMinutes() === c?.getMinutes() &&
      (!checkSeconds || this.getSeconds() === c?.getSeconds())
    );
  }

  isBetween(
    a: TremazeDate,
    b: TremazeDate,
    measurement: TREMAZE_DATE_MEASUREMENTS = 'millisecond',
  ): boolean {
    if (!a || !b) {
      return false;
    }
    return this.isAfter(a, measurement) && this.isBefore(b, measurement);
  }

  isSameOrBetween(
    a: TremazeDate,
    b: TremazeDate,
    measurement: TREMAZE_DATE_MEASUREMENTS = 'millisecond',
  ): boolean {
    if (!a || !b) {
      return false;
    }
    return (
      this.isSame(a, measurement) ||
      this.isSame(b, measurement) ||
      this.isBetween(a, b, measurement)
    );
  }

  isBefore(
    c: TremazeDate,
    measurement: TREMAZE_DATE_MEASUREMENTS = 'millisecond',
  ): boolean {
    if (!c) {
      return false;
    }
    return this.isSameOrBefore(c, measurement) && !this.isSame(c, measurement);
  }

  isSameOrBefore(
    c: TremazeDate,
    measurement: TREMAZE_DATE_MEASUREMENTS = 'millisecond',
  ): boolean {
    if (!c) {
      return false;
    }
    switch (measurement) {
      case 'year':
        return this.getFullYear() <= c.getFullYear();
      case 'month':
        return (
          this.getFullYear() < c.getFullYear() ||
          (this.getFullYear() === c.getFullYear() &&
            this.getMonth() <= c.getMonth())
        );
      case 'week':
        return (
          this.getFullYear() < c.getFullYear() ||
          (this.getFullYear() === c.getFullYear() && this.week() <= c.week())
        );
      case 'day':
        return (
          this.getFullYear() < c.getFullYear() ||
          (this.getFullYear() === c.getFullYear() &&
            this.getMonth() < c.getMonth()) ||
          (this.getMonth() === c.getMonth() && this.getDate() <= c.getDate())
        );
      case 'hour':
        return (
          this.isSameOrBefore(c, 'day') ||
          (this.isSame(c, 'day') && this.getHours() <= c.getHours())
        );
      case 'minute':
        return (
          this.isSameOrBefore(c, 'hour') ||
          (this.isSame(c, 'hour') && this.getMinutes() <= c.getMinutes())
        );
      case 'second':
        return (
          this.isSameOrBefore(c, 'minute') ||
          (this.isSame(c, 'minute') && this.getSeconds() <= c.getSeconds())
        );
      case 'millisecond':
      default:
        return this.getTime() <= c.getTime();
    }
  }

  isAfter(
    c: TremazeDate,
    measurement: TREMAZE_DATE_MEASUREMENTS = 'millisecond',
  ) {
    return !this.isSameOrBefore(c, measurement);
  }

  isSameOrAfter(
    c: TremazeDate,
    measurement: TREMAZE_DATE_MEASUREMENTS = 'millisecond',
  ) {
    return !this.isBefore(c, measurement);
  }

  /**
   * Returns difference between 2 TremazeDates in given measurement. Everything rougher than hours is always floored and never rounded up.
   * @param comp - The date to compare to
   * @param measurement - the measurement
   */
  diff(
    comp: TremazeDate,
    measurement: TREMAZE_DATE_MEASUREMENTS = 'millisecond',
  ): number {
    // TODO implement all this stuff
    switch (measurement) {
      case 'year':
        return this._calcYearDiff(comp);
      case 'month':
        const yearDiff = this._calcYearDiff(comp);
        return this.getMonth() - comp.getMonth() + 12 * yearDiff;
      case 'week':
        throw new Error('not implemeted');
      case 'day':
        return Math.round(
          (this.getTime() - comp.getTime()) / (1000 * 60 * 60 * 24),
        );
      case 'hour':
        return Math.floor((this.getTime() - comp.getTime()) / 1000 / 60 / 60);
      case 'minute':
        return Math.floor((this.getTime() - comp.getTime()) / 1000 / 60);
      case 'second':
        return (this.getTime() - comp.getTime()) / 1000;
      default:
        return this.getTime() - comp.getTime();
    }
  }

  /**
   * Returns difference between 2 TremazeDates solely based on the time of day in milliseconds.
   * @param comp
   */
  timeDiff(comp: TremazeDate): number {
    return (
      this.getHours() * 60 * 60 * 1000 +
      this.getMinutes() * 60 * 1000 +
      this.getSeconds() * 1000 +
      this.getMilliseconds() -
      (comp.getHours() * 60 * 60 * 1000 +
        comp.getMinutes() * 60 * 1000 +
        comp.getSeconds() * 1000 +
        comp.getMilliseconds())
    );
  }

  getAge(): number {
    const now = TremazeDate.getNow();
    return this._calcYearDiff(now);
  }

  clone(): TremazeDate {
    return new TremazeDate(this.getTime());
  }

  format(formatString: string = ''): string {
    function x(xi: number) {
      return `{A${xi}}`;
    }

    const format = tremazeDateFormat_DE;
    if (formatString) {
      return formatString
        .replace(/YYYY/g, x(0))
        .replace(/YY/g, x(1))
        .replace(/MMMM/g, x(2))
        .replace(/MMM/g, x(3))
        .replace(/MM/g, x(4))
        .replace(/M/g, x(5))
        .replace(/DDDD/g, x(6))
        .replace(/DDD/g, x(7))
        .replace(/DD/g, x(8))
        .replace(/D/g, x(9))
        .replace(/hh/g, x(10))
        .replace(/mm/g, x(11))
        .replace(/ss/g, x(12))
        .replace(/EEEE/g, x(13))
        .replace(new RegExp(x(0), 'g'), this.getFullYear().toString())
        .replace(
          new RegExp(x(1), 'g'),
          this.getFullYear().toString().substr(2, 2),
        )
        .replace(new RegExp(x(2), 'g'), format.months[this.getMonth() + 1])
        .replace(new RegExp(x(3), 'g'), format.monthsShort[this.getMonth() + 1])
        .replace(
          new RegExp(x(4), 'g'),
          TremazeDate.addLeadingZero(this.getMonth() + 1),
        )
        .replace(new RegExp(x(5), 'g'), (this.getMonth() + 1).toString())
        .replace(new RegExp(x(6), 'g'), format.weekdays[this.getDay()])
        .replace(new RegExp(x(7), 'g'), format.weekdaysMin[this.getDay()])
        .replace(
          new RegExp(x(8), 'g'),
          TremazeDate.addLeadingZero(this.getDate()),
        )
        .replace(new RegExp(x(9), 'g'), this.getDate().toString())
        .replace(
          new RegExp(x(10), 'g'),
          TremazeDate.addLeadingZero(this.getHours()),
        )
        .replace(
          new RegExp(x(11), 'g'),
          TremazeDate.addLeadingZero(this.getMinutes()),
        )
        .replace(
          new RegExp(x(12), 'g'),
          TremazeDate.addLeadingZero(this.getSeconds()),
        )
        .replace(new RegExp(x(13), 'g'), format.weekdays[this.getDay()]);
    }
    return this.toLocaleDateString();
  }

  toJSON(key?: any, excludeTime?: boolean): string {
    // tslint:disable-next-line:max-line-length
    if (typeof excludeTime === 'boolean' && excludeTime === true) {
      return `${this.getFullYear()}-${TremazeDate.addLeadingZero(
        this.getMonth() + 1,
      )}-${TremazeDate.addLeadingZero(this.getDate())}`;
    }
    return this.toISOString();
  }

  add(amount: number, measurement: TREMAZE_DATE_MEASUREMENTS): TremazeDate {
    switch (measurement) {
      case 'year':
        this.setFullYear(this.getFullYear() + amount);
        break;
      case 'month':
        const r = this.getMonth() + amount;
        if (r > 11) {
          const y = Math.floor(r / 12);
          const m = r % 12;
          this.add(y, 'year');
          this.setMonth(m);
        } else {
          this.setMonth(r);
        }
        break;
      case 'week':
        this.setDate(this.getDate() + amount * 7);
        break;
      case 'day':
        this.setDate(this.getDate() + amount);
        break;
      case 'hour':
        this.setTime(this.getTime() + amount * 60 * 60 * 1000);
        break;
      case 'minute':
        this.setTime(this.getTime() + amount * 60 * 1000);
        break;
      case 'second':
        this.setTime(this.getTime() + amount * 1000);
        break;
      case 'millisecond':
        this.setTime(this.getTime() + amount);
        break;
    }
    return this;
  }

  subtract = (amount: number, measurement: TREMAZE_DATE_MEASUREMENTS) =>
    this.add(-amount, measurement);

  addDuration(duration: Duration) {
    return this.add(duration.inMilliseconds, 'millisecond');
  }

  subtractDuration(duration: Duration) {
    return this.subtract(duration.inMilliseconds, 'millisecond');
  }

  startOf(measurement: TREMAZE_DATE_UNITS): TremazeDate {
    switch (measurement) {
      case 'year':
        this.setMonth(0);
        this.setDate(1);
        this.startOf('day');
        break;
      case 'month':
        this.setDate(1);
        this.startOf('day');
        break;
      case 'week':
        const currentDay1 = this.getDay();
        // Sunday case
        this.setDate(this.getDate() - currentDay1);
        this.startOf('day');
        break;
      case 'isoWeek':
        const currentDay2 = this.isoWeekday() - 1;
        // Sunday case
        this.setDate(this.getDate() - currentDay2);
        this.startOf('day');
        break;
      case 'day':
        this.setHours(0);
        this.setMinutes(0);
        this.setSeconds(0);
        this.setMilliseconds(0);
        break;
      case 'hour':
        this.setMinutes(0);
        this.setSeconds(0);
        this.setMilliseconds(0);
        break;
      case 'minute':
        this.setSeconds(0);
        this.setMilliseconds(0);
        break;
      case 'second':
        this.setMilliseconds(0);
        break;
      case 'millisecond':
        break;
    }
    return this;
  }

  endOf(measurement: TREMAZE_DATE_UNITS): TremazeDate {
    switch (measurement) {
      case 'year':
        this.setMonth(11);
        this.setDate(31);
        break;
      case 'month':
        this.setDate(1);
        this.setMonth(this.getMonth() + 1);
        this.setDate(0);
        break;
      case 'week':
        const currentDay1 = this.getDay();
        // Sunday case
        this.setDate(this.getDate() + 6 - currentDay1);
        break;
      case 'isoWeek':
        const currentDay2 = this.isoWeekday() - 1;
        // Sunday case
        this.setDate(this.getDate() + 6 - currentDay2);
        break;
      case 'day':
        this.setHours(23);
        this.setMinutes(59);
        this.setSeconds(59);
        this.setMilliseconds(999);
        break;
      case 'hour':
        this.setMinutes(59);
        this.setSeconds(59);
        this.setMilliseconds(999);
        break;
      case 'minute':
        this.setSeconds(59);
        this.setMilliseconds(999);
        break;
      case 'second':
        this.setMilliseconds(999);
        break;
      case 'millisecond':
        break;
    }
    return this;
  }

  week(): number {
    const d = TremazeDate.utc(
      TremazeDate.UTC(this.getFullYear(), this.getMonth(), this.getDate()),
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = TremazeDate.utc(
      TremazeDate.UTC(d.getUTCFullYear(), 0, 1),
    );
    return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  }

  weekDay(): number {
    return this.getDay();
  }

  isoWeekday(): number {
    const d = this.getDay();
    return d === 0 ? 7 : d;
  }

  nextQuarterHour(): TremazeDate {
    const minutes = this.getMinutes();
    if (minutes < 15) {
      this.setMinutes(15);
    } else if (minutes < 30) {
      this.setMinutes(30);
    } else if (minutes < 45) {
      this.setMinutes(45);
    } else {
      this.setMinutes(0);
      this.add(1, 'hour');
    }
    return this;
  }

  private _calcYearDiff(comp: TremazeDate): number {
    let age = comp.getFullYear() - this.getFullYear();
    const month1 = comp.getMonth();
    const month2 = this.getMonth();
    if (month2 > month1) {
      age--;
    } else if (month1 === month2) {
      const day1 = comp.getDate();
      const day2 = this.getDate();
      if (day2 > day1) {
        age--;
      }
    }
    return age;
  }

  set(sets: {
    year?: number;
    month?: number;
    day?: number;
    hour?: number;
    minute?: number;
    second?: number;
    millisecond?: number;
  }): TremazeDate {
    if (isNotNullOrUndefined(sets.year)) {
      this.setFullYear(sets.year);
    }
    if (isNotNullOrUndefined(sets.month)) {
      this.setMonth(sets.month);
    }
    if (isNotNullOrUndefined(sets.day)) {
      this.setDate(sets.day);
    }
    if (isNotNullOrUndefined(sets.hour)) {
      this.setHours(sets.hour);
    }
    if (isNotNullOrUndefined(sets.minute)) {
      this.setMinutes(sets.minute);
    }
    if (isNotNullOrUndefined(sets.second)) {
      this.setSeconds(sets.second);
    }
    if (isNotNullOrUndefined(sets.millisecond)) {
      this.setMilliseconds(sets.millisecond);
    }
    return this;
  }
}

export type DateRange = [TremazeDate, TremazeDate];

export type TREMAZE_DATE_MEASUREMENTS =
  | 'year'
  | 'month'
  | 'week'
  | 'day'
  | 'hour'
  | 'minute'
  | 'second'
  | 'millisecond';

export type TREMAZE_DATE_UNITS =
  | 'year'
  | 'month'
  | 'week'
  | 'isoWeek'
  | 'day'
  | 'hour'
  | 'minute'
  | 'second'
  | 'millisecond';

export type WEEKDAYS =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';

export const WEEKDAYARR: WEEKDAYS[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export enum WeekDayGer {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}
