import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventPresetSelectionPopupComponent } from './event-preset-selection-popup.component';
import { IconTextButtonModule } from '@tremaze/shared/ui/icon-text-button';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    IconTextButtonModule,
    MatButton,
    MatDivider,
    FormsModule,
    MatDialogModule,
  ],
  declarations: [EventPresetSelectionPopupComponent],
})
export class EventPresetSelectionModule {}
