<div
  class="form-grid"
  [formGroup]="formGroup"
  [ngSwitch]="formGroup.controls.fieldType.value"
>
  <fieldset class="form-grid-item-full-width">
    <legend>Beschreibe das Feld</legend>

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" maxlength="120" />
      <mat-error>Bitte gib einen Namen an</mat-error>
      <mat-hint
        >{{ formGroup.controls.name.value?.length ?? 0 }} / 120</mat-hint
      >
    </mat-form-field>

    <mat-form-field>
      <mat-label>Beschreibe, was zu tun ist</mat-label>
      <input matInput formControlName="label" maxlength="120" />
      <mat-error>Bitte gib eine kurze Beschreibung an</mat-error>
      <mat-hint
        >{{ formGroup.controls.label.value?.length ?? 0 }} / 120</mat-hint
      >
    </mat-form-field>
  </fieldset>

  <fieldset>
    <legend>Feldeinstellungen</legend>

    <mat-checkbox formControlName="required">Ist Pflichtfeld</mat-checkbox>
  </fieldset>

  <tremaze-custom-form-fields-textbox-field-edit
    *ngSwitchCase="'TEXTBOX'"
    [formGroup]="formGroup"
  ></tremaze-custom-form-fields-textbox-field-edit>
  <tremaze-custom-form-fields-file-edit
    *ngSwitchCase="'FILE_UPLOAD'"
    [formGroup]="formGroup"
  ></tremaze-custom-form-fields-file-edit>
  <tremaze-custom-form-fields-multiselect-edit
    *ngSwitchCase="'MULTISELECT'"
    [formGroup]="formGroup"
    [index]="index"
  ></tremaze-custom-form-fields-multiselect-edit>
</div>
