import { Directive, Input, OnDestroy } from '@angular/core';
import { PersonalConfig } from '@tremaze/personal-config';
import { BehaviorSubject, Observable } from 'rxjs';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';

@Directive({
  selector: '[tremazeDataTablePersonalConfigSettingsKey]',
  standalone: true,
})
export class DataTablePersonalConfigSettingsKeyDirective implements OnDestroy {
  private _key$ = new BehaviorSubject<
    keyof PersonalConfig['filterSettings'] | undefined
  >(undefined);

  get key$(): Observable<keyof PersonalConfig['filterSettings']> {
    return this._key$.pipe(filterNotNullOrUndefined());
  }

  @Input({
    required: true,
    alias: 'tremazeDataTablePersonalConfigSettingsKey',
  })
  set key(key: keyof PersonalConfig['filterSettings'] | undefined) {
    this._key$.next(key);
  }

  get key(): keyof PersonalConfig['filterSettings'] | undefined {
    return this._key$.value;
  }

  ngOnDestroy(): void {
    this._key$.complete();
  }
}
