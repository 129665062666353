import { EventEmitter, Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, filter, interval, map, of, take } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { LocalStorage } from '@tremaze/shared/core/storage/local-storage';
import { DOCUMENT } from '@angular/common';
import { negateBool } from '@tremaze/shared/util/rxjs';

@Injectable({ providedIn: 'root' })
export class AppStateService {
  private readonly _menuCollapsed$ = new BehaviorSubject(
    this._getIsMenuCollapsedSettingInLocalStorage(),
  );
  private readonly _darkMode$ = new BehaviorSubject(
    this._getIsDarkModeSettingInLocalStorage(),
  );

  readonly menuCollapsed$ = this._menuCollapsed$.asObservable();

  readonly menuExpanded$ = this.menuCollapsed$.pipe(negateBool());

  readonly darkMode$ = this._darkMode$.asObservable();

  public menuCollapsedStateChanged = new EventEmitter();
  readonly updateAvailable$ = this.swUpdate?.isEnabled
    ? this.swUpdate.versionUpdates.pipe(
        filter(
          (e) => e.type === 'VERSION_READY' || e.type === 'VERSION_DETECTED',
        ),
        map(() => true),
      )
    : of(false);

  constructor(
    private localStorage: LocalStorage,
    @Optional() private swUpdate: SwUpdate,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if (swUpdate?.isEnabled) {
      setTimeout(() => swUpdate.checkForUpdate(), 1000 * 5);
      interval(1000 * 60).subscribe(() => swUpdate.checkForUpdate());
    }

    this._darkMode$.subscribe((dm) => this.onDarkModeChange(dm));
  }

  private _getIsMenuCollapsedSettingInLocalStorage(): boolean {
    const nav = this.localStorage.getItem('NAV_COLLAPSED');
    return nav ? (JSON.parse(nav) ?? false) : false;
  }

  private _getIsDarkModeSettingInLocalStorage(): boolean {
    const dm = this.localStorage.getItem('DARK_MODE');
    return dm ? (JSON.parse(dm) ?? false) : false;
  }

  toggleDarkMode() {
    this.darkMode$.pipe(take(1)).subscribe((dm) => this.setDarkMode(!dm));
  }

  private onDarkModeChange(value: boolean) {
    if (value) {
      document.documentElement.classList.add('dark-theme');
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
      document.documentElement.classList.remove('dark-theme');
    }
    this.localStorage.setItem('DARK_MODE', JSON.stringify(value));
  }

  setDarkMode(value: boolean) {
    this._darkMode$.next(value);
  }

  get isMobile() {
    return window.innerWidth <= 980;
  }

  toggleMenuCollapsed() {
    this.menuCollapsed$
      .pipe(take(1))
      .subscribe((mc) => this.setMenuCollapsed(!mc));
  }

  setMenuCollapsed(value: boolean) {
    this._menuCollapsed$.next(value);
    this.menuCollapsedStateChanged.emit();
    const v = JSON.stringify(value);
    this.localStorage.setItem('NAV_COLLAPSED', v);
  }

  blockScrolling() {
    this.document.documentElement.style.setProperty('overflow', 'hidden');
  }

  enableScrolling() {
    this.document.documentElement.style.removeProperty('overflow');
  }
}
