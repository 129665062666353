import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as TenantActions from './tenant.actions';
import { TenantEntity } from './tenant.models';
import { TenantProject } from '@tremaze/shared/feature/tenant/types';

export const TENANT_FEATURE_KEY = 'tenant';

export interface State extends EntityState<TenantEntity> {
  selectedId?: string | number; // which Tenant record has been selected
  loaded: boolean; // has the Tenant list been loaded
  error?: string | null; // last known error (if any)
  projects?: TenantProject[] | null;
}

export interface TenantPartialState {
  readonly [TENANT_FEATURE_KEY]: State;
}

export const tenantAdapter: EntityAdapter<TenantEntity> =
  createEntityAdapter<TenantEntity>();

export const initialState: State = tenantAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  projects: [],
});

const tenantReducer = createReducer(
  initialState,
  on(TenantActions.init, (state) => ({ ...state, loaded: false, error: null })),
  on(TenantActions.loadTenantSuccess, (state, { tenant }) =>
    tenantAdapter.setAll(tenant, {
      ...state,
      loaded: true,
      selectedId: tenant?.[0]?.id,
    }),
  ),
  on(TenantActions.loadTenantFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(TenantActions.loadTenantProjectsSuccess, (state, { projects }) => ({
    ...state,
    projects,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return tenantReducer(state, action);
}
