import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tremaze-expanding-icon-text-input',
  standalone: true,
  imports: [CommonModule],
  template: `
    <input
      type="text"
      #input
      placeholder="Suche..."
      [class.hasValue]="hasValue"
      [value]="value"
      (blur)="triggerChangeDetection()"
      (keyup)="onValueChange($event)"
      (keyup.escape)="clearValueAndRemoveFocus()"
    />
    <span (click)="onClickButton()" class="{{ iconClass }}"></span>
  `,
  styleUrls: ['./shared-ui-inputs-expanding-icon-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ExpandingIconTextInputComponent,
      multi: true,
    },
  ],
})
export class ExpandingIconTextInputComponent
  implements ControlValueAccessor, AfterViewInit
{
  @ViewChild('input') private input: ElementRef<HTMLInputElement>;
  @Output() valueChange = new EventEmitter<string>();
  onChange?: (value: string) => void;
  onTouched?: () => void;

  @HostBinding('class.tremaze-expanding-icon-text-input')
  private readonly hostClass = true;

  @Input()
  position: 'left' | 'right' = 'right';

  _value = '';

  get value() {
    return this._value;
  }

  @Input()
  set value(value: string | null) {
    this._value = value;
    if (this.input?.nativeElement) {
      this.input.nativeElement.value = value;
    }
  }

  @HostBinding('class.tremaze-expanding-icon-text-input--left')
  private get _isLeft() {
    return this.position === 'left';
  }

  constructor(private readonly _cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.isInputFocused) {
      if (this.input?.nativeElement) {
        this.input.nativeElement.value = this.value;
      }
    }
  }

  triggerChangeDetection() {
    this._cdRef.detectChanges();
  }

  get hasValue() {
    return this.value && this.value.length > 0;
  }

  get iconClass() {
    return this.hasValue ? 'lnr lnr-cross' : 'lnr lnr-magnifier';
  }

  onClickButton() {
    if (this.hasValue) {
      this.clearValueAndRemoveFocus();
      return;
    }
    this.switchFocus();
  }

  clearValue() {
    this.handleInput('');
  }

  switchFocus() {
    if (this.isInputFocused) {
      this.removeFocusFromInput();
    } else {
      this.focusInput();
    }
  }

  clearValueAndRemoveFocus() {
    this.clearValue();
    this.removeFocusFromInput();
  }

  onValueChange(event: Event) {
    this.handleInput((event.target as HTMLInputElement).value);
  }

  focusInput() {
    this.input.nativeElement.focus();
  }

  removeFocusFromInput() {
    this.input.nativeElement.blur();
  }

  get isInputFocused() {
    return this.input?.nativeElement === document.activeElement;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  handleInput(value: string) {
    this.value = value;
    this.valueChange.emit(value);
    this.onChange?.(value);
  }
}
