import { NgModule } from '@angular/core';
import {
  IconTextButtonComponent,
  IconTextButtonGroupComponent,
  IconTextToggleButtonComponent,
} from './icon-text-button.component';
import { IconTextButtonTitleDirective } from './icon-text-button-title.directive';
import { IconTextButtonIconDirective } from './icon-text-button-icon.directive';
import { IconTextButtonTextDirective } from './icon-text-button-text.directive';

@NgModule({
  declarations: [],
  imports: [
    IconTextButtonComponent,
    IconTextToggleButtonComponent,
    IconTextButtonGroupComponent,
    IconTextButtonTitleDirective,
    IconTextButtonIconDirective,
    IconTextButtonTextDirective,
  ],
  exports: [
    IconTextButtonComponent,
    IconTextToggleButtonComponent,
    IconTextButtonGroupComponent,
    IconTextButtonTitleDirective,
    IconTextButtonIconDirective,
    IconTextButtonTextDirective,
  ],
})
export class IconTextButtonModule {}

export {
  IconTextButtonComponent,
  IconTextToggleButtonComponent,
  IconTextButtonGroupComponent,
  IconTextButtonTitleDirective,
  IconTextButtonIconDirective,
  IconTextButtonTextDirective,
};
