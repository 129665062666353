import { TzPermissionRequest } from '@tremaze/shared/permission/types';
import { Category, CategoryType } from '@tremaze/shared/feature/category/types';

export abstract class CategoryPrivilegeChecks {
  static getCreatePrivilegeRequest(
    forType?: CategoryType,
  ): TzPermissionRequest {
    switch (forType) {
      case 'EVENT_CATEGORY':
        return {
          gPerms: 'EVENT_WRITE',
          iPerms: {
            perms: [['EVENT_WRITE'], ['EVENT_WRITE_DEPARTMENT']],
            instIds: 'ANY',
          },
        };
      case 'INFORMATION_CATEGORY':
      default:
        return {
          gPerms: 'INFORMATION_WRITE',
          iPerms: {
            perms: [['INFORMATION_WRITE'], ['INFORMATION_WRITE_DEPARTMENT']],
            instIds: 'ANY',
          },
        };
    }
  }

  static getGlobalWriteRequest(forType?: CategoryType): TzPermissionRequest {
    switch (forType) {
      case 'EVENT_CATEGORY':
        return {
          gPerms: 'EVENT_WRITE',
        };
      case 'INFORMATION_CATEGORY':
      default:
        return {
          gPerms: 'INFORMATION_WRITE',
        };
    }
  }

  static getEditPrivilegeRequest$({
    instIds,
    departmentInstIds,
    categoryType,
    standardCategory,
  }: Category): TzPermissionRequest {
    if (standardCategory) {
      return false;
    }
    switch (categoryType) {
      case 'EVENT_CATEGORY':
        return {
          gPerms: 'EVENT_WRITE',
          iPerms: [
            { perms: 'EVENT_WRITE', instIds },
            {
              perms: 'EVENT_WRITE_DEPARTMENT',
              instIds: departmentInstIds,
            },
          ],
        };
      case 'INFORMATION_CATEGORY':
      default:
        return {
          gPerms: 'INFORMATION_WRITE',
          iPerms: [
            { perms: 'INFORMATION_WRITE', instIds },
            {
              perms: 'INFORMATION_WRITE_DEPARTMENT',
              instIds: departmentInstIds,
            },
          ],
        };
    }
  }

  static getDeletePrivilegeRequest$({
    instIds,
    departmentInstIds,
    categoryType,
    standardCategory,
  }: Category): TzPermissionRequest {
    if (standardCategory) {
      return false;
    }
    switch (categoryType) {
      case 'EVENT_CATEGORY':
        return {
          gPerms: 'EVENT_DELETE',
          iPerms: [
            { perms: 'EVENT_DELETE', instIds },
            {
              perms: 'EVENT_DELETE_DEPARTMENT',
              instIds: departmentInstIds,
            },
          ],
        };
      case 'INFORMATION_CATEGORY':
      default:
        return {
          gPerms: 'INFORMATION_DELETE',
          iPerms: [
            { perms: 'INFORMATION_DELETE', instIds },
            {
              perms: 'INFORMATION_DELETE_DEPARTMENT',
              instIds: departmentInstIds,
            },
          ],
        };
    }
  }
}
