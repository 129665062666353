import { Component, OnInit } from '@angular/core';
import { L10n } from '@syncfusion/ej2-base';
import { syncfusionLocalizations } from '../l10n/syncfusion/german';

@Component({
  selector: 'tremaze-root',
  template: `
    <tour-step-template></tour-step-template>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {
  ngOnInit() {
    L10n.load(syncfusionLocalizations);
  }
}
