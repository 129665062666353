import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectUserNotificationComponent } from './select-user-notification.component';
import { SelectUserNotificationService } from './select-user-notification.service';

@NgModule({
  imports: [CommonModule, SelectUserNotificationComponent],
  providers: [SelectUserNotificationService],
})
export class SelectUserNotificationModule {}
