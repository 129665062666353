import { FormGroupModel } from '@tremaze/shared/util/form';
import { DefaultCRUDDataSource } from '@tremaze/shared/util-http';
import { finalize, first, take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FormBuilder } from '@ngneat/reactive-forms';
import { NotificationService } from '@tremaze/shared/notification';
import { MatDialogRef } from '@angular/material/dialog';
import { Injector } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface EditComponentService<T extends object> {
  onInvalid?(): void;
}

/**
 * @deprecated Do not use this class.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export abstract class EditComponentService<T extends object> {
  get loading(): boolean {
    return this._loading;
  }

  private _loading = false;
  private readonly _formGroup: FormGroupModel<T>;
  private _success = new Subject();
  private _ref?: MatDialogRef<unknown>;

  protected constructor(
    protected dataSource: DefaultCRUDDataSource<T>,
    protected fb: FormBuilder,
    protected notificationService: NotificationService,
    private injector: Injector,
    protected id?: string,
    protected instIds?: string[],
    protected departmentIds?: string[],
  ) {
    this._formGroup = this.createFormGroup(fb);
    if (this.id) {
      this._loading = true;
      this.prefillFormGroup(
        this.dataSource
          .getFreshById(this.id)
          .pipe(finalize(() => (this._loading = false))),
      );
    }
    this._ref = injector.get(MatDialogRef);
    this._ref
      ?.afterClosed()
      .pipe(first())
      .subscribe(() => this.dispose());
  }

  get formGroup(): FormGroupModel<T> {
    return this._formGroup;
  }

  get isEdit() {
    return !!this.id?.length;
  }

  trySubmit() {
    if (this.loading) {
      return;
    }
    this._formGroup.markAllAsTouched();
    this._formGroup.updateValueAndValidity();
    const { instIds, departmentIds } = this;
    if (this._formGroup.valid) {
      this._loading = true;
      if (this.isEdit) {
        this.dataSource
          .edit(this._formGroup.value as never, { instIds, departmentIds })
          .pipe(finalize(() => (this._loading = false)))
          .subscribe(
            (r) => this.onSuccess(r),
            () => this.onError(),
          );
      } else {
        this.dataSource
          .create(this._formGroup.value as never, { instIds, departmentIds })
          .pipe(finalize(() => (this._loading = false)))
          .subscribe(
            (r) => {
              this.onSuccess(r);
            },
            () => this.onError(),
          );
      }
    } else {
      this.onInvalid?.();
    }
  }

  dispose() {
    this._success.complete();
  }

  protected abstract createFormGroup(fb: FormBuilder): FormGroupModel<T>;

  protected prefillFormGroup(value$: Observable<T>) {
    value$.pipe(take(1)).subscribe((value) => {
      this._formGroup.setValue(value as never);
      this._formGroup.enable();
    });
  }

  private done(r: T) {
    this._ref?.close(r);
  }

  private onSuccess(r: T) {
    this.notificationService.showNotification(
      `Der Eintrag wurde ${this.isEdit ? 'bearbeitet' : 'angelegt'}`,
    );
    this.done(r);
  }

  private onError() {
    this.notificationService.showDefaultErrorNotification();
  }
}
