import { Meta } from '@tremaze/shared/models';
import { DirStorage } from '@tremaze/shared/feature/file-storage/types';
import { TremazeDate } from '@tremaze/shared/util-date';

export abstract class DirStorageFixtures {
  static generateDirStorage = (
    id: number,
    generateSubDirs = false,
  ): DirStorage => {
    return new DirStorage(
      id.toString(),
      new Meta(
        new TremazeDate('2021-01-01T00:00:00Z').add(id, 'day'),
        new TremazeDate('2022-01-01T00:00:00Z').add(id, 'day'),
      ),
      `dir Number ${id}`,
      `dir${id}`,
      '/path/to/dir',
      '/path/to/dir',
      generateSubDirs
        ? Array.from({ length: 5 }, (_, i) =>
            DirStorageFixtures.generateDirStorage(i),
          )
        : [],
      { id: 'ownerId' },
      [],
      undefined,
      id % 2 === 0,
    );
  };

  static generateDirStorageByAbsolutePath = (
    absolutePath: string,
  ): DirStorage => {
    return new DirStorage(
      '1',
      new Meta(),
      'dir Number 1',
      'dir1',
      absolutePath,
      absolutePath,
      [],
      { id: 'ownerId' },
      [],
      undefined,
      true,
    );
  };

  static readonly dirStorage1: DirStorage =
    DirStorageFixtures.generateDirStorage(1, true);
  static readonly dirStorage2: DirStorage =
    DirStorageFixtures.generateDirStorage(2, true);
  static readonly dirStorage3: DirStorage =
    DirStorageFixtures.generateDirStorage(3, true);
  static readonly dirStorage4: DirStorage =
    DirStorageFixtures.generateDirStorage(4, true);
  static readonly dirStorage5: DirStorage =
    DirStorageFixtures.generateDirStorage(5, true);

  static readonly all: DirStorage[] = [
    DirStorageFixtures.dirStorage1,
    DirStorageFixtures.dirStorage2,
    DirStorageFixtures.dirStorage3,
    DirStorageFixtures.dirStorage4,
    DirStorageFixtures.dirStorage5,
  ];
}
