import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCropperServicePayload } from '@tremaze/shared/feature/file-storage/feature/image-cropper/types';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OutputFormat } from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';

@UntilDestroy({ arrayName: 'subs' })
@Component({
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ImageCropperComponent implements OnInit {
  croppedImageBase64: string;
  private subs: Subscription[] = [];

  constructor(
    private ref: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ImageCropperServicePayload,
  ) {}

  private _transform: ImageTransform = {};

  get transform(): ImageTransform {
    return this._transform;
  }

  private _imageLoaded = false;

  get imageLoaded(): boolean {
    return this._imageLoaded;
  }

  private _imageLoadError = false;

  get imageLoadError(): boolean {
    return this._imageLoadError;
  }

  get format(): OutputFormat {
    const type = this.blob.type;
    return (<string>type).split('/')[1] as OutputFormat;
  }

  get blob() {
    return <File>this.data?.blob;
  }

  get aspectRatio() {
    return this.data?.aspectRatio ?? 16 / 9;
  }

  get currentRotation() {
    return this.transform.rotate || 0;
  }

  private _scaleDisplayHidden = true;

  get scaleDisplayHidden(): boolean {
    return this._scaleDisplayHidden;
  }

  set scaleDisplayHidden(value: boolean) {
    this._scaleDisplayHidden = value;
  }

  get currentScalePercent() {
    return Math.round((this.transform.scale || 1) * 100);
  }

  onLoad() {
    this._imageLoaded = true;
    this._imageLoadError = false;
  }

  onLoadFailed() {
    this._imageLoaded = false;
    this._imageLoadError = true;
  }

  ngOnInit(): void {
    this.subs.push(
      fromEvent(document, 'mousewheel')
        .pipe(debounceTime(2000))
        .subscribe(() => {
          this._scaleDisplayHidden = true;
        }),
    );
  }

  onImageCropped($event: ImageCroppedEvent) {
    this.croppedImageBase64 = $event.base64;
  }

  onRationInputChange(val: string) {
    this._transform = { ...this.transform, rotate: Number(val) };
  }

  onMousewheel(ev: WheelEvent) {
    this._scaleDisplayHidden = false;
    this._transform = {
      ...this.transform,
      scale: Math.min(
        3,
        Math.max(
          0.1,
          (this.transform.scale || 1) +
            -((ev.deltaY / Math.abs(ev.deltaY)) * 0.1),
        ),
      ),
    };
  }

  onClickReset() {
    this._transform = {};
  }

  onClickRotateCounterclockwise() {
    this._addRotation(-90);
  }

  onClickRotateClockwise() {
    this._addRotation(90);
  }

  onClickFlipXAxis() {
    this._transform = { ...this.transform, flipV: !this.transform.flipV };
  }

  onClickFlipYAxis() {
    this._transform = { ...this.transform, flipH: !this.transform.flipH };
  }

  async onClickSubmit() {
    this._imageLoaded = false;
    if (this.croppedImageBase64) {
      const blob = await fetch(this.croppedImageBase64).then((r) => r.blob());
      this.ref.close(blob);
    }
    this._imageLoaded = true;
  }

  private _addRotation(rot: number) {
    let cRot = (this.transform.rotate || 0) + rot;
    cRot = cRot % 360;
    this._transform = { ...this.transform, rotate: cRot };
  }
}
