import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'tremaze-data-table-loading-bar',
  template: `
    <mat-progress-bar
      *ngIf="loading$ | async"
      @progressBar
      class="table-wrapper__progress-bar mat-progress-bar-invisible-background"
      color="accent"
      mode="indeterminate"
      style="z-index: 101"
    ></mat-progress-bar>
  `,
  styleUrls: ['./data-table-loading-bar.component.scss'],
  animations: [
    trigger('progressBar', [
      transition(':enter', [
        style({ opacity: 0 }), // initial
        animate('.3s ease', style({ opacity: 1 })), // final
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(
          '.3s .3s ease',
          style({
            opacity: 0,
          }),
        ),
      ]),
    ]),
  ],
})
export class DataTableLoadingBarComponent {
  /**
   * loading$ is an observable that emits true while the table is loading.
   * When loading$ emits false, the animation is stopped.
   * @Input
   * @type {Observable<boolean>}
   */
  @Input() loading$: Observable<boolean>;
}
