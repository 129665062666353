import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventScopeSelectorPopupComponent } from './event-scope-selector-popup.component';
import { SharedUiPopupModule } from '@tremaze/shared/ui/popup';
import { EventScopeSelectorService } from './event-scope-selector.service';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { SharedUiHintModule } from '@tremaze/shared/ui/hint';

@NgModule({
  imports: [
    CommonModule,
    SharedUiPopupModule,
    MatListModule,
    MatButtonModule,
    FormsModule,
    SharedUiHintModule,
  ],
  declarations: [EventScopeSelectorPopupComponent],
  providers: [EventScopeSelectorService],
})
export class SharedFeatureEventFeatureEventScopeSelectorModule {}
