import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  CustomForm,
  CustomFormFeature,
} from '@tremaze/shared/feature/custom-forms/types';
import {
  DefaultDataSourceMethods,
  SortedFilteredPaginatedListParams,
} from '@tremaze/shared/util-http';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CustomFormSelectionDataAccessService {
  constructor(private readonly _http: HttpClient) {}

  getFilteredCustomForms(
    filterValue: string,
    config?: { feature?: CustomFormFeature; instIds?: string[] },
  ): Observable<CustomForm[]> {
    const { feature, instIds } = config ?? {};
    const filter: SortedFilteredPaginatedListParams = {
      filterValue: filterValue?.trim() ?? '',
      filterFields: ['NAME'],
      page: 0,
      pageSize: 30,
      sort: 'name',
      sortDirection: 'asc',
    };

    if (feature) {
      return DefaultDataSourceMethods.getPaginated<CustomForm>(
        this._http,
        `institutions/forms`,
        CustomForm.deserialize,
        {
          filter,
          instIds,
          q: {
            feature,
          },
        },
      ).pipe(map((d) => d.content));
    }

    return DefaultDataSourceMethods.getPaginated<CustomForm>(
      this._http,
      `forms`,
      CustomForm.deserialize,
      {
        filter,
      },
    ).pipe(map((d) => d.content));
  }
}
