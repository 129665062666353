<div
  *ngIf="!hideFilter"
  class="table-wrapper__container__filter gridster-nodrag"
>
  <h5 *ngIf="showAnyFilter">Filter:</h5>
  <tremaze-institution-filter-button
    *ngIf="enableInstitutionFilter"
    [initialInstitutions]="store.currentInstitutions$ | async"
    (filterChange)="onInstitutionFilterChange($event)"
    [institutionFilterPrivilege]="institutionFilterPrivilege"
  ></tremaze-institution-filter-button>
  <tremaze-department-filter-button
    *ngIf="enableDepartmentFilter"
    [initialDepartments]="store.currentDepartments$ | async"
    (filterChange)="onDepartmentFilterChange($event)"
    [departmentFilterPrivilege]="departmentFilterPrivilege"
  ></tremaze-department-filter-button>
  <tremaze-user-filter-button
    *ngIf="enableUserFilter"
    [initialUsers]="store.currentUsers$ | async"
    (filterChange)="onUserFilterChange($event)"
    [userFilterPrivilege]="userFilterPrivilege"
  ></tremaze-user-filter-button>

  <ng-container *ngFor="let customFilterDef of host.customFilterDefinitions">
    <ng-template [ngTemplateOutlet]="customFilterDef.template"></ng-template>
  </ng-container>

  <span class="spacer"></span>

  <tremaze-expanding-icon-text-input
    label="Suche..."
    #filterInput
  ></tremaze-expanding-icon-text-input>

  <button
    (click)="onClickResetFiltersButton()"
    mat-mini-fab
    matTooltip="Alle Filter zurücksetzen"
  >
    <mat-icon>
      <span class="lnr lnr-cross"></span>
    </mat-icon>
  </button>
  <button
    (click)="onClickReloadButton()"
    class="table-wrapper__reload mat-elevation-z0"
    mat-mini-fab
  >
    <mat-icon>
      <span class="lnr lnr-sync"></span>
    </mat-icon>
  </button>
</div>
