import {
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SelectZoneDirective } from './select-zone.directive';

@Directive({
  selector: '[tremazeSelectZoneItem]',
  standalone: true,
})
export class SelectZoneItemDirective<T> implements OnInit, OnDestroy {
  static _nextId = 0;
  private _id = SelectZoneItemDirective._nextId++;

  private readonly _selectZone = inject(SelectZoneDirective);
  readonly elementRef = inject(ElementRef);

  @Input() selectionDisabled = false;
  @Input({ required: true }) selectionData?: T;

  @Output() readonly selectionChange = new EventEmitter<boolean>();

  private _selected = false;

  set selected(value: boolean) {
    if (!this.selectionDisabled && this._selected !== value) {
      this._selected = value;
      this.selectionChange.emit(value);
    }
  }

  get selected() {
    return this._selected;
  }

  ngOnInit() {
    this._selectZone.registerItem(this._id, this);
  }

  ngOnDestroy() {
    this._selectZone.unregisterItem(this._id);
  }
}
