import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

type Inset = {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
};

@Component({
  selector: 'tremaze-dynamic-list-add-button',
  template: `
    <button
      mat-mini-fab
      class="dynamic-list__Add-button"
      (click)="clickedAdd.emit()"
      [disabled]="disabled"
      type="button"
    >
      <mat-icon>
        <span class="lnr lnr-plus"></span>
      </mat-icon>
    </button>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'dynamic-list__Add-button',
  },
})
export class DynamicListAddButtonComponent {
  @Input()
  @HostBinding('style.inset')
  get inset(): Inset {
    return this._inset;
  }

  set inset(value: Inset) {
    this._inset = value;
  }

  @Output() clickedAdd = new EventEmitter<void>();

  private _inset: Inset = {
    bottom: '0',
    right: '1rem',
  };

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
}
