import { Component, Inject } from '@angular/core';
import { EventPreset } from './types';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventPresetSelectionConfig } from './config';

@Component({
  selector: 'tremaze-event-preset-selection-popup',
  templateUrl: './event-preset-selection-popup.component.html',
  styleUrl: './event-preset-selection-popup.component.scss',
})
export class EventPresetSelectionPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly _config: EventPresetSelectionConfig,
  ) {}

  value?: EventPreset[] = [];

  get isEmpty() {
    return !this.value?.length;
  }

  showPreset(preset: EventPreset) {
    return (
      !this._config?.availablePresets ||
      this._config.availablePresets.includes(preset)
    );
  }
}
