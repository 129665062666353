import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tremaze-panel-header',
  template: `
    <ng-content select="[tremazePanelIcon]"></ng-content>
    <ng-content select="[tremazePanelTitle]"></ng-content>
    <ng-content select="[tremazePanelSubtitle]"></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class PanelHeaderComponent {
  @HostBinding('class') class = 'tremaze-panel-header';
}
