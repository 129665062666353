import { Inject, Injectable, Optional } from '@angular/core';
import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsGetFreshOptions,
  DefaultCRUDDataSourceImpl,
} from '@tremaze/shared/util-http';
import { EventTemplate } from '@tremaze/shared/feature/event/types';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { map, Observable, zip } from 'rxjs';
import {
  EVENT_MODULE_CONFIG,
  EventModuleConfig,
} from '@tremaze/shared/feature/event/module-config';
import { CustomForm } from '@tremaze/shared/feature/custom-forms/types';
import { catchErrorMapTo } from '@tremaze/shared/util/rxjs';
import { TremazeSchedule } from '@tremaze/shared/scheduling/types';

interface InputDTO
  extends Required<
    Pick<
      EventTemplate,
      | 'address'
      | 'description'
      | 'name'
      | 'highlight'
      | 'maxMember'
      | 'registrationNecessary'
    >
  > {
  categoryIds: string[];
  instIds: string[];
  titleImageId: string;
  instAssignmentIds?: string[];
  documentationCustomFormIds?: string[];
  public?: boolean;
  userTypeIds?: string[];
  visibleForFamily?: boolean;
  hasVideoMeeting?: boolean;
  hideWhenFull?: boolean;
  schedule?: Pick<
    TremazeSchedule,
    | 'repeatNumber'
    | 'repeatDays'
    | 'startDate'
    | 'endDate'
    | 'repeatEvery'
    | 'allowedRegistrationScope'
  >;
  userIds?: string[];
  departmentIds?: string[];
  defaultAppointmentDuration?: string;
  specializationIds?: string[];
  typeOfParticipationIds?: string[];
  enableParticipationInfo?: boolean;
  billable?: boolean;
}

@Injectable({ providedIn: 'root' })
export class DefaultRemoteEventTemplateDataSource extends DefaultCRUDDataSourceImpl<EventTemplate> {
  protected controller = '/eventTemplates';
  protected filterFields: ['NAME'];

  protected deserializer = EventTemplate.deserialize;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
    @Optional()
    @Inject(EVENT_MODULE_CONFIG)
    private readonly moduleConfig: EventModuleConfig,
  ) {
    super();
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions,
  ): Observable<EventTemplate> {
    return zip(
      super.getFreshById(id, options),
      this.http
        .get(`/eventTemplates/${id}/forms/documentation`)
        .pipe(catchErrorMapTo([])),
    ).pipe(
      map(([template, forms]) => {
        const documentationForms = ((forms as Array<unknown>) ?? []).map(
          CustomForm.deserialize,
        );
        const data = { ...template, documentationForms: documentationForms };
        return EventTemplate.deserialize(data);
      }),
    );
  }

  create(
    i: EventTemplate,
    options?: DataSourceMethodsCreateOptions<EventTemplate>,
  ): Observable<EventTemplate> {
    const payload: InputDTO = {
      address: i.address,
      description: i.description ?? '',
      categoryIds: i.categories?.map((category) => category.id),
      instIds: i.institutions?.map((inst) => inst.id),
      name: i.name,
      highlight: i.highlight ?? false,
      titleImageId: i.titleImage?.id,
      maxMember: i.maxMember,
      registrationNecessary: i.registrationNecessary ?? false,
      instAssignmentIds: i.institutionAssignments?.map((inst) => inst.id),
      documentationCustomFormIds: i.documentationForms?.map((form) => form.id),
      public: i.isPublic,
      userTypeIds: i.userTypes?.map((type) => type.id),
      visibleForFamily: i.visibleForFamily,
      hasVideoMeeting: i.hasVideoMeeting,
      hideWhenFull: i.hideWhenFull,
      userIds: i.users?.map((user) => user.id),
      departmentIds: i.departments?.map((department) => department.id),
      defaultAppointmentDuration:
        i.defaultAppointmentDuration?.inMinutes?.toString() ?? undefined,
      specializationIds: i.specializations?.map((spec) => spec.id),
      typeOfParticipationIds: i.participationTypes?.map((type) => type.id),
      enableParticipationInfo: i.enableParticipationInfo,
      billable: i.billable,
    };
    const schedule = i.schedule;
    if (schedule) {
      payload.schedule = {
        repeatNumber: schedule.repeatNumber,
        repeatDays: schedule.repeatDays,
        repeatEvery: schedule.repeatEvery,
        allowedRegistrationScope: schedule.allowedRegistrationScope,
      };
    }
    return super.create(payload as any, options);
  }

  edit(
    i: EventTemplate,
    options?: DataSourceMethodsEditOptions<EventTemplate>,
  ): Observable<EventTemplate> {
    if (typeof i.description !== 'string') {
      i.description = '';
    }
    const payload: InputDTO = {
      address: i.address,
      description: i.description ?? '',
      categoryIds: i.categories?.map((category) => category.id),
      instIds: i.institutions?.map((inst) => inst.id),
      name: i.name,
      highlight: i.highlight ?? false,
      titleImageId: i.titleImage?.id,
      maxMember: i.maxMember,
      registrationNecessary: i.registrationNecessary ?? false,
      instAssignmentIds: i.institutionAssignments?.map((inst) => inst.id),
      documentationCustomFormIds: i.documentationForms?.map((form) => form.id),
      public: i.isPublic,
      userTypeIds: i.userTypes?.map((type) => type.id),
      visibleForFamily: i.visibleForFamily,
      hasVideoMeeting: i.hasVideoMeeting,
      hideWhenFull: i.hideWhenFull,
      userIds: i.users?.map((user) => user.id),
      departmentIds: i.departments?.map((department) => department.id),
      defaultAppointmentDuration:
        i.defaultAppointmentDuration?.inMinutes?.toString() ?? undefined,
      specializationIds: i.specializations?.map((spec) => spec.id),
      typeOfParticipationIds: i.participationTypes?.map((type) => type.id),
      enableParticipationInfo: i.enableParticipationInfo,
      billable: i.billable,
    };
    const schedule = i.schedule;
    if (schedule) {
      payload.schedule = {
        repeatNumber: schedule.repeatNumber,
        repeatDays: schedule.repeatDays,
        repeatEvery: schedule.repeatEvery,
        allowedRegistrationScope: schedule.allowedRegistrationScope,
      };
    }
    options = { ...(options ?? {}), endpoint: `/${i.id}` };
    return super.edit(payload as any, options);
  }
}
