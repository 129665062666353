import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CustomFormEditComponentService } from './custom-form-edit-component.service/custom-form-edit-component.service';
import { FormGroup } from '@angular/forms';
import { CustomFormFieldsAbstractControl } from './form-models';
import { fieldTypeIcons, fieldTypeOptions } from './field-type-options';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { fieldAnimation } from './animations';
import { map, Observable, startWith, Subject, takeUntil, tap } from 'rxjs';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { TzPermissionRequest } from '@tremaze/shared/permission/types';
import { CustomFormPermissionUtils } from '@tremaze/shared/feature/custom-forms/utils';
import { customFormFeatureLabelMap } from '@tremaze/shared/feature/custom-forms/types';
import { CustomFormEditConfig } from './config';

@Component({
  selector: 'tremaze-custom-form-edit',
  templateUrl: './custom-form-edit.component.html',
  styleUrls: ['./custom-form-edit.component.scss'],
  animations: [fieldAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFormEditComponent implements OnDestroy {
  @ViewChildren(MatExpansionPanel)
  private readonly _expansionPanels!: QueryList<MatExpansionPanel>;

  private readonly _destroy$ = new Subject<void>();

  readonly formFeatureLabelMap = customFormFeatureLabelMap;

  private readonly _institutionIdsFromEditContext$ =
    this.service.form.controls.institutions.valueChanges.pipe(
      startWith(this.service.form.value.institutions ?? []),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map((insts: Institution[]) => (insts ?? []).map((i) => i.id!)),
    );

  readonly publishPermissionRequest$: Observable<TzPermissionRequest> =
    this._institutionIdsFromEditContext$.pipe(
      map(CustomFormPermissionUtils.buildPublishPermissionsForInstIds),
    );

  get formId(): string | undefined {
    return this._config?.formId;
  }

  @Input()
  set customFormEditConfig(value: CustomFormEditConfig | undefined) {
    this._config = value;
    this.service.config = value;
  }

  private _config?: CustomFormEditConfig;

  constructor(
    readonly service: CustomFormEditComponentService,
    _cdRef: ChangeDetectorRef,
  ) {
    service.form.statusChanges
      .pipe(
        tap(() => _cdRef.detectChanges()),
        takeUntil(this._destroy$),
      )
      .subscribe();

    service.triedSubmittingInvalid$
      .pipe(
        map(() => service.firstInvalidFieldIndex),
        filterNotNullOrUndefined(),
        tap((index) => {
          this._expansionPanels.get(index)?.open();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  idCompareWith = (a: Institution, b?: Institution) => a.id === b?.id;

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getShowErrorIconForExpansionPanel(
    field: FormGroup<CustomFormFieldsAbstractControl>,
  ): boolean {
    return (
      field.invalid && Object.values(field.controls).every((f) => f.touched)
    );
  }

  getFieldExpansionPanelHeaderTitle(
    field: FormGroup<CustomFormFieldsAbstractControl>,
  ): string {
    if (field.controls.name.value?.length) {
      return field.controls['name'].value;
    }
    return 'Kein Name';
  }

  getFieldExpansionPanelHeaderDescription(
    field: FormGroup<CustomFormFieldsAbstractControl>,
  ): string {
    if (field.controls.fieldType.value) {
      return fieldTypeOptions[field.controls.fieldType.value];
    }
    return 'Kein Feldtyp';
  }

  getFieldExpansionPanelHeaderIcon(
    field: FormGroup<CustomFormFieldsAbstractControl>,
  ): string {
    if (field.controls.fieldType.value) {
      return fieldTypeIcons[field.controls.fieldType.value];
    }
    return 'lnr lnr-question';
  }

  onDrop(event: CdkDragDrop<unknown>) {
    this.service.moveField(event.previousIndex, event.currentIndex);
  }

  onClickSubmitButton() {
    this.service.submit();
  }

  onClickRemoveFieldButton(index: number) {
    this.service.removeFieldAt(index);
  }

  trackField(_: never, item: FormGroup<CustomFormFieldsAbstractControl>) {
    return item.controls.trackingId?.value;
  }
}
