import { Inject, Injectable, Injector } from '@angular/core';
import { EditComponentService } from '@tremaze/shared/edit';
import { Category, CategoryType } from '@tremaze/shared/feature/category/types';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RemoteCCCategoryDataSource } from '@tremaze/shared/feature/category/data-access';
import { FormBuilder } from '@ngneat/reactive-forms';
import {
  FormGroupModel,
  FormModel,
  TremazeValidators,
} from '@tremaze/shared/util/form';
import { Validators } from '@angular/forms';
import { NotificationService } from '@tremaze/shared/notification';
import {
  CATEGORY_MODULE_CONFIG,
  CATEGORY_TYPE_CONFIG,
  CategoryModuleConfig,
} from '@tremaze/shared/feature/category/module-config';
import { PermissionCheckService } from '@tremaze/shared/permission/services';
import { CategoryPrivilegeChecks } from '@tremaze/shared/feature/category/util/privilege-checks';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { Institution } from '@tremaze/shared/feature/institution/types';

@Injectable({ providedIn: 'root' })
export class CategoryEditComponentService extends EditComponentService<Category> {
  constructor(
    dataSource: RemoteCCCategoryDataSource,
    fb: FormBuilder,
    protected notificationService: NotificationService,
    injector: Injector,
    private permissionCheckService: PermissionCheckService,
    private readonly authService: AuthV2Service,
    @Inject(MAT_DIALOG_DATA) data: { id?: string; instId?: string },
    @Inject(CATEGORY_TYPE_CONFIG) private readonly _type: CategoryType,
    @Inject(CATEGORY_MODULE_CONFIG)
    private moduleConfig?: CategoryModuleConfig,
  ) {
    super(
      dataSource,
      fb,
      notificationService,
      injector,
      data?.id,
      data?.instId ? [data.instId] : null,
    );
    this.onFormGroupCreated();
  }

  private _getInitialInstitutions$(): Observable<Institution[]> {
    if (this.moduleConfig?.automaticallyAssignAuthUserInstitutionOnCreation) {
      return this.authService.authenticatedUser$.pipe(
        take(1),
        map((r) => {
          if (r.userInstitutions?.length) {
            return [r.userInstitutions[0].institution];
          }
          return [];
        }),
      );
    }
    return of([]);
  }

  onFormGroupCreated() {
    if (!this.isEdit) {
      this._getInitialInstitutions$()
        .pipe(take(1))
        .subscribe((inst) => {
          this.formGroup.controls.institutions.setValue(inst);
        });
    }
    this.setUpGlobalPrivilegeCheckValidator();
  }

  get titleImageEnabled() {
    return !this.moduleConfig?.titleImageDisabled;
  }

  get iconEnabled() {
    return !this.moduleConfig?.iconDisabled;
  }

  get colorEnabled() {
    return !this.moduleConfig?.colorDisabled;
  }

  get descriptionEnabled() {
    return !this.moduleConfig?.descriptionDisabled;
  }

  get departmentAllocationEnabled() {
    return !this.moduleConfig?.disableDepartmentAllocation;
  }

  get userTypeAllocationEnabled() {
    return !this.moduleConfig?.disableUserTypeAllocation;
  }

  trySubmit() {
    if (!this.moduleConfig?.iconDisabled && !this.formGroup.value?.icon) {
      this.notificationService.showNotification('Bitte wähle ein Icon');
    }
    super.trySubmit();
  }

  protected createFormGroup(fb: FormBuilder): FormGroupModel<Category> {
    const model: FormModel<Category> = {
      ...new Category(),
      name: fb.control(null, Validators.required),
    };

    const fg = fb.group<FormModel<Category>>(model, {});
    if (this.isEdit) {
      fg.disable();
    }
    return fg;
  }

  private async setUpGlobalPrivilegeCheckValidator(): Promise<void> {
    const hasPermission = await this.permissionCheckService.checkPermission$(
      CategoryPrivilegeChecks.getGlobalWriteRequest(this._type),
    );
    if (!hasPermission) {
      this.formGroup.setValidators(
        TremazeValidators.eitherRequiredValidator<Category>(
          'departments',
          'institutions',
        ),
      );
    }
  }
}
