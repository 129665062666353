import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomForm } from '@tremaze/shared/feature/custom-forms/types';
import { ensureArray } from '@tremaze/shared/util-utilities';
import {
  CustomFormEditConfig,
  CustomFormEditService,
} from '@tremaze/shared/feature/custom-forms/feature/edit';
import { CustomFormSelectionDataAccessService } from '../custom-form-selection-data-access.service';
import { firstValueFrom, map } from 'rxjs';
import { ensureObservable } from '@tremaze/shared/util/rxjs';

@Component({
  selector: 'tremaze-custom-form-selection-button',
  template: `
    @if (multiple) {
      <tremaze-dynamic-list>
        <tremaze-dynamic-list-item *ngFor="let form of value">
          <h2 tremazeDynamicListItemTitle>{{ form.name }}</h2>
          <span tremazeDynamicListItemSubtitle>{{
            form.institutionNamesString
          }}</span>
          <tremaze-dynamic-list-item-action
            icon="lnr lnr-trash2"
            color="warn"
            (clickedAction)="onClickedRemoveItem(form)"
          ></tremaze-dynamic-list-item-action>
        </tremaze-dynamic-list-item>
      </tremaze-dynamic-list>
    }
    @if (canSelectFromExisting) {
      <button
        mat-stroked-button
        color="primary"
        tremazePickerPopup
        [showAddOptionButton]="canAdd"
        [filteredOptions]="customFormsDataSource"
        [displayWith]="customFormDisplayWith"
        [subtitleWith]="customFormSubtitleWith"
        inputPrefixIcon="lnr lnr-register"
        overlayLabel="Formular wählen"
        (optionSelected)="onOptionSelected($event)"
        (addOptionClicked)="onClickAddButton()"
        [disabled]="disabled"
      >
        <span *ngIf="label">{{ label }}&nbsp;</span>
        <span>{{ buttonLabel }}</span>
      </button>
    } @else {
      <button
        mat-stroked-button
        color="primary"
        (click)="onClickAddButton()"
        [disabled]="disabled"
      >
        <span *ngIf="label">{{ label }}&nbsp;</span>
        <span>{{ buttonLabel }}</span>
      </button>
    }
  `,
  styles: [
    `
      :host {
        display: inline-block;
        min-width: 250px;
      }

      tremaze-dynamic-list {
        margin-bottom: 1rem;
        display: block;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomFormSelectionButtonComponent,
    },
  ],
})
export class CustomFormSelectionButtonComponent
  implements ControlValueAccessor
{
  @Output() readonly selectionChange = new EventEmitter<
    CustomForm[] | CustomForm | null
  >();

  @Output() readonly formRemoved = new EventEmitter<CustomForm>();
  @Output() readonly formAdded = new EventEmitter<CustomForm>();

  private _onChange?: (value: CustomForm[] | CustomForm | null) => void;
  private _onTouched?: () => void;

  private _value: CustomForm[] = [];

  private _touched = false;
  private _disabled = false;

  @Input() canAdd = true;

  @Input() multiple = false;

  /**
   * If true, removed forms will automatically be archived.
   */
  @Input() archiveOnRemove = false;

  @Input() label?: string;

  @Input() config?: CustomFormEditConfig;

  @Input() canSelectFromExisting = false;

  get value() {
    return this._value.sort((a, b) => a.name.localeCompare(b.name));
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  get buttonLabel() {
    return 'Formular hinzufügen';
  }

  readonly customFormsDataSource = (filterValue: string) => {
    if (this.config?.feature) {
      return this._dataSource
        .getFilteredCustomForms(filterValue, {
          feature: this.config!.feature,
        })
        .pipe(
          map((forms) => {
            return forms.filter((form) => {
              return !this._value.find((f) => f.id === form.id);
            });
          }),
        );
    }

    return this._dataSource.getFilteredCustomForms(filterValue).pipe(
      map((forms) => {
        return forms.filter((form) => {
          return !this._value.find((f) => f.id === form.id);
        });
      }),
    );
  };

  readonly customFormDisplayWith = (customForm: CustomForm) =>
    customForm?.name ?? '??';

  readonly customFormSubtitleWith = (customForm: CustomForm) =>
    customForm?.institutions?.map((i) => i.name).join(', ') ?? '??';

  constructor(
    private readonly _editService: CustomFormEditService,
    private readonly _dataSource: CustomFormSelectionDataAccessService,
    private readonly _cdRef: ChangeDetectorRef,
  ) {}

  writeValue(obj: CustomForm[]): void {
    this._value = ensureArray(obj);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  private _reportChange() {
    this._onChange?.(this._value);
    this.selectionChange.emit(this._value);
    this._cdRef.detectChanges();
  }

  private _addForm(form: CustomForm) {
    this.markAsTouched();
    if (this.multiple) {
      this._value = [...this._value, form];
    } else {
      this._value = [form];
    }
    this.formAdded.emit(form);
    this._reportChange();
  }

  onOptionSelected(form: CustomForm) {
    if (form) {
      this._addForm(form);
    }
  }

  onClickAddButton() {
    this._editService.create(this.config).subscribe((r) => {
      if (r) {
        this._addForm(r);
      }
    });
  }

  async onClickedRemoveItem(form: CustomForm) {
    if (this.archiveOnRemove) {
      const institutions = await firstValueFrom(
        ensureObservable(this.config?.institutions ?? []),
      );
      if (institutions.length === 1) {
        this._editService
          .removeFormFromInstitution(form.id, institutions[0].id)
          .subscribe((r) => {
            if (r) {
              this.markAsTouched();
              this._value = this._value.filter((f) => f.id !== form.id);
              this.formRemoved.emit(form);
              this._reportChange();
            }
          });
        return;
      }
    } else {
      this.markAsTouched();
      this._value = this._value.filter((f) => f.id !== form.id);
      this.formRemoved.emit(form);
      this._reportChange();
    }
  }

  markAsTouched() {
    if (!this._touched) {
      this._onTouched?.();
      this._touched = true;
    }
  }
}
