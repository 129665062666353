import { HttpClient } from '@angular/common/http';
import { Gender } from '@tremaze/shared/feature/gender/types';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { Injectable } from '@angular/core';
import {
  DataSourceMethodsPaginatedOptions,
  DefaultREADDataSourceWithPaginationImpl,
} from '@tremaze/shared/util-http';
import { Observable } from 'rxjs';
import { Pagination, TableObject } from '@tremaze/shared/models';
import { map } from 'rxjs/operators';

export abstract class GenderDataSource extends DefaultREADDataSourceWithPaginationImpl<Gender> {
  deserializer = Gender.deserialize;
}

@Injectable({ providedIn: 'root' })
export class RemoteAppGenderDataSource extends GenderDataSource {
  protected controller = 'public/genders';

  constructor(
    public http: HttpClient,
    public js: JsonSerializer,
  ) {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class RemoteCCGenderDataSource extends GenderDataSource {
  protected controller = 'public/genders';

  constructor(
    public http: HttpClient,
    public js: JsonSerializer,
  ) {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class RemotePublicGenderDataSource extends GenderDataSource {
  protected controller = '/public/gender';

  constructor(
    public http: HttpClient,
    public js: JsonSerializer,
  ) {
    super();
  }

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions<Gender>,
  ): Observable<Pagination<Gender>> {
    return this.http
      .get<TableObject<unknown>>(`${this.controller}/all`)
      .pipe(
        map((r) => new Pagination<Gender>(r.items.map(Gender.deserialize))),
      );
  }
}
