import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { User } from '@tremaze/shared/feature/user/types';
import { SharedFileStorageUiCircleAvatarModule } from '@tremaze/shared/feature/file-storage/ui/circle-avatar';
import { SharedUiPipesFullNamePipeModule } from '@tremaze/shared/ui/pipes/full-name-pipe';
import { SharedUiInitialsCircleModule } from '@tremaze/shared/ui/initials-circle';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'tremaze-user-list-item',
  standalone: true,
  imports: [
    CommonModule,
    SharedFileStorageUiCircleAvatarModule,
    SharedUiPipesFullNamePipeModule,
    SharedUiInitialsCircleModule,
    MatTooltipModule,
  ],
  templateUrl: './shared-feature-user-ui-user-list-item.component.html',
  styleUrls: ['./shared-feature-user-ui-user-list-item.component.scss'],
})
export class SharedFeatureUserUiUserListItemComponent {
  @Input() user!: User;

  get userTypeString(): string {
    return this.user.userTypes?.map((t) => t.viewName).join(', ') ?? '';
  }

  get institutionString(): string | undefined {
    const firstInstitution = this.user.institutions?.[0];
    let res = '';
    if (firstInstitution) {
      res += ` - ${firstInstitution.name}`;
      if (this.user.institutions?.length > 1) {
        res += ` (+${this.user.institutions.length - 1})`;
      }
      return res;
    }
    return undefined;
  }

  get fullInstitutionString(): string | undefined {
    return this.user.institutions?.map((i) => i.name).join(', ');
  }
}
