import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataTableService {
  readonly _reload$ = new Subject<string>();

  readonly reload$ = this._reload$.asObservable();

  reloadDataTable(tableId: string): void {
    this._reload$.next(tableId);
  }
}
