import { InjectionToken } from '@angular/core';

export interface LegalSettingsModuleConfig {
  readonly hideDataPrivacySetting?: boolean;
  readonly hideImprintSetting?: boolean;
  readonly hideDisclaimerOfLiabilitySetting?: boolean;
  readonly hideAccessibilityTextSetting?: boolean;
  readonly hidePrivacySettingsApplyToAllCheckbox?: boolean;
  readonly hideImprintSettingsApplyToAllCheckbox?: boolean;
  readonly hideTermsOfUseSetting?: boolean;
}

export const LEGAL_SETTINGS_MODULE_CONFIG =
  new InjectionToken<LegalSettingsModuleConfig>('LEGAL_SETTINGS_MODULE_CONFIG');
