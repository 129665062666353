import {
  Directive,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DragZoneDirective } from './drag-zone.directive';

@Directive({
  selector: '[tremazeDragZoneItem]',
  standalone: true,
})
export class DragZoneItemDirective<T> implements OnInit, OnDestroy {
  static _nextId = 0;
  private _id = DragZoneItemDirective._nextId++;

  private readonly _dragZone = inject(DragZoneDirective);

  @Input({ required: true }) dragData?: T;

  @Input() dragDisabled = false;

  ngOnInit() {
    this._dragZone.registerDragItem(this._id, this);
  }

  ngOnDestroy() {
    this._dragZone.unregisterDragItem(this._id);
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (!this.dragDisabled) {
      event.preventDefault();
      event.stopPropagation();
      this._dragZone.startDrag(this._id, event);
    }
  }
}
