import { Pipe, PipeTransform } from '@angular/core';
import { Duration, DurationUnit } from '@tremaze/duration';
// import sentry
import * as Sentry from '@sentry/angular-ivy';

@Pipe({
  name: 'duration',
  standalone: true,
})
export class DurationPipe implements PipeTransform {
  transform(millis: number | Duration | null, unit?: DurationUnit): string {
    if (millis === null) {
      return '0 Minuten';
    }
    const duration =
      typeof millis === 'number' ? new Duration(millis) : millis.clone();
    if (duration instanceof Duration) {
      if (unit) {
        return `${duration.in(unit, false).toFixed(2).replace('.00', '')} ${Duration.getUnitLabel(unit, duration.in(unit, false))}`;
      }

      try {
        if (duration.isZero) {
          return '0 Minuten';
        }
        return duration.ceil('seconds').format();
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            duration,
          },
        });
      }
    }
    return 'Berechnung fehlgeschlagen';
  }
}
