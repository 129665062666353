import { EventRegistration } from '@tremaze/shared/feature/event-registration/types';
import { UserFixtures } from '../user';
import { EventFixtures, EventParticipationTypeFixtures } from '../event';
import { CategoryFixtures } from '../category';

export abstract class EventRegistrationFixtures {
  static readonly event1Accepted1 = new EventRegistration(
    UserFixtures.client1,
    EventFixtures.event1,
    'ACCEPTED',
    false,
    '1',
    undefined,
    undefined,
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    EventParticipationTypeFixtures.participationTypeOnline,
    CategoryFixtures.eventCategory1,
  );

  static readonly event1Accepted2 = new EventRegistration(
    UserFixtures.client2,
    EventFixtures.event1,
    'ACCEPTED',
    false,
    '11',
    undefined,
    undefined,
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    EventParticipationTypeFixtures.participationTypeOnline,
    CategoryFixtures.eventCategory1,
  );

  static readonly event1Declined1 = new EventRegistration(
    UserFixtures.client3,
    EventFixtures.event1,
    'DECLINED',
    false,
    '3',
    undefined,
    undefined,
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    EventParticipationTypeFixtures.participationTypeOnline,
    CategoryFixtures.eventCategory1,
  );

  static readonly event1Declined2 = new EventRegistration(
    UserFixtures.client4,
    EventFixtures.event1,
    'DECLINED',
    false,
    '31',
    undefined,
    undefined,
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    EventParticipationTypeFixtures.participationTypeOnline,
    CategoryFixtures.eventCategory1,
  );

  static readonly event1Pending1 = new EventRegistration(
    UserFixtures.client5,
    EventFixtures.event1,
    'NOT_RESPONDED',
    false,
    '2',
  );
}
