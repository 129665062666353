<ng-container [formGroup]="formGroup" *ngIf="formGroup">
  <tremaze-panel-list>
    <tremaze-panel>
      <tremaze-panel-header>
        <span tremazePanelIcon class="lnr lnr-clipboard-text"></span>
        <h3 tremazePanelTitle>Allgemeines</h3>
      </tremaze-panel-header>
      <tremaze-event-template-form-general-section />
    </tremaze-panel>

    <tremaze-panel>
      <tremaze-panel-header>
        <span tremazePanelIcon class="lnr lnr-clock2"></span>
        <h3 tremazePanelTitle>Zeit</h3>
      </tremaze-panel-header>
      <tremaze-event-template-edit-time-settings></tremaze-event-template-edit-time-settings>
    </tremaze-panel>

    <tremaze-panel *ifModuleEnabled="'EVENT_SCHEDULE'">
      <tremaze-panel-header>
        <span tremazePanelIcon class="lnr lnr-repeat"></span>
        <h3 tremazePanelTitle>Wiederholung</h3>
      </tremaze-panel-header>
      <tremaze-event-template-schedule-settings *ngIf="!isScheduleDisabled" />
    </tremaze-panel>

    <tremaze-panel>
      <tremaze-panel-header>
        <span tremazePanelIcon class="lnr lnr-arrows-split"></span>
        <h3 tremazePanelTitle>Zuweisung</h3>
      </tremaze-panel-header>
      <tremaze-event-template-form-allocation-section />
    </tremaze-panel>

    <tremaze-panel>
      <tremaze-panel-header>
        <span tremazePanelIcon class="lnr lnr-clipboard-user"></span>
        <h3 tremazePanelTitle>Anmeldung</h3>
      </tremaze-panel-header>
      <tremaze-event-template-registration-settings />
    </tremaze-panel>

    <tremaze-panel *ifModuleEnabled="'APPROVAL'">
      <tremaze-panel-header>
        <span tremazePanelIcon class="lnr lnr-checkmark-circle"></span>
        <h3 tremazePanelTitle>Bewilligung & Abrechnung</h3>
      </tremaze-panel-header>

      <tremaze-event-template-form-approval-section />
    </tremaze-panel>

    <tremaze-panel>
      <tremaze-panel-header>
        <span tremazePanelIcon class="lnr lnr-map-marker"></span>
        <h3 tremazePanelTitle>Ort</h3>
      </tremaze-panel-header>
      <tremaze-event-template-form-address-section />
    </tremaze-panel>

    <tremaze-panel>
      <tremaze-panel-header>
        <span tremazePanelIcon class="lnr lnr-cog2"></span>
        <h3 tremazePanelTitle>Einstellungen</h3>
      </tremaze-panel-header>
      <tremaze-event-template-form-settings-section />
    </tremaze-panel>

    <tremaze-panel>
      <tremaze-panel-header>
        <span tremazePanelIcon class="lnr lnr-pencil"></span>
        <h3 tremazePanelTitle>Beschreibung</h3>
      </tremaze-panel-header>
      <tremaze-event-template-form-description-section />
    </tremaze-panel>

    <tremaze-panel *ifModuleEnabled="'CUSTOM_FORM'">
      <tremaze-panel-header>
        <span tremazePanelIcon class="lnr lnr-register"></span>
        <h3 tremazePanelTitle>Formulare</h3>
      </tremaze-panel-header>
      <tremaze-custom-form-selection-button
        [canSelectFromExisting]="true"
        [multiple]="true"
        formControlName="documentationForms"
        label="Doku-Formular:"
      ></tremaze-custom-form-selection-button>
    </tremaze-panel>
  </tremaze-panel-list>
</ng-container>
