import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ParticipationCriteria } from '@tremaze/shared/feature/participation-criteria/types';
import { Observable } from 'rxjs';
import { ParticipationCriteriaSelectionComponent } from './participation-criteria-selection.component';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ParticipationCriteriaSelectionService {
  constructor(private dialog: MatDialog) {}

  selectCriteria(
    preSelected?: ParticipationCriteria[],
    mandatory?: boolean,
  ): Observable<null | ParticipationCriteria[]> {
    return this.dialog
      .open(ParticipationCriteriaSelectionComponent, {
        data: { preSelected, mandatory },
        panelClass: 'relative-dialog',
      })
      .afterClosed()
      .pipe(map((r) => r || null));
  }
}
