import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tremaze-icon-picker-dialog',
  template: `
    <span mat-dialog-title>Icon wählen</span>
    <div mat-dialog-content style="max-width: 400px;">
      <button
        mat-icon-button
        color="accent"
        *ngFor="let icon of allIcons"
        [mat-dialog-close]="icon"
      >
        <span class="lnr lnr-{{ icon }}"></span>
      </button>
    </div>
  `,
  encapsulation: ViewEncapsulation.Emulated,
})
export class IconPickerDialogComponent {
  allIcons = Object.values(CategoryIcons);
}

export const CategoryIcons: string[] = [
  'book',
  'football',
  'baseball',
  'balloon',
  'tennis',
  'dumbbell',
  '8ball',
  'ping-pong',
  'code',
  'at-sign',
  'city',
  'palette',
  'graduation-hat',
  'guitar',
  'trumpet',
  'music-note3',
  'bus',
  'plane',
  'boat',
  'happy',
  'mic2',
  'gamepad',
  'spades',
  'envelope',
  'binoculars',
  'trophy',
  'hammer-wrench',
  'hammer',
  'hamburger',
  'platter',
  'pizza',
  'chef',
  'dinner',
  'landscape',
  'pine-tree',
  'tree',
  'leaf',
  'paw',
  'rocket',
  'briefcase',
  'ghost-hipster',
  'christmas',
  'halloween',
  'easter-egg',
];
