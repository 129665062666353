import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { SelectUserNotificationConfig } from './types';
import { SelectUserNotificationComponent } from './select-user-notification.component';

@Injectable()
export class SelectUserNotificationService {
  constructor(private readonly _dialog: MatDialog) {}

  askUserForNotification(
    config?: SelectUserNotificationConfig,
  ): Observable<boolean | undefined> {
    return this._dialog
      .open(SelectUserNotificationComponent, {
        data: config,
      })
      .afterClosed()
      .pipe(map((r) => r ?? undefined));
  }
}
