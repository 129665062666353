import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { MonoTypeOperatorFunction, OperatorFunction, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

export function reportProgressPercent<T>(
  reportProgressFn?: (progress: number) => void,
): MonoTypeOperatorFunction<HttpEvent<T>> {
  if (!reportProgressFn) {
    return (source) => source;
  }
  return (source) =>
    source.pipe(
      tap((event) => {
        if (
          event.type === HttpEventType.DownloadProgress ||
          event.type === HttpEventType.UploadProgress
        ) {
          const total = event.total;
          if (total) {
            const progress = Math.round((100 * event.loaded) / total);
            reportProgressFn(progress);
          } else {
            reportProgressFn(0);
          }
        }
      }),
    );
}

export function filterResponse<T>(): OperatorFunction<
  HttpEvent<T>,
  HttpResponse<T>
> {
  return (source) =>
    source.pipe(
      filter(
        (event): event is HttpResponse<T> => event instanceof HttpResponse,
      ),
    );
}
