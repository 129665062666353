import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface EventModuleConfig {
  scheduleDisabled?: boolean;
  externalProviderDisabled?: boolean;
  eventFilesDisabled?: boolean;
  holidayDisabled?: boolean;
  paymentDisabled?: boolean;
  participationCriteriaDisabled?: boolean;
  ticketsDisabled?: boolean;
  parentConfirmationDisabled?: boolean;
  emergencyContactDisabled?: boolean;
  priceLevelDisabled?: boolean;
  highlightsDisabled?: boolean;
  pricesDisabled?: boolean;
  showCredits?: boolean;
  countRegistrationsInListDisabled?: boolean;
  visibleForFamilySettingEnabled?: boolean;
  genderSettingsDisabled?: boolean;
  ageRestrictionsDisabled?: boolean;
  registrationDisabled?: boolean;
  publicEvents?: 'DEFAULT_ON' | 'DEFAULT_OFF' | 'DISABLED';
  automaticallyAssignAuthUserInstitutionOnCreation?: boolean;
  automaticallyAssignAuthUserOnCreation?: boolean;
  departmentAllocationDisabled?: boolean;
  videoMeetingsDisabled?: boolean;
  visibleOnlyForEmployeesSettingDisabled?: boolean;
  organizerDisabled?: boolean;
  signOnOffDateDisabled?: boolean;
  workshopsEnabled?: boolean;
  usersDisabled?: boolean;
}

export const EVENT_MODULE_CONFIG = new InjectionToken<EventModuleConfig>(
  'EVENT_MODULE_CONFIG',
);

export abstract class EventModuleConfigService {
  abstract getEventModuleConfig$(): Observable<EventModuleConfig>;
}
