import { Injectable } from '@angular/core';
import {
  BackgroundTask,
  BackgroundTaskType,
} from '@tremaze/shared/ui/progress-display/types';
import {
  BehaviorSubject,
  combineLatest,
  endWith,
  finalize,
  from,
  map,
  Observable,
  scan,
  shareReplay,
  switchMap,
} from 'rxjs';
import { uuid } from '@tremaze/shared/util-generators';
import { TremazeDate } from '@tremaze/shared/util-date';

type TaskMap = { [key: string]: BackgroundTask };

@Injectable({ providedIn: 'root' })
export class BackgroundTaskService {
  private _tasksMap$ = new BehaviorSubject<TaskMap>({});

  readonly tasks$: Observable<BackgroundTask[]> = this._tasksMap$.pipe(
    map((map) => Object.values(map)),
  );

  readonly taskCount$: Observable<number> = this.tasks$.pipe(
    map((tasks) => tasks.length),
  );

  readonly completedTaskCount$: Observable<number> = this.tasks$.pipe(
    switchMap((tasks) =>
      from(tasks).pipe(
        switchMap((task) => task.isCompleted$),
        map((isCompleted) => (isCompleted ? 1 : 0)),
        scan((acc, curr) => acc + curr, 0),
      ),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly totalProgress$: Observable<number> = this.tasks$.pipe(
    switchMap((tasks) =>
      combineLatest(tasks.map((task) => task.progress$)).pipe(
        map(
          (progresses) =>
            progresses.reduce((acc, curr) => acc + curr, 0) / progresses.length,
        ),
      ),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly hasTasks$: Observable<boolean> = this.taskCount$.pipe(
    map((count) => count > 0),
  );

  registerTask(p: {
    name: string;
    progress$: Observable<number>;
    type: BackgroundTaskType;
  }): void {
    const id = uuid();
    const task = new BackgroundTask(
      id,
      TremazeDate.getNow(),
      p.type,
      p.name,
      p.progress$.pipe(
        endWith(100),
        finalize(() => {
          setTimeout(() => {
            this._tasksMap$.next(
              Object.keys(this._tasksMap$.value)
                .filter((key) => key !== id)
                .reduce(
                  (acc, key) => ({ ...acc, [key]: this._tasksMap$.value[key] }),
                  {},
                ),
            );
          }, 3000);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      ),
    );
    this._tasksMap$.next({ ...this._tasksMap$.value, [id]: task });
  }
}
