import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelListComponent } from './panel-list/panel-list.component';
import { PanelComponent } from './panel/panel.component';
import { PanelHeaderComponent } from './panel-header/panel-header.component';
import { PanelTitleDirective } from './panel-title.directive';
import { PanelIconDirective } from './panel-icon.directive';
import { PanelSubtitleDirective } from './panel-subtitle.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    PanelListComponent,
    PanelComponent,
    PanelHeaderComponent,
    PanelTitleDirective,
    PanelIconDirective,
    PanelSubtitleDirective,
  ],
  exports: [
    PanelListComponent,
    PanelComponent,
    PanelHeaderComponent,
    PanelTitleDirective,
    PanelIconDirective,
    PanelSubtitleDirective,
  ],
})
export class SharedUiPanelListModule {}
