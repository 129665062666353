import { animate, style, transition, trigger } from '@angular/animations';

const timing = '.6s ease';

export const simpleFadeInOutAnimation = trigger('simpleFadeInOut', [
  transition(':enter', [
    style({
      opacity: 0.75,
      overflow: 'hidden',
      height: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
    }), // initial
    animate(
      timing,
      style({
        opacity: 1,
        height: '*',
        paddingTop: '*',
        paddingBottom: '*',
      }),
    ), // final
  ]),
  transition(':leave', [
    style({
      opacity: 1,
      height: '*',
      overflow: 'hidden',
      paddingTop: '*',
      paddingBottom: '*',
    }),
    animate(
      timing,
      style({
        opacity: 0,
        height: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
      }),
    ),
  ]),
]);
