import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'tremaze-panel-list',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-auto-rows: auto;
        grid-gap: 1rem;
        padding: 1rem;
        overflow: auto;
      }
    `,
  ],
})
export class PanelListComponent {
  @Input()
  maxColumns?: number = 1;

  @HostBinding('style.grid-template-columns')
  get gridTemplateColumns() {
    const maxColumns = this.maxColumns || 1;
    return `repeat(auto-fit, minmax(300px, ${100 / maxColumns}%))`;
  }
}
