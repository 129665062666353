<h1 mat-dialog-title>Terminnutzen wählen</h1>
<div mat-dialog-content>
  <p style="margin-bottom: 25px">
    Bitte wähle, welche Art von Termin du erstellen möchtest.
  </p>
  <tremaze-icon-text-button-group
    class="button-grid"
    [(ngModel)]="value"
    [multiple]="false"
    [canBeEmpty]="false"
    color="primary"
  >
    @if (showPreset('BLOCKER')) {
      <button tremaze-icon-text-toggle-button value="BLOCKER">
        <span class="lnr lnr-calendar-cross" tremazeIconTextButtonIcon></span>
        <h3 tremazeIconTextButtonTitle>Terminblocker</h3>
        <p tremazeIconTextButtonText>
          Dieser Terminslot ist für die interne Terminvergabe vorgesehen.
          Solange keine Klient*innen zugeordnet wurden, ist der Termin nur für
          Mitarbeitende sichtbar.
        </p>
      </button>
    }

    @if (showPreset('OPTION')) {
      <button tremaze-icon-text-toggle-button value="OPTION">
        <span class="lnr lnr-calendar-full" tremazeIconTextButtonIcon></span>
        <h3 tremazeIconTextButtonTitle>Terminoption</h3>
        <p tremazeIconTextButtonText>
          Dieser Termin ist für die selbstständige Buchung durch Klient*innen
          auf der Website und in der App gedacht.
        </p>
      </button>
    }

    @if (showPreset('TEAM')) {
      <button tremaze-icon-text-toggle-button value="TEAM">
        <span class="lnr lnr-group-work" tremazeIconTextButtonIcon></span>
        <h3 tremazeIconTextButtonTitle>Einen Termin im Team planen</h3>
        <p tremazeIconTextButtonText>
          Dieser Termin ist für interne Besprechungen zwischen Mitarbeitenden
          gedacht.
        </p>
      </button>
    }

    @if (showPreset('NONE')) {
      <button tremaze-icon-text-toggle-button value="NONE">
        <span class="lnr lnr-calendar-empty" tremazeIconTextButtonIcon></span>
        <h3 tremazeIconTextButtonTitle>Keine Auswahl</h3>
        <p tremazeIconTextButtonText>
          Plane einen Termin ohne Voreinstellungen.
        </p>
      </button>
    }
  </tremaze-icon-text-button-group>
</div>

<div mat-dialog-actions>
  <button mat-raised-button style="flex: 1" [matDialogClose]="undefined">
    Abbrechen
  </button>
  <button
    mat-raised-button
    color="primary"
    style="flex: 1"
    [disabled]="isEmpty"
    [matDialogClose]="value[0]"
  >
    Weiter
  </button>
</div>
