import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'tremaze-document-editor-document-is-blocked-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './document-editor-document-is-blocked-dialog.component.html',
  styleUrl: './document-editor-document-is-blocked-dialog.component.scss',
})
export class DocumentEditorDocumentIsBlockedDialogComponent {}
