import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import {
  Information,
  InformationTag,
} from '@tremaze/shared/feature/information/types';
import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsGetFreshOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSource,
  DefaultCRUDDataSourceImpl,
  DefaultREADDataSourceWithPagination,
  DefaultREADDataSourceWithPaginationImpl,
} from '@tremaze/shared/util-http';
import { Pagination } from '@tremaze/shared/models';
import { InstitutionContextService } from '@tremaze/shared/feature/institution/shared/singletons';
import { switchMap } from 'rxjs/operators';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';

interface InformationCreateDTO
  extends Pick<
    Information,
    'description' | 'title' | 'subtitle' | 'visibleForFamily' | 'secure'
  > {
  showFrom: string;
  showUntil: string;
  categoryId: string;
  instIds: string[];
  userIds: string[];
  titleImageId: string;
  informationFileIds: string[];
  informationMediaIds: string[];
  tagIds: string[];
  departmentIds: string[];
  public: boolean;
  userTypeIds: string[];
  contextInstitutionId?: string;
  divisionIds: string[];
}

interface InformationEditDTO extends InformationCreateDTO {
  informationId: string;
}

function informationToCreateDTO(
  i: Information,
  options?: DataSourceMethodsCreateOptions<Information>,
): InformationCreateDTO {
  return {
    categoryId: i.category?.id,
    title: i.title,
    titleImageId: i.titleImage?.id,
    description: i.description,
    informationMediaIds: i.informationMedia.map((d) => d.id),
    informationFileIds: i.informationFiles?.map((d) => d.id),
    showUntil: i.showUntil?.toISOString(),
    showFrom: i.showFrom?.toISOString(),
    instIds: i.institutions?.map((inst) => inst.id) ?? options?.instIds,
    userIds: i.users?.map((u) => u.id),
    tagIds: i.tags?.map((t) => t.id),
    subtitle: i.subtitle,
    departmentIds: i.departments?.map((d) => d.id),
    visibleForFamily: i.visibleForFamily,
    public: i.isPublic,
    userTypeIds: i.userTypes.map((u) => u.id),
    secure: i.secure,
    contextInstitutionId: i.contextInstitution?.id,
    divisionIds: i.divisions?.map((d) => d.id),
  };
}

function informationToEditDTO(
  i: Information,
  options?: DataSourceMethodsCreateOptions<Information>,
): InformationEditDTO {
  return {
    ...informationToCreateDTO(i, options),
    informationId: i.id,
  };
}

@Injectable({ providedIn: 'root' })
export abstract class InformationREADDataSource extends DefaultREADDataSourceWithPagination<Information> {
  abstract getCommentsEnabledForInformationById(
    id: string,
  ): Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class RemoteInformationREADDataSourceDefaultImpl
  extends DefaultREADDataSourceWithPaginationImpl<Information>
  implements InformationREADDataSource
{
  deserializer = Information.deserialize;
  controller = '/public/information';

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  getCommentsEnabledForInformationById(id: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.controller}/${id}/comments/enabled`);
  }
}

@Injectable({ providedIn: 'root' })
export abstract class InformationCRUDDataSource
  extends DefaultCRUDDataSource<Information>
  implements InformationREADDataSource
{
  abstract setPublishedById(
    id: string,
    published: boolean,
    notifyUsers?: boolean,
  ): Observable<boolean>;

  /**
   *
   * @param id The id of the information object
   * @returns {Observable<boolean>} A stream emitting one boolean indicating whether comments are activated for this information or not
   */
  abstract getCommentsEnabledForInformationById(
    id: string,
  ): Observable<boolean>;

  /**
   * Updates the activated status for an information object
   * @param id The id of the information object
   * @param enabled Whether the comments are to be enabled
   * @returns {Observable<boolean>} A stream emitting one boolean indicating whether the action was performed successfully
   */
  abstract setCommentsEnabledForInformationById(
    id: string,
    enabled: boolean,
  ): Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class RemoteInformationCRUDDataSourceDefaultImpl
  extends DefaultCRUDDataSourceImpl<Information>
  implements InformationCRUDDataSource
{
  deserializer = Information.deserialize;
  controller = '/information';
  supportsGlobalFlag = false;
  filterFields = ['TITLE'];

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  setCommentsEnabledForInformationById(
    id: string,
    enabled: boolean,
  ): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.controller}/${id}/comments/enable`,
      null,
      { params: { enabled } },
    );
  }

  create(
    i: Information,
    options?: DataSourceMethodsCreateOptions<Information>,
  ): Observable<Information> {
    return super.create(informationToCreateDTO(i, options) as never, options);
  }

  edit(
    i: Information,
    options?: DataSourceMethodsEditOptions<Information>,
  ): Observable<Information> {
    return super.edit(informationToEditDTO(i, options) as never, options);
  }

  setPublishedById(
    id: string,
    published: boolean,
    notifyUsers = true,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.controller}/${id}/${!published ? 'un' : ''}publish`,
      null,
      { params: { notifyUsers: notifyUsers.toString() } },
    );
  }

  getCommentsEnabledForInformationById(id: string): Observable<boolean> {
    return this.http.get<boolean>(`/public/information/${id}/comments/enabled`);
  }
}

@Injectable({ providedIn: 'root' })
export class RemotePublicInformationDataSource extends DefaultREADDataSourceWithPaginationImpl<Information> {
  deserializer = Information.deserialize;
  controller = '/public/information';

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
    @Optional() protected authService: AuthV2Service,
    @Optional() private institutionContextService?: InstitutionContextService,
  ) {
    super();
  }

  private get instId$() {
    return (
      this.institutionContextService?.currentInstitutionId$ || of(undefined)
    );
  }

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions,
    tagsFilter?: InformationTag[],
  ): Observable<Pagination<Information>> {
    return this.instId$.pipe(
      switchMap((instId) => {
        return super.getPaginated({
          overrideController: this.authService.authenticated
            ? '/information'
            : undefined,
          endpoint: '/all',
          q: {
            ...(options?.q || {}),
            ...(instId && { instId: instId }),
            ...(!instId && { tenantView: 'true' }),
            tags: (tagsFilter || []).map((t) => t.id),
          },
          filter: {
            sort: 'meta.insertDate',
            sortDirection: 'desc',
          },
          ...(options || {}),
        });
      }),
    );
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions,
  ): Observable<Information> {
    return super.getFreshById(id, {
      ...(options ?? {}),
      endpoint: options?.endpoint ?? '/get',
    });
  }
}

@Injectable({ providedIn: 'root' })
export abstract class InformationTagReadDataSource extends DefaultREADDataSourceWithPaginationImpl<InformationTag> {
  protected deserializer = InformationTag.deserialize;
}

interface CreateTagDTO {
  name: string;
  instId: string;
}

interface EditTagDTO {
  name: string;
  tagId: string;
}

@Injectable({ providedIn: 'root' })
export class RemoteInformationTagDataSource extends DefaultCRUDDataSourceImpl<InformationTag> {
  deserializer = InformationTag.deserialize;
  controller = '/tags';

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  create(
    { name }: InformationTag,
    options?: DataSourceMethodsCreateOptions<InformationTag>,
  ): Observable<InformationTag> {
    const payload: CreateTagDTO = {
      name,
      instId: options?.instIds?.[0],
    };
    return super.create(payload, options);
  }

  edit(
    i: InformationTag,
    options?: DataSourceMethodsEditOptions<InformationTag>,
  ): Observable<InformationTag> {
    const payload: EditTagDTO = {
      tagId: i.id,
      name: i.name,
    };
    return super.edit(payload as never, options);
  }
}

@Injectable({ providedIn: 'root' })
export class RemoteInformationTagPublicDataSource extends InformationTagReadDataSource {
  protected controller = '/public/informationTag';

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
    @Optional() private institutionContextService?: InstitutionContextService,
  ) {
    super();
  }

  getPaginated(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<InformationTag>> {
    const instId$ =
      this.institutionContextService?.currentInstitutionId$ || of(null);
    return instId$.pipe(
      switchMap((instId) =>
        super.getPaginated({
          ...(options || {}),
          q: { ...(options?.q || {}), instId },
          endpoint: '/all',
        }),
      ),
    );
  }
}
