import {
  Component,
  Inject,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { EventTemplate } from '@tremaze/shared/feature/event/types';
import { FormGroup } from '@ngneat/reactive-forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { EVENT_CATEGORY_TYPE_PROVIDER } from '@tremaze/shared/feature/category/module-config';
import { EventTemplateEditFormService } from './event-template-edit-form.service';
import {
  EVENT_MODULE_CONFIG,
  EventModuleConfig,
} from '@tremaze/shared/feature/event/module-config';

@Component({
  selector: 'tremaze-event-template-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./template-form.styles.css'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    EVENT_CATEGORY_TYPE_PROVIDER,
  ],
  animations: [
    trigger('formRow', [
      transition(':enter', [
        style({ height: '0px', opacity: 0 }),
        animate('.3s ease-out', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('.3s ease-in', style({ height: '0px', opacity: 0 })),
      ]),
    ]),
  ],
})
export class EventTemplateEditComponent<T extends EventTemplate> {
  constructor(
    @Optional()
    @Inject(EVENT_MODULE_CONFIG)
    private readonly moduleConfig?: EventModuleConfig,
  ) {}

  get isScheduleDisabled(): boolean {
    return this.moduleConfig?.scheduleDisabled;
  }

  get formGroup(): FormGroup<T> {
    return this.eventTemplateForm?.formGroup as any;
  }
  @Input() eventTemplateForm: EventTemplateEditFormService;
}
