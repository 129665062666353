import { inject, Injectable } from '@angular/core';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilKeyChanged,
  map,
  Observable,
  scan,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import {
  PersonalConfig,
  PersonalConfigAttributeFilterSetting,
  PersonalConfigUser,
} from './types/personal-config';
import { Time } from '@angular/common';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';
import { PersonalConfigDataSource } from './data-access/data-source';

@Injectable({
  providedIn: 'root',
})
export class PersonalConfigService {
  private readonly _dataSource = inject(PersonalConfigDataSource);
  private readonly _authService = inject(AuthV2Service);

  private readonly _personalConfig$ =
    new BehaviorSubject<PersonalConfig | null>(null);

  get personalConfig$(): Observable<PersonalConfig | null> {
    return this._personalConfig$.asObservable();
  }

  private readonly _shouldReload$ = combineLatest([
    this._authService.activeTenant$.pipe(distinctUntilKeyChanged('id')),
    this._authService.authenticatedUser$.pipe(
      distinctUntilKeyChanged('userId'),
    ),
  ]);

  private _saveConfig$ = new Subject<PersonalConfig>();
  private _updateConfig$ = new Subject<Partial<PersonalConfig>>();

  constructor() {
    this._shouldReload$.subscribe(() =>
      this._dataSource
        .getPersonalConfig$()
        .subscribe((r) => this._personalConfig$.next(r)),
    );

    this._updateConfig$
      .pipe(
        scan(
          (acc, curr) => ({ ...acc, ...curr }),
          {} as Partial<PersonalConfig>,
        ),
        tap((config) => {
          if (!this._personalConfig$.value) {
            return;
          }

          const result = {
            ...this._personalConfig$.value,
            ...config,
          };

          this._personalConfig$.next(result);
          this._saveConfig$.next(result);
        }),
      )
      .subscribe();

    this._saveConfig$
      .pipe(
        debounceTime(750),
        switchMap((config) => this._dataSource.saveConfig(config)),
      )
      .subscribe();
  }

  updateConfig(config: Partial<PersonalConfig>): void {
    this._updateConfig$.next(config);
  }

  private _isTimeValid(
    time?: Time | { hours: unknown; minutes: unknown },
  ): boolean {
    return (
      !!time &&
      typeof time.hours === 'number' &&
      typeof time.minutes === 'number'
    );
  }

  getAttributeFilterSetting$<T extends keyof PersonalConfig['filterSettings']>(
    key: T,
    attribute: string,
  ): Observable<PersonalConfigAttributeFilterSetting | undefined> {
    return this.personalConfig$.pipe(
      filterNotNullOrUndefined(),
      map(
        (r) =>
          r.filterSettings[key][
            attribute
          ] as PersonalConfigAttributeFilterSetting,
      ),
    );
  }

  updateCoreWorkingHours(start: Time, end: Time): void {
    if (!this._isTimeValid(start) || !this._isTimeValid(end)) {
      return;
    }
    this.updateConfig({
      coreWorkingHoursStart: start,
      coreWorkingHoursEnd: end,
    });
  }

  resetCoreWorkingHours(): void {
    this.updateConfig({
      coreWorkingHoursStart: undefined,
      coreWorkingHoursEnd: undefined,
    });
  }

  updateTeamMembers(teamMembers: PersonalConfigUser[]): void {
    this.updateConfig({ teamMembers });
  }

  updateFilterSetting<T extends keyof PersonalConfig['filterSettings']>(
    key: T,
    filterSetting: Partial<PersonalConfig['filterSettings'][T]>,
  ): void {
    if (!this._personalConfig$.value) {
      return;
    }

    this.updateConfig({
      filterSettings: {
        ...this._personalConfig$.value.filterSettings,
        [key]: {
          ...this._personalConfig$.value.filterSettings[key],
          ...filterSetting,
        },
      },
    });
  }
}
