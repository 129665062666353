<mat-card
  appearance="raised"
  class="overlay-card"
  [@transformPanel]="'showing'"
  (@transformPanel.done)="filterInput.focus()"
>
  <mat-card-content>
    <mat-form-field
      class="example-full-width"
      style="position: sticky; width: 100%; margin: 10px 0"
      subscriptSizing="dynamic"
      appearance="outline"
    >
      <mat-label>{{ label }}</mat-label>
      <input type="text" matInput [matAutocomplete]="auto" #filterInput />
      <span
        *ngIf="inputPrefixIcon"
        matPrefix
        [class]="inputPrefixIcon"
        style="margin-right: 0.5rem; margin-left: 0.5rem"
      ></span>
      <mat-spinner
        *ngIf="isLoading$ | async"
        matSuffix
        mode="indeterminate"
        style="margin-right: 1rem"
        [diameter]="20"
        color="accent"
      ></mat-spinner>
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayWith ?? null"
        (optionSelected)="onOptionSelected($event.option.value)"
      >
        <mat-option
          *ngIf="showAddOptionButton"
          (click)="onClickAddOption($event)"
        >
          <mat-icon style="margin-right: 8px">
            <span class="lnr lnr-plus-circle"></span>
          </mat-icon>
          <span>Neu anlegen</span>
        </mat-option>

        <mat-option *ngIf="hasError$ | async; else noError" disabled>
          <mat-icon color="warn" style="margin-right: 0.5rem">
            <span class="lnr lnr-notification"></span>
          </mat-icon>
          Es ist ein Fehler aufgetreten
        </mat-option>
        <ng-template #noError>
          <mat-option *ngIf="showResetButton" [value]="null"
            >Keine Auswahl</mat-option
          >

          <mat-option *ngIf="isEmpty$ | async; else hasValue" disabled
            >Keine Ergebnisse</mat-option
          >

          <ng-template #hasValue>
            <mat-option
              *ngFor="let option of filteredOptions$ | async"
              [value]="option"
            >
              <div style="display: flex; padding: 0.3rem 0">
                <tremaze-circle-avatar
                  *ngIf="getAvatar"
                  [file]="getAvatar(option)"
                  [fallbackInitials]="
                    getAvatarFallbackInitials?.(option) ?? '??'
                  "
                ></tremaze-circle-avatar>
                <div
                  style="
                    display: inline-flex;
                    flex-direction: column;
                    height: 100%;
                    padding-top: 0.3rem;
                  "
                >
                  <b>{{ displayWith?.(option) ?? option }}</b>
                  <small *ngIf="subtitleWith?.(option) as subtitle">{{
                    subtitle
                  }}</small>
                </div>
              </div>
            </mat-option>
          </ng-template>
        </ng-template>
      </mat-autocomplete>
    </mat-form-field>
  </mat-card-content>
</mat-card>
