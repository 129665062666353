import { User } from '@tremaze/shared/feature/user/types';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

@staticImplements<Deserializable<JitsiMeetingRoom>>()
export class JitsiMeetingRoom {
  constructor(
    readonly id: string,
    readonly allowedUsers: User[],
    readonly moderators: User[],
    name: string,
    nameSuffix: string,
    readonly code?: string,
  ) {}

  static deserialize(data: any): null | JitsiMeetingRoom {
    return !data
      ? null
      : new JitsiMeetingRoom(
          data.id,
          data.allowedUsers?.map(User.deserialize) ?? [],
          data.moderators?.map(User.deserialize) ?? [],
          data.name,
          data.nameSuffix,
          data.code,
        );
  }
}

export interface JitsiToken {
  readonly roomName: string;
  readonly token: string;
  readonly domain: string;
}
