import {
  AfterViewInit,
  booleanAttribute,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DocumentEditorContainerComponent,
  DocumentEditorContainerModule,
  PrintService,
  ToolbarItem,
  ToolbarService,
} from '@syncfusion/ej2-angular-documenteditor';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  distinctUntilChanged,
  finalize,
  map,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { CustomToolbarItemModel } from '@syncfusion/ej2-angular-pdfviewer';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { DocumentEditorWebsocketService } from './document-editor-websocket.service';
import { outputFromObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tremaze-document-editor',
  standalone: true,
  imports: [
    CommonModule,
    DocumentEditorContainerModule,
    MatProgressSpinnerModule,
  ],
  template: `
    @if (isBlocked$ | async) {
      <div class="blocked-hint">
        Dieses Dokument wird gerade von einer anderen Person bearbeitet und ist
        daher blockiert. Bitte warte, bis es wieder verfügbar ist oder komme
        später wieder.
      </div>
    }

    <ejs-documenteditorcontainer
      (toolbarClick)="onToolbarClick($event)"
      [currentUser]="(currentUserName$ | async) ?? ''"
      [documentEditorSettings]="{ printDevicePixelRatio: 5 }"
      [enableToolbar]="true"
      [toolbarItems]="additionalToolbarItems"
      locale="de-DE"
      serviceUrl="https://wordprocessor.tagea.app/api/documenteditor/"
    ></ejs-documenteditorcontainer>
    @if (isLoading$ | async) {
      <div
        style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(255, 255, 255, 0.8); display: flex; justify-content: center; align-items: center;"
      >
        <mat-spinner></mat-spinner>
      </div>
    }
  `,
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
      }

      ejs-documenteditorcontainer {
        display: block;
        height: 100% !important;
        width: 100% !important;
      }

      .blocked-hint {
        text-align: center;
        padding: 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: rgba(255, 255, 255);
      }
    `,
  ],
  providers: [ToolbarService, PrintService, DocumentEditorWebsocketService],
})
export class DocumentEditorComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private readonly _websocketService = inject(DocumentEditorWebsocketService);
  private readonly _http = inject(HttpClient);

  readonly isBlocked$ = this._websocketService.isBlocked$;

  readonly currentUserName$ = inject(AuthV2Service).authenticatedUser$.pipe(
    map((u) => u.firstName + ' ' + u.lastName),
  );

  get proxyFixedDocumentUrl() {
    if (!this.documentUrl) {
      return;
    }
    return this.documentUrl.replace(
      'http://localhost:4200/api',
      'https://api.dev.cloud.tagea.app',
    );
  }

  @Input() set fileId(value: string | undefined) {
    this._websocketService.setFileId(value);
  }

  private _documentUrl?: string;

  get documentUrl(): string | undefined {
    return this._documentUrl;
  }

  @Input({ required: true })
  set documentUrl(value: string) {
    this._documentUrl = value;
    const proxyFixedDocumentUrl = this.proxyFixedDocumentUrl;
    if (proxyFixedDocumentUrl) {
      this.loadDocument(proxyFixedDocumentUrl);
    }
  }

  @Input({ required: true }) documentName = 'document.pdf';
  @Input() additionalToolbarItems: (CustomToolbarItemModel | ToolbarItem)[] =
    [];

  private _canWrite = true;

  @Input({ transform: booleanAttribute }) set canWrite(value: boolean) {
    this._canWrite = value;
    if (value) {
      this._enableEditor();
    } else {
      this._disableEditor();
    }
  }

  @Output() additionalToolbarItemClicked = new EventEmitter<string>();
  @Output()
  readonly contentChange = new EventEmitter<void>();
  readonly requestClose = outputFromObservable(
    this._websocketService.requestClose$,
  );

  @ViewChild(DocumentEditorContainerComponent, { static: true })
  documentEditor?: DocumentEditorContainerComponent;

  private _destroyed$ = new Subject<void>();
  readonly isLoading$ = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    this._websocketService.isBlocked$
      .pipe(distinctUntilChanged())
      .subscribe((isBlocked) => {
        if (isBlocked) {
          this._disableEditor();
        } else {
          this._enableEditor();
        }
      });
  }

  ngAfterViewInit() {
    if (this.documentEditor) {
      (this.documentEditor.contentChange as EventEmitter<unknown>).subscribe(
        () => {
          this.contentChange.emit();
        },
      );
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    this.isLoading$.complete();
  }

  private _editorEnabled = true;

  get editorEnabled(): boolean {
    return this._editorEnabled;
  }

  private _disableEditor() {
    this._editorEnabled = false;
    if (this.documentEditor) {
      this.documentEditor.enableToolbar = false;
      this.documentEditor.restrictEditing = true;
    }
  }

  private _enableEditor() {
    this._editorEnabled = true;
    if (this.documentEditor && !this.documentEditor.enableToolbar) {
      this.documentEditor.enableToolbar = true;
      this.documentEditor.restrictEditing = false;
      const proxyFixedDocumentUrl = this.proxyFixedDocumentUrl;
      if (proxyFixedDocumentUrl) {
        this.loadDocument(proxyFixedDocumentUrl);
      }
    }
  }

  public onToolbarClick(args: ClickEventArgs): void {
    this.additionalToolbarItemClicked.emit(args.item.id);
  }

  public print(): void {
    if (this.documentEditor) {
      let color: string | undefined;
      if (
        this.documentEditor.documentEditorSettings &&
        this.documentEditor!.documentEditorSettings!.formFieldSettings
      ) {
        color =
          this.documentEditor.documentEditorSettings.formFieldSettings
            .shadingColor;
        this.documentEditor.documentEditorSettings.formFieldSettings.shadingColor =
          '#ffffff';
      }
      setTimeout(() => {
        this.documentEditor!.documentEditor.print();
        if (color) {
          this.documentEditor!.documentEditorSettings!.formFieldSettings!.shadingColor =
            color;
        }
      });
    }
  }

  private loadDocument(url: string) {
    this.isLoading$.next(true);
    this._http
      .get(url, {
        responseType: 'blob',
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
      .pipe(
        switchMap((r) => {
          // send the file back to api to convert it to .sfdt format
          const data = new FormData();
          data.append('file', r as any);
          return this._http.post<{ sfdt: string }>(
            '/syncfusion/import/word',
            data,
            {
              responseType: 'json',
            },
          );
        }),
        takeUntil(this._destroyed$),
        finalize(() => this.isLoading$.next(false)),
      )
      .subscribe((response) => {
        this.documentEditor!.documentEditor.open(response as any);
      });
  }

  async save() {
    if (!this.documentEditor) {
      return;
    }
    const blob = await this.documentEditor.documentEditor.saveAsBlob('Docx');
    return new Blob([blob], { type: 'application/msword' });
  }
}
