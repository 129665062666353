import { Component } from '@angular/core';
import { CustomFormEditComponentService } from '../custom-form-edit-component.service/custom-form-edit-component.service';
import { fieldTypeIcons, fieldTypeOptions } from '../field-type-options';
import { CustomFormFieldType } from '@tremaze/shared/feature/custom-forms/types';

@Component({
  selector: 'tremaze-custom-form-add-new-field-button',
  templateUrl: './custom-form-add-new-field-button.component.html',
})
export class CustomFormAddNewFieldButtonComponent {
  readonly fieldTypeOptions = fieldTypeOptions;
  readonly fieldTypeIcons = fieldTypeIcons;

  constructor(readonly service: CustomFormEditComponentService) {}

  onClickOption(type: CustomFormFieldType): void {
    this.service.addNewField(type);
  }
}
