import { NgModule } from '@angular/core';
import { DragZoneDirective } from './drag-zone.directive';
import { DragZoneItemDirective } from './drag-zone-item.directive';
import { DragZonePreviewComponent } from './drag-zone-preview/drag-zone-preview.component';
import { DropZoneDirective } from './drop-zone.directive';

@NgModule({
  imports: [
    DragZoneDirective,
    DragZoneItemDirective,
    DragZonePreviewComponent,
    DropZoneDirective,
  ],
  exports: [
    DragZoneDirective,
    DragZoneItemDirective,
    DragZonePreviewComponent,
    DropZoneDirective,
  ],
})
export class DragZoneModule {}
