import { Directive, Input, TemplateRef } from '@angular/core';
import { DataTableStore } from './data-table.store';
import { map, take, tap } from 'rxjs/operators';
import { ensureArray } from '@tremaze/shared/util-utilities';

@Directive({
  selector: '[tremazeDataTableCustomFilter]',
})
export class DataTableCustomFilterDirective {
  get queryParamValue(): unknown {
    return this._queryParamValue;
  }

  @Input() set queryParamValue(value: unknown) {
    this._store.state$
      .pipe(
        map((s) => s.queryParameters),
        take(1),
        tap((params) => {
          const paramsNames = ensureArray(this.queryParamName);
          const paramsMap = {};
          if (paramsNames.length === 1) {
            paramsMap[paramsNames[0]] = value;
          } else {
            const values = ensureArray(value);
            for (let i = 0; i < paramsNames.length; i++) {
              if (values.length <= i) {
                paramsMap[paramsNames[i]] = undefined;
                continue;
              }
              paramsMap[paramsNames[i]] = values[i];
            }
          }
          this._store.queryParametersUpdate({
            ...params,
            ...paramsMap,
          });
        }),
      )
      .subscribe();
    this._queryParamValue = value;
  }

  @Input() queryParamName: string | string[];
  private _queryParamValue: unknown;

  constructor(
    public readonly template: TemplateRef<any>,
    private _store: DataTableStore<any>,
  ) {}
}
