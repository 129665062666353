import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircleAvatarComponent } from './circle-avatar.component';
import { SharedFileStorageUiFilePreviewOverlayModule } from '@tremaze/shared/feature/file-storage/ui/file-preview-overlay';
import { SharedFileStorageServicesModule } from '@tremaze/shared/feature/file-storage/services';
import { SharedUiInitialsCircleModule } from '@tremaze/shared/ui/initials-circle';
import { CircleAvatarBadgeDirective } from './circle-avatar-badge.directive';

@NgModule({
  imports: [
    CommonModule,
    SharedFileStorageUiFilePreviewOverlayModule,
    SharedFileStorageServicesModule,
    SharedUiInitialsCircleModule,
  ],
  declarations: [CircleAvatarComponent, CircleAvatarBadgeDirective],
  exports: [CircleAvatarComponent, CircleAvatarBadgeDirective],
})
export class SharedFileStorageUiCircleAvatarModule {}
