import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  State,
  TENANT_FEATURE_KEY,
  tenantAdapter,
  TenantPartialState,
} from './tenant.reducer';

// Lookup the 'Tenant' feature state managed by NgRx
export const getTenantState = createFeatureSelector<TenantPartialState, State>(
  TENANT_FEATURE_KEY,
);

const { selectAll, selectEntities } = tenantAdapter.getSelectors();

export const getTenantLoaded = createSelector(
  getTenantState,
  (state: State) => state.loaded,
);

export const getTenantError = createSelector(
  getTenantState,
  (state: State) => state.error,
);

export const getAllTenant = createSelector(getTenantState, (state: State) =>
  selectAll(state),
);

export const getTenantEntities = createSelector(
  getTenantState,
  (state: State) => selectEntities(state),
);

export const getSelectedId = createSelector(
  getTenantState,
  (state: State) => state.selectedId,
);

export const getProjects = createSelector(
  getTenantState,
  (state) => state.projects,
);

export const getSelected = createSelector(
  getTenantEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId],
);
