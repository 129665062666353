import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface DirStorageDataSourceConfig {
  basePath?: string | Observable<string>;
}

export const DIR_STORAGE_DATA_SOURCE_CONFIG =
  new InjectionToken<DirStorageDataSourceConfig>(
    'DIR_STORAGE_DATA_SOURCE_CONFIG',
  );
