<button mat-menu-item (click)="showFilePreview.emit()">
      <span class="folder-view-file-contextmenu__Item__Content-Wrapper">
        <tremaze-icon
          class="folder-view-file-contextmenu__Item__Icon"
          icon="lnr-eye"
        ></tremaze-icon>
        <span>Datei ansehen</span>
      </span>
</button>

@if (file?.absolutePath) {
  <button mat-menu-item (click)="navigateToPath.emit()">
        <span class="folder-view-file-contextmenu__Item__Content-Wrapper">
          <tremaze-icon
            class="folder-view-file-contextmenu__Item__Icon"
            icon="lnr-exit-right"
          ></tremaze-icon>
          <span>Gehe zu Ordner</span>
        </span>
  </button>
}

<button mat-menu-item (click)="copyFileLink.emit()">
      <span class="folder-view-file-contextmenu__Item__Content-Wrapper">
        <tremaze-icon
          class="folder-view-file-contextmenu__Item__Icon"
          icon="lnr-share"
        ></tremaze-icon>
        <span>Öffentlichen Link kopieren</span>
      </span>
</button>

<button mat-menu-item (click)="downloadFile.emit()">
      <span class="folder-view-file-contextmenu__Item__Content-Wrapper">
        <tremaze-icon
          class="folder-view-file-contextmenu__Item__Icon"
          icon="lnr-download2"
        ></tremaze-icon>
        <span>Herunterladen</span>
      </span>
</button>

@if (canWrite) {
  <button mat-menu-item (click)="duplicateFile.emit()">
        <span class="folder-view-file-contextmenu__Item__Content-Wrapper">
          <tremaze-icon
            class="folder-view-file-contextmenu__Item__Icon"
            icon="lnr-copy"
          ></tremaze-icon>
          <span>Datei duplizieren</span>
        </span>
  </button>

  <button mat-menu-item (click)="renameFile.emit()">
        <span class="folder-view-file-contextmenu__Item__Content-Wrapper">
          <tremaze-icon
            class="folder-view-file-contextmenu__Item__Icon"
            icon="lnr-pencil2"
          ></tremaze-icon>
          <span>Datei umbenennen</span>
        </span>
  </button>
}

@if (canDelete) {
  <button mat-menu-item (click)="deleteFile.emit()">
        <span
          class="folder-view-file-contextmenu__Item__Content-Wrapper"
          style="color: red"
        >
          <tremaze-icon
            class="folder-view-file-contextmenu__Item__Icon"
            icon="lnr-trash2"
          ></tremaze-icon>
          <span>Löschen</span>
        </span>
  </button>
}
