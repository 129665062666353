import { PageGenerator } from '../helpers';
import {
  Approval,
  ApprovalStatus,
  PartialBudget,
} from '@tremaze/shared/feature/approval/types';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { InstitutionFixtures } from '../institution/institution';
import { UserFixtures } from '../user/user';
import { CostBearerFixtures } from './cost-bearer';
import { TremazeDate } from '@tremaze/shared/util-date';
import { FileStorageFixtures } from '../file-storage';
import { ApprovalServiceFixtures } from './approval-service';

class ApprovalFixturePageGenerator extends PageGenerator<Approval> {
  override generateByIndex(index: number): Approval {
    return TestApprovalFixtures.generateApproval(index);
  }
}

export abstract class TestApprovalFixtures {
  private static _mapIdToStatus = (id: number): ApprovalStatus => {
    if (id % 4 === 0) {
      return 'EXPIRED';
    }
    if (id % 3 === 0) {
      return 'REJECTED';
    }
    if (id % 2 === 0) {
      return 'APPROVED';
    }
    return 'REQUESTED';
  };

  static generateApproval = (id: number): Approval => {
    const status = TestApprovalFixtures._mapIdToStatus(id);
    const isApproved = status === 'APPROVED';
    const baseDate = TremazeDate.getNow();
    return new Approval(
      id.toString(),
      InstitutionFixtures.institution1,
      UserFixtures.generateUser(id),
      CostBearerFixtures.costBearer1,
      `File Number ${id}`,
      baseDate,
      isApproved ? baseDate.subtract(id, 'day') : undefined,
      isApproved ? baseDate.add(id, 'day') : undefined,
      baseDate.subtract(id, 'day'),
      [1, 2, 3].map((i) => TestApprovalFixtures.generatePartialBudget(i)),
      FileStorageFixtures.fileStorage1,
      FileStorageFixtures.fileStorage2,
      TestApprovalFixtures._mapIdToStatus(id),
      20,
      5,
      15.5,
    );
  };

  static generatePartialBudget = (id: number) =>
    new PartialBudget(
      ApprovalServiceFixtures.generateApprovalService(id),
      TremazeDate.getNow().subtract(id, 'day'),
      TremazeDate.getNow().add(id, 'day'),
      id * 2,
      id * 1.5,
      40,
      32,
      id.toString(),
      10,
      5,
    );

  private static _approvalStubPageGenerator =
    new ApprovalFixturePageGenerator();

  static generateApprovalPage = (options: DataSourceMethodsPaginatedOptions) =>
    TestApprovalFixtures._approvalStubPageGenerator.generatePage(options);

  static readonly approval1: Approval =
    TestApprovalFixtures.generateApproval(1);
  static readonly approval2: Approval =
    TestApprovalFixtures.generateApproval(2);
  static readonly approval3: Approval =
    TestApprovalFixtures.generateApproval(3);
  static readonly approval4: Approval =
    TestApprovalFixtures.generateApproval(4);
  static readonly approval5: Approval =
    TestApprovalFixtures.generateApproval(5);

  static readonly approvals = [
    TestApprovalFixtures.approval1,
    TestApprovalFixtures.approval2,
    TestApprovalFixtures.approval3,
    TestApprovalFixtures.approval4,
    TestApprovalFixtures.approval5,
  ];

  static readonly partialBudget1 =
    TestApprovalFixtures.generatePartialBudget(1);
  static readonly partialBudget2 =
    TestApprovalFixtures.generatePartialBudget(2);
  static readonly partialBudget3 =
    TestApprovalFixtures.generatePartialBudget(3);
  static readonly partialBudget4 =
    TestApprovalFixtures.generatePartialBudget(4);
  static readonly partialBudget5 =
    TestApprovalFixtures.generatePartialBudget(5);

  static readonly partialBudgets = [
    TestApprovalFixtures.partialBudget1,
    TestApprovalFixtures.partialBudget2,
    TestApprovalFixtures.partialBudget3,
    TestApprovalFixtures.partialBudget4,
    TestApprovalFixtures.partialBudget5,
  ];
}
