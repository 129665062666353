import { Component, Input } from '@angular/core';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

@Component({
  selector: 'tremaze-dynamic-list-item-avatar',
  template: `
    <tremaze-circle-avatar
      [file]="fileStorage"
      [radius]="18"
      [fallbackInitials]="initialsString"
    >
      <ng-content
        select="[tremazeCircleAvatarBadge]"
        ngProjectAs="[tremazeCircleAvatarBadge]"
      ></ng-content>
    </tremaze-circle-avatar>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    `,
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'dynamic-list__Item__Avatar',
  },
})
export class DynamicListItemAvatarComponent {
  @Input() fileStorage?: FileStorage;

  @Input() fallbackInitials?: string;

  get initialsString(): string {
    return this.fallbackInitials ?? '??';
  }
}
