export const syncfusionLocalizations = {
  'de-DE': {
    grid: {
      EmptyRecord: 'Keine Datensätze zum Anzeigen',
      True: 'wahr',
      False: 'falsch',
      InvalidFilterMessage: 'Ungültige Filterdaten',
      GroupDropArea:
        'Ziehen Sie eine Spaltenüberschrift hierher, um die Spalte zu gruppieren',
      UnGroup: 'Klicken Sie hier, um die Gruppierung aufzuheben',
      GroupDisable: 'Die Gruppierung ist für diese Spalte deaktiviert',
      FilterbarTitle: '"s Filterbalkenzelle',
      EmptyDataSourceError:
        'Datenquelle darf beim ersten Laden nicht leer sein, da Spalten aus der Datenquelle automatisch generiert werden',
      Add: 'Hinzufügen',
      Edit: 'Bearbeiten',
      Cancel: 'Abbrechen',
      Update: 'Aktualisieren',
      Delete: 'Löschen',
      Print: 'Drucken',
      Pdfexport: 'PDF-Export',
      Excelexport: 'Excel-Export',
      Wordexport: 'Word-Export',
      Csvexport: 'CSV-Export',
      Search: 'Suchen',
      Columnchooser: 'Spalten',
      Save: 'Speichern',
      Item: 'Datensatz',
      Items: 'Datensätze',
      EditOperationAlert: 'Keine Datensätze zum Bearbeiten ausgewählt',
      DeleteOperationAlert: 'Keine Datensätze zum Löschen ausgewählt',
      SaveButton: 'Speichern',
      OKButton: 'in Ordnung',
      CancelButton: 'Abbrechen',
      EditFormTitle: 'Details von',
      AddFormTitle: 'Neuen Datensatz hinzufügen',
      BatchSaveConfirm: 'Möchten Sie die Änderungen wirklich speichern?',
      BatchSaveLostChanges:
        'Nicht gespeicherte Änderungen gehen verloren. Sind Sie sicher, dass Sie fortfahren wollen?',
      ConfirmDelete: 'Möchten Sie den Datensatz wirklich löschen?',
      CancelEdit: 'Möchten Sie die Änderungen wirklich abbrechen?',
      ChooseColumns: 'Wählen Sie Spalten',
      SearchColumns: 'Spalten durchsuchen',
      Matchs: 'Keine Treffer gefunden',
      FilterButton: 'Filter',
      ClearButton: 'Löschen',
      StartsWith: 'Beginnt mit',
      EndsWith: 'Endet mit',
      Contains: 'Enthält',
      Equal: 'Gleich',
      NotEqual: 'Nicht gleich',
      LessThan: 'Weniger als',
      LessThanOrEqual: 'Weniger als oder gleich',
      GreaterThan: 'Grösser als',
      GreaterThanOrEqual: 'Grösser als oder gleich',
      ChooseDate: 'Wählen Sie ein Datum',
      EnterValue: 'Geben Sie den Wert ein',
      Copy: 'Kopieren',
      Group: 'Nach dieser Spalte gruppieren',
      Ungroup: 'Gruppierung nach dieser Spalte aufheben',
      autoFitAll: 'Automatisch alle Spalten anpassen',
      autoFit: 'Diese Spalte automatisch anpassen',
      Export: 'Export',
      FirstPage: 'Erste Seite',
      LastPage: 'Letzte Seite',
      PreviousPage: 'Vorherige Seite',
      NextPage: 'Nächste Seite',
      SortAscending: 'Aufsteigend sortieren',
      SortDescending: 'Absteigend sortieren',
      EditRecord: 'Datensatz bearbeiten',
      DeleteRecord: 'Datensatz löschen',
      FilterMenu: 'Filter',
      SelectAll: 'Wählen Sie Alle',
      Blanks: 'Leerzeichen',
      FilterTrue: 'Wahr',
      FilterFalse: 'Falsch',
      NoResult: 'Keine Treffer gefunden',
      ClearFilter: 'Filter löschen',
      NumberFilter: 'Anzahl Filter',
      TextFilter: 'Textfilter',
      DateFilter: 'Datumsfilter',
      DateTimeFilter: 'DatumsZeit-Filter',
      MatchCase: 'Gross- / Kleinschreibung',
      Between: 'Zwischen',
      CustomFilter: 'Benutzerdefinierte Filter',
      CustomFilterPlaceHolder: 'Geben Sie einen Wert ein',
      CustomFilterDatePlaceHolder: 'Wählen Sie ein Datum',
      AND: 'UND',
      OR: 'ODER',
      ShowRowsWhere: 'Zeilen anzeigen, in denen:',
      NotStartsWith: 'beginnt nicht mit',
      Like: 'Wie',
      NotEndsWith: 'endet nicht mit',
      NotContains: 'enthält nicht',
      IsNull: 'Null',
      NotNull: 'nicht null',
      IsEmpty: 'leer',
      IsNotEmpty: 'nicht leer',
      AddCurrentSelection: 'Aktuelle Auswahl zum Filter hinzufügen',
      UnGroupButton: 'Klicken Sie hier, um die Gruppierung aufzuheben',
      AutoFitAll: 'Automatisch alle Spalten anpassen',
      AutoFit: 'Diese Spalte automatisch anpassen',
      Clear: 'Klar',
      FilterMenuDialogARIA: 'Menüdialog filtern',
      ExcelFilterDialogARIA: 'Excel-Filterdialog',
      DialogEditARIA: 'Dialog bearbeiten',
      ColumnChooserDialogARIA: 'Spaltenauswahl',
      ColumnMenuDialogARIA: 'Spaltenmenüdialog',
      CustomFilterDialogARIA: 'Benutzerdefinierter Filterdialog',
      SortAtoZ: 'Sortiere von A bis Z',
      SortZtoA: 'Z bis A sortieren',
      SortByOldest: 'Nach Ältesten sortieren',
      SortByNewest: 'Nach Neuesten sortieren',
      SortSmallestToLargest: 'Vom Kleinsten zum Größten sortieren',
      SortLargestToSmallest: 'Vom Größten zum Kleinsten sortieren',
      Sort: 'Sortieren',
      FilterDescription:
        'Drücken Sie die Alt-Nach-unten-Taste, um das Filtermenü zu öffnen',
      SortDescription: 'Drücken Sie zum Sortieren die Eingabetaste',
      ColumnMenuDescription:
        'Drücken Sie die Alt-Nach-unten-Taste, um das Spaltenmenü zu öffnen',
      GroupDescription: 'Drücken Sie zum Gruppieren die Strg-Leertaste',
      ColumnHeader: ' Spaltenüberschrift ',
      TemplateCell: ' ist eine Vorlagenzelle',
      CommandColumnAria: 'ist die Spaltenüberschrift der Befehlsspalte ',
      DialogEdit: 'Dialog bearbeiten',
      ClipBoard: 'Zwischenablage',
      GroupButton: 'Gruppenschaltfläche',
      UnGroupAria: 'Schaltfläche zum Aufheben der Gruppierung',
      GroupSeperator: 'Trennzeichen für die gruppierten Spalten',
      UnGroupIcon: 'heben Sie die Gruppierung der gruppierten Spalte auf ',
      GroupedSortIcon: 'sortieren Sie die gruppierte Spalte ',
      GroupedDrag: 'Ziehen Sie die gruppierte Spalte',
      GroupCaption: ' ist eine Gruppenunterschriftszelle',
      CheckBoxLabel: 'Kontrollkästchen',
      Expanded: 'Erweitert',
      Collapsed: 'Zusammengebrochen',
      SelectAllCheckbox: 'Kontrollkästchen „Alle auswählen“.',
      SelectRow: 'Zeile auswählen',
    },
    pager: {
      currentPageInfo: '{0} von {1} Seiten',
      totalItemsInfo: '({0} Datensätze)',
      firstPageTooltip: 'Gehe zur ersten Seite',
      lastPageTooltip: 'Gehe zur letzten Seite',
      nextPageTooltip: 'Gehe zur nächsten Seite',
      previousPageTooltip: 'Zurück zur letzten Seite',
      nextPagerTooltip: 'Gehe zu den nächsten Pager-Elementen',
      previousPagerTooltip: 'Gehen Sie zu den vorherigen Pager-Elementen',
      pagerDropDown: 'Datensätze pro Seite',
      pagerAllDropDown: 'Datensätze',
      All: 'Alle',
      totalItemInfo: '({0} Datensatz)',
      Container: 'Pager-Container',
      Information: 'Pager-Informationen',
      ExternalMsg: 'Pager-externe Nachricht',
      Page: 'Buchseite ',
      Of: ' von ',
      Pages: ' Seiten',
    },
    kanban: {
      items: 'Artikel',
      min: 'Mindest',
      max: 'Max',
      cardsSelected: 'Karten ausgewählt',
      addTitle: 'Neue Karte hinzufügen',
      editTitle: 'Kartendetails bearbeiten',
      deleteTitle: 'Karte löschen',
      deleteContent: 'Möchten Sie diese Karte wirklich löschen?',
      save: 'Speichern',
      delete: 'Löschen',
      cancel: 'Abbrechen',
      yes: 'Ja',
      no: 'Nein',
      close: 'Schließen',
      noCard: 'Keine Karten zum Anzeigen',
      unassigned: 'Nicht zugewiesen',
      cards: 'Karte',
    },
    dropdowns: {
      noRecordsTemplate: 'Keine Datensätze gefunden',
      actionFailureTemplate: 'Die Anfrage ist fehlgeschlagen',
      overflowCountTemplate: '+${count} mehr ..',
      selectAllText: 'Alles auswählen',
      unSelectAllText: 'Alles abwählen',
      totalCountTemplate: '${count} ausgewählt',
    },
    'drop-down-list': {
      noRecordsTemplate: 'Keine Datensätze gefunden',
      actionFailureTemplate: 'Die Anfrage ist fehlgeschlagen',
    },
    'combo-box': {
      noRecordsTemplate: 'Keine Datensätze gefunden',
      actionFailureTemplate: 'Die Anfrage ist fehlgeschlagen',
    },
    'auto-complete': {
      noRecordsTemplate: 'Keine Datensätze gefunden',
      actionFailureTemplate: 'Die Anfrage ist fehlgeschlagen',
    },
    'multi-select': {
      noRecordsTemplate: 'Keine Datensätze gefunden',
      actionFailureTemplate: 'Die Anfrage ist fehlgeschlagen',
      overflowCountTemplate: '+${count} mehr ..',
      selectAllText: 'Wählen Sie Alle',
      unSelectAllText: 'Alles widerrufen',
      totalCountTemplate: '${count} ausgewählt',
    },
    listbox: {
      noRecordsTemplate: 'Keine Datensätze gefunden',
      actionFailureTemplate: 'Die Anfrage ist fehlgeschlagen',
      selectAllText: 'Wählen Sie Alle',
      unSelectAllText: 'Alles widerrufen',
      moveUp: 'Nach oben bewegen',
      moveDown: 'Sich abwärts bewegen',
      moveTo: 'Ziehen nach',
      moveFrom: 'Verschieben von',
      moveAllTo: 'Alle verschieben nach',
      moveAllFrom: 'Alle verschieben von',
    },
    spreadsheet: {
      InsertingEmptyValue: 'Referenzwert ist ungültig.',
      FindValue: 'Wert finden',
      ReplaceValue: 'Wert ersetzen',
      FindReplaceTooltip: 'Ersatz finden',
      ByRow: 'Nach Reihen',
      ByColumn: 'Nach Spalten',
      MatchExactCellElements: 'Gesamten Zellinhalt abgleichen',
      EnterCellAddress: 'Zellenadresse eingeben',
      FindAndReplace: 'Suchen und Ersetzen',
      ReplaceAllEnd: ' Streichhölzer ersetzt durch',
      FindNextBtn: 'Nächstes finden',
      FindPreviousBtn: 'Zurück suchen',
      ReplaceBtn: 'Ersetzen',
      ReplaceAllBtn: 'Alles ersetzen',
      GotoHeader: 'Gehe zu',
      Sheet: 'Blatt',
      SearchWithin: 'In ... suchen',
      SearchBy: 'Suche nach',
      Reference: 'Bezug',
      Workbook: 'Arbeitsmappe',
      NoElements: 'Wir konnten nicht finden, wonach Sie gesucht haben.',
      FindWhat: 'Finde was',
      ReplaceWith: 'Ersetzen mit',
      FileName: 'Dateiname',
      ExtendValidation:
        'Die Auswahl enthält einige Zellen ohne Datenvalidierung. Möchten Sie die Validierung auf diese Zellen ausdehnen?',
      Yes: 'Ja',
      No: 'Nein',
      PROPER:
        'Konvertiert einen Text in die richtige Groß-/Kleinschreibung; Anfangsbuchstabe in Großbuchstaben und andere Buchstaben in Kleinbuchstaben.',
      Cut: 'Schnitt',
      Copy: 'Kopieren',
      Paste: 'Paste',
      PasteSpecial: 'Spezielles einfügen',
      All: 'Alle',
      Values: 'Werte',
      Formats: 'Formate',
      Font: 'Schriftart',
      FontSize: 'Schriftgröße',
      Bold: 'Deutlich',
      Italic: 'Kursiv',
      Underline: 'Unterstreichen',
      Strikethrough: 'Durchgestrichen',
      TextColor: 'Textfarbe',
      FillColor: 'Füllfarbe',
      HorizontalAlignment: 'Horizontale Ausrichtung',
      AlignLeft: 'Linksbündig',
      AlignCenter: 'Center',
      AlignRight: 'Rechts ausrichten',
      VerticalAlignment: 'Vertikale Ausrichtung',
      AlignTop: 'Oben ausrichten',
      AlignMiddle: 'Mitte ausrichten',
      AlignBottom: 'Unten ausrichten',
      MergeCells: 'Zellen verbinden',
      MergeAll: 'Alle zusammenführen',
      MergeHorizontally: 'Horizontal zusammenführen',
      MergeVertically: 'Vertikal zusammenführen',
      Unmerge: 'Zusammenführung aufheben',
      UnmergeCells: 'Zellen trennen',
      SelectMergeType: 'Wählen Sie Zusammenführungstyp aus',
      MergeCellsAlert:
        'Beim Zusammenführen von Zellen wird nur der Wert ganz oben links (Oberste) beibehalten. Trotzdem zusammenführen?',
      PasteMergeAlert:
        'Wir können das nicht mit einer zusammengeführten Zelle machen.',
      Borders: 'Grenzen',
      TopBorders: 'Obere Grenzen',
      LeftBorders: 'Linke Grenzen',
      RightBorders: 'Rechte Grenzen',
      BottomBorders: 'Untere Grenzen',
      AllBorders: 'Alle Grenzen',
      HorizontalBorders: 'Horizontale Grenzen',
      VerticalBorders: 'Vertikale Grenzen',
      OutsideBorders: 'Außerhalb der Grenzen',
      InsideBorders: 'Innere Grenzen',
      NoBorders: 'Keine Grenzen',
      BorderColor: 'Randfarbe',
      BorderStyle: 'Rahmenstil',
      InsertFunction: 'Funktion einfügen',
      Insert: 'Einfügung',
      Delete: 'Löschen',
      DuplicateSheet: 'Duplikat',
      MoveRight: 'Nach rechts bewegen',
      MoveLeft: 'Geh nach links',
      Rename: 'Umbenennen',
      Hide: 'Verstecken',
      NameBox: 'Namensfeld',
      ShowHeaders: 'Kopfzeilen anzeigen',
      HideHeaders: 'Kopfzeilen ausblenden',
      ShowGridLines: 'Rasterlinien anzeigen',
      HideGridLines: 'Gitternetzlinien ausblenden',
      FreezePanes: 'Fenster einfrieren',
      FreezeRows: 'Zeilen einfrieren',
      FreezeColumns: 'Spalten einfrieren',
      UnfreezePanes: 'Fenster freigeben',
      UnfreezeRows: 'Zeilen aufheben',
      UnfreezeColumns: 'Spalten freigeben',
      AddSheet: 'Blatt hinzufügen',
      ListAllSheets: 'Alle Blätter auflisten',
      CollapseToolbar: 'Symbolleiste minimieren',
      ExpandToolbar: 'Erweitern Sie die Symbolleiste',
      CollapseFormulaBar: 'Formelleiste reduzieren',
      ExpandFormulaBar: 'Formelleiste erweitern',
      File: 'Datei',
      Home: 'Heim',
      Formulas: 'Formeln',
      View: 'Sicht',
      New: 'Neu',
      Open: 'Offen',
      SaveAs: 'Speichern als',
      ExcelXlsx: 'Microsoft Excel',
      ExcelXls: 'Microsoft Excel 97-2003',
      CSV: 'Komma-getrennte Werte',
      FormulaBar: 'Formelbar',
      Sort: 'Sortieren',
      SortAscending: 'Aufsteigend',
      SortDescending: 'Absteigend',
      CustomSort: 'Benutzerdefinierte Sortierung',
      AddColumn: 'Spalte hinzufügen',
      ContainsHeader: 'Daten enthalten Header',
      CaseSensitive: 'Groß- und Kleinschreibung beachten',
      SortBy: 'Sortieren nach',
      ThenBy: 'Dann vorbei',
      SelectAColumn: 'Wählen Sie eine Spalte aus',
      SortEmptyFieldError:
        'Für alle Sortierkriterien muss eine Spalte angegeben sein. Überprüfen Sie die ausgewählten Sortierkriterien und versuchen Sie es erneut.',
      SortDuplicateFieldError:
        ' mehr als einmal nach Werten sortiert wird. Löschen Sie die doppelten Sortierkriterien und versuchen Sie es erneut.',
      SortOutOfRangeError:
        'Wählen Sie eine Zelle oder einen Bereich innerhalb des verwendeten Bereichs aus und versuchen Sie es erneut.',
      MultiRangeSortError:
        'Dies ist bei einer Mehrfachbereichsauswahl nicht möglich. Wählen Sie einen einzelnen Bereich aus und versuchen Sie es erneut.',
      HideRow: 'Zeile ausblenden',
      HideRows: 'Zeilen ausblenden',
      UnhideRows: 'Zeilen einblenden',
      HideColumn: 'Spalte ausblenden',
      HideColumns: 'Spalten ausblenden',
      UnhideColumns: 'Spalten einblenden',
      InsertRow: 'Zeile einfügen',
      InsertRows: 'Zeilen einfügen',
      Above: 'Über',
      Below: 'Unter',
      InsertColumn: 'Spalte einfügen',
      InsertColumns: 'Spalten einfügen',
      Before: 'Vor',
      After: 'Nach',
      DeleteRow: 'Zeile löschen',
      DeleteRows: 'Zeilen löschen',
      DeleteColumn: 'Spalte löschen',
      DeleteColumns: 'Spalten löschen',
      Ok: 'OK',
      Close: 'Schließen',
      MoreOptions: 'Mehr Optionen',
      Cancel: 'Absagen',
      Apply: 'Anwenden',
      MoreColors: 'Mehr Farben',
      StandardColors: 'Standardfarben',
      General: 'Allgemein',
      Number: 'Anzahl',
      Currency: 'Währung',
      Accounting: 'Buchhaltung',
      ShortDate: 'Kurzes Date',
      LongDate: 'Langes Datum',
      Time: 'Zeit',
      Percentage: 'Prozentsatz',
      Fraction: 'Fraktion',
      Scientific: 'Wissenschaftlich',
      Text: 'Text',
      NumberFormat: 'Zahlenformat',
      MobileFormulaBarPlaceHolder: 'Wert oder Formel eingeben',
      PasteAlert:
        'Sie können dies hier nicht einfügen, da der Kopierbereich und der Einfügebereich nicht die gleiche Größe haben. Bitte versuchen Sie es in einem anderen Bereich einzufügen.',
      DestroyAlert:
        'Möchten Sie die aktuelle Arbeitsmappe wirklich löschen, ohne sie zu speichern, und eine neue Arbeitsmappe erstellen?',
      SheetRenameInvalidAlert: 'Blattname enthält ungültige Zeichen.',
      SheetRenameEmptyAlert: 'Blattname darf nicht leer sein.',
      SheetRenameAlreadyExistsAlert:
        'Blattname existiert bereits. Bitte geben Sie einen anderen Namen ein.',
      DeleteSheetAlert: 'Möchten Sie dieses Tabellenblatt wirklich löschen?',
      DeleteSingleLastSheetAlert:
        'Eine Arbeitsmappe muss mindestens ein sichtbares Arbeitsblatt enthalten.',
      PickACategory: 'Wählen Sie eine Kategorie aus',
      Description: 'Beschreibung',
      UnsupportedFile: 'Nicht unterstützte Datei',
      DataLimitExceeded:
        'Die Dateidaten sind zu groß und die Verarbeitung dauert länger. Möchten Sie fortfahren?',
      FileSizeLimitExceeded:
        'Die Dateigröße ist zu groß und die Verarbeitung dauert länger. Möchten Sie fortfahren?',
      InvalidUrl: 'ungültige URL',
      SUM: 'Fügt eine Reihe von Zahlen und/oder Zellen hinzu.',
      SUMIF: 'Fügt die Zellen basierend auf der angegebenen Bedingung hinzu.',
      SUMIFS: 'Fügt die Zellen basierend auf angegebenen Bedingungen hinzu.',
      ABS: 'Gibt den Wert einer Zahl ohne Vorzeichen zurück.',
      RAND: 'Gibt eine Zufallszahl zwischen 0 und 1 zurück.',
      RANDBETWEEN:
        'Gibt basierend auf angegebenen Werten eine zufällige ganze Zahl zurück.',
      FLOOR:
        'Rundet eine Zahl auf das nächste Vielfache eines gegebenen Faktors ab.',
      CEILING:
        'Rundet eine Zahl auf das nächste Vielfache eines gegebenen Faktors auf.',
      PRODUCT: 'Multipliziert eine Reihe von Zahlen und/oder Zellen.',
      AVERAGE:
        'Berechnet den Durchschnitt für die Reihe von Zahlen und/oder Zellen ohne Text.',
      AVERAGEIF:
        'Berechnet den Durchschnitt für die Zellen basierend auf dem angegebenen Kriterium.',
      AVERAGEIFS:
        'Berechnet den Durchschnitt für die Zellen basierend auf den angegebenen Bedingungen.',
      AVERAGEA:
        'Berechnet den Durchschnitt für die Zellen, die TRUE als 1, Text und FALSE als 0 auswerten.',
      COUNT:
        'Zählt die Zellen, die numerische Werte in einem Bereich enthalten.',
      COUNTIF: 'Zählt die Zellen basierend auf der angegebenen Bedingung.',
      COUNTIFS: 'Zählt die Zellen basierend auf angegebenen Bedingungen.',
      COUNTA: 'Zählt die Zellen, die Werte in einem Bereich enthalten.',
      MIN: 'Gibt die kleinste Anzahl der angegebenen Argumente zurück.',
      MAX: 'Gibt die größte Anzahl der angegebenen Argumente zurück.',
      DATE: 'Gibt das Datum basierend auf dem angegebenen Jahr, Monat und Tag zurück.',
      DAY: 'Gibt den Tag aus dem angegebenen Datum zurück.',
      DAYS: 'Gibt die Anzahl der Tage zwischen zwei Daten zurück.',
      IF: 'Gibt einen Wert basierend auf dem angegebenen Ausdruck zurück.',
      IFS: 'Gibt einen Wert basierend auf den angegebenen mehreren Ausdrücken zurück.',
      CalculateAND:
        'Gibt TRUE zurück, wenn alle Argumente TRUE sind, ansonsten FALSE.',
      CalculateOR:
        'Gibt TRUE zurück, wenn eines der Argumente TRUE ist, ansonsten FALSE.',
      IFERROR:
        'Gibt den Wert zurück, wenn kein Fehler gefunden wurde, sonst wird der angegebene Wert zurückgegeben.',
      CHOOSE:
        'Gibt einen Wert aus einer Werteliste basierend auf der Indexnummer zurück.',
      INDEX:
        'Gibt einen Wert der Zelle in einem bestimmten Bereich basierend auf der Zeilen- und Spaltennummer zurück.',
      FIND: 'Gibt die Position einer Zeichenfolge innerhalb einer anderen Zeichenfolge zurück, wobei die Groß-/Kleinschreibung beachtet wird.',
      CONCATENATE: 'Kombiniert zwei oder mehr Saiten miteinander.',
      CONCAT: 'Verkettet eine Liste oder einen Bereich von Textzeichenfolgen.',
      SUBTOTAL:
        'Gibt die Zwischensumme für einen Bereich unter Verwendung der angegebenen Funktionsnummer zurück.',
      RADIANS: 'Konvertiert Grad in Bogenmaß.',
      MATCH:
        'Gibt die relative Position eines angegebenen Werts im angegebenen Bereich zurück.',
      SLOPE:
        'Gibt die Steigung der Linie aus der linearen Regression der Datenpunkte zurück.',
      INTERCEPT:
        'Berechnet den Punkt der Y-Schnittlinie durch lineare Regression.',
      UNIQUE: 'Gibt eindeutige Werte aus einem Bereich oder Array zurück',
      TEXT: 'Konvertiert einen Wert in Text im angegebenen Zahlenformat.',
      DefineNameExists:
        'Dieser Name existiert bereits, versuchen Sie es mit einem anderen Namen.',
      CircularReference:
        'Wenn eine Formel auf einen oder mehrere Zirkelbezüge verweist, kann dies zu einer fehlerhaften Berechnung führen.',
      SORT: 'Sortiert einen Bereich eines Arrays',
      T: 'Überprüft, ob ein Wert Text ist oder nicht, und gibt den Text zurück.',
      EXACT:
        'Überprüft, ob zwei Textstrings genau gleich sind und gibt TRUE oder FALSE zurück.',
      LEN: 'Gibt eine Anzahl von Zeichen in einer angegebenen Zeichenfolge zurück.',
      MOD: 'Gibt einen Rest zurück, nachdem eine Zahl durch den Divisor dividiert wurde.',
      ODD: 'Rundet eine positive Zahl auf und eine negative Zahl auf die nächste ungerade Ganzzahl ab.',
      PI: 'Gibt den Wert von pi zurück.',
      COUNTBLANK:
        'Gibt die Anzahl leerer Zellen in einem angegebenen Zellbereich zurück.',
      EVEN: 'Rundet eine positive Zahl auf und eine negative Zahl auf die nächste gerade Ganzzahl ab.',
      DECIMAL:
        'Konvertiert eine Textdarstellung einer Zahl in einer bestimmten Basis in eine Dezimalzahl.',
      ADDRESS:
        'Gibt einen Zellbezug als Text zurück, wenn die angegebenen Zeilen- und Spaltennummern angegeben sind.',
      CHAR: 'Gibt das Zeichen aus der angegebenen Zahl zurück.',
      CODE: 'Gibt den numerischen Code für das erste Zeichen in einer angegebenen Zeichenfolge zurück.',
      DOLLAR: 'Wandelt die Zahl in währungsformatierten Text um.',
      SMALL: 'Gibt den k-ten kleinsten Wert in einem gegebenen Array zurück.',
      LARGE: 'Gibt den k-größten Wert in einem gegebenen Array zurück.',
      TIME: 'Konvertiert Stunden, Minuten, Sekunden in den zeitformatierten Text.',
      DEGREES: 'Konvertiert Bogenmaß in Grad.',
      FACT: 'Gibt die Fakultät einer Zahl zurück.',
      MEDIAN: 'Gibt den Median der gegebenen Zahlenmenge zurück.',
      EDATE:
        'Gibt ein Datum mit der angegebenen Anzahl von Monaten vor oder nach dem angegebenen Datum zurück.',
      DATEVALUE: 'Konvertiert eine Datumszeichenfolge in einen Datumswert.',
      NOW: 'Gibt das aktuelle Datum und die Uhrzeit zurück.',
      HOUR: 'Gibt die Anzahl der Stunden in einer angegebenen Zeitzeichenfolge zurück.',
      MINUTE:
        'Gibt die Anzahl der Minuten in einer angegebenen Zeitzeichenfolge zurück.',
      SECOND:
        'Gibt die Anzahl der Sekunden in einer angegebenen Zeitzeichenfolge zurück.',
      MONTH:
        'Gibt die Anzahl der Monate in einer angegebenen Datumszeichenfolge zurück.',
      OR: 'ODER',
      AND: 'UND',
      CustomFilterDatePlaceHolder: 'Wählen Sie ein Datum aus',
      CustomFilterPlaceHolder: 'Geben Sie den Wert ein',
      CustomFilter: 'Benutzerdefinierte Filter',
      Between: 'Zwischen',
      MatchCase: 'Streichholzschachtel',
      DateTimeFilter: 'DateTime-Filter',
      Undo: 'Rückgängig machen',
      Redo: 'Wiederholen',
      ClearAllFilter: 'Klar',
      ReapplyFilter: 'Bewerben Sie sich erneut',
      DateFilter: 'Datumsfilter',
      TextFilter: 'Textfilter',
      NumberFilter: 'Zahlenfilter',
      ClearFilter: 'Filter löschen',
      NoResult: 'Keine Treffer gefunden',
      FilterFalse: 'FALSCH',
      FilterTrue: 'WAHR',
      Blanks: 'Leerzeichen',
      SelectAll: 'Wählen Sie Alle',
      GreaterThanOrEqual: 'Größer als oder gleich',
      GreaterThan: 'Größer als',
      LessThanOrEqual: 'Weniger als oder gleich',
      LessThan: 'Weniger als',
      NotEqual: 'Nicht gleich',
      Equal: 'Gleich',
      Contains: 'Enthält',
      NotContains: 'Enthält nicht',
      EndsWith: 'Endet mit',
      NotEndsWith: 'Endet nicht mit',
      StartsWith: 'Beginnt mit',
      NotStartsWith: 'Beginnt nicht mit',
      IsEmpty: 'Leer',
      IsNotEmpty: 'Nicht leer',
      ClearButton: 'Klar',
      FilterButton: 'Filter',
      CancelButton: 'Absagen',
      OKButton: 'OK',
      Search: 'Suche',
      DataValidation: 'Datenvalidierung',
      CellRange: 'Zellbereich',
      Allow: 'Erlauben',
      Data: 'Daten',
      Minimum: 'Minimum',
      Maximum: 'Maximal',
      IgnoreBlank: 'Leerzeichen ignorieren',
      WholeNumber: 'Ganze Zahl',
      Decimal: 'Dezimal',
      Date: 'Datum',
      TextLength: 'Textlänge',
      List: 'Liste',
      NotBetween: 'Nicht zwischen',
      EqualTo: 'Gleicht',
      NotEqualTo: 'Nicht gleichzusetzen mit',
      GreaterThanOrEqualTo: 'Größer als oder gleich wie',
      LessThanOrEqualTo: 'Weniger als oder gleich',
      InCellDropDown: 'In-Cell-Dropdown',
      Sources: 'Quellen',
      Value: 'Wert',
      Retry: 'Wiederholen',
      DialogError:
        'Die Listenquelle muss ein Verweis auf eine einzelne Zeile oder Spalte sein.',
      MinMaxError: 'Das Maximum muss größer oder gleich dem Minimum sein.',
      Spreadsheet: 'Kalkulationstabelle',
      MoreValidation: 'Diese Auswahl enthält mehr als eine Validierung.',
      FileNameError:
        'Ein Dateiname darf keine Zeichen wie \\ / : * ? enthalten. " < > [ ] |',
      ValidationError:
        'Dieser Wert entspricht nicht den für die Zelle definierten Datenüberprüfungseinschränkungen.',
      ListLengthError: 'Die Listenwerte erlauben nur bis zu 256 Zeichen',
      ProtectSheet: 'Schutzblatt',
      UnprotectSheet: 'Blattschutz aufheben',
      SelectCells: 'Wählen Sie gesperrte Zellen aus',
      SelectUnlockedCells: 'Wählen Sie entsperrte Zellen aus',
      Save: 'Speichern',
      EmptyFileName: 'Der Dateiname darf nicht leer sein.',
      LargeName: 'Der Name ist zu lang.',
      FormatCells: 'Zellen formatieren',
      FormatRows: 'Zeilen formatieren',
      FormatColumns: 'Spalten formatieren',
      InsertLinks: 'Verknüpfungen einfügen',
      ProtectContent: 'Schützen Sie den Inhalt gesperrter Zellen',
      ProtectAllowUser:
        ' Allen Benutzern dieses Arbeitsblatts Folgendes erlauben:',
      EditAlert:
        'Die Zelle, die Sie ändern möchten, ist geschützt. Heben Sie den Schutz des Blatts auf, um Änderungen vorzunehmen.',
      ClearValidation: 'Klare Validierung',
      ISNUMBER:
        'Gibt true zurück, wenn der Wert als numerischer Wert analysiert wird.',
      ROUND: 'Rundet eine Zahl auf eine angegebene Anzahl von Ziffern.',
      GEOMEAN:
        'Gibt das geometrische Mittel eines Arrays oder Bereichs positiver Daten zurück.',
      POWER: 'Gibt das Ergebnis einer potenzierten Zahl zurück',
      LOG: 'Gibt den Logarithmus einer Zahl zur angegebenen Basis zurück.',
      TRUNC:
        'Gibt den abgeschnittenen Wert einer Zahl auf eine angegebene Anzahl von Dezimalstellen zurück.',
      EXP: 'Gibt e potenziert mit der gegebenen Zahl zurück.',
      LOOKUP:
        'Sucht einen Wert in einem einzeiligen oder einspaltigen Bereich und gibt dann einen Wert von derselben Position in einem einzeiligen oder einspaltigen Bereich zurück.',
      HLOOKUP:
        'Sucht den Wert in der obersten Zeile des Wertearrays und gibt dann den Wert in derselben Spalte der Zeile im von Ihnen angegebenen Array zurück.',
      VLOOKUP:
        'Sucht nach dem angegebenen Wert in der ersten Spalte des Suchbereichs und gibt einen passenden Wert aus einer anderen Spalte in derselben Zeile zurück.',
      NOT: 'Gibt die Umkehrung des angegebenen logischen Ausdrucks zurück.',
      EOMONTH:
        'Gibt den letzten Tag des Monats zurück, der die angegebene Anzahl von Monaten vor oder nach dem am Anfang angegebenen Startdatum liegt.',
      SQRT: 'Gibt die Quadratwurzel einer positiven Zahl zurück.',
      ROUNDDOWN: 'Rundet die Zahl auf Null ab.',
      RSQ: 'Gibt das Quadrat des momentanen Korrelationskoeffizienten des Pearson-Produkts basierend auf den Datenpunkten in „known_y“ und „known_x“ zurück.',
      HighlightCellsRules: 'Zellenregeln hervorheben',
      CFEqualTo: 'Gleicht',
      TextThatContains: 'Text, der enthält',
      ADateOccuring: 'Ein Datum tritt ein',
      DuplicateValues: 'Doppelte Werte',
      TopBottomRules: 'Top/Bottom-Regeln',
      Top10Items: 'Top 10 Artikel',
      Top10: 'Top 10',
      Bottom10Items: 'Unterste 10 Artikel',
      Bottom10: 'Untere 10',
      AboveAverage: 'Überdurchschnittlich',
      BelowAverage: 'Unterdurchschnittlich',
      FormatCellsGreaterThan: 'Zellen formatieren, die GRÖSSER ALS sind:',
      FormatCellsLessThan: 'Zellen formatieren, die KLEINER ALS sind:',
      FormatCellsBetween: 'Zellen formatieren, die ZWISCHEN liegen:',
      FormatCellsEqualTo: 'Zellen formatieren, die GLEICH sind:',
      FormatCellsThatContainTheText:
        'Zellen formatieren, die den Text enthalten:',
      FormatCellsThatContainADateOccurring:
        'Formatieren Sie Zellen, die ein Datum enthalten, das auftritt:',
      FormatCellsDuplicate: 'Zellen formatieren, die Folgendes enthalten:',
      FormatCellsTop: 'Zellen formatieren, die im TOP-Ranking stehen:',
      FormatCellsBottom: 'Zellen formatieren, die ganz unten rangieren:',
      FormatCellsAbove: 'Zellen formatieren, die ÜBER DURCHSCHNITTLICH sind:',
      FormatCellsBelow:
        'Zellen formatieren, die UNTER DEM DURCHSCHNITT liegen:',
      With: 'mit',
      DataBars: 'Datenleisten',
      ColorScales: 'Farbskalen',
      IconSets: 'Icon-Sets',
      ClearRules: 'Klare Regeln',
      SelectedCells: 'Regeln aus ausgewählten Zellen löschen',
      EntireSheet: 'Löschen Sie Regeln aus dem gesamten Blatt',
      LightRedFillWithDarkRedText: 'Hellrote Füllung mit dunkelrotem Text',
      YellowFillWithDarkYellowText: 'Gelbe Füllung mit dunkelgelbem Text',
      GreenFillWithDarkGreenText: 'Grüne Füllung mit dunkelgrünem Text',
      RedFill: 'Rote Füllung',
      RedText: 'Roter Text',
      Duplicate: 'Duplikat',
      Unique: 'Einzigartig',
      And: 'und',
      WebPage: 'Website',
      ThisDocument: 'Dieses Dokument',
      DisplayText: 'Text anzeigen',
      Url: 'URL',
      CellReference: 'Zellbezug',
      DefinedNames: 'Definierte Namen',
      EnterTheTextToDisplay: 'Geben Sie den anzuzeigenden Text ein',
      EnterTheUrl: 'Geben Sie die URL ein',
      INT: 'Gibt eine Zahl bis zur nächsten ganzen Zahl zurück.',
      SUMPRODUCT:
        'Gibt die Summe des Produkts gegebener Bereiche von Arrays zurück.',
      TODAY: 'Gibt das aktuelle Datum als Datumswert zurück.',
      ROUNDUP: 'Rundet eine Zahl von Null weg.',
      Link: 'Verknüpfung',
      Hyperlink: 'Hyperlinks',
      EditHyperlink: 'Hyperlink bearbeiten',
      OpenHyperlink: 'Hyperlinks öffnen',
      RemoveHyperlink: 'Hyperlinks entfernen',
      InvalidHyperlinkAlert:
        'Die Adresse dieser Website ist ungültig. Überprüfen Sie die Adresse und versuchen Sie es erneut.',
      InsertLink: 'Verknüpfung einfügen',
      EditLink: 'Verknüpfung bearbeiten',
      WrapText: 'Zeilenumbruch',
      Update: 'Aktualisieren',
      SortAndFilter: 'Sortieren & Filtern',
      Filter: 'Filter',
      FilterCellValue: 'Nach Wert der ausgewählten Zelle filtern',
      FilterOutOfRangeError:
        'Wählen Sie eine Zelle oder einen Bereich innerhalb des verwendeten Bereichs aus und versuchen Sie es erneut.',
      ClearFilterFrom: 'Filter löschen von',
      LN: 'Gibt den natürlichen Logarithmus einer Zahl zurück.',
      DefineNameInValid: 'Der eingegebene Name ist ungültig.',
      EmptyError: 'Sie müssen einen Wert eingeben',
      ClearHighlight: 'Markierung löschen',
      HighlightInvalidData: 'Markieren Sie ungültige Daten',
      Clear: 'Klar',
      ClearContents: 'Inhalte löschen',
      ClearAll: 'Alles löschen',
      ClearFormats: 'Klare Formate',
      ClearHyperlinks: 'Hyperlinks löschen',
      Image: 'Bild',
      ConditionalFormatting: 'Bedingte Formatierung',
      BlueDataBar: 'Blaue Datenleiste',
      GreenDataBar: 'Grüne Datenleiste',
      RedDataBar: 'Rote Datenleiste',
      OrangeDataBar: 'Orange Datenleiste',
      LightBlueDataBar: 'Hellblauer Datenbalken',
      PurpleDataBar: 'Lila Datenleiste',
      GYRColorScale: 'Farbskala Grün - Gelb - Rot',
      RYGColorScale: 'Farbskala Rot - Gelb - Grün',
      GWRColorScale: 'Farbskala Grün - Weiß - Rot',
      RWGColorScale: 'Farbskala Rot - Weiß - Grün',
      BWRColorScale: 'Farbskala Blau - Weiß - Rot',
      RWBColorScale: 'Farbskala Rot - Weiß - Blau',
      WRColorScale: 'Farbskala Weiß - Rot',
      RWColorScale: 'Farbskala Rot - Weiß',
      GWColorScale: 'Farbskala Grün - Weiß',
      WGColorScale: 'Weiß - Grüne Farbskala',
      GYColorScale: 'Farbskala Grün - Gelb',
      YGColorScale: 'Farbskala Gelb - Grün',
      ThreeArrowsColor: '3 Pfeile (farbig)',
      ThreeArrowsGray: '3 Pfeile (grau)',
      ThreeTriangles: '3 Dreiecke',
      FourArrowsColor: '4 Pfeile (grau)',
      FourArrowsGray: '4 Pfeile (farbig)',
      FiveArrowsColor: '5 Pfeile (grau)',
      FiveArrowsGray: '5 Pfeile (farbig)',
      ThreeTrafficLights1: '3 Ampeln (ohne Rahmen)',
      ThreeTrafficLights2: '3 Ampeln (umrandet)',
      ThreeSigns: '3 Zeichen',
      FourTrafficLights: '4 Ampeln',
      RedToBlack: 'Rot zu Schwarz',
      ThreeSymbols1: '3 Symbole (eingekreist)',
      ThreeSymbols2: '3 Symbole (nicht eingekreist)',
      ThreeFlags: '3 Flaggen',
      ThreeStars: '3 Sterne',
      FourRatings: '4 Bewertungen',
      FiveQuarters: '5 Quartale',
      FiveRatings: '5 Bewertungen',
      FiveBoxes: '5 Kisten',
      Chart: 'Diagramm',
      Column: 'Spalte',
      Bar: 'Bar',
      Area: 'Bereich',
      Pie: 'Kuchen',
      Doughnut: 'Krapfen',
      PieAndDoughnut: 'Kuchen/Donut',
      Line: 'Linie',
      Radar: 'Radar',
      Scatter: 'Streuen',
      ChartDesign: 'Diagrammdesign',
      ClusteredColumn: 'Gruppierte Säule',
      StackedColumn: 'Gestapelte Spalte',
      StackedColumn100: '100 % gestapelte Säule',
      ClusteredBar: 'Geclusterte Bar',
      StackedBar: 'Gestapelte Leiste',
      StackedBar100: '100 % gestapelter Balken',
      StackedArea: 'Gestapelter Bereich',
      StackedArea100: '100 % gestapelte Fläche',
      StackedLine: 'Gestapelte Linie',
      StackedLine100: '100 % gestapelte Linie',
      LineMarker: 'Linie mit Markierungen',
      StackedLineMarker: 'Gestapelte Linie mit Markierungen',
      StackedLine100Marker: '100 % gestapelte Linie mit Markierungen',
      AddChartElement: 'Diagrammelement hinzufügen',
      Axes: 'Achsen',
      AxisTitle: 'Achsentitel',
      ChartTitle: 'Diagrammtitel',
      DataLabels: 'Datenaufkleber',
      Gridlines: 'Gitterlinien',
      Legends: 'Legenden',
      PrimaryHorizontal: 'Primäre Horizontale',
      PrimaryVertical: 'Primäre Vertikale',
      None: 'Keiner',
      AboveChart: 'Oben Diagramm',
      Center: 'Center',
      InsideEnd: 'Inneres Ende',
      InsideBase: 'Innerhalb der Basis',
      OutsideEnd: 'Äußeres Ende',
      PrimaryMajorHorizontal: 'Grundlegende große horizontale',
      PrimaryMajorVertical: 'Primäre Hauptvertikale',
      PrimaryMinorHorizontal: 'Primäre kleine horizontale',
      PrimaryMinorVertical: 'Primäre kleine Vertikale',
      Right: 'Rechts',
      Left: 'Links',
      Bottom: 'Unterseite',
      Top: 'oben',
      SwitchRowColumn: 'Zeile/Spalte wechseln',
      ChartTheme: 'Diagrammthema',
      ChartType: 'Diagramm Typ',
      Material: 'Material',
      Fabric: 'Stoff',
      Bootstrap: 'Bootstrap',
      HighContrastLight: 'Kontrastreiches Licht',
      MaterialDark: 'Material dunkel',
      FabricDark: 'Stoff dunkel',
      HighContrast: 'Hoher Kontrast',
      BootstrapDark: 'Bootstrap dunkel',
      Bootstrap4: 'Bootstrap4',
      Bootstrap5Dark: 'Bootstrap5 dunkel',
      Bootstrap5: 'Bootstrap5',
      Tailwind: 'Rückenwind',
      TailwindDark: 'Rückenwind Dunkel',
      VerticalAxisTitle: 'Titel der vertikalen Achse',
      HorizontalAxisTitle: 'Titel der horizontalen Achse',
      EnterTitle: 'Titel eingeben',
      UnprotectWorksheet: 'Blattschutz aufheben',
      ReEnterPassword: 'Geben Sie das Passwort erneut ein, um fortzufahren',
      SheetPassword: 'Passwort zum Aufheben des Blattschutzes:',
      ProtectWorkbook: 'Arbeitsmappe schützen',
      Password: 'Passwort (optional):',
      EnterThePassword: 'Geben Sie das Passwort ein',
      ConfirmPassword: 'Kennwort bestätigen',
      EnterTheConfirmPassword: 'Wiederhole die Eingabe deines Passwortes',
      PasswordAlert: 'Bestätigungspasswort ist nicht identisch',
      UnprotectWorkbook: 'Schutz der Arbeitsmappe aufheben',
      UnprotectPasswordAlert: 'Das eingegebene Passwort ist nicht korrekt.',
      IncorrectPassword:
        'Die Datei oder das Arbeitsblatt kann mit dem angegebenen Passwort nicht geöffnet werden',
      PasswordAlertMsg: 'Bitte geben Sie das Passwort ein',
      ConfirmPasswordAlertMsg: 'Bitte geben Sie das Bestätigungspasswort ein',
      IsProtected: 'ist geschützt',
      PDF: 'PDF-Dokument',
      AutoFillMergeAlertMsg:
        'Dazu müssen alle verbundenen Zellen die gleiche Größe haben.',
      Fluent: 'Fließend',
      FluentDark: 'Fließendes Dunkel',
      Custom: 'Benutzerdefiniert',
      WEEKDAY: 'Gibt den Wochentag zurück, der einem Datum entspricht.',
      FillSeries: 'Serie füllen',
      CopyCells: 'Zellen kopieren',
      FillFormattingOnly: 'Nur Formatierung ausfüllen',
      FillWithoutFormatting: 'Ohne Formatierung ausfüllen',
      CustomFormat: 'Benutzerdefinierte Zahlenformate',
      CustomFormatPlaceholder:
        'Geben Sie ein benutzerdefiniertes Format ein oder wählen Sie es aus',
      CustomFormatTypeList: 'Art',
      CellReferenceTypoError:
        'Wir haben einen Tippfehler in Ihrem Zellbezug gefunden. Möchten Sie diesen Verweis wie folgt korrigieren?',
      AddCurrentSelection: 'Aktuelle Auswahl zum Filter hinzufügen',
      ExternalWorkbook:
        'Eine importierte Excel-Datei enthält eine externe Arbeitsmappenreferenz. Möchten Sie diese Datei importieren?',
      Directional: 'Gerichtet',
      Shapes: 'Formen',
      Indicators: 'Indikatoren',
      Ratings: 'Bewertungen',
      InvalidFormulaError:
        'Wir haben festgestellt, dass Sie eine ungültige Formel eingegeben haben.',
      InvalidArguments:
        'Wir haben festgestellt, dass Sie eine Formel mit ungültigen Argumenten eingegeben haben.',
      EmptyExpression:
        'Wir haben festgestellt, dass Sie eine Formel mit einem leeren Ausdruck eingegeben haben.',
      MismatchedParenthesis:
        'Wir haben festgestellt, dass Sie eine Formel eingegeben haben, bei der eine oder mehrere öffnende oder schließende Klammern fehlen.',
      ImproperFormula:
        'Wir haben festgestellt, dass Sie eine Formel eingegeben haben, die falsch ist.',
      WrongNumberOfArguments:
        'Wir haben festgestellt, dass Sie eine Formel mit einer falschen Anzahl an Argumenten eingegeben haben.',
      Requires3Arguments:
        'Wir haben festgestellt, dass Sie eine Formel eingegeben haben, die drei Argumente erfordert.',
      MismatchedStringQuotes:
        'Wir haben festgestellt, dass Sie eine Formel mit nicht übereinstimmenden Anführungszeichen eingegeben haben.',
      FormulaCircularRef:
        'Wir haben festgestellt, dass Sie eine Formel mit einem Zirkelbezug eingegeben haben.',
    },
    filemanager: {
      NewFolder: 'Neuer Ordner',
      Upload: 'Hochladen',
      Delete: 'Löschen',
      Rename: 'Umbenennen',
      Download: 'Herunterladen',
      Cut: 'Ausschneiden',
      Copy: 'Kopieren',
      Paste: 'Einfügen',
      SortBy: 'Sortiere nach',
      Refresh: 'Aktualisierung',
      'Item-Selection': 'Artikel ausgewählt',
      'Items-Selection': 'Elemente ausgewählt',
      View: 'Aussicht',
      Details: 'Einzelheiten',
      SelectAll: 'Wählen Sie Alle',
      Open: 'Öffnen',
      'Tooltip-NewFolder': 'Neuer Ordner',
      'Tooltip-Upload': 'Hochladen',
      'Tooltip-Delete': 'Löschen',
      'Tooltip-Rename': 'Umbenennen',
      'Tooltip-Download': 'Herunterladen',
      'Tooltip-Cut': 'Ausschneiden',
      'Tooltip-Copy': 'Kopieren',
      'Tooltip-Paste': 'Einfügen',
      'Tooltip-SortBy': 'Sortiere nach',
      'Tooltip-Refresh': 'Aktualisierung',
      'Tooltip-Selection': 'Auswahl aufheben',
      'Tooltip-View': 'Aussicht',
      'Tooltip-Details': 'Einzelheiten',
      'Tooltip-SelectAll': 'Wählen Sie Alle',
      Name: 'Name',
      Size: 'Grösse',
      DateModified: 'Geändert',
      DateCreated: 'Datum erstellt',
      Path: 'Pfad',
      Modified: 'Geändert',
      Created: 'Erstellt',
      Location: 'Ort',
      Type: 'Art',
      Permission: 'Genehmigung',
      Ascending: 'Aufsteigend',
      Descending: 'Absteigend',
      None: 'Keiner',
      'View-LargeIcons': 'Grosse Icons',
      'View-Details': 'Einzelheiten',
      Search: 'Suchen',
      'Button-Ok': 'in Ordnung',
      'Button-Cancel': 'Abbrechen',
      'Button-Yes': 'Ja',
      'Button-No': 'Nein',
      'Button-Create': 'Erstellen',
      'Button-Save': 'Speichern',
      'Header-NewFolder': 'Mappe',
      'Content-NewFolder': 'Geben Sie Ihren Ordnernamen ein',
      'Header-Rename': 'Umbenennen',
      'Content-Rename': 'Geben Sie Ihren neuen Namen ein',
      'Header-Rename-Confirmation': 'Bestätigung umbenennen',
      'Content-Rename-Confirmation':
        'Wenn Sie eine Dateinamenerweiterung ändern, wird die Datei möglicherweise instabil. Möchten Sie das wirklich ändern?',
      'Header-Delete': 'Datei löschen',
      'Content-Delete': 'Möchten Sie diese Datei wirklich löschen?',
      'Header-Folder-Delete': 'Lösche Ordner',
      'Content-Folder-Delete': 'Möchten Sie diesen Ordner wirklich löschen?',
      'Header-Multiple-Delete': 'Mehrere Dateien löschen',
      'Content-Multiple-Delete':
        'Möchten Sie diese {0} Dateien wirklich löschen?',
      'Header-Duplicate': 'Datei / Ordner existiert',
      'Content-Duplicate':
        '{0} existiert bereits. Möchten Sie umbenennen und einfügen?',
      'Header-Upload': 'Daten hochladen',
      Error: 'Fehler',
      'Validation-Empty': 'Der Datei- oder Ordnername darf nicht leer sein.',
      'Validation-Invalid':
        'Der Datei- oder Ordnername {0} enthält ungültige Zeichen. Bitte benutze einen anderen Namen. Gültige Datei- oder Ordnernamen dürfen nicht mit einem Punkt oder Leerzeichen enden und keines der folgenden Zeichen enthalten: \\ /: *? "<> |',
      'Validation-NewFolder-Exists':
        'Eine Datei oder ein Ordner mit dem Namen {0} ist bereits vorhanden.',
      'Validation-Rename-Exists':
        '{0} kann nicht in {1} umbenannt werden: Ziel ist bereits vorhanden.',
      'Folder-Empty': 'Dieser Ordner ist leer',
      'File-Upload': 'Ziehen Sie die Dateien hierher, um sie hochzuladen',
      'Search-Empty': 'keine Ergebnisse gefunden',
      'Search-Key': 'Versuchen Sie es mit verschiedenen Stichwörtern',
      'Filter-Empty': 'keine Ergebnisse gefunden',
      'Filter-Key': 'Versuchen Sie es mit einem anderen Filter',
      'Sub-Folder-Error':
        'Der Zielordner ist der Unterordner des Quellordners.',
      'Same-Folder-Error':
        'Der Zielordner ist der gleiche wie der Quellordner.',
      'Access-Denied': 'Zugriff verweigert',
      'Access-Details':
        'Sie haben keine Berechtigung, auf diesen Ordner zuzugreifen.',
      'Header-Retry': 'Die Datei existiert bereits',
      'Content-Retry':
        'In diesem Ordner existiert bereits eine Datei mit diesem Namen. Was würdest du gern tun?',
      'Button-Keep-Both': 'Behalte beides',
      'Button-Replace': 'Ersetzen',
      'Button-Skip': 'Überspringen',
      'ApplyAll-Label': 'Mache das für alle aktuellen Artikel',
      KB: 'KB',
      'Access-Message':
        '{0} ist nicht zugänglich. Sie benötigen die Berechtigung, um die Aktion {1} auszuführen.',
      'Network-Error':
        'NetworkError: Fehler beim Senden auf XMLHTTPRequest: Fehler beim Laden',
      'Server-Error': 'ServerError: Ungültige Antwort von',
    },
    calendar: {
      today: 'Heute',
    },
    datepicker: {
      today: 'Heute',
      placeholder: 'Wählen Sie ein Datum',
    },
    daterangepicker: {
      placeholder: 'Wählen Sie einen Datumsbereich',
      startLabel: 'Anfangsdatum',
      endLabel: 'Enddatum',
      applyText: 'Anwenden',
      cancelText: 'Abbrechen',
      selectedDays: 'Ausgewählte Tage',
      days: 'Tage',
      customRange: 'Benutzerdefinierter Bereich',
    },
    timepicker: {
      placeholder: 'Wählen Sie eine Zeit aus',
    },
    datetimepicker: {
      today: 'Heute',
      placeholder: 'Wählen Sie ein Datum und eine Uhrzeit',
    },
    dialog: {
      close: 'Schliessen',
    },
    toast: {
      close: 'Schliessen',
    },
    tab: {
      closeButtonTitle: 'Schliessen',
    },
    schedule: {
      day: 'Tag',
      week: 'Woche',
      workWeek: 'Arbeitswoche',
      month: 'Monat',
      year: 'Jahr',
      agenda: 'Agenda',
      weekAgenda: 'Woche Agenda',
      workWeekAgenda: 'Arbeitswoche Agenda',
      monthAgenda: 'Monatsagenda',
      today: 'Heute',
      noEvents: 'Keine Ereignisse',
      emptyContainer: 'An diesem Tag sind keine Veranstaltungen geplant.',
      allDay: 'Den ganzen Tag',
      start: 'Anfang',
      end: 'Ende',
      more: 'Mehr',
      close: 'Schließen',
      cancel: 'Stornieren',
      noTitle: '(Kein Titel)',
      delete: 'Löschen',
      deleteEvent: 'Ereignis löschen',
      deleteMultipleEvent: 'Löschen Sie mehrere Ereignisse',
      selectedItems: 'Artikel ausgewählt',
      deleteSeries: 'Reihe löschen',
      edit: 'Bearbeiten',
      editSeries: 'Reihe bearbeiten',
      editEvent: 'Veranstaltung bearbeiten',
      createEvent: 'Erstellen',
      subject: 'Gegenstand',
      addTitle: 'Titel hinzufügen',
      moreDetails: 'Mehr Details',
      moreEvents: 'Weitere Veranstaltungen',
      save: 'Speichern',
      editContent: 'Wie möchten Sie den Termin in der Serie ändern?',
      deleteContent: 'Möchten Sie dieses Ereignis wirklich löschen?',
      deleteMultipleContent:
        'Möchten Sie die ausgewählten Ereignisse wirklich löschen?',
      newEvent: 'Neues Event',
      title: 'Titel',
      location: 'Ort',
      description: 'Beschreibung',
      timezone: 'Zeitzone',
      startTimezone: 'Startzeitzone',
      endTimezone: 'Endzeitzone',
      repeat: 'Wiederholen',
      saveButton: 'Speichern',
      cancelButton: 'Stornieren',
      deleteButton: 'Löschen',
      recurrence: 'Wiederholung',
      wrongPattern: 'Das Wiederholungsmuster ist nicht gültig.',
      seriesChangeAlert:
        'Möchten Sie die an bestimmten Instanzen dieser Serie vorgenommenen Änderungen verwerfen und erneut mit der gesamten Serie abgleichen?',
      createError:
        'Die Dauer des Ereignisses muss kürzer sein als die Häufigkeit des Ereignisses. Verkürzen Sie die Dauer oder ändern Sie das Wiederholungsmuster im Wiederholungsereignis-Editor.',
      sameDayAlert:
        'Zwei Vorkommen desselben Ereignisses können nicht am selben Tag auftreten.',
      occurenceAlert:
        'Ein Vorkommen des wiederkehrenden Termins kann nicht neu geplant werden, wenn ein späteres Vorkommen desselben Termins übersprungen wird.',
      editRecurrence: 'Wiederholung bearbeiten',
      repeats: 'Wiederholt',
      alert: 'Aufmerksam',
      startEndError: 'Das ausgewählte Enddatum liegt vor dem Startdatum.',
      invalidDateError: 'Der eingegebene Datumswert ist ungültig.',
      blockAlert:
        'Ereignisse können nicht innerhalb des gesperrten Zeitbereichs geplant werden.',
      ok: 'OK',
      yes: 'Ja',
      no: 'Nein',
      occurrence: 'Auftreten',
      series: 'Serie',
      previous: 'Bisherige',
      next: 'Nächster',
      timelineDay: 'Timeline Tag',
      timelineWeek: 'Timeline Woche',
      timelineWorkWeek: 'Timeline-Arbeitswoche',
      timelineMonth: 'Timeline-Monat',
      timelineYear: 'Timeline-Jahr',
      editFollowingEvent: 'Folge Veranstaltungen',
      deleteTitle: 'Ereignis löschen',
      editTitle: 'Ereignis bearbeiten',
      beginFrom: 'Beginnen Sie von',
      endAt: 'Ende um',
      expandAllDaySection: 'Erweitern Sie den ganzen Tag',
      collapseAllDaySection: 'Ganztägiger Zusammenbruch',
      searchTimezone: 'Zeitzone suchen',
      noRecords: 'Keine Aufzeichnungen gefunden',
      deleteRecurrenceContent:
        'Möchten Sie nur dieses Ereignis oder die ganze Serie löschen?',
      recurrenceDateValidation:
        'Einige Monate haben weniger als das ausgewählte Datum. In diesen Monaten fällt das Vorkommen auf das letzte Datum des Monats.',
    },
    recurrenceeditor: {
      none: 'Keiner',
      daily: 'Täglich',
      weekly: 'Wöchentlich',
      monthly: 'Monatlich',
      month: 'Monat',
      yearly: 'Jährlich',
      never: 'noch nie',
      until: 'Bis',
      count: 'Anzahl',
      first: 'Zuerst',
      second: 'Zweite',
      third: 'Dritte',
      fourth: 'Vierte',
      last: 'Letzte',
      repeat: 'Wiederholen',
      repeatEvery: 'Wiederhole jeden',
      on: 'Wiederhole weiter',
      end: 'Ende',
      onDay: 'Tag',
      days: 'Tage',
      weeks: 'Wochen',
      months: 'Monate',
      years: 'Jahre',
      every: 'jeden',
      summaryTimes: 'mal',
      summaryOn: 'auf',
      summaryUntil: 'bis',
      summaryRepeat: 'Wiederholt',
      summaryDay: 'Tage',
      summaryWeek: 'Wochen',
      summaryMonth: 'Monate',
      summaryYear: 'Jahre',
      monthWeek: 'Monat Woche',
      monthPosition: 'Monatliche Position',
      monthExpander: 'Monats-Expander',
      yearExpander: 'Jahres-Expander',
      repeatInterval: 'Wiederholungsintervall',
    },
    gantt: {
      emptyRecord: 'Keine Datensätze zum Anzeigen',
      id: 'AUSWEIS',
      name: 'Name',
      startDate: 'Anfangsdatum',
      endDate: 'Enddatum',
      duration: 'Dauer',
      progress: 'Fortschritt',
      dependency: 'Abhängigkeit',
      notes: 'Anmerkungen',
      baselineStartDate: 'Basisstartdatum',
      baselineEndDate: 'Baseline-Enddatum',
      taskMode: 'Aufgabenmodus',
      changeScheduleMode: 'Ändern Sie den Zeitplanmodus',
      subTasksStartDate: 'Startdatum der Unteraufgaben',
      subTasksEndDate: 'Unteraufgaben Enddatum',
      scheduleStartDate: 'Startdatum planen',
      scheduleEndDate: 'Planen Sie das Enddatum',
      auto: 'Auto',
      manual: 'Handbuch',
      type: 'Art',
      offset: 'Versatz',
      resourceName: 'Ressourcen',
      resourceID: 'Ressourcen-ID',
      day: 'Tag',
      hour: 'Stunde',
      minute: 'Minute',
      days: 'Tage',
      hours: 'Stunden',
      minutes: 'Minuten',
      generalTab: 'Allgemeines',
      customTab: 'Benutzerdefinierte Spalten',
      writeNotes: 'Notizen schreiben',
      addDialogTitle: 'Neue Aufgabe',
      editDialogTitle: 'Aufgabeninformationen',
      saveButton: 'Speichern',
      add: 'Hinzufügen',
      edit: 'Bearbeiten',
      update: 'Aktualisieren',
      delete: 'Löschen',
      cancel: 'Abbrechen',
      search: 'Suchen',
      task: ' Aufgabe',
      tasks: ' Aufgaben',
      zoomIn: 'Hineinzoomen',
      zoomOut: 'Rauszoomen',
      zoomToFit: 'Zoom passend',
      excelExport: 'Excel-Export',
      csvExport: 'CSV-Export',
      expandAll: 'Alle erweitern',
      collapseAll: 'Alles einklappen',
      nextTimeSpan: 'Nächste Zeitspanne',
      prevTimeSpan: 'Vorherige Zeitspanne',
      okText: 'OK',
      confirmDelete: 'Möchten Sie den Datensatz wirklich löschen?',
      from: 'Von',
      to: 'Bis',
      taskLink: 'Task-Link',
      lag: 'Verzögerung',
      start: 'Start',
      finish: 'Fertig',
      enterValue: 'Geben Sie einen Wert ein',
      taskBeforePredecessor_FS:
        "Sie haben '{0}' verschoben, um vor dem Ende von '{1}' zu beginnen, und die beiden Aufgaben sind miteinander verknüpft. Infolgedessen können die Links nicht beachtet werden. Wählen Sie unten eine Aktion aus, die ausgeführt werden soll",
      taskAfterPredecessor_FS:
        "Sie haben '{0}' von '{1}' entfernt und die beiden Aufgaben sind miteinander verknüpft. Infolgedessen können die Links nicht beachtet werden. Wählen Sie unten eine Aktion aus, die ausgeführt werden soll",
      taskBeforePredecessor_SS:
        "Sie haben '{0}' verschoben, um vor dem Start von '{1}' zu beginnen, und die beiden Aufgaben sind miteinander verknüpft. Infolgedessen können die Links nicht beachtet werden. Wählen Sie unten eine Aktion aus, die ausgeführt werden soll",
      taskAfterPredecessor_SS:
        "Sie haben '{0}' verschoben, um nach dem Start von '{1}' zu beginnen, und die beiden Aufgaben sind miteinander verbunden. Infolgedessen können die Links nicht beachtet werden. Wählen Sie unten eine Aktion aus, die ausgeführt werden soll",
      taskBeforePredecessor_FF:
        "Sie haben '{0}' verschoben, um den Vorgang zu beenden, bevor '{1}' abgeschlossen ist, und die beiden Aufgaben sind miteinander verknüpft. Infolgedessen können die Links nicht beachtet werden. Wählen Sie unten eine Aktion aus, die ausgeführt werden soll",
      taskAfterPredecessor_FF:
        'Sie haben "{0}" nach "{1}" verschoben, um den Vorgang abzuschließen, und die beiden Aufgaben sind miteinander verknüpft. Infolgedessen können die Links nicht beachtet werden. Wählen Sie unten eine Aktion aus, die ausgeführt werden soll',
      taskBeforePredecessor_SF:
        "Sie haben '{0}' von '{1}' zum Start verschoben und die beiden Aufgaben sind miteinander verbunden. Infolgedessen können die Links nicht beachtet werden. Wählen Sie unten eine Aktion aus, die ausgeführt werden soll",
      taskAfterPredecessor_SF:
        "Sie haben '{0}' nach dem Start von '{1}' verschoben und die beiden Aufgaben sind miteinander verbunden. Infolgedessen können die Links nicht beachtet werden. Wählen Sie unten eine Aktion aus, die ausgeführt werden soll",
      taskInformation: 'Aufgabeninformationen',
      deleteTask: 'Aufgabe löschen',
      deleteDependency: 'Abhängigkeit löschen',
      convert: 'Konvertieren',
      save: 'Speichern',
      above: 'Über',
      below: 'Unten',
      child: 'Kind',
      milestone: 'Meilenstein',
      toTask: 'Zur Aufgabe',
      toMilestone: 'Zum Meilenstein',
      eventMarkers: 'Ereignismarker',
      leftTaskLabel: 'Linke Aufgabenbezeichnung',
      rightTaskLabel: 'Richtige Aufgabenbezeichnung',
      timelineCell: 'Timeline-Zelle',
      confirmPredecessorDelete:
        'Möchten Sie den Abhängigkeitslink wirklich entfernen?',
      unit: 'Einheit',
      work: 'Arbeit',
      taskType: 'Aufgabentyp',
      unassignedTask: 'Nicht zugewiesene Aufgabe',
      group: 'Gruppe',
      indent: 'Einzug',
      outdent: 'Ausrücken',
      segments: 'Segmente',
      splitTask: 'Aufgabe teilen',
      mergeTask: 'Aufgabe zusammenführen',
      left: 'Links',
      right: 'Recht',
    },
    richtexteditor: {
      alignments: 'Ausrichtungen',
      justifyLeft: 'Linksbündig',
      justifyCenter: 'Zentriert',
      justifyRight: 'Rechts ausrichten',
      justifyFull: 'Justify ausrichten',
      fontName: 'Schriftartenname',
      fontSize: 'Schriftgrösse',
      fontColor: 'Schriftfarbe',
      backgroundColor: 'Hintergrundfarbe',
      bold: 'Fett gedruckt',
      italic: 'Kursiv',
      underline: 'Unterstreichen',
      strikethrough: 'Durchgestrichen',
      clearFormat: 'Format löschen',
      clearAll: 'Alles löschen',
      cut: 'Ausschneiden',
      copy: 'Kopieren',
      paste: 'Einfügen',
      unorderedList: 'Aufzählung',
      orderedList: 'Nummerierte Liste',
      indent: 'Einzug vergrössern',
      outdent: 'Einzug verringern',
      undo: 'Rückgängig machen',
      redo: 'Wiederholen',
      superscript: 'Hochgestellt',
      subscript: 'Tiefgestellt',
      createLink: 'Hyperlink einfügen',
      openLink: 'Verbindung öffnen',
      editLink: 'Link bearbeiten',
      removeLink: 'Link entfernen',
      image: 'Bild einfügen',
      replace: 'Ersetzen',
      numberFormatList: 'Liste der Zahlenformate',
      bulletFormatList: 'Liste der Aufzählungsformate',
      audioReplace: 'Ersetzen',
      videoReplace: 'Ersetzen',
      videoAlign: 'Ausrichten',
      videoDimension: 'Abmessungen',
      audioRemove: 'Entfernen',
      videoRemove: 'Entfernen',
      audioLayoutOption: 'Layout-Option',
      videoLayoutOption: 'Layout-Option',
      align: 'Ausrichten',
      caption: 'Bildbeschreibung',
      remove: 'Entfernen',
      insertLink: 'Link einfügen',
      display: 'Anzeige',
      altText: 'alternativer Text',
      dimension: 'Grösse ändern',
      fullscreen: 'Maximieren',
      maximize: 'Maximieren',
      minimize: 'Minimieren',
      lowerCase: 'Kleinbuchstaben',
      upperCase: 'Grossbuchstaben',
      print: 'Drucken',
      formats: 'Formate',
      sourcecode: 'Code-Ansicht',
      preview: 'Vorschau',
      viewside: 'ViewSide',
      insertCode: 'Code eingeben',
      linkText: 'Text anzeigen',
      linkTooltipLabel: 'Titel',
      linkWebUrl: 'Webadresse',
      linkTitle: 'Geben Sie einen Titel ein',
      linkurl: 'https://example.com',
      linkOpenInNewWindow: 'Link in neuem Fenster öffnen',
      linkHeader: 'Link einfügen',
      dialogInsert: 'Einfügen',
      dialogCancel: 'Abbrechen',
      dialogUpdate: 'Aktualisieren',
      imageHeader: 'Bild einfügen',
      audioHeader: 'Audio einfügen',
      videoHeader: 'Video einfügen',
      imageLinkHeader:
        'Sie können auch einen Link aus dem Internet bereitstellen',
      audioLinkHeader:
        'Sie können auch einen Link aus dem Internet bereitstellen',
      videoLinkHeader: 'Web-URL',
      embedVideoLinkHeader: 'Medieneinbettungs-URL',
      mdimageLink: 'Bitte geben Sie eine URL für Ihr Bild an',
      imageUploadMessage:
        'Legen Sie das Bild hier ab oder durchsuchen Sie es, um es hochzuladen',
      audioUploadMessage:
        'Legen Sie eine Audiodatei ab oder navigieren Sie zum Hochladen',
      videoUploadMessage:
        'Legen Sie eine Videodatei ab oder navigieren Sie zum Hochladen',
      imageDeviceUploadMessage: 'Klicken Sie hier zum Hochladen',
      audioDeviceUploadMessage: 'Klicken Sie hier, um es hochzuladen',
      videoDeviceUploadMessage: 'Klicken Sie hier, um es hochzuladen',
      imageAlternateText: 'Alternativer Text',
      alternateHeader: 'alternativer Text',
      browse: 'Durchsuche',
      imageUrl: 'https://example.com/image.png',
      audioUrl: 'https://example.com/audio.mp3',
      videoUrl: 'https://example.com/video.mp4',
      webUrl: 'Web-URL',
      embedUrl: 'Code einbetten',
      imageCaption: 'Untertitel',
      imageSizeHeader: 'Bildgrösse',
      imageHeight: 'Höhe',
      imageWidth: 'Breite',
      videoHeight: 'Höhe',
      videoWidth: 'Breite',
      textPlaceholder: 'Text eingeben',
      inserttablebtn: 'Tabelle einfügen',
      tabledialogHeader: 'Tabelle einfügen',
      tableWidth: 'Breite',
      cellpadding: 'Zellauffüllung',
      cellspacing: 'Zellenabstand',
      columns: 'Anzahl der Spalten',
      rows: 'Zeilenanzahl',
      tableRows: 'Reihe',
      tableColumns: 'Spalte',
      tableCellHorizontalAlign: 'Horizontale Ausrichtung der Tabellenzelle',
      tableCellVerticalAlign: 'Tabelle vertikal ausrichten',
      createTable: 'Tabelle erstellen',
      removeTable: 'Tabelle entfernen',
      tableHeader: 'Kopfzeile',
      tableRemove: 'Tabelle löschen',
      tableCellBackground: 'Tabellenzellenhintergrund',
      tableEditProperties: 'Tabelle Eigenschaften bearbeiten',
      styles: 'Stile',
      insertColumnLeft: 'Spalte links einfügen',
      insertColumnRight: 'Spalte rechts einfügen',
      deleteColumn: 'Spalte löschen',
      insertRowBefore: 'Zeile davor einfügen',
      insertRowAfter: 'Zeile danach einfügen',
      deleteRow: 'Zeile löschen',
      tableEditHeader: 'Tabelle bearbeiten',
      TableHeadingText: 'Überschrift',
      TableColText: 'Col',
      imageInsertLinkHeader: 'Link einfügen',
      editImageHeader: 'Bild bearbeiten',
      alignmentsDropDownLeft: 'Linksbündig',
      alignmentsDropDownCenter: 'Zentriert',
      alignmentsDropDownRight: 'Rechts ausrichten',
      alignmentsDropDownJustify: 'Justify ausrichten',
      imageDisplayDropDownInline: 'In der Reihe',
      imageDisplayDropDownBreak: 'Brechen',
      audioLayoutOptionDropDownInline: 'Im Einklang',
      audioLayoutOptionDropDownBreak: 'Brechen',
      videoLayoutOptionDropDownInline: 'Im Einklang',
      videoLayoutOptionDropDownBreak: 'Brechen',
      tableInsertRowDropDownBefore: 'Zeile davor einfügen',
      tableInsertRowDropDownAfter: 'Zeile danach einfügen',
      tableInsertRowDropDownDelete: 'Zeile löschen',
      tableInsertColumnDropDownLeft: 'Spalte links einfügen',
      tableInsertColumnDropDownRight: 'Spalte rechts einfügen',
      tableInsertColumnDropDownDelete: 'Spalte löschen',
      tableVerticalAlignDropDownTop: 'Oben ausrichten',
      tableVerticalAlignDropDownMiddle: 'Mitte ausrichten',
      tableVerticalAlignDropDownBottom: 'Unten ausrichten',
      tableStylesDropDownDashedBorder: 'Gestrichelte Grenzen',
      tableStylesDropDownAlternateRows: 'Alternative Zeilen',
      pasteFormat: 'Format einfügen',
      pasteFormatContent: 'Wählen Sie die Formatierungsaktion',
      plainText: 'Einfacher Text',
      cleanFormat: 'Sauber',
      keepFormat: 'Behalten',
      pasteDialogOk: 'in Ordnung',
      pasteDialogCancel: 'Stornieren',
      fileManager: 'Dateimanager',
      fileDialogHeader: 'Dateibrowser',
      formatsDropDownParagraph: 'Absatz',
      formatsDropDownCode: 'Code',
      formatsDropDownQuotation: 'Zitat',
      formatsDropDownHeading1: 'Überschrift 1',
      formatsDropDownHeading2: 'Überschrift 2',
      formatsDropDownHeading3: 'Überschrift 3',
      formatsDropDownHeading4: 'Überschrift 4',
      fontNameSegoeUI: 'SegoeUI',
      fontNameArial: 'Arial',
      fontNameGeorgia: 'Georgia',
      fontNameImpact: 'Impact',
      fontNameTahoma: 'Tahoma',
      fontNameTimesNewRoman: 'Times New Roman',
      fontNameVerdana: 'Verdana',
      formatsOLListNumber: 'Nummer',
      formatsOLListLowerAlpha: 'LowerAlpha',
      formatsOLListUpperAlpha: 'OberAlpha',
      formatsOLListLowerRoman: 'Niederroman',
      formatsOLListUpperRoman: 'Oberrömisch',
      formatsOLListLowerGreek: 'Niedergriechisch',
      formatsULListDisc: 'Rabatt',
      formatsULListCircle: 'Kreis',
      formatsULListSquare: 'Quadrat',
      formatsOLListNone: 'Keiner',
      formatsULListNone: 'Keiner',
      formatPainter: 'Format Maler',
      emojiPicker: 'Emoji-Picker',
      embeddedCode: 'Eingebetteter Code',
      pasteEmbeddedCodeHere: 'Fügen Sie hier eingebetteten Code ein',
      emojiPickerTypeToFind: 'Geben Sie „Suchen“ ein',
      emojiPickerNoResultFound: 'keine Ergebnisse gefunden',
      emojiPickerTrySomethingElse: 'Versuchen Sie etwas anderes',
      linkAriaLabel: 'In einem neuen Fenster öffnen',
      imageLinkAriaLabel: 'In einem neuen Fenster öffnen',
      unsupportedImage: 'Nicht unterstütztes Dateiformat',
      mergecells: 'Zellen verbinden',
      verticalsplit: 'Vertikale Aufteilung',
      horizontalsplit: 'Horizontale Aufteilung',
      numberFormatListLowerAlpha: 'Unteres Alpha',
      numberFormatListUpperAlpha: 'Oberes Alpha',
      numberFormatListLowerRoman: 'Niederrömisch',
      numberFormatListUpperRoman: 'Oberrömisch',
      numberFormatListLowerGreek: 'Niedergriechisch',
    },
    colorpicker: {
      Apply: 'Übernehmen',
      Cancel: 'Abbrechen',
      ModeSwitcher: 'Wechselmodus',
    },
    uploader: {
      Browse: 'Durchsuche...',
      Clear: 'Löschen',
      Upload: 'Hochladen',
      dropFilesHint: 'Oder legen Sie Dateien hier ab',
      invalidMaxFileSize: 'Die Dateigrösse ist zu gross',
      invalidMinFileSize: 'Die Dateigrösse ist zu klein',
      invalidFileType: 'Dateityp ist nicht erlaubt',
      uploadFailedMessage: 'Datei konnte nicht hochgeladen werden',
      uploadSuccessMessage: 'Datei erfolgreich hochgeladen',
      removedSuccessMessage: 'Datei erfolgreich entfernt',
      removedFailedMessage: 'Datei kann nicht entfernt werden',
      inProgress: 'Hochladen',
      readyToUploadMessage: 'Bereit zum Hochladen',
      abort: 'Abbrechen',
      remove: 'Entfernen',
      cancel: 'Abbrechen',
      delete: 'Datei löschen',
      pauseUpload: 'Datei-Upload angehalten',
      pause: 'Pause',
      resume: 'Fortsetzen',
      retry: 'Wiederholen',
      fileUploadCancel: 'Datei-Upload abgebrochen',
    },
    numerictextbox: {
      incrementTitle: 'Inkrementieren Sie den Wert',
      decrementTitle: 'Wert verringern',
    },
    slider: {
      incrementTitle: 'Erhöhen, ansteigen',
      decrementTitle: 'Verringern',
    },
    formValidator: {
      required: 'Dieses Feld wird benötigt.',
      email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      url: 'Bitte geben Sie eine gültige URL ein.',
      date: 'Bitte gib ein korrektes Datum an.',
      dateIso: 'Bitte geben Sie ein gültiges Datum (ISO) ein.',
      creditcard: 'Bitte geben Sie eine gültige Kartennummer ein',
      number: 'Bitte geben Sie eine gültige Nummer ein.',
      digits: 'Bitte gib nur Ziffern ein.',
      maxLength: 'Bitte geben Sie nicht mehr als {0} Zeichen ein.',
      minLength: 'Bitte geben Sie mindestens {0} Zeichen ein.',
      rangeLength:
        'Bitte geben Sie einen Wert zwischen {0} und {1} Zeichen ein.',
      range: 'Bitte geben Sie einen Wert zwischen {0} und {1} ein.',
      max: 'Bitte geben Sie einen Wert ein, der kleiner oder gleich {0} ist.',
      min: 'Bitte geben Sie einen Wert größer oder gleich {0} ein.',
      regex: 'Bitte geben Sie einen korrekten Wert ein.',
      tel: 'Bitte geben Sie eine gültige Telefonnummer ein.',
      pattern: 'Bitte geben Sie einen korrekten Musterwert ein.',
      equalTo: 'Bitte geben Sie den gültigen Übereinstimmungstext ein',
    },
    treegrid: {
      Above: 'Über',
      Below: 'Unter',
      AddRow: 'Zeile hinzufügen',
      ExpandAll: 'Alle erweitern',
      CollapseAll: 'Alles schließen',
    },
    querybuilder: {
      StartsWith: 'Beginnt mit',
      EndsWith: 'Endet mit',
      Contains: 'Enthält',
      NotLike: 'Enthält',
      Like: 'Nicht wie',
      Equal: 'Gleich',
      NotEqual: 'Nicht gleich',
      LessThan: 'Weniger als',
      LessThanOrEqual: 'Weniger als oder gleich',
      GreaterThan: 'Grösser als',
      GreaterThanOrEqual: 'Grösser als oder gleich',
      Between: 'Zwischen',
      NotBetween: 'Nicht zwischen',
      Empty: 'Leeren',
      NotEmpty: 'Nicht leer',
      In: 'In',
      NotIn: 'Nicht in',
      NotContains: 'Enthält nicht',
      Remove: 'ENTFERNEN',
      SelectField: 'Wählen Sie ein Feld aus',
      SelectOperator: 'Betreiber auswählen',
      DeleteRule: 'Entfernen Sie diesen Zustand',
      DeleteGroup: 'Gruppe löschen',
      AddGroup: 'Gruppe hinzufügen',
      AddCondition: 'Bedingung hinzufügen',
      Edit: 'BEARBEITEN',
      ValidationMessage: 'Dieses Feld wird benötigt',
      SummaryViewTitle: 'Zusammenfassungsansicht',
      OtherFields: 'Andere Felder',
      AND: 'UND',
      OR: 'ODER',
      SelectValue: 'Wert eingeben',
      IsEmpty: 'Ist leer',
      IsNotEmpty: 'Ist nicht leer',
      IsNull: 'Ist Null',
      IsNotNull: 'Ist nicht Null',
      True: 'wahr',
      False: 'falsch',
      DoesNotStartWith: 'Beginnt nicht mit',
      DoesNotEndWith: 'Endet nicht mit',
      DoesNotContain: 'Beinhaltet nicht',
      AddButton: 'Gruppe/Bedingung hinzufügen',
      CloneGroup: 'Klongruppe',
      LockGroup: 'Gruppe sperren',
      CloneRule: 'Klonregel',
      LockRule: 'Sperrregel',
      UnlockRule: 'Regel freischalten',
      UnlockGroup: 'Gruppe entsperren',
    },
    barcode: {},
    datamatrix: {},
    qrcode: {},
    pivotview: {
      grandTotal: 'Gesamtsumme',
      total: 'Gesamt',
      value: 'Wert',
      noValue: 'Kein Wert',
      row: 'Reihe',
      column: 'Spalte',
      collapse: 'Zusammenbruch',
      expand: 'Erweitern',
      rowAxisPrompt: 'Zeile hier ablegen',
      columnAxisPrompt: 'Spalte hier ablegen',
      valueAxisPrompt: 'Wert hier ablegen',
      filterAxisPrompt: 'Filter hier ablegen',
      filter: 'Filter',
      filtered: 'Gefiltert',
      sort: 'Sortieren',
      filters: 'Filter',
      rows: 'Zeilen',
      columns: 'Spalten',
      values: 'Werte',
      close: 'Schliessen',
      cancel: 'Abbrechen',
      delete: 'Löschen',
      CalculatedField: 'Berechnetes Feld',
      createCalculatedField: 'Berechnetes Feld erstellen',
      fieldName: 'Geben Sie den Feldnamen ein',
      error: 'Fehler',
      invalidFormula: 'Ungültige Formel',
      dropText: "Beispiel: ('Summe (Order_Count)' + 'Summe (In_Stock)') * 250",
      dropTextMobile:
        'Fügen Sie hier Felder hinzu und bearbeiten Sie die Formel.',
      dropAction:
        'Das berechnete Feld kann nicht in einer anderen Region ausser der Werteachse platziert werden.',
      alert: 'Warnen',
      warning: 'Warnung',
      ok: 'in Ordnung',
      search: 'Suchen',
      drag: 'Ziehen',
      remove: 'Entfernen',
      allFields: 'Alle Felder',
      formula: 'Formel',
      addToRow: 'Zur Zeile hinzufügen',
      addToColumn: 'Zur Spalte hinzufügen',
      addToValue: 'Wert hinzufügen',
      addToFilter: 'Zum Filter hinzufügen',
      emptyData: 'Keine Datensätze zum Anzeigen',
      fieldExist:
        'In diesem Namen ist bereits ein Feld vorhanden. Bitte geben Sie einen anderen Namen ein.',
      confirmText:
        'In diesem Namen ist bereits ein Berechnungsfeld vorhanden. Möchten Sie es ersetzen?',
      noMatches: 'Keine Treffer',
      format: 'Fasst die Werte nach zusammen',
      edit: 'Bearbeiten',
      clear: 'Löschen',
      formulaField: 'Ziehen Sie Felder in die Formel und legen Sie sie dort ab',
      dragField: 'Ziehen Sie das Feld in die Formel',
      clearFilter: 'Filter löschen',
      by: 'durch',
      all: 'Alle',
      multipleItems: 'Mehrere Artikel',
      member: 'Mitglied',
      label: 'Etikette',
      date: 'Datum',
      enterValue: 'Wert eingeben',
      chooseDate: 'Datum eingeben',
      Before: 'Vor',
      BeforeOrEqualTo: 'Vorher oder gleich',
      After: 'Nach',
      AfterOrEqualTo: 'Nach oder gleich',
      labelTextContent:
        'Zeigen Sie die Datensätze, für die das Label angezeigt wird',
      dateTextContent: 'Zeigen Sie die Datensätze an, für die das Datum liegt',
      valueTextContent: 'Zeigen Sie die Datensätze für die',
      Equals: 'Gleich',
      DoesNotEquals: 'Ist nicht gleich',
      BeginWith: 'Beginnt mit',
      DoesNotBeginWith: 'Beginnt nicht mit',
      EndsWith: 'Endet mit',
      DoesNotEndsWith: 'Endet nicht mit',
      Contains: 'Enthält',
      DoesNotContains: 'Beinhaltet nicht',
      GreaterThan: 'Grösser als',
      GreaterThanOrEqualTo: 'Grösser als oder gleich wie',
      LessThan: 'Weniger als',
      LessThanOrEqualTo: 'Weniger als oder gleich',
      Between: 'Zwischen',
      NotBetween: 'Nicht zwischen',
      And: 'und',
      Sum: 'Summe',
      Count: 'Anzahl',
      DistinctCount: 'Eindeutiger Graf',
      Product: 'Produkt',
      Avg: 'Durchschn',
      Min: 'Mindest',
      SampleVar: 'Beispielvariante',
      PopulationVar: 'Bevölkerung Var',
      RunningTotals: 'Laufende Summen',
      Max: 'Max',
      Index: 'Index',
      SampleStDev: 'Beispiel StDev',
      PopulationStDev: 'Bevolkerung StDev',
      PercentageOfRowTotal: '% der Zeilensumme',
      PercentageOfParentTotal: '% der übergeordneten Summe',
      PercentageOfParentColumnTotal:
        '% der Gesamtsumme der übergeordneten Spalte',
      PercentageOfParentRowTotal: '% der Summe der übergeordneten Zeilen',
      DifferenceFrom: 'Unterschied von',
      PercentageOfDifferenceFrom: '% der Differenz von',
      PercentageOfGrandTotal: '% der Gesamtsumme',
      PercentageOfColumnTotal: '% der Spaltensumme',
      NotEquals: 'Nicht gleich',
      AllValues: 'Alle Werte',
      conditionalFormatting: 'Bedingte Formatierung',
      apply: 'Übernehmen',
      condition: 'Bedingung hinzufügen',
      formatLabel: 'Format',
      valueFieldSettings: 'Wertfeldeinstellungen',
      baseField: 'Basisfeld:',
      baseItem: 'Basisartikel:',
      summarizeValuesBy: 'Werte zusammenfassen nach:',
      sourceName: 'Feldname :',
      sourceCaption: 'Feldbeschriftung:',
      example: 'z.B:',
      editorDataLimitMsg: ' weitere Artikel. Suchen, um weiter zu verfeinern.',
      details: 'Einzelheiten',
      manageRecords: 'Datensätze verwalten',
      Years: 'Jahre',
      Quarters: 'Viertel',
      Months: 'Monate',
      Days: 'Tage',
      Hours: 'Std',
      Minutes: 'Protokoll',
      Seconds: 'Sekunden',
      save: 'Speichern Sie einen Bericht',
      new: 'Erstellen Sie einen neuen Bericht',
      load: 'Belastung',
      saveAs: 'Als aktuellen Bericht speichern',
      rename: 'Benennen Sie einen aktuellen Bericht um',
      deleteReport: 'Löschen Sie einen aktuellen Bericht',
      export: 'Export',
      subTotals: 'Zwischensummen',
      grandTotals: 'Gesamtsummen',
      reportName: 'Berichtsname:',
      pdf: 'PDF',
      excel: 'Excel',
      csv: 'CSV',
      png: 'PNG',
      jpeg: 'JPEG',
      svg: 'SVG',
      mdxQuery: 'MDX-Abfrage',
      showSubTotals: 'Zwischensummen anzeigen',
      doNotShowSubTotals: 'Keine Zwischensummen anzeigen',
      showSubTotalsRowsOnly: 'Nur Zwischensummenzeilen anzeigen',
      showSubTotalsColumnsOnly: 'Nur Zwischensummenspalten anzeigen',
      showGrandTotals: 'Gesamtsummen anzeigen',
      doNotShowGrandTotals: 'Keine Gesamtsummen anzeigen',
      showGrandTotalsRowsOnly: 'Nur Gesamtsummenzeilen anzeigen',
      showGrandTotalsColumnsOnly: 'Nur Gesamtsummenspalten anzeigen',
      fieldList: 'Feldliste anzeigen',
      grid: 'Tabelle anzeigen',
      toolbarFormatting: 'Bedingte Formatierung',
      chart: 'Diagramm',
      reportMsg: 'Bitte geben Sie einen gültigen Berichtsnamen ein !!!',
      reportList: 'Berichtsliste',
      removeConfirm: 'Möchten Sie diesen Bericht wirklich löschen?',
      emptyReport: 'Keine Berichte gefunden !!',
      bar: 'Bar',
      line: 'Linie',
      area: 'Bereich',
      scatter: 'Streuen',
      polar: 'Polar',
      of: 'von',
      emptyFormat: 'Kein Format gefunden !!!',
      emptyInput: 'Geben Sie einen Wert ein',
      newReportConfirm: 'Möchten Sie Änderungen für den Bericht speichern?',
      emptyReportName: 'Geben Sie einen Berichtsnamen ein',
      qtr: 'Qtr',
      null: 'Null',
      undefined: 'nicht definiert',
      groupOutOfRange: 'Außer Reichweite',
      fieldDropErrorAction:
        'Das Feld, das Sie verschieben, kann nicht in diesem Bereich des Berichts platziert werden',
      MoreOption: 'Mehr...',
      aggregate: 'Aggregat',
      drillThrough: 'Durchbohren',
      ascending: 'Aufsteigend',
      descending: 'Absteigend',
      number: 'Nummer',
      currency: 'Währung',
      percentage: 'Prozentsatz',
      formatType: 'Formattyp',
      customText: 'Währungszeichen',
      symbolPosition: 'Symbolposition',
      left: 'Links',
      right: 'Richtig',
      grouping: 'Gruppierung',
      true: 'Wahr',
      false: 'Falsch',
      decimalPlaces: 'Nachkommastellen',
      numberFormat: 'Zahlenformatierung',
      memberType: 'Feldtyp',
      formatString: 'Zeichenfolge formatieren',
      expressionField: 'Ausdruck',
      customFormat: 'Geben Sie eine benutzerdefinierte Formatzeichenfolge ein',
      selectedHierarchy: 'Übergeordnete Hierarchie',
      olapDropText:
        'Beispiel: [Maße]. [Bestellmenge] + ([Maße]. [Bestellmenge] * 0,10)',
      Percent: 'Prozent',
      Custom: 'Benutzerdefiniert',
      Measure: 'Messen',
      Dimension: 'Abmessungen',
      Standard: 'Standard',
      blank: '(Leer)',
      fieldTooltip:
        "Felder ziehen und ablegen, um einen Ausdruck zu erstellen. Und wenn Sie die vorhandenen berechneten Felder bearbeiten möchten! Dann können Sie es erreichen, indem Sie einfach das Feld unter 'Berechnete Mitglieder' auswählen.",
      QuarterYear: 'Vierteljahr',
      fieldTitle: 'Feldname',
      drillError:
        'Die Rohdaten der berechneten Felder können nicht angezeigt werden.',
      caption: 'Feldbeschriftung',
      copy: 'Kopieren',
      defaultReport: 'Standardbericht',
      customFormatString: 'Benutzerdefiniertes Format',
      invalidFormat: 'Ungültiges Format.',
      group: 'Gruppe',
      unGroup: 'Gruppierung aufheben',
      invalidSelection: 'Diese Auswahl kann nicht gruppiert werden.',
      groupName:
        'Geben Sie die Beschriftung ein, die in der Kopfzeile angezeigt werden soll',
      captionName: 'Geben Sie die Beschriftung für das Gruppenfeld ein',
      selectedItems: 'Ausgewählte Gegenstände',
      groupFieldCaption: 'Feldbeschriftung',
      groupTitle: 'Gruppenname',
      startAt: 'Beginnt um',
      endAt: 'Endet am',
      groupBy: 'Intervall von',
      selectGroup: 'Wählen Sie Gruppen aus',
      numberFormatString: 'Beispiel: C, P, 0000%, ### 0. ## 0 # usw.',
      stackingcolumn: 'Gestapelte Säule',
      stackingbar: 'Gestapelte Bar',
      stackingarea: 'Gestapelter Bereich',
      stepline: 'Schrittlinie',
      steparea: 'Schrittbereich',
      splinearea: 'Spline-Bereich',
      spline: 'Spline',
      stackingcolumn100: '100% gestapelte Säule',
      stackingbar100: '100% gestapelte Stange',
      stackingarea100: '100% gestapelte Fläche',
      bubble: 'Blase',
      pareto: 'Pareto',
      radar: 'Radar',
      chartTypeSettings: 'Diagrammtypeinstellungen',
      multipleAxes: 'Mehrere Achsen',
      sortAscending: 'Aufsteigende Reihenfolge sortieren',
      sortDescending: 'Absteigende Reihenfolge sortieren',
      sortNone: 'Datenreihenfolge sortieren',
      clearCalculatedField: 'Bearbeiten Sie die bearbeiteten Feldinformationen',
      editCalculatedField: 'Berechnetes Feld bearbeiten',
      ChartType: 'Diagramm Typ',
      yes: 'Ja',
      no: 'Nein',
      numberFormatMenu: 'Zahlenformatierung ...',
      conditionalFormattingMenu: 'Bedingte Formatierung...',
      removeCalculatedField:
        'Möchten Sie dieses berechnete Feld wirklich löschen?',
      replaceConfirmBefore: 'Ein Bericht mit dem Namen',
      replaceConfirmAfter: ' ist bereits vorhanden. Möchten Sie es ersetzen?',
      pie: 'Kuchen',
      funnel: 'Trichter',
      doughnut: 'Krapfen',
      pyramid: 'Pyramide',
      showLegend: 'Legende anzeigen',
      exit: 'Ausgang',
      invalidJSON: 'Ungültige JSON-Daten',
      invalidCSV: 'Ungültige CSV-Daten',
      stacked: 'Gestapelt',
      single: 'Einzel',
      multipleAxisMode: 'Mehrachsenmodus',
      grandTotalPosition: 'Gesamtsummenposition',
      top: 'Spitze',
      bottom: 'Unterseite',
      None: 'Keiner',
      stackingline: 'Gestapelte Linie',
      stackingline100: '100% Gestapelte Linie',
      rowPage: 'Zeilenpager',
      rowPerPage: 'Zeilen pro Seite',
      columnPage: 'Spaltenpager',
      columnPerPage: 'Spalten pro Seite',
      goToFirstPage: 'Gehen Sie zur ersten Seite',
      goToPreviousPage: 'Zurück zur letzten Seite',
      goToNextPage: 'Gehen Sie zur nächsten Seite',
      goToLastPage: 'Gehen Sie zur letzten Seite',
      combined: 'Kombiniert',
      subTotalPosition: 'Zwischensumme Position',
      auto: 'Auto',
      loading: 'Wird geladen...',
    },
    pivotfieldlist: {
      staticFieldList: 'Pivot-Feldliste',
      fieldList: 'Feldliste',
      dropFilterPrompt: 'Filter hier ablegen',
      dropColPrompt: 'Spalte hier ablegen',
      dropRowPrompt: 'Zeile hier ablegen',
      dropValPrompt: 'Wert hier ablegen',
      addPrompt: 'Feld hier hinzufügen',
      adaptiveFieldHeader: 'Feld auswählen',
      centerHeader: 'Felder zwischen Achsen ziehen:',
      add: 'hinzufügen',
      drag: 'Ziehen',
      filter: 'Filter',
      filtered: 'Gefiltert',
      sort: 'Sortieren',
      remove: 'Entfernen',
      filters: 'Filter',
      rows: 'Zeilen',
      columns: 'Spalten',
      values: 'Werte',
      CalculatedField: 'Berechnetes Feld',
      createCalculatedField: 'Berechnetes Feld erstellen',
      fieldName: 'Geben Sie den Feldnamen ein',
      error: 'Fehler',
      invalidFormula: 'Ungültige Formel',
      dropText:
        "Beispiel: ('Summe (Order_Count)' + 'Summe (In_Stock)' ') * 250",
      dropTextMobile:
        'Fügen Sie hier Felder hinzu und bearbeiten Sie die Formel.',
      dropAction:
        'Das berechnete Feld kann nicht in einer anderen Region ausser der Werteachse platziert werden.',
      search: 'Suchen',
      close: 'Schliessen',
      cancel: 'Abbrechen',
      delete: 'Löschen',
      alert: 'Warnen',
      warning: 'Warnung',
      ok: 'in Ordnung',
      allFields: 'Alle Felder',
      formula: 'Formel',
      fieldExist:
        'In diesem Namen ist bereits ein Feld vorhanden. Bitte geben Sie einen anderen Namen ein.',
      confirmText:
        'In diesem Namen ist bereits ein Berechnungsfeld vorhanden. Möchten Sie es ersetzen?',
      noMatches: 'Keine Treffer',
      format: 'Fasst die Werte nach zusammen',
      edit: 'Bearbeiten',
      clear: 'Löschen',
      formulaField: 'Ziehen Sie Felder in die Formel und legen Sie sie dort ab',
      dragField: 'Ziehen Sie das Feld in die Formel',
      clearFilter: 'Filter löschen',
      by: 'durch',
      enterValue: 'Wert eingeben',
      chooseDate: 'Datum eingeben',
      all: 'Alle',
      multipleItems: 'Mehrere Artikel',
      Equals: 'Gleich',
      DoesNotEquals: 'Ist nicht gleich',
      BeginWith: 'Beginnt mit',
      DoesNotBeginWith: 'Beginnt nicht mit',
      EndsWith: 'Endet mit',
      DoesNotEndsWith: 'Endet nicht mit',
      Contains: 'Enthält',
      DoesNotContains: 'Beinhaltet nicht',
      GreaterThan: 'Grösser als',
      GreaterThanOrEqualTo: 'Grösser als oder gleich wie',
      LessThan: 'Weniger als',
      LessThanOrEqualTo: 'Weniger als oder gleich',
      Between: 'Zwischen',
      NotBetween: 'Nicht zwischen',
      Before: 'Vor',
      BeforeOrEqualTo: 'Vorher oder gleich',
      After: 'Nach',
      AfterOrEqualTo: 'Nach oder gleich',
      member: 'Mitglied',
      label: 'Etikette',
      date: 'Datum',
      value: 'Wert',
      labelTextContent:
        'Zeigen Sie die Datensätze, für die das Label angezeigt wird',
      dateTextContent: 'Zeigen Sie die Datensätze an, für die das Datum liegt',
      valueTextContent: 'Zeigen Sie die Datensätze für die',
      And: 'und',
      Sum: 'Summe',
      Count: 'Anzahl',
      DistinctCount: 'Eindeutiger Graf',
      Product: 'Produkt',
      Avg: 'Durchschn',
      Min: 'Mindest',
      Max: 'Max',
      Index: 'Index',
      SampleStDev: 'Beispiel StDev',
      PopulationStDev: 'Bevolkerung StDev',
      SampleVar: 'Probenvar',
      PopulationVar: 'Bevölkerung Var',
      RunningTotals: 'Laufende Summen',
      DifferenceFrom: 'Unterschied von',
      PercentageOfDifferenceFrom: '% der Differenz von',
      PercentageOfGrandTotal: '% der Gesamtsumme',
      PercentageOfColumnTotal: '% der Spaltensumme',
      PercentageOfRowTotal: '% der Zeilensumme',
      PercentageOfParentTotal: '% der übergeordneten Summe',
      PercentageOfParentColumnTotal:
        '% der Gesamtsumme der übergeordneten Spalte',
      PercentageOfParentRowTotal: '% der Summe der übergeordneten Zeilen',
      Years: 'Jahre',
      Quarters: 'Viertel',
      Months: 'Monate',
      Days: 'Tage',
      Hours: 'Std',
      Minutes: 'Protokoll',
      Seconds: 'Sekunden',
      apply: 'Übernehmen',
      valueFieldSettings: 'Wertfeldeinstellungen',
      sourceName: 'Feldname :',
      sourceCaption: 'Feldbeschriftung:',
      summarizeValuesBy: 'Werte zusammenfassen nach:',
      baseField: 'Basisfeld:',
      baseItem: 'Basisartikel:',
      example: 'z.B:',
      editorDataLimitMsg: ' weitere Artikel. Suchen, um weiter zu verfeinern.',
      deferLayoutUpdate: 'Layout-Aktualisierung verschieben',
      null: 'Null',
      undefined: 'nicht definiert',
      groupOutOfRange: 'Außer Reichweite',
      fieldDropErrorAction:
        'Das Feld, das Sie verschieben, kann nicht in diesem Bereich des Berichts platziert werden',
      MoreOption: 'Mehr...',
      memberType: 'Feldtyp',
      selectedHierarchy: 'Übergeordnete Hierarchie',
      formatString: 'Zeichenfolge formatieren',
      expressionField: 'Ausdruck',
      olapDropText:
        'Beispiel: [Maße]. [Bestellmenge] + ([Maße]. [Bestellmenge] * 0,10)',
      customFormat: 'Geben Sie eine benutzerdefinierte Formatzeichenfolge ein',
      Measure: 'Messen',
      Dimension: 'Abmessungen',
      Standard: 'Standard',
      Currency: 'Währung',
      Percent: 'Prozent',
      Custom: 'Benutzerdefiniert',
      blank: '(Leer)',
      fieldTooltip:
        "Felder ziehen und ablegen, um einen Ausdruck zu erstellen. Und wenn Sie die vorhandenen berechneten Felder bearbeiten möchten! Sie erreichen dies, indem Sie einfach das Feld unter 'Berechnete Mitglieder' auswählen.",
      fieldTitle: 'Feldname',
      QuarterYear: 'Vierteljahr',
      caption: 'Feldbeschriftung',
      copy: 'Kopieren',
      group: 'Gruppe',
      numberFormatString: 'Beispiel: C, P, 0000%, ### 0. ## 0 # usw.',
      sortAscending: 'Aufsteigende Reihenfolge sortieren',
      sortDescending: 'Absteigende Reihenfolge sortieren',
      sortNone: 'Datenreihenfolge sortieren',
      clearCalculatedField: 'Bearbeiten Sie die bearbeiteten Feldinformationen',
      editCalculatedField: 'Berechnetes Feld bearbeiten',
      selectGroup: 'Wählen Sie Gruppen aus',
      of: 'von',
      removeCalculatedField:
        'Möchten Sie dieses berechnete Feld wirklich löschen?',
      yes: 'Ja',
      no: 'Nein',
      None: 'Keiner',
    },
    pdfviewer: {
      PdfViewer: 'PDF-Viewer',
      Cancel: 'Abbrechen',
      'Download file': 'Datei herunterladen',
      Download: 'Herunterladen',
      'Enter Password':
        'Dieses Dokument ist passwortgeschützt. Bitte Passwort eingeben.',
      'File Corrupted': 'Datei ist beschädigt',
      'File Corrupted Content':
        'Die Datei ist beschädigt und kann nicht geöffnet werden.',
      'Fit Page': 'Seite anpassen',
      'Fit Width': 'Breite anpassen',
      Automatic: 'Automatisch',
      'Go To First Page': 'Erste Seite anzeigen',
      'Invalid Password': 'Falsches Passwort. Bitte erneut versuchen.',
      'Next Page': 'Nächste Seite anzeigen',
      OK: 'OK',
      Open: 'Datei öffnen',
      'Page Number': 'Aktuelle Seitennummer',
      'Previous Page': 'Vorherige Seite anzeigen',
      'Go To Last Page': 'Letzte Seite anzeigen',
      Zoom: 'Zoomen',
      'Zoom In': 'Hineinzoomen',
      'Zoom Out': 'Herauszoomen',
      'Page Thumbnails': 'Seitenminiaturen',
      Bookmarks: 'Lesezeichen',
      Print: 'Druckdatei',
      'Password Protected': 'Passwort erforderlich',
      Copy: 'Kopieren',
      'Text Selection': 'Textauswahl-Tool',
      Panning: 'Schwenkmodus',
      'Text Search': 'Text finden',
      'Find in document': 'Im Dokument suchen',
      'Match case': 'Gross- / Kleinschreibung',
      Apply: 'Übernehmen',
      GoToPage: 'Gehe zu Seite',
      'No matches':
        'Der Viewer hat die Suchen im Dokument beendet. Es wurden keine weiteren Übereinstimmungen gefunden',
      'No Text Found': 'Kein Text gefunden',
      Undo: 'Rückgängig machen',
      Redo: 'Wiederholen',
      Annotation: 'Anmerkungen hinzufügen oder bearbeiten',
      Highlight: 'Text hervorheben',
      Underline: 'Text unterstreichen',
      Strikethrough: 'Durchgestrichener Text',
      Delete: 'Anmerkung löschen',
      Opacity: 'Opazität',
      'Color edit': 'Farbe ändern',
      'Opacity edit': 'Deckkraft ändern',
      'Highlight context': 'Markieren',
      'Underline context': 'Unterstreichen',
      'Strikethrough context': 'Durchstreichen',
      'Server error':
        'Web-Service hört nicht zu. PDF Viewer ist in allen Funktionen vom Web-Service abhängig. Bitte starten Sie den Webdienst, um fortzufahren.',
      'Open text': 'Öffnen',
      'First text': 'Erste Seite',
      'Previous text': 'Vorherige Seite',
      'Next text': 'Nächste Seite',
      'Last text': 'Letzte Seite',
      'Zoom in text': 'Hineinzoomen',
      'Zoom out text': 'Rauszoomen',
      'Selection text': 'Auswahl',
      'Pan text': 'Schwenken',
      'Print text': 'Drucken',
      'Search text': 'Suchen',
      'Annotation Edit text': 'Anmerkung bearbeiten',
      'Line Thickness': 'Dicke der Linie',
      'Line Properties': 'Linieneigenschaften',
      'Start Arrow': 'Pfeil starten',
      'End Arrow': 'Pfeil beenden',
      'Line Style': 'Linienart',
      'Fill Color': 'Füllfarbe',
      'Line Color': 'Linienfarbe',
      None: 'Keiner',
      'Open Arrow': 'Öffnen',
      'Closed Arrow': 'Geschlossen',
      'Round Arrow': 'Runden',
      'Square Arrow': 'Platz',
      'Diamond Arrow': 'Diamant',
      Butt: 'Hintern',
      Cut: 'Ausschneiden',
      Paste: 'Einfügen',
      'Delete Context': 'Löschen',
      Properties: 'Eigenschaften',
      'Add Stamp': 'Stempel hinzufügen',
      'Add Shapes': 'Formen hinzufügen',
      'Stroke edit': 'Strichfarbe ändern',
      'Change thickness': 'Randstärke ändern',
      'Add line': 'Zeile hinzufügen',
      'Add arrow': 'Pfeil hinzufügen',
      'Add rectangle': 'Rechteck hinzufügen',
      'Add circle': 'Kreis hinzufügen',
      'Add polygon': 'Polygon hinzufügen',
      'Add Comments': 'Füge Kommentare hinzu',
      Comments: 'Bemerkungen',
      'No Comments Yet': 'Noch keine Kommentare',
      Accepted: 'Akzeptiert',
      Completed: 'Abgeschlossen',
      Cancelled: 'Abgesagt',
      Rejected: 'Abgelehnt',
      'Leader Length': 'Länge des Anführers',
      'Scale Ratio': 'Skalierungsverhältnis',
      Calibrate: 'Kalibrieren',
      'Calibrate Distance': 'Entfernung kalibrieren',
      'Calibrate Perimeter': 'Perimeter kalibrieren',
      'Calibrate Area': 'Bereich kalibrieren',
      'Calibrate Radius': 'Radius kalibrieren',
      'Calibrate Volume': 'Lautstärke kalibrieren',
      highlight: 'Markieren',
      underline: 'Unterstreichen',
      strikethrough: 'Durchstreichen',
      'Seach text': 'Suchen',
      FormDesigner: 'Formularfelder hinzufügen und bearbeiten',
      'Client error':
        'Clientseitiger Fehler gefunden. Bitte überprüfen Sie die benutzerdefinierten Header in der Eigenschaft AjaxRequestSettings und die Webaktionsmethoden in der Eigenschaft ServerActionSettings.',
      'FormDesigner Edit text': 'Formularfelder hinzufügen und bearbeiten',
      SubmitForm: 'Formular absenden',
      Depth: 'Tiefe',
      Closed: 'Geschlossen',
      Round: 'Runden',
      Square: 'Quadrat',
      Diamond: 'Diamant',
      Edit: 'Bearbeiten',
      Comment: 'Kommentar',
      'Comment Panel': 'Kommentarbereich',
      'Set Status': 'Status einstellen',
      Post: 'Post',
      Page: 'Seite',
      'Add a comment': 'Einen Kommentar hinzufügen',
      'Add a reply': 'Fügen Sie eine Antwort hinzu',
      'Import Annotations': 'Importieren Sie Anmerkungen aus der JSON-Datei',
      'Export Annotations': 'Anmerkung in JSON-Datei exportieren',
      'Export XFDF': 'Anmerkung in XFDF-Datei exportieren',
      'Import XFDF': 'Importieren Sie Anmerkungen aus der XFDF-Datei',
      Add: 'Hinzufügen',
      Clear: 'klar',
      Bold: 'Fett',
      Italic: 'Kursiv',
      Strikethroughs: 'Durchgestrichen',
      Underlines: 'Unterstreichen',
      Superscript: 'Hochgestellt',
      Subscript: 'Tiefgestellt',
      'Align left': 'Linksbündig',
      'Align right': 'Rechtsbündig',
      Center: 'Zentriert',
      Justify: 'Blocksatz',
      'Font color': 'Schriftfarbe',
      'Text Align': 'Textausrichtung',
      'Text Properties': 'Schriftstil',
      SignatureFieldDialogHeaderText: 'Signatur hinzufügen',
      HandwrittenSignatureDialogHeaderText: 'Signatur hinzufügen',
      InitialFieldDialogHeaderText: 'Initiale hinzufügen',
      HandwrittenInitialDialogHeaderText: 'Initiale hinzufügen',
      'Draw Ink': 'Tinte zeichnen',
      Create: 'Erstellen',
      'Font family': 'Schriftfamilie',
      'Font size': 'Schriftgröße',
      'Free Text': 'Freier Text',
      'Import Failed':
        'Ungültiger JSON-Dateityp oder Dateiname; Bitte wählen Sie eine gültige JSON-Datei aus',
      'File not found':
        'Die importierte JSON-Datei wurde nicht am gewünschten Speicherort gefunden',
      'Export Failed':
        'Die Aktion zum Exportieren von Anmerkungen ist fehlgeschlagen. Bitte stellen Sie sicher, dass Anmerkungen ordnungsgemäß hinzugefügt werden',
      of: 'von ',
      Dynamic: 'Dynamisch',
      'Standard Business': 'Standardgeschäft',
      'Sign Here': 'Hier unterschreiben',
      'Custom Stamp': 'Kundenspezifischer Stempel',
      'Enter Signature as Name': 'Gib deinen Namen ein',
      'Draw-hand Signature': 'ZEICHNEN',
      'Type Signature': 'ART',
      'Upload Signature': 'HOCHLADEN',
      'Browse Signature Image': 'DURCHSUCHE',
      'Save Signature': 'Unterschrift speichern',
      'Save Initial': 'Initial speichern',
      Textbox: 'Textfeld',
      Password: 'Passwort',
      'Check Box': 'Kontrollkästchen',
      'Radio Button': 'Radio knopf',
      Dropdown: 'Dropdown-Liste',
      'List Box': 'Listenfeld',
      Signature: 'Unterschrift',
      'Delete FormField': 'Formularfeld löschen',
      'Textbox Properties': 'Textbox-Eigenschaften',
      Name: 'Name',
      Tooltip: 'Kurzinfo',
      Value: 'Wert',
      'Form Field Visibility': 'Sichtbarkeit von Formularfeldern',
      'Read Only': 'Schreibgeschützt',
      Required: 'Erforderlich',
      Checked: 'Geprüft',
      'Show Printing': 'Drucken anzeigen',
      Formatting: 'Format',
      Fill: 'Füllen',
      Border: 'Rahmen',
      'Border Color': 'Rahmenfarbe',
      Thickness: 'Dicke',
      'Max Length': 'Maximale Länge',
      'List Item': 'Artikelname',
      'Export Value': 'Gegenstandswert',
      'Dropdown Item List': 'Dropdown-Artikelliste',
      'List Box Item List': 'Listenfeld Artikelliste',
      General: 'ALLGEMEINES',
      Appearance: 'AUSSEHEN',
      Options: 'OPTIONEN',
      'Delete Item': 'Löschen',
      Up: 'Hoch',
      Down: 'Runter',
      Multiline: 'Mehrzeilig',
      Revised: 'Überarbeitet',
      Reviewed: 'Überprüft',
      Received: 'Erhalten',
      Confidential: 'Vertraulich',
      Approved: 'Genehmigt',
      'Not Approved': 'Nicht genehmigt',
      Witness: 'Zeuge',
      'Initial Here': 'Initiale hier',
      Draft: 'Entwurf',
      Final: 'Finale',
      'For Public Release': 'Zur Veröffentlichung',
      'Not For Public Release': 'Nicht zur Veröffentlichung',
      'For Comment': 'Für Kommentar',
      Void: 'Leere',
      'Preliminary Results': 'Vorläufige Ergebnisse',
      'Information Only': 'Nur Informationen',
      in: 'in',
      m: 'm',
      ft_in: 'ft_in',
      ft: 'ft',
      p: 'p',
      cm: 'cm',
      mm: 'mm',
      pt: 'pt',
      cu: 'cu',
      sq: 'Quadrat',
      Initial: 'Initial',
    },
    diagram: {
      Copy: 'Kopieren',
      Cut: 'Ausschneiden',
      Paste: 'Einfügen',
      Undo: 'Rückgängig machen',
      Redo: 'Wiederholen',
      SelectAll: 'Wählen Sie Alle',
      Grouping: 'Gruppierung',
      Group: 'Gruppe',
      UnGroup: 'Gruppierung aufheben',
      Order: 'Bestellung',
      BringToFront: 'Nach vorne bringen',
      MoveForward: 'Vorwärts bewegen',
      SendToBack: 'An Zurück senden',
      SendBackward: 'Rückwärts senden',
    },
    'inplace-editor': {
      save: 'Speichern',
      cancel: 'Abbrechen',
      loadingText: 'Wird geladen...',
      editIcon: 'Zum Bearbeiten anklicken',
      editAreaClick: 'Zum Bearbeiten anklicken',
      editAreaDoubleClick: 'Doppelklick zum bearbeiten',
    },
    drawing: {},
    'drop-down-base': {
      noRecordsTemplate: 'Keine Datensätze gefunden',
      actionFailureTemplate: 'Die Anfrage ist fehlgeschlagen',
    },
    chart: {
      Zoom: 'Zoomen',
      ZoomIn: 'Hineinzoomen',
      ZoomOut: 'Herauszoomen',
      Reset: 'Zurücksetzen',
      Pan: 'Verschieben',
      ResetZoom: 'Zoom zurücksetzen',
    },
    maps: {
      Zoom: 'Zoomen',
      ZoomIn: 'Hineinzoomen',
      ZoomOut: 'Herauszoomen',
      Reset: 'Zurücksetzen',
      Pan: 'Verschieben',
      ResetZoom: 'Zoom zurücksetzen',
      ImageNotFound: 'Bild nicht gefunden',
    },
    'drop-down-tree': {
      noRecordsTemplate: 'Keine Aufzeichnungen gefunden',
      actionFailureTemplate: 'Anfrage fehlgeschlagen',
      overflowCountTemplate: '+ ${count} mehr ..',
      totalCountTemplate: '${count} ausgewählt',
    },
    documenteditor: {
      Table: 'Tabelle',
      Row: 'Reihe',
      Cell: 'Zelle',
      Ok: 'In Ordnung',
      Apply: 'Progressiv',
      Cancel: 'Abbrechen',
      Size: 'Grösse',
      'Preferred Width': 'Bevorzugte Breite',
      Points: 'Punkte',
      Percent: 'Prozent',
      'Measure in': 'Messen Sie in',
      Alignment: 'Ausrichtung',
      Left: 'Links',
      Center: 'Zentriert',
      Right: 'Rechts',
      Decimal: 'Dezimal',
      Bar: 'Straßensperre',
      Leader: 'Manager',
      Tab: 'Unbezahlte Rechnung',
      BookMarkList: 'Eine Liste von Lesezeichen in einem Dokument',
      TabMarkList: 'Eine Liste von Tabstopps in einem Absatz',
      'Tab stop position': 'Tabstoppposition',
      'Default tab stops': 'Standardkarte',
      'Tab stops to be cleared': 'Karten zum Löschen',
      Tabs: 'Tab-Fenster',
      Set: 'Rekrutierung',
      Clear: 'Transparent',
      'Clear All': 'Alles löschen',
      Justify: 'Rechtfertigen',
      'Indent from left': 'Einzug von links',
      'Borders and Shading': 'Rahmen und Schattierung',
      Options: 'Optionen',
      'Specify height': 'Geben Sie die Höhe an',
      'At least': 'Mindestens',
      Exactly: 'Genau',
      'Row height is': 'Reihenhöhe ist',
      'Allow row to break across pages': 'Zeilenumbruch über Seiten zulassen',
      'Repeat as header row at the top of each page':
        'Wiederholen Sie dies als Kopfzeile oben auf jeder Seite',
      'Vertical alignment': 'Vertikale Ausrichtung',
      Top: 'oben',
      Bottom: 'Unterseite',
      'Default cell margins': 'Standard-Zellenränder',
      'Default cell spacing': 'Standardzellenabstand',
      'Allow spacing between cells':
        'Lassen Sie den Abstand zwischen den Zellen zu',
      'Cell margins': 'Zellränder',
      'Same as the whole table': 'Gleich wie der ganze Tisch',
      Borders: 'Grenzen',
      None: 'Keiner',
      Style: 'Stil',
      Width: 'Breite',
      Height: 'Höhe',
      Letter: 'Brief',
      Tabloid: 'Boulevardzeitung',
      Legal: 'Legal',
      Statement: 'Erklärung',
      Executive: 'Exekutive',
      A3: 'A3',
      A4: 'A4',
      A5: 'A5',
      B4: 'B4',
      B5: 'B5',
      'Custom Size': 'Benutzerdefiniertes Format',
      'Different odd and even': 'Unterschiedlich ungerade und gerade',
      'Different first page': 'Andere erste Seite',
      'From edge': 'Vom Rand',
      Header: 'Header',
      Footer: 'Fusszeile',
      'First Page Header': 'Kopfzeile der ersten Seite',
      'First Page Footer': 'Fußzeile der ersten Seite',
      'Even Page Header': 'Gerader Seitenkopf',
      'Even Page Footer': 'Gleichmäßige Fußzeile',
      'Odd Page Header': 'Ungerade Seitenkopfzeile',
      'Odd Page Footer': 'Ungerade Seitenfußzeile',
      'Same as Previous': 'Das gleiche wie vorher',
      Section: 'Abschnitt',
      Margin: 'Ränder',
      Paper: 'Papier',
      Layout: 'Layout',
      Orientation: 'Orientierung',
      Landscape: 'Landschaft',
      Portrait: 'Porträt',
      'Show page numbers': 'Seitenzahlen anzeigen',
      'Right align page numbers': 'Seitenzahlen nach rechts ausrichten',
      Nothing: 'Nichts',
      'Tab leader': 'Tab Leader',
      'Show levels': 'Ebenen anzeigen',
      'Use hyperlinks instead of page numbers':
        'Verwenden Sie Hyperlinks anstelle von Seitenzahlen',
      'Build table of contents from':
        'Erstellen Sie das Inhaltsverzeichnis von',
      Styles: 'Stile',
      'Available styles': 'Verfügbare Stile',
      'TOC level': 'TOC-Ebene',
      Heading: 'Überschrift',
      'Heading 1': 'Überschrift 1',
      'Heading 2': 'Überschrift 2',
      'Heading 3': 'Überschrift 3',
      'Heading 4': 'Überschrift 4',
      'Heading 5': 'Überschrift 5',
      'Heading 6': 'Überschrift 6',
      'List Paragraph': 'Absatz auflisten',
      Normal: 'Normal',
      'Outline levels': 'Gliederungsebenen',
      'Table entry fields': 'Tabelleneingabefelder',
      Modify: 'Ändern',
      Color: 'Farbe',
      Setting: 'Rahmen',
      Box: 'Kasten',
      All: 'Alle',
      Custom: 'Benutzerdefiniert',
      Preview: 'Vorschau',
      Shading: 'Schattierung',
      Fill: 'Füllen',
      'Apply To': 'Gelten',
      'Table Properties': 'Tabelleneigenschaften',
      'Cell Options': 'Zellenoptionen',
      'Table Options': 'Tabellenoptionen',
      'Insert Table': 'Tabelle einfügen',
      'Number of columns': 'Anzahl der Spalten',
      'Number of rows': 'Zeilenanzahl',
      'Text to display': 'Anzuzeigender Text',
      'ScreenTip text': 'QuickInfo-Text',
      Address: 'Adresse',
      'Insert Hyperlink': 'Hyperlink einfügen',
      'Edit Hyperlink': 'Hyperlink bearbeiten',
      Insert: 'Einfügen',
      General: 'Allgemeines',
      Indentation: 'Vertiefung',
      'Before text': 'Vor dem Text',
      Special: 'Besondere',
      'First line': 'Erste Linie',
      Hanging: 'Hängend',
      'After text': 'Nach dem Text',
      By: 'Durch',
      Before: 'Vor',
      'Line Spacing': 'Zeilenabstand',
      After: 'Nach',
      At: 'Beim',
      Multiple: 'Mehrere',
      Spacing: 'Abstand',
      'Define new Multilevel list': 'Neue Mehrebenenliste definieren',
      'List level': 'Listenebene',
      'Choose level to modify': 'Wählen Sie die zu ändernde Ebene',
      Level: 'Niveau',
      'Number format': 'Zahlenformat',
      'Number style for this level': 'Zahlenstil für diese Ebene',
      'Enter formatting for number':
        'Geben Sie die Formatierung für die Nummer ein',
      'Start at': 'Beginne bei',
      'Restart list after': 'Liste danach neu starten',
      Position: 'Position',
      'Text indent at': 'Text einrücken um',
      'Aligned at': 'Ausgerichtet um',
      'Follow number with': 'Folgen Sie der Nummer mit',
      'Tab character': 'Tabulatorzeichen',
      Space: 'Platz',
      Arabic: 'Arabisch',
      UpRoman: 'UpRoman',
      LowRoman: 'LowRoman',
      UpLetter: 'UpLetter',
      LowLetter: 'LowLetter',
      Number: 'Nummer',
      'Leading zero': 'Führende Null',
      Bullet: 'Kugel',
      Ordinal: 'Ordinal',
      'Ordinal Text': 'Ordinaler Text',
      'For East': 'Für den Osten',
      'No Restart': 'Kein Neustart',
      Font: 'Schriftart',
      'Font style': 'Schriftstil',
      'Underline style': 'Unterstreichen Sie Stil',
      'Font color': 'Schriftfarbe',
      Effects: 'Auswirkungen',
      Strikethrough: 'Durchgestrichen',
      Superscript: 'Hochgestellt',
      Subscript: 'Tiefgestellt',
      'Double strikethrough': 'Doppelt durchgestrichen',
      Regular: 'Normal',
      Bold: 'Fett',
      Italic: 'Kursiv',
      Cut: 'Ausschneiden',
      Copy: 'Kopieren',
      Paste: 'Einfügen',
      Hyperlink: 'Hyperlink',
      'Open Hyperlink': 'Öffnen Sie den Hyperlink',
      'Copy Hyperlink': 'Hyperlink kopieren',
      'Remove Hyperlink': 'Hyperlink entfernen',
      Paragraph: 'Absatz',
      'Linked Style': 'Verknüpft (Absatz und Zeichen)',
      Character: 'Charakter',
      'Merge Cells': 'Zellen verbinden',
      'Insert Above': 'Oben einfügen',
      'Insert Below': 'Unten einfügen',
      'Insert Left': 'Links einfügen',
      'Insert Right': 'Rechts einfügen',
      Delete: 'Löschen',
      'Delete Table': 'Tabelle löschen',
      'Delete Row': 'Zeile löschen',
      'Delete Column': 'Spalte löschen',
      'File Name': 'Dateiname',
      'Format Type': 'Formattyp',
      Save: 'Speichern',
      Navigation: 'Navigation',
      Results: 'Ergebnisse',
      Replace: 'Ersetzen',
      'Replace All': 'Alles ersetzen',
      'We replaced all': 'Wir haben alle ersetzt',
      Find: 'Finden',
      'No matches': 'Keine Treffer',
      'All Done': 'Alles erledigt',
      Result: 'Ergebnis',
      of: 'von',
      instances: 'Instanzen',
      with: 'mit',
      'Click to follow link': 'Klicken Sie hier, um dem Link zu folgen',
      'Continue Numbering': 'Nummerierung fortsetzen',
      'Bookmark name': 'Name des Lesezeichens',
      Close: 'Schliessen',
      'Restart At': 'Neustart um',
      Properties: 'Eigenschaften',
      Name: 'Name',
      'Style type': 'Artart',
      'Style based on': 'Stil basiert auf',
      'Style for following paragraph': 'Stil für folgenden Absatz',
      Formatting: 'Formatierung',
      'Numbering and Bullets': 'Nummerierung und Aufzählungszeichen',
      Numbering: 'Nummerierung',
      'Update Field': 'Feld aktualisieren',
      'Edit Field': 'Feld bearbeiten',
      Bookmark: 'Lesezeichen',
      'Page Setup': 'Seiteneinrichtung',
      'No bookmarks found': 'Keine Lesezeichen gefunden',
      'Number format tooltip information':
        'Einstufiges Zahlenformat: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Beispiel: "Kapitel% 1". Nummerierung wie in </br> Kapitel 1. Element </br> Kapitel 2. Element </br>… </br> Kapitel N. Element </br> </br> Mehrstufiges Zahlenformat: </br> [ PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Zum Beispiel \'% 1.% 2\'. zeigt die Nummerierung wie </br> 1.1 an. Punkt 1.2. Artikel </br>… </br> 1.N. Artikel',
      Format: 'Format',
      'Create New Style': 'Neuen Stil erstellen',
      'Modify Style': 'Stil ändern',
      New: 'Neu',
      Bullets: 'Aufzählungszeichen',
      'Use bookmarks': 'Verwenden Sie Lesezeichen',
      'Table of Contents': 'Inhaltsverzeichnis',
      AutoFit: 'AutoFit',
      'AutoFit to Contents': 'Automatisch an den Inhalt anpassen',
      'AutoFit to Window': 'Automatisch an Fenster anpassen',
      'Fixed Column Width': 'Feste Spaltenbreite',
      Reset: 'Zurücksetzen',
      'Match case': 'Gross- / Kleinschreibung',
      'Whole words': 'Ganze Wörter',
      Add: 'Hinzufügen',
      'Go To': 'Gehe zu',
      'Search for': 'Suchen nach',
      'Replace with': 'Ersetzen mit',
      'TOC 1': 'Inhaltsverzeichnis 1',
      'TOC 2': 'Inhaltsverzeichnis 2',
      'TOC 3': 'Inhaltsverzeichnis 3',
      'TOC 4': 'Inhaltsverzeichnis 4',
      'TOC 5': 'Inhaltsverzeichnis 5',
      'TOC 6': 'Inhaltsverzeichnis 6',
      'TOC 7': 'Inhaltsverzeichnis 7',
      'TOC 8': 'Inhaltsverzeichnis 8',
      'TOC 9': 'Inhaltsverzeichnis 9',
      'Right-to-left': 'Rechts nach links',
      'Left-to-right': 'Links nach rechts',
      Direction: 'Richtung',
      'Table direction': 'Tischrichtung',
      'Indent from right': 'Von rechts einrücken',
      'Contextual Spacing':
        'Fügen Sie keinen Abstand zwischen den Absätzen der gleichen Stile ein',
      'Password Mismatch': 'Das Passwort stimmt nicht überein',
      'Restrict Editing': 'Bearbeitung einschränken',
      'Formatting restrictions': 'Formatierungsbeschränkungen',
      'Allow formatting': 'Formatierung zulassen',
      'Editing restrictions': 'Bearbeitungsbeschränkungen',
      'Read only': 'Schreibgeschützt',
      'Exceptions Optional': 'Ausnahmen (optional)',
      'Select Part Of Document And User':
        'Wählen Sie Teile des Dokuments aus und wählen Sie Benutzer aus, die diese frei bearbeiten dürfen.',
      Everyone: 'Jeder',
      'More users': 'Weitere Benutzer',
      'Add Users': 'Benutzer hinzufügen',
      'Enforcing Protection': 'Ja, Schutz erzwingen',
      'Start Enforcing Protection': 'Starten Sie die Durchsetzung des Schutzes',
      'Enter User': 'Benutzer eingeben',
      Users: 'Benutzer',
      'Enter new password': 'Neues Passwort eingeben',
      'Reenter new password to confirm':
        'Geben Sie das neue Passwort zur Bestätigung erneut ein',
      'Your permissions': 'Ihre Berechtigungen',
      'Protected Document':
        'Dieses Dokument ist vor unbeabsichtigter Bearbeitung geschützt. Sie können in dieser Region Änderungen vornehmen.',
      FormFieldsOnly:
        'Formulare dürfen nur in dieser Region ausgefüllt werden.',
      CommentsOnly: 'Sie können nur Kommentare in diesen Bereich einfügen.',
      ReadOnlyProtection: 'Sie können in dieser Region bearbeiten.',
      'Stop Protection': 'Stoppen Sie den Schutz',
      Password: 'Passwort',
      'Spelling Editor': 'Rechtschreibeditor',
      Spelling: 'Rechtschreibung',
      'Spell Check': 'Rechtschreibprüfung',
      'Underline errors': 'Fehler unterstreichen',
      Ignore: 'Ignorieren',
      'Ignore All': 'Alles ignorieren',
      'Add to Dictionary': 'Zum Wörterbuch hinzufügen',
      Change: 'Veränderung',
      'Change All': 'Ändere Alles',
      Suggestions: 'Vorschläge',
      'The password is incorrect': 'Das Passwort ist inkorrekt',
      'Error in establishing connection with web server':
        'Fehler beim Herstellen der Verbindung mit dem Webserver',
      'Highlight the regions I can edit':
        'Markieren Sie die Regionen, die ich bearbeiten kann',
      'Show All Regions I Can Edit':
        'Alle Regionen anzeigen, die ich bearbeiten kann',
      'Find Next Region I Can Edit':
        'Nächste Region suchen, die ich bearbeiten kann',
      'Keep source formatting': 'Quellformatierung beibehalten',
      'Match destination formatting': 'Zielformatierung anpassen',
      'Text only': 'Nur Text',
      Comments: 'Bemerkungen',
      'Type your comment': 'Geben Sie Ihren Kommentar ein',
      Post: 'Post',
      Reply: 'Antworten',
      'New Comment': 'Neuer Kommentar',
      Edit: 'Bearbeiten',
      Resolve: 'Entschlossenheit',
      Reopen: 'Wieder öffnen',
      'No comments in this document': 'Keine Kommentare in diesem Dokument',
      more: 'Mehr',
      'Type your comment here': 'Geben Sie hier Ihren Kommentar ein',
      'Next Comment': 'Nächster Kommentar',
      'Previous Comment': 'Vorheriger Kommentar',
      'Un-posted comments': 'Nicht veröffentlichte Kommentare',
      'Discard Comment':
        'Hinzugefügt Kommentare nicht gepostet. Wenn Sie fortfahren, wird dieser Kommentar verworfen.',
      Discard: 'Verwerfen',
      'No Headings': 'Keine Überschrift gefunden!',
      'Add Headings':
        'Dieses Dokument hat keine Überschriften. Bitte fügen Sie Überschriften hinzu und versuchen Sie es erneut.',
      'More Options': 'Mehr Optionen',
      'Click to see this comment':
        'Klicken Sie hier, um diesen Kommentar anzuzeigen',
      'Drop Down Form Field': 'Dropdown-Formularfeld',
      'Drop-down items': 'Dropdown-Elemente',
      'Items in drop-down list': 'Elemente in der Dropdown-Liste',
      ADD: 'HINZUFÜGEN',
      REMOVE: 'ENTFERNEN',
      'Field settings': 'Feldeinstellungen',
      Tooltip: 'Tooltip',
      'Drop-down enabled': 'Dropdown aktiviert',
      'Check Box Form Field': 'Kontrollkästchen Formularfeld',
      'Check box size': 'Kontrollkästchengröße',
      Auto: 'Auto',
      'Default value': 'Standardwert',
      'Not checked': 'Nicht geprüft',
      Checked: 'Überprüft',
      'Check box enabled': 'Kontrollkästchen aktiviert',
      'Text Form Field': 'Textformularfeld',
      Type: 'Art',
      'Default text': 'Standardtext',
      'Maximum length': 'Maximale Länge',
      'Text format': 'Textformat',
      'Fill-in enabled': 'Ausfüllen aktiviert',
      'Default number': 'Standardnummer',
      'Default date': 'Standarddatum',
      'Date format': 'Datumsformat',
      'Merge Track':
        'Diese Aktion wird nicht als Änderung markiert. Wollen Sie fortfahren?',
      UnTrack: 'Kann nicht verfolgt werden',
      Accept: 'Akzeptieren',
      Reject: 'Ablehnen',
      'Previous Changes': 'Vorherige Änderungen',
      'Next Changes': 'Nächste Änderungen',
      Inserted: 'Eingefügt',
      Deleted: 'Gelöscht',
      'Move From': 'Gehe von',
      'Move To': 'Übertragen an',
      Changes: 'Änderungen',
      'Accept all': 'Akzeptiere alle',
      'Reject all': 'Alles ablehnen',
      'No changes': 'Keine Änderungen',
      'Accept Changes': 'Änderungen akzeptieren',
      'Reject Changes': 'Änderungen ablehnen',
      User: 'Benutzer',
      View: 'Aussicht',
      Insertion: 'Einfügen',
      Deletion: 'Streichung',
      'All caps': 'Alle Kappen',
      'This region is locked by': 'Diese Region ist gesperrt durch',
      Lock: 'Sperren',
      Unlock: 'Freischalten',
      'Already locked':
        'Ausgewählt oder ein Teil der Region ist bereits von einem anderen Benutzer gesperrt',
      '1, 2, 3, ...': '1, 2, 3, ...',
      'a, b, c, ...': 'a, b, c, ...',
      'A, B, C, ...': 'A, B, C, ...',
      'I, II, III, ...': 'I, II, III, ...',
      'i, ii, iii, ...': 'i, ii, iii, ...',
      'Text Form': 'Textform',
      'Check Box': 'Kontrollkästchen',
      DropDown: 'Dropdown-Liste',
      'Form Fields': 'Formularfelder',
      'Fillin enabled': 'Ausfüllen aktiviert',
      'Multiple Comment': 'Bitte posten Sie Ihren Kommentar',
      InsertFootnote: 'Fußnote einfügen',
      InsertEndnote: 'Endnote einfügen',
      Footnote: 'Fußnote',
      Endnote: 'Endnote',
      'Notes Options': 'Notizoptionen',
      'Click to View/Edit Footnote':
        'Klicken Sie hier, um die Fußnote anzuzeigen / zu bearbeiten',
      'Click to View/Edit Endnote':
        'Klicken Sie hier, um die Endnote anzuzeigen / zu bearbeiten',
      InsertAsRows: 'Als neue Zeilen einfügen',
      InsertAsColumns: 'Als neue Spalten einfügen',
      OverwriteCells: 'Zellen überschreiben',
      NestTable: 'Nesttisch',
      'No suggestions': 'Keine Vorschläge',
      'More Suggestion': 'Mehr Vorschlag',
      'Ignore Once': 'Einmal ignorieren',
      'Keep With Next': 'Weiter mit dem nächsten',
      'Keep Lines Together': 'Linien zusammenhalten',
      WidowControl: 'Witwen-/Waisenkontrolle',
      'Indents and Spacing': 'Einzüge und Abstände',
      'Line and Page Breaks': 'Zeilen- und Seitenumbrüche',
      Pagination: 'Seitennummerierung',
      Single: 'Einzel',
      DashSmallGap: 'DashSmallGap',
      DashDot: 'Strich Punkt',
      Double: 'Doppelt',
      ThinThickSmallGap: 'ThinThickSmallGap',
      ThickThinSmallGap: 'DickDünnKleinGap',
      ThickThinMediumGap: 'DickDünnMediumGap',
      ThickThinLargeGap: 'ThickThinLargeGap',
      SingleWavy: 'SingleWellig',
      DoubleWavy: 'Doppelt gewellt',
      Inset: 'Einsatz',
      DashLargeGap: 'DashLargeGap',
      Dot: 'Punkt',
      DashDotDot: 'DashDotDot',
      Triple: 'Verdreifachen',
      ThinThickThinSmallGap: 'ThinThickThinSmallGap',
      ThinThickThinMediumGap: 'ThinThickThinMediumGap',
      ThinThickThinLargeGap: 'ThinThickThinLargeGap',
      DashDotStroked: 'DashDotStroked',
      Engrave3D: 'Gravieren3D',
      Thick: 'Zähflüssig',
      Outset: 'Anfang',
      Emboss3D: 'Emboss3D',
      ThinThickLargeGap: 'ThinThickLargeGap',
      ThinThickMediumGap: 'ThinThickMediumGap',
      'Number of rows must be between':
        'Die Anzahl der Zeilen muss dazwischen liegen',
      'Number of columns must be between':
        'Die Anzahl der Spalten muss dazwischen liegen',
      and: 'Und',
      Unlimited: 'Unbegrenzt',
      'Regular text': 'Normaler Text',
      Date: 'Datum',
      Uppercase: 'Großbuchstaben',
      Lowercase: 'Kleinbuchstaben',
      FirstCapital: 'FirstCapital',
      TitleCase: 'Titelkoffer',
      'Filling in forms': 'Formulare ausfüllen',
      px: 'px',
      'Tracked changes': 'Verfolgte Änderungen',
      TrackChangesOnly:
        'Sie können in dieser Region Änderungen vornehmen, aber alle Änderungen werden nachverfolgt.',
      RemovedIgnore: 'Möchten Sie die ignorierten Ausnahmen entfernen?',
      RemovedIgnoreExceptions:
        'Wenn Sie diese Änderung im Dokumentschutz vornehmen, ignoriert Word alle Ausnahmen in diesem Dokument.',
      Information: 'Informationen',
      Yes: 'Ja',
      No: 'Nein',
      'Page Break': 'Seitenumbruch',
      'Column Break': 'Spaltenbruch',
      'Section Break Next Page': 'Abschnittsumbruch (nächste Seite)',
      'Section Break Continuous': 'Abschnittswechsel (fortlaufend)',
      'Unsupported format':
        'Das ausgewählte Dateiformat wird nicht unterstützt. Bitte wählen Sie ein gültiges Format.',
      One: 'Eins',
      Two: 'Zwei',
      Three: 'Drei',
      Presets: 'Voreinstellungen',
      Columns: 'Säulen',
      'Split your text into two or more columns':
        'Teilen Sie Ihren Text in zwei oder mehr Spalten auf',
      'Line between column': 'Linie zwischen Spalte',
      'Width and Spacing': 'Breite und Abstand',
      'Equal column width': 'Gleiche Spaltenbreite',
      Column: 'Spalte',
      'Paste Content Dialog':
        'Aufgrund der Sicherheitsrichtlinie des Browsers ist das Einfügen aus der Zwischenablage des Systems eingeschränkt. Alternativ verwenden Sie die Tastenkombination',
      'Paste Content CheckBox': 'Nicht mehr auftauchen',
      'The top/bottom margins are too large for the page height in some sections.':
        'In einigen Abschnitten sind die oberen/unteren Ränder zu groß für die Seitenhöhe.',
      'Column width cannot be less than 36 pt.':
        'Die Spaltenbreite darf nicht weniger als 36 pt betragen.',
      'Left and right margins.':
        'Die von Ihnen gewählten Einstellungen für den linken und rechten Rand, den Spaltenabstand oder die Absatzeinzüge sind zu groß für die Seitenbreite in denselben Abschnitten.',
      "The address of this site is 'not valid. Check the address and try again.":
        'Die Adresse dieser Website ist nicht gültig. Überprüfen Sie die Adresse und versuchen Sie es erneut.',
      'Alt Text': 'Alt-Text',
      Title: 'Titel',
      Description: 'Beschreibung',
      'Left Indent': 'Linker Ball',
      'Right Indent': 'Richtiger Zeitraum',
      'Hanging Indent': 'Hängender Versatz',
      'First Line Indent': 'Der erste ist der Zeileneinzug',
      'Left Margin': 'Linke Ecke',
      'Right Margin': 'Rechte Ecke',
      'Top Margin': 'Oberkante',
      'Bottom Margin': 'Unterkante',
      'Left Tab': 'Linke Registerkarte',
      'Right Tab': 'Rechte Registerkarte',
      'Center Tab': 'Zentrales Lesezeichen',
      'Decimal Tab': 'Tiere kort',
      'Bar Tab': 'Gummiplatte',
      'Move Table Column': 'Verschieben Sie eine Tabellenspalte',
      'Paste CheckBox Content Dialog': 'Nicht mehr anzeigen',
      'The address of this site is not valid. Check the address and try again.':
        'Die Adresse dieser Website ist ungültig. ',
      'Left and right margins':
        'Die von Ihnen gewählten Einstellungen für den linken und rechten Rand, den Spaltenabstand oder die Absatzeinzüge sind zu groß für die Seitenbreite in denselben Abschnitten.',
      'Number of rows must be between 1 and 32767.':
        'Die Anzahl der Zeilen muss zwischen 1 und 32767 liegen.',
      'Number of columns must be between 1 and 63.':
        'Die Anzahl der Spalten muss zwischen 1 und 63 liegen.',
      'You may format text only with certain styles':
        'Sie können Text nur mit bestimmten Stilen formatieren.',
      'Table Of Contents': 'Inhaltsverzeichnis',
    },
    documenteditorcontainer: {
      New: 'Neu',
      Open: 'Öffnen',
      Undo: 'Rückgängig machen',
      Redo: 'Wiederholen',
      Image: 'Bild',
      Table: 'Tabelle',
      Link: 'Verknüpfung',
      Bookmark: 'Lesezeichen',
      'Table of Contents': 'Inhaltsverzeichnis',
      'HEADING - - - - 1': 'ÜBERSCHRIFT 1',
      'HEADING - - - - 2': 'TITEL - - - - 2',
      'HEADING - - - - 3': 'TITEL - - - - 3',
      Header: 'Header',
      Footer: 'Fusszeile',
      'Page Setup': 'Seiteneinrichtung',
      'Page Number': 'Seitennummer',
      Break: 'Brechen',
      Find: 'Finden',
      'Local Clipboard': 'Lokale Zwischenablage',
      'Restrict Editing': 'Bearbeitung einschränken',
      'Upload from computer': 'Hochladen vom Computer',
      'By URL': 'Über die URL',
      'Page Break': 'Seitenumbruch',
      'Section Break': 'Abschnitt Pause',
      'Header And Footer': 'Kopfzeile Fußzeile',
      Options: 'Optionen',
      Levels: 'Ebenen',
      'Different First Page': 'Unterschiedliche erste Seite',
      'Different header and footer for odd and even pages':
        'Unterschiedliche Kopf- und Fußzeilen für gerade und ungerade Seiten.',
      'Different Odd And Even Pages': 'Verschiedene gerade und ungerade Seiten',
      'Different header and footer for first page':
        'Unterschiedliche Kopf- und Fußzeile für die erste Seite.',
      Position: 'Position',
      'Header from Top': 'Kopfzeile von oben',
      'Footer from Bottom': 'Fußzeile von unten',
      'Distance from top of the page to top of the header':
        'Abstand vom oberen Rand der Seite zum oberen Rand der Kopfzeile.',
      'Distance from bottom of the page to bottom of the footer':
        'Abstand vom unteren Rand der Seite zum unteren Rand der Fußzeile.',
      'Aspect ratio': 'Seitenverhältnis',
      W: 'W',
      H: 'H',
      Width: 'Breite',
      Height: 'Höhe',
      Text: 'Text',
      Paragraph: 'Absatz',
      Fill: 'Füllen',
      'Fill color': 'Füllfarbe',
      'Border Style': 'Rahmenstil',
      'Outside borders': 'Außengrenzen',
      'All borders': 'Alle Grenzen',
      'Inside borders': 'Innerhalb der Grenzen',
      'Left border': 'Linke Grenze',
      'Inside vertical border': 'Innerhalb der vertikalen Grenze',
      'Right border': 'Rechter Rand',
      'Top border': 'Oberer Rand',
      'Inside horizontal border': 'Innerhalb der horizontalen Grenze',
      'Bottom border': 'Untere Grenze',
      'Border color': 'Randfarbe',
      'Border width': 'Rahmenbreite',
      Cell: 'Zelle',
      'Merge cells': 'Zellen verbinden',
      'Insert Or Delete': 'Einfügen / Löschen',
      'Insert columns to the left': 'Spalten links einfügen',
      'Insert columns to the right': 'Spalten rechts einfügen',
      'Insert rows above': 'Zeilen darüber einfügen',
      'Insert rows below': 'Zeilen darunter einfügen',
      'Delete rows': 'Zeilen löschen',
      'Delete columns': 'Spalten löschen',
      'Cell Margin': 'Zellrand',
      Top: 'oben',
      Bottom: 'Unterseite',
      Left: 'Links',
      Right: 'Rechts',
      'Align Text': 'Text ausrichten',
      'Align top': 'Oben ausrichten',
      'Align bottom': 'Boden ausrichten',
      'Align center': 'Im Zentrum anordnen',
      'Number of heading or outline levels to be shown in table of contents':
        'Anzahl der Überschriften- oder Gliederungsebenen, die im Inhaltsverzeichnis angezeigt werden sollen.',
      'Show page numbers': 'Seitenzahlen anzeigen',
      'Show page numbers in table of contents':
        'Seitenzahlen im Inhaltsverzeichnis anzeigen.',
      'Right align page numbers': 'Seitenzahlen nach rechts ausrichten',
      'Right align page numbers in table of contents':
        'Richten Sie die Seitenzahlen im Inhaltsverzeichnis nach rechts aus.',
      'Use hyperlinks': 'Verwenden Sie Hyperlinks',
      'Use hyperlinks instead of page numbers':
        'Verwenden Sie Hyperlinks anstelle von Seitenzahlen.',
      Font: 'Schriftart',
      'Font Size': 'Schriftgröße',
      'Font color': 'Schriftfarbe',
      'Text highlight color': 'Hervorhebungsfarbe für Text',
      'Clear all formatting': 'Löschen Sie alle Formatierungen',
      'Bold Tooltip': 'Fett (Strg + B)',
      'Italic Tooltip': 'Kursiv (Strg + I)',
      'Underline Tooltip': 'Unterstreichen (Strg + U)',
      Strikethrough: 'Durchgestrichen',
      'Superscript Tooltip': 'Hochgestellt (Strg + Umschalt ++)',
      'Subscript Tooltip': 'Tiefgestellt (Strg + =)',
      'Align left Tooltip': 'Links ausrichten (Strg + L)',
      'Center Tooltip': 'Zentrum (Strg + E)',
      'Align right Tooltip': 'Rechts ausrichten (Strg + R)',
      'Justify Tooltip': 'Ausrichten (Strg + J)',
      'Decrease indent': 'Einzug verkleinern',
      'Increase indent': 'Einzug vergrößern',
      'Line spacing': 'Zeilenabstand',
      Bullets: 'Aufzählungszeichen',
      Numbering: 'Nummerierung',
      Styles: 'Stile',
      'Manage Styles': 'Stile verwalten',
      Page: 'Seite',
      of: 'von',
      'Fit one page': 'Eine Seite einpassen',
      'Spell Check': 'Rechtschreibprüfung',
      Spelling: 'Rechtschreibung',
      'Underline errors': 'Fehler unterstreichen',
      'Fit page width': 'Seitenbreite anpassen',
      Update: 'Aktualisieren',
      Cancel: 'Abbrechen',
      Insert: 'Einfügen',
      'No Border': 'Keine Grenze',
      'Create a new document': 'Erstellen Sie ein neues Dokument.',
      'Open a document': 'Öffnen Sie ein Dokument.',
      'Undo Tooltip': 'Machen Sie die letzte Operation rückgängig (Strg + Z).',
      'Redo Tooltip': 'Wiederholen Sie die letzte Operation (Strg + Y).',
      'Insert inline picture from a file':
        'Fügen Sie ein Inline-Bild aus einer Datei ein.',
      'Insert a table into the document':
        'Fügen Sie eine Tabelle in das Dokument ein',
      'Create Hyperlink':
        'Erstellen Sie in Ihrem Dokument einen Link für den schnellen Zugriff auf Webseiten und Dateien (Strg + K).',
      'Insert a bookmark in a specific place in this document':
        'Fügen Sie ein Lesezeichen an einer bestimmten Stelle in dieses Dokument ein.',
      'Provide an overview of your document by adding a table of contents':
        'Verschaffen Sie sich einen Überblick über Ihr Dokument, indem Sie ein Inhaltsverzeichnis hinzufügen.',
      'Add or edit the header':
        'Fügen Sie den Header hinzu oder bearbeiten Sie ihn.',
      'Add or edit the footer':
        'Fügen Sie die Fußzeile hinzu oder bearbeiten Sie sie.',
      'Open the page setup dialog':
        'Öffnen Sie das Dialogfeld zur Seiteneinrichtung.',
      'Add page numbers': 'Seitenzahlen hinzufügen.',
      'Find Text': 'Suchen Sie nach Text im Dokument (Strg + F).',
      'Toggle between the internal clipboard and system clipboard':
        'Zwischen der internen Zwischenablage und der Systemzwischenablage wechseln. </br> Der Zugriff auf die Systemzwischenablage über ein Skript wird aufgrund der Sicherheitsrichtlinien des Browsers verweigert. Stattdessen </br> 1. Sie können die interne Zwischenablage zum Ausschneiden, Kopieren und Einfügen innerhalb der Komponente aktivieren. </br> 2. Sie können die Tastenkombinationen (Strg + X, Strg + C und Strg + V) zum Ausschneiden verwenden , kopieren und einfügen mit System-Zwischenablage.',
      'Current Page Number':
        'Die aktuelle Seitenzahl im Dokument. Klicken oder tippen Sie, um zu einer bestimmten Seite zu navigieren.',
      'Read only': 'Schreibgeschützt',
      Protections: 'Schutzmaßnahmen',
      'Error in establishing connection with web server':
        'Fehler beim Herstellen der Verbindung mit dem Webserver',
      Single: 'Einzel',
      Double: 'Doppelt',
      'New comment': 'Neuer Kommentar',
      Comments: 'Bemerkungen',
      'Print layout': 'Drucklayout',
      'Web layout': 'Web-Layout',
      'Text Form': 'Textform',
      'Check Box': 'Kontrollkästchen',
      DropDown: 'Dropdown-Liste',
      'Update Fields': 'Felder aktualisieren',
      'Update cross reference fields': 'Querverweisfelder aktualisieren',
      'Hide properties pane': 'Eigenschaftenbereich ausblenden',
      'Show properties pane': 'Eigenschaftenbereich anzeigen',
      'Form Fields': 'Formularfelder',
      'Track Changes': 'Verfolgen Sie die im Dokument vorgenommenen Änderungen',
      TrackChanges: 'Änderungen verfolgen',
      AllCaps: 'AllCaps',
      'Change case Tooltip': 'Fall ändern',
      'Insert Footnote': 'Fußnote einfügen',
      'Insert Endnote': 'Endnote einfügen',
      'Footnote Tooltip': 'Fußnote einfügen (Alt + Strg + F).',
      'Endnote Tooltip': 'Endnote einfügen (Alt + Strg + D).',
      UPPERCASE: 'GROSSBUCHSTABEN',
      'No color': 'Keine Farbe',
      'Top margin': 'Oberer Rand',
      'Bottom margin': 'Unterer Rand',
      'Left margin': 'Linker Rand',
      'Right margin': 'Rechter Rand',
      Normal: 'Normal',
      Heading: 'Üerschrift',
      'Heading 1': 'Überschrift 1',
      'Heading 2': 'Überschrift 2',
      'Heading 3': 'Überschrift 3',
      'Heading 4': 'Überschrift 4',
      'Heading 5': 'Überschrift 5',
      'Heading 6': 'Überschrift 6',
      ZoomLevelTooltip:
        'Zoomstufe. Klicken oder tippen Sie auf , um die Zoom-Optionen zu öffnen.',
      None: 'Keiner',
      Borders: 'Grenzen',
      'ShowHiddenMarks Tooltip':
        'Ausgeblendete Zeichen wie Leerzeichen, Tabulatoren, Absatzmarken und Umbrüche anzeigen. (Strg + *)',
      Columns: 'Säulen',
      'Column Break': 'Spaltenumbruch',
      'Link to Previous': 'Link zu früher',
      'Link to PreviousTooltip':
        'Verknüpfen Sie diesen Abschnitt mit der Kopf- oder Fußzeile des vorherigen Abschnitts',
      'Alternate Text': 'Alternativer Text',
      'The address of this site is not valid. Check the address and try again.':
        'Die Adresse dieser Website ist ungültig. ',
      OK: 'OK',
      Information: 'Information',
      'Page Breaks': 'Ende der Seiten',
      'Section Breaks': 'Die Division bricht zusammen',
      Column: 'Spalte',
      'Next Page': 'Nächste Seite',
      Continuous: 'kohärent',
    },
    carousel: {
      nextSlide: 'Nächste Folie',
      of: 'von',
      pauseSlideTransition: 'Folienübergang anhalten',
      playSlideTransition: 'Folienübergang abspielen',
      previousSlide: 'Vorherige Folie',
      slide: 'Gleiten',
      slideShow: 'Diashow',
    },
    message: {
      close: 'Nah dran',
    },
    'image-editor': {
      '0': '0%',
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '20': '20 %',
      '40': '40 %',
      '60': '60 %',
      '80': '80 %',
      '100': '100%',
      Crop: 'Ernte',
      CropAndTransform: 'Zuschneiden und transformieren',
      ZoomIn: 'Hineinzoomen',
      ZoomOut: 'Rauszoomen',
      Undo: 'Rückgängig machen',
      Redo: 'Wiederholen',
      Transform: 'Verwandeln',
      Annotation: 'Anmerkung',
      Finetune: 'Feinabstimmung',
      Brightness: 'Helligkeit',
      Contrast: 'Kontrast',
      Hue: 'Farbton',
      Saturation: 'Sättigung',
      Opacity: 'Opazität',
      Blur: 'Verwischen',
      Sharpen: 'Schärfen',
      Exposure: 'Belichtung',
      Filter: 'Filter',
      Default: 'Standard',
      Chrome: 'Chrom',
      Cold: 'Kalt',
      Warm: 'Warm',
      Grayscale: 'Graustufen',
      BlackAndWhite: 'Schwarz und weiß',
      Sepia: 'Sepia',
      Invert: 'Umkehren',
      Text: 'Text hinzufügen',
      Pen: 'Stift',
      Reset: 'Zurücksetzen',
      Save: 'Speichern',
      Select: 'Wählen',
      RotateLeft: 'Nach links drehen',
      RotateRight: 'Drehe nach rechts',
      HorizontalFlip: 'Horizontaler Flip',
      VerticalFlip: 'Vertikaler Flip',
      OK: 'OK',
      Cancel: 'Stornieren',
      DlgOK: 'OK',
      FillColor: 'Füllfarbe',
      StrokeColor: 'Strichfarbe',
      StrokeWidth: 'Strichbreite',
      FontFamily: 'Schriftfamilie',
      FontStyle: 'Schriftstil',
      FontSize: 'Schriftgröße',
      FontColor: 'Schriftfarbe',
      Pan: 'Pfanne',
      Move: 'Bewegen',
      Load: 'Belastung',
      Custom: 'Brauch',
      Square: 'Quadrat',
      Circle: 'Kreis',
      Ellipse: 'Ellipse',
      Rectangle: 'Rechteck',
      Line: 'Linie',
      Arrow: 'Pfeil',
      Path: 'Weg',
      Bold: 'Deutlich',
      Italic: 'Kursiv',
      BoldItalic: 'Fett Kursiv',
      XSmall: 'XS',
      Small: 'Klein',
      Medium: 'Mittel',
      Large: 'Groß',
      XLarge: 'XL',
      ABC: 'ABC',
      Browse: 'Durchsuche',
      Duplicate: 'Duplikat',
      Remove: 'Entfernen',
      EditText: 'Text bearbeiten',
      Start: 'Start',
      End: 'Ende',
      Bar: 'Bar',
      ArrowSolid: 'Pfeil fest',
      CircleSolid: 'Kreis fest',
      SquareSolid: 'Quadratisch massiv',
      None: 'Keiner',
      CropSelection: 'Ernteauswahl',
      Image: 'Bild hinzufügen',
      Transparency: 'Transparenz',
      Height: 'Höhe',
      Width: 'Breite',
      AspectRatio: 'Seitenverhältnis beibehalten',
      W: 'W',
      H: 'H',
      DragText: 'Ziehen Sie Ihr Bild hierher oder legen Sie es dort ab',
      DropText: 'Legen Sie Ihr Bild hier ab oder',
      BrowseText: 'Hier stöbern...',
      SupportText: 'Unterstützt:',
      Frame: 'Rahmen',
      Mat: 'Matte',
      Bevel: 'Fase',
      Inset: 'Einsatz',
      Hook: 'Haken',
      Color: 'Farbe',
      Size: 'Größe',
      Offset: 'Versatz',
      Radius: 'Radius',
      Amount: 'Menge',
      Resize: 'Größe ändern',
      Border: 'Grenze',
      Solid: 'Solide',
      Dashed: 'Gestrichelt',
      Dotted: 'Gepunktet',
      GradientColor: 'Farbverlauf',
      ConfirmDialogHeader: 'Bestätigen Sie „Änderungen speichern“.',
      ConfirmDialogContent:
        'Möchten Sie die am Bild vorgenommenen Änderungen speichern?',
      AlertDialogHeader: 'Nicht unterstützte Datei',
      AlertDialogContent: 'Die abgelegte Datei wird nicht unterstützt.',
      Yes: 'Ja',
      No: 'NEIN',
      ImageErrorDialogHeader: 'Fehler beim Auswählen des Bildes',
      ImageErrorDialogContent: 'Wählen Sie nur ein Bild zum Öffnen aus.',
      Straighten: 'Termin',
      NoOutline: 'Keine Umrisse',
      Clone: 'Klonen',
      Delete: 'ausrotten',
    },
    mention: {
      noRecordsTemplate: 'Keine Aufzeichnungen gefunden',
    },
    inplaceeditor: {
      save: 'Speichern',
      cancel: 'Stornieren',
      loadingText: 'Wird geladen...',
      editIcon: 'Zum Bearbeiten anklicken',
      editAreaClick: 'Zum Bearbeiten anklicken',
      editAreaDoubleClick: 'Doppelklick zum bearbeiten',
    },
    PdfViewer: {
      PdfViewer: 'PDF-Viewer',
      Cancel: 'Stornieren',
      'Download file': 'Download-Datei',
      Download: 'Herunterladen',
      'Enter Password': 'Dieses Dokument ist passwortgeschützt. ',
      'File Corrupted': 'Datei beschädigt',
      'File Corrupted Content':
        'Die Datei ist beschädigt und kann nicht geöffnet werden.',
      'Fit Page': 'Seite anpassen',
      'Fit Width': 'Breite anpassen',
      Automatic: 'Automatisch',
      'Go To First Page': 'Erste Seite anzeigen',
      'Invalid Password': 'Falsches Passwort. ',
      'Next Page': 'Nächste Seite anzeigen',
      OK: 'OK',
      Open: 'Datei öffnen',
      'Page Number': 'Aktuelle Seitenzahl',
      'Previous Page': 'Vorherige Seite anzeigen',
      'Go To Last Page': 'Letzte Seite anzeigen',
      Zoom: 'Zoomen',
      'Zoom In': 'Hineinzoomen',
      'Zoom Out': 'Rauszoomen',
      'Page Thumbnails': 'Miniaturansichten der Seiten',
      Bookmarks: 'Lesezeichen',
      Print: 'Druckdatei',
      'Organize Pages': 'Seiten organisieren',
      'Insert Right': 'Recht einfügen',
      'Insert Left': 'Links einfügen',
      Total: 'Gesamt',
      Pages: 'Seiten',
      'Rotate Right': 'Drehe nach rechts',
      'Rotate Left': 'Nach links drehen',
      'Delete Page': 'Seite löschen',
      'Delete Pages': 'Seiten löschen',
      Save: 'Speichern',
      'Save As': 'Speichern als',
      'Select All': 'Wählen Sie Alle',
      'Password Protected': 'Passwort erforderlich',
      Copy: 'Kopieren',
      'Text Selection': 'Textauswahltool',
      Panning: 'Schwenkmodus',
      'Text Search': 'Text finden',
      'Find in document': 'Im Dokument suchen',
      'Match case': 'Streichholzetui',
      Apply: 'Anwenden',
      GoToPage: 'Gehen Sie zur Seite',
      'No matches': 'Der Viewer hat die Suche im Dokument abgeschlossen. ',
      'No Text Found': 'Kein Text gefunden',
      Undo: 'Rückgängig machen',
      Redo: 'Wiederholen',
      Annotation: 'Anmerkungen hinzufügen oder bearbeiten',
      FormDesigner: 'Fügen Sie Formularfelder hinzu und bearbeiten Sie sie',
      Highlight: 'Text hervorheben',
      Underline: 'Text unterstreichen',
      Strikethrough: 'Durchgestrichener Text',
      Delete: 'Anmerkung löschen',
      Opacity: 'Opazität',
      'Color edit': 'Farbe ändern',
      'Opacity edit': 'Deckkraft ändern',
      'Highlight context': 'Markieren',
      'Underline context': 'Unterstreichen',
      'Strikethrough context': 'Durchschlagen',
      'Server error': 'Der Webdienst hört nicht zu. ',
      'Client error':
        'Der Client-Seiten-Fehler wird gefunden. Bitte überprüfen Sie die benutzerdefinierten Header in der Eigenschaft von AjaxRequestSets und Web -Aktion in der Eigenschaftsassettierungseigenschaft.',
      'Open text': 'Offen',
      'First text': 'Erste Seite',
      'Previous text': 'Vorherige Seite',
      'Next text': 'Nächste Seite',
      'Last text': 'Letzte Seite',
      'Zoom in text': 'Hineinzoomen',
      'Zoom out text': 'Rauszoomen',
      'Selection text': 'Auswahl',
      'Pan text': 'Pfanne',
      'Print text': 'Drucken',
      'Search text': 'Suchen',
      'Annotation Edit text': 'Anmerkung bearbeiten',
      'FormDesigner Edit text':
        'Fügen Sie Formularfelder hinzu und bearbeiten Sie sie',
      'Line Thickness': 'Dicke der Linie',
      'Line Properties': 'Linieneigenschaften',
      'Start Arrow': 'Pfeil starten',
      'End Arrow': 'Endpfeil',
      'Line Style': 'Linienstil',
      'Fill Color': 'Füllfarbe',
      'Line Color': 'Linienfarbe',
      None: 'Keiner',
      'Open Arrow': 'Offen',
      'Closed Arrow': 'Geschlossen',
      'Round Arrow': 'Runden',
      'Square Arrow': 'Quadrat',
      'Diamond Arrow': 'Diamant',
      Butt: 'Hintern',
      Cut: 'Schneiden',
      Paste: 'Paste',
      'Delete Context': 'Löschen',
      Properties: 'Eigenschaften',
      'Add Stamp': 'Stempel hinzufügen',
      'Add Shapes': 'Formen hinzufügen',
      'Stroke edit': 'Ändern Sie die Strichfarbe',
      'Change thickness': 'Randstärke ändern',
      'Add line': 'Zeile hinzufügen',
      'Add arrow': 'Pfeil hinzufügen',
      'Add rectangle': 'Rechteck hinzufügen',
      'Add circle': 'Kreis hinzufügen',
      'Add polygon': 'Polygon hinzufügen',
      'Add Comments': 'Füge Kommentare hinzu',
      Comments: 'Kommentare',
      SubmitForm: 'Formular abschicken',
      'No Comments Yet': 'Noch keine Kommentare',
      Accepted: 'Akzeptiert',
      Completed: 'Vollendet',
      Cancelled: 'Abgesagt',
      Rejected: 'Abgelehnt',
      'Leader Length': 'Führungslänge',
      'Scale Ratio': 'Skalenverhältnis',
      Calibrate: 'Kalibrieren',
      'Calibrate Distance': 'Distanz kalibrieren',
      'Calibrate Perimeter': 'Umfang kalibrieren',
      'Calibrate Area': 'Bereich kalibrieren',
      'Calibrate Radius': 'Radius kalibrieren',
      'Calibrate Volume': 'Lautstärke kalibrieren',
      Depth: 'Tiefe',
      Closed: 'Geschlossen',
      Round: 'Runden',
      Square: 'Quadrat',
      Diamond: 'Diamant',
      Edit: 'Bearbeiten',
      Comment: 'Kommentar',
      'Comment Panel': 'Kommentarpanel',
      'Set Status': 'Status festlegen',
      Post: 'Post',
      Page: 'Seite',
      'Add a comment': 'Einen Kommentar hinzufügen',
      'Add a reply': 'Fügen Sie eine Antwort hinzu',
      'Import Annotations': 'Importieren Sie Annotationen aus der JSON -Datei',
      'Export Annotations': 'Annotation an die JSON -Datei exportieren',
      'Export XFDF': 'Annotation in XFDF -Datei exportieren',
      'Import XFDF': 'Importieren Sie Annotationen aus der XFDF -Datei',
      Add: 'Hinzufügen',
      Clear: 'Klar',
      Bold: 'Deutlich',
      Italic: 'Kursiv',
      Strikethroughs: 'Durchgestrichen',
      Underlines: 'Unterstreichen',
      Superscript: 'Hochgestellt',
      Subscript: 'Index',
      'Align left': 'Linksbündig',
      'Align right': 'Rechts ausrichten',
      Center: 'Center',
      Justify: 'Rechtfertigen',
      'Font color': 'Schriftfarbe',
      'Text Align': 'Textausrichtung',
      'Text Properties': 'Schriftstil',
      SignatureFieldDialogHeaderText: 'Signatur hinzufügen',
      HandwrittenSignatureDialogHeaderText: 'Signatur hinzufügen',
      InitialFieldDialogHeaderText: 'Initial hinzufügen',
      HandwrittenInitialDialogHeaderText: 'Initial hinzufügen',
      'Draw Ink': 'Tinte zeichnen',
      Create: 'Erstellen',
      'Font family': 'Schriftfamilie',
      'Font size': 'Schriftgröße',
      'Free Text': 'Freier Text',
      'Import Failed':
        'Ungültiger JSON -Dateityp oder Dateiname; Bitte wählen Sie eine gültige JSON -Datei aus',
      'Import PDF Failed':
        'Ungültige PDF -Dateityp oder PDF -Datei nicht gefunden. Bitte wählen Sie eine gültige PDF -Datei aus',
      'File not found':
        'Die importierte JSON -Datei wird nicht am gewünschten Ort gefunden',
      'Export Failed':
        'Exportanmerkungen sind gescheitert. Bitte stellen Sie sicher, dass Anmerkungen ordnungsgemäß hinzugefügt werden',
      of: 'von ',
      Dynamic: 'Dynamisch',
      'Standard Business': 'Standardgeschäft',
      'Sign Here': 'Hier unterschreiben',
      'Custom Stamp': 'Benutzerdefinierte Stempel',
      'Enter Signature as Name': 'Gib deinen Namen ein',
      'Draw-hand Signature': 'ZIEHEN',
      'Type Signature': 'TYP',
      'Upload Signature': 'HOCHLADEN',
      'Browse Signature Image': 'DURCHSUCHE',
      'Save Signature': 'Signatur speichern',
      'Save Initial': 'Initial speichern',
      Textbox: 'Textfeld',
      Password: 'Passwort',
      'Check Box': 'Kontrollkästchen',
      'Radio Button': 'Radio knopf',
      Dropdown: 'Runterfallen',
      'List Box': 'Listenfeld',
      Signature: 'Unterschrift',
      'Delete FormField': 'Formular löschen',
      'Textbox Properties': 'Textbox -Eigenschaften',
      Name: 'Name',
      Tooltip: 'Tooltip',
      Value: 'Wert',
      'Form Field Visibility': 'Sichtbarkeit von Feldwäschen',
      'Read Only': 'Schreibgeschützt',
      Required: 'Erforderlich',
      Checked: 'Geprüft',
      'Show Printing': 'Drucken zeigen',
      Formatting: 'Format',
      Fill: 'Füllen',
      Border: 'Grenze',
      'Border Color': 'Randfarbe',
      Thickness: 'Dicke',
      'Max Length': 'Maximale Länge',
      'List Item': 'Artikelname',
      'Export Value': 'Gegenstandswert',
      'Dropdown Item List': 'Dropdown -Elementliste',
      'List Box Item List': 'Listenfeldelementliste',
      General: 'ALLGEMEIN',
      Appearance: 'AUSSEHEN',
      Options: 'OPTIONEN',
      'Delete Item': 'Löschen',
      Up: 'Hoch',
      Down: 'Runter',
      Multiline: 'Multiline',
      Revised: 'Überarbeitet',
      Reviewed: 'Bewertet',
      Received: 'Erhalten',
      Confidential: 'Vertraulich',
      Approved: 'Genehmigt',
      'Not Approved': 'Nicht bestätigt',
      Witness: 'Zeuge',
      'Initial Here': 'Anfang hier',
      Draft: 'Entwurf',
      Final: 'Finale',
      'For Public Release': 'Für die Veröffentlichung',
      'Not For Public Release': 'Nicht für die Veröffentlichung',
      'For Comment': 'Für Kommentar',
      Void: 'Leere',
      'Preliminary Results': 'Vorläufige Ergebnisse',
      'Information Only': 'Nur Informationen',
      in: 'In',
      m: 'M',
      ft_in: 'ft_in',
      ft: 'ft',
      p: 'P',
      cm: 'cm',
      mm: 'mm',
      pt: 'pt',
      cu: 'cu',
      sq: 'Quadrat',
      Initial: 'Anfänglich',
    },
    stepper: {
      optional: 'Optional',
    },
  },
};
