import { Directive } from '@angular/core';

@Directive({
  selector: '[tremazeDynamicListItemTitle]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'dynamic-list__Item__Title',
  },
})
export class DynamicListItemTitleDirective {}
