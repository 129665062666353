import { ApprovalService } from './approval-service';
import { TremazeDate } from '@tremaze/shared/util-date';
import { Deserializable, staticImplements } from '@tremaze/shared/util-decorators';

/**
 * PartialBudget class
 *
 * @description
 * Represents a partial budget entity
 *
 */
@staticImplements<Deserializable<PartialBudget>>()
export class PartialBudget {
  constructor(
    readonly approvalService: ApprovalService,
    readonly approvedFrom: TremazeDate,
    readonly approvedUntil: TremazeDate,
    readonly totalBudget: number,
    readonly netTotalBudget?: number,
    readonly weeklyHours?: number,
    readonly netWeeklyHours?: number,
    readonly id?: string,
    readonly remainingPlannedBudget = 0,
    readonly remainingActualBudget = 0,
  ) {}

  static deserialize(input: any): PartialBudget {
    return new PartialBudget(
      input.approvalService,
      TremazeDate.deserialize(input.approvedFrom),
      TremazeDate.deserialize(input.approvedUntil),
      input.totalBudget ?? 0,
      input.netTotalBudget ?? undefined,
      input.weeklyHours,
      input.netWeeklyHours,
      input.id ?? undefined,
      input.remainingPlannedBudget ?? undefined,
      input.remainingActualBudget ?? undefined,
    );
  }

  copyWithNewId(id: string): PartialBudget {
    return new PartialBudget(
      this.approvalService,
      this.approvedFrom,
      this.approvedUntil,
      this.totalBudget,
      this.netTotalBudget,
      this.weeklyHours,
      this.netWeeklyHours,
      id,
      this.remainingPlannedBudget,
      this.remainingActualBudget,
    );
  }
}
