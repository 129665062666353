<div
  style="
    min-height: 400px;
    min-width: 400px;
    display: flex;
    position: relative;
    max-height: 80vh;
    max-width: 90vw;
  "
  (mousewheel)="onMousewheel($event)"
>
  <div class="center-children" *ngIf="imageLoadError">
    <tremaze-hint [colorPalette]="'warn'" mode="ERROR"
      >Die Datei ist fehlerhaft und konnte nicht geladen werden
    </tremaze-hint>
  </div>

  <image-cropper
    *ngIf="!imageLoadError"
    style="flex: 1"
    (imageCropped)="onImageCropped($event)"
    (imageLoaded)="onLoad()"
    (loadImageFailed)="onLoadFailed()"
    [imageFile]="blob"
    [aspectRatio]="aspectRatio"
    [format]="format"
    [transform]="transform"
    [maintainAspectRatio]="true"
    [autoCrop]="true"
  ></image-cropper>
  <span class="scale-display" [class.visible]="!scaleDisplayHidden"
    >{{ currentScalePercent }}%</span
  >
  <div
    *ngIf="!imageLoaded && !imageLoadError"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.1);
    "
  >
    <mat-spinner color="accent" diameter="25"></mat-spinner>
  </div>
</div>
<div
  align="end"
  style="
    padding-top: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  "
>
  <ng-container *ngIf="imageLoaded">
    <button
      mat-icon-button
      color="accent"
      matTooltip="Um 90° gegen den Uhrzeigersinn drehen"
      (click)="onClickRotateCounterclockwise()"
    >
      <span class="lnr lnr-undo2"></span>
    </button>
    <input
      style="max-width: 3em; text-align: center"
      type="number"
      [value]="currentRotation"
      matTooltip="Rotation: {{ currentRotation }}°"
      (change)="onRationInputChange(rotationInput.value)"
      (keyup)="onRationInputChange(rotationInput.value)"
      #rotationInput
      max="360"
      min="-360"
    />
    <button
      mat-icon-button
      color="accent"
      matTooltip="Um 90° mit dem Uhrzeigersinn drehen"
      (click)="onClickRotateClockwise()"
    >
      <span class="lnr lnr-redo2"></span>
    </button>
    <button
      mat-icon-button
      color="accent"
      matTooltip="An der X-Achse spiegeln"
      (click)="onClickFlipXAxis()"
    >
      <span class="lnr lnr-flip-vertical2"></span>
    </button>
    <button
      mat-icon-button
      color="accent"
      matTooltip="An der Y-Achse spiegeln"
      (click)="onClickFlipYAxis()"
    >
      <span class="lnr lnr-flip-horizontal2"></span>
    </button>
    <button
      mat-icon-button
      color="accent"
      matTooltip="Zurücksetzen"
      (click)="onClickReset()"
    >
      <span class="lnr lnr-return2"></span>
    </button>
  </ng-container>

  <button mat-button mat-dialog-close style="margin-right: 10px">
    Abbrechen
  </button>
  <button
    *ngIf="imageLoaded"
    mat-raised-button
    (click)="onClickSubmit()"
    [disabled]="!croppedImageBase64"
    color="accent"
  >
    Zuschneiden
  </button>
</div>
