import { Meta } from '@tremaze/shared/models';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

export type ParticipationCriteriaTypes = 'CHECKBOX' | 'TEXT_QUESTION';

@staticImplements<Deserializable<ParticipationCriteria>>()
export class ParticipationCriteria {
  constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public criteria?: boolean,
    public description?: string,
    public criteriaType: ParticipationCriteriaTypes = 'CHECKBOX',
  ) {}

  static deserialize(data: any): null | ParticipationCriteria {
    return !data
      ? null
      : new ParticipationCriteria(
          data.id,
          Meta.deserialize(data.meta),
          data.criteria,
          data.description,
          data.criteriaType,
        );
  }
}

@staticImplements<Deserializable<ParticipationCriteriaReference>>()
export class ParticipationCriteriaReference {
  constructor(readonly used?: boolean) {}

  static deserialize(data: any): null | ParticipationCriteriaReference {
    return !data ? null : new ParticipationCriteriaReference(data.used);
  }
}

@staticImplements<Deserializable<SelectedParticipationCriteria>>()
export class SelectedParticipationCriteria {
  constructor(
    readonly id?: string,
    readonly checked?: boolean,
    readonly textboxValue?: string,
    readonly criteria?: ParticipationCriteria,
  ) {}

  static deserialize(data: any): null | SelectedParticipationCriteria {
    return !data
      ? null
      : new SelectedParticipationCriteria(
          data.id,
          data.checked,
          data.textboxValue,
          data.eventCriteria,
        );
  }
}
