import { Component, Input, ViewEncapsulation } from '@angular/core';
// noinspection ES6PreferShortImport
import { EditComponentService } from './edit-component.service';
import { Category } from '@tremaze/shared/feature/category/types';

@Component({
  selector: 'tremaze-edit-dialog',
  template: `
    <span mat-dialog-title
      >{{ title }} {{ isEdit ? 'bearbeiten' : 'anlegen' }}</span
    >
    <div mat-dialog-content>
      <ng-content></ng-content>
    </div>
    <div mat-dialog-actions>
      <ng-content select="[actions]"></ng-content>
      <span class="spacer"></span>
      <button mat-button [mat-dialog-close]="null">Abbrechen</button>
      <button
        mat-raised-button
        color="accent"
        (click)="onClickSubmit()"
        [disabled]="service.loading"
      >
        {{ isEdit ? 'Speichern' : 'Anlegen' }}
      </button>
    </div>
  `,
  styles: [``],
  encapsulation: ViewEncapsulation.Emulated,
})
export class EditDialogComponent {
  @Input() title: string;

  constructor(readonly service: EditComponentService<Category>) {}

  get isEdit() {
    return this.service.isEdit;
  }

  onClickSubmit() {
    this.service.trySubmit();
  }
}
