import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { Linearicon } from '@tremaze/shared/ui';
import { IconComponent } from '@tremaze/shared/ui/icon';

@Component({
  selector: 'tremaze-folder-view-item-icon',
  standalone: true,
  imports: [CommonModule, IconComponent],
  template: ` <tremaze-icon [icon]="icon"></tremaze-icon> `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-item-icon',
  },
})
export class FolderViewItemIconComponent {
  @Input() item?: FileStorage | DirStorage;

  get isFile(): boolean {
    return this.item instanceof FileStorage;
  }

  get isDir(): boolean {
    return this.item instanceof DirStorage;
  }

  get icon(): Linearicon {
    if (this.isFile) {
      return 'lnr-file-empty';
    }

    if (this.isDir) {
      if ((this.item as DirStorage).isSystemDir) {
        return 'lnr-folder-star';
      }
      return 'lnr-folder';
    }

    return 'lnr-question-circle';
  }
}
