import { CustomFormField } from '../form-field';
import { Meta } from '@tremaze/shared/models';
import { CustomMultiSelectFormFieldValue } from '../values/multiselect-value';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';

export type MultiSelectType = 'MULTISELECT' | 'RADIOBUTTON' | 'DROPDOWN';

export interface CustomMultiSelectFormFieldItem<T> {
  readonly id: string;
  readonly meta?: Meta;
  readonly value: T;
  readonly label: string;
  readonly sort: number;
}

@staticImplements<Deserializable<CustomMultiSelectFormField<unknown>>>()
export class CustomMultiSelectFormField<T>
  implements CustomFormField<CustomMultiSelectFormFieldItem<T>>
{
  readonly fieldType = 'MULTISELECT';

  constructor(
    readonly id: string,
    readonly meta: Meta,
    readonly name: string,
    readonly label: string,
    readonly required: boolean,
    readonly sort: number,
    readonly json_id: string,
    readonly type: MultiSelectType,
    readonly items: CustomMultiSelectFormFieldItem<T>[],
    readonly minSelectedItems?: number,
    readonly maxSelectedItems?: number,
    readonly filledOutValues?: CustomMultiSelectFormFieldValue<T>[],
  ) {}

  static deserialize(data: any): CustomMultiSelectFormField<unknown> {
    return new CustomMultiSelectFormField<unknown>(
      data.id,
      Meta.deserialize(data),
      data.name?.trim(),
      data.label?.trim(),
      data.required ?? false,
      data.sort ?? 0,
      '' + data.json_id,
      data.type,
      (data.items as CustomMultiSelectFormFieldItem<any>[]).sort(
        (a, b) => a.sort - b.sort,
      ),
      data.minSelectedItems == 0 ? null : data.minSelectedItems,
      data.maxSelectedItems == 0 ? null : data.maxSelectedItems,
      data.filledOutValues?.map(CustomMultiSelectFormFieldValue.deserialize),
    );
  }

  tryFindItemsByString(value: string): CustomMultiSelectFormFieldItem<T>[] {
    const foundItem = this.items.find(
      (item) =>
        (item.value as string)?.trim()?.toLowerCase() ===
        value.trim().toLowerCase(),
    );
    if (!foundItem) {
      return [];
    }
    return [foundItem];
  }
}
