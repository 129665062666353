import { inject, Injectable } from '@angular/core';
import { AuthV2Service } from './auth-service';
import { TenantEntity } from '@tremaze/shared/feature/tenant';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  from,
  interval,
  Observable,
  of,
  ReplaySubject,
  skip,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import { AuthenticatedUser } from '../../types/src';
import {
  AuthConfig,
  OAuthErrorEvent,
  OAuthService,
  OAuthSuccessEvent,
} from 'angular-oauth2-oidc';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import {
  bindTo,
  filterNotNullOrUndefined,
  mapNotNullOrUndefined,
  negateBool,
} from '@tremaze/shared/util/rxjs';
import { map, tap } from 'rxjs/operators';
import { Tenant } from '@tremaze/shared/feature/tenant/types';
import { HttpClient } from '@angular/common/http';
import { AuthV2DataSource } from '@tremaze/shared/core/auth-v2/data-access';
import { TenantDataSource } from '@tremaze/shared/feature/tenant/data-access';
import { Router } from '@angular/router';

const TENANT_KEY = '_TENANT_';

@Injectable({ providedIn: 'root' })
export class AuthOidcService implements AuthV2Service {
  private readonly _configService = inject(AppConfigService);
  private readonly _service = inject(OAuthService);
  private readonly _http = inject(HttpClient);
  private readonly _storage = inject(Storage);
  private readonly _dataSource = inject(AuthV2DataSource);
  private readonly _tenantDataSource = inject(TenantDataSource);
  private readonly _router = inject(Router);

  get authCodeFlowConfig(): AuthConfig {
    return {
      issuer: this._configService.authIssuer,
      redirectUri: window.location.origin,
      clientId: this._configService.clientId,
      responseType: 'code',
      scope: 'openid profile offline_access',
      showDebugInformation: true,
      customQueryParams: {
        tenantId:
          this._configService.state == 'PROD'
            ? 'b9aae7e6-e951-4f2f-b876-f4d7580a8bd0'
            : '5819e5fe-d7b3-4d56-a340-594309a0537f',
        stage: this._configService.state.toLowerCase(),
      },
    };
  }

  private readonly _authenticated$ = new ReplaySubject<boolean>(1);

  get authenticated$(): Observable<boolean> {
    return this._authenticated$.asObservable();
  }

  get authenticatedStateChanged$(): Observable<boolean> {
    return this._authenticated$.asObservable();
  }

  get authenticated(): boolean {
    return this._service.hasValidAccessToken();
  }

  get notAuthenticated$(): Observable<boolean> {
    return of(!this.authenticated);
  }

  get hasActiveTenant$(): Observable<boolean> {
    return this.activeTenant$.pipe(mapNotNullOrUndefined());
  }

  private _activeTenant$ = new BehaviorSubject<TenantEntity | null>(null);

  get activeTenant$(): Observable<TenantEntity | null> {
    return this._activeTenant$.asObservable();
  }

  get activeTenant(): TenantEntity | null {
    return this._activeTenant$.value;
  }

  private _authenticatedUserTenants$ = new ReplaySubject<TenantEntity[]>(1);

  get authenticatedUserTenants$(): Observable<TenantEntity[]> {
    return this._authenticatedUserTenants$.asObservable();
  }

  get hasAuthenticatedUser$(): Observable<boolean> {
    return this.authenticatedUser$.pipe(mapNotNullOrUndefined());
  }

  get hasNoAuthenticatedUser$(): Observable<boolean> {
    return this.hasAuthenticatedUser$.pipe(negateBool());
  }

  private _reloadAuthenticatedUser$ = new Subject<void>();
  private _authenticatedUser$ = new ReplaySubject<AuthenticatedUser>(1);

  get authenticatedUser$(): Observable<AuthenticatedUser> {
    return this._authenticatedUser$
      .asObservable()
      .pipe(filterNotNullOrUndefined());
  }

  get accessToken(): string {
    return this._service.getAccessToken();
  }

  private _accessToken$ = new ReplaySubject<string>(1);

  get accessToken$(): Observable<string> {
    return this._accessToken$.asObservable();
  }

  login(): void {
    this._service.initLoginFlow();
  }

  init(): void {
    this._service.configure(this.authCodeFlowConfig);
    this._service.setupAutomaticSilentRefresh();
    this._service.loadDiscoveryDocumentAndLogin().then((value) => {
      this._authenticated$.next(value);
    });

    if (this._storage.getItem(TENANT_KEY)) {
      this._activeTenant$.next({
        id: this._storage.getItem(TENANT_KEY),
        tenantEventFiles: [],
        fakeAccountsAreDefault: false,
      });
      this._loadTenantAndSelect(this._storage.getItem(TENANT_KEY));
    }

    combineLatest([
      this._authenticatedUserTenants$,
      this.activeTenant$.pipe(distinctUntilChanged((a, b) => a?.id === b?.id)),
    ])
      .pipe(
        tap(([tenants, tenant]) => {
          if (tenant) {
            if (!tenants.find((t) => t.id === tenant.id)) {
              this._storage.removeItem(TENANT_KEY);
              this._activeTenant$.next(null);
            }
          }
        }),
      )
      .subscribe();

    this._service.events
      .pipe(
        skip(1),
        tap((event) => {
          if (event instanceof OAuthErrorEvent) {
            this.logout();
          }
        }),
        map((event) => {
          if (event instanceof OAuthSuccessEvent) {
            return true;
          } else if (event instanceof OAuthErrorEvent) {
            return false;
          }
          return null;
        }),
        filterNotNullOrUndefined(),
        distinctUntilChanged(),
        bindTo(this._authenticated$),
      )
      .subscribe();

    this._service.events
      .pipe(
        map(() => this._service.getAccessToken()),
        distinctUntilChanged(),
        bindTo(this._accessToken$),
      )
      .subscribe();

    this._authenticated$
      .pipe(
        distinctUntilChanged(),
        switchMap((authenticated) =>
          authenticated ? this._obtainAuthenticatedUserTenants() : of([]),
        ),
        tap(tenants => {
          if(tenants.length === 1 && !this._activeTenant$.value) {
            this._activeTenant$.next(tenants[0]);
            this._storage.setItem(TENANT_KEY, tenants[0].id);
            this._router.navigate(['/']);
            this._loadTenantAndSelect(this._storage.getItem(TENANT_KEY));
          }
        }),
        bindTo(this._authenticatedUserTenants$),
      )
      .subscribe();

    combineLatest([
      this.authenticated$,
      this.activeTenant$.pipe(
        map((tenant) => tenant?.id),
        distinctUntilChanged(),
      ),
      interval(1000 * 30).pipe(
        tap(() => console.log('reloading user because of interval')),
        startWith(0),
      ),
      this._reloadAuthenticatedUser$.pipe(
        tap(() => console.log('reloading user because of reload event')),
        startWith(null),
      ),
    ])
      .pipe(
        switchMap(([authenticated, tenantId]) =>
          authenticated && tenantId
            ? this._dataSource.getAuthenticatedUser()
            : of(null),
        ),
        bindTo(this._authenticatedUser$),
      )
      .subscribe();
  }

  logout(): void {
    this._service.revokeTokenAndLogout();
  }

  selectTenant(tenant: TenantEntity): void {
    this._storage.setItem(TENANT_KEY, tenant.id);
    this._loadTenantAndSelect(tenant.id, true);
  }

  reloadAuthenticatedUser(): void {
    this._reloadAuthenticatedUser$.next();
  }

  _loadTenantAndSelect(tenantId: string, navigate = false) {
    this._tenantDataSource.getTenant(tenantId).subscribe((tenant) => {
      this._activeTenant$.next(tenant);
      if (navigate) {
        this._router.navigate(['/']);
      }
    });
  }

  reloadActiveTenant(): void {
    const tenantId = this._storage.getItem(TENANT_KEY);
    if (tenantId) {
      this._loadTenantAndSelect(tenantId);
    }
  }

  refreshToken(): void {
    this._service.refreshToken();
  }

  getRefreshedToken(): Observable<string | null> {
    return from(
      this._service.refreshToken().then((token) => {
        return token.access_token;
      }),
    );
  }

  private _obtainAuthenticatedUserTenants(): Observable<Tenant[]> {
    return this._http
      .get<unknown[]>('/users/me/tenants', {
        params: { skipTenantId: 'true' },
      })
      .pipe(
        map((r) =>
          r
            .map(Tenant.deserialize)
            .sort((a, b) => a.name.localeCompare(b.name)),
        ),
      );
  }
}
