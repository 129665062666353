import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleFileDisplayComponent } from './single-file-display.component';
import { SharedFileStorageUiFilePreviewOverlayModule } from '@tremaze/shared/feature/file-storage/ui/file-preview-overlay';
import { SharedFileStorageServicesModule } from '@tremaze/shared/feature/file-storage/services';

@NgModule({
  imports: [CommonModule, SharedFileStorageUiFilePreviewOverlayModule],
  declarations: [SingleFileDisplayComponent],
  exports: [SingleFileDisplayComponent],
})
export class SharedFileStorageUiSingleFileDisplayModule {}
