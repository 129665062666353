import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticatedUser } from '@tremaze/shared/core/auth-v2/types';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Address, ContactInfo } from '@tremaze/shared/models';
import { UserInstitution } from '@tremaze/shared/feature/user/feature/allocation/types';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { TremazeDate } from '@tremaze/shared/util-date';
import { AuthV2DataSource } from './shared-core-auth-v2-data-access';
import { Gender } from '@tremaze/shared/feature/gender/types';

@Injectable({ providedIn: 'root' })
export class RemoteOIDCAuthV2DataSource implements AuthV2DataSource {
  constructor(private http: HttpClient) {}

  getAuthenticatedUser(): Observable<AuthenticatedUser> {
    return (
      this.http
        // TODO: Define the correct type
        // eslint-disable-next-line
        .get<any>('/users/self', {})
        .pipe(
          map(
            (u) =>
              new AuthenticatedUser(
                u.id,
                null,
                u.username,
                u.email,
                true,
                false,
                u.userRoles,
                u.userPrivileges,
                u.firstName,
                u.lastName,
                new ContactInfo(u.email, u.mobile, u.phone),
                Gender.deserialize(u.gender),
                u.userInstitutions?.map(UserInstitution.deserialize) ?? [],
                FileStorage.deserialize(u.avatar),
                Address.deserialize(u.address),
                TremazeDate.deserialize(u.birth),
                u.userTypes?.map((ut) => ut.userType) ?? [],
              ),
          ),
        )
    );
  }
}
