import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'tremaze-drag-zone-preview',
  standalone: true,
  imports: [],
  template: `
    <ng-template>
      <div>
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
  styles: `
    :host {
      display: none;
      pointer-events: none;
    }

    div {
      pointer-events: none;
    }
  `,
})
export class DragZonePreviewComponent {
  @ViewChild(TemplateRef, { static: true }) templateRef?: TemplateRef<unknown>;
}
