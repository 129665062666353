<h2 mat-dialog-title>Dokument blockiert</h2>
<div mat-dialog-content>
  <p>Dieses Dokument wird gerade von einer anderen Person bearbeitet und ist
    daher blockiert. Bitte warte, bis es wieder verfügbar ist oder komme
    später wieder.</p>
</div>
<div align='end' matDialogActions>
  <button color='accent' mat-raised-button matDialogClose='WAIT'>Abwarten</button>
  <button mat-button matDialogClose='ABORT'>Abbrechen</button>
</div>
