import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { SortedFilteredTableConfig } from '@tremaze/shared/sorted-filtered-paginated-table/types';
import { ParticipationCriteria } from '@tremaze/shared/feature/participation-criteria/types';
import {
  ParticipationCriteriaDataSource,
  ParticipationCriteriaDataSourceMethodsPaginatedOptions,
} from '@tremaze/shared/feature/participation-criteria/data-access';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SortedFilteredTableDataManipulationService } from '@tremaze/shared/sorted-filtered-paginated-table/services';
import { ParticipationCriteriaEditService } from '../../../edit/src/lib/participation-criteria-edit.service';

@Component({
  selector: 'tremaze-participation-criteria-list',
  templateUrl: './participation-criteria-list.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: SortedFilteredTableDataManipulationService,
      useExisting: ParticipationCriteriaEditService,
    },
  ],
})
export class ParticipationCriteriaListComponent implements OnInit {
  config: SortedFilteredTableConfig<ParticipationCriteria> = {
    displayedColumns: [
      {
        name: 'description',
        displayName: 'Beschreibung',
      },
      {
        name: 'criteria',
        displayName: 'Ist Verpflichtend',
      },
      {
        name: 'criteriaType',
        displayName: 'Typ',
      },
    ],
    dataSource: this.dataSource,
  };
  @Output()
  selectionChange = new EventEmitter<ParticipationCriteria[]>();

  constructor(
    private dataSource: ParticipationCriteriaDataSource,
    private editService: ParticipationCriteriaEditService,
  ) {}

  private _mandatory: boolean;

  @Input()
  get mandatory(): boolean {
    return this._mandatory;
  }

  set mandatory(value: boolean) {
    this._mandatory = coerceBooleanProperty(value);
  }

  private _enableSelection: boolean;

  @Input('enable-selection')
  get enableSelection(): boolean {
    return this._enableSelection;
  }

  set enableSelection(value: boolean) {
    this._enableSelection = coerceBooleanProperty(value);
  }

  private _initialSelectionValue: ParticipationCriteria[];

  get initialSelectionValue(): ParticipationCriteria[] {
    return this._initialSelectionValue;
  }

  @Input()
  set initialSelectionValue(value: ParticipationCriteria[]) {
    this._initialSelectionValue = value ?? [];
  }

  private get paginationRequestOptions(): ParticipationCriteriaDataSourceMethodsPaginatedOptions {
    return { mandatory: this.mandatory };
  }

  ngOnInit(): void {
    if (this.mandatory) {
      this.editService.mode = 'ONLY_MANDATORY';
    } else if (this.mandatory === false) {
      this.editService.mode = 'ONLY_OPTIONAL';
    } else {
      this.editService.mode = 'DEFAULT';
    }
    this.config.paginationRequestOptions = this.paginationRequestOptions;
  }
}
