import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ExpandableFabOverlayComponent } from './expandable-fab-overlay/expandable-fab-overlay.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { ExpandableFabDirection } from './types';
import { merge } from 'rxjs';

@Directive({
  selector: '[tremazeExpandableFabTriggerFor]',
  standalone: true,
})
export class ExpandableFabTriggerForDirective {
  private readonly _overlay = inject(Overlay);
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _elementRef = inject(ElementRef);

  @Input() direction: ExpandableFabDirection = 'topLeft';
  @Input({ required: true })
  tremazeExpandableFabTriggerFor?: ExpandableFabOverlayComponent;

  private _overlayRef?: OverlayRef;

  private get _overlayPosition(): ConnectedPosition {
    return {
      originX: 'center',
      originY: 'center',
      overlayX: 'center',
      overlayY: 'center',
    };
  }

  @HostListener('click')
  toggle() {
    if (this._overlayRef) {
      this._overlayRef.detach();
      this._overlayRef = undefined;
    } else {
      this.showOverlay();
    }
  }

  showOverlay() {
    const templateRef = this.tremazeExpandableFabTriggerFor?.templateRef;

    if (!templateRef) {
      return;
    }

    this.tremazeExpandableFabTriggerFor!.direction = this.direction;

    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._elementRef)
        .withPositions([this._overlayPosition]),
    });
    const portal = new TemplatePortal(templateRef, this._viewContainerRef);

    this._overlayRef.attach(portal);

    this.tremazeExpandableFabTriggerFor!.show();

    merge(
      this._overlayRef.backdropClick(),
      this.tremazeExpandableFabTriggerFor!.requestClose,
    ).subscribe(() => {
      this._overlayRef?.detach();
      this._overlayRef = undefined;
      this.tremazeExpandableFabTriggerFor?.hide();
    });
  }
}
