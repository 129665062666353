import { UserType } from '@tremaze/shared/feature/user/types';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Meta } from '@tremaze/shared/models';
import { TremazeDate } from '@tremaze/shared/util-date';

@staticImplements<Deserializable<TermsOfUseAcceptance>>()
export class TermsOfUseAcceptance {
  constructor(
    readonly hasAcceptedLatestTermsOfUse: boolean,
    readonly lastAcceptedTermsOfUseDate?: TremazeDate,
    readonly lastAcceptedAtDate?: TremazeDate,
  ) {}

  static deserialize(data: any): TermsOfUseAcceptance | null {
    return !data
      ? null
      : new TermsOfUseAcceptance(
          data.currentAccepted,
          TremazeDate.deserialize(data.lastAcceptedTermsOfUseDate),
          TremazeDate.deserialize(data.lastAcceptedAtDate),
        );
  }
}

@staticImplements<Deserializable<TermsOfUse>>()
export class TermsOfUse {
  constructor(
    public id: string,
    public text: string,
    public checkboxes: string[],
    public userType: UserType,
    public meta?: Meta,
  ) {}

  get isEmpty(): boolean {
    return !this.text?.length && this.checkboxes.length === 0;
  }

  static emptyFromUserType(userType: UserType): TermsOfUse {
    return new TermsOfUse('', '', [], userType);
  }

  static deserialize(data?: any): TermsOfUse | null {
    if (data == null) {
      return null;
    }
    return new TermsOfUse(
      data.id,
      data.text,
      data.checkboxes.map((c: any) => c.text),
      data.userType,
      Meta.deserialize(data),
    );
  }

  copyWith(
    data: Partial<{
      text: string | null;
      checkboxes: string[] | null;
      meta: Meta | null;
    }>,
  ): TermsOfUse {
    return new TermsOfUse(
      this.id,
      data.text ?? this.text,
      data.checkboxes ?? this.checkboxes,
      this.userType,
      data.meta ?? this.meta,
    );
  }
}
