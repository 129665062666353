import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DirStorage } from '@tremaze/shared/feature/file-storage/types';

@Component({
  selector: 'tremaze-folder-view-folder-contextmenu',
  standalone: true,
  imports: [CommonModule, IconComponent, MatMenuModule],
  templateUrl: './folder-view-folder-contextmenu.component.html',
  styleUrl: './folder-view-folder-contextmenu.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-folder-contextmenu',
  },
})
export class FolderViewFolderContextmenuComponent {
  @Input({ required: true }) folder?: DirStorage;
  @Input() canWrite?: boolean;

  @Output() readonly deleteFolder = new EventEmitter<void>();
  @Output() readonly openFolder = new EventEmitter<void>();
  @Output() readonly renameFolder = new EventEmitter<void>();
}
