import { PageGenerator } from '../helpers';
import { Division } from '@tremaze/shared/feature/division/types';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { InstitutionFixtures } from '../institution';

class DivisionFixturePageGenerator extends PageGenerator<Division> {
  override generateByIndex(index: number): Division {
    return DivisionFixtures.generateDivision(index);
  }
}

export abstract class DivisionFixtures {
  private static _divisionStubPageGenerator =
    new DivisionFixturePageGenerator();

  static generateDivisionPage = (options: DataSourceMethodsPaginatedOptions) =>
    DivisionFixtures._divisionStubPageGenerator.generatePage(options);

  static generateDivision = (id: number): Division =>
    new Division(id.toString(), `Division ${id}`, [
      InstitutionFixtures.generateInstitution(id),
    ]);

  static readonly division1: Division = DivisionFixtures.generateDivision(1);
  static readonly division2: Division = DivisionFixtures.generateDivision(2);
  static readonly division3: Division = DivisionFixtures.generateDivision(3);
  static readonly division4: Division = DivisionFixtures.generateDivision(4);
  static readonly division5: Division = DivisionFixtures.generateDivision(5);

  static readonly divisions = [
    DivisionFixtures.division1,
    DivisionFixtures.division2,
    DivisionFixtures.division3,
    DivisionFixtures.division4,
    DivisionFixtures.division5,
  ];
}
