import {
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  CustomFormsMultiSelectFieldType,
  CustomFormsMultiSelectItemFieldType,
} from '../../form-models';
import {
  multiSelectFieldTypeIcons,
  multiSelectFieldTypeOptions,
} from '../../field-type-options';
import { CustomFormEditComponentService } from '../../custom-form-edit-component.service/custom-form-edit-component.service';
import { fieldAnimation } from '../../animations';
import {
  combineLatest,
  map,
  Observable,
  of,
  OperatorFunction,
  pluck,
  startWith,
} from 'rxjs';
import { MultiSelectType } from '@tremaze/shared/feature/custom-forms/types';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

export function mapMultiSelectTypeToMaxSelectedItemsValue(): OperatorFunction<
  MultiSelectType,
  number | undefined
> {
  return (ob$: Observable<MultiSelectType>) =>
    ob$.pipe<number | undefined>(
      map((type) => {
        switch (type) {
          case 'MULTISELECT':
            return undefined;
          case 'RADIOBUTTON':
          case 'DROPDOWN':
            return 1;
        }
      }),
    );
}

@Component({
  selector: 'tremaze-custom-form-fields-multiselect-edit',
  templateUrl: './custom-form-fields-multiselect-edit.component.html',
  styles: [
    `
      :host {
        display: contents;
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      li > mat-form-field {
        flex: 1;
      }

      li > button {
        margin-left: 1rem;
      }

      ol {
        padding: 0;
      }

      li > mat-icon {
        margin-right: 0.5rem;
      }
    `,
  ],
  animations: [fieldAnimation],
})
export class CustomFormFieldsMultiselectEditComponent {
  @ViewChildren('optionInput')
  private readonly _optionElements!: QueryList<ElementRef<HTMLElement>>;

  get maxSelectedItemsControl(): AbstractControl | undefined {
    return this._formGroup.controls.maxSelectedItems;
  }

  get minSelectedItemsControl(): AbstractControl | undefined {
    return this._formGroup.controls.minSelectedItems;
  }

  get maxSelectedItemsErrorMsg(): string | null {
    if (this.maxSelectedItemsControl?.hasError('greaterThanOrEqual')) {
      return 'Max. ausgewählt muss größer oder gleich Min. ausgewählt sein.';
    } else if (this.maxSelectedItemsControl?.hasError('max')) {
      const type = this.formGroup.controls.type?.value ?? 'MULTISELECT';
      const isTypeThatCanOnlyHaveMaxOneSelected = type !== 'MULTISELECT';
      if (!isTypeThatCanOnlyHaveMaxOneSelected) {
        return 'Max. ausgewählt darf nicht größer als die Anzahl der Optionen sein.';
      } else {
        return 'Max. ausgewählt kann für diesen Auswahltyp nicht größer als 1 sein';
      }
    } else if (this.maxSelectedItemsControl?.hasError('min')) {
      return 'Max. ausgewählt darf nicht kleiner als 0 sein.';
    }
    return null;
  }

  get minSelectedItemsErrorMsg(): string | null {
    if (this.minSelectedItemsControl?.hasError('min')) {
      return 'Min. ausgewählt darf nicht kleiner als 0 sein.';
    } else if (this.minSelectedItemsControl?.hasError('max')) {
      const type = this.formGroup.controls.type?.value ?? 'MULTISELECT';
      const isTypeThatCanOnlyHaveMaxOneSelected = type !== 'MULTISELECT';
      if (!isTypeThatCanOnlyHaveMaxOneSelected) {
        return 'Min. ausgewählt darf nicht größer als die Anzahl der Optionen sein.';
      } else {
        return 'Min. ausgewählt kann für diesen Auswahltyp nicht größer als 1 sein';
      }
    }
    return null;
  }

  get maxSelectedItemsMaxValue$(): Observable<number> {
    return this._maxSelectedItemsMaxValue$;
  }

  get maxSelectedItemsMinValue$(): Observable<number> {
    return this._maxSelectedItemsMinValue$;
  }

  get minSelectedItemsMaxValue$(): Observable<number | undefined> {
    return this._minSelectedItemsMaxValue$;
  }

  get minSelectedItemsMinValue$(): Observable<number> {
    return this._minSelectedItemsMinValue$;
  }

  get disableRemoveOptionButton(): boolean {
    return this.options.length <= 1;
  }

  @Input()
  get formGroup(): FormGroup<CustomFormsMultiSelectFieldType<unknown>> {
    return this._formGroup;
  }

  set formGroup(value: FormGroup<CustomFormsMultiSelectFieldType<unknown>>) {
    this._formGroup = value;
    this._createMinMaxSelectedItemsMinMaxValueStreams(value);
  }

  readonly multiSelectFieldTypeOptions = multiSelectFieldTypeOptions;
  readonly multiSelectFieldTypeIcons = multiSelectFieldTypeIcons;
  private _formGroup!: FormGroup<CustomFormsMultiSelectFieldType<unknown>>;
  @Input() index!: number;

  private _minSelectedItemsMinValue$: Observable<number> = of(0);
  private _minSelectedItemsMaxValue$: Observable<number> = of(0);
  private _maxSelectedItemsMinValue$: Observable<number> = of(0);
  private _maxSelectedItemsMaxValue$: Observable<number> = of(0);

  private _createMinMaxSelectedItemsMinMaxValueStreams(
    formGroup: FormGroup<CustomFormsMultiSelectFieldType<unknown>>,
  ): void {
    this._minSelectedItemsMaxValue$ =
      this._createMinSelectedItemsMaxValueStream(formGroup);
    this._maxSelectedItemsMinValue$ =
      this._createMaxSelectedItemsMinValueStream(formGroup);
    this._maxSelectedItemsMaxValue$ =
      this._createMaxSelectedItemsMaxValueStream(formGroup);
  }

  private _createMinSelectedItemsMaxValueStream(
    formGroup: FormGroup<CustomFormsMultiSelectFieldType<unknown>>,
  ) {
    const typeControl = formGroup.controls.type;
    const optionsControl = formGroup.controls.items;
    if (typeControl && optionsControl) {
      return combineLatest([
        typeControl.valueChanges.pipe(
          startWith(typeControl.value),
          filterNotNullOrUndefined(),
          mapMultiSelectTypeToMaxSelectedItemsValue(),
        ),
        optionsControl.valueChanges.pipe(
          pluck('length'),
          startWith(optionsControl.value.length),
        ),
      ]).pipe(
        map(([type, length]) => (type ? Math.min(type, length) : length)),
      );
    }
    return of(0);
  }

  private _createMaxSelectedItemsMinValueStream(
    formGroup: FormGroup<CustomFormsMultiSelectFieldType<unknown>>,
  ) {
    const typeControl = formGroup.controls.type;
    if (typeControl) {
      return combineLatest([
        typeControl.valueChanges.pipe(
          startWith(typeControl.value),
          filterNotNullOrUndefined(),
          mapMultiSelectTypeToMaxSelectedItemsValue(),
        ),
        formGroup.controls.required.valueChanges.pipe(
          startWith(formGroup.controls.required.value),
          map((r) => (r ? 1 : 0)),
        ),
      ]).pipe(
        map(([type, length]) => (type ? Math.max(type, length) : length)),
      );
    }
    return of(0);
  }

  private _createMaxSelectedItemsMaxValueStream(
    formGroup: FormGroup<CustomFormsMultiSelectFieldType<unknown>>,
  ) {
    return this._createMinSelectedItemsMaxValueStream(formGroup);
  }

  getLabelErrorMsgByIndex(index: number): string | null {
    const control =
      this._formGroup.controls.items?.controls[index]?.controls.label;
    if (control?.invalid) {
      if (control.hasError('required')) {
        return 'Bitte gib einen Wert an';
      }
      if (control.hasError('uniqueArray')) {
        return 'Dieser Wert ist bereits vorhanden';
      }
    }
    return null;
  }

  getValueErrorMsgByIndex(index: number): string | null {
    const control =
      this._formGroup.controls.items?.controls[index]?.controls.value;
    if (control?.invalid) {
      if (control.hasError('required')) {
        return 'Bitte gib einen Wert an';
      }
      if (control.hasError('uniqueArray')) {
        return 'Dieser Wert ist bereits vorhanden';
      }
      if (control.hasError('pattern')) {
        return 'Bitte gib nur Buchstaben, Zahlen und Unter- und Bindestriche ein';
      }
    }
    return null;
  }

  constructor(readonly service: CustomFormEditComponentService) {}

  private _addOption() {
    this.service.addOptionToMultiSelectField(this.index);
  }

  private _focusOption(index: number) {
    if (this._optionElements?.length > index) {
      this._optionElements.get(index)?.nativeElement.focus();
    }
  }

  onPressedEnterInOption(index: number, event: Event): void {
    event.stopPropagation();
    const control = this.formGroup.controls.items?.controls?.[index];
    if (control && control.valid) {
      const isLastOption = index === this.options.length - 1;
      if (isLastOption) {
        this._addOption();
        setTimeout(() => this._focusOption(index + 1));
      } else {
        this._focusOption(index + 1);
      }
    }
  }

  onClickAddOptionButton() {
    this._addOption();
  }

  onClickRemoveOption(index: number): void {
    this.service.removeOptionFromMultiSelectField(this.index, index);
  }

  get options(): FormGroup<CustomFormsMultiSelectItemFieldType<unknown>>[] {
    return this._formGroup.controls.items?.controls ?? [];
  }

  get triggerText(): string {
    const value = this._formGroup.controls.type?.value;
    if (value) {
      return this.multiSelectFieldTypeOptions[value];
    }
    return 'Bitte wählen';
  }

  get triggerIcon(): string {
    const value = this._formGroup.controls.type?.value;
    if (value) {
      return this.multiSelectFieldTypeIcons[value];
    }
    return '';
  }

  onDrop(event: CdkDragDrop<unknown>) {
    this.service.moveMultiSelectOption(
      this.index,
      event.previousIndex,
      event.currentIndex,
    );
  }

  trackField(
    _: never,
    item: FormGroup<CustomFormsMultiSelectItemFieldType<unknown>>,
  ) {
    return item.controls.value?.value;
  }
}
