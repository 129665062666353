import { Injectable, Optional } from '@angular/core';
import { SortedFilteredTableDataManipulationService } from '@tremaze/shared/sorted-filtered-paginated-table/services';
import { ParticipationCriteria } from '@tremaze/shared/feature/participation-criteria/types';
import { MatDialog } from '@angular/material/dialog';
import { SortedFilteredTableDataManipulationServiceResponse } from '@tremaze/shared/sorted-filtered-paginated-table/types';
import { Observable, of } from 'rxjs';
import { ParticipationCriteriaEditComponent } from './participation-criteria-edit.component';
import { map, switchMap } from 'rxjs/operators';
import { DeleteParticipationCriteriaConfirmationService } from '@tremaze/shared/feature/participation-criteria/feature/delete-confirmation';
import { ParticipationCriteriaDataSource } from '@tremaze/shared/feature/participation-criteria/data-access';

@Injectable({ providedIn: 'root' })
export class ParticipationCriteriaEditService
  implements SortedFilteredTableDataManipulationService<ParticipationCriteria>
{
  mode: 'DEFAULT' | 'ONLY_MANDATORY' | 'ONLY_OPTIONAL';

  constructor(
    private dialog: MatDialog,
    private dataSource: ParticipationCriteriaDataSource,
    @Optional()
    private deleteParticipationCriteriaConfirmationService: DeleteParticipationCriteriaConfirmationService,
  ) {}

  createItem(): Observable<
    | SortedFilteredTableDataManipulationServiceResponse<ParticipationCriteria>
    | null
    | undefined
  > {
    return this.dialog
      .open(ParticipationCriteriaEditComponent, {
        data: { hideMandatorySetting: this.mode !== 'DEFAULT' },
      })
      .afterClosed()
      .pipe(
        map((r: ParticipationCriteria) => {
          if (r && this.mode !== 'DEFAULT') {
            r.criteria = this.mode === 'ONLY_MANDATORY';
          }
          return r ? { item: r } : null;
        }),
      );
  }

  editItem(
    item: ParticipationCriteria,
  ): Observable<
    | SortedFilteredTableDataManipulationServiceResponse<ParticipationCriteria>
    | null
    | undefined
  > {
    return this.dialog
      .open(ParticipationCriteriaEditComponent, {
        data: {
          criteria: item,
          hideMandatorySetting: this.mode !== 'DEFAULT',
        },
      })
      .afterClosed()
      .pipe(
        map((r: ParticipationCriteria) => {
          if (r && this.mode !== 'DEFAULT') {
            r.criteria = this.mode === 'ONLY_MANDATORY';
          }
          return r ? { item: r } : null;
        }),
      );
  }

  deleteItem(item: ParticipationCriteria): Observable<null | boolean> {
    if (this.deleteParticipationCriteriaConfirmationService) {
      return this.deleteParticipationCriteriaConfirmationService
        .confirmDeletion(item)
        .pipe(
          switchMap((r) => {
            if (r === true) {
              return this.dataSource.deleteById(item.id);
            }
            return of(null);
          }),
        );
    }
    return this.dataSource.deleteById(item.id);
  }
}
