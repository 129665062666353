import { Injectable } from '@angular/core';
import { TremazeDate } from '@tremaze/shared/util-date';
import {
  NgxMatDateAdapter,
  NgxMatNativeDateAdapter,
} from '@angular-material-components/datetime-picker';

const dateInputRegex =
  /^(3[01]|[12][0-9]|0?[1-9])\.(1[0-2]|0?[1-9])\.(\d{4}),\s(2[0-3]|[01]?[0-9]):([0-5][0-9])$/;

@Injectable({ providedIn: 'root' })
export class TremazeDateAdapter
  extends NgxMatNativeDateAdapter
  implements NgxMatDateAdapter<Date>
{
  createDate(year: number, month: number, date: number): TremazeDate {
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`,
      );
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    return new TremazeDate(TremazeDate.UTC(year, month, date));
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  clone(date: TremazeDate): TremazeDate {
    return date.clone();
  }

  parse(value: string | number): TremazeDate | null {
    if (typeof value === 'string') {
      if (dateInputRegex.test(value)) {
        const str = value.split(', ');
        const date = str[0].split('.');
        const time = str[1].split(':');
        return TremazeDate.utc(
          TremazeDate.UTC(
            Number(date[2]),
            Number(date[1]) - 1,
            Number(date[0]),
            Number(time[0]),
            Number(time[1]),
          ),
        );
      }

      if (value.indexOf('.') > -1) {
        const str: string[] = value.split('.');
        if (
          str.length < 2 ||
          isNaN(+str[0]) ||
          isNaN(+str[1]) ||
          isNaN(+str[2])
        ) {
          return null;
        }
        return TremazeDate.utc(
          TremazeDate.UTC(Number(str[2]), Number(str[1]) - 1, Number(str[0])),
        );
      }
    }
    const timestamp: number =
      typeof value === 'number' ? value : TremazeDate.parse(value);
    return isNaN(timestamp) ? null : TremazeDate.utc(timestamp);
  }
}
