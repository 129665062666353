@if (isLoading) {
  <mat-progress-bar mode='indeterminate'></mat-progress-bar>
}
<h1 mat-dialog-title>Datei kopieren</h1>
<div mat-dialog-content>
  <p>Möchtest du die Datei <b>{{ file.fileViewname }}</b> in den aktuellen Ordner kopieren?</p>
  <mat-form-field>
    <mat-label>Dateiname</mat-label>
    <input [formControl]='filenameFormControl' matInput placeholder='Dateiname'>
    @if (fileExtension; as fx) {
      <span matSuffix>{{ fileExtension }}</span>
    }
  </mat-form-field>
</div>
<div align='end' mat-dialog-actions>
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button (click)='onClickCopy()' [disabled]='isLoading'
          color='accent' mat-raised-button>Kopieren
  </button>
</div>
