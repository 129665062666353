import { Provider } from '@angular/core';
import { RemoteCCDepartmentDataSource } from '@tremaze/shared/feature/department/data-access';
import {
  InstitutionCRUDDataSource,
  InstitutionREADDataSource,
  RemoteInstitutionCRUDDataSourceDefaultImpl,
} from '@tremaze/shared/feature/institution/data-access';
import { RemoteUserDataSource } from '@tremaze/shared/feature/user/data-access';
import {
  GenderDataSource,
  RemoteCCGenderDataSource,
} from '@tremaze/shared/feature/gender/data-access';
import {
  InformationCRUDDataSource,
  InformationREADDataSource,
} from '@tremaze/shared/feature/information/data-access';
import { RemoteCRUDInformationDataSourceTageaImpl } from '@tremaze/tagea-control-center/feature/information/data-access';
import {
  ActiveTenantDefaultService,
  ActiveTenantService,
} from '@tremaze/shared/feature/tenant';
import {
  RemotePrivilegeDataSource,
  RemoteRoleDataSource,
} from '@tremaze/shared/permission/data-access';
import { RemoteTenantDataSource } from '@tremaze/shared/feature/tenant/data-access';
import { RemoteUserDataSourceTageaCCImpl } from '@tremaze/tagea-control-center/feature/user/data-access';
import {
  ContactPointCRUDDataSource,
  ContactPointReadDataSourceWithPagination,
  RemoteContactPointCRUDDataSourceDefaultImpl,
} from '@tremaze/shared/feature/contact-point/data-access';
import {
  EventCRUDDataSource,
  EventREADDataSource,
} from '@tremaze/shared/feature/event/data-access';
import { DefaultRemoteEventTemplateDataSource } from '@tremaze/shared/feature/event/template/data-access';
import { RemoteCCEventCategoryDataSource } from '@tremaze/shared/feature/category/data-access';
import { RemoteEwsEventDataSource } from '@tremaze/shared/feature/ews/data-access';
import {
  provideRemoteApprovalDataSource,
  provideRemoteApprovalServiceDataSource,
  provideRemoteCostBearerDataSource,
} from '@tremaze/approval-data-access';
import {
  provideFileStorageDataSource,
  provideFolderViewDataSource,
} from '@tremaze/shared/feature/file-storage/data-access';
import { provideConfirmationService } from '@tremaze/shared/feature/confirmation';
import { provideTermsOfUseSettingsDataSource } from '@tremaze/shared/feature/legal-settings/feature/terms-of-use/data-access';
import { provideRemoteLocationDataSource } from '@tremaze/location-data-access';
import { provideRemoteSuggestionsDataSource } from '@tremaze/suggestions-api';
import { provideCustomFormDataSource } from '@tremaze/shared/feature/custom-forms/data-access';

export const DATA_SOURCE_PROVIDERS: Provider[] = [
  RemoteCCDepartmentDataSource,
  {
    provide: RemoteUserDataSource,
    useClass: RemoteUserDataSourceTageaCCImpl,
  },
  {
    provide: InstitutionCRUDDataSource,
    useClass: RemoteInstitutionCRUDDataSourceDefaultImpl,
  },
  {
    provide: InstitutionREADDataSource,
    useExisting: InstitutionCRUDDataSource,
  },
  {
    provide: GenderDataSource,
    useClass: RemoteCCGenderDataSource,
  },
  {
    provide: InformationCRUDDataSource,
    useClass: RemoteCRUDInformationDataSourceTageaImpl,
  },
  {
    provide: InformationREADDataSource,
    useExisting: InformationCRUDDataSource,
  },
  {
    provide: ActiveTenantService,
    useClass: ActiveTenantDefaultService,
  },
  RemoteRoleDataSource,
  RemotePrivilegeDataSource,
  RemoteTenantDataSource,
  {
    provide: EventCRUDDataSource,
    useClass: RemoteEwsEventDataSource,
  },
  {
    provide: ContactPointCRUDDataSource,
    useClass: RemoteContactPointCRUDDataSourceDefaultImpl,
  },
  {
    provide: ContactPointReadDataSourceWithPagination,
    useExisting: ContactPointCRUDDataSource,
  },
  {
    provide: EventREADDataSource,
    useExisting: EventCRUDDataSource,
  },
  DefaultRemoteEventTemplateDataSource,
  RemoteCCEventCategoryDataSource,
  provideRemoteApprovalServiceDataSource(),
  provideRemoteCostBearerDataSource(),
  provideRemoteApprovalDataSource(),
  provideFileStorageDataSource(),
  provideFolderViewDataSource(),
  provideConfirmationService(),
  provideTermsOfUseSettingsDataSource(),
  provideRemoteLocationDataSource(),
  provideRemoteSuggestionsDataSource(),
  provideCustomFormDataSource(),
];
