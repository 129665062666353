import { Component } from '@angular/core';

@Component({
  selector: 'tremaze-popup-actions',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: flex-end;
        padding-top: 1rem;
        gap: 1rem;
      }
    `,
  ],
})
export class PopupActionsComponent {}
