import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Information } from '@tremaze/shared/feature/information/types';
import { Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { RemoteInformationCRUDDataSourceDefaultImpl } from '@tremaze/shared/feature/information/data-access';

@Injectable({ providedIn: 'root' })
export class RemoteCRUDInformationDataSourceTageaImpl extends RemoteInformationCRUDDataSourceDefaultImpl {
  constructor(http: HttpClient, js: JsonSerializer) {
    super(http, js);
  }

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions<Information>,
    goalFilter?: string,
  ): Observable<Pagination<Information>> {
    const newQ = {
      ...options?.q,
      goalFilter: goalFilter ?? 'ONLY_INFORMATION',
    };
    return super.getPaginated({
      ...(options ?? {}),
      q: newQ,
    });
  }
}

@Injectable()
export class RemoteCRUDInformationGoalDataSourceTageaImpl extends RemoteCRUDInformationDataSourceTageaImpl {
  getPaginated(
    options?: DataSourceMethodsPaginatedOptions<Information>,
  ): Observable<Pagination<Information>> {
    console.log('RemoteCRUDInformationGoalDataSourceTageaImpl.getPaginated');
    return super.getPaginated({ ...options }, 'ONLY_GOALS');
  }
}
