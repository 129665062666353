import { Component, Inject } from '@angular/core';
import { POPUP_DATA } from '@tremaze/shared/ui/popup';

@Component({
  selector: 'tremaze-feature-assistant-dialog',
  templateUrl: './feature-assistant-dialog.component.html',
  styleUrls: ['./feature-assistant-dialog.component.scss'],
})
export class FeatureAssistantDialogComponent {
  constructor(@Inject(POPUP_DATA) readonly data: { message: string }) {}
}
