import { InjectionToken } from '@angular/core';
import { CategoryType } from '@tremaze/shared/feature/category/types';

export interface CategoryModuleConfig {
  titleImageDisabled?: boolean;
  iconDisabled?: boolean;
  colorDisabled?: boolean;
  automaticallyAssignAuthUserInstitutionOnCreation?: boolean;
  descriptionDisabled?: boolean;
  disableDepartmentAllocation?: boolean;
  disableUserTypeAllocation?: boolean;
}

export const CATEGORY_MODULE_CONFIG = new InjectionToken<
  CategoryModuleConfig[]
>('CATEGORY_MODULE_CONFIG');

export const CATEGORY_TYPE_CONFIG = new InjectionToken<CategoryType>(
  'CATEGORY_TYPE_CONFIG',
);

export const EVENT_CATEGORY_TYPE_PROVIDER = {
  provide: CATEGORY_TYPE_CONFIG,
  useValue: 'EVENT_CATEGORY',
};

export const INFORMATION_CATEGORY_TYPE_PROVIDER = {
  provide: CATEGORY_TYPE_CONFIG,
  useValue: 'INFORMATION_CATEGORY',
};
