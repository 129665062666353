<ng-container [formGroup]="formGroup">
  <section class="form-grid">
    <tremaze-address-edit
      [formGroup]="formGroup.controls.address"
    ></tremaze-address-edit>
    <div
      class="form-grid-item-new-row"
      *ngIf="eventTemplateForm.showUseInstAddressButton"
    >
      <button
        mat-stroked-button
        color="accent"
        [disabled]="
          eventTemplateForm.isUseAddressFromSelectedInstitutionButtonDisabled
        "
        (click)="eventTemplateForm.onClickUseAddressFromSelectedInstitution()"
      >
        <span class="lnr lnr-map-marker-check"></span>
        {{ eventTemplateForm.useAddressFromSelectedInstitutionLabelText }}
      </button>
    </div>
  </section>
</ng-container>
