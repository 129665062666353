import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  Output,
} from '@angular/core';
import { DragCounterReferenceService } from './drag-counter-reference.service';

@Directive({
  selector: '[tremazeFileDropInDropZone]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'file-drop-in-drop-zone',
  },
  exportAs: 'fileDropInDropZone',
})
export class FileDropInDropZoneDirective {
  private readonly _refService = inject(DragCounterReferenceService);

  @HostBinding('class.file-drop-in-drop-zone--Disabled')
  @Input()
  dropInZoneDisabled = false;

  @Output() readonly filesDropped = new EventEmitter<File[]>();

  @HostBinding('class.file-drop-in-drop-zone--Dragging')
  get isDraggingFiles() {
    return this._refService.isDragging;
  }

  private _isDraggingOver = false;

  @HostBinding('class.file-drop-in-drop-zone--DraggingOver')
  get isDraggingOver() {
    return this._isDraggingOver;
  }

  @HostListener('dragover', ['$event'])
  private _onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this._isDraggingOver = true;
  }

  @HostListener('dragleave', ['$event'])
  private _onDragLeave(event: DragEvent) {
    this._isDraggingOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    if (this.dropInZoneDisabled) {
      return;
    }

    if (this._isDraggingOver && event.dataTransfer?.files.length) {
      event.preventDefault();
      this.filesDropped.emit(Array.from(event.dataTransfer.files));
    }
    this._isDraggingOver = false;
  }
}
