import { ApprovalService } from '@tremaze/shared/feature/approval/types';
import { PageGenerator } from '../helpers';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';

class ApprovalServiceFixturePageGenerator extends PageGenerator<ApprovalService> {
  override generateByIndex(index: number): ApprovalService {
    return ApprovalServiceFixtures.generateApprovalService(index);
  }
}

export abstract class ApprovalServiceFixtures {
  static generateApprovalService = (id: number): ApprovalService => ({
    id: `${id}`,
    shortName: `AS${id}`,
    description: `Approval Service ${id}`,
    value: id,
    externalId: `AS-${id}`,
  });

  private static _approvalServiceStubPageGenerator =
    new ApprovalServiceFixturePageGenerator();

  static generateApprovalServicePage = (
    options: DataSourceMethodsPaginatedOptions,
  ) =>
    ApprovalServiceFixtures._approvalServiceStubPageGenerator.generatePage(
      options,
    );

  static readonly approvalService1: ApprovalService =
    ApprovalServiceFixtures.generateApprovalService(1);
  static readonly approvalService2: ApprovalService =
    ApprovalServiceFixtures.generateApprovalService(2);
  static readonly approvalService3: ApprovalService =
    ApprovalServiceFixtures.generateApprovalService(3);
  static readonly approvalService4: ApprovalService =
    ApprovalServiceFixtures.generateApprovalService(4);
  static readonly approvalService5: ApprovalService =
    ApprovalServiceFixtures.generateApprovalService(5);

  static readonly approvalServices = [
    ApprovalServiceFixtures.approvalService1,
    ApprovalServiceFixtures.approvalService2,
    ApprovalServiceFixtures.approvalService3,
    ApprovalServiceFixtures.approvalService4,
    ApprovalServiceFixtures.approvalService5,
  ];
}
