import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HtmlPreviewDialogComponent } from './html-preview-dialog.component';

@Injectable()
export class HtmlPreviewService {
  constructor(public dialog: MatDialog) {}

  openHtmlPreview(previewUrl: string) {
    this.dialog.open(HtmlPreviewDialogComponent, {
      width: '900px',
      data: {
        previewUrl: previewUrl,
      },
    });
  }
}
