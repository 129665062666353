export type CountryCode =
  | 'AF'
  | 'AL'
  | 'DZ'
  | 'AS'
  | 'AD'
  | 'AO'
  | 'AI'
  | 'AQ'
  | 'AG'
  | 'AR'
  | 'AM'
  | 'AW'
  | 'AU'
  | 'AT'
  | 'AZ'
  | 'BS'
  | 'BH'
  | 'BD'
  | 'BB'
  | 'BY'
  | 'BE'
  | 'BZ'
  | 'BJ'
  | 'BM'
  | 'BT'
  | 'BO'
  | 'BQ'
  | 'BA'
  | 'BW'
  | 'BV'
  | 'BR'
  | 'IO'
  | 'BN'
  | 'BG'
  | 'BF'
  | 'BI'
  | 'KH'
  | 'CM'
  | 'CA'
  | 'CV'
  | 'KY'
  | 'CF'
  | 'TD'
  | 'CL'
  | 'CN'
  | 'CX'
  | 'CC'
  | 'CO'
  | 'KM'
  | 'CG'
  | 'CD'
  | 'CK'
  | 'CR'
  | 'HR'
  | 'CU'
  | 'CW'
  | 'CY'
  | 'CZ'
  | 'CI'
  | 'DK'
  | 'DJ'
  | 'DM'
  | 'DO'
  | 'EC'
  | 'EG'
  | 'SV'
  | 'GQ'
  | 'ER'
  | 'EE'
  | 'SZ'
  | 'ET'
  | 'FK'
  | 'FO'
  | 'FJ'
  | 'FI'
  | 'FR'
  | 'GF'
  | 'PF'
  | 'TF'
  | 'GA'
  | 'GM'
  | 'GE'
  | 'DE'
  | 'GH'
  | 'GI'
  | 'GR'
  | 'GL'
  | 'GD'
  | 'GP'
  | 'GU'
  | 'GT'
  | 'GG'
  | 'GN'
  | 'GW'
  | 'GY'
  | 'HT'
  | 'HM'
  | 'VA'
  | 'HN'
  | 'HK'
  | 'HU'
  | 'IS'
  | 'IN'
  | 'ID'
  | 'IR'
  | 'IQ'
  | 'IE'
  | 'IM'
  | 'IL'
  | 'IT'
  | 'JM'
  | 'JP'
  | 'JE'
  | 'JO'
  | 'KZ'
  | 'KE'
  | 'KI'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KG'
  | 'LA'
  | 'LV'
  | 'LB'
  | 'LS'
  | 'LR'
  | 'LY'
  | 'LI'
  | 'LT'
  | 'LU'
  | 'MO'
  | 'MG'
  | 'MW'
  | 'MY'
  | 'MV'
  | 'ML'
  | 'MT'
  | 'MH'
  | 'MQ'
  | 'MR'
  | 'MU'
  | 'YT'
  | 'MX'
  | 'FM'
  | 'MD'
  | 'MC'
  | 'MN'
  | 'ME'
  | 'MS'
  | 'MA'
  | 'MZ'
  | 'MM'
  | 'NA'
  | 'NR'
  | 'NP'
  | 'NL'
  | 'NC'
  | 'NZ'
  | 'NI'
  | 'NE'
  | 'NG'
  | 'NU'
  | 'NF'
  | 'MK'
  | 'MP'
  | 'NO'
  | 'OM'
  | 'PK'
  | 'PW'
  | 'PS'
  | 'PA'
  | 'PG'
  | 'PY'
  | 'PE'
  | 'PH'
  | 'PN'
  | 'PL'
  | 'PT'
  | 'PR'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RU'
  | 'RW'
  | 'BL'
  | 'SH'
  | 'KN'
  | 'LC'
  | 'MF'
  | 'PM'
  | 'VC'
  | 'WS'
  | 'SM'
  | 'ST'
  | 'SA'
  | 'SN'
  | 'RS'
  | 'SC'
  | 'SL'
  | 'SG'
  | 'SX'
  | 'SK'
  | 'SI'
  | 'SB'
  | 'SO'
  | 'ZA'
  | 'GS'
  | 'SS'
  | 'ES'
  | 'LK'
  | 'SD'
  | 'SR'
  | 'SJ'
  | 'SE'
  | 'CH'
  | 'SY'
  | 'TW'
  | 'TJ'
  | 'TZ'
  | 'TH'
  | 'TL'
  | 'TG'
  | 'TK'
  | 'TO'
  | 'TT'
  | 'TN'
  | 'TR'
  | 'TM'
  | 'TC'
  | 'TV'
  | 'UG'
  | 'UA'
  | 'AE'
  | 'GB'
  | 'US'
  | 'UM'
  | 'UY'
  | 'UZ'
  | 'VU'
  | 'VE'
  | 'VN'
  | 'VG'
  | 'VI'
  | 'WF'
  | 'EH'
  | 'XK'
  | 'YE'
  | 'ZM'
  | 'ZW';

export interface Country {
  name: string;
  code: CountryCode;
}

export function tryGetCountryByCode(
  code: CountryCode | Lowercase<CountryCode> | string | undefined | null,
): Country | undefined {
  if (!code || !code.length) return undefined;
  code = code.toUpperCase() as CountryCode;
  return COUNTRIES_GER.find((country) => country.code === code);
}

export function getCountryByCode(
  code: CountryCode | Lowercase<CountryCode>,
): Country {
  code = code.toUpperCase() as CountryCode;
  return COUNTRIES_GER.find((country) => country.code === code);
}

export const COUNTRIES_GER: { name: string; code: CountryCode }[] = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Albanien', code: 'AL' },
  { name: 'Algerien', code: 'DZ' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Antigua und Barbuda', code: 'AG' },
  { name: 'Argentinien', code: 'AR' },
  { name: 'Armenien', code: 'AM' },
  { name: 'Australien', code: 'AU' },
  { name: 'Österreich', code: 'AT' },
  { name: 'Aserbaidschan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesch', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Weißrussland', code: 'BY' },
  { name: 'Belgien', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivien', code: 'BO' },
  { name: 'Bosnien und Herzegowina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Brasilien', code: 'BR' },
  { name: 'Brunei', code: 'BN' },
  { name: 'Bulgarien', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cabo Verde', code: 'CV' },
  { name: 'Kambodscha', code: 'KH' },
  { name: 'Kamerun', code: 'CM' },
  { name: 'Kanada', code: 'CA' },
  { name: 'Zentralafrikanische Republik', code: 'CF' },
  { name: 'Tschad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Kolumbien', code: 'CO' },
  { name: 'Komoren', code: 'KM' },
  { name: 'Kongo (Demokratische Republik)', code: 'CD' },
  { name: 'Kongo (Republik)', code: 'CG' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Kroatien', code: 'HR' },
  { name: 'Kuba', code: 'CU' },
  { name: 'Zypern', code: 'CY' },
  { name: 'Tschechien', code: 'CZ' },
  { name: 'Dänemark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominikanische Republik', code: 'DO' },
  { name: 'Osttimor', code: 'TL' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Ägypten', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Äquatorialguinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estland', code: 'EE' },
  { name: 'Eswatini', code: 'SZ' },
  { name: 'Äthiopien', code: 'ET' },
  { name: 'Fidschi', code: 'FJ' },
  { name: 'Finnland', code: 'FI' },
  { name: 'Frankreich', code: 'FR' },
  { name: 'Gabun', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgien', code: 'GE' },
  { name: 'Deutschland', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Griechenland', code: 'GR' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Ungarn', code: 'HU' },
  { name: 'Island', code: 'IS' },
  { name: 'Indien', code: 'IN' },
  { name: 'Indonesien', code: 'ID' },
  { name: 'Iran', code: 'IR' },
  { name: 'Irak', code: 'IQ' },
  { name: 'Irland', code: 'IE' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italien', code: 'IT' },
  { name: 'Jamaika', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jordanien', code: 'JO' },
  { name: 'Kasachstan', code: 'KZ' },
  { name: 'Kenia', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea (Nord)', code: 'KP' },
  { name: 'Korea (Süd)', code: 'KR' },
  { name: 'Kosovo', code: 'XK' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kirgisistan', code: 'KG' },
  { name: 'Laos', code: 'LA' },
  { name: 'Lettland', code: 'LV' },
  { name: 'Libanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyen', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Litauen', code: 'LT' },
  { name: 'Luxemburg', code: 'LU' },
  { name: 'Madagaskar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Malediven', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshallinseln', code: 'MH' },
  { name: 'Mauretanien', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mexiko', code: 'MX' },
  { name: 'Mikronesien', code: 'FM' },
  { name: 'Moldawien', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolei', code: 'MN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Marokko', code: 'MA' },
  { name: 'Mosambik', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Niederlande', code: 'NL' },
  { name: 'Neuseeland', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Nordmazedonien (ehemals Mazedonien)', code: 'MK' },
  { name: 'Norwegen', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palästina', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua-Neuguinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippinen', code: 'PH' },
  { name: 'Polen', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Katar', code: 'QA' },
  { name: 'Rumänien', code: 'RO' },
  { name: 'Russland', code: 'RU' },
  { name: 'Ruanda', code: 'RW' },
  { name: 'St. Kitts und Nevis', code: 'KN' },
  { name: 'St. Lucia', code: 'LC' },
  { name: 'St. Vincent und die Grenadinen', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'São Tomé und Príncipe', code: 'ST' },
  { name: 'Saudi-Arabien', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbien', code: 'RS' },
  { name: 'Seychellen', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapur', code: 'SG' },
  { name: 'Slowakei', code: 'SK' },
  { name: 'Slowenien', code: 'SI' },
  { name: 'Salomonen', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'Südafrika', code: 'ZA' },
  { name: 'Südsudan', code: 'SS' },
  { name: 'Spanien', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Schweden', code: 'SE' },
  { name: 'Schweiz', code: 'CH' },
  { name: 'Syrien', code: 'SY' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Tadschikistan', code: 'TJ' },
  { name: 'Tansania', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad und Tobago', code: 'TT' },
  { name: 'Tunesien', code: 'TN' },
  { name: 'Türkei', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'Vereinigte Arabische Emirate', code: 'AE' },
  { name: 'Vereinigtes Königreich', code: 'GB' },
  { name: 'Vereinigte Staaten', code: 'US' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Usbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Vatikanstadt (Heiliger Stuhl)', code: 'VA' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Jemen', code: 'YE' },
  { name: 'Sambia', code: 'ZM' },
  { name: 'Simbabwe', code: 'ZW' },
];
