import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { PopupDialogComponent } from './popup-dialog/popup-dialog.component';
import { PopupOptions } from './types';
import { PopupBottomSheetComponent } from './popup-bottom-sheet/popup-bottom-sheet.component';
import { PopupRef } from './popup-ref';
import { ComponentType } from '@angular/cdk/portal';

@Injectable()
export class PopupService {
  private get _isMobile(): boolean {
    return window.innerWidth < 600;
  }

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _bottomSheet: MatBottomSheet,
  ) {}

  open<C = any, ResultType = any>(
    componentOrTemplateRef: ComponentType<C>,
    options: PopupOptions,
  ): PopupRef<C, ResultType> {
    const mode = options.mode ?? 'AUTO';
    let ref:
      | MatDialogRef<PopupDialogComponent<C>, ResultType>
      | MatBottomSheetRef<PopupBottomSheetComponent<C>, ResultType>;
    if (mode != 'DIALOG' && (mode === 'BOTTOM_SHEET' || this._isMobile)) {
      ref = this._bottomSheet.open<PopupBottomSheetComponent<C>>(
        PopupBottomSheetComponent,
        {
          data: {
            component: componentOrTemplateRef,
            options,
            data: options.data,
          },
          disableClose: options.disableClose,
        },
      );
    } else {
      ref = this._dialog.open<PopupDialogComponent<C>>(PopupDialogComponent, {
        data: {
          component: componentOrTemplateRef,
          options,
          data: options.data,
        },
        maxWidth: options.maxWidth,
        disableClose: options.disableClose,
      });
    }

    return new PopupRef<C, ResultType>(ref);
  }
}
