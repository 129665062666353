import {
  booleanAttribute,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DefaultValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  CustomFormFeature,
  customFormFeatureLabelMap,
} from '@tremaze/shared/feature/custom-forms/types';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormField,
  MatFormFieldAppearance,
  MatLabel,
} from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ensureArray, ensureNotArray } from '@tremaze/shared/util-utilities';

@Component({
  selector: 'tremaze-custom-form-feature-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
  ],
  template: `
    <mat-form-field
      [appearance]="appearance"
      subscriptSizing="dynamic"
      style="width: 100%"
    >
      <mat-label>Art</mat-label>
      <mat-select
        [multiple]="multiple"
        [disabled]="disabled"
        [value]="value"
        (selectionChange)="onSelectChange($event)"
      >
        @for (feature of formFeatureLabelMap | keyvalue; track feature.key) {
          <mat-option [value]="feature.key">{{ feature.value }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomFormFeatureSelectComponent,
    },
  ],
})
export class CustomFormFeatureSelectComponent extends DefaultValueAccessor {
  readonly formFeatureLabelMap = customFormFeatureLabelMap;

  @Input() appearance: MatFormFieldAppearance =
    inject(MAT_FORM_FIELD_DEFAULT_OPTIONS, { optional: true })?.appearance ??
    'outline';

  private _multiple = false;

  @Input({ transform: booleanAttribute })
  get multiple() {
    return this._multiple;
  }

  set multiple(value: boolean | string | null | undefined | '') {
    this._multiple = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input({ transform: booleanAttribute })
  get disabled() {
    return this._disabled;
  }

  set disabled(value: boolean | string | null | undefined | '') {
    this.setDisabledState(coerceBooleanProperty(value));
  }

  @Output() selectionChange = new EventEmitter<
    CustomFormFeature[] | CustomFormFeature | null | undefined
  >();

  override setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
    super.setDisabledState(isDisabled);
  }

  private _transformValue(
    value?: CustomFormFeature[] | CustomFormFeature | null,
  ): CustomFormFeature[] | CustomFormFeature | null | undefined {
    if (!value) {
      return this.multiple ? [] : undefined;
    }
    if (this.multiple) {
      return ensureArray(value);
    }
    return ensureNotArray(value);
  }

  private _value?: CustomFormFeature[] | CustomFormFeature | null | undefined;

  get value() {
    return this._transformValue(this._value);
  }

  @Input()
  set value(value: CustomFormFeature[] | CustomFormFeature | null | undefined) {
    value = this._transformValue(value);
    if (this._value !== value) {
      this._value = value;
      this.onChange?.(value);
    }
  }

  override writeValue(value: any) {
    value = this._transformValue(value);
    super.writeValue(value);
    this._value = value;
  }

  onSelectChange(change: MatSelectChange) {
    const value = this._transformValue(change.value);
    this._value = value;
    this.onChange?.(value);
    this.onTouched?.();
    this.selectionChange.emit(value);
  }
}
