import {
  Component,
  ComponentRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ComponentOrTemplateRef, PopupOptions } from '../types';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { PopupRef } from '../popup-ref';
import { POPUP_DATA } from '../tokens';

@Component({
  selector: 'tremaze-popup-bottom-sheet',
  templateUrl: './popup-bottom-sheet.component.html',
  providers: [
    {
      provide: PopupRef,
      useFactory: (ref: MatBottomSheetRef) => new PopupRef(ref),
      deps: [MatBottomSheetRef],
    },
    {
      provide: POPUP_DATA,
      useFactory: (data: any) => data.data,
      deps: [[new Optional(), MAT_BOTTOM_SHEET_DATA]],
    },
  ],
  styles: [
    `
      h1 {
        font-size: 1.5rem;
      }
    `,
  ],
})
export class PopupBottomSheetComponent<C> implements OnInit {
  get componentRef(): ComponentRef<C> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._componentRef!;
  }

  @ViewChild('container', { read: ViewContainerRef, static: true })
  private readonly container!: ViewContainerRef;

  private _componentRef?: ComponentRef<C>;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    readonly data: {
      component: ComponentOrTemplateRef<C>;
      options: PopupOptions;
    },
  ) {}

  private _createComponent() {
    this._componentRef = this.container.createComponent(this.data.component);
  }

  ngOnInit(): void {
    this._createComponent();
  }
}
