<mat-slide-toggle (change)='onChangeMixFilesAndFolders($event)' [checked]='initialMixFilesAndFolders$ | async'
                  [disableRipple]='true' labelPosition='before'>Dateien und Ordner
  mischen
</mat-slide-toggle>
@if (initialSort$ | async; as initialSort) {

  <mat-table (matSortChange)='onSortChange($event)' [dataSource]='tableData$' matSort
             [matSortActive]='initialSort.field'
             [matSortDirection]='initialSort.direction'>

    <ng-container matColumnDef='select'>
      <mat-header-cell *matHeaderCellDef>
        @if (selectMultiple) {
          <mat-checkbox (change)='$event ? toggleAllRows() : null'
                        [checked]='isAllSelected'
                        [indeterminate]='hasSelection && !isAllSelected'>
          </mat-checkbox>
        }
      </mat-header-cell>
      <mat-cell *matCellDef='let row'>
        @if (canSelectRowFn(row)) {
          <mat-checkbox (change)='$event ? toggleSelection(row) : null'
                        (click)='$event.stopPropagation()'
                        (dblclick)='$event.stopPropagation()'
                        [checked]='isSelected(row)'>
          </mat-checkbox>
        }
      </mat-cell>
    </ng-container>

    <!-- Icon Column -->
    <ng-container matColumnDef='icon'>
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef='let row' mat-cell>
        <tremaze-folder-view-item-icon [item]='row'></tremaze-folder-view-item-icon>
      </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef='name'>
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
      <mat-cell *matCellDef='let row' mat-cell>
        @if (isRowBeingEdited(row)) {
          <tremaze-folder-view-item-name-input
            [item]='row === "CREATE_DIRECTORY" ? null : row'
            (cancel)='stopEditing()'
            (submitText)='onSubmitNameEdit($event)'
            fontSize='inherit'></tremaze-folder-view-item-name-input>
        } @else {
          {{ getRowName(row) }}
        }
      </mat-cell>
    </ng-container>

    <!-- Modified Date Column -->
    <ng-container matColumnDef='date'>
      <mat-header-cell *matHeaderCellDef mat-sort-header> Zuletzt geändert</mat-header-cell>
      <mat-cell *matCellDef='let row' mat-cell>
        {{ (row.meta?.editDate ?? row.meta?.insertDate) | date: 'dd.MM.yyyy HH:mm' }}
      </mat-cell>
    </ng-container>

    <!-- Size Column -->
    <ng-container matColumnDef='size'>
      <mat-header-cell *matHeaderCellDef mat-sort-header>Größe</mat-header-cell>
      <mat-cell *matCellDef='let row'>
        @if (isItemFile(row)) {
          {{ row.fileSize | bytes }}
        }
      </mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef='actions'>
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef='let row'>

        <button (click)='$event.stopPropagation()' [matMenuTriggerData]='{row}' [matMenuTriggerFor]='menu'
                color='primary'
                mat-icon-button>
          <tremaze-icon icon='lnr-ellipsis' style='transform: rotate(90deg)'></tremaze-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef='displayedColumns; sticky: true'></mat-header-row>

    <ng-container *matRowDef='let row; columns: displayedColumns;'>
      <mat-row (click)='onRowClick(row)'
               (dblclick)='onRowDoubleClick(row)'
               (dropped)='onDataDropped(row, $event)'
               (filesDropped)='onFilesDropped(row, $event)'
               [dragData]='row'
               [dragDisabled]='!isSelected(row)'
               [dropZoneDisabled]='isItemFile(row)'
               [dropInZoneDisabled]='isItemFile(row)'
               tremazeDragZoneItem
               tremazeDropZone
               tremazeFileDropInDropZone
      ></mat-row>
    </ng-container>
  </mat-table>
}


<mat-menu #menu='matMenu'>
  <ng-template let-row='row' matMenuContent>
    @if (isItemFile(row)) {
      <tremaze-folder-view-file-contextmenu [file]='row'
                                            [canWrite]='(getCanWriteStreamForFileOrFolder?.(row) | async) ?? false'
                                            [canDelete]='(getCanDeleteStreamForFileOrFolder?.(row) | async) ?? false'
                                            (showFilePreview)='showFilePreview.emit(row)'
                                            (navigateToPath)='navigateToPath.emit(row.absolutePath)'
                                            (duplicateFile)='duplicateFile.emit(row)'
                                            (copyFileLink)='copyFileLink.emit(row)'
                                            (deleteFile)='deleteFile.emit(row)'
                                            (downloadFile)='downloadFile.emit(row)'
                                            (renameFile)='startFileNameEdit(row)'
      ></tremaze-folder-view-file-contextmenu>
    } @else {
      <tremaze-folder-view-folder-contextmenu [folder]='row'
                                              [canWrite]='(getCanWriteStreamForFileOrFolder?.(row) | async) ?? false'
                                              (deleteFolder)='deleteFolder.emit(row)'
                                              (openFolder)='openFolder.emit(row)'
                                              (renameFolder)='startFolderNameEdit(row)'
      ></tremaze-folder-view-folder-contextmenu>
    }
  </ng-template>
</mat-menu>
