import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Time } from '@angular/common';
import { TremazeDate } from '@tremaze/shared/util-date';

export type PersonalConfigUser = {
  id: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  avatar?: FileStorage;
};

export type PersonalConfigInstitution = {
  id: string;
  name: string;
  logo?: FileStorage;
};

export type PersonalConfigDepartment = {
  id: string;
  name: string;
};

export type PersonalConfigSpecialization = {
  id: string;
  name: string;
};

export type PersonalConfigListType =
  | 'CLIENT_LIST'
  | 'EMPLOYEE_LIST'
  | 'EVENT_LIST'
  | 'INFORMATION_LIST'
  | 'GOAL_LIST'
  | 'EVENT_CATEGORY_LIST'
  | 'EVENT_CALENDAR'
  | 'EVENT_TEMPLATE_LIST'
  | 'USER_UNAPPROVED_LIST'
  | 'APPROVAL_LIST'
  | 'FORM_LIST'
  | 'SUBMISSION_LIST'
  | 'INFORMATION_CATEGORY_LIST';

export type PersonalConfigAttributeFilterSetting =
  | {
      dataType: 'STRING';
      value?: string | string[];
    }
  | {
      dataType: 'DATE';
      value?: TremazeDate | TremazeDate[];
    }
  | {
      dataType: 'INTEGER' | 'DOUBLE';
      value?: number | number[];
    }
  | {
      dataType: 'BOOLEAN';
      value?: boolean | boolean[];
    };

export type PersonalConfigSortSetting = {
  attribute: string;
  direction: 'asc' | 'desc';
};

export type PersonalConfigFilterSetting = {
  filterInstitutions: PersonalConfigInstitution[];
  filterDepartments: PersonalConfigDepartment[];
  filterUsers: PersonalConfigUser[];
  filterSpecializations: PersonalConfigSpecialization[];
  sortAttribute?: PersonalConfigSortSetting;
  [key: string]: PersonalConfigAttributeFilterSetting | unknown | undefined;
};

type FilterSettings = {
  eventCalendarSettings: PersonalConfigFilterSetting;
  eventListSettings: PersonalConfigFilterSetting;
  informationListSettings: PersonalConfigFilterSetting;
  informationCategoryListSettings: PersonalConfigFilterSetting;
  goalListSettings: PersonalConfigFilterSetting;
  eventCategoryListSettings: PersonalConfigFilterSetting;
  eventTemplateListSettings: PersonalConfigFilterSetting;
  employeeListSettings: PersonalConfigFilterSetting;
  clientListSettings: PersonalConfigFilterSetting;
  userUnapprovedListSettings: PersonalConfigFilterSetting;
  approvalListSettings: PersonalConfigFilterSetting;
  formListSettings: PersonalConfigFilterSetting;
  submissionListSettings: PersonalConfigFilterSetting;
};

export type PersonalConfig = {
  readonly teamMembers: PersonalConfigUser[];
  readonly filterSettings: FilterSettings;
  readonly coreWorkingHoursStart?: Time;
  readonly coreWorkingHoursEnd?: Time;
  readonly eventPreviewSettings: {
    defaultExtendedUserCreation: boolean;
  };
};

export type PersonalConfigListFilterSettingInput = {
  entityId: string;
  entityType: 'SPECIALIZATION' | 'INSTITUTION' | 'DEPARTMENT' | 'USER';
  listType: PersonalConfigListType;
};

export function mapPersonalConfigFilterKeyToPersonalConfigFilterSettingInputType(
  key: keyof PersonalConfig['filterSettings'],
): PersonalConfigListFilterSettingInput['listType'] | null {
  switch (key) {
    case 'informationCategoryListSettings':
      return 'INFORMATION_CATEGORY_LIST';
    case 'clientListSettings':
      return 'CLIENT_LIST';
    case 'employeeListSettings':
      return 'EMPLOYEE_LIST';
    case 'eventListSettings':
      return 'EVENT_LIST';
    case 'informationListSettings':
      return 'INFORMATION_LIST';
    case 'goalListSettings':
      return 'GOAL_LIST';
    case 'eventCategoryListSettings':
      return 'EVENT_CATEGORY_LIST';
    case 'eventTemplateListSettings':
      return 'EVENT_TEMPLATE_LIST';
    case 'eventCalendarSettings':
      return 'EVENT_CALENDAR';
    case 'userUnapprovedListSettings':
      return 'USER_UNAPPROVED_LIST';
    case 'approvalListSettings':
      return 'APPROVAL_LIST';
    case 'formListSettings':
      return 'FORM_LIST';
    case 'submissionListSettings':
      return 'SUBMISSION_LIST';
  }
  return null;
}
