import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicListComponent } from './dynamic-list/dynamic-list.component';
import { DynamicListItemTitleDirective } from './dynamic-list-item-title.directive';
import { DynamicListItemComponent } from './dynamic-list-item/dynamic-list-item.component';
import { DynamicListItemAvatarComponent } from './dynamic-list-item-avatar.component';
import { DynamicListItemSubtitleDirective } from './dynamic-list-item-subtitle.directive';
import { MatButtonModule } from '@angular/material/button';
import { DynamicListItemActionComponent } from './dynamic-list-item-action.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedFileStorageUiCircleAvatarModule } from '@tremaze/shared/feature/file-storage/ui/circle-avatar';
import { DynamicListAddButtonComponent } from './tremaze-dynamic-list-add-button/dynamic-list-add-button.component';
import { DynamicListCenterColumnComponent } from './dynamic-list-center-column.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    SharedFileStorageUiCircleAvatarModule,
  ],
  declarations: [
    DynamicListComponent,
    DynamicListItemTitleDirective,
    DynamicListItemComponent,
    DynamicListItemAvatarComponent,
    DynamicListItemSubtitleDirective,
    DynamicListItemActionComponent,
    DynamicListAddButtonComponent,
    DynamicListCenterColumnComponent,
  ],
  exports: [
    DynamicListComponent,
    DynamicListItemTitleDirective,
    DynamicListItemComponent,
    DynamicListItemAvatarComponent,
    DynamicListItemSubtitleDirective,
    DynamicListItemActionComponent,
    DynamicListAddButtonComponent,
    DynamicListCenterColumnComponent,
  ],
})
export class DynamicListModule {}
