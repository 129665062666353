import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  Optional,
  ViewChild,
} from '@angular/core';
import { DataTableStore } from '../../data-table.store';
import { IdObject } from '@tremaze/shared/util/id-object';
import { pluck, takeUntil, tap } from 'rxjs/operators';
import { PAGE_SIZE } from '../../data-table.component';
import { MatPaginator } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { DataTablePermissionConfig } from '@tremaze/shared/ui/data-table/types';
import { DataTableActionsService } from '../../data-table-actions.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'tremaze-data-table-bottom',
  templateUrl: './data-table-bottom.component.html',
  styleUrls: ['./data-table-bottom.component.scss'],
})
export class DataTableBottomComponent<T extends IdObject>
  implements AfterViewInit, OnDestroy
{
  private _hideCreateButton = false;

  @Input()
  get hideCreateButton(): boolean {
    return this._hideCreateButton;
  }

  set hideCreateButton(value: boolean) {
    this._hideCreateButton = coerceBooleanProperty(value);
  }

  @Input() pageSizes: PAGE_SIZE[];
  @Input() initialPageSize: PAGE_SIZE;
  @Input() permissionConfig?: DataTablePermissionConfig<T>;
  readonly totalElementCount$ = this._store.vm$.pipe(
    pluck('totalElementCount'),
  );
  readonly pageIndex$ = this._store.vm$.pipe(pluck('currentPageIndex'));
  @ViewChild(MatPaginator) private _paginator: MatPaginator;
  private _destroyed$ = new Subject<void>();

  get showCreateButton(): boolean {
    return this._actionsService != null && this._actionsService.create != null;
  }

  constructor(
    private readonly _store: DataTableStore<T>,
    @Optional() private readonly _actionsService?: DataTableActionsService,
  ) {}

  ngAfterViewInit(): void {
    this._paginator.page
      .pipe(
        takeUntil(this._destroyed$),
        tap(({ pageSize, pageIndex }) =>
          this._store.paginatorUpdate({
            page: pageIndex,
            pageSize,
          }),
        ),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onClickCreateButton(): void {
    this._actionsService?.create(() => this._store.reload());
  }
}
