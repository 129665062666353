import { Injectable } from '@angular/core';
import { EventScope, TremazeEvent } from '@tremaze/shared/feature/event/types';
import { PopupService } from '@tremaze/shared/ui/popup';
import { map, Observable, of } from 'rxjs';
import { EventScopeSelectorPopupComponent } from './event-scope-selector-popup.component';
import { EventRegistrationScope } from '@tremaze/shared/feature/event-registration/types';

@Injectable()
export class EventScopeSelectorService {
  constructor(private readonly _popupService: PopupService) {}

  selectEventScope(
    forEvent?: Pick<TremazeEvent, 'startDate' | 'schedule'>,
    allowedScopes?: EventScope[],
  ): Observable<EventScope | undefined> {
    if (!forEvent?.schedule) {
      return of('SINGLE');
    }

    return this._popupService
      .open<EventScopeSelectorPopupComponent, EventScope>(
        EventScopeSelectorPopupComponent,
        {
          title: 'Dies ist ein wiederkehrender Termin',
          mode: 'DIALOG',
          data: { event: forEvent, allowedScopes },
        },
      )
      .afterClosed()
      .pipe(map((r) => r ?? undefined));
  }

  selectEventRegistrationScope(
    forEvent?: Pick<TremazeEvent, 'startDate' | 'schedule'>,
  ): Observable<EventRegistrationScope | undefined> {
    return this.selectEventScope(forEvent, [
      'SINGLE',
      'ALL',
    ]) as Observable<EventRegistrationScope>;
  }
}
