import { inject, Injectable } from '@angular/core';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { MatDialog } from '@angular/material/dialog';
import { FolderViewCopyDialogComponent } from '../components/copy-dialog/folder-view-copy-dialog.component';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class FolderViewCopyService {
  private readonly _dialog = inject(MatDialog);

  async copyFileToFolder(file: FileStorage, folderId: string) {
    const result: FileStorage | undefined | null = await firstValueFrom(
      this._dialog
        .open(FolderViewCopyDialogComponent, {
          data: { file, folderId },
          panelClass: 'relative-dialog',
        })
        .afterClosed(),
    );

    return result;
  }
}
