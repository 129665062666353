import { Component, Inject, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SelectUserNotificationConfig } from './types';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'tremaze-select-user-notification',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatRadioGroup,
    MatRadioButton,
    FormsModule,
    MatButton,
  ],
  template: `
    <h1 mat-dialog-title>{{ title }}</h1>
    <div mat-dialog-content>
      <p>{{ message }}</p>

      <mat-radio-group [(ngModel)]="value">
        <mat-radio-button [value]="true">{{
          trueOptionLabel
        }}</mat-radio-button>
        <mat-radio-button [value]="false">{{
          falseOptionLabel
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="undefined">
        {{ cancelButtonLabel }}
      </button>
      <button
        [disabled]="value === undefined"
        mat-raised-button
        color="accent"
        [mat-dialog-close]="value"
      >
        {{ confirmButtonLabel }}
      </button>
    </div>
  `,
  styles: `
    mat-radio-group {
      display: flex;
      flex-direction: column;
    }
  `,
})
export class SelectUserNotificationComponent {
  value?: boolean;

  get title(): string {
    return this.config?.title ?? 'Benutzer*in benachrichtigen';
  }

  get message(): string {
    return (
      this.config?.message ?? 'Soll die Benutzer*in benachrichtigt werden?'
    );
  }

  get trueOptionLabel(): string {
    return (
      this.config?.trueOptionLabel ?? 'Benutzer*in per E-Mail benachrichtigen'
    );
  }

  get falseOptionLabel(): string {
    return (
      this.config?.falseOptionLabel ??
      'Keine E-Mail-Benachrichtigung an Benutzer*in senden'
    );
  }

  get confirmButtonLabel(): string {
    return this.config?.confirmButtonLabel ?? 'Bestätigen';
  }

  get cancelButtonLabel(): string {
    return this.config?.cancelButtonLabel ?? 'Abbrechen';
  }

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    readonly config?: SelectUserNotificationConfig,
  ) {}
}
