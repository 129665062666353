import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomFormFieldsAbstractControl } from '../form-models';

@Component({
  selector: 'tremaze-custom-forms-field-edit',
  templateUrl: './custom-forms-field-edit.component.html',
})
export class CustomFormsFieldEditComponent {
  @Input() formGroup!: FormGroup<CustomFormFieldsAbstractControl>;
  @Input() index!: number;
}
