import { inject, Injectable } from '@angular/core';
import { FileSelectorComponent } from './file-selector.component';
import { Observable } from 'rxjs';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FileSelectorService } from './file-selector.service';
import { FileSelectorConfig, FolderSelectorConfig } from './config';
import { FolderViewSelectionType } from '@tremaze/shared/feature/file-storage/feature/folder-view';

@Injectable()
export class FileSelectorServiceImpl implements FileSelectorService {
  private readonly _dialog = inject(MatDialog);

  private _select(
    config: Pick<FileSelectorConfig, 'fileTypeMatcher'> & {
      type: FolderViewSelectionType;
    },
  ): Observable<{ files: FileStorage[]; dirs: DirStorage[] } | null> {
    return this._dialog
      .open(FileSelectorComponent, {
        panelClass: ['dialog-no-padding', 'fullscreen'],
        data: config,
      })
      .afterClosed()
      .pipe(
        map((r) => {
          if (r) {
            return r as { files: FileStorage[]; dirs: DirStorage[] };
          }
          return r ?? null;
        }),
      );
  }

  selectFiles(config?: FileSelectorConfig): Observable<FileStorage[] | null> {
    return this._select({
      fileTypeMatcher: config?.fileTypeMatcher,
      type: 'singleFile',
    }).pipe(map((r) => r?.files ?? null));
  }

  selectFolders(
    config?: FolderSelectorConfig,
  ): Observable<DirStorage[] | null> {
    return this._select({
      type: 'singleFolder',
    }).pipe(map((r) => r?.dirs ?? null));
  }
}
