import { inject, Injectable, OnDestroy } from '@angular/core';
import { bulkActionConfig } from './tokens';
import { BulkActionConfig } from './types';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  filter,
  finalize,
  from,
  map,
  Observable,
  of,
  ReplaySubject,
  scan,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  toArray,
} from 'rxjs';
import {
  bindTo,
  ensureObservable,
  filterFalse,
  filterTrue,
  shareReplayWithRefCount,
} from '@tremaze/shared/util/rxjs';
import { NotificationService } from '@tremaze/shared/notification';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import { Duration } from '@tremaze/duration';

type LoadedItem<T> = {
  item: T;
  isActionPermitted: boolean;
  errorWhenLoading?: boolean;
  errorWhenChecking?: boolean;
  errorWhenExecuting?: boolean;
};

@Injectable()
export class BulkActionComponentService<T> implements OnDestroy {
  private readonly _notificationService = inject(NotificationService);
  private readonly _confirmationService = inject(ConfirmationService);
  private readonly _config = inject(bulkActionConfig) as BulkActionConfig<T>;

  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  private readonly _isExecuting$ = new BehaviorSubject<boolean>(false);
  private readonly _destroyed$ = new Subject<void>();
  private readonly _itemLoaded$ = new ReplaySubject<LoadedItem<T>>();
  private readonly _itemExecuted$ = new Subject<LoadedItem<T>>();
  private readonly _beginExecution$ = new Subject<void>();
  private readonly _executionTime$ = new Subject<number>();

  readonly isLoading$: Observable<boolean> = this._isLoading$;
  readonly isExecuting$: Observable<boolean> = this._isExecuting$;

  readonly completed$ = this.isExecuting$.pipe(
    filterTrue(),
    take(1),
    concatMap(() => this.isExecuting$.pipe(filterFalse(), take(1))),
    map(() => true),
    startWith(false),
    shareReplayWithRefCount(),
  );

  get items(): T[] {
    return this._config.items;
  }

  readonly loadedItems$: Observable<LoadedItem<T>[]> = this._itemLoaded$.pipe(
    scan(
      (acc, item) => {
        return [...acc, item];
      },
      <LoadedItem<T>[]>[],
    ),
    shareReplayWithRefCount(),
  );

  readonly loadedPercents$: Observable<number> = this.loadedItems$.pipe(
    map((loadedItems) => (loadedItems.length / this.items.length) * 100),
    shareReplayWithRefCount(),
  );

  readonly permittedItems$: Observable<T[]> = this.loadedItems$.pipe(
    map((loadedItems) =>
      loadedItems
        .filter((item) => item.isActionPermitted)
        .map((item) => item.item),
    ),
    shareReplayWithRefCount(),
  );

  readonly notPermittedItems$: Observable<T[]> = this.loadedItems$.pipe(
    map((loadedItems) =>
      loadedItems
        .filter((item) => !item.isActionPermitted)
        .map((item) => item.item),
    ),
    shareReplayWithRefCount(),
  );

  readonly executedItems$: Observable<LoadedItem<T>[]> =
    this._beginExecution$.pipe(
      switchMap(() =>
        this._itemExecuted$.pipe(
          scan(
            (acc, item) => {
              return [...acc, item];
            },
            <LoadedItem<T>[]>[],
          ),
        ),
      ),
      startWith([]),
      shareReplayWithRefCount(),
    );

  readonly allItemsExecuted$: Observable<boolean> = combineLatest([
    this.permittedItems$.pipe(
      map((r) => r.length),
      distinctUntilChanged(),
    ),
    this.executedItems$.pipe(
      map((r) => r.length),
      distinctUntilChanged(),
    ),
  ]).pipe(
    map(([permitted, executed]) => permitted === executed),
    distinctUntilChanged(),
    shareReplayWithRefCount(),
  );

  readonly successfulItems$: Observable<LoadedItem<T>[]> =
    this.executedItems$.pipe(
      map((executedItems) =>
        executedItems.filter((item) => !item.errorWhenExecuting),
      ),
      shareReplayWithRefCount(),
    );

  readonly failedItems$: Observable<LoadedItem<T>[]> = this.executedItems$.pipe(
    map((executedItems) =>
      executedItems.filter((item) => item.errorWhenExecuting),
    ),
    shareReplayWithRefCount(),
  );

  readonly executedPercent$ = combineLatest([
    this.permittedItems$,
    this.executedItems$,
  ]).pipe(
    map(([permittedItems, executedItems]) => {
      return (executedItems.length / permittedItems.length) * 100;
    }),
    shareReplayWithRefCount(),
  );

  readonly averageExecutionTime$ = this._beginExecution$.pipe(
    switchMap(() =>
      this._executionTime$.pipe(
        startWith(200),
        scan(
          (acc, time) => {
            return [...acc, time];
          },
          <number[]>[],
        ),
      ),
    ),
    map((times) => times.reduce((acc, time) => acc + time, 0) / times.length),
    startWith(0),
    shareReplayWithRefCount(),
  );

  readonly remainingTimeEstimate$ = combineLatest([
    this.permittedItems$,
    this.executedItems$,
    this.averageExecutionTime$,
    this.isLoading$,
  ]).pipe(
    map(
      ([
        permittedItems,
        executedItems,
        averageExecutionTime,
        isLoadingItems,
      ]) => {
        const relevantLength = isLoadingItems
          ? this.items.length
          : permittedItems.length;
        const remainingItems = relevantLength - executedItems.length;
        return remainingItems * averageExecutionTime;
      },
    ),
    distinctUntilChanged(),
    map((time) => Duration.fromMilliseconds(time).round('seconds')),
    shareReplayWithRefCount(),
  );

  constructor() {
    this._loadItems().subscribe({
      complete: () => this._isLoading$.next(false),
    });
  }

  ngOnDestroy() {
    this._itemLoaded$.complete();
    this._isLoading$.complete();
    this._isExecuting$.complete();
    this._beginExecution$.complete();
    this._executionTime$.complete();
    this._itemExecuted$.complete();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _loadItems(): Observable<LoadedItem<T>[]> {
    this._isLoading$.next(true);
    return from(this.items).pipe(
      concatMap((item) => this._loadItem(item)),
      toArray(),
      takeUntil(this._destroyed$),
      finalize(() => this._isLoading$.next(false)),
    );
  }

  private _loadItem(item: T): Observable<LoadedItem<T>> {
    return this._fetchItem(item).pipe(
      switchMap((item) => {
        return ensureObservable(this._isActionPermitted(item)).pipe(
          map((isActionPermitted) => ({ item, isActionPermitted })),
          catchError(() =>
            of({ item, isActionPermitted: false, errorWhenChecking: true }),
          ),
        );
      }),
      catchError(() => {
        return of({ item, isActionPermitted: false, errorWhenLoading: true });
      }),
      bindTo(this._itemLoaded$),
    );
  }

  private _fetchItem(item: T): Observable<T> {
    return this._config.fetchItem(item);
  }

  beginExecution() {
    this._beginExecution$.next();
    this._isExecuting$.next(true);
    this._itemLoaded$
      .pipe(
        takeUntil(this._destroyed$),
        takeUntil(this._beginExecution$),
        takeUntil(this.allItemsExecuted$.pipe(filterTrue())),
        filter((item) => item.isActionPermitted),
        concatMap((item) => {
          const start = Date.now();
          return this._execute(item.item).pipe(
            map(() => ({ ...item, errorWhenExecuting: false })),
            catchError(() => of({ ...item, errorWhenExecuting: true })),
            finalize(() => {
              this._executionTime$.next(Date.now() - start);
            }),
          );
        }),
        bindTo(this._itemExecuted$),
        finalize(() => {
          this._notificationService.showNotification(
            'Mehrfachaktion abgeschlossen',
          );
          this._isExecuting$.next(false);
        }),
      )
      .subscribe();
  }

  private _execute(item: T): Observable<void> {
    return this._config.execute(item);
  }

  private _isActionPermitted(
    item: T,
  ): boolean | Promise<boolean> | Observable<boolean> {
    return this._config.isActionPermitted
      ? this._config.isActionPermitted(item)
      : true;
  }
}
