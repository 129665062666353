import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Meta } from '@tremaze/shared/models';
import { TremazeDate } from '@tremaze/shared/util-date';

export abstract class FileStorageFixtures {
  static generateFileStorage = (id: number): FileStorage => {
    let fileType: string;
    let fileExtension: string;
    switch (id % 5) {
      case 0:
        fileType = 'application/pdf';
        fileExtension = 'pdf';
        break;
      case 1:
        fileType = 'application/msword';
        fileExtension = 'doc';
        break;
      case 2:
        fileType = 'image/jpeg';
        fileExtension = 'jpg';
        break;
      case 3:
        fileType = 'video/mp4';
        fileExtension = 'mp4';
        break;
      default:
      case 4:
        fileType =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        fileExtension = 'docx';
        break;
    }

    return new FileStorage(
      id.toString(),
      new Meta(
        new TremazeDate('2021-01-01T00:00:00Z').add(id, 'day'),
        new TremazeDate('2022-01-01T00:00:00Z').add(id, 'day'),
      ),
      `File Number ${id}.${fileExtension}`,
      `file${id}.${fileExtension}`,
      100000,
      100,
      200,
      fileType,
    );
  };

  static readonly fileStorage1: FileStorage =
    FileStorageFixtures.generateFileStorage(1);
  static readonly fileStorage2: FileStorage =
    FileStorageFixtures.generateFileStorage(2);
  static readonly fileStorage3: FileStorage =
    FileStorageFixtures.generateFileStorage(3);
  static readonly fileStorage4: FileStorage =
    FileStorageFixtures.generateFileStorage(4);
  static readonly fileStorage5: FileStorage =
    FileStorageFixtures.generateFileStorage(5);

  static readonly all: FileStorage[] = [
    FileStorageFixtures.fileStorage1,
    FileStorageFixtures.fileStorage2,
    FileStorageFixtures.fileStorage3,
    FileStorageFixtures.fileStorage4,
    FileStorageFixtures.fileStorage5,
  ];
}
