import {
  Component,
  ContentChild,
  ContentChildren,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DynamicListItemTitleDirective } from '../dynamic-list-item-title.directive';
import { DynamicListItemAvatarComponent } from '../dynamic-list-item-avatar.component';
import { DynamicListItemSubtitleDirective } from '../dynamic-list-item-subtitle.directive';
import { DynamicListItemActionComponent } from '../dynamic-list-item-action.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DynamicListCenterColumnComponent } from '../dynamic-list-center-column.component';
import { dynamicListItemAnimations } from '../animations';

@Component({
  selector: 'tremaze-dynamic-list-item',
  template: `
    <div class="dynamic-list__Item__Col1">
      <div
        [@childAnimation]
        class="dynamic-list__Item__Col1__Content"
        [class.dynamic-list__Item__Col1__Content--No-Avatar]="!avatar"
      >
        <ng-content select="tremaze-dynamic-list-item-avatar"></ng-content>
        <ng-content select="[tremazeDynamicListItemTitle]"></ng-content>
        <ng-content select="[tremazeDynamicListItemSubtitle]"></ng-content>
        <ng-content></ng-content>
      </div>
    </div>
    <div class="dynamic-list__Item__Col2" *ngIf="centerColumn">
      <div class="dynamic-list__Item__Col2__Content" [@childAnimation]>
        <ng-content select="tremaze-dynamic-list-center-column"></ng-content>
      </div>
    </div>
    <div *ngIf="actions?.length" class="dynamic-list__Item__Col3">
      <ul class="dynamic-list__Item__Actions">
        <ng-content select="tremaze-dynamic-list-item-action"></ng-content>
      </ul>
    </div>
  `,
  styleUrls: ['./dynamic-list-item.component.scss'],
  animations: dynamicListItemAnimations,
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'dynamic-list__Item primary-hover',
  },
})
export class DynamicListItemComponent {
  @ContentChild(DynamicListItemTitleDirective, { descendants: false })
  readonly title?: DynamicListItemTitleDirective;
  @ContentChild(DynamicListItemAvatarComponent, { descendants: false })
  readonly avatar?: DynamicListItemAvatarComponent;
  @ContentChild(DynamicListItemSubtitleDirective, { descendants: false })
  readonly subtitle?: DynamicListItemSubtitleDirective;
  @ContentChild(DynamicListCenterColumnComponent, { descendants: false })
  readonly centerColumn?: DynamicListCenterColumnComponent;
  @ContentChildren(DynamicListItemActionComponent, { descendants: false })
  readonly actions?: DynamicListItemActionComponent[];

  @ViewChild(TemplateRef, { static: true }) templateRef!: TemplateRef<any>;

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
}
