import { InjectionToken } from '@angular/core';

export interface FileStorageModuleConfig {
  // @deprecated
  disableSharedControllers?: boolean;
  legacyDownloadPathEnabled?: boolean;
}

export const FILE_STORAGE_MODULE_CONFIG =
  new InjectionToken<FileStorageModuleConfig>('FILE_STORAGE_MODULE_CONFIG');
