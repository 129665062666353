import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  isObservable,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

@Component({
  selector: 'tremaze-picker-popup',
  templateUrl: './picker-popup.component.html',
  styleUrls: ['./picker-popup.component.scss'],
  animations: [
    trigger('transformPanel', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'scale(1, 0.8)',
        }),
      ),
      transition(
        'void => showing',
        animate(
          '120ms cubic-bezier(0, 0, 0.2, 1)',
          style({
            opacity: 1,
            transform: 'scale(1, 1)',
          }),
        ),
      ),
      transition('* => void', animate('100ms linear', style({ opacity: 0 }))),
    ]),
  ],
})
export class PickerPopupComponent<T extends object>
  implements AfterViewInit, OnDestroy
{
  @Input() displayWith?: (option?: T) => string;
  @Input() subtitleWith?: (option?: T) => string | undefined;
  @Input() getAvatar?: (option?: T) => FileStorage | null | undefined;
  @Input() getAvatarFallbackInitials?: (option: T) => string | undefined;
  @Input() filteredOptions?: (filterValue: string) => Observable<T[]> | T[];
  @Input() inputPrefixIcon?: string;
  @Input() label = '';
  @Input() showResetButton = false;
  @Input() showAddOptionButton = false;

  @Output() optionSelected = new EventEmitter<T>();

  @Output() addOptionClicked = new EventEmitter<void>();

  @ViewChild('filterInput', { read: ElementRef })
  input!: ElementRef<HTMLInputElement>;

  private readonly _filterValue$ = new Subject<string>();
  private readonly _destroy$ = new Subject<void>();
  readonly hasError$ = new BehaviorSubject<boolean>(false);
  private readonly _filterChanged$ = new Subject<void>();

  readonly filteredOptions$: Observable<T[]> = this._filterValue$.pipe(
    startWith(''),
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this._filterChanged$.next()),
    switchMap((filterValue) => {
      const options = this.filteredOptions?.(filterValue) ?? [];
      return isObservable(options)
        ? options.pipe(
            tap(() => this.hasError$.next(false)),
            catchError((_) => {
              this.hasError$.next(true);
              return of([]);
            }),
          )
        : of(options);
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly isEmpty$ = this.filteredOptions$.pipe(
    map((options) => options.length === 0),
  );

  readonly isLoading$ = merge(
    this._filterValue$.pipe(
      map(() => true),
      startWith(true),
    ),
    this.filteredOptions$.pipe(map(() => false)),
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  ngAfterViewInit(): void {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        map((event) => (event.target as HTMLInputElement).value),
        tap((filterValue) => this._filterValue$.next(filterValue)),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._filterValue$.complete();
    this._destroy$.complete();
  }

  onOptionSelected(option?: T): void {
    if (!option) {
      return;
    }
    this.optionSelected.emit(option);
  }

  onClickAddOption(event: Event): void {
    this.addOptionClicked.emit();
  }
}
