import {
  booleanAttribute,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DragZoneDirective } from './drag-zone.directive';
import { DragZoneItemDirective } from './drag-zone-item.directive';

@Directive({
  selector: '[tremazeDropZone]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'drop-zone',
  },
})
export class DropZoneDirective implements OnInit, OnDestroy {
  readonly elementRef = inject(ElementRef);
  private readonly _dragZone? = inject(DragZoneDirective, { optional: true });
  private readonly _dragItem? = inject(DragZoneItemDirective, {
    optional: true,
    self: true,
  });

  static _nextId = 0;
  private _id = DropZoneDirective._nextId++;

  private _dropZoneDisabled = false;

  set dropZoneDisabled(value: boolean) {
    this._dropZoneDisabled = value;
  }

  @Input({ transform: booleanAttribute })
  @HostBinding('class.drop-zone--Disabled')
  get dropZoneDisabled(): boolean {
    return (
      this._dropZoneDisabled ||
      (!!this._dragItem && !this._dragItem?.dragDisabled)
    );
  }

  @Output() readonly dropped = new EventEmitter<unknown[]>();

  @HostBinding('style.pointer-events')
  get pointerEvents(): string {
    return this._dragZone?.isDragging && this.dropZoneDisabled ? 'none' : '';
  }

  ngOnInit() {
    if (this._dragZone) {
      this._dragZone.registerDropZone(this._id, this);
    } else {
      console.warn('No drag-zone found for drop-zone');
    }
  }

  ngOnDestroy() {
    this._dragZone?.unregisterDropZone(this._id);
  }

  drop(data: unknown[]): void {
    if (!this.dropZoneDisabled) {
      this.dropped.emit(data);
    }
  }
}
