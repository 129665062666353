import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IconPickerDialogComponent } from './icon-picker-dialog.component';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IconPickerService {
  constructor(private dialog: MatDialog) {}

  pickIcon(): Observable<null | string> {
    return this.dialog
      .open(IconPickerDialogComponent)
      .afterClosed()
      .pipe(map((r) => r || null));
  }
}
