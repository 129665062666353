import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContainer,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton, MatIconButton } from '@angular/material/button';
import { ADVISOR_BACKDROP_CLASS, ADVISOR_CLASS } from './qm-advisor.service';
import { MatIcon } from '@angular/material/icon';
import { DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject, map, throttleTime } from 'rxjs';

@Component({
  selector: 'tremaze-qm-advisor',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContainer,
    MatDialogActions,
    MatDialogContent,
    MatDialogClose,
    MatButton,
    MatDialogTitle,
    MatIconButton,
    MatIcon,
  ],
  templateUrl: './qm-advisor.component.html',
  styleUrl: './qm-advisor.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class QmAdvisorComponent implements OnInit, OnDestroy {
  constructor(private dialogRef: DialogRef) {
    this.dialogRef.backdropClick.subscribe(() => {
      this.hideDialogWithoutClosing();
    });
  }

  hideDialogWithoutClosing() {
    const advisorDialog = document.getElementsByClassName(ADVISOR_CLASS)[0];
    const advisorBackdrop = document.getElementsByClassName(
      ADVISOR_BACKDROP_CLASS,
    )[0];
    if (advisorDialog && advisorDialog instanceof HTMLElement) {
      advisorDialog.style.display = 'none';
    }
    if (advisorBackdrop && advisorBackdrop instanceof HTMLElement) {
      advisorBackdrop.style.display = 'none';
    }
  }

  iframeHeightSubject = new BehaviorSubject<number>(580);
  iframeHeight$ = this.iframeHeightSubject.asObservable().pipe(
    throttleTime(300, undefined, { trailing: true }),
    map((height) => `${height}px`),
  );

  handleResize() {
    const viewHeight = window.innerHeight;
    if (viewHeight < 640) {
      this.iframeHeightSubject.next(viewHeight - 60);
    } else {
      this.iframeHeightSubject.next(580);
    }
  }

  ngOnInit(): void {
    this.handleResize();
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  /**
   *  HEIGHT NEEDED: 580px + 60px Overhead
   */
}
