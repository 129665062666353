import {
  booleanAttribute,
  ContentChild,
  Directive,
  Input,
  TemplateRef,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SortDirection } from '@angular/material/sort';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tremazeDataTableColumn]',
})
export class DataTableColumnDirective {
  @Input('tremazeDataTableColumn') public columnName: string;
  @ContentChild(TemplateRef) public columnTemplate: TemplateRef<unknown>;
  @Input() sortStart: SortDirection = 'asc';

  private _displayName: string;

  get displayName(): string {
    return this._displayName ?? this.columnName;
  }

  @Input()
  set displayName(value: string) {
    this._displayName = value;
  }

  private _sortable: boolean;

  @Input({ transform: booleanAttribute })
  get sortable(): boolean {
    return this._sortable;
  }

  set sortable(value: boolean | undefined) {
    this._sortable = coerceBooleanProperty(value);
  }

  private _disableSortClear: boolean;

  @Input()
  get disableSortClear(): boolean {
    return this._disableSortClear;
  }

  set disableSortClear(value: boolean) {
    this._disableSortClear = coerceBooleanProperty(value);
  }
}
