import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
} from '@angular/core';
import { FILE_PREVIEW_DIALOG_DATA } from './file-preview-overlay.tokens';
import { Image } from './file-preview-overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';

export class FilePreviewOverlayRef {
  constructor(private overlayRef: OverlayRef) {}

  close(): void {
    this.overlayRef.dispose();
  }
}

@Component({
  selector: 'tremaze-file-preview-overlay',
  templateUrl: './file-preview-overlay.component.html',
  styleUrls: ['./file-preview-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewOverlayComponent {
  loaded = false;
  safeResourceURL: SafeResourceUrl;
  @HostBinding('class.fullscreen-file-preview-overlay') fullscreen = false;

  constructor(
    public dialogRef: FilePreviewOverlayRef,
    private _sanitization: DomSanitizer,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public image: Image,
  ) {
    if (image.type === 'PDF') {
      this.fullscreen = true;
      this.safeResourceURL = this._sanitization.bypassSecurityTrustResourceUrl(
        image.url,
      );
    } else {
      this.fullscreen = false;
    }
  }

  onClickDownload() {
    if (this.image) {
      const a: HTMLAnchorElement = document.createElement(
        'a',
      ) as HTMLAnchorElement;
      const { origin, pathname, searchParams } = new URL(this.image.url);
      a.href = `${origin}${pathname}?tenantId=${searchParams.get('tenantId')}`;
      a.download = this.image.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(this.image.url);
    }
  }

  @HostListener('document:keydown', ['$event'])
  public handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      this.dialogRef.close();
    }
  }
}
