import { MatFormFieldControl } from '@angular/material/form-field';
import {
  FileStorage,
  FileStorageEntityMeta,
  FileStorageEntityType,
} from '@tremaze/shared/feature/file-storage/types';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import {
  catchError,
  flatMap,
  from,
  Observable,
  of,
  pluck,
  shareReplay,
  Subject,
  toArray,
} from 'rxjs';
import { FilePreviewOverlayService } from '@tremaze/shared/feature/file-storage/ui/file-preview-overlay';
import { PermissionCheckService } from '@tremaze/shared/permission/services';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';
import { FileSelectorService } from '@tremaze/shared/feature/file-storage/ui/file-selector';
import { NotificationService } from '@tremaze/shared/notification';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Failure } from '@tremaze/shared/util-error';
import { HttpErrorResponse } from '@angular/common/http';
import { TzPermissionRequest } from '@tremaze/shared/permission/types';
import { InstitutionSelectionService } from '@tremaze/shared/feature/institution/feature/selection';
import {
  filterNotNullOrUndefined,
  mapNotNullOrUndefined,
} from '@tremaze/shared/util/rxjs';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import {
  filter,
  map,
  mapTo,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ensureArray } from '@tremaze/shared/util-utilities';

type GlobalUploadType = 'GLOBAL';

export type FileInputAcceptType = 'any' | 'image' | 'video';

export type FileInputAcceptTypeOrArray =
  | FileInputAcceptType
  | FileInputAcceptType[];

export type UploadMIMEType = 'video/quicktime';

export abstract class FileInputBase
  implements
    MatFormFieldControl<FileStorage[] | FileStorage>,
    ControlValueAccessor
{
  get rejectTypes(): UploadMIMEType[] {
    return this._rejectTypes;
  }

  set rejectTypes(value: UploadMIMEType[]) {
    this._rejectTypes = value;
  }

  static nextId = 0;
  readonly valueChanged = new EventEmitter<
    FileStorage[] | FileStorage | null
  >();
  // tslint:disable-next-line:no-input-rename
  abstract instId$: Observable<string | string[] | null>;
  hasUploadPermission$: Observable<boolean>;
  hasViewFileStoragePermission$: Observable<boolean>;
  abstract entityName: FileStorageEntityType;
  abstract systemDirType: string;
  controlType?: string;
  autofilled?: boolean;
  errorState: boolean;
  readonly placeholder: string;
  stateChanges = new Subject<void>();
  abstract id: string;
  abstract describedBy: string;
  abstract permissionRequest: TzPermissionRequest;
  protected abstract _uploadEvent$: Observable<FileList | File>;
  protected readonly _destroyed$ = new Subject();
  protected abstract _multiple: boolean;
  private readonly _hasGlobalUploadPermission$ = from(
    this._permissionChecker.checkPermission$({ gPerms: 'FILE_STORAGE_WRITE' }),
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
  private _possibleInstIdsToUpload$: Observable<string[] | GlobalUploadType>;
  protected onChangeCallback;
  private _uploadAction$: Observable<void>;
  private _ownInstIds = this._authService.authenticatedUser$.pipe(
    map((r) => r.instIds),
  );
  private _rejectTypes: UploadMIMEType[] = [];

  protected constructor(
    protected _fileService: FileStorageService,
    protected _permissionChecker: PermissionCheckService,
    protected fileSelectorService: FileSelectorService,
    protected readonly _institutionSelectionService: InstitutionSelectionService,
    private readonly _cdRef: ChangeDetectorRef,
    protected notificationService: NotificationService,
    protected _filePreviewOverlayService: FilePreviewOverlayService,
    public ngControl: NgControl,
    private readonly _authService: AuthV2Service,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get fileInputAccept(): string | null {
    const mode = this.mode ?? 'image';
    if (typeof mode === 'string') {
      return this.modeToInputAccept(mode);
    }
    return mode.map((r) => this.modeToInputAccept(r)).join(',');
  }

  get fileTypeRegex(): RegExp {
    const mode = this.mode ?? 'image';
    if (typeof mode === 'string') {
      return new RegExp(this.modeToRegexString(mode));
    }
    return new RegExp(
      `(${mode.map((m) => this.modeToRegexString(m)).join(')|(')})`,
    );
  }

  private _mode: FileInputAcceptTypeOrArray = 'image';

  get mode(): FileInputAcceptTypeOrArray {
    return this._mode ?? 'image';
  }

  set mode(value: FileInputAcceptTypeOrArray) {
    this._mode = value;
  }

  get shouldLabelFloat() {
    return !this.disabled && !this.empty;
  }

  get empty() {
    return !this.value;
  }

  get canShowPreview(): boolean {
    return !!this._filePreviewOverlayService;
  }

  get canUpload(): boolean {
    return !!this.entityName || this.systemDirType === 'AVATAR';
  }

  get canSelectFromFileStorage() {
    return this.systemDirType !== 'AVATAR';
  }

  private _disabled: boolean;

  get disabled(): boolean {
    const d = this.ngControl ? this.ngControl.disabled : this._disabled;
    return d || !(this.canSelectFromFileStorage || this.canUpload);
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _focused: boolean;

  get focused(): boolean {
    return this._focused && !this.disabled;
  }

  set focused(value: boolean) {
    this._focused = value;
  }

  private _required: boolean;

  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next(null);
  }

  private _value: FileStorage[] | FileStorage | null;

  get value(): FileStorage[] | FileStorage | null {
    return this._value;
  }

  set value(v: FileStorage[] | FileStorage | null) {
    let va: FileStorage[] | FileStorage = v;
    if (this._multiple) {
      if (v && !Array.isArray(v)) {
        va = [v];
      }
    } else {
      if (v && Array.isArray(v)) {
        va = v[0];
      }
    }
    this._value = va;
    this.stateChanges.next(null);
  }

  private _aspectRatio: number;

  get aspectRatio(): number {
    return this._aspectRatio ?? 16 / 9;
  }

  set aspectRatio(value: number) {
    this._aspectRatio = value;
  }

  private _incorrectAspectRatio = false;

  get incorrectAspectRatio(): boolean {
    return this._incorrectAspectRatio;
  }

  onAspectRatioUpdated(aspectRatio: number) {
    if (this.aspectRatio) {
      const rA = Math.floor(aspectRatio * 100);
      const tRA = Math.floor(this.aspectRatio * 100);
      const diff = rA - tRA;
      this._incorrectAspectRatio = Math.abs(diff) > 10;
    }
  }

  async onClickMagnifierButton(event: Event, target: FileStorage) {
    if (!target) {
      if (this._multiple) {
        return;
      }
      target = this.value as FileStorage;
    }
    event.stopPropagation();
    if (this._filePreviewOverlayService) {
      let url;
      if (target.type === 'PDF') {
        const blob = await this._fileService
          .downloadBlob(target)
          .pipe(take(1))
          .toPromise();
        if (blob instanceof Failure) {
          return;
        }
        url = window.URL.createObjectURL(blob);
      } else {
        url = await this._fileService.getFileDownloadURL(target).toPromise();
      }
      this._filePreviewOverlayService.open({
        data: {
          type: target.type,
          url,
          name: target.fileViewname,
        },
      });
    }
  }

  hasLegalFileType<T extends File | FileStorage>(file: T): boolean {
    let MIMEtype: UploadMIMEType;

    if (file instanceof File) {
      MIMEtype = file.type as UploadMIMEType;
    }

    if (file instanceof FileStorage) {
      MIMEtype = file.fileType as UploadMIMEType;
    }

    const hasCorrectFileTypeCategory = this.fileTypeRegex.test(MIMEtype);

    const hasIllegalFileType = this._rejectTypes.includes(MIMEtype);

    return hasCorrectFileTypeCategory && !hasIllegalFileType;
  }

  groupByLegalFileType<T extends File | FileStorage>(
    files: T[],
  ): { legalFiles: T[]; illegalFiles: T[] } {
    const legalFiles = [];
    const illegalFiles = [];

    for (const file of files) {
      this.hasLegalFileType(file)
        ? legalFiles.push(file)
        : illegalFiles.push(file);
    }

    return { legalFiles, illegalFiles };
  }

  async onClickSelectFromFileStorage() {
    if (!this.disabled) {
      if (await this.hasViewFileStoragePermission$.pipe(take(1)).toPromise()) {
        const selectedFile = await this.fileSelectorService
          .selectFiles({ fileTypeMatcher: this.fileTypeRegex })
          .pipe(
            map((files) => {
              const { legalFiles, illegalFiles } =
                this.groupByLegalFileType(files);

              for (const illegalFile of illegalFiles) {
                this.notificationService.showNotification(
                  `Die gewählte Datei ${illegalFile.filename} ist nicht zulässig`,
                );
              }

              return legalFiles;
            }),
          )
          .toPromise();
        if (selectedFile?.length) {
          if (this._multiple) {
            this.value = [...(this.value as FileStorage[]), ...selectedFile];
          } else {
            this.value = selectedFile[0];
          }
          this.onChangeCallback?.(this.value);
          this.valueChanged.emit(this.value);
        }
      }
    }
  }

  async onFilesDropped(files: FileList) {
    if (
      !this.disabled &&
      this.canUpload &&
      (await this.hasUploadPermission$.pipe(take(1)).toPromise())
    ) {
      if (files.length > 1) {
        this.notificationService.showNotification(
          'Hier kann nur eine Datei hochgeladen werden',
        );
      }
    }
  }

  onClickDeleteButton() {
    this.writeValue(null);
    this.onChangeCallback?.(null);
    this.valueChanged.emit(null);
  }

  onContainerClick(event: MouseEvent): void {}

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {}

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  writeValue(obj: any): void {
    if (this._multiple && obj && !Array.isArray(obj)) {
      obj = [obj];
    } else if (!this._multiple && obj && Array.isArray(obj)) {
      obj = obj[0];
    }
    if (this._value !== obj) {
      this.value = obj;
    }
  }

  protected init(): void {
    this.setUpStreams();
    this._setUpUploadStreamAndListen();
  }

  protected doCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next(null);
    }
  }

  protected onDestroy(): void {
    this._destroyed$.next(null);
    this._destroyed$.complete();
  }

  private modeToInputAccept(mode: FileInputAcceptType): string {
    switch (mode) {
      case 'any':
        return null;
      case 'image':
        return 'image/*';
      case 'video':
        return 'video/*';
    }
  }

  private modeToRegexString(mode: FileInputAcceptType): string {
    switch (mode) {
      case 'any':
        return '.*';
      case 'image':
        return 'image\\/.+';
      case 'video':
        return 'video\\/.+';
    }
  }

  private _setUpUploadStreamAndListen(): void {
    this._uploadAction$ = this._uploadEvent$.pipe(
      // normalize to fileArray
      map((fileOrFileList) => {
        if (fileOrFileList instanceof FileList) {
          const fileArray: File[] = [];
          for (let i = 0; i < fileOrFileList.length; i++) {
            fileArray.push(fileOrFileList.item(i));
          }
          return fileArray;
        } else {
          return [fileOrFileList];
        }
      }),
      map((fileList) => {
        const { legalFiles, illegalFiles } =
          this.groupByLegalFileType(fileList);

        for (const illegalFile of illegalFiles) {
          this.notificationService.showNotification(
            `Die gewählte Datei ${illegalFile.name} ist nicht zulässig`,
          );
        }

        return legalFiles;
      }),
      filter((fileList) => fileList.length > 0),
      switchMap((selectedFiles) => {
        return this._possibleInstIdsToUpload$.pipe(
          take(1),
          switchMap<
            string[] | GlobalUploadType,
            Observable<{
              files: File[];
              entityName: FileStorageEntityType;
              entityMeta?: FileStorageEntityMeta;
            }>
          >((r) => {
            const base = { files: selectedFiles, entityName: this.entityName };
            if (r === 'GLOBAL') {
              return of(base);
            }
            return this._selectInstitutionToUploadTo(r).pipe(
              filterNotNullOrUndefined(),
              map((result) => {
                let entityMeta: FileStorageEntityMeta = {};
                if (result === 'UPLOAD_TO_PERSONAL_DIR') {
                  entityMeta = { uploadInUserDir: true };
                } else {
                  entityMeta = { instId: result };
                }
                return { ...base, entityMeta };
              }),
            );
          }),
          switchMap(({ files, entityName, entityMeta }) => {
            let request: Observable<FileStorage | FileStorage[]>;
            if (this.systemDirType === 'AVATAR') {
              request = this._fileService.uploadAvatar(files[0]);
            } else {
              request = this._fileService.uploadEntityFiles({
                files,
                entity: entityName,
                entityMeta,
              });
            }
            return request;
          }),
          catchError((e) => {
            if (e instanceof HttpErrorResponse) {
              this.notificationService.showDefaultErrorNotification();
            }
            return of(null);
          }),
          tap((event) => {
            if (this._multiple) {
              this.value = [
                ...(this.value as FileStorage[]),
                ...ensureArray(event),
              ];
            } else {
              this.value = ensureArray(event)[0];
            }
            this.onChangeCallback?.(this.value);
          }),
        );
      }),
      mapTo(null),
      catchError(() => of(null)),
      takeUntil(this._destroyed$),
    );

    this._uploadAction$.subscribe();
  }

  private setUpStreams(): void {
    this._possibleInstIdsToUpload$ = this.instId$.pipe(
      switchMap((ids) => (!ids?.length ? this._ownInstIds : of(ids))),
      map((instIds) => {
        if (!instIds?.length) {
          return null;
        }
        if (Array.isArray(instIds)) {
          return instIds;
        }
        return [instIds];
      }),
      switchMap<any, Observable<[string[], boolean]>>((ids) =>
        this._hasGlobalUploadPermission$.pipe(map((v) => [ids, v])),
      ),
      switchMap(([instIds, hasGlobalUploadPermission]) => {
        // if (hasGlobalUploadPermission) {
        //   if (!instIds?.length) {
        //     return of('GLOBAL' as GlobalUploadType);
        //   }
        //   return of(instIds);
        // }
        if (!instIds?.length) {
          return of(null);
        }
        return from(instIds).pipe(
          flatMap((instId) =>
            this._permissionChecker
              .checkPermission$({
                gPerms: 'FILE_STORAGE_WRITE',
                iPerms: {
                  instIds: instId,
                },
              })
              .then((hasPermission) => ({ instId, hasPermission })),
          ),
          filter(({ hasPermission }) => hasPermission),
          pluck('instId'),
          toArray(),
          takeUntil(this._destroyed$),
          shareReplay({
            bufferSize: 1,
            refCount: true,
          }),
        ) as Observable<string[]>;
      }),
    );

    this.hasUploadPermission$ = this._possibleInstIdsToUpload$.pipe(
      mapNotNullOrUndefined(),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );

    this.hasViewFileStoragePermission$ = from(
      this._permissionChecker.checkPermission$({
        gPerms: 'FILE_STORAGE_READ',
        iPerms: { instIds: 'ANY' },
      }),
    ).pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );
  }

  private _selectInstitutionToUploadTo(
    instIdPool: string[],
  ): Observable<string | 'UPLOAD_TO_PERSONAL_DIR' | null> {
    return this._institutionSelectionService
      .selectFromIdPool(instIdPool, {
        text: 'Bitte wähle den Bereich, in den die Datei abgelegt werden soll.',
        customOptions: [
          {
            label: 'Mein Persönlicher Ordner',
            value: 'UPLOAD_TO_PERSONAL_DIR',
          },
        ],
      })
      .pipe(map((r) => r));
  }
}
